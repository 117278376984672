<template>

    <div class="contents" ref="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">사용자</a>
            </li>
            <li>
                <a href="javascript:void(0)">권한관리</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>사용자 관리</h2>
                <div class="infection_search">
                    <h4>검색</h4>
                    <dl>
                        <dt>조건</dt>
                        <dd>
                            <select v-model="type">
                                <option value="N">이름</option>
                                <option value="E">사번</option>
                                <option value="D">부서</option>
                            </select>
                        </dd>
                        <dt>검색어</dt>
                        <dd>
                            <input type="text" style="width: 400px;" v-model="searchWord" @keydown.enter="search(0)"/>
                        </dd>
                        <dd>
                            <button class="btn_search" @click="search(0)">검색</button>
                            <button class="btn_secondary" id="btn_reg" @click="clear"> 초기화 </button>
                      
                        </dd>
                    </dl>
                </div>
                <br/>
                <br/>
                <div class="btn-right">
                    <!-- <button class="btn_primary" id="btn_reg" @click="() => {$router.push({name:'user-add'})}">사용자 등록</button> -->
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                </div>
            </div>
            <div class="table table_line">

                <table>
                    <tr>
                        <th>ID(사번)</th>
                        <th>소속/이름/직급</th>
                        <th>권한</th>
                        <th>등록일</th>
                        <th>관리</th>
                    </tr>
                    <tr v-for="(item, index) in userList" :key="index">
                        <td>{{ item.usrId }}</td>
                        <td>{{ item.deptNm + " " + item.korNm  + " " + item.jobposNm}}</td>
                        <td>{{ item.authName }}</td>
                        <td>{{ convertDate(item.regDate) }}</td>
                        <td>
                            <button v-if="role == 'ROLE_ADMIN'" @click="goUserUpd(item)">설정</button>
                            <button v-else-if="(item.authority != 'ROLE_ADMIN') && (role == 'ROLE_DIAG')" @click="goUserUpd(item)">설정</button>
                            <button v-else-if="(item.authority != 'ROLE_ADMIN') && (item.authority != 'ROLE_DIAG') && (role == 'ROLE_DEPT')" @click="goUserUpd(item)">설정</button>
                            <button v-else-if="(item.authority != 'ROLE_ADMIN') && (item.authority != 'ROLE_DIAG') && (item.modifyYn == 'Y')" @click="goUserUpd(item)">설정</button>                             
                        </td>
                    </tr>
                </table>
            </div>
            <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" 
            :type="type" :searchWord="searchWord" />

        </div>
    </div>
</template>

<script>
    import pagination from '../../components/commonPagination.vue'
    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';

    export default {
        data: () => {
            return {type: "N", searchWord: "", userList: [], page: {}, pageNation: []}
        },
        mixins: [mixin],
        created: function () {
            this.getMyinfo();
        },
        mounted: function() {
            if(this.$route.query.page) {
                this.type = this.$route.query.type
                this.searchWord = this.$route.query.searchWord
                this.search(this.$route.query.page);
            } else {
                this.search(0);
            }
                       
        },
        components : {
            pagination
        },
        methods: {
            clear(){
                this.type="N"
                this.searchWord = ""
                this.search(0)
            },
            getUserList: function (type, searchWord, page) {
                if (page < 0 || (this.page.totalPages == 0 ? 1 : this.page.totalPages) <= page) {
                    return;
                }
                this
                .$axios
                .get('/api/mng/service-user', {
                    params: {
                        type: type,
                        searchWord: searchWord,
                        page: page,
                        size: 30
                    }
                })
                .then((response) => {
                    this.userList = [];
                    this.pageNation = [];
                    this.page = {};
                    let users = response.data.result.content;

                    for (var i = 0; i < users.length; i++) {
                        this.userList.push(users[i]);
                    }
                    let pageInfo = response.data.result;
                    this.pagerHandler(
                        pageInfo.number,
                        pageInfo.first,
                        pageInfo.last,
                        pageInfo.totalPages
                    );
                    if(this.$route.query.page) {
                        this.$router.push({
                            path: this.$route.path,
                            query: {
                                page: page,
                                type: this.type, 
                                searchWord: this.searchWord,
                            }
                        }).catch(()=>{});
                    }
                    
                    this.$refs.contents.scrollTo(0, 0);
                })
                .catch((err) => {
                    console.log(err.response.data);
                });
            },
            search: function (page) {
              this.$router.push({
                path: this.$route.path,
                query: {
                  page : page,
                  type : this.type,
                  searchWord : this.searchWord
                }
              })
              this.getUserList(this.type, this.searchWord, page);
            },
            goUserUpd: function (item) {
                this.$router.push({
                    name: 'user-upd',
                    params: {
                        userId: item.usrId,
                        permission: item.permission,
                        deptNm: item.deptNm,
                        userNm: item.korNm,
                        jobposNm: item.jobposNm,
                        authority: item.authority,
                        download: item.download,
                    }
                });
            },
            confirmDownload: function () {
                this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {
                let param = {
                        page: 0,
                        size: 100000,
                        type: this.type,
                        searchWord: this.searchWord,
                    }

                this
                    .$axios
                    .get('/api/mng/v1/service-user/excel', {
                        params: param,
                        responseType: "blob"
                    })
                    .then((response) => {
                        console.log(response);
                        const url = window
                            .URL
                            .createObjectURL(
                                new Blob([response.data], {type: response.headers['content-type']})
                            );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute(
                            'download',
                            '사용자관리_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx'
                        );
                        document
                            .body
                            .appendChild(link);
                        link.click();

                        if (response.status == 200) {
                            let param = {
                                fileName: '사용자관리_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                                fileSize: response.data.size,
                                purpose: paramdata.purpose,
                                purposeEtc: paramdata.purpose_etc == null
                                    ? ''
                                    : paramdata.purpose_etc
                            }
                            this.setDownloadHistory(param);
                        }
                    })
            },

        }
    }
</script>
