<template>
    <div :id="editorId"></div>
</template>
<script>
    import 'codemirror/lib/codemirror.css'; // Editor's Dependency Style
    import '@toast-ui/editor/dist/toastui-editor.css'; // Editor's Style
    import 'tui-color-picker/dist/tui-color-picker.css';

    import Editor from '@toast-ui/editor';
    import colorSyntax from '@toast-ui/editor-plugin-color-syntax';

    export default {
        data: () => {
            return {editor: null,}
        },
        mounted() {
            const editor = new Editor({
                el: document.querySelector('#' + this.editorId),
                height: '390px',
                events: {
                    blur: this.onChange
                },
                language: 'ko',
                initialEditType: 'wysiwyg',
                previewStyle: 'vertical',
                plugins: [colorSyntax]
            });
            editor.setHtml(this.contents);
            this.editor = editor;
        },       
        props: [
            'contents', 'motherIndex', 'editorId'
        ],
        watch: {
            contents: function (newVal) {             
                this.editor.setHtml(newVal);
            }, 
              
        },
        methods: {
            onChange: function () {
                this.$emit('setEditorHtml', this.motherIndex, this.editor.getHtml());
            }
        }
    }
</script>