<template>
  <div>
    <div class="wrapper"
         v-if="this.$route.name == 'inquiry-popup' || this.$route.name == 'index' || this.$route.name == 'login' || this.$route.name == 'resetPw'">
      <router-view></router-view>
    </div>
    <div class="wrapper" v-else>
      <div id="header">
        <h1 @click="showLeftMenu" class="logo">AMC스마트출입시스템</h1>
        <nav style="width: 80%;">
          <div v-for="(item, index) in menus"  :key="index">
            <li :class="{active: item.active}" v-for="(pitem, pindex) in permission" :key="pindex"
                v-if="pitem == item.categoryCode">
              <a href="javascript:void(0)" @click="goCategory(item.categoryCode)">{{ item.category }}</a>
            </li>
          </div>
        </nav>
        <div class="usermenu">
          <div class="user_name">{{ korName + ' ' + userDeptName }}</div>
          <button class="btn_logout" @click="logOut()">로그아웃</button>
        </div>
      </div>
      <div id="container">
        <div v-if="leftMenu" class="subNav"
             v-for="(item, index) in menus.filter((item) => {return item.categoryCode == menuCategory})" :key="index">
          <ul v-for="(m, mIndex) in item.sub" :key="mIndex">
            <span>{{ m.title }}</span>
            <li v-for="(s, sIndex) in m.sub" :key="sIndex" :class="{active: s.active}">
              <a href="javascript:void(0)" @click="goMenu(s.path)">{{ s.title }}</a>
            </li>
          </ul>
        </div>
        <router-view :key="$route.fullPath" :class="{noSubNav : !leftMenu}"></router-view>
      </div>
    </div>
  </div>

</template>

<script>
import mixin from './mixin/mixin.js';

export default {
  name: 'App',
  mounted() {
    if (this.$route.name != 'index' && this.$route.name != 'login' && this.$route.name != 'resetPw' && this.$route.name != 'inquiry-popup') {
      let token = localStorage.token;
      if (typeof (token) == 'undefined' || token === null) {
        this.$router.push({name: 'login'});
      } else {
        let permission = localStorage.permission;
        let ps = JSON.stringify(permission.split(''));
        this.permission = JSON.parse(ps)
        this.permission.sort();
        this.menuCategory = this.permission[0];
        if (this.$route.name != 'login') {
          if (!this.korName) {
            this.getMyinfo();
          }
        }
      }
      this.setAllMenuOff(this.menus);
      this.setAllMenuOff(this.menus);
      this.setMenuActiveDetector(this.$route.path, this.menus);
    }
  },
  watch: {
    $route() {
      if (this.$route.name != 'index' && this.$route.name != 'login' && this.$route.name != 'resetPw' && this.$route.name != 'inquiry-popup') {
        if (!this.korName) {
          this.getMyinfo();
        }
        let permission = localStorage.permission;
        let ps = JSON.stringify(permission.split(''));
        this.permission = JSON.parse(ps)
      }
      if (this.$route.name == 'login') {
        localStorage.removeItem('token');
        localStorage.removeItem('permission');
        this.korName = null;
      }
      this.setAllMenuOff(this.menus);
      this.setMenuActiveDetector(this.$route.path, this.menus);
    }
  },
  mixins: [mixin],
  data: () => ({
    permission: '',
    menuCategory: 'A',
    page: '',
    leftMenu: true,
    menuPathList:[],
    menus: [
      {
        category: '방문신청',
        categoryCode: 'A',
        active: false,
        sub: [
          {
            title: '방문신청',
            sub: [
              {
                title: '방문고객관리',
                path: '/visit/visitor',
                active: false
              }, {
                title: '집합행사관리',
                path: '/visit/group-event',
                active: false
              }, {
                title: '병동방문객관리',
                path: '/visit/visit-patient',
                active: false
              }
            ]
          },
          {
            title: '출입자',
            sub: [
              {
                title: '협력업체 관리',
                path: '/entrance/partner/list',
                active: false
              }
            ]
          },
          {
            title: '사용자',
            sub: [
              {
                title: '권한관리',
                path: '/user/list',
                active: false
              },
            ]
          }
        ]
      },
      // {     category: '응급문진',     categoryCode: 'E',     active: true,     sub: [ {
      // title: '응급문진',             sub: [                 { title: '응급문진', path:
      // null,                     active: false                 }             ] } ]
      // },
      {
        category: '출입관리',
        categoryCode: 'B',
        active: false,
        sub: [
          {
            title: '출입',
            sub: [
              {
                title: '출입현황',
                path: '/entranceAuth/summary',
                active: false
              }, {
                title: '출입증 발급 현황',
                path: '/entranceAuth/pass',
                active: false
              },
              // {     title: '출입인증 통계분석',     path: '/statistics/entrance',     active: false
              // }
            ]
          }, {
            title: '출입자',
            sub: [
              {
                title: '환자·보호자',
                path: '/entrance/pt-nok',
                active: false
              }, {
                title: '직원·관계사',
                path: '/entrance/dept-partner',
                active: false
              }, {
                title: '방문고객',
                path: '/entrance/gstList',
                active: false
              }, {
                title: '병동방문객',
                path: '/entrance/wGstList',
                active: false
              }, {
                title: '예외출입자',
                path: '/entrance/exceptionPermitList',
                active: false
              },
              //  {
              //     title: '예외출입자(현장)',
              //     path: '/entrance/exceptionList',
              //     active: false
              // },
              // {
              //     title: '재문진 허용',
              //     path: '/entrance/permitInquiry',
              //     active: false
              // },
              // }, {
              //     title: '보호자 관리',
              //     path: '/inquriy/nok-mng',
              //     active: false
              // },
              // {
              //     title: '보호자 관리',
              //     path: '/inquriy/nok-mng2',
              //     active: false
              // }

            ]
          },
          // {
          // 	title: '마스터관리',
          // 	sub: [
          // 		{
          // 			title: '블랙리스트관리',
          // 			path: '/service/black-list',
          // 			active: false
          // 		}
          // 	]
          // }
        ]
      }, {
        category: '감염관리',
        categoryCode: 'C',
        active: false,
        sub: [

          {
            title: '문진',
            sub: [
              {
                title: '문진관리',
                path: '/inq-list',
                active: false
              },
              {
                title: '문진현황',
                path: '/inquiry/status',
                active: false
              },
              {
                title: '방문력관리',
                path: '/place/manage',
                active: false
              }, {
                title: '방문력관리(영어)',
                path: '/place/inter',
                active: false
              }, {
                title: '긴급방문력 조사',
                path: '/inquriy/emergency',
                active: false
              },
            ]
          }, {
            title: '마스터관리',
            sub: [
              {
                title: '유행감염병관리',
                path: '/service/diseases-code',
                active: false
              }
            ]
          }
        ]
      },
      {
        category: '응급관리',
        categoryCode: 'F',
        active: false,
        sub: [
          // {
          //   title: '문진',
          //   sub: [
          //     {
          //       title: '문진관리',
          //       path: '/emma/inq-list',
          //       active: false
          //     },
          //     {
          //       title: '방문력 관리',
          //       path: '/emma/place-manage',
          //       active: false
          //     },
          //   ]
          // },
          {
            title: '체류시간 관리',
            sub: [
              {
                title: '체류시간 안내문구 관리',
                path: '/emma/residenceTime',
                active: false
              },
              {
                title: '체류시간 동의내역 조회',
                path: '/emma/residenceTime/agree',
                active: false
              }
            ]
          }
        ]
      },

      {
        category: '서비스관리',
        categoryCode: 'D',
        active: false,
        sub: [

          {
            title: '기본환경',
            sub: [
              {
                title: '발열감지 내역',
                path: '/service/feverdetection',
                active: false
              }, {
                title: '단말 온도 보정',
                path: '/service/temp-adjust',
                active: false
              },
              {
                title: '장치 관리',
                path: '/service/device-list',
                active: false
              }, {
                title: '개인정보 동의내역 관리',
                path: '/privacy/list',
                active: false
              }, {
                title: '개인정보 동의내역 조회',
                path: '/privacy/agree-list',
                active: false
              }, {
                title: '민감정보 동의내역 관리',
                path: '/sensitive/list',
                active: false
              }, {
                title: '민감정보 동의내역 조회',
                path: '/sensitive/agree-list',
                active: false
              }, {
                title: '재인쇄 규칙 관리',
                path: '/service/reprintrule',
                active: false
              }
            ]
          }, {
            title: '알림서비스',
            sub: [
              {
                title: '발송규칙 관리',
                path: '/service/send-rule',
                active: false
              }, {
                title: '발송 이력조회',
                path: '/service/send-rule-list',
                active: false
              },
              // {
              //     title: '직원발열관리및조회',
              //     path: '/service/emp-inf-manage',
              //     active: false
              // },
              {
                title: '블랙리스트내원알림',
                path: '/service/black-list-notice-list',
                active: false
              },
            ]
          },
          // {     title: '마스터관리',     sub: [         {             title: '유행감염병관리',
          // path: '/service/diseases',             active: false         }, { title:
          // '분류코드관리',             path: '/service/place-class', active: false         },
          // {             title: '적용일자관리',             path: '/service/apply-date',
          // active: false         }, { title: '블랙리스트관리',             path:
          // '/service/black-list', active: false         }     ] },
          {
            title: '감사',
            sub: [
              {
                title: '접속기록 관리',
                path: '/connection/log',
                active: false
              }, {
                title: '권한변경 기록관리',
                path: '/connection/authHistory',
                active: false
              }, {
                title: '파일다운로드이력관리',
                path: '/connection/filedownHistory',
                active: false
              },
              {
                title: '제어기록관리',
                path: '/connection/controlLog',
                active: false
              }, {
                title: '협력업체 관리로그',
                path: '/connection/partnerManageLog',
                active: false
              }, {
                title: '병동방문객 조회로그',
                path: '/connection/searchList',
                active: false
              }, {
                title: '응급실 관계 삭제로그',
                path: '/emma/relation/delete',
                active: false
              }
            ]
          }

        ]
      }, {
        category: '통계',
        categoryCode: 'E',
        active: false,
        sub: [
          {
            title: '출입통계분석',
            sub: [
              {
                title: '키오스크 발급유형별(일)',
                path: '/accessStatistics/kioskTicket',
                active: false
              }, {
                title: '키오스크 발급유형별(시)',
                path: '/accessStatistics/kioskTicketHour',
                active: false
              },
              {
                title: '구역별 게이트 발열감지',
                path: '/accessStatistics/locationFeverdetection',
                active: false
              }, {
                title: '게이트 출입결과(일) ',
                path: '/accessStatistics/speedgatePassResult',
                active: false
              }, {
                title: '게이트 출입결과(시)',
                path: '/accessStatistics/entryPassResult',
                active: false
              }, {
                title: '구역별 게이트 입실(일) ',
                path: '/accessStatistics/speedgateEnter',
                active: false
              }, {
                title: '구역별 게이트 입실(시) ',
                path: '/accessStatistics/speedgateInTimePlace',
                active: false
              }, {
                title: '구역별 게이트 퇴실(일)',
                path: '/accessStatistics/speedgateExit',
                active: false
              }
              // }, {
              //     title: '구역별 게이트 퇴실(시) ',
              //     path: '/accessStatistics/speedgateOutTimePlace',
              //     active: false
              // },
              // {
              //     title: '구역별 게이트 인증',
              //     path: '/accessStatistics/speedgateCheck',
              //     active: false
              // }
            ]
          }, {
            title: '문진표통계분석',
            sub: [
              {
                title: '직원(직원및협력업체)',
                path: '/statistics/emplist',
                active: false
              }, {
                title: '직원(사번없는협력업체)',
                path: '/statistics/etclist',
                active: false
              }, {
                title: '환자',
                path: '/statistics/ptlist',
                active: false
              }, {
                title: '보호자(상주보호자)',
                path: '/statistics/rnoklist',
                active: false
              }, {
                title: '보호자(일반보호자)',
                path: '/statistics/noklist',
                active: false
              }, {
                title: '중환자실면회객',
                path: '/statistics/icugstlist',
                active: false
              }, {
                title: '병동부방문객',
                path: '/statistics/wgst',
                active: false
              }, {
                title: '방문객',
                path: '/statistics/gstlist',
                active: false
              }, {
                title: '문진유형별(일)',
                path: '/accessStatistics/answerType',
                active: false
              }, {
                title: '문진유형별(시간)',
                path: '/accessStatistics/answerTypeHour',
                active: false
              }, {
                title: '고객유형별(일)',
                path: '/accessStatistics/visitorType',
                active: false
              }, {
                title: '고객유형별(시간)',
                path: '/accessStatistics/visitorTypeHour',
                active: false
              }, {
                title: '문진결과별',
                path: '/accessStatistics/answerResult',
                active: false
              }, {
                title: '문진결과별(상세) ',
                path: '/accessStatistics/answerResultAb',
                active: false
              },
              {
                title: '수식로직별',
                path: '/accessStatistics/susicType',
                active: false
              },
              {
                title: '키오스크구역별(일)',
                path: '/accessStatistics/kioskLocation',
                active: false
              }, {
                title: '키오스크구역별(시간)',
                path: '/accessStatistics/kioskTimeType',
                active: false
              },

            ]
          }, {
            title: '알림사용통계분석서비스',
            sub: [
              {
                title: '알림사용통계분석',
                path: '/service/send-analisys',
                active: false
              }, {
                title: ' ',
                path: '/service/send-analisys',
                active: false
              }
            ]
          },
        ]
      }
      // {     category: '관리자',     categoryCode: 'M',     active: true,     sub: [ {
      // title: '세팅',             sub: [                 { title: 'API 관리', path:
      // '/service/api-list', active: false                 }, { title: '장치 등록 관리',
      // path: '/service/device-list',                     active: false }, { title:
      // '장소 등록 관리',                     path: '/service/place-list', active: false }
      // ] }     ] },
    ]
  }),
  methods: {
    goMenu: function (target) {
      this.$router.push({path: target})
    },
    goCategory: function (categoryCode) {
      this.leftMenu = true;

      this.menuCategory = categoryCode;
      let stoper = true;
      for (var i = 0; i < this.menus.length; i++) {
        // this.menus[i].active = false;
        this.$set(this.menus[i], 'active', false)
        if (this.menus[i].categoryCode == this.menuCategory) {
          this.$set(this.menus[i], 'active', true)
          for (var k = 0; k < this.menus[i].sub.length; k++) {
            var list = this.menus[i].sub[k].sub;
            for (var l = 0; l < list.length; l++) {
              if (list[l].path != null) {
                if (stoper) {
                  stoper = false;
                  this.goMenu(list[l].path);
                }
              }
            }
          }
        }
      }
    },
    // setMenuActive: function (target) {
    //   for (var i = 0; i < this.menus.length; i++) {
    //     for (var k = 0; k < this.menus[i].sub.length; k++) {
    //       var list = this.menus[i].sub[k].sub;
    //       for (var l = 0; l < list.length; l++) {
    //         list[l].active = false;
    //         if (list[l].path == target && list[l].path != null) {
    //           list[l].active = true;
    //           this.menus[i].active = true;
    //           this.menuCategory = this.menus[i].categoryCode;
    //         }
    //       }
    //     }
    //   }
    // },
    setMenuActiveDetector(path, menuObject) {
      for (const element of menuObject) {
        if (element.sub && element.sub.length > 0) {
          if (this.setMenuActiveDetector(path, element.sub)) {
            element.active = true;
            if(element.categoryCode) {
              this.menuCategory = element.categoryCode;
            }
            return true;
          }
        } else {
          if(element.path === path) {
            element.active = true;
            return true;
          }
        }
      }
    },
    setAllMenuOff(menuObject){
      for (const element of menuObject) {
        if(element.path) {
          this.menuPathList.push(element.path);
        }
        let isRegisterPath = false;
        for(let element of this.menuPathList) {
          if(this.$route.path === element){
            isRegisterPath = true;
          }
        }
        if(isRegisterPath) {
          element.active = false;
        }
        if (element.sub && element.sub.length > 0) {
          this.setAllMenuOff(element.sub);
        }
      }
    },
    logOut: function () {
      this.$store.commit('setToken', {token: null});
      localStorage.removeItem('token');
      this.goCategory(this.menuCategory);
      this.$router.push({name: 'login'});
      this.$router.go();
    },
    showLeftMenu: function () {
      if (this.leftMenu) this.leftMenu = false;
      else this.leftMenu = true;
    }
  }

};
</script>
<style>
@import './assets/css/common.css';

.noSubNav {
  padding-left: 0;
}
nav li {
  margin: 0 3%;

}
nav li.active a {

  padding: 0 10px;

}
</style>
