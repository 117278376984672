<script>
    import {Bar} from "vue-chartjs";
    export default {
        extends: Bar,
        props: ['chartData'],
        watch: {
            chartData: function(newVal){
               this.renderChart(newVal, {
                responsive: true,
                maintainAspectRatio: false
            });
            }
        },
        mounted: function () {
            console.log(this.chartData);
            this.renderChart(this.chartData, {
                responsive: true,
                maintainAspectRatio: false
            });
        }
    };
</script>