<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javscript:void(0)">문진표통계분석</a>
            </li>
            <li>
                <a href="javscript:void(0)">직원(직원및협력업체)</a>
            </li>
        </div>
        <div class="wapper" style="min-height: 700px;">
            <div class="title">
                <h2>직원(직원및협력업체)</h2>
            </div>
            <div class="infection_search">
                <dl>
                    <dt>기간 
                        <!-- <span class="ico_help">도움말</span> -->
                        </dt>
                    <dd>
                        <input type='date' v-model='startDate' @change="changeStartDate"/>
                        ~
                        <input type='date' v-model='endDate' @change="changeEndDate"/>
                    </dd>
                </dl>
                <dl>
                    <dt>구분</dt>
                    <dd>
                        <input type="checkbox" id="sort_all" name="sort_all" v-model="paramAll" @change="paramAllCheck"><label for="sort_all"><span>전체</span></label> &nbsp; &nbsp; | &nbsp; &nbsp;
                        <input type="checkbox" id="sort_1" name="sort_1" v-model="paramDiagnosis"><label for="sort_1"><span>진료과</span></label> &nbsp;
                        <input type="checkbox" id="sort_2" name="sort_2" v-model="paramIntern"><label for="sort_2"><span>인턴</span></label> &nbsp;
                        <input type="checkbox" id="sort_3" name="sort_3" v-model="paramStudent"><label for="sort_3"><span>의대학생</span></label> &nbsp;
                        <input type="checkbox" id="sort_4" name="sort_4" v-model="paramNormal"><label for="sort_4"><span>진료과 외</span></label> &nbsp;
                    </dd>
                </dl>
                <div class="search_btn">
                <button class="btn_search" @click="getList(0)">조회</button>
                </div>
            </div>
            <h3>요약 데이터
              
            </h3>
            <div class="table">
                <table>
                    <tr>
                        <th colspan="2">분류</th><th v-for="(item, index) of summary" :key="index">{{item.visitDate}}</th><th>평균</th><th>정의</th>
                    </tr>
                    <tr>
                        <td colspan="2">진료과</td>
                        <td v-for="(item, index) of summary" :key="index">{{item.diagnosisRate.toFixed(2)}}</td>
                        <td>{{smrDiagnosisAvg.toFixed(2)}}</td>
                        <td>~~과 소속</td>
                    </tr>
                    <tr>
                        <td> </td>
                        <td>의사직</td>
                        <td v-for="(item, index) of summary" :key="index">{{item.doctorInqRate.toFixed(2)}}</td>
                        <td>{{smrDoctorAvg.toFixed(2)}}</td>
                        <td>D숫자 사번</td>
                    </tr>
                    <tr>
                        <td> </td>
                        <td>의사직 외</td>
                        <td v-for="(item, index) of summary" :key="index">{{item.exceptDoctorRate.toFixed(2)}}</td>
                        <td>{{smrExceptDoctorAvg.toFixed(2)}}</td>
                        <td>D숫자 사번 외</td>
                    </tr>
                    <tr>
                        <td colspan="2">인턴</td>
                       <td v-for="(item, index) of summary" :key="index">{{item.internRate.toFixed(2)}}</td>
                       <td>{{smrInternAvg.toFixed(2)}}</td>
                        <td>교육수련실 + D숫자 사번</td>
                    </tr>
                    <tr>
                        <td colspan="2">의대학생</td>
                        <td v-for="(item, index) of summary" :key="index">{{item.studentRate.toFixed(2)}}</td>
                        <td>{{smrStudentAvg.toFixed(2)}}</td>
                        <td>ST숫자 사번</td>
                    </tr>
                    <tr>
                        <td colspan="2">진료과 외</td>
                        <td v-for="(item, index) of summary" :key="index">{{item.normalEmpRate.toFixed(2)}}</td>
                        <td>{{smrNormalEmpAvg.toFixed(2)}}</td>
                        <td>~~과 외 소속</td>
                    </tr>
                    <tfoot>
                        <tr>
                            <td colspan="2">전직원</td>
                            <td v-for="(item, index) of summary" :key="index">{{ item.totalRate.toFixed(2)}}</td> 
                            <td>{{smrTotalAvg.toFixed(2)}}</td>                    
                            <td>전체직원</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <h3>부서별 데이터
                  <div class="btn-right">
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                </div>

            </h3>
            <div class="stickyHeader">
                <div class="dummyTop">

                </div>
                 <div class="table dept">
                    <table>
                        <colgroup>
                            <col width="20%"/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th rowspan="2">부서명</th><th colspan="3">의사직</th><th colspan="3">의사직 외</th><th colspan="3">전체</th>
                            </tr>
                            <tr>
                                <th>작성<br/>(명)</th><th>전체<br/>(명)</th><th>작성률<br/>(%)</th><th>작성<br/>(명)</th><th>전체<br/>(명)</th><th>작성률<br/>(%)</th><th>작성<br/>(명)</th><th>전체<br/>(명)</th><th>작성률<br/>(%)</th>
                            </tr>
                        </thead>
                        <tr v-for="(item, index) of dept" :key="index">
                            <td>{{item.deptNm}}</td>                   
                            <td>{{item.doctorInq == 0 ? 0: item.doctorInq.toFixed(1) }}</td>     
                            <td>{{item.doctorTotal == 0 ? 0: item.doctorTotal.toFixed(1) }}</td>     
                            <td :class="{underAvg:checkUnderAvg(item.doctorInqRate)}">{{item.doctorInqRate == 100 ? 100 : (item.doctorInqRate == 0 ? 0: item.doctorInqRate.toFixed(2)) }}</td>                       
                            <td>{{item.exceptDoctorInq == 0 ? 0: item.exceptDoctorInq.toFixed(1) }}</td>     
                            <td>{{item.exceptDoctorTotal == 0 ? 0: item.exceptDoctorTotal.toFixed(1) }}</td>     
                            <td :class="{underAvg:checkUnderAvg(item.exceptDoctorRate)}">{{item.exceptDoctorRate == 100 ? 100 : (item.exceptDoctorRate == 0 ? 0: item.exceptDoctorRate.toFixed(2)) }}</td>                        
                            <td>{{item.totalInq == 0 ? 0: item.totalInq.toFixed(1) }}</td>     
                            <td>{{item.total == 0 ? 0: item.total.toFixed(1) }}</td>     
                            <td  :class="{underAvg:checkUnderAvg(item.totalRate)}">{{item.totalRate == 100 ? 100 : (item.totalRate == 0 ? 0: item.totalRate.toFixed(2)) }}</td>
                        </tr>                  
                    </table>
                </div>
            </div>           
        </div>
    </div>

</template>
<script>
    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';

    export default {
        data: () => {
            return {
                summary: [],
                dept: [],
                startDate: moment().subtract(1, 'days').format("YYYY-MM-DD"),
                endDate: moment().subtract(1, 'days').format("YYYY-MM-DD"),
                smrDiagnosisAvg : 0,
                smrDoctorAvg : 0,
                smrExceptDoctorAvg : 0,
                smrNormalEmpAvg: 0,
                smrInternAvg: 0,
                smrStudentAvg : 0,
                smrTotalAvg : 0,
                paramAll : true,
                paramDiagnosis: true,
                paramIntern : true,
                paramStudent:true,
                paramNormal: true,
            }
        },
        mixins: [mixin],
        mounted: function () {
            this.getList(0);
        },
        watch: {           
            paramDiagnosis(){
                if(this.paramDiagnosis && this.paramIntern && this.paramStudent && this.paramNormal){
                    this.paramAll = true;
                } else {
                    this.paramAll = false;
                }
            },
            paramIntern(){
                if(this.paramDiagnosis && this.paramIntern && this.paramStudent && this.paramNormal){
                    this.paramAll = true;
                } else {
                    this.paramAll = false;
                }
            },
            paramStudent(){
                if(this.paramDiagnosis && this.paramIntern && this.paramStudent && this.paramNormal){
                    this.paramAll = true;
                } else {
                    this.paramAll = false;
                }
            },
            paramNormal(){
                if(this.paramDiagnosis && this.paramIntern && this.paramStudent && this.paramNormal){
                    this.paramAll = true;
                } else {
                    this.paramAll = false;
                }
            }
        },
        methods: {
            getList: function () {      
                
                if(this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                
                 if(this.searchConditionChecker()) {
                     this.openAlert("한개 이상의 구분을 선택하세요.");
                      this.btnLock = false;
                     return;
                 } 

                const param = {
                    startDate: this.startDate,
                    endDate: this.endDate,
                    paramDiagnosis: this.paramDiagnosis  ? 'Y' :'N' ,
                    paramIntern: this.paramIntern ? 'Y' :'N',
                    paramStudent: this.paramStudent ? 'Y' :'N',
                    paramNormal: this.paramNormal ? 'Y' :'N'
                }
                this
                .$axios
                .get('/api/statistics/analysis/getEmplist',{
                    params: param
                })
                .then((response) => {
                     this.btnLock = false;
                    if (response.data.success) {
                        this.summary = response.data.result.summaryEmp;
                        this.dept = response.data.result.deptList;

                        let smrDiagnosisAvg = 0;
                        let smrDoctorAvg = 0;
                        let smrExceptDoctorAvg  = 0;
                        let smrNormalEmpAvg  = 0;
                        let smrInternAvg  = 0;
                        let smrStudentAvg   = 0;
                        let smrTotalAvg  = 0;

                        let listSize = this.summary.length ? this.summary.length : 1;


                        for(let item of this.summary) {
                            smrDiagnosisAvg += item.diagnosisRate;
                            smrDoctorAvg += item.doctorInqRate;
                            smrExceptDoctorAvg += item.exceptDoctorRate;
                            smrNormalEmpAvg += item.normalEmpRate;
                            smrInternAvg += item.internRate;
                            smrStudentAvg += item.studentRate;
                            smrTotalAvg += item.totalRate;
                        }

                        this.smrDiagnosisAvg = (smrDiagnosisAvg / listSize);
                        this.smrDoctorAvg = (smrDoctorAvg / listSize);
                        this.smrExceptDoctorAvg = (smrExceptDoctorAvg / listSize);
                        this.smrNormalEmpAvg = (smrNormalEmpAvg / listSize);
                        this.smrInternAvg = (smrInternAvg / listSize);
                        this.smrStudentAvg = (smrStudentAvg / listSize);
                        this.smrTotalAvg = (smrTotalAvg / listSize);


                    } else {
                        this.openAlert("리스트 조회에 실패했습니다.");
                    }
                    
                })
                .catch(() => {
                    this.openAlert("리스트 조회에 실패했습니다.");
                    this.btnLock = false;
                });
            },
            confirmDownload: function () {
                this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {                
                const param = {
                    page: 0,
                    size: 2000,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    paramDiagnosis: this.paramDiagnosis  ? 'Y' :'N' ,
                    paramIntern: this.paramIntern ? 'Y' :'N',
                    paramStudent: this.paramStudent ? 'Y' :'N',
                    paramNormal: this.paramNormal ? 'Y' :'N'
                }
                this
                .$axios
                .get('/api/statistics/analysis/emplist/excel', {
                    params: param,
                    responseType: "blob"
                })
                .then((response) => {
                    console.log(response);
                    const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download','문진표통계분석_직원_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx');
                    document.body.appendChild(link);
                    link.click();

                    if(response.status == 200) {
                          let param = {
                              fileName: '문진표통계분석_직원_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                              fileSize: response.data.size,
                              purpose: paramdata.purpose,
                              purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                          }
                          this.setDownloadHistory(param);
                    }
                })
            },
            changeStartDate(event) {
                this.startDate = event.target.value;
            },
            changeEndDate(event) {
                this.endDate = event.target.value;
            },           
           checkUnderAvg(targetAvg) {
                if(this.smrTotalAvg) {
                    if(targetAvg != 0) {
                        if(targetAvg < this.smrTotalAvg) {
                            return true;
                        }
                    }

                }
                return false;
            },
            paramAllCheck() {
                if(this.paramAll) {
                    this.paramDiagnosis = true;
                    this.paramIntern = true;
                    this.paramStudent = true;
                    this.paramNormal = true;
                } else {
                    this.paramDiagnosis = false;
                    this.paramIntern = false;
                    this.paramStudent = false;
                    this.paramNormal = false;
                }
             
            },  
            searchConditionChecker: function() { 


                if((!this.paramDiagnosis && !this.paramIntern && !this.paramNormal && !this.paramStudent )) {
                   return true;
                } else {
                    return false;
                }
            }

        }
    }
</script>
<style scoped> 

    .stickyHeader  {
        position: absolute;
        top: 0;
        width: calc(100% - 270px);
        z-index: -1;
    }

    .dummyTop {
        height: 838px;
    }

    .dept table thead {
        position: sticky;
        top: -1px;
        z-index: 5;
        height: 50px;
        border: solid 1px #e8e9eb;
        min-width: 200px;
    }

    .dept table {
            border-collapse: separate !important;
            border-spacing: 0px !important;
    }
    .dept table td {
         border: solid 0.5px #e8e9eb;
    }
    
    .underAvg {
        background-color: #ffe0e0;
    }

</style>
