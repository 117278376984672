<template>
  <div class="contents">
    <div class="loc">
      <li>
        <a href="">기본환경</a>
      </li>
      <li>
        <a href="">개인정보 동의내역 관리</a>
      </li>
    </div>
    <div class="wapper">
      <div class="title">
        <h2>개인정보 동의내역 관리</h2>
        <div class="btn-right">
          <button class="btn_primary" @click="getPrevList()">이전 버전 불러오기</button>
        </div>
      </div>
      <div class="details">
        <div class="detail_conts">
          <h4>한국어 버전 </h4>
          <blockquote>문진에서 한국어 선택시 보여집니다.</blockquote>
          <div id="editor"></div>
        </div>
        <div class="detail_conts">
          <h4>영문 버전 </h4>
          <blockquote>문진에서 한국어 이외의 언어를 선택시 보여집니다.</blockquote>
          <div id="editor2"></div>
        </div>
      </div>
      <div class="title">
        <div class="btn-right">
          <button class="btn_primary" @click="savePrivacy()">저장</button>
          <button class="btn_secondary" @click="goBack()">취소</button>
        </div>
      </div>
    </div>
    <modal
        name="alertMsg"
        :width="540"
        :height="190"
        :adaptive="true"
        :clickToClose="false">
      <div id="popup_reg" class="pop pop_md">
        <div class="pop_head">
          <h2>알림</h2>
          <a class="btn_pop_close" @click="closePop('alertMsg')">닫기</a>
        </div>
        <div class="pop_body">
          {{ errMsg }}
        </div>
        <div class="pop_footer">
          <button class="btn_secondary" @click="closePop('alertMsg')">확 인</button>
        </div>
      </div>
    </modal>
    <modal
        name="success"
        :width="540"
        :height="190"
        :adaptive="true"
        :clickToClose="false"
        @closed="goBack">
      <div id="popup_reg" class="pop pop_md">
        <div class="pop_head">
          <h2>알림</h2>
          <a class="btn_pop_close" @click="closePop('success')">닫기</a>
        </div>
        <div class="pop_body">
          {{ errMsg }}
        </div>
        <div class="pop_footer">
          <button class="btn_secondary" @click="closePop('success')">확 인</button>
        </div>
      </div>
    </modal>
    <modal
        name="popup_prev_road"
        :width="320"
        :height="228"
        :adaptive="true"
        :clickToClose="false">
      <div id="popup_prev_road" class="pop pop_sm">
        <!-- 새 문진 등록 -->
        <div class="pop_head">
          <h2>이전 버전 불러오기</h2>
          <a class="btn_pop_close" @click="closePop('popup_prev_road')">닫기</a>
        </div>
        <div class="pop_body">
          <dl>
            <dt>버전 선택</dt>
            <dd>
              <select @change="selectPrevVer($event)">
                <option value="" disabled="disabled" selected="selected">버전</option>
                <option v-for="(item, index) in prevList" :key="index" :value="item.code">{{ item.ver }}
                </option>
              </select>
            </dd>
          </dl>
        </div>
        <div class="pop_footer">
          <button class="btn_secondary" id="btn_reg_cancel" @click="closePop('popup_prev_road')">취 소</button>
          <button class="btn_primary" @click="getPrivacyInfo()">확인</button>
        </div>
      </div>
    </modal>
  </div>

</template>
<script>
import 'codemirror/lib/codemirror.css'; // Editor's Dependency Style
import '@toast-ui/editor/dist/toastui-editor.css'; // Editor's Style
import 'tui-color-picker/dist/tui-color-picker.css';


import Editor from '@toast-ui/editor';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';


export default {
  mounted() {
    const editor = new Editor({
      el: document.querySelector('#editor'),
      height: '700px',
      language: 'ko',
      initialEditType: 'wysiwyg',
      previewStyle: 'vertical',
      plugins: [colorSyntax]
    });

    const editor2 = new Editor({
      el: document.querySelector('#editor2'),
      height: '700px',
      language: 'en',
      initialEditType: 'wysiwyg',
      previewStyle: 'vertical',
      plugins: [colorSyntax]
    });

    editor.getHtml();
    this.editor = editor;

    editor2.getHtml();
    this.editor2 = editor2;
  },


  data: () => {
    return {
      editor: null,
      editor2: null,
      errMsg: null,
      btnLock: false,
      prevList: [],
      prevVer : null,
    }
  },
  methods: {
    goBack: function () {
      this
          .$router
          .go(-1);
          // .push({name: 'privacy-list'});
    },
    savePrivacy: function () {
      if (this.btnLock) {
        return;
      } else {
        this.btnLock = true;
      }

      let text = this.editor.getHtml();
      let text2 = this.editor2.getHtml();
      if (text.length <= 0) {
        this.errMsg = "한국어 내용을 입력하세요.";
        this
            .$modal
            .show('alertMsg');
        return;
      }
      if (text2.length <= 0) {
        this.errMsg = "영어 내용을 입력하세요.";
        this
            .$modal
            .show('alertMsg');
        return;
      }


      this
          .$axios
          .post('/api/mng/v1/privacy', {privacyContents: text, privacyContentsEng: text2})
          .then((response) => {

            if (response.data.success) {
              this.errMsg = "저장이 완료되었습니다. \n 리스트 페이지로 이동합니다.";
              this
                  .$modal
                  .show('success');
            } else {

              this.errMsg = "저장중 오류가 발생했습니다.";
              this
                  .$modal
                  .show('alertMsg');
            }
            this.btnLock = false;

          })
          .catch((err) => {
            this.btnLock = false;
            this.errMsg = err.response.data;
            this
                .$modal
                .show('alertMsg');
          })
    },
    async getPrevList() {
      await this.getPrivacy();
      this.$modal.show('popup_prev_road');
    },
    async getPrivacy() {
      let param = {
        page: 0,
        size: 100,
        startDate: this.startDate,
        endDate: this.endDate,
      }

      await this
          .$axios
          .get('/api/mng/privacy', {
            params: param
          })
          .then((response) => {
            console.log(response.data);
            this.list = [];
            this.pageNation = [];
            this.page = {};
            let connList = response.data.connCount;
            let agreeList = response.data.agreeCount;
            let privacyList = response.data.result.content;
            for (var i = 0; i < privacyList.length; i++) {
              let obj = {
                code: privacyList[i].privacyCode,
                ver: privacyList[i].privacyVer,
                connect: 0,
                agree: 0,
                regUser: privacyList[i].regUser,
                regDate: privacyList[i].regDate
              }
              for (var k = 0; k < connList.length; k++) {
                console.log("coount", connList[k].connCount);
                if (privacyList[i].privacyCode == connList[k].privacyCode) {
                  console.log("innerCnt", connList[k].connCount);
                  obj.connect = connList[k].connCount;
                  continue;
                }
              }

              for (var l = 0; l < agreeList.length; l++) {
                console.log("agree", agreeList[l].connCount);
                if (privacyList[l].privacyCode == agreeList[l].privacyCode) {
                  console.log("innerCnt", agreeList[l].agreeCount);
                  obj.agree = agreeList[i].agreeCount;
                  continue;
                }
              }
              console.log("obj", obj);
              this.prevList.push(obj);
            }
          })
          .catch();


    },
    selectPrevVer(event) {
      let code = event.target.value;
      if(code) {
          this.prevVer = code;
      }
    },
    async getPrivacyInfo() {
      if(this.prevVer) {
        await this
            .$axios
            .get('/api/mng/env/v1/privacy', {
              params: {
                privacyCode: this.prevVer
              }
            })
            .then((response) => {
              this.editor.reset();
              console.log(response.data.result);
              if (response.data.success) {
                console.log(response.data.result);
                this.editor.setHtml(response.data.result.privacyContents);
                this.editor2.setHtml(response.data.result.privacyContentsEng);
              } else {
                this.openAlert("개인정보 정보를 가져오는 도중 오류가 발생했습니다.");
              }
            })
            .catch(() => {
              this.openAlert("개인정보 정보를 가져오는 도중 오류가 발생했습니다.");
            })
      }
      this.prevVer = null;
      this.$modal.hide('popup_prev_road')
    },
    closePop: function (target) {
      this.errMsg = null;
      this
          .$modal
          .hide(target);
    },
  }
};
</script>
<style scoped="scoped">
#popup_reg {
  display: inline-block;
}

.details {
  padding: 20px 0;
  display: flex;
}

.details .detail_conts {
  width: 48%;
  margin: 0 1%;
}

.pop {
  display: inline-block;
}

</style>
