<template>

    <div class="contents">
        <div class="loc">
            <li>
                <a href="javscript:void(0)">방문신청</a>
            </li>
            <li>
                <a href="javscript:void(0)">방문고객관리</a>
            </li>
            <li>
                <a href="javscript:void(0)">방문고객 재등록</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>방문고객 재등록</h2>
            </div>
            <div class="register">
                <dl>
                    <dt>방문객 유형</dt>
                    <dd>
                        <input
                            type="radio"
                            name="check_radio"
                            id="gst"
                            value="GST"
                            v-model="type"
                            onclick="return(false);"/>
                        <label for="gst" style="display:inline-block">
                            <span>일반방문</span>
                        </label>
                        <input
                            type="radio"
                            name="check_radio"
                            id="pt"
                            value="PT"
                            v-model="type"
                            onclick="return(false);"/>
                        <label for="pt" style="display:inline-block; margin-left: 30px">
                            <span>환자</span>
                        </label>
                        <input
                            type="radio"
                            name="check_radio"
                            id="nok"
                            value="NOK"
                            v-model="type"
                            onclick="return(false);"/>
                        <label for="nok" style="display:inline-block; margin-left: 30px">
                            <span>보호자</span>
                        </label>
                        <input type="radio" name="check_radio" id="pg" value="PG" v-model="type" onclick="return(false);"/>
                        <label for="pg" style="display: inline-block; margin-left: 30px">
                            <span>병동방문객</span>
                        </label>
                        <!-- <label for="etc" style="display:inline-block; margin-left: 30px"> <span>1회권
                        (긴급/예외, 건진, 입퇴원, 임종 등)</span> </label> -->
                    </dd>
                </dl>
                <dl>
                    <dt>출입증 유형 (유효기간)</dt>
                    <dd>
                        <input
                            type="radio"
                            name="check_radio_type"
                            id="oneday"
                            value="oneday"
                            v-model="entryType"
                            onclick="return(false);"/>
                        <label for="oneday" style="display:inline-block">
                            <span>당일권</span>
                        </label>
                        <input
                            type="radio"
                            name="check_radio_type"
                            id="allday"
                            value="allday"
                            v-model="entryType"
                            onclick="return(false);"/>
                        <label for="allday" style="display:inline-block; margin-left: 30px">
                            <span>24시권</span>
                        </label>
                        <input
                            type="radio"
                            name="check_radio_type"
                            id="onetime"
                            value="onetime"
                            v-model="entryType"
                            onclick="return(false);"/>
                        <label for="onetime" style="display:inline-block; margin-left: 30px">
                            <span>1회권</span>
                        </label>
                    </dd>
                </dl>
                <dl v-if="type != 'GST' && type != 'ETC' ">
                    <dt>병원등록번호</dt>
                    <dd>
                        <input
                            type="text"
                            v-model="patno"
                            placeholder="환자의 병원등록 번호를 입력한 후 조회를 눌러주세요."
                            readonly="readonly"/>
                        <button class="btn_secondary" @click="findPatNo">조회</button>
                    </dd>
                </dl>
                <dl v-if="(type == 'NOK') || (type =='PG')">
                    <dt>환자</dt>
                    <dd>
                        <input type="text" v-model="patName" readonly="readonly"/>
                    </dd>
                </dl>
                <dl v-if="(type == 'NOK') || (type =='PG')">
                    <dt>관계</dt>
                    <dd>
                        <input
                            type="text"
                            v-model="relation"
                            placeholder="환자와의 관계를 입력하세요."
                            readonly="readonly"/>
                    </dd>
                </dl>
                <dl>
                    <dt>이름</dt>
                    <dd><input
                        type="text"
                        v-if="type != 'PT'"
                        v-model="name"
                        readonly="readonly"
                        placeholder="방문할 방문자의 이름을 입력하세요."/>
                        <input
                            type="text"
                            v-model="name"
                            v-if="type == 'PT'"
                            readonly="readonly"
                            placeholder="방문할 방문자의 이름을 입력하세요."/>

                    </dd>
                </dl>
                <dl>
                    <dt>휴대전화번호</dt>
                    <dd><input
                        type="text"
                        v-model="phone"
                        placeholder="모바일 문진을 수신 받을 전화번호를 입력하세요."
                        readonly="readonly"/></dd>
                </dl>
                <dl>
                    <dt>내원(예정)일</dt>
                    <dd>
                        <date-picker
                            :dateParam.sync="reserveDate"
                            @dateVal="reserveDateFunc"
                            style="width: 150px; height:40px;"></date-picker>
                    </dd>
                </dl>
                <dl>
                    <dt>내원목적</dt>
                    <dd>
                        <select v-if=" type != 'PG'" class="width_select" v-model="purpose" @change="changePurpose">
                            <option v-for="item in purposelist" :value="item.value" :key="item.value">
                                {{ item.text }}
                            </option>
                        </select>
                        <select v-if=" type == 'PG' " class="width_select" v-model="purpose" @change="changePurpose">
                            <option v-for="item in visitorList" :value="item.value" :key="item.value">
                                {{ item.text }}
                            </option>
                        </select>
                    </dd>
                </dl>
                <dl v-show="etcShow">
                    <dt>내원 목적 수기 입력</dt>
                    <dd>
                        <input
                            ref="etcInput"
                            v-model="purpose_etc_text"
                            type="text"
                            placeholder="기타 내원 목적을 수기로 입력하는 입력 필드입니다."/>
                    </dd>
                </dl>
                <dl>
                    <dt>내원장소</dt>
                    <dd><input
                        type="text"
                        v-model="place"
                        placeholder="내원 장소를 입력하세요."
                        readonly="readonly"/></dd>
                </dl>
                <dl>
                    <dt>소속</dt>
                    <dd><input type="text" v-model="visitDeptName" readonly="readonly"/></dd>
                </dl>
                <dl v-if="type != 'PT'">
                    <dt>임시출입카드번호</dt>
                    <dd><input type="text" v-model="cardNo" placeholder="병동 출입시에만 입력"/></dd>
                </dl>
                <dl>
                    <dt>PCR검사 여부 확인</dt>
                    <dd><input v-model="pcrYn" type="checkbox" id="pcrYn" onclick="return(false);"/>
                        <label for="pcrYn">
                            <span></span>
                        </label>
                    </dd>
                </dl>
                <dl>
                    <dt>백신접종 여부 확인</dt>
                    <dd><input v-model="vaccYn" type="checkbox" id="vaccYn" onclick="return(false);"/>
                        <label for="vaccYn">
                            <span></span>
                        </label>
                    </dd>
                </dl>
                <dl v-if="type == 'PG'">
                    <dt>개인정보 수집/활용 동의</dt>
                    <dd>
                        <input v-model="acceptYn" type="checkbox" id="acceptYn" />
                        <label for="acceptYn"><span></span></label>
                    </dd>
                </dl>
            </div>
            <div class="btn_wrap">
                <div class="btn_left">
                    <button class="btn_secondary" @click="goPrev()">취 소</button>
                    <button class="btn_primary" @click="saveConfirm()">저 장</button>
                </div>

            </div>
        </div>
    </div>

</template>
<script>
    import mixin from '../../mixin/mixin.js';
    import datePicker from '../../components/datePicker.vue';
    import moment from 'moment';
    import $ from 'jquery'
    export default {
        data: () => {
            return {
                reserveDate: moment().format("YYYY-MM-DD"),
                name: null,
                phone: null,
                type: 'GST',
                entryType: 'oneday',
                purpose: "",
                place: null,
                cardNo: null,
                pcrYn: false,
                vaccYn: false,
                visitDeptName: '',
                patno: null,
                patName: null,
                patPhone: null,
                relation: null,
                purpose_etc_text: null,
                etcShow: false,
                acceptYn: false,
                patNoCheckPass: true,
                purposelist: [
                    {
                        value: "",
                        text: "내원 목적 선택"
                    }, {
                        value: "배송 및 납품",
                        text: "배송 및 납품"
                    }, {
                        value: "기기 점검 및 수리",
                        text: "기기 점검 및 수리"
                    }, {
                        value: "용역 및 위탁",
                        text: "용역 및 위탁"
                    }, {
                        value: "공사 및 보수",
                        text: "공사 및 보수"
                    }, {
                        value: "상담, 교육 및 처치 (환자, 보호자)",
                        text: "상담, 교육 및 처치 (환자, 보호자)"
                    }, {
                        value: "분만장",
                        text: "분만장"
                    }, {
                        value: "임종 환자 면회 보호자",
                        text: "임종 환자 면회 보호자"
                    }, {
                        value: "입, 퇴원시 물품 이송",
                        text: "입, 퇴원시 물품 이송"
                    }, {
                        value: "제약 회사",
                        text: "제약 회사"
                    }, {
                        value: "회의",
                        text: "회의"
                    }, {
                        value: "의무기록 사본발급(보험사)",
                        text: "의무기록 사본발급(보험사)"
                    }, {
                        value:"정기면회",
                        text:"정기면회"
                    }, {
                        value: 'premium',
                        text: '프리미엄 숙박건진'
                    }, {
                        value: "etc",
                        text: "기타(수기 입력)"
                    }
                ],
                visitorList: [
                    {
                        value: "",
                        text: "내원 목적 선택"
                    }, {
                        value: "입퇴원 짐 이송",
                        text: "입퇴원 짐 이송"
                    }, {
                        value: "임종 면회",
                        text: "임종 면회"
                    }, {
                        value: "주치의 상담",
                        text: "주치의 상담"
                    }, {
                        value:"정기면회",
                        text:"정기면회"
                    }, {
                        value: "etc",
                        text: "기타(수기 입력)"
                    }
                    
                ],

                visitorInfo: null,
                isNok: null,
                visitPlace: null
            }
        },

        mixins: [mixin],
        components: {
            'date-picker': datePicker
        },
        created: function () {
            this.getMyinfo();
        },
        mounted: function () {
            // this.visitorInfo = this.$route.query.info;
            let result = this.$route.query.info;
            this.reserveDate = new Date()
                .toISOString()
                .substring(0, 10);
            this.name = result.name;
            this.phone = this.convertPhone(result.phone);
            this.type = result.isNok == "Y" ? "PG" : result.type;
            this.entryType = result.entryType;
            

            // 내원목적 수기입력 체크
            let etcYn = true;
            for (var i = 0; i < this.purposelist.length; i++) {
                let item = this.purposelist[i];
                if (item.value == result.purpose) {
                    etcYn = false;
                }
            }

            if (etcYn) {
                this.purpose = "etc"
                this.purpose_etc_text = result.purpose;
                this.etcShow = true;
            } else {
                this.purpose = result.purpose;
                this.etcShow = false;
            }

            this.place = result.place;
            this.cardNo = result.cardNo;
            this.pcrYn = result.pcrYn == "Y"
                ? true
                : false;
            this.vaccYn = result.vaccYn == "Y"
                ? true
                : false;
            this.patno = result.patno;
            this.relation = result.relation;
            if (this.type != 'GST' && this.type != 'ETC' && this.type != 'PG')  {
                this.findPatNo();
            } else if (this.type == 'PG') {
                this.findPatNoPG()
            }
        }
        ,
        watch: {
            type: function (newVal) {
                if (newVal == "PT") {
                    this.cardNo = null;
                } else if (newVal == "GST" || newVal == "ETC") {
                    this.patno = null;
                }
            },
            phone: function (newVal) {
                console.log(newVal);
                newVal = newVal.replace(/-/gi, "");
                console.log("remove", newVal);
                if (this.phone.length > 11) {
                    newVal = newVal.substring(0, 11);
                }
                newVal = this.convertPhone(newVal);
                console.log("convert", newVal);
                this.phone = newVal;

            },
            userDeptName: function (newVal) {
                console.log(newVal);
                if (newVal) {
                    this.visitDeptName = newVal;
                }
            }
        },
        methods: {
            reserveDateFunc: function (val) { //날짜선텍 콤포넌트에서 선택된 날짜를 세팅한다.
                this.reserveDate = val;
            },
            goPrev: function () {
                this
                    .$router
                    .go(-1);
                    // .push({name: 'visitor'});
            },
            findPatNo: function () {
                if (this.patno == null) {
                    this.openAlert("환자의 병원 등록번호를 입력하세요.");
                    return;
                }
                if (this.patno.trim() == null) {
                    this.openAlert("환자의 병원 등록번호를 입력하세요.");
                    return;
                }

                let visitDate = this
                    .reserveDate
                    .replaceAll("-", "");

                this
                    .$axios
                    .get('/api/v2/identity/patient', {
                        params: {
                            identityNo: this.patno,
                            visitDate: visitDate
                        }
                    })
                    .then((response) => {
                        if (response.data.success) {
                            let result = response.data.result;
                            console.log(result.ptName);
                            if (this.type == 'PT') {
                                this.name = result.ptName
                                this.phone = result.mpbhNo;
                            } else {
                                this.patName = result.ptName;
                                this.patPhone = result.mpbhNo;
                            }

                        } else {
                            this.openAlert(response.data.resultMsg);
                        }
                    })
                    .catch(() => {
                        this.openAlert("환자 정보 조회중 오류가 발생했습니다.");
                    })

            },
            findPatNoPG: function(){
                if (this.patno == null) {
                    this.openAlert("환자의 병원 등록번호를 입력하세요.");
                    return;
                }
                if (this.patno.trim() == null) {
                    this.openAlert("환자의 병원 등록번호를 입력하세요.");
                    return;
                }

                this.$axios
                    .get("/api/v1/identity/inpatient",  {
                    params: {
                        param: this.patno,
                    },
                    } )
                    .then((response) => {
                    if (response.data.success) {
                        let result = response.data.result;
                        console.log(result.ptName);
                        if (this.type == "PT") {
                        this.name = result.patNm;
                        this.phone = result.rprnTelno;
                        } else {
                        this.patName = result.patNm;
                        this.patPhone = result.rprnTelno;
                        this.visitPlace = result.locKorNm;
                        }
                    }else if(response.data.resultCd == '300'){
                        this.openAlertClosed("방문하려는 환자가 재원중이 아닙니다.",this.goPrev());
                    } else {
                        this.openAlertClosed(response.data.resultMsg,this.goPrev());
                    }
                    })
                    .catch((e) => {
                        console.log(e)
                        this.openAlert("환자 정보 조회중 오류가 발생했습니다.");
                    });
            },
            getInfo: function (seq) {
                console.log(seq);
                this
                    .$axios
                    .get('/api/mng/visitor/v1/visitor', {
                        params: {
                            seq: seq
                        }
                    })
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.success) {
                            let result = response
                                .data
                                .result
                                .content[0];
                            console.log(result);
                            this.reserveDate = new Date()
                                .toISOString()
                                .substring(0, 10);
                            this.name = result.name;
                            this.phone = this.convertPhone(result.phone);
                            this.type = result.type;
                            this.entryType = result.entryType;

                            // 내원목적 수기입력 체크
                            let etcYn = true;
                            for (var i = 0; i < this.purposelist.length; i++) {
                                let item = this.purposelist[i];
                                if (item.value == result.purpose) {
                                    etcYn = false;
                                }
                            }

                            if (etcYn) {
                                this.purpose = "etc"
                                this.purpose_etc_text = result.purpose;
                                this.etcShow = true;
                            } else {
                                this.purpose = result.purpose;
                                this.etcShow = false;
                            }

                            this.place = result.place;
                            this.cardNo = result.cardNo;
                            this.pcrYn = result.pcrYn == "Y"
                                ? true
                                : false;
                            this.vaccYn = result.vaccYn == "Y"
                                ? true
                                : false;
                            this.patno = result.patno;
                            this.relation = result.relation;
                            if (this.type != 'GST' && this.type != 'ETC') 
                                this.findPatNo();

                            }
                        else {
                            this.openAlert("방문고객을 불러오는 도중 오류가 발생하였습니다.");
                        }
                    })
                    .catch(() => {
                        this.openAlert("방문고객을 불러오는 도중 오류가 발생하였습니다.");
                    });
            },
            PatNoCheck: function () {
                
                let visitDate = this
                    .reserveDate
                    .replaceAll("-", "");

                return this
                    .$axios
                    .get('/api/v2/identity/patient', {
                        params: {
                            identityNo: this.patno,
                            visitDate: visitDate
                        }
                    })
                    .then((response) => {
                        if (response.data.success) {
                            console.log("this is true")
                            return true;
                        } else {
                            console.log("this is false")
                            return false;
                        }
                    })
                    .catch(() => {
                        return false;
                    })

            },
            saveConfirm: async function () {
                let list = [
                    {
                        id: this.name,
                        name: '방문자 이름'
                    }, {
                        id: this.phone,
                        name: '휴대전화번호'
                    }, {
                        id: this.purpose == "etc"
                            ? this.purpose_etc_text
                            : this.purpose,
                        name: '내원목적'
                    }, {
                        id: this.place,
                        name: '내원장소'
                    }, {
                        id: this.visitDeptName,
                        name: '소속'
                    }
                ];

                if (!this.nullChecker(list)) {
                    return;
                }
                if (this.name.length > 20) {
                    this.openAlert("방문자는 20자를 넘을 수 없습니다.");
                    return;
                }
                console.log(
                    /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/.test(this.phone)
                );
                if (!/^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/.test(this.phone)) {
                    this.openAlert("올바른 핸드폰 형식을 입력해 주세요.");
                    return;
                }
                this.phone = this
                    .phone
                    .replace(/-/gi, "")
                    .trim();
                if (this.phone.length > 11) {
                    this.openAlert("휴대전화번호는 11자를 넘을 수 없습니다.");
                    return;
                }
                if (this.purpose.length > 50) {
                    this.openAlert("내원목적은 50자를 넘을 수 없습니다.");
                    return;
                }
                if (this.place.length > 50) {
                    this.openAlert("내원장소는 50자를 넘을 수 없습니다.");
                    return;
                }
                if (this.visitDeptName.length > 20) {
                    this.openAlert("방문부서는 20자를 넘을 수 없습니다.");
                    return;
                }

                if (this.patno != null) {
                    if (this.patno.length > 10) {
                        this.openAlert("환자번호는 10자를 넘을 수 없습니다.");
                        return;
                    }
                }
                if (this.cardNo != null && this.cardNo != "") {
                    if (this.cardNo.length > 7) {
                        this.openAlert("카드번호는 7자를 넘을 수 없습니다.");
                        return;
                    }
                    this.cardNo = this
                        .cardNo
                        .toUpperCase();

                    // 앞자리 2자는 영문, 뒷자리 5자리는 숫자만 입력 가능
                    let cardNo1 = this
                        .cardNo
                        .substring(0, 2);
                    let cardNo2 = this
                        .cardNo
                        .substring(2, 7);
                    let reg1 = /^[a-z|A-Z|\*]+$/;
                    let reg2 = /^[0-9|\*]+$/;

                    if (!reg1.test(cardNo1)) {
                        this.openAlert("앞자리 2자리는 영문만 입력 가능합니다.")
                        return;
                    }
                    if (!reg2.test(cardNo2)) {
                        this.openAlert("뒷자리 5자리는 숫자만 입력 가능합니다.")
                        return;
                    }
                }

                // 오늘 날짜와 선택한 날짜를 비교
                if (moment(moment().format("YYYY-MM-DD")).isAfter(this.reserveDate)) {
                    this.openAlert("내원(예정)일은 오늘 이후 날짜로 지정하여야 합니다");
                    return;
                }

                if (this.type=='PG' && !this.acceptYn ){
                    this.openAlert("개인정보 수집/활용에 동의하셔야 합니다.");
                    return;
                }
                
                if(this.type == 'PT' || this.type == 'NOK') {
                    await this.PatNoCheck().then((response) => {
                        if (!response) {      
                            this.openAlert("입력하신 병원등록번호는 방문예정일에 수진예약이 없습니다.</br> 병원등록번호와 방문 예정일을 확인해주세요.");
                            this.patNoCheckPass = false;
                            return;
                        }
                    })
                }

                if (this.patNoCheckPass){
                    this.openConfirm("저장하시겠습니까?", this.save);
                }
            },
            save: function () {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                let phone = this
                    .phone
                    .replace(/-/gi, "")
                    .trim();

                let purpose = this.purpose == "etc" ? this.purpose_etc_text : this.purpose;
                
                if(this.type == "PG"){
                    this.type = "GST";
                }    
                this
                    .$axios
                    .put('/api/mng/visitor/v1/visitor', {
                        seq: this.$route.query.info.seq,
                        reserveDate: this.reserveDate,
                        name: this.name,
                        phone: phone,
                        type: this.type,
                        entryType: this.entryType,
                        purpose: purpose,
                        place: this.place,
                        cardNo: this.cardNo,
                        pcrYn: this.pcrYn
                            ? "Y"
                            : "N",
                        vaccYn: this.vaccYn
                            ? "Y"
                            : "N",
                        acceptYn: this.acceptYn ? "Y" : "N",
                        visitDeptName: this.visitDeptName,
                        patno: this.patno,
                        relation: this.relation
                    })
                    .then((response) => {
                        console.log(response);

                        if (response.data.success) {
                            this.openAlertClosed("저장이 완료되었습니다.", this.goPrev);
                        } else {
                            this.openAlert('저장중 오류가 발생했습니다.');
                        }
                        this.btnLock = false;
                    })
                    .catch(() => {
                        this.openAlert('저장중 오류가 발생했습니다.');
                        this.btnLock = false;
                    })
                },
            changePurpose(event) {
                console.log(event.target.value);
                this.purpose = event.target.value;
                if ("etc" == this.purpose) {
                    this.etcShow = true;
                    this.setFocus();
                } else {
                    this.purpose_etc = '';
                    this.etcShow = false;
                }
            },
            setFocus: function () {
                setTimeout(() => {
                    this
                        .$refs
                        .etcInput
                        .focus();
                }, 1);
            }
        }
    }
</script>