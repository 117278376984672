<template>
    <section class="kiosk kiosk02" style="display: block">
        <!-- kiosk header -->
        <div class="kiost-top">
            <h1>문진</h1>
            <h2>아래 질문에 답해주세요.</h2>
        </div>

        <div class="kiosk-area">
            <div class="scroll" style="max-height:514px">
                <div v-for="(item,index) in this.inqItems" :key="index">
                    <!-- 기본형 -->
                    <div class="kiosk-box" v-if="item.categoryType == 'N' && item.show">
                        <div class="kiosk-txt">
                            <h3>{{item.questionContents}}</h3>
                        </div>
                        <div class="kiosk-txt scroll" style="height: 320px; padding-top: 30px;">
                            <ul>
                                <li v-for="(subItem, subIndex) in item.inquiryList" :key="subIndex">
                                    <input type="checkbox" :id="subItem.inquiryMappingId"/>
                                    <label :for="subItem.inquiryMappingId">
                                        <span>{{subItem.inquiryContents}}
                                        </span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <!-- 버튼 -->
                        <div class="btn-area type2">
                            <a href="#none" class="btn-default btn-white">선택완료</a>
                            <a href="#none" class="btn-default">해당없음</a>
                        </div>
                    </div>
                    <!-- 이미지형 -->
                    <div class="kiosk-box" v-if="item.categoryType == 'I' && item.show">
                        <div class="kiosk-txt">
                            <h3>증상</h3>
                        </div>
                        <div class="kiosk-txt scroll" style="height: 320px; padding-top: 30px;">
                            <ul
                                class="img_symptoms"
                                v-for="(subItem, subIndex) in item.inquiryList"
                                :key="subIndex">
                                <dl v-if="item.categoryType == 'I'">
                                    <dd>
                                        <img
                                            src="../assets/images/symptoms_1.svg"
                                            v-if="subItem.inquiryPicture == null"/>
                                        <img
                                            :src="'data:image/png;base64,' +subItem.inquiryPicture"
                                            height="240px"
                                            v-if="subItem.inquiryPicture != null"/></dd>
                                    <dt><input
                                        type="checkbox"
                                        :id="subItem.inquiryMappingId"
                                        @click="checkItem($event,index,subIndex)"
                                        v-model="subItem.check"/>

                                        <label :for="subItem.inquiryMappingId">
                                            <span>
                                                {{subItem.inquiryContents}}</span>
                                        </label>
                                    </dt>
                                </dl>

                            </ul>
                        </div>
                        <!-- 버튼 -->
                        <div class="btn-area type2">
                            <a href="#none" class="btn-default btn-white">선택완료</a>
                            <a href="#none" class="btn-default">해당없음</a>
                        </div>
                    </div>
                    <div class="kiosk-box" v-if="item.categoryType == 'M' && item.show">
                        <div class="kiosk-txt">
                            <h3>{{item.questionContents}}
                                <p>{{item.categoryDesc}}</p>

                            </h3>
                        </div>
                        <div class="kiosk-txt scroll" style="height: 320px; padding-top: 30px;">
                            <!-- 객관식형 문진 -->
                            <ul>
                                <li v-for="(subItem, subIndex) in item.inquiryList" :key="subIndex">
                                    <input
                                        type="radio"
                                        :id="subItem.inquiryMappingId"
                                        :name="item.categoryId"
                                        v-model="item.answerId"
                                        @click="maCheck(index,subItem.targetCategoryId)"
                                        :value="subItem.inquiryMappingId"/>
                                    <label :for="subItem.inquiryMappingId">
                                        <span>{{subItem.inquiryContents}}
                                        </span>
                                    </label>

                                </li>
                            </ul>

                        </div>
                        <div class="btn-area type2">
                            <a href="javascript:void(0)" class="btn-default" style="width: 100%;">선택 완료</a>
                        </div>

                    </div>

                </div>
                <!-- 방문력 -->
                <div class="kiosk-box bb0">
                    <div class="kiosk-txt">
                        <h3>방문력</h3>
                    </div>
                    <div class="kiosk-txt scroll" style="height: 320px; padding-top: 30px;">

                        <ul>
                            <li>
                                <input type="checkbox" id="pp_1" name="pp_1"/>
                                <label for="pp_1">
                                    <span>미리보기용 테스트 방문력 (9/19)</span>
                                </label>
                            </li>
                            <li>
                                <input type="checkbox" id="pp_2" name="pp_2"/>
                                <label for="pp_2">
                                    <span>미리보기용 테스트 방문력 (9/19)
                                    </span>
                                </label>
                            </li>
                            <li>
                                <input type="checkbox" id="pp_3" name="pp_3"/>
                                <label for="pp_3">
                                    <span>미리보기용 테스트 방문력 (9/19)
                                    </span>
                                </label>
                            </li>
                            <li>
                                <input type="checkbox" id="pp_4" name="pp_4"/>
                                <label for="pp_4">
                                    <span>미리보기용 테스트 방문력 (9/19)
                                    </span>
                                </label>
                            </li>
                            <li>
                                <input type="checkbox" id="pp_1" name="pp_1"/>
                                <label for="pp_1">
                                    <span>미리보기용 테스트 방문력 (9/19)</span>
                                </label>
                            </li>
                            <li>
                                <input type="checkbox" id="pp_2" name="pp_2"/>
                                <label for="pp_2">
                                    <span>미리보기용 테스트 방문력 (9/19)
                                    </span>
                                </label>
                            </li>
                            <li>
                                <input type="checkbox" id="pp_3" name="pp_3"/>
                                <label for="pp_3">
                                    <span>미리보기용 테스트 방문력 (9/19)
                                    </span>
                                </label>
                            </li>
                            <li>
                                <input type="checkbox" id="pp_4" name="pp_4"/>
                                <label for="pp_4">
                                    <span>미리보기용 테스트 방문력 (9/19)
                                    </span>
                                </label>
                            </li>
                        </ul>

                    </div>
                    <!-- 버튼 -->
                    <div class="btn-area type2">
                        <a href="#none" class="btn-default btn-white">선택완료</a>
                        <a href="#none" class="btn-default">해당없음</a>
                    </div>
                </div>

            </div>

        </div>
    </section>
</template>
<script>
    import mixin from '../mixin/mixin.js';
    export default {
        mixins: [mixin],
        data: () => {
            return {
                btnstatus: null, categoryList: [], // 문진 타입
                inqItems: [], // 문진 문항
                famInfo: [
                    { //동거가족
                        famId: 'fam1',
                        checked: false
                    }, {
                        famId: 'fam2',
                        checked: false
                    }, {
                        famId: 'fam3',
                        checked: false
                    }
                ],
                placeHistoryList: [], //방문력 리스트
                privacy: {},
                btnText: [],
                addListnerStatus: true,
                visitDate: null,
                selectMethod: false,
                passCheck: null,
                detailId: null,
                deviceType: 'K'
            }
        },
        props: ['items'],
        created: function () {
            this.inqItems = this.items;
            this.getInquiryInfo();
        },
        methods: {
            getInquiryInfo: function () {
                this.inqItems = JSON.parse(JSON.stringify(this.items.filter((el) => {
                    return el.categoryType != 'H' && el.categoryType != 'P' && el.categoryType != 'E'
                })));

                for (let cat of this.inqItems) {
                    let tempObj = {
                        text: null,
                        codeId: cat.categoryId,
                        btnstatus: false
                    };
                    this
                        .btnText
                        .push(tempObj);

                }
            },
            maCheck: function (index, target) { //객관식형의 체크 여부에 따라 연결된 문진을 노출 비노출 해주는 함수.
                let maTargetList = [];
                for (let target of this.inqItems[index].inquiryList) {
                    if (target.targetCategoryId != null) {
                        maTargetList.push(target.targetCategoryId);
                    }
                }
                if (target != null) {
                    for (let maTarget of maTargetList) { //해당 카테고리에서 선택값입력 전에 연결된 보기 체크 해제.
                        for (let [index, item] of this.inqItems.entries()) {
                            if (maTarget == item.categoryId) {
                                this.$set(this.inqItems[index], 'show', false);
                                for (let i = 0; i < this.inqItems[index].inquiryList.length; i++) {
                                    this.$set(this.inqItems[index].inquiryList[i], 'check', false);
                                    this
                                        .btnText[index]
                                        .btnstatus = false;
                                }
                            }
                        }
                    }

                    console.log('target', target);

                    for (let [index, item] of this.inqItems.entries()) {
                        if (item.categoryId == target) { //해당 카테고리에서 선택값에 해당하는 연결문진 표출.
                            this.$set(this.inqItems[index], 'show', true);
                        }
                    }

                }
              
            },
            getPlaceHistory: function (place) { //해당 타입에 맞는 방문력 정보 가져오기
                let tempList = place;
                let placeList = [];
                let placeInfo = {};
                let storeList = [];
                for (let [index, temp] of tempList.entries()) {
                    if (index == 0) {
                        let storeInfo = {};
                        placeInfo = {
                            local1: temp.local1,
                            local2: temp.local2,
                            localEn1: temp.localEn1,
                            localEn2: temp.localEn2,
                            type: temp.type
                        }
                        storeInfo = {
                            local3: temp.local3,
                            localEn3: temp.localEn3,
                            storeName: temp.storeName,
                            storeNameEn: temp.storeNameEn,
                            boundaryDate: temp.boundaryDate,
                            historyId: temp.historyId,
                            check: false
                        }
                        storeList.push(storeInfo);
                    }

                    if (index > 0) {
                        if (temp.local1 == tempList[index - 1].local1 && temp.local2 == tempList[index - 1].local2) {
                            let storeInfo = {};
                            storeInfo = {
                                local3: temp.local3,
                                localEn3: temp.localEn3,
                                storeName: temp.storeName,
                                storeNameEn: temp.storeNameEn,
                                boundaryDate: temp.boundaryDate,
                                historyId: temp.historyId,
                                check: false
                            }
                            storeList.push(storeInfo);
                            if (index + 1 == tempList.length) {
                                placeInfo.storeList = storeList;
                                placeList.push(placeInfo);
                                storeList = [];
                                placeInfo = {};
                            }
                            continue;
                        } else {
                            placeInfo.storeList = storeList;

                            placeList.push(placeInfo);
                            storeList = [];
                            placeInfo = {};
                            let storeInfo = {};

                            placeInfo = {
                                local1: temp.local1,
                                local2: temp.local2,
                                localEn1: temp.localEn1,
                                localEn2: temp.localEn2,
                                type: temp.type
                            }
                            storeInfo = {
                                local3: temp.local3,
                                localEn3: temp.localEn3,
                                storeName: temp.storeName,
                                storeNameEn: temp.storeNameEn,
                                boundaryDate: temp.boundaryDate,
                                historyId: temp.historyId,
                                check: false
                            }
                            storeList.push(storeInfo);
                            if (index + 1 == tempList.length) {
                                placeInfo.storeList = storeList;
                                placeList.push(placeInfo);
                                storeList = [];
                                placeInfo = {};
                            }

                        }

                    }
                }
                this.placeHistoryList = placeList;
                for (let [index, inq] of this.inqItems.entries()) {
                    if (inq.categoryType == "H") {
                        this.$set(this.inqItems[index], 'placeList', placeList);
                    }
                }

                if (this.answerType == "EMP") {
                    //직원의 경우 동거가족 조사를 위한 방문력 세팅
                    for (var k = 0; k < this.famInfo.length; k++) {
                        //깊은 복사를 하여 넣는다.
                        let temp = JSON.parse(JSON.stringify(this.placeHistoryList));
                        this.$set(this.famInfo[k], 'famItems', temp);
                    }
                }

            }
        }

    }
</script>
<style scoped="scoped">

    .kiosk02 h3 {
        line-height: 29px;
        width: 382px;
        margin: 10px 20px;
        padding: unset;

    }

      .kiosk-txt input[type="radio"] {
        display: none;
    }

    .kiosk-txt input[type="radio"]:checked+label {
        background: url("../assets/images/ico_radiochecked.svg") no-repeat right;
        display: inline-block;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        font-size: 18px;
        height: 40px;
        background-size: 18px 18px;

    }

    .kiosk-txt input[type="radio"]+label {
        background: url("../assets/images/ico_radio.svg") no-repeat right;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        vertical-align: middle;
        width: 100%;
        font-size: 18px;
        height: 40px;
    }
</style>