<template>
  <div class="contents">
    <div class="loc">
      <li>
        <a href="javscript:void(0)">방문고객관리</a>
      </li>
      <li>
        <a href="javscript:void(0)">문진결과</a>
      </li>
    </div>
    <div class="wapper" style="min-height: 700px">
      <div class="title">
        <h2>문진결과</h2>

      </div>
      <div class="tabArea">
        <input id="tab1" type="radio" name="tabs" checked="" />
        <label for="tab1">방문객</label>
        <input id="tab2" type="radio" name="tabs" />
        <label for="tab2">협력업체</label>
        <input id="tab3" type="radio" name="tabs" />
        <label for="tab3">일반보호자</label>
        <input id="tab4" type="radio" name="tabs" />
        <label for="tab4">일반문진표</label>
        <inquiryResultTable id="tab_content1" masterId="GST"/>
        <inquiryResultTable id="tab_content2" masterId="PEMP"/>
        <inquiryResultTable id="tab_content3" masterId="NOK"/>
        <inquiryResultTableNoPass id="tab_content4"/>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import inquiryResultTableNoPass from "../../components/inquiryResultTableNoPass";
import inquiryResultTable from "../../components/inquiryResultTable";

export default {
  components: {
    inquiryResultTable,
    inquiryResultTableNoPass
  },
  name: "inquiryStatus",
  methods: {
    get_YYYY_MM_DD: () => moment().format("YYYY.MM.DD"),
  },
};
</script>
<style scoped>
#tab1:checked~#tab_content1, #tab2:checked~#tab_content2, #tab3:checked~#tab_content3, #tab4:checked~#tab_content4 {
    display: block;
}
</style>
