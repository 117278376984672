<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">알림서비스</a>
            </li>
            <li>
                <a href="javascript:void(0)">직원발열관리및조회</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>직원발열 조회 및 미실시수신 관리</h2>
            </div>
            <div class="register">
                <dl>
                    <dt style="text-align:center;">유형</dt>
                    <dd>
                        <input type="radio" name="check_radio" id="emp" value="E" v-model="targetType"/>
                        <label for="emp" style="display: inline-block; margin-left:30px">
                            <span>관리대상 사번 등록</span>
                        </label>

                        <input
                            type="radio"
                            name="check_radio"
                            id="dept"
                            value="D"
                            v-model="targetType"/>
                        <label for="dept" style="display: inline-block; margin-left:30px">
                            <span>관리대상 부서 등록</span>
                        </label>

                        <input
                            type="radio"
                            name="check_radio"
                            id="unit"
                            value="U"
                            v-model="targetType"/>
                        <label for="unit" style="display: inline-block; margin-left:30px">
                            <span>관리대상 유닛등록</span>
                        </label>

                    </dd>
                </dl>
                <dl>
                    <dt style="text-align:center;">관리자사번</dt>
                    <dd>
                        <input type="text" v-model="aprcAtprEmpno"/>
                    </dd>
                </dl>
                <dl v-if="this.targetType == 'E'">
                    <dt style="text-align:center;">관리받을 사번</dt>
                    <dd>
                        <input type="text" v-model="addAprcAtprEmpno"/>
                    </dd>
                </dl>
                <dl v-if="this.targetType == 'D'">
                    <dt style="text-align:center;">부서코드</dt>
                    <dd>
                        <input type="text" v-model="blngDeptCd"/>
                    </dd>
                </dl>
                <dl v-if="this.targetType == 'U'">
                    <dt style="text-align:center;">유닛코드</dt>
                    <dd>
                        <input type="text" v-model="blngUnitCd"/>
                    </dd>
                </dl>
            </div>
            <div class="btn_wrap">
                <div class="btn_left">
                    <button class="btn_secondary" @click="goPrev()">취 소</button>
                    <button class="btn_primary" @click="saveConfirm()">저 장</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import mixin from '../../mixin/mixin.js';
    export default {
        data: () => {
            return {targetType: 'E', aprcAtprEmpno: null, addAprcAtprEmpno: null, blngDeptCd: null, blngUnitCd: null}
        },
        watch: {
            targetType: function () {
                // this.aprcAtprEmpno = null;
                this.addAprcAtprEmpno = null;
                this.blngDeptCd = null;
                this.blngUnitCd = null;
            }
        },
        mixins: [mixin],
        methods: {
            goPrev: function () {
                this
                    .$router
                    .push({
                        name: 'empInfManage',
                        query: {
                            tid: window.btoa(1)
                        }
                    })
            },
            saveConfirm: function () {

                //사번패턴
                let regEmp = /^[a-zA-Z]{2}[0-9]{5}$/;

                if (this.targetType == 'E') {
                    let chkList = [
                        {
                            name: '관리자 사번',
                            id: this.aprcAtprEmpno
                        }, {
                            name: '관리받을 사번',
                            id: this.addAprcAtprEmpno
                        }
                    ];
                    if (!this.nullChecker(chkList)) {
                        return;
                    }
                    if (!regEmp.test(this.aprcAtprEmpno)) {
                        this.openAlert('올바른 관리자 사번형식을 입력하여 주세요.');
                        return;
                    }
                    if (!regEmp.test(this.addAprcAtprEmpno)) {
                        this.openAlert('올바른 관리받을 사번형식을 입력하여 주세요.');
                        return;
                    }
                    this.aprcAtprEmpno = this
                        .aprcAtprEmpno
                        .trim()
                        .toUpperCase();
                    this.addAprcAtprEmpno = this
                        .addAprcAtprEmpno
                        .trim()
                        .toUpperCase();

                } else if (this.targetType == 'D') {
                    let chkList = [
                        {
                            name: '관리자 사번',
                            id: this.aprcAtprEmpno
                        }, {
                            name: '부서 코드',
                            id: this.blngDeptCd
                        }
                    ];
                    if (!this.nullChecker(chkList)) {
                        return;
                    }
                    if (!regEmp.test(this.aprcAtprEmpno)) {
                        this.openAlert('올바른 사번형식을 입력하여 주세요.');
                        return;
                    }

                } else if (this.targetType == 'U') {
                    let chkList = [
                        {
                            name: '관리자 사번',
                            id: this.aprcAtprEmpno
                        }, {
                            name: '유닛 코드',
                            id: this.blngUnitCd
                        }
                    ];
                    if (!this.nullChecker(chkList)) {
                        return;
                    }
                    if (!regEmp.test(this.aprcAtprEmpno)) {
                        this.openAlert('올바른 사번형식을 입력하여 주세요.');
                        return;
                    }

                } else {
                    this.openAlert("대상 유형을 선택해 주세요.");
                    return;
                }

                this.openConfirm('저장하시겠습니까?', this.save);

            },
            save: function () {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                this
                    .$axios
                    .post('/api/mng/service/empinqmng/v1/emp-notice-target', {
                        aprcAtprEmpno: this.aprcAtprEmpno,
                        addAprcAtprEmpno: this.addAprcAtprEmpno,
                        blngDeptCd: this.blngDeptCd,
                        blngUnitCd: this.blngUnitCd
                    })
                    .then((response) => {
                        if (response.data.success) {
                            console.log(response.data.result);
                            this.openAlertClosed("저장이 완료되었습니다.",this.goPrev);

                        } else {
                            this.openAlert(response.data.resultMsg);
                        }
                        this.btnLock = false;
                    })
                    .catch(() => {

                        this.openAlert('저장중 오류가 발생했습니다.');

                        this.btnLock = false;
                    })
                }
        }
    }
</script>
<style scoped="scoped">
    .btn_del {
        padding: 0 10px 0 25px;
        background: url("../../assets/images/ico_del.svg") no-repeat 3px 5px;
        height: 36px;
        line-height: 36px;
        border: 1px solid #A0A3B9;
        border-radius: 3px;
        color: #A0A3B9;
        font-size: 15px;
        margin-left: 20px;
    }
</style>