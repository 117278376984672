import alertMsg from '../components/alertMsg.vue';
import confirmMsg from '../components/confirmMsg.vue';
import downloadConfirmMsg from '../components/confirmDownload.vue';
import loading from '../components/loading.vue';

const monthList = [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월'
];
const weekDayList = [
    '일',
    '월',
    '화',
    '수',
    '목',
    '금',
    '토'
];

const LINE_FEED = 10 // \n


let adminMixin = {

    data: () => {
        return {
            alertMsg: null,
            btnLock: false,
            page: {},
            pageNation: [],
            korName: '',
            username: '',
            userDept: '',
            userDeptName: '',
            role: [],
            download: null,
            colorList: [
                '#8a82fd',
                '#82c6fd',
                '#82fdd8',
                '#86fd82',
                '#fdf582',
                '#fdb382',
                '#fd8282',
                '#ceb443',
                '#96774f',
                '#71964f',


            ],
            answerTypeList: [
                {'code': '',    'name':'전체'},
                {'code':'EMP',  'name':'직원'},
                {'code':'PT',   'name':'환자'},
                {'code':'RPT',  'name':'재원환자'},
                {'code':'NOK',  'name':'보호자'},
                {'code':'RNOK', 'name':'상주보호자'},
                {'code':'GST',  'name':'방문객'},
                {'code':'PEMP', 'name':'협력업체'},
                {'code':'TMP',  'name':'기타방문객'},
                {'code':'ICUGST',   'name':'중환자실 면회객'},
                {'code':'WGST', 'name':'병동부 방문객'}
            ]
        }
    },
    methods: {
        pagerHandler: function(number, first, last, totalPages) {
            this.pageNation = [];
            this.page = {};
            let pageOffset = 5;

            let pageObj = {
                number: number,
                first: first,
                last: last,
                totalPages: totalPages
            }
            this.page = pageObj;

            let start = (Math.floor((number / pageOffset)) * pageOffset);

            for (var k = start; k < start + 5; k++) {
                if (k == totalPages) {
                    break;
                } else {
                    this
                        .pageNation
                        .push(k + 1);
                }
            }

        },
        convertDate: function(date) {
            if (date == null) {
                return null;
            }
            let dateVal = new Date(date);
            let year = dateVal.getFullYear();
            year = year
                .toString()
                .substring(2, 4);
            let month = dateVal.getMonth() + 1;

            let day = dateVal.getDate();
            let hour = dateVal.getHours();
            let minute = dateVal.getMinutes();

            let result = year + '.' + month
                .toString()
                .padStart(2, "0") + '.' + day
                .toString()
                .padStart(2, "0") + ' ' + hour
                .toString()
                .padStart(2, "0") + ':' + minute
                .toString()
                .padStart(2, "0");

            return result;
        },
        convertDateSec: function(date) {
            if (date == null) {
                return null;
            }
            let dateVal = new Date(date);
            let year = dateVal.getFullYear();
            year = year.toString().substring(2, 4);
            let month = dateVal.getMonth() + 1;

            let day = dateVal.getDate();
            let hour = dateVal.getHours();
            let minute = dateVal.getMinutes();
            let sec = dateVal.getSeconds();

            let result = year + '.' + month
                .toString()
                .padStart(2, "0") + '.' + day
                .toString()
                .padStart(2, "0") + ' ' + hour
                .toString()
                .padStart(2, "0") + ':' + minute
                .toString()
                .padStart(2, "0") + ':' + sec;

            return result;
        },
        convertDateDay: function(date) {
            if (date == null) {
                return null;
            }
            let dateVal = new Date(date);
            let year = dateVal.getFullYear();
            year = year.toString().substring(2, 4);
            let month = dateVal.getMonth() + 1;

            let day = dateVal.getDate();
            let hour = dateVal.getHours();
            let minute = dateVal.getMinutes();

            let result = year + '.' + month
                .toString()
                .padStart(2, "0") + '.' + day
                .toString()
                .padStart(2, "0");

            return result;
        },
        convertDashDate: function(date) {
            if (date == null) {
                return null;
            }
            let dateVal = new Date(date);
            let year = dateVal.getFullYear();
            year = year
                .toString()
                .substring(2, 4);
            let month = dateVal.getMonth() + 1;

            let day = dateVal.getDate();

            let result = year + '-' + month
                .toString()
                .padStart(2, "0") + '-' + day
                .toString()
                .padStart(2, "0")
            return result;
        },
        convertCalDate: function(date) {
            if (date == null) {
                return null;
            }
            let dateVal = new Date(date);
            let year = dateVal.getFullYear();
            let month = dateVal.getMonth() + 1;
            let day = dateVal.getDate();

            let result = year + '-' + month
                .toString()
                .padStart(2, "0") + '-' + day
                .toString()
                .padStart(2, "0")
            return result;
        },
        // 사업자 등록번호 체크
        convertPartnerRegNo: function(partnerRegNo) {
            if (partnerRegNo == null) {
                return null;
            } else {
                if (partnerRegNo.length == 13) {
                    partnerRegNo = partnerRegNo.slice(0, -1);
                } else {
                    partnerRegNo = partnerRegNo.replace(/[^0-9]/g, '')
                    partnerRegNo = partnerRegNo.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
                }
                return partnerRegNo;
            }

        },
        // 대표전화번호 체크
        convertRepPhone: function(repPhone) {
            if (repPhone == null) {
                return null;
            } else {
                repPhone = repPhone.replace(/[^0-9]/g, '')
                repPhone = repPhone.replace(/-/, "");
                if (repPhone.length > 12) {
                    repPhone = repPhone.substring(0, 12);
                }
                repPhone = repPhone.replace(
                    /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/,
                    '$1-$2-$3'
                );
                return repPhone;
            }
        },
        // 관리자전화번호 체크
        convertPhone: function(phone) {
            if (phone == null) {
                return null;
            } else {
                phone = phone.replace(/[^0-9]/g, '')
                phone = phone.replace(/-/, "");
                if (phone.length > 11) {
                    phone = phone.substring(0, 11);
                }
                phone = phone.replace(
                    /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/,
                    '$1-$2-$3'
                );
                return phone;
            }
        },
        convertIdentityNo: function(value) {
            if (value == null) {
                return null;
            } else {
                const regExp = /[a-zA-Z]/g;
                if (!regExp.test(value)) {

                    value = value.replace(/[^0-9]/g, '')
                    value = value.replace(/-/, "");
                    if (value.length > 11) {
                        value = value.substring(0, 11);
                    }
                    value = value.replace(
                        /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/,
                        '$1-$2-$3'
                    );

                }
                return value;
            }
        },
        // 대표자명 체크
        convertRepName: function(repName) {
            if (repName == null) {
                return null;
            } else {
                const regex = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|]*$/;
                if (!regex.test(repName)) {
                    repName = repName.slice(0, -1);
                }
                return repName;
            }
        },
        // 관리자명 체크
        convertManagerName: function(managerName) {
            if (managerName == null) {
                return null;
            } else {
                const regex = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|]*$/;
                if (!regex.test(managerName)) {
                    managerName = managerName.slice(0, -1);
                }
                return managerName;
            }
        },

        openAlert: function(msg) {
            this
                .$modal
                .show(alertMsg, {
                    alertMsg: msg
                }, {
                    width: '540px',
                    height: 'auto',
                    adaptive: true,
                    clickToClose: false
                })
        },
        openAlertClosed: function(msg, callback, param) {

           if (param == null||param== undefined){
                this
                    .$modal
                    .show(alertMsg, {
                        alertMsg: msg
                    }, {
                        width: '540px',
                        height: 'auto',
                        adaptive: true,
                        clickToClose: false
                    }, {
                        closed: callback,
                    })
           } else {
                this
                    .$modal
                    .show(alertMsg, {
                        alertMsg: msg
                    }, {
                        width: '540px',
                        height: 'auto',
                        adaptive: true,
                        clickToClose: false
                    }, {
                        closed: callback,
                        param
                    })
            }
        },
        openConfirm: function(msg, callback, callbackParam) {
            this
                .$modal
                .show(confirmMsg, {
                    alertMsg: msg,
                    callback: callback,
                    callbackParam: callbackParam
                }, {
                    name: 'comfirmModal',
                    width: '540px',
                    height: 'auto',
                    adaptive: true,
                    clickToClose: false
                })
        },
        downloadConfirm: async function(callback, callbackParam) {
            let response = await this
                .$axios
                .get('/api/mng/download-check');

            if (response.data.result != 'Y') {
                this.openAlert("다운로드 권한이 없습니다.");
                return;
            }
            this
                .$modal
                .show(downloadConfirmMsg, {
                    callback: callback,
                    callbackParam: callbackParam
                }, {
                    name: 'confirmDownloadModal',
                    width: '290px',
                    height: 'auto',
                    adaptive: true,
                    clickToClose: false
                })
        },
        getHeader: function(date) {
            let i = new Date(date).getMonth();
            return new Date(date).getFullYear() + '년 ' + monthList[i];
        },
        getMonth: function(date) {
            let i = new Date(date).getMonth();
            return monthList[i];
        },
        getWeekDay: function(date) {
            let i = new Date(date).getDay(date);
            return weekDayList[i];
        },
        nullChecker: function(checkList) {
            console.log(checkList);
            for (let item of checkList) {
                console.log(item);
                if (item.id == null) {
                    this.openAlert(item.name + '를 입력해 주세요.');
                    return false;
                }
                if (item.id.trim() == '') {
                    this.openAlert(item.name + '를 입력해 주세요.');
                    return false;
                }
            }
            return true;
        },
        openLoading: function(msg) {
            this.$modal.hide("axiosLoadingModal"); // 혹시 Axios 스피너가 동작중이었으면 닫은 후 Open
            this
                .$modal
                .show(loading, {
                    alertMsg: msg
                }, {
                    name: 'loadingModal',
                    width: '300px',
                    height: 'auto',
                    adaptive: true,
                    clickToClose: false,
                    styles: {
                        "background": 'rgba(0, 0, 0, 0)',
                        'box-shadow': 'none'
                    }
                })
        },
        closeLoading: function() {
            this
                .$modal
                .hide('loadingModal')
        },
        getMyinfo: function() {
            this
                .$axios
                .get('/api/v1/tablet/my-info')
                .then((response) => {
                    if (response.data.success) {
                        let myinfo = response.data.result;
                        if (this.$route.name != 'login' && this.$route.name != 'resetPw' && this.$route.name != 'inquiry-popup') {
                            if (myinfo.username == null) {
                                this.$router.push({ name: 'login' });
                            }
                        }
                        this.korName = myinfo.korName;
                        this.username = myinfo.username;
                        this.userDept = myinfo.userDept;
                        this.userDeptName = myinfo.userDeptName;
                        this.role = myinfo.role;
                    }
                })
        },
        setDownloadHistory: function(param) {
            this
                .$axios
                .post('/api/mng/setting/v1/log/down/save', {
                    fileName: param.fileName,
                    fileSize: param.fileSize,
                    purpose: param.purpose,
                    purposeEtc: param.purposeEtc
                })
                .then((response) => {
                    console.log(response.data.success);
                })
                .catch(() => {
                    this.openAlert("다운로드 이력 저장  오류가 발생하였습니다.");
                });
        },
        sessionCountDown: function() {
            let token = localStorage.token;
            console.log(token);
            if (token != null) {
                this.sessionInterval = setInterval(() => {
                    this.sessionTime--;
                    console.log(token);
                    if (this.sessionTime > 0) {
                        console.log(this.sessionTime);
                    } else {
                        this
                            .$store
                            .commit('setToken', { token: null });
                        localStorage.removeItem('token');
                        this
                            .$router
                            .push({ name: 'login' });
                    }
                }, 1000);
            }
        },
        convertAnswerType: function (value) {
            if (value != null && this.answerTypeList.some(a => a.code == value)) {
                return this.answerTypeList.find(x => x.code == value).name;
            }
            return value
        },
        getByteLength(decimal) {
            return (decimal >> 7) || (LINE_FEED === decimal) ? 2 : 1
        },
        getByte(str) {
            return str
                .split('')
                .map((s) => s.charCodeAt(0))
                .reduce((prev, unicodeDecimalValue) => prev + this.getByteLength(unicodeDecimalValue), 0)
        },
        getLimitedByteText(inputText, maxByte) {
            const characters = inputText.split('')
            let validText = ''
            let totalByte = 0

            for (let i = 0; i < characters.length; i += 1) {
                const character = characters[i]
                const decimal = character.charCodeAt(0)
                const byte = this.getByteLength(decimal) // 글자 한 개가 몇 바이트 길이인지 구해주기

                // 현재까지의 바이트 길이와 더해 최대 바이트 길이를 넘지 않으면
                if (totalByte + byte <= maxByte) {
                    totalByte += byte      // 바이트 길이 값을 더해 현재까지의 총 바이트 길이 값을 구함
                    validText += character // 글자를 더해 현재까지의 총 문자열 값을 구함
                } else {                 // 최대 바이트 길이를 넘으면
                    break                  // for 루프 종료
                }
            }

            return validText
        }
    }
}
export default adminMixin;