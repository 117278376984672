<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javscript:void(0)">방문신청</a>
            </li>
            <li>
                <a href="javscript:void(0)">병동방문객관리</a>
            </li>
        </div>
        <div class="wapper" style="min-height: 700px;">
            <div class="title">
                <h2>병동방문객관리</h2>
            </div>
            <h3>
                <div class="btn-right">
                    <button class="btn_search" @click="openSearch()">검색</button>
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">
                            <!--<dl class="full_width">
                                <dt>병동방문고객</dt>
                                <dd><input type="text" v-model="name"/></dd>
                            </dl>-->
                            <dl class="full_width">
                                <dt>환자번호</dt>
                                <dd><input type="text" v-model="patno"/></dd>
                            </dl>
                            <!--<dl class="full_width">
                                <dt>방문장소</dt>
                                <dd><input type="text" v-model="place"/></dd>
                            </dl>-->
                            <dl class="full_width">
                                <dt>등록일/내원일</dt>
                                <dd>
                                    <input type='date' v-model='startDate' @change="changeStartDate"/>
                                    ~
                                    <input type='date' v-model='endDate' @change="changeEndDate"/>
                                </dd>
                            </dl>
                        </div>
                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'btn_filered' : filter}"
                                @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                            <button class="btn_primary btn_md" @click="openPop()">적용</button>
                        </div>
                    </div>
                </div>
            </h3>
            <div class="table table_line">
                <table>
                    <tr>
                        <th>등록일시</th>
                        <th>방문일시</th>
                        <th>재원환자</th>
                        <th>방문장소</th>
                        <th>병동방문고객</th>
                        <th>연락처</th>
                        <th>환자와의 관계</th>
                    </tr>
                    <tr v-for="(item, index) in visitorList" :key="index">
                        <td>{{ convertDate(item.regDate)}}</td>
                        <td>{{item.regDateIn}}</td>
                        <td>{{item.patName + '('+ item.patno + ')'}}</td>
                        <td>{{item.place}}</td>
                        <td>

                            {{item.name}}
                            <label v-if="item.pcrYn == 'Y'" class="btn_primary btn_md_custom">P</label>
                            <label v-if="item.vaccYn == 'Y'" class="btn_primary btn_md_custom">V</label>
                            <label v-if="item.deniedYn == 'Y'" class="btn_danger btn_md_custom">R</label>
                        </td>
                        <td>{{convertPhone(item.phone)}}</td>
                        <td>{{item.relation}}</td>        
                    </tr>
                </table>
            </div>
            <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" 
            :startDate="startDate" :endDate="endDate" :pid="patno"/>
        </div>
        <modal
            name="conform_search"
            :width="540"
            :height="160"
            :adaptive="false"
            :clickToClose="false">
            <div id="popup_reg" class="pop pop_md pop_reg">
                <!-- 검색 경고 메세지 -->
                <div class="pop_head">
                    <h2>알림</h2>
                    <a class="btn_pop_close" @click="closePop()">닫기</a>
                </div>
                <div class="pop_body">
                    <h4 class="modal-title">병동 방문객 기록을 조회하는 경우 기록이 남게 됩니다. 진행하시겠습니까?</h4>
                </div>
                <div class="pop_footer">
                    <button class="btn_secondary" @click="closePop()">취 소</button>
                    <button class="btn_primary" @click="getList(0)">확 인</button>
                </div>
            </div>
        </modal>
    </div>

</template>
<script>
    import pagination from '../../components/commonPagination.vue'
    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';
    import $ from 'jquery'
    export default {
        data: () => {
            return {
                visitorList: [],
                result:[],
                inqResult: [],
                name: null,
                phone: null,
                purpose: null,
                place: null,
                filter: false,
                startDate: moment()
                    .add(-1, 'd')
                    .format("YYYY-MM-DD"),
                endDate: moment()
                    .add(1, 'M')
                    .format("YYYY-MM-DD"),
                answerType: null,
                answerTime: null,
                identityNo:null,
                detailId:null,
                masterId:null,
                isNok:null,
                patName:null,
                patno:null
            }
        },
        mixins: [mixin],
        // mounted: function () {
        //     this.getList(0);
        // },
        mounted: function() {
            if(this.$route.query.page) {
                this.changeFilter()
                this.patno = this.$route.query.pid;
                this.startDate = this.$route.query.startDate|| null
                this.endDate = this.$route.query.endDate|| null
                this.getList(this.$route.query.page);
            } else {
                // this.getList(0);
            }
        },
        components : {
            pagination
        },
        methods: {
            getList: function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }
                if (this.patno == null||this.patno == ''){
                    this.openAlert("조회하고자 하는 환자번호를 입력해주세요");
                    this.closePop()
                    return;
                }

                let param = {};

                if (this.filter) {
                    param = {
                        page: page,
                        size: 30,
                        name: this.name,
                        patno: this.patno,
                        place: this.place,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                } else {
                    param = {
                        page: page,
                        size: 30,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                }

                this
                    .$axios
                    .get('/api/mng/visitor/v1/inptvisitor', {params: param})
                    .then((response) => {
                        console.log(response.data);

                        if (response.data.success) {
                            let result = response.data.result.content;

                            this.visitorList = result;

                            let pageInfo = response.data.result;

                            this.pagerHandler(
                                pageInfo.number,
                                pageInfo.first,
                                pageInfo.last,
                                pageInfo.totalPages
                            );
                            this.setSearchHistory(param);
                            this.closePop();
                        } else {
                            this.openAlert("방문고객을 불러오는 도중 오류가 발생하였습니다.");
                        }
                    })
            },
            setSearchHistory: function(param) {
                this
                .$axios
                .post('/api/mng/setting/v1/log/search/save', {
                    pageNumber: param.page+1,
                    visitorName: param.name,
                    ptId: param.patno,
                    visotorPlace: param.place,
                    startDate: param.startDate,
                    endDate: param.endDate
                })
                .then((response) => {
                    console.log(response.data.success);
                })
                .catch(() => {
                    this.openAlert("이력 저장  오류가 발생하였습니다.");
                });
            },
            firstSearch: function () {
                let pageQuery = {
                    page : 0,
                    pid : this.patno|| null,
                    startDate : this.startDate|| null,
                    endDate : this.endDate|| null
                }
                this.$router.push({
                    path: this.$route.path,
                    query: pageQuery
                }).catch(()=>{});
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            changeStartDate(event) {
                this.startDate = event.target.value;
            },
            changeEndDate(event) {
                this.endDate = event.target.value;
            },
            confirmDownload: function () {
                if(this.patno == null || this.patno == ""){
                    this.openAlert("조회하고자 하는 환자번호를 입력해주세요");
                } else {
                    this.downloadConfirm(this.confirmExcelDown);
                }
            },
            confirmExcelDown: function (param) {
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {
                let param = {};

                if (this.filter) {
                    param = {
                        page: 0,
                        size: 100000,
                        name: this.name,
                        patno: this.patno,
                        phone: this.phone,
                        purpose: this.purpose,
                        place: this.place,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                } else {
                    this.openAlert("조회하고자 하는 환자번호를 입력해주세요");
                    return
                    param = {
                        
                        // page: 0,
                        // size: 100000,
                        // startDate: this.startDate,
                        // endDate: this.endDate
                    }
                }
                this
                    .$axios
                    .get('/api/mng/visitor/v1/inptvisitor/excel', {
                        params: param,
                        responseType: "blob"
                    })
                    .then((response) => {
                        console.log(response);
                        const url = window
                            .URL
                            .createObjectURL(
                                new Blob([response.data], {type: response.headers['content-type']})
                            );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute(
                            'download',
                            '병동방문객관리_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx'
                        );
                        document
                            .body
                            .appendChild(link);
                        link.click();

                        if (response.status == 200) {
                            let param = {
                                fileName: '병동방문객관리_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                                fileSize: response.data.size,
                                purpose: paramdata.purpose,
                                purposeEtc: paramdata.purpose_etc == null
                                    ? ''
                                    : paramdata.purpose_etc
                            }
                            this.setDownloadHistory(param);
                        }
                    })
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.name = null;
                this.phone = null;
                this.startDate = moment()
                    .add(-1, 'd')
                    .format("YYYY-MM-DD");
                this.endDate = moment()
                    .add(1, 'M')
                    .format("YYYY-MM-DD");
                this.reserve = false;
                this.purpose = null;
                this.place = null;
            },
            add: function () {
                this
                    .$router
                    .push({name: 'visitorMng'});
            },
            convertType: function (type,isNok) {
                if (type != null) {
                    if (type == "PT") {
                        return "환자";
                    } else if (type == "GST") {
                        return "방문객";
                    } else if (type == "NOK") {
                        if(isNok == "Y"){
                            return "병동방문객";
                        }else{
                            return "보호자";
                        }                
                    } else if (type == "TMP") {
                        return "방문객";
                    } else if ( type == "PEMP"){
                        return "협력업체";
                    } else if (type == "ETC") {
                        return "기타";
                    }
                }
            },
            closePop: function () {
                this
                    .$modal
                    .hide('conform_search');
            },
            openPop: function(){
                if(this.filter){
                    this.$modal.show('conform_search');
                }
            },
        }
    }
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
    .btn_md_custom {
        padding: 0 5px;
        min-width: auto;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
    }
    .etcBtn {
        width: 60px;
        padding: 0;
    }
    .pop {
        display: inline-block;
        box-shadow: none;
        overflow-y: auto;
    }    
    .subHead {
        font-size : 13px;
        font-weight: bold;
        margin-bottom: 10px;

    }
    .ddText{
        text-align : center ;
        
    }
    .pop_body dt {
        width: 200%;
    }
    .titleBar{ 
        font-size : 15px; 
        font-weight: bold;
        
        
    }
    #titleBc{
        background-color: rgba(128, 126, 126, 0.212);
    }
    .dtText{
        margin-left: 30px;
        display: inline-block;
    }
    .spanId{
        margin-left: 100px;
    }


 
</style>