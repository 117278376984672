<template>
    <section class="container ask"
     style="max-width: 545px; height: 700px; overflow-y: scroll;">
        <div class="conts">
            <div class="wapper" v-if="!step2">
                <h2>문진</h2>
                <div class="question_list">

                    <div v-for="(inquiry, index) in inqItems" :key="index">
                        <div class="multi_q" v-if="inquiry.categoryType == 'M' && inquiry.show">
                            <h3>{{inquiry.questionContents}}
                                <P>{{inquiry.categoryDesc}}</P>
                            </h3>
                            <ul style="margin-top: 10px;">
                                <li v-for="(items, childIndex) in inquiry.inquiryList" :key="childIndex">
                                    <input
                                        type="radio"
                                        :id="items.inquiryMappingId"
                                        :name="inquiry.answerId"
                                        v-model="inquiry.answerId"
                                        @click="maCheck(index,items.targetCategoryId)"
                                        :value="items.inquiryMappingId"/>
                                    <label :for="items.inquiryMappingId">
                                        <span v-text="items.inquiryContents"></span>
                                    </label>
                                </li>
                                <!-- <li> <button>해당없음</button> </li> -->
                            </ul>
                        </div>

                        <div v-if="inquiry.categoryType == 'I' && inquiry.show">
                            <h3>{{inquiry.questionContents}}
                                <P>{{inquiry.categoryDesc}}</P>
                            </h3>
                            <ul style="margin-top: 10px;">
                                <li v-for="(items, childIndex) in inquiry.inquiryList" :key="childIndex">
                                    <div v-if="inquiry.answerContents == 'Y'">
                                        <input
                                            type="checkbox"
                                            :id="items.inquiryMappingId"
                                            v-model="items.check"
                                            @click="inqCheck(index)"/>
                                        <label :for="items.inquiryMappingId">
                                            <span v-text="items.inquiryContents"></span>
                                        </label>
                                    </div>
                                    <div v-if="inquiry.answerContents != 'Y'">
                                        <span v-text="items.inquiryContents"></span>
                                    </div>
                                </li>
                                <li>
                                    <button @click="yesSymptom($event,index)">위 보기에 해당사항 있음</button>
                                </li>
                            </ul>
                        </div>
                        <div v-if="inquiry.categoryType == 'N' && inquiry.show">
                            <h3>{{inquiry.questionContents}}
                                <P>{{inquiry.categoryDesc}}</P>
                            </h3>
                            <ul style="margin-top: 10px;">
                                <li v-for="(items, childIndex) in inquiry.inquiryList" :key="childIndex">
                                    <div v-if="inquiry.answerContents == 'Y'">
                                        <input
                                            type="checkbox"
                                            :id="items.inquiryMappingId"
                                            v-model="items.check"
                                            @change="inqCheck(index)"/>
                                        <label :for="items.inquiryMappingId">
                                            <span v-text="items.inquiryContents"></span>
                                        </label>
                                    </div>
                                    <div v-if="inquiry.answerContents != 'Y'">
                                        <span v-text="items.inquiryContents"></span>
                                    </div>
                                </li>
                                <li>
                                    <button @click="yesSymptom($event,index)">위 보기에 해당사항 있음</button>
                                </li>
                            </ul>

                        </div>

                        <div v-if="inquiry.categoryType == 'T' && inquiry.show">
                            <h3>{{inquiry.questionContents}}
                                <P>{{inquiry.categoryDesc}}</P>
                            </h3>
                            <ul style="margin-top: 10px;">
                                <div class="number_box">
                                    <input type="text" placeholder="답변입력" v-model="inquiry.answerContents"/></div>
                            </ul>
                        </div>
                        <div v-if="inquiry.categoryType == 'E'">
                            <h3>{{inquiry.questionContents}}
                            </h3>
                            <div class="textEditor" v-html="inquiry.categoryDesc"></div>
                        </div>

                        <div v-if="inquiry.categoryType == 'H'">
                            <h3>방문력
                                <P>{{inquiry.categoryDesc}}</P>

                            </h3>
                            <ul>
                                <!-- <div v-for="(item, index) in inquiry.placeList" :key="index">
                                <h5>{{item.local1}}</h5> <li v-for="(subItem, subIndex) in item.storeList"
                                :key="subIndex"> <div v-if="inquiry.answerContents == 'Y'"> <input
                                type="checkbox" :id="subItem.historyId" v-model="subItem.check"/> <label
                                :for="subItem.historyId"> <span>{{ subItem.local2 + " " + subItem.local3 + " " +
                                subItem.storeName + " (" + subItem.boundaryDate + ")"}} </span> </label> </div>
                                <div v-if="inquiry.answerContents != 'Y'"> <span>{{ subItem.local2 + " " +
                                subItem.local3 + " " + subItem.storeName + " (" + subItem.boundaryDate + ")"}}
                                </span> </div> </li> </div> -->
                                <table v-if="inquiry.answerContents == 'Y'">
                                    <template v-for="(item, placeIndex) in inquiry.placeList">
                                        <tr v-for="(store, storeIndex) in item.storeList" :key="store.historyId">
                                            <th
                                                v-if="storeIndex == 0"
                                                :rowspan="item.storeList.length"
                                                style="width:100px; border-bottom: 1px solid #eeeeee;">{{item.local1}}</th>
                                            <td style="padding-right: 15px;">
                                                <input
                                                    type="checkbox"
                                                    :id="store.historyId"
                                                    v-model="store.check"
                                                    @click="openCal(index, placeIndex, storeIndex, store.check, store.placeDate)"/>
                                                <label :for="store.historyId">
                                                    <span>
                                                        <span v-if="checkNew(store.regDate, store.updDate)" class="label_new">신규</span>
                                                        <span v-if="store.grade == 1" class="label_important">중요</span>{{store.local2 + ' ' + store.local3 + ' ' +store.storeName + ' (' + store.boundaryDate + ')'}}</span>
                                                    <div v-if="store.check" class="pic_date" style="top: 57%; right: 3px;">{{convertSDate(store.placeDate)}}</div>
                                                </label>
                                            </td>
                                        </tr>
                                    </template>
                                </table>
                                <table v-if="inquiry.answerContents != 'Y'">
                                    <template v-for="(item) in inquiry.placeList">
                                        <tr v-for="(store, storeIndex) in item.storeList" :key="store.historyId">
                                            <th
                                                v-if="storeIndex == 0"
                                                :rowspan="item.storeList.length"
                                                style="width:100px; border-bottom: 1px solid #eeeeee;">{{item.local1}}</th>
                                            <td style="padding-right: 15px;">
                                                <span>
                                                    <span v-if="checkNew(store.regDate, store.updDate)" class="label_new">신규</span>
                                                    <span v-if="store.grade == 1" class="label_important">중요</span>{{store.local2 + ' ' + store.local3 + ' ' +store.storeName + ' (' + store.boundaryDate + ')'}}</span>
                                            </td>
                                        </tr>

                                    </template>

                                </table>
                                <li>
                                    <button @click="yesPlace($event, index)">위 보기에 방문한적 있음</button>
                                </li>
                            </ul>

                        </div>

                    </div>

                    <!-- 직원의 경우 동거가족 여부 표출 -->
                    <h2 v-if="answerType == 'EMP'">동거가족 문진</h2>
                    <p v-if="answerType == 'EMP'" style="margin-bottom: 20px">동거가족이 증상 및 장소방문력이 있는 경우 해당 동거가족에 대한 문진을 추가하세요.</p>
                    <div v-if="answerType == 'EMP'">
                        <ul class="family_q" v-for="(fam,famIndex) in famInfo" :key="famIndex">
                            <div class="title">
                                <input
                                    type="checkbox"
                                    :id="fam.famId"
                                    :name="fam.famId"
                                    v-model="fam.checked"
                                    @click="famCheck($event, famIndex)"/>
                                <label :for="fam.famId">
                                    <span>동거가족
                                        {{famIndex + 1}}</span>
                                </label>
                            </div>
                            <div class="family_q_list place" v-if="fam.checked">
                                <div v-for="(inquiry, inqIndex) in fam.inqItems" :key="inqIndex">
                                    <div v-if="inquiry.categoryType == 'FS' && inquiry.show">
                                        <h3>{{inquiry.questionContents}}
                                            <P>{{inquiry.categoryDesc}}</P>
                                        </h3>
                                        <ul style="margin-top: 10px;">
                                            <li v-for="(items, childIndex) in inquiry.inquiryList" :key="childIndex">
                                                <div v-if="inquiry.answerContents == 'Y'">
                                                    <input
                                                        type="checkbox"
                                                        :id="items.inquiryMappingId + fam.famId"
                                                        v-model="items.check"/>
                                                    <label :for="items.inquiryMappingId +fam.famId">
                                                        <span v-text="items.inquiryContents"></span>
                                                    </label>
                                                </div>
                                                <div v-if="inquiry.answerContents != 'Y'">
                                                    <span v-text="items.inquiryContents"></span>
                                                </div>
                                            </li>
                                            <li>
                                                <button @click="famYesSymptom($event,famIndex,inqIndex)">위 보기에 해당사항 있음</button>
                                            </li>
                                        </ul>

                                    </div>
                                    <div v-if="inquiry.categoryType == 'FD' && inquiry.show">
                                        <h3>{{inquiry.questionContents}}
                                            <P>{{inquiry.categoryDesc}}</P>
                                        </h3>
                                        <ul style="margin-top: 10px;">
                                            <li v-for="(items, childIndex) in inquiry.inquiryList" :key="childIndex">
                                                <div v-if="inquiry.answerContents == 'Y'">
                                                    <input
                                                        type="checkbox"
                                                        :id="items.inquiryMappingId +fam.famId"
                                                        v-model="items.check"/>
                                                    <label :for="items.inquiryMappingId +fam.famId">
                                                        <span v-text="items.inquiryContents"></span>
                                                    </label>
                                                </div>
                                                <div v-if="inquiry.answerContents != 'Y'">
                                                    <span v-text="items.inquiryContents"></span>
                                                </div>
                                            </li>
                                            <li>
                                                <button @click="famYesSymptom($event,famIndex,inqIndex)">위 보기에 해당사항 있음</button>
                                            </li>
                                        </ul>

                                    </div>
                                    <div v-if="inquiry.categoryType == 'H'">
                                        <h3>방문력
                                            <P>{{inquiry.categoryDesc}}</P>
                                        </h3>
                                        <ul>
                                            <table v-if="inquiry.answerContents == 'Y'">
                                                <template v-for="(item,placeIndex) in inquiry.placeList">
                                                    <tr v-for="(store, storeIndex) in item.storeList" :key="store.historyId">
                                                        <th
                                                            v-if="storeIndex == 0"
                                                            :rowspan="item.storeList.length"
                                                            style="width:100px; border-bottom: 1px solid #eeeeee;">{{item.local1}}</th>
                                                        <td style="padding-right: 15px;">
                                                            <input
                                                                type="checkbox"
                                                                :id="store.historyId + fam.famId"
                                                                v-model="store.check"
                                                                @click="famOpenCal(famIndex, inqIndex, placeIndex, storeIndex, store.check, store.placeDate)"/>
                                                            <label :for="store.historyId + fam.famId">
                                                                <span>
                                                                    <span v-if="checkNew(store.regDate, store.updDate)" class="label_new">신규</span>
                                                                    <span v-if="store.grade == 1" class="label_important">중요</span>{{store.local2 + ' ' + store.local3 + ' ' +store.storeName + ' (' + store.boundaryDate + ')'}}</span>
                                                                <div v-if="store.check " class="pic_date" style="top: 57%; right: 3px;">{{convertSDate(store.placeDate)}}</div>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </table>
                                            <table v-if="inquiry.answerContents != 'Y'">
                                                <template v-for="(item) in inquiry.placeList">
                                                    <tr v-for="(store, storeIndex) in item.storeList" :key="store.historyId">
                                                        <th
                                                            v-if="storeIndex == 0"
                                                            :rowspan="item.storeList.length"
                                                            style="width:100px; border-bottom: 1px solid #eeeeee;">{{item.local1}}</th>
                                                        <td style="padding-right: 15px;">
                                                            <span>
                                                                <span v-if="checkNew(store.regDate, store.updDate)" class="label_new">신규</span>
                                                                <span v-if="store.grade == 1" class="label_important">중요</span>{{store.local2 + ' ' + store.local3 + ' ' +store.storeName + ' (' + store.boundaryDate + ')'}}</span>
                                                        </td>
                                                    </tr>

                                                </template>

                                            </table>
                                            <li>
                                                <button @click="famYesPlace($event, famIndex, inqIndex)">위 보기에 방문한적 있음</button>
                                            </li>
                                        </ul>

                                    </div>
                                </div>

                            </div>
                        </ul>

                    </div>
                    <!-- <div class="privacy_wrap"> <input type="checkbox" id="agree" name="agree"
                    v-model="privacyCheck"/> <label for="agree"> <span>개인정보 수집 동의</span> </label> <a
                    href="javascript:void(0);" @click="openPrivacy()">보기</a> </div> -->
                    <div class="btn_area ">
                        <button v-if="answerType != 'PT'" style="width:100%" @click="$emit('close')">완료</button>
                        <button v-if="answerType == 'PT'" style="width:100%" @click="$emit('close')">다음</button>
                    </div>
                </div>
            </div>
            <div class="wapper" v-if="answerType == 'PT' && step2">
                <h2>동행하실 보호자 연락처를</h2>
                <h2>입력해주세요.</h2>
                <div class="question_list">
                    <div class="guide_with" v-if="answerType == 'PT'">
                        <h4>
                            <input type="checkbox" id="with_guide" name="with_guide" v-model="accompanyYn"/>
                            <label for="with_guide">
                                <span style="width: 350px;">보호자와 동반하여 내원하는 경우 체크하세요.
                                </span>
                            </label>
                        </h4>
                        <p>* 보호자에게 병원 출입증 발급 링크를 자동으로 송부합니다.</p>
                        <div class="guide_info" id="guide_num" style="display: block;">
                            <div class="num_wrap">
                                <div class="num_select">
                                    <select :disabled="!accompanyYn" v-model="preAccompany1">
                                        <option value="010">010</option>
                                        <option value="011">011</option>
                                        <option value="016">016</option>
                                        <option value="017">017</option>
                                        <option value="018">018</option>
                                        <option value="019">019</option>
                                    </select>
                                </div>
                                <div class="num_input">
                                    <input
                                        type="number"
                                        placeholder="핸드폰번호를 입력하세요."
                                        :disabled="!accompanyYn"
                                        v-model="postAccompany1"/></div>
                                <div class="noti">* 2G폰을 사용하시는 보호자님은 링크를 받으실 수 없습니다.</div>
                            </div>
                        </div>
                    </div>
                    <div class="btn_area">
                        <button v-if="answerType == 'PT'">완료</button>
                    </div>
                </div>
            </div>

            <div class="modal" id="kiosk_agree" ref="privacy">
                <!--개인정보 수집-->
                <div class="bg">
                    <a class="js-close-modal" id="close-modal" @click="closePrivacy()">닫기</a>
                    <div class="header">개인정보 수집 및 이용</div>
                    <div class="body" v-html="privacy.privacyContents"></div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import mixin from '../mixin/mixin.js';
    import moment from 'moment';

    export default {
        data: () => {
            return {
                crntDate: null, answerType: null, //응답자 종류
                identityNo: null, //식별번호
                categoryList: [], // 문진 타입
                inqItems: [], // 문진 문항
                famInfo: [
                    { //동거가족
                        famId: 'fam1',
                        checked: false,
                        inqItems: []
                    }, {
                        famId: 'fam2',
                        checked: false,
                        inqItems: []
                    }, {
                        famId: 'fam3',
                        checked: false,
                        inqItems: []
                    }
                ],
                placeHistoryList: [], //방문력 리스트
                btnLock: false, //버튼 잠금(제출중 일때 중복 제출 방지용)
                privacy: {
                    privacyCode: null,
                    privacyContents: null
                }, //개인정보 동의정보
                // privacyCheck: false, 개인정보 동의를 체크했는지 여부.
                accompanyYn: false, //보호자 동행여부,
                preAccompany1: "010",
                postAccompany1: "",
                visitDate: null,
                step2: false, //환자의 경우 두번째 스텝으로 보호자의 연락처를 기입하는 화면을 보여준다.
            }
        },
        // components: {     'date-picker': datePicker },
        mixins: [mixin],
        watch: {
            postAccompany1: function (newVal) {
                if (newVal.length > 8) {
                    newVal = newVal.substring(0, 8);
                }
                //숫자가 아닌 문자가 입력되는거 막기.
                newVal = newVal.replace(/[ㄱ-힣~!@#$%^&*()_+|<>?:{}= ]/g, "");
                this.postAccompany1 = newVal;

            },
            accompanyYn: function (newVal) {
                if (!newVal) {
                    this.postAccompany1 = null;
                }
            }
        },
        created: function () {

            this.getInquiryInfo();

        },
        props: ['items'],
        methods: {

            getInquiryInfo: function () { //해당 타입에 맞는 문진 정보 가져오기.
                this.inqItems = JSON.parse(JSON.stringify(this.items.filter((el) => {
                    return el.categoryType != "P" && el.categoryType != "FS" && el.categoryType != "FD"
                })));

                console.log(this.items);

                for (let item of this.inqItems) {
                    if (item.categoryType == "H") {
                        console.log(item.placeList);
                        this.getPlaceHistory(item.placeList);
                    }
                }

                if (this.answerType == "EMP") {

                    let inqItems = JSON.parse(JSON.stringify(this.items.filter((el) => {
                        return el.categoryType == "FS" || el.categoryType == "FD" || el.categoryType == "H"
                    })));

                    for (let fam of this.famInfo) {
                        fam.inqItems = JSON.parse(JSON.stringify(inqItems));
                    }

                }

            },
            getInquiryInfo2: function () { //해당 타입에 맞는 문진 정보 가져오기.
                this
                    .$axios
                    .get('/api/v1/inquiry', {
                        params: {
                            answerType: window.atob(this.$route.query.itype)
                        }
                    })
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.resultCd == '304') {
                            this.openAlert('등록된 문진데이터가 없습니다.');
                            return;
                        }

                        this.detailId = response.data.result.detailId;

                        this.inqItems = response
                            .data
                            .result
                            .categoryList
                            .filter((cat) => {
                                return cat.categoryType != "P" && cat.categoryType != "FS" && cat.categoryType != "FD"
                            });
                        for (let item of this.inqItems) {
                            if (item.categoryType == "H") {
                                console.log(item.placeList);
                                this.getPlaceHistory(item.placeList);
                            }
                        }

                        if (this.answerType == "EMP") {

                            let inqItems = response
                                .data
                                .result
                                .categoryList
                                .filter((cat) => {
                                    return cat.categoryType == "FS" || cat.categoryType == "FD" || cat.categoryType == "H"
                                });

                            for (let fam of this.famInfo) {
                                fam.inqItems = JSON.parse(JSON.stringify(inqItems));
                            }

                        }

                    })
            },
            getPlaceHistory: function (place) { //해당 타입에 맞는 방문력 정보 가져오기
                console.log('placeList', place);
                let tempList = place;
                let placeList = [];
                let placeInfo = {};
                let storeList = [];
                for (let [index, temp] of tempList.entries()) {
                    if (index == 0) {
                        let storeInfo = {};
                        placeInfo = {
                            local1: temp.local1,
                            localEn1: temp.localEn1,
                            type: temp.type
                        }
                        storeInfo = {
                            local2: temp.local2,
                            localEn2: temp.localEn2,
                            local3: temp.local3,
                            localEn3: temp.localEn3,
                            storeName: temp.storeName,
                            storeNameEn: temp.storeNameEn,
                            boundaryDate: temp.boundaryDate,
                            historyId: temp.historyId,
                            placeDate: temp.placeDate,
                            grade: temp.grade,
                            regDate: temp.regDate,
                            updDate: temp.updDate,
                            check: false
                        }
                        storeList.push(storeInfo);
                    }

                    if (index > 0) {
                        if (temp.local1 == tempList[index - 1].local1) {
                            let storeInfo = {};
                            storeInfo = {
                                local2: temp.local2,
                                local2En: temp.local2En,
                                local3: temp.local3,
                                localEn3: temp.localEn3,
                                storeName: temp.storeName,
                                storeNameEn: temp.storeNameEn,
                                boundaryDate: temp.boundaryDate,
                                historyId: temp.historyId,
                                placeDate: temp.placeDate,
                                grade: temp.grade,
                                regDate: temp.regDate,
                                updDate: temp.updDate,
                                check: false
                            }
                            storeList.push(storeInfo);
                            if (index + 1 == tempList.length) {
                                placeInfo.storeList = storeList;
                                placeList.push(placeInfo);
                                storeList = [];
                                placeInfo = {};
                            }
                            continue;
                        } else {
                            placeInfo.storeList = storeList;

                            placeList.push(placeInfo);
                            storeList = [];
                            placeInfo = {};
                            let storeInfo = {};

                            placeInfo = {
                                local1: temp.local1,
                                localEn1: temp.localEn1,
                                type: temp.type
                            }
                            storeInfo = {
                                local2: temp.local2,
                                localEn2: temp.localEn2,
                                local3: temp.local3,
                                localEn3: temp.localEn3,
                                storeName: temp.storeName,
                                storeNameEn: temp.storeNameEn,
                                boundaryDate: temp.boundaryDate,
                                historyId: temp.historyId,
                                placeDate: temp.placeDate,
                                regDate: temp.regDate,
                                updDate: temp.updDate,
                                check: false
                            }
                            storeList.push(storeInfo);
                            if (index + 1 == tempList.length) {
                                placeInfo.storeList = storeList;
                                placeList.push(placeInfo);
                                storeList = [];
                                placeInfo = {};
                            }

                        }

                    }
                }
                this.placeHistoryList = placeList;
                for (let [index, inq] of this.inqItems.entries()) {
                    if (inq.categoryType == "H") {
                        this.$set(this.inqItems[index], 'placeList', placeList);
                    }
                }

                if (this.answerType == "EMP") {
                    //직원의 경우 동거가족 조사를 위한 방문력 세팅
                    for (let k = 0; k < this.famInfo.length; k++) {
                        //깊은 복사를 하여 넣는다.
                        let temp = JSON.parse(JSON.stringify(this.placeHistoryList));

                        for (let l = 0; l < this.famInfo[k].inqItems.length; l++) {
                            if (this.famInfo[k].categoryType == "H") {
                                this.$set(this.famInfo[k].inqItem[l], 'placeList', temp);
                            }

                        }

                    }
                }

            },
            // getCategory: function (callback) {     this         .$axios
            // .get('/api/pre/get-inquiry-code')         .then((response) => {
            // this.categoryList = response.data.category;         });     callback(); },
            famCheck: function (event, index) {

                if (event.target.checked) {
                    //앞의 동거가족이 체크 돼있는지 확인
                    if (index > 0) {
                        for (var i = 0; i < index; i++) {
                            if (!this.famInfo[i].checked) {
                                this.openAlert("동거가족" + (
                                    i + 1
                                ) + "부터 체크해 주세요.");
                                event.target.checked = false;
                                return;
                            }
                        }
                    }
                } else {
                    for (let [famIndex, fam] of this.famInfo.entries()) {
                        if (famIndex > index) {
                            this
                                .famInfo[famIndex]
                                .checked = false;
                        }
                        for (let famCat of fam.inqItems) {

                            famCat.answerId = null;
                            famCat.answerContents = null;
                            if (famCat.categoryType == 'FS' || famCat.categoryType == 'FD') {
                                for (let inq of famCat.inquiryList) {
                                    inq.check = false;
                                }
                            } else if (famCat.categoryType == 'H') {
                                for (let place of famCat.placeList) {
                                    for (let store of place.storeList) {
                                        store.check = false;
                                    }
                                }

                            }

                        }

                    }
                }
            },

            openPrivacy: function () {
                console.log(this.$refs.privacy);
                let privacy = this.$refs.privacy;

                privacy
                    .classList
                    .add("modal-open");
            },
            closePrivacy: function () {
                let privacy = this.$refs.privacy;

                privacy
                    .classList
                    .remove("modal-open");
            },
            goPass: function (passCheck) {
                console.log('passCheck', passCheck);
                this
                    .$router
                    .push({
                        name: 'pass',
                        params: {
                            pass: window.btoa(passCheck)
                        },
                        query: {
                            pass: window.btoa(passCheck)
                        }
                    })

            },
            submit: function () {
                // 개인정보 동의를 했는지 체크한다. if (!this.privacyCheck) {     this.openAlert("개인정보 수집 동의를
                // 하셔야 출입증이 발급됩니다.");     return; }
                for (let item of this.inqItems) {
                    if (item.categoryType == 'M') {
                        if (item.answerId == null && item.show) {
                            this.openAlert(
                                "[" + item.questionContents + "]문진에 대한 답변을 해주세요."
                            );
                            return;
                        }
                    }
                    if (item.categoryType == 'I' || item.categoryType == 'N') {
                        if (item.answerContents == 'Y' && item.show) {
                            let checked = false;
                            for (let inq of item.inquiryList) {
                                if (inq.check) {
                                    checked = true;
                                    break;
                                }
                            }
                            if (!checked) {
                                this.openAlert(
                                    "[" + item.questionContents + "]문진에 대하여 해당있다고 하셨습니다. 상세 항목을 체크해주세요."
                                );

                                return;

                            }

                        }
                    }
                }

                let inqList = JSON.parse(JSON.stringify(this.inqItems));

                let symptomCnt = 0; // 증상이 있다고 대답한 경우 카운트

                if (this.accompanyYn) {
                    if (!this.postAccompany1) {
                        this.openAlert("동반할 보호자의 번호를 입력하세요.");
                        return;
                    }

                }

                //본인 응답 내용을 리스트로 담는다.

                for (let inqItem of inqList) {

                    if (inqItem.categoryType == 'H') {
                        console.log(inqItem.placeList);
                        let placeList = [];
                        for (let place of inqItem.placeList) {
                            for (let store of place.storeList) {
                                if (store.check) {
                                    let tempObj = {
                                        local1: place.local1,
                                        localEn1: place.localEn1,
                                        check: store.check,
                                        historyId: store.historyId,
                                        local2: store.local2,
                                        local3: store.local3,
                                        localEn2: store.localEn2,
                                        localEn3: store.localEn3,
                                        storeName: store.storeName,
                                        storeNameEn: store.storeNameEn
                                    }
                                    placeList.push(tempObj);
                                    symptomCnt++;
                                }
                            }
                        }

                    } else {
                        if (inqItem.questionType == "S" || inqItem.questionType == "D") {
                            for (let inq of inqItem.inquiryList) {
                                if (inq.acceptYn == "N") {
                                    if (inq.check) {
                                        symptomCnt++;
                                    }
                                }
                            }
                        }
                    }
                }

                // let famAnswerList = []; if (this.answerType == "EMP") {     동거가족 응답내용을 저장한다.
                // for (let fam of this.famInfo) {         for (let famItem of fam.famItems) {
                // for (let store of famItem.storeList) {                 let tempObj = {
                // codeDetailId: 'PH',                     famId: fam.famId, local1:
                // famItem.local1,                     local2: famItem.local2, local3:
                // store.local3,                     storeName: store.storeName, detailId:
                // store.detailId,                     checked: store.checked }
                // famAnswerList.push(tempObj);             }         }     } }

                console.log('symptomCnt :', symptomCnt);

                //증상이 있을 경우 확인 후 저장
                if (symptomCnt > 0 && this.answerType != "EMP") {
                    this.alertMsg = "증상이 있을 경우 원내 출입에 제한이 있습니다. <br/><br/>작성하신 문진을 최종 제출하시겠습니까?";
                } else {
                    this.alertMsg = "작성하진 문진을 최종 제출하시겠습니까?";
                }

                this.openConfirm(this.alertMsg, this.save, inqList);

                console.log('ok');
                console.log(inqList);

            },
            save: function (inqList) {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                let completePlace = true;
                if (this.$route.query.lang) {
                    if (this.$route.query.lang != "KOR" && this.$route.query.lang != "ENG") {
                        console.log('completePlace false');
                        completePlace = false;
                    }
                }

                let accompanyId = null;
                if (this.accompanyYn) {
                    accompanyId = this.preAccompany1 + this.postAccompany1;
                }

                let visitDate = this.convertDirectDate(new Date());
                if (this.$route.query.vd) {
                    console.log('yes visitDate');
                    visitDate = window.atob(this.$route.query.vd);
                }
                let refId = null;
                if (this.answerType == 'NOK') {
                    refId = window.atob(this.$route.query.pidn);
                }

                let fam1 = null;
                let fam2 = null;
                let fam3 = null;

                let famIndex = 0;
                for (let fam of this.famInfo) {
                    famIndex++;
                    if (fam.checked) {
                        if (famIndex == 1) {
                            this.checkedPlace(fam.inqItems);
                            console.log(fam.inqItems);
                            fam1 = fam.inqItems;
                        } else if (famIndex == 2) {
                            this.checkedPlace(fam.inqItems);
                            console.log(fam.inqItems);
                            fam2 = fam.inqItems;
                        } else if (famIndex == 3) {
                            this.checkedPlace(fam.inqItems);
                            console.log(fam.inqItems);
                            fam3 = fam.inqItems;
                        }
                    }
                }
                console.log(fam1);
                console.log(fam2);
                console.log(fam3);
                for (let inqItem of inqList) {
                    if (inqItem.categoryType == 'H') {
                        console.log(inqItem.placeList);
                        let placeList = [];
                        for (let place of inqItem.placeList) {
                            for (let store of place.storeList) {
                                if (store.check) {
                                    let tempObj = {
                                        local1: place.local1,
                                        localEn1: place.localEn1,
                                        check: store.check,
                                        historyId: store.historyId,
                                        local2: store.local2,
                                        local3: store.local3,
                                        localEn2: store.localEn2,
                                        localEn3: store.localEn3,
                                        storeName: store.storeName,
                                        storeNameEn: store.storeNameEn
                                    }
                                    placeList.push(tempObj);

                                }

                            }
                        }
                        inqItem.placeList = placeList;
                    }
                }

                this
                    .$axios
                    .post('/api/pre/v1/answer', {
                        identityNo: window.atob(this.$route.query.idn),
                        deviceType: 'M',
                        categoryList: inqList,
                        answerType: window.atob(this.$route.query.type),
                        visitDate: visitDate,
                        accompanyId: accompanyId,
                        completePlace: completePlace,
                        detailId: this.detailId,
                        inqType: window.atob(this.$route.query.itype),
                        refId: refId,
                        fam1: fam1,
                        fam2: fam2,
                        fam3: fam3
                    })
                    .then((response) => {
                        if (response.data.success) {
                            if (this.answerType == "EMP") {
                                this
                                    .$router
                                    .push({name: 'thankyou'})
                                return;
                            }
                            if (response.data.resultCd == 201) {
                                // 직원외 증상있음 출입 금지.
                                this
                                    .$router
                                    .push({name: 'passForbiden'})
                                return;
                            }
                            //방문일에 해당하는 문진을 이미 완료했을 경우 출입증 페이지로 이동한다.
                            console.log('complete');
                            this
                                .$router
                                .push({
                                    name: 'pass',
                                    query: {
                                        pass: window.btoa(response.data.result.passCheck)
                                    }
                                })
                            return;

                        } else {
                            this.openAlert('저장중 오류가 발생했습니다.');
                        }
                        this.btnLock = false;
                    })
                    .catch(() => {
                        this.openAlert('저장중 오류가 발생했습니다.');
                        this.btnLock = false;
                    });
            },
            checkedPlace: function (inqList) {
                for (let inqItem of inqList) {
                    if (inqItem.categoryType == 'H') {
                        console.log(inqItem.placeList);
                        let placeList = [];
                        for (let place of inqItem.placeList) {
                            for (let store of place.storeList) {
                                if (store.check) {
                                    let tempObj = {
                                        local1: place.local1,
                                        localEn1: place.localEn1,
                                        check: store.check,
                                        historyId: store.historyId,
                                        local2: store.local2,
                                        local3: store.local3,
                                        localEn2: store.localEn2,
                                        localEn3: store.localEn3,
                                        storeName: store.storeName,
                                        storeNameEn: store.storeNameEn
                                    }
                                    placeList.push(tempObj);

                                }

                            }
                        }
                        inqItem.placeList = placeList;
                    }
                }
            },
            maCheck: function (index, target) { //객관식형의 체크 여부에 따라 연결된 문진을 노출 비노출 해주는 함수.
                // let maTargetList = []; for (let target of this.inqItems[index].inquiryList) {
                // if (target.targetCategoryId != null) {
                // maTargetList.push(target.targetCategoryId);     } } for (let maTarget of
                // maTargetList) { 해당 카테고리에서 선택값입력 전에 연결된 보기 체크 해제.     for (let [index, item]
                // of this.inqItems.entries()) {         if (maTarget == item.categoryId) {
                // this.$set(this.inqItems[index], 'show', false);             for (let i = 0; i
                // < this.inqItems[index].inquiryList.length; i++) {
                // this.$set(this.inqItems[index].inquiryList[i], 'check', false);             }
                // }     } } console.log('target', target);

                this.initShow(index);

                for (let [index, item] of this.inqItems.entries()) {
                    if (item.categoryId == target) { //해당 카테고리에서 선택값에 해당하는 연결문진 표출.
                        this.$set(this.inqItems[index], 'show', true);
                    }
                }

            },
            initShow: function (index) {
                let maTargetList = [];
                for (let target of this.inqItems[index].inquiryList) {
                    if (target.targetCategoryId != null) {
                        maTargetList.push(target.targetCategoryId);
                    }
                }

                for (let maTarget of maTargetList) { //해당 카테고리에서 선택값입력 전에 연결된 보기 체크 해제.
                    for (let [index, item] of this.inqItems.entries()) {
                        if (maTarget == item.categoryId) {
                            this.$set(this.inqItems[index], 'show', false);
                            this.$set(this.inqItems[index], 'answerId', null);
                            for (let i = 0; i < this.inqItems[index].inquiryList.length; i++) {
                                this.$set(this.inqItems[index].inquiryList[i], 'check', false);
                                if (this.inqItems[index].inquiryList[i].targetCategoryId != null) {
                                    this.initShow(index);
                                }
                            }
                        }
                    }
                }

            },
            symptomChecker: function () {},
            goStep2: function () {
                for (let item of this.inqItems) {
                    if (item.categoryType == 'M') {
                        if (item.answerId == null) {
                            this.openAlert(
                                "[" + item.questionContents + "]문진에 대한 답변을 선택해주세요."
                            );
                            return;
                        }
                    }
                }
                this.step2 = true;
            },
            yesSymptom: function (event, index) {
                console.log(this.inqItems[index], index);
                console.log(event.target.innerText);

                if (this.inqItems[index].answerContents == null) {
                    this.$set(this.inqItems[index], 'answerContents', 'Y');
                    event.target.innerText = '해당없음';
                } else {
                    this.$set(this.inqItems[index], 'answerContents', null);
                    for (let inq of this.inqItems[index].inquiryList) {
                        inq.check = false;
                    }
                    event.target.innerText = '위 보기에 해당사항 있음';
                }
            },

            famYesSymptom: function (event, famIndex, index) {
                console.log(
                    'this.famInfo[famIndex].inqItems[index].answerContents',
                    this.famInfo[famIndex].inqItems[index].answerContents
                );

                if (this.famInfo[famIndex].inqItems[index].answerContents == null) {
                    this.$set(this.famInfo[famIndex].inqItems[index], 'answerContents', 'Y');
                    event.target.innerText = '해당없음';
                } else {
                    this.$set(this.famInfo[famIndex].inqItems[index], 'answerContents', null);
                    for (let inq of this.famInfo[famIndex].inqItems[index].inquiryList) {
                        inq.check = false;
                    }
                    event.target.innerText = '위 보기에 해당사항 있음';
                }

            },
            yesPlace: function (event, index) {
                console.log(this.inqItems[index], index);
                console.log(event.target.innerText);

                if (this.inqItems[index].answerContents == null) {
                    this.$set(this.inqItems[index], 'answerContents', 'Y');
                    event.target.innerText = '방문한적 없음';
                } else {
                    this.$set(this.inqItems[index], 'answerContents', null);
                    for (let place of this.inqItems[index].placeList) {
                        for (let store of place.storeList) {
                            store.check = false;
                        }
                    }
                    event.target.innerText = '위 보기에 방문한적 있음';
                }
            },
            famYesPlace: function (event, famIndex, index) {
                if (this.famInfo[famIndex].inqItems[index].answerContents == null) {
                    event.target.innerText = "방문한적 없음"
                    this.$set(this.famInfo[famIndex].inqItems[index], 'answerContents', 'Y');
                } else {
                    this.$set(this.famInfo[famIndex].inqItems[index], 'answerContents', null);
                    for (let place of this.famInfo[famIndex].inqItems[index].placeList) {
                        for (let store of place.storeList) {
                            store.check = false;
                        }
                    }
                    event.target.innerText = '위 보기에 방문한적 있음';
                }

            },
            placeDateFunc: function (val) { //날짜선텍 콤포넌트에서 선택된 날짜를 세팅한다.
                this.saveParam.endDateEmp = val;
            },

            setCalDate: function (motherIndex, placeIndex, storeIndex, placeDate) {
                this.$set(
                    this.inqItems[motherIndex].placeList[placeIndex].storeList[storeIndex],
                    'placeDate',
                    placeDate
                );
            },
            setFamCalDate: function (
                famIndex,
                motherIndex,
                placeIndex,
                storeIndex,
                placeDate
            ) {
                this.$set(
                    this.famInfo[famIndex].inqItems[motherIndex].placeList[placeIndex].storeList[storeIndex],
                    'placeDate',
                    placeDate
                );
            },
            convertSDate: function (date) {
                if (date != null) {
                    return moment(date).format('MM/DD');

                }

            },
            checkNew: function (regDate, updDate) {
                let oneAgoDate = moment(new Date())
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD');
                let sDate = moment(new Date(regDate))
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD');
                if (updDate != null) {
                    sDate = moment(new Date(updDate))
                        .subtract(1, 'days')
                        .format('YYYY-MM-DD');
                }

                if (sDate >= oneAgoDate) {
                    return true;
                }

                return false;
            },
            inqCheck: function (index) {

                if (this.inqItems[index].targetCategoryId != null) {
                    let targetCatId = this
                        .inqItems[index]
                        .targetCategoryId;
                    let inqList = this
                        .inqItems[index]
                        .inquiryList;
                    //체크된게 있으면 연결문진을 보여주고, 체크된게 하나라도 없으면 연결문진을 감춘다.
                    let checkedCnt = 0;
                    for (let inq of inqList) {
                        console.log(inq.check);
                        if (inq.check) {
                            checkedCnt++;
                        }
                    }
                    console.log(targetCatId);
                    console.log(checkedCnt);
                    if (checkedCnt == 0) {
                        for (let k = 0; k < this.inqItems.length; k++) {
                            if (this.inqItems[k].categoryId == targetCatId) {
                                this.$set(this.inqItems[k], 'show', false);
                                this.$set(this.inqItems[k], 'answerId', null);
                                this.$set(this.inqItems[k], 'answerContents', null);
                                if (this.inqItems[k].categoryType == 'M') {
                                    for (let j = 0; j < this.inqItems[k].inquiryList; j++) {
                                        this.$set(this.inqItems[k].inquiryList[j], 'check', false);
                                    }
                                }
                            }
                        }
                    } else {
                        for (let k = 0; k < this.inqItems.length; k++) {
                            if (this.inqItems[k].categoryId == targetCatId) {
                                this.$set(this.inqItems[k], 'show', true);

                            }
                        }

                    }
                }
            }
        }
    }
</script>
<style scoped="scoped" src="../assets/css/common2.css"></style>
<style scoped="scoped">
    .family_q .family_q_list {
        display: block;
    }
    .question_list .place h4.danger {
        padding-left: 20px;
        color: red;
    }
    .question_list .place h4 {
        padding-left: 20px;
        color: #ff9900;
    }
    .question_list .place li {
        border-bottom: none;
        overflow: hidden;
    }
    .question_list ul:last-child {
        width: 100%;
        margin-bottom: 20px;
        padding: 5px 0;
        border-radius: 11px;
        box-shadow: 0 2px 20px 0 rgb(0 0 0 / 9%);
        background-color: #ffffff;
        overflow: hidden;
    }
    .question_list .place li:last-child {
        border-bottom: 1px solid #eeeeee;
        overflow: hidden;
    }
    .question_list .place h5 {
        border-bottom: none;
        padding-bottom: 0;
    }

    .guide_with {
        margin-bottom: 20px;
    }

    .multi_q input[type="radio"]+label {
        height: 25px;
        background: url("../assets/images/ico_pri_unchecked.svg") no-repeat right;
        width: 100%;
        cursor: pointer;
        display: inline-block;
    }

    .multi_q input[type="radio"]:checked + label {
        background: url("../assets/images/ico_pri_check.svg") no-repeat right;
        width: 100%;
        display: inline-block;
    }

    .multi_q input[type="radio"] {
        display: none;
    }

    .multi_q input[type="radio"] + label span {
        /* padding: 0 0 0 25px; */
        width: 100%;
        display: block;
    }

    .number_box input[type="text"] {
        width: 100%;
        margin-top: 20px;
        font-size: 16px;
        border: none;
        border-bottom: 2px solid #cccccc !important;
        text-align: left;
        font-family: "NEXONLv1GothicB";
        background: #f6f6f6;
        padding: 0 20px;
        color: #797777;
    }

    .textEditor {
        width: 100%;
        margin-bottom: 20px;
        padding: 5px 0;
        border-radius: 11px;
        box-shadow: 0 2px 20px 0 rgb(0 0 0 / 9%);
        background-color: #ffffff;
        overflow: hidden;
    }

    .question_list input[type="checkbox"]+label {
        background: url("../assets/images/ico_uncheck.svg") no-repeat right;
        cursor: pointer;
        display: block;
        background-size: 18px;
    }
      .question_list input[type="checkbox"]:checked+label {
        background: url("../assets/images/ico_checked.svg") no-repeat right;
        cursor: pointer;
        display: block;
        background-size: 18px;
    }

</style>