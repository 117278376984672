<template>
    <v-app >
        <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent="persistent"
            width="290px">
            <template v-slot:activator="{ on, attrs }">
                <!-- <v-text-field v-model="date" readonly="readonly" v-bind="attrs" v-on="on"
                style="padding:0;"></v-text-field> -->
                <input
                    class="input_cal"
                    v-model="date"
                    label="적용일자를 선택하세요."
                    readonly="readonly"
                    v-bind="attrs"
                    v-on="on"/>
            </template>
            <v-date-picker
                v-model="date"
                scrollable="scrollable"
                no-title="no-title"
                :weekday-format="getWeekDay"
                :month-format="getMonth"
                :header-date-format="getHeader"
                prev-icon="mdi-skip-previous"
                next-icon="mdi-skip-next">

                <v-spacer></v-spacer>
                <v-btn text="text" color="primary" @click="modal = false">
                    취소
                </v-btn>
                <v-btn text="text" color="primary" @click="$refs.dialog.save(date)">
                    확인
                </v-btn>

            </v-date-picker>
        </v-dialog>

    </v-app>
</template>
<script>
    import mixin from '../mixin/mixin.js';

    export default {
        data: () => ({date: null, modal: false}),
        props: ['dateParam'],
        created: function () {
            this.date = this.dateParam;
        },
        watch: {
            date: function () {
                this.$emit('dateVal', this.date);
            },
            dateParam: function () {
                this.date = this.dateParam;
            }
        },
        mixins: [mixin]
    }
</script>
<style scoped="scoped">
    .input_cal {
        border: solid 1px #AAACB4;
        border-radius: 3px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        margin-top: 6px;
    }
    .v-date-picker-table--date td {
        width: 45px;
        padding: 0;
        height: 32px;
    }
</style>