<template>
  <div class="contents">
    <div class="loc">
      <li>
        <a href="javascript:void(0)">출입자</a>
      </li>
      <li>
        <a href="javascript:void(0)">협력업체 관리</a>
      </li>
      <li>
        <a href="javascript:void(0)">협력업체 사원 신규 등록</a>
      </li>
    </div>
    <div class="wapper">
      <div class="title">
        <h2>협력업체 사원 신규 등록</h2>
      </div>
      <div class="register">
        <h3>엑셀 일괄 등록</h3>
        <p>등록해야 할 사원 많을 경우 엑셀파일을 사용하여 일괄 등록할 수 있습니다.</p>
        <p>아래 엑셀 양식을 다운로드하여 작성하신 후 파일을 업로드해주세요.</p>

        <button class="btn_secondary" @click="getForm()">서식다운로드</button>
        <div class="filebox bs3-primary">
          <label for="ex_filename">파일선택</label>
          <input class="upload-name" :value="fileName" disabled="disabled"/>
          <input
              type="file"
              @change="putFile($event)"
              ref="excelFile"
              accept=".xls, .xlsx"
              name="file"
              id="ex_filename"
              class="upload-hidden"/>
          <div class="file_ok">성공</div>
        </div>
        <p>- 첨부가능 파일 : 최대용량 10MB</p>
        <p class="txt_error" v-if="size==null">파일을 선택하세요.</p>
        <p class="txt_error" v-if="size != null">- 첨부가능 파일 : 현재용량
          {{ this.size }}</p>

        <div class="reg_loader">
          <div v-show="isUploading" style="display:none;" id="loader" ref="loader"></div>
          <div v-show="isUploading" style="display:none;" id="loader_txt" ref="loader_txt" class="loader_txt">주소록을 추가하고
            있습니다.
          </div>
          <div v-show="isUploaded"
               style="display:none;"
               ref="upload_done"
               id="upload_done"
               class="animate-bottom">
            <div class="add_done" id="addr_success">
              <span class="highlight">협력업체 사원을 추가 했습니다. </span>
            </div>
            <div class="addr_fail_list">
              <dl id="addr_fail_1">
                <dt>정상 등록 수</dt>
                <dd>{{ this.successCnt }}명</dd>
              </dl>
              <dl id="addr_fail_2">
                <dt>등록 실패 수</dt>
                <dd>{{ this.failCnt }}명</dd>
              </dl>
              <div id="duplicate-list" v-if="duplicateList.length > 0">
                <table>
                  <thead>
                  <tr>
                    <th colspan="3">
                      직원 전화번호 중복으로인한 등록 제외
                    </th>
                  </tr>
                  <tr>
                    <th>
                      업체명
                    </th>
                    <th>
                      직원명
                    </th>
                    <th>
                      직원 전화번호
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item of this.duplicateList" :key="item.empCode">
                    <td>
                      {{ item.groupName }}
                    </td>
                    <td>
                      {{ item.empName }}
                    </td>
                    <td>
                      {{ item.empPhone }}
                    </td>
                  </tr>
                  </tbody>
                  <tr></tr>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="btn_wrap">
        <div class="btn_left">
          <button v-if="!complete" class="btn_secondary" @click="cancel()">취 소</button>
          <button v-if="!complete" class="btn_primary" @click="saveConfirm()">저장</button>
          <button v-if="complete" class="btn_primary" @click="cancel()">목록으로 돌아가기</button>
          <button v-if="complete" class="btn_primary" @click="saveConfirm()">저장</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from '../../mixin/mixin.js';
import XLSX from "xlsx";

export default {
  data: () => {
    return {
      excelFile: null,
      partnerCode: null,
      pname: null,
      fileName: '선택된 파일 없음',
      size: null,
      complete: false,
      successCnt: 0,
      failCnt: 0,
      duplicateList: [],
      isUploading: false,
      isUploaded: false,
    }
  },
  created: function () {
    this.partnerCode = this.$route.query.pid
    this.pname = this.$route.query.pname
  },
  mixins: [mixin],
  methods: {
    putFile: function (event) {
      if (event.target.files) {

        var bytes = parseInt(event.target.files[0].size);

        var s = [
          'bytes',
          'KB',
          'MB',
          'GB',
          'TB',
          'PB'
        ];

        var e = Math.floor(Math.log(bytes) / Math.log(1024));

        let size = 0;

        if (e == "-Infinity") {
          size = "0 " + s[0];
        } else {
          size = (bytes / Math.pow(1024, Math.floor(e))).toFixed(2) + " " + s[e];
        }

        if (size > 10) {
          this.openAlert("첨부가능한 파일의 최대 용량은 10MB를 넘을 수 없습니다.");
          return;
        }
        this.complete = true;
        this.excelFile = event
            .target
            .files[0];
        this.fileName = event
            .target
            .files[0]
            .name;
        this.size = size
      } else {
        this.initParam();
      }

    },
    cancel: function () {
      this.goList();
    },
    saveConfirm: function () {

      if (this.excelFile == null) {
        this.openAlert("첨부할 파일을 선택해 주세요.");
        return;
      }

      this.openConfirm("협력업체직원을 등록하시겠습니까?", this.save);

    },
    getForm: function () {
      this
          .$axios
          .get('/api/mng/partner/emp/v1/list/file', {responseType: "blob"})
          .then((response) => {
            console.log(response);
            var fileURL = window
                .URL
                .createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', '협력업체 사원 등록 양식.xlsx');
            document
                .body
                .appendChild(fileLink);

            fileLink.click();
          })
          .catch(() => {
            this.openAlert("서식 다운로드에 실패했습니다.");
          });
    },
    save: function () {
      if (this.btnLock) {
        return;
      } else {
        this.btnLock = true;
      }

      this.isUploading = true;

      let formDom = new FormData();
      formDom.append('empExcel', this.excelFile);
      formDom.append('partnerCode', this.partnerCode);

      this.$axios
          .post('/api/mng/partner/emp/v1/list/excel', formDom, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then((response) => {
        console.log(response.data);
        this.isUploaded = true;
        if (response.data.success) {
          this.complete = true;
          this.successCnt = response.data.result.successCnt;
          this.failCnt = response.data.result.failCnt;
          this.duplicateList = response.data.result.duplicate;
          this.initParam();
        } else {
          if (response.data.resultCd == "810") {
            this.openAlert(response.data.resultMsg);
          } else {
            this.openAlert("협력업체직원을 등록하는 도중 에러가 발생했습니다.");
          }
          this.initParam();
        }
        this.btnLock = false;
      })
          .catch(() => {
            this.openAlert("협력업체직원을 등록하는 도중 에러가 발생했습니다.");
            this.initParam();
            this.btnLock = false;
          }).finally(() => {
        this.isUploading = false;
      });

    },
    initParam: function () {
      this.fileName = '선택된 파일 없음';
      this.size = null;
      this.excelFile = null;
      this.$refs.excelFile.value = null;
    },
    goList: function () {
      this
          .$router
          .go(-1);
                // .push({
            //name: 'partnerEmpList',
            //query: {
              //pid: this.$route.query.pid,
              //pname: this.pname
            //}
          //});
    }
  }
}
</script>
<style scoped>
#duplicate-list {
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid #eeeeee;
  height: 250px;
  overflow: auto;
}
#duplicate-list th {
  text-align: center;
  background: #FFFFFF;
}
</style>