<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="">발열감지</a>
            </li>
            <li>
                <a href="">발열감지 내역</a>
            </li>
        </div>
        <div class="wapper" style="min-height:700px">
            <div class="title">
                <h2>발열감지 내역 ({{totalCount | comma}}건)</h2>
                <p class="sub_title">{{convertDateDay(new Date())}} 기준 결과입니다.</p>
            </div>
            <h3>
                <div class="btn-right">
                    <button class="btn_search" @click="openSearch()">검색</button>
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">
                            <dl class="full_width">
                                <dt>기간</dt>
                                <dd>
                                    <input type='date' v-model='startDate' @change="changeStartDate"/>
                                    ~
                                    <input type='date' v-model='endDate' @change="changeEndDate"/>
                                </dd>
                            </dl>
                            <dl class="full_width">
                                <dt>사번</dt>
                                <dd><input type="text" v-model='userId'/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>이름</dt>
                                <dd><input type="text" v-model='userName'/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>소속</dt>
                                <dd><input type="text" v-model='userDept'/></dd>
                            </dl>
                        </div>
                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'btn_filered' : filter}"
                                @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                            <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
                        </div>
                    </div>
                </div>
            </h3>
            <div class="table table_line">
                <table>
                    <colgroup>
                        <col width="20%"/>
                        <col width="10%"/>
                        <col />
                        <col width="20%"/>
                        <col width="20%"/>
                        <col width="10%"/>
                    </colgroup>
                    <tr>
                        <th>일자</th>
                        <th>사번</th>
                        <th>소속</th>
                        <th>이름</th>
                        <th>입장장소</th>
                        <th>체온</th>
                    </tr>
                    <tr v-for="(item,index) in this.resultList" :key="index">
                        <td>{{convertDateSec(item.regDate)}}</td>
                        <td>{{item.userId}}</td>
                        <td>{{item.userDept}}</td>
                        <td>{{item.userName}}</td>
                        <td>{{item.placeName}}</td>
                        <td>{{convertTemperature(item.temperature)}}</td>
                    </tr>
                </table>
            </div>
            <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" 
            :startDate="startDate" :endDate="endDate" 
            :identityNo="userId" :identityName="userName" :deptName="userDept"
            />
        </div>
    </div>
</template>
<script>
    import pagination from '../../components/commonPagination.vue'
    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';
    import $ from 'jquery'

    export default {
        data: () => {
            return {
                resultList: [],
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
                userId: null,
                userName: null,
                userDept: null,
                totalCount: 0,
                filter: false
            }
        },
        mixins: [mixin],
        created: function () {
            if(this.$route.query.page) {
                this.changeFilter()
                this.startDate = this.$route.query.startDate || moment().format("YYYY-MM-DD");
                this.endDate = this.$route.query.endDate || moment().format("YYYY-MM-DD");
                this.userId = this.$route.query.identityNo || null;
                this.userName = this.$route.query.identityName || null;
                this.userDept = this.$route.query.deptName || null;
                this.getList(this.$route.query.page)
            } else {
                this.getList(0);
            }
            
        },
        components : {
            pagination
        },
        filters: {
          comma(val) {
            if (val !== null)
              return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
        methods: {
            getList: function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }
                let param = {};

                if (this.filter) {
                    param = {
                        page: page,
                        size: 30,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        userId: this.userId,
                        userName: this.userName,
                        userDept: this.userDept
                    }
                } else {
                    param = {
                        page: page,
                        size: 30,
                        startDate: this.startDate,
                        endDate: this.endDate,
                    }
                }
                this
                .$axios
                .get('/api/mng/service/feverdetection/getFeverDetection',{
                    params: param
                })
                .then((response) => {
                    if (response.data.success) {
                        console.log(response.data.result.content);
                        this.resultList = response.data.result.content;

                        let pageInfo = response.data.result;
                        this.totalCount = pageInfo.totalElements;

                        this.pagerHandler(
                            pageInfo.number,
                            pageInfo.first,
                            pageInfo.last,
                            pageInfo.totalPages
                        );
                    } else {
                        this.openAlert("리스트 조회에 실패했습니다.");
                    }
                })
                .catch(() => {
                    this.openAlert("리스트 조회에 실패했습니다.");
                });
            },
            firstSearch: function () {
                let pageQuery = {
                    page : 0,
                    startDate : this.startDate || moment().format("YYYY-MM-DD"),
                    endDate : this.endDate || moment().format("YYYY-MM-DD"),
                    identityNo : this.userId || null,
                    identityName : this.userName || null,
                    deptName : this.userDept || null,
                }
                this.$router.push({
                    path: this.$route.path,
                    query: pageQuery
                }).catch(()=>{});
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.startDate = moment().format("YYYY-MM-DD");
                this.endDate = moment().format("YYYY-MM-DD");
                this.userId = null;
                this.userName = null;
                this.userDept = null;
            },
            changeStartDate(event) {
                this.startDate = event.target.value;
            },
            changeEndDate(event) {
                this.endDate = event.target.value;
            },
            convertTemperature: function (value) {
                if (value != null) {
                    return value.slice(0,2) + "." + value.slice(2,4);
                }
            },
            confirmDownload: function () {
                this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {
                let param = {};

                if (this.filter) {
                    param = {
                        page: 0,
                        size: 100000,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        useYn: this.useYn,
                        sendTarget: this.sendTarget,
                        regUser: this.regUser
                    }
                } else {
                    param = {
                        page: 0,
                        size: 100000,
                    }
                }
                this
                .$axios
                .get('/api/mng/service/feverdetection/getFeverDetection/excel', {
                    params: param,
                    responseType: "blob"
                })
                .then((response) => {
                    console.log(response);
                    const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download','발열감지내역_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx');
                    document.body.appendChild(link);
                    link.click();

                    if(response.status == 200) {
                          let param = {
                              fileName: '발열감지내역_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                              fileSize: response.data.size,
                              purpose: paramdata.purpose,
                              purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                          }
                          this.setDownloadHistory(param);
                    }
                })
            }
        }
    }
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
</style>
