<template>
  <div class="contents">
    <div class="loc">
      <li>
        <a href="javascript:void(0)">문진 관리</a>
      </li>
    </div>
    <div class="wapper">
      <div class="title">
        <h2>문진 관리</h2>
        <div class="btn-right">
          <button class="btn_primary" id="btn_reg" @click="opnePop()" v-if="$route.path !== '/emma/inq-list'">새 문진 등록</button>
        </div>
      </div>
      <div class="table table_line">
        <table>
          <colgroup>
            <col/>
            <col width="10%"/>
            <col width="20%"/>
            <col width="20%"/>
            <col width="20%"/>
          </colgroup>
          <tr>
            <th>문진유형</th>
            <th>상태</th>
            <th>현재버전</th>
            <th>다음버전</th>
            <th>관리</th>
          </tr>
          <tr v-for="master in masters" :key="master.masterId">
            <td>{{ master.masterName }}</td>
            <td>
              <span :class="{label_ing : master.useYn == 'Y'}">{{ master.useYn == 'Y' ? '사용중' : '미사용' }}</span>
              <img v-if="reserveRules.find(({targetCode}) => targetCode == master.masterId)" src = "@/assets/images/ico_stop-watch_blue.png" style="width: 14px; margin-top: 4px; margin-left: 2px;"/>
              
            </td>
            <td v-if="master.currntInfo">v{{ master.currntInfo.detailVer }}
              ('{{ convertDate(master.currntInfo.publishStartDate) }}
              부터 적용중 )
            </td>
            <td v-else="">-</td>
            <td v-if="master.nextInfo">v{{ master.nextInfo.detailVer }}
              ('{{ convertDate(master.nextInfo.publishStartDate) }}
              적용예정)
            </td>
            <td v-else="">-</td>
            <td>
              <button @click="copyUrl(master.masterId)">URL복사</button>
              <button @click="showQr(master.masterId)">QR코드</button>
              <button @click="manageMaster(master.masterId)">설정</button>
            </td>
          </tr>
        </table>
        <input type="hidden" id="copyUrl" ref="copyUrl" value=""/>
      </div>
    </div>
    <!-- 팝업 -->
    <modal
        name="addMaster"
        :width="540"
        :height="360"
        :adaptive="true"
        :clickToClose="false">
      <div id="popup_reg" class="pop pop_md pop_reg">
        <!-- 새 문진 등록 -->
        <div class="pop_head">
          <h2>새 문진 등록</h2>
          <a class="btn_pop_close" @click="closePop()">닫기</a>
        </div>
        <div class="pop_body">
          <dl>
            <dt>문진코드</dt>
            <dd>
              <input
                  type="text"
                  class="input_full"
                  v-model="masterId"
                  placeholder="문진유형 코드 입력"/>
            </dd>
          </dl>
          <dl>
            <dt>문진명</dt>
            <dd>
              <input
                  type="text"
                  class="input_full"
                  v-model="masterIdNm"
                  placeholder="문진유형 명 입력"/>
            </dd>
          </dl>
          <dl>
            <dt>개인정보 처리방침</dt>
            <dd>
              <select v-model="privacy">
                <option value="" disabled="disabled" selected="selected">개인정보 처리 방침을 선택해 주세요.</option>
                <option
                    v-for="(item, index) in privacyList"
                    :key="index"
                    :value="item.privacyCode">
                  {{ item.privacyVer }}
                </option>
              </select>
            </dd>
          </dl>
          <dl>
            <dt>민감정보 처리방침</dt>
            <dd>
              <select v-model="sensitive">
                <option value="" disabled="disabled" selected="selected">민감정보 처리 방침을 선택해 주세요.</option>
                <option
                    v-for="(item, index) in sensitiveList"
                    :key="index"
                    :value="item.privacyCode">
                  {{ item.privacyVer }}
                </option>
              </select>
            </dd>
          </dl>
        </div>
        <div class="pop_footer">
          <button class="btn_secondary" @click="closePop()">취 소</button>
          <button class="btn_primary" @click="next()">다 음</button>
        </div>
      </div>
    </modal>
    <modal
        name="alertMsg"
        :width="540"
        :height="190"
        :adaptive="true"
        :clickToClose="false">
      <div id="popup_reg" class="pop pop_md">
        <!-- 새 문진 등록 -->
        <div class="pop_head">
          <h2>알림</h2>
          <a class="btn_pop_close">닫기</a>
        </div>
        <div class="pop_body">
          {{ errMsg }}
        </div>
        <div class="pop_footer">
          <button class="btn_secondary" @click="closeAlert()">확 인</button>
        </div>
      </div>
    </modal>
    <modal
        name="qrcontainer"
        :width="340"
        :height="340"
        :adaptive="true"
        :clickToClose="true">

      <vue-qrcode :value="qrValue" :width="340"/>

    </modal>

  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'
import mixin from '../../mixin/mixin.js';

export default {
  data: () => ({
    masters: [],
    privacy: "",
    sensitive: "",
    privacyList: [],
    sensitiveList: [],
    masterId: null,
    masterIdNm: null,
    errMsg: null,
    btnLock: false,
    qrValue: null,
    reserveRules: [],
  }),
  mixins: [mixin],
  created: function () {
    this
        .$store
        .commit('initState');
  },
  computed: {},
  watch: {},
  mounted: function () {
    this.getMasterInfo();
    this.getPrivacyList();
  },
  components: {
    'vue-qrcode': VueQrcode
  },
  methods: {
    getMasterInfo: function () {
      console.log(this.$route.path);
      if (this.$route.path === '/emma/inq-list') {
        this.$axios
            .post('/api/mng/inq-list/filter', {masterIdList: ['ERPER']})
            .then((response) => {
              console.log("getMasterInfo", response.data);
              this.masters = response.data.result;
            })
      } else {
        this
            .$axios
            .get("/api/mng/inq-list")
            .then((response) => {
              console.log("getMasterInfo", response.data);
              this.masters = response.data.result;
              this.getReserveRule();
            })
            .catch((err) => {
              console.log(err);
            });
      }
    },
    getDetailInfo: function (masterId) {
      this
          .$axios
          .post('/api/mng/inq-detail', {masterId: masterId})
          .then((response) => {
            console.log("getDetailInfo", response.data);
            this
                .$store
                .commit({type: 'setDetailList', detailList: response.data.detailList});
            this
                .$store
                .commit({type: 'setMasterInfo', masterInfo: response.data.master});
            this
                .$store
                .commit({type: 'setMasterId', masterId: masterId});

            this
                .$router
                .push({
                  name: 'inq-detail',
                  query: {
                    masterId: masterId
                  },
                  params: {
                    masterId: masterId,
                    useYn: response
                        .data
                        .master[0]
                        .useYn
                  }
                })
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getPrivacyList: function () {
      this
          .$axios
          .get('/api/mng/common/v1/privacy')
          .then((response) => {
            console.log(response.data);
            let privacy = response.data.result;

            if (privacy.privacyList.length > 0) {
              this.privacyList = privacy.privacyList;
            } else {
              this.openAlert("등록된 개인정보가 없습니다.");
            }

            if (privacy.sensitiveList.length > 0) {
              this.sensitiveList = privacy.sensitiveList;
            } else {
              this.openAlert("등록된 민감정보가 없습니다.");
            }
          })
          .catch(() => {
            this.openAlert("정보동의서를 가져오는데 오류가 발생했습니다.");
          });
    },
    convertDate: function (date) {
      let dateVal = new Date(date);
      let year = dateVal.getFullYear();
      year = year
          .toString()
          .substring(2, 4);
      let month = dateVal.getMonth() + 1;

      let day = dateVal.getDate();
      // let hour = dateVal.getHours(); let minute = dateVal.getMinutes();

      let result = year + '.' + month
          .toString()
          .padStart(2, "0") + '.' + day
          .toString()
          .padStart(2, "0")
      // + ' ' + hour .toString() .padStart(2, "0") + ':' + minute .toString()
      // .padStart(2, "0");

      return result;
    },
    manageMaster: function (masterId) {
      this.getDetailInfo(masterId);

    },
    createInqMaster: function () {
      this
          .$router
          .push({
            name: 'inquiry-master',
            query: {
              updMode: "I",
              masterId: ""

            }
          });
    },
    opnePop: function () {
      this
          .$modal
          .show('addMaster');
    },
    closePop: function () {
      this.masterId = null;
      this.masterIdNm = null;
      this.privacy = "";
      this
          .$modal
          .hide('addMaster');
    },
    next: function () {

      let regex = /^[a-zA-Z]{3,11}$/;

      if (this.masterId == null || this.masterId == "") {
        this.errMsg = "문진 코드를 입력하세요.";
        this
            .$modal
            .show('alertMsg');
        return;
      }

      if (!regex.test(this.masterId)) {
        this.errMsg = "문진 코드는 영문 3글자 이상 10글자 이하로 입력해 주세요.";
        this
            .$modal
            .show('alertMsg');
        return;
      }

      if (this.masterIdNm != null) {
        this.masterIdNm = this
            .masterIdNm
            .trim();
      }

      if (this.masterIdNm == null || this.masterIdNm == "") {
        this.errMsg = "문진명을 입력하세요.";
        this
            .$modal
            .show('alertMsg');
        return;
      }

      if (this.masterIdNm.length > 20) {
        this.errMsg = "문진명은 20글자를 초과하여 저장할 수 없습니다.";
        this
            .$modal
            .show('alertMsg');
        return;
      }

      if (this.privacy == null || this.privacy == "") {
        this.errMsg = "개인정보 처리방침 버전을 선택하세요.";
        this
            .$modal
            .show('alertMsg');
        return;
      }

      if (this.sensitive == null || this.sensitive == "") {
        this.errMsg = "민감정보 처리방침 버전을 선택하세요.";
        this
            .$modal
            .show('alertMsg');
        return;
      }

      if (this.btnLock) {
        return;
      } else {
        this.btnLock = true;
      }

      //master 저장
      this
          .$axios
          .post('/api/mng/inq-master', {
            masterId: this.masterId,
            masterIdNm: this.masterIdNm,
            privacyCode: this.privacy,
            sensitiveCode: this.sensitive,
            updMode: 'N',
            useYn: 'N'
          })
          .then((response) => {
            console.log(response.data.result.masterId);
            this.masterId = null;
            this.masterIdNm = null;
            this.privacy = "";
            this.getMasterInfo();
            this.getDetailInfo(response.data.result.masterId);
            this.closePop();
            this.btnLock = false;
          })
          .catch((err) => {
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
            console.log(err.message);
            this.errMsg = err.response.data;
            this
                .$modal
                .show('alertMsg');
            this.btnLock = false;
          });

    },
    closeAlert: function () {
      this.errMsg = null;
      this
          .$modal
          .hide('alertMsg');
    },
    copyUrl: function (masterId) {
      let urlInput = this.$refs.copyUrl;
      this
          .$axios
          .get('/api/mng/common/v1/get-url', {
            params: {
              masterId: masterId
            }
          })
          .then((response) => {
            if (response.data.success) {
              console.log(response.data.result);
              urlInput.value = response.data.result.url;
              urlInput.type = 'text';
              urlInput.select();
              document.execCommand("Copy");
              urlInput.type = 'hidden';
              this.openAlert("URL이 복사 되었습니다.");
            } else {
              this.openAlert(response.data.resultMsg);
            }
          })
          .catch(() => {
            this.openAlert("URL 데이터를 가져오는 도중 오류가 발생했습니다.");
          });
    },
    showQr: function (masterId) {
      this
          .$axios
          .get('/api/mng/common/v1/get-url', {
            params: {
              masterId: masterId
            }
          })
          .then((response) => {
            if (response.data.success) {
              this.qrValue = response.data.result.url;
              this
                  .$modal
                  .show('qrcontainer');
            } else {
              this.openAlert(response.data.resultMsg);
            }
          })
          .catch(() => {
            this.openAlert("URL 데이터를 가져오는 도중 오류가 발생했습니다.");
          });
    },
    getReserveRule: function() {
        this.$axios.get('/api/reserveRules', {
            params: {
                targetCode: null,
                onOffType: null,
                jobStat: null,
            }
        }).then((response) => {
            this.reserveRules = response.data.result;
        })
      
    },
  }
}
</script>
<style scoped="scoped">
.pop {
  display: inline-block;
  box-shadow: none;
}
</style>