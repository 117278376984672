<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javscript:void(0)">통계</a>
            </li>
            <li>
                <a href="javscript:void(0)">일별통계</a>
            </li>
            <li>
                <a href="javscript:void(0)">수식별</a>
            </li>
        </div>
        <div class="wapper" style="min-height: 700px;">
            <div class="title">
                <h2>문진 수식로직별 통계</h2>
            </div>
            <div class="infection_search">
                <dt>날짜</dt>
                <dd>
                    <select @change="changeSearchDate">
                        <option value='recentWeek'>최근일주일</option>
                        <option value='prevWeek'>지난주</option>
                        <option value='recentMonth'>최근 1개월</option>
                        <option value='customDate'>직접지정</option>
                    </select>
                    <input type="date" :disabled="datedisabled" v-model="startDate"/>
                    ~
                    <input type="date" :disabled="datedisabled" v-model="endDate"/>
                    <button class="btn_search" @click="getList()">조회</button>
                </dd>
            </div>
            <h3>
                <div class="select_data">카테고리&nbsp;
                    <div class="select_data">
                        <select
                            v-model="answerType"
                            @change="getList(answerType)"
                            style="width: 150px; text-align: center;">
                            <option value="PT">외래</option>
                            <option value="ADMINER">입원</option>
                            <option value="HSC">건진</option>
                        </select>
                        <div class="checkbox">
                            <input
                                type="checkbox"
                                id="sort_all"
                                name="sort_all"
                                v-model="paramAll"
                                @click="updateAll($event.target)"
                                @change="paramAllCheck"/>
                            <label for="sort_all">
                                <span>전체</span>
                            </label>
                            <template v-for="(title,index) of titleInfo">
                                <input
                                    :key="'c_'+index"
                                    type="checkbox"
                                    class="datacheckbox"
                                    :id='"sort_" + index'
                                    name="check_sort"
                                    :ref="'sort_' + index"
                                    checked="checked"
                                    :value="index"
                                    @change="updateChart(index)"/>
                                <label :key="index" :for='"sort_" + index'>
                                    <span>{{title[1]}}</span>
                                </label>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="btn-right">
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                </div>
            </h3>
            <!-- 그래프 -->
            <div class="chart_wrap">
                <div style="width: 100%;">
                    <canvas id="myChart" height="300" width="1000"></canvas>
                </div>
            </div>
            <!-- 데이터 -->
            <div class="table statistics">
                <table>
                    <tr>
                        <th>날짜</th>
                        <th>요일</th>
                        <th v-for="(title,index) of titleInfo" :key="'h_' + index">{{ title[1] }}</th>
                        <th>합계</th>
                    </tr>
                    <tr v-for="(item, index) of list" :key="'v_' + index">
                        <th>{{ convertMonthDay(item.visitDate) }}</th>
                        <th>{{ convertWeekday(item.visitDate) }}</th>
                        <template v-for="(title, titleIndex) of titleInfo">
                            <td :key="'t_' + titleIndex">
                                {{ item[title[0]] }}
                            </td>
                        </template>
                        <td>{{ item['total']}}</td>
                    </tr>
                    <tfoot>
                        <tr>
                            <th colspan="2">합계</th>
                            <template v-for="(title, titleIndex) of titleInfo">
                                <td :key="'f_' + titleIndex">{{ totalMap[title[0]] }}</td>
                            </template>
                            <td>
                                {{totalMap['total']}}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from '../../mixin/mixin.js';
    import Chart from 'chart.js/auto';
    import moment from 'moment';
    export default {
        components: {},
        data: () => {
            return {
                datedisabled: true,
                startDate: moment()
                    .add(-7, 'd')
                    .format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
                chartData: null,
                list: null,
                paramAll: true,
                paramStation: true,
                paramDoc1: true,
                paramDoc2: true,
                paramConfm1: true,
                paramConfm2: true,
                answerType: 'PT',
                labels: [],
                datasets: [],
                formatData: {
                    type: 'bar',
                    label: '',
                    stack: 'type',
                    backgroundColor: '',
                    data: []
                },
                myChart: {},
                ctx: null,
                titleArray: [],
                titleCdArray: [],
                totalMap: {},
                titleInfo: []
            }
        },
        mixins: [mixin],
        mounted: function () {
            this.startDate = moment()
                .add(-7, 'd')
                .format("YYYY-MM-DD");
            this.endDate = moment().format("YYYY-MM-DD");
            this.ctx = document
                .getElementById('myChart')
                .getContext("2d");
            this.createChart(this.chartData);
            this.getList();
        },
        watch: {},
        methods: {
            changeSearchDate(event) {
                if (event.target.value == "recentWeek") {
                    this.datedisabled = true;
                    this.startDate = moment()
                        .add(-7, 'd')
                        .format("YYYY-MM-DD")
                    this.endDate = moment().format("YYYY-MM-DD")
                } else if (event.target.value == "prevWeek") {
                    this.datedisabled = true;
                    this.startDate = moment()
                        .add(-1, 'w')
                        .isoWeekday(1)
                        .format("YYYY-MM-DD")
                    this.endDate = moment()
                        .add(-1, 'w')
                        .isoWeekday(5)
                        .format("YYYY-MM-DD")
                } else if (event.target.value == "recentMonth") {
                    this.datedisabled = true;
                    this.startDate = moment()
                        .add(-1, 'M')
                        .format("YYYY-MM-DD")
                    this.endDate = moment().format("YYYY-MM-DD")
                } else if (event.target.value == "customDate") {
                    this.datedisabled = false;
                    this.startDate = moment().format("YYYY-MM-DD")
                    this.endDate = moment().format("YYYY-MM-DD")
                }
            },
            getList: function () {
                this
                    .myChart
                    .destroy();
                this.labels = [];
                this.datasets = [];
                const param = {
                    startDate: this.startDate,
                    endDate: this.endDate,
                    inqType: this.answerType
                }
                this
                    .$axios
                    .get('/api/statistics/analysis/susik-result', {params: param})
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.success) {
                            //표그래프 세팅
                            this.list = response.data.result.gridData;
                            console.log("list", this.list);
                            //그래프 데이터 세팅
                            let gridList = response.data.result.dataset;

                            this.titleArray = response.data.result.titleArray;
                            this.titleCdArray = response.data.result.titleCdArray;
                            this.totalMap = response.data.result.totalMap;
                            this.titleInfo = response.data.result.titleInfo;
                            console.log(gridList);
                            if (gridList.length > 0) {
                                for (let i = 0; i < gridList.length; i++) {
                                    let formatObj = Object.assign({}, this.formatData);
                                    formatObj.label = gridList[i].title;
                                    formatObj.backgroundColor = this.colorList[i];
                                    formatObj.data = gridList[i].values;
                                    console.log(formatObj);
                                    this
                                        .datasets
                                        .push(formatObj);
                                }
                            } else {
                                let formatObj = Object.assign({}, this.formatData);
                                this
                                    .datasets
                                    .push(formatObj);
                            }
                            for (let date of this.list) {
                                let tempArr = [];
                                tempArr.push(moment(date.visitDate).format("MM/DD"));
                                tempArr.push(
                                    moment(date.visitDate).locale('ko').format("dddd").replace('요일', '')
                                );
                                this
                                    .labels
                                    .push(tempArr);
                            }
                            this.chartData = {
                                labels: this.labels,
                                datasets: this.datasets,
                                options: {
                                    plugins: {
                                        legend: {
                                            position: 'bottom'
                                        },
                                        scales: {
                                            x: {
                                                stacked: true
                                            },
                                            y: {
                                                stacked: true
                                            }
                                        }
                                    }

                                }
                            }
                            this.createChart(this.chartData);

                        } else {
                            this.openAlert("리스트 조회에 실패했습니다.");
                        }
                    })
                    .catch(() => {
                        this.openAlert("리스트 조회에 실패했습니다.");
                        this.btnLock = false;
                    });
            },
            confirmDownload: function () {
                this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {
                const param = {
                    startDate: this.startDate,
                    endDate: this.endDate,
                    inqType: this.answerType
                }
                this
                .$axios
                .get('/api/statistics/analysis/susik-result/excel', {
                    params: param,
                    responseType: "blob"
                })
                .then((response) => {
                    console.log(response);
                    const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download','문진표통계분석_수식 종류별 ' + moment().format("YYYYMMDD_HHmmss") + '.xlsx');
                    document.body.appendChild(link);
                    link.click();

                    if(response.status == 200) {
                          let param = {
                              fileName: '문진표통계분석_스피드게이트 퇴실_구역별 ' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                              fileSize: response.data.size,
                              purpose: paramdata.purpose,
                              purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                          }
                          this.setDownloadHistory(param);
                    }
                })
            },
            changeStartDate(event) {
                this.startDate = event.target.value;
            },
            changeEndDate(event) {
                this.endDate = event.target.value;
            },
            checkUnderAvg(targetAvg) {
                if (this.smrTotalAvg) {
                    if (targetAvg != 0) {
                        if (targetAvg < this.smrTotalAvg) {
                            return true;
                        }
                    }

                }
                return false;
            },
            paramAllCheck() {
                if (this.paramAll) {
                    this.paramStation = true;
                    this.paramDoc1 = true;
                    this.paramDoc2 = true;
                    this.paramConfm1 = true;
                    this.paramConfm2 = true;
                } else {
                    this.paramStation = false;
                    this.paramDoc1 = false;
                    this.paramDoc2 = false;
                    this.paramConfm1 = false;
                    this.paramConfm2 = false;
                }

            },
            searchConditionChecker: function () {

                if ((!this.paramWest1 && !this.paramWest2 && !this.paramEastB1 && !this.paramEastFn && !this.paramEastBk && !this.paramNew && !this.paramNewB1)) {
                    return true;
                } else {
                    return false;
                }
            },
            updateAll(target) {
                const selectallcheckbox = document.getElementById('sort_all');
                let checkboxes = document.querySelectorAll('.datacheckbox');

                console.log("target", target);
                console.log("checkbox", checkboxes);
                if (target.checked === false) {
                    for (let i = 0; i < checkboxes.length; i++) {
                        checkboxes[i].checked = false;
                        this
                            .myChart
                            .hide(i);
                    }
                };
                if (target.checked === true) {
                    for (let i = 0; i < checkboxes.length; i++) {
                        checkboxes[i].checked = true;
                        this
                            .myChart
                            .show(i);
                    }
                }

            },
            updateChart(index) {
                // console.log("Object ",  this.$data);
                let checBoxList = document.getElementsByName('check_sort');
                let isAllCheked = true;
                for (let i = 0; i < checBoxList.length; i++) {
                    console.log(checBoxList[i].checked);
                    if (!checBoxList[i].checked) {
                        isAllCheked = false;
                    }
                }

                if (isAllCheked) {
                    this.paramAll = true;
                } else {
                    this.paramAll = false;
                }
                console.log();

                let value = this
                    .$refs['sort_' + index][0]
                    .value;
                console.log(value);
                const isDataShown = this
                    .myChart
                    .isDatasetVisible(value);
                console.log("data", isDataShown);
                if (isDataShown === false) {
                    this
                        .myChart
                        .show(value);
                }
                if (isDataShown === true) {
                    this
                        .myChart
                        .hide(value);
                }
            },
            createChart: function (chartData) {
                console.log('chartData', chartData);

                console.log('ctx', this.ctx);
                this.myChart = new Chart(this.ctx, {
                    type: 'bar',
                    data: chartData
                });
                console.log('myChart', this.myChart);
            },
            convertWeekday: function (date) {
                return moment(date)
                    .locale('ko')
                    .format("dddd")
                    .replace('요일', '');
            },
            convertMonthDay: function (date) {
                return moment(date).format("MM/DD");
            }
        }
    }
</script>
<style scoped="scoped">
    .select_data .checkbox {
        line-height: 35px;
    }
    .checkbox span {
        margin-right: 10px;
    }
    h3 {
        margin-bottom: 100px;
    }
</style>