<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">출입자</a>
            </li>
            <li>
                <a href="javascript:void(0)">예외 출입자</a>
            </li>
        </div>
        <div class="wapper" style="min-height:800px">
            <div class="title">
                <h2>예외 출입자</h2>
                <p class="sub_title">{{convertDateDay(new Date())}}
                    기준 출입대상 예외 출입자 목록입니다.(총
                    {{totalCount}}명)</p>
            </div>
            <h3>
                <div class="btn-right">
                    <button class="btn_search" @click="openSearch()">검색</button>
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">
                            <dl class="full_width">
                                <dt>예외출입일시</dt>
                                <dd>
                                    <input type='date' v-model='startDate' @change="changeStartDate"/>
                                    ~
                                    <input type='date' v-model='endDate' @change="changeEndDate"/>
                                </dd>
                            </dl>
                            <dl class="full_width">
                                <dt>고유번호</dt>
                                <dd><input type="text" v-model='identityNo'/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>이름</dt>
                                <dd><input type="text" v-model='identityName'/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>발급자</dt>
                                <dd><input type="text" v-model='agreeRegUser'/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>예외출입사유</dt>
                                <dd><input type="text" v-model='agreePassReason'/></dd>
                            </dl>
                        </div>
                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'btn_filered' : filter}"
                                @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                            <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
                        </div>
                    </div>
                </div>
            </h3>
            <div class="table table_line">
                <table>
                    <tr>
                        <th>예외출입허용일시</th>
                        <th>출입구분</th>
                        <th>고유번호</th>
                        <th>이름</th>
                        <th>예외출입사유</th>
                        <th>발급자</th>

                    </tr>
                    <tr v-for="(item,index) in this.list" :key="index">
                        <td>{{convertDate(item.agreePassDate)}}</td>
                        <td>{{convertType(item.answerType)}}</td>
                        <td>{{item.identityNo}}</td>
                        <td>{{item.identityName}}</td>
                        <td>{{item.agreePassReason}}</td>
                        <td>{{item.agreeRegUser}}</td>
                    </tr>
                </table>
            </div>
        <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" 
            :startDate="startDate" :endDate="endDate" 
            :identityNo ="identityNo" :identityName ="identityName" 
            :agreeRegUser ="agreeRegUser" :agreePassReason ="agreePassReason"/>
        </div>
    </div>
</template>
<script>
    import pagination from '../../components/commonPagination.vue'

    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';
    import $ from 'jquery'

    export default {
        mixins: [mixin],
        data: () => {
            return {
                list: [],
                filter: false,
                totalCount: 0,
                
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
                agreePassDate: moment().format("YYYY-MM-DD"),
                identityNo: null,
                identityName: null,
                agreeRegUser: null,
                agreePassReason: null,
                answerType : null
            }
        },
        created: function () {    
             if(this.$route.query.page) {
                this.changeFilter()
                this.startDate = this.$route.query.startDate || moment.format("YYYY-MM-DD")
                this.endDate = this.$route.query.endDate || moment.format("YYYY-MM-DD")
                this.identityNo= this.$route.query.identityNo
                this.identityName= this.$route.query.identityName
                this.agreeRegUser= this.$route.query.agreeRegUser
                this.agreePassReason= this.$route.query.agreePassReason
                this.getExceptionList(this.$route.query.page);
            } else {
                this.getExceptionList(0);
            }      
        },
        components:{
            pagination
        },
        methods: {
            getExceptionList: function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }

                let params = {};

                if (this.filter) {
                    params = {
                        page: page,
                        size: 30,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        identityNo: this.identityNo,
                        identityName: this.identityName,
                        agreeRegUser: this.agreeRegUser,
                        agreePassReason: this.agreePassReason
                    }
                } else {
                    params = {
                        page: page,
                        size: 30,
                        startDate: this.agreePassDate,
                        endDate: this.agreePassDate
                    }
                    }
                    console.log(JSON.stringify(params));
                    this
                        .$axios
                        .get('/api/mng/entrance/v1/pass/exception-permit-list', {params: params})
                        .then((response) => {
                            let result = response.data.result.content;
                            this.list = result;

                            let pageInfo = response.data.result;
                            this.totalCount = pageInfo.totalElements;

                            this.pagerHandler(
                                pageInfo.number,
                                pageInfo.first,
                                pageInfo.last,
                                pageInfo.totalPages
                            );
                        })
                },
                firstSearch: function () {
                    let pageQuery = {
                        page : 0,
                        identityNo : this.identityNo || null,
                        identityName : this.identityName || null,
                        agreeRegUser : this.agreeRegUser || null,
                        agreePassReason : this.agreePassReason || null,
                        startDate : this.startDate|| null,
                        endDate : this.endDate|| null
                    }
                    this.$router.push({
                        path: this.$route.path,
                        query: pageQuery
                    }).catch(()=>{});
                },
                openSearch: function () {
                    $('.search').slideToggle('down');
                },
                closeSearch: function () {
                    $('.search').slideToggle('up');
                },
                changeFilter: function () {
                    this.filter = !this.filter;
                    if (!this.filter) {
                        this.initSearchCnd();
                    }
                },
                initSearchCnd: function () {
                    this.startDate = moment().format("YYYY-MM-DD");
                    this.endDate = moment().format("YYYY-MM-DD");
                    this.placeCode = 'all';
                    this.passReason = null;
                    this.placeUser = null;
                },
                confirmDownload: function () {
                    this.downloadConfirm(this.confirmExcelDown);
                },
                confirmExcelDown: function (param) {
                    console.log(param.purpose);
                    console.log(param.purpose_etc);
                    this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
                },
                excelDown: function (paramdata) {
                    let params = {};
                    if (this.filter) {
                        params = {
                            page: 0,
                            size: 100000,
                            startDate: this.agreePassDate,
                            endDate: this.agreePassDate,
                            identityNo: this.identityNo,
                            identityName: this.identityName,
                            agreeRegUser: this.agreeRegUser,
                            agreePassReason: this.agreePassReason
                        }
                    } else {
                        params = {
                            page: 0,
                            size: 100000,
                            startDate: this.agreePassDate,
                            endDate: this.agreePassDate
                        }
                    }
                    this
                        .$axios
                        .get('/api/mng/entrance/v1/pass/exception-permit-list/excel', {
                            params: params,
                            responseType: "blob"
                        })
                        .then((response) => {
                            console.log(response);
                            const url = window
                                .URL
                                .createObjectURL(
                                    new Blob([response.data], {type: response.headers['content-type']})
                                );
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute(
                                'download',
                                '예외출입자관리_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx'
                            );
                            document
                                .body
                                .appendChild(link);
                            link.click();

                            if (response.status == 200) {
                                let param = {
                                    fileName: '예외출입자관리_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                                    fileSize: response.data.size,
                                    purpose: paramdata.purpose,
                                    purposeEtc: paramdata.purpose_etc == null
                                        ? ''
                                        : paramdata.purpose_etc
                                }
                                this.setDownloadHistory(param);
                            }
                        })
                },
                changeStartDate(event) {
                    this.startDate = event.target.value;
                },
                changeEndDate(event) {
                    this.endDate = event.target.value;
                },
            convertType: function (type) {
                if (type != null) {
                    if (type == "PT") {
                        return "환자";
                    } else if (type == "GST") {
                        return "방문객";
                    } else if (type == "NOK") {
                        return "보호자";
                    } else if (type == "TMP") {
                        return "방문객";
                    } else if ( type == "PEMP"){
                        return "협력업체";
                    } else if (type == "ETC") {
                        return "기타";
                    }
                }
            }                


            }
        }
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
</style>