<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="">알림서비스</a>
            </li>
            <li>
                <a href="">발송규칙 관리</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>발송규칙 관리</h2>
            </div>
            <div class="table table_line">
                <table>
                    <tr>
                        <th></th>
                        <th>구분</th>
                        <th>발송조건</th>
                        <th>발송시간</th>
                        <th>대상</th>
                        <th>발송채널</th>
                        <th>설정</th>
                    </tr>
                    <tr v-for="(item, index) in ruleList" :key="index">
                        <td>
                            <label class="switch">
                                <input
                                    type="checkbox"
                                    id="guide_check"
                                    :value="item.use"
                                    v-model="item.use"
                                    @change="changeUse($event,index)"/>
                                <span class="slider"></span>
                            </label>
                            <span class="txt">{{item.use ? '운영중' : '미운영'}}</span>
                        </td>
                        <td>{{item.sendName}}</td>
                        <td>{{item.sendCond == 'A' ? '타임아웃' : '특정시간'}}</td>
                        <td>{{item.sendTime}}
                            <span v-if="item.sendCond == 'A'">분</span>
                        </td>
                        <td>{{item.target}}</td>
                        <td>{{convertChannel(item.targetChannel)}}</td>
                        <td>
                            <button @click="openmodify(index)">설정</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <!-- 팝업 -->
        <modal
            name="popup_q_road"
            :width="320"
            :height="220"
            :adaptive="true"
            :clickToClose="false">
            <div id="popup_q_road" class="pop pop_sm">
                <!-- 새 문진 등록 -->
                <div class="pop_head">
                    <h2>설정</h2>
                    <a class="btn_pop_close" @click="closeModifyModal()">닫기</a>
                </div>
                <div class="pop_body">
                    <dl>
                        <dt>발송시간</dt>
                        <dd>
                            <v-app v-if="sendCond == 'E'">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    :return-value.sync="sendTime"
                                    transition="scale-transition"
                                    offset-y="offset-y"
                                    max-width="290px"
                                    min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <input
                                            type="text"
                                            v-model="sendTime"
                                            label="Picker in menu"
                                            prepend-icon="mdi-clock-time-four-outline"
                                            readonly="readonly"
                                            style="display:inlin-block; width:100px;"
                                            v-bind="attrs"
                                            v-on="on"/>
                                    </template>
                                    <v-time-picker
                                        v-if="menu"
                                        v-model="sendTime"
                                        full-width="full-width"
                                        color="#006769"
                                        @click:minute="$refs.menu.save(sendTime)"></v-time-picker>
                                </v-menu>
                            </v-app>
                            <div v-if="sendCond == 'A'">
                                <input type="text" v-model="sendTime" style="width:150px"/>
                                분
                            </div>
                        </dd>
                    </dl>
                </div>
                <div class="pop_footer">
                    <button class="btn_secondary" id="btn_reg_cancel" @click="closeModifyModal()">취 소</button>
                    <button class="btn_primary" @click="modifyConfirm()">확인</button>
                </div>
            </div>
        </modal>

    </div>
</template>
<script>
    import mixin from '../../mixin/mixin.js';
    export default {
        data: () => {
            return {
                ruleList: [],
                resultList: [],
                sendCond: null,
                sendTime: null,
                useYn: null,
                menu: false,
                sendCode: null
            }
        },
        mixins: [mixin],
        created: function () {
            this.getRuleList();
        },
        methods: {
            getRuleList: function () {
                this
                .$axios
                .get('/api/mng/service/send-rule/v1/rule')
                .then((response) => {
                    if (response.data.success) {
                        console.log(response.data.result);
                        this.ruleList = response.data.result;

                    } else {
                        this.openAlert("리스트 조회에 실패했습니다.");
                    }
                })
                .catch(() => {
                    this.openAlert("발송규칙을 가져오지 못하였습니다.");
                })
            },
            convertChannel: function (channel) {
                if (channel == 'ALM') {
                    return '알림톡';
                } else if (channel == 'SMS') {
                    return '단문메시지';
                } else if (channel == 'LMS') {
                    return '장문메시지'
                }
            },
            openmodify: function (index) {
                this.sendCond = this
                    .ruleList[index]
                    .sendCond;
                this.sendTime = this
                    .ruleList[index]
                    .sendTime;
                this.useYn = this
                    .ruleList[index]
                    .use;
                this.sendCode = this
                    .ruleList[index]
                    .sendCode;
                this
                    .$modal
                    .show("popup_q_road");
            },
            closeModifyModal: function () {
                this.sendCond = null;
                this.sendTime = null;
                this.sendCode = null;
                this.useYn = null;
                this
                    .$modal
                    .hide("popup_q_road");
            },
            modifyConfirm: function () {
                if (this.sendTime == null) {
                    this.openAlert("발송시간을 입력하여 주세요.");
                    return;
                }
                if (this.sendTime.trim() == '') {
                    this.openAlert("발송시간을 입력하여 주세요.");
                    return;
                }
                this.sendTime = this
                    .sendTime
                    .trim();
                if (this.sendCond == 'A') {
                    let reg = /^[1-9]{1}[0-9]{0,3}$/;
                    if (!reg.test(this.sendTime)) {
                        this.openAlert("타임아웃 형식의 경우 숫자로만 입력 가능하며 1~9999분까지 입력가능합니다.");
                        return;
                    }
                }
                this.openConfirm("변경한 내용을 저장하시겠습니까?", this.modifySendTime);
            },
            modifySendTime: function (updateUseyn) {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                this
                .$axios
                .put('/api/mng/service/send-rule/v1/rule', {
                    sendTime: this.sendTime,
                    sendCode: this.sendCode,
                    use: this.useYn
                })
                .then((response) => {
                    if (response.data.success) {
                        if('Y' !== updateUseyn)
                        this.openAlert('수정이 완료되었습니다.');
                        this.closeModifyModal();
                        this.getRuleList();
                    } else {
                        this.openAlert('수정중 에러가 발생했습니다.');
                    }
                    this.btnLock = false;
                })
                .catch(() => {
                    this.openAlert('수정중 에러가 발생했습니다.');
                    this.btnLock = false;
                });
            },
            changeUse: function (event, index) {
                this.useYn = this.ruleList[index].use;
                this.sendCode = this.ruleList[index].sendCode;
                this.modifySendTime("Y");
                console.log(this.useYn);
                console.log(event.target.value);
            }
        }
    }
</script>
<style scoped="scoped">
    .pop {
        display: inline-block;
        position: unset;
    }
</style>
