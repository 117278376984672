<template>
  <section id="tab_content1" style="min-height: 600px;">
    <h3>
      <div class="btn-right">
        <button class="btn_search" @click="openSearch()">검색</button>
        <button class="btn_download" @click="confirmDownload()">
          다운로드
        </button>
        <div class="search">
          <h3>검색</h3>
          <div class="search_detail">
            <dl class="full_width">
              <dt>환자의 병동</dt>
              <dd><input type="text" v-model="wardNm" /></dd>
            </dl>
            <dl class="full_width">
              <dt>병실</dt>
              <dd><input type="text" v-model="roomNm" /></dd>
            </dl>
            <dl class="full_width">
              <dt>환자명,보호자명</dt>
              <dd><input type="text" v-model="identityName" /></dd>
            </dl>
            <dl class="full_width">
              <dt>등록번호</dt>
              <dd><input type="text" v-model="identityNo" /></dd>
            </dl>
            <dl class="full_width">
              <dt>휴대전화번호</dt>
              <dd><input type="text" v-model="identityPhone" /></dd>
            </dl>
          </div>
          <div class="search_footer">
            <button
              class="btn_secondary btn_md btn_filer"
              :class="{ btn_filered: filter }"
              @click="changeFilter()"
            >
              {{ this.filter ? "필터해제" : "필터적용" }}
            </button>
            <button class="btn_secondary btn_md" @click="closeSearch()">
              취소
            </button>
            <button class="btn_primary btn_md" @click="getEmergencyResult(0)">
              적용
            </button>
          </div>
        </div>
      </div>
    </h3>
    <div class="table table_line" style="min-height:'700px'">
      <table>
        <tr>
          <th v-for="(item, index) in this.headers" :key="index">{{ item }}</th>
        </tr>
        <tr v-for="(item, index) in list" :key="index">
          <td>{{ item.wardNm }}</td>
          <td>{{ item.roomNm }}</td>
          <td>{{ convertIdentityNoRnok(item.identityNo) }}</td>
          <td>{{ item.accompanyName }}</td>
          <td>{{ item.identityName }}</td>
          <td>{{ item.identityPhone }}</td>
          <td>{{ item.answerDate }}</td>
          <td>{{ item.answerTime }}</td>
          <td v-for="(subItem, subIndex) in item.answerList" :key="subIndex">
            {{ subItem.answerContents }}
          </td>
        </tr>
      </table>
    </div>
    <div class="pagination">
      <ul>
        <li>
          <a
            href="javascript:void(0)"
            @click="getEmergencyResult(page.number - 1)"
            >이전</a
          >
        </li>
        <li
          v-for="(item, index) in pageNation"
          :key="index"
          class="pageNo"
          :class="{ active: item == page.number + 1 }"
        >
          <a href="javascript:void(0)" @click="getEmergencyResult(item - 1)">{{
            item
          }}</a>
        </li>
        <li>
          <a
            href="javascript:void(0)"
            @click="getEmergencyResult(page.number + 1)"
            >다음</a
          >
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import mixin from "../../mixin/mixin.js";
import moment from "moment";
import $ from "jquery";

export default {
  props: {
    masterId: {
      type: String,
      required: true,
    },
    detailId: {
      type: String,
      required: true,
    },
  },
  mixins: [mixin],
  data: () => {
    return {
      list: [],
      filter: false,
      headers: [],
      identityNo: null,
      empDept: null,
      identityName: null,
      wardNm: null,
      roomNm: null,
      identityPhone: null,
    };
  },
  mounted: function () {
    this.getEmergencyResult(0);
  },
  methods: {
    getEmergencyResult: function (page) {
      if (page < 0 || this.page.totalPages < page) {
        return;
      }

      let params = {};

      if (this.filter) {
        params = {
          page: page,
          size: 30,
          answerType: this.masterId,
          detailId: this.detailId,
          identityNo: this.identityNo,
          empDept: this.empDept,
          identityName: this.identityName,
          wardNm: this.wardNm,
          roomNm: this.roomNm,
          identityPhone: this.identityPhone,
        };
      } else {
        params = {
          page: page,
          size: 30,
          answerType: this.masterId,
          detailId: this.detailId,
        };
      }
      this.$axios
        .get("/api/mng/inquiry/v1/emergency/detail", { params: params })
        .then((response) => {
          console.log(response.data.result);

          this.list = response.data.result.listResult.content;
          this.headers = response.data.result.headers;

          let pageInfo = response.data.result.listResult;
          this.totalCount = pageInfo.totalElements;



          this.pagerHandler(
            pageInfo.number,
            pageInfo.first,
            pageInfo.last,
            pageInfo.totalPages
          );
        });
    },
    openSearch: function () {
      $(".search").slideToggle("down");
    },
    closeSearch: function () {
      $(".search").slideToggle("up");
    },
    changeFilter: function () {
      this.filter = !this.filter;
      if (!this.filter) {
        this.initSearchCnd();
      }
    },
    initSearchCnd: function () {
      this.identityNo = null;
      this.empDept = null;
      this.identityName = null;
      this.wardNm = null;
      this.roomNm = null;
      this.identityPhone = null;
    },
    confirmDownload: function () {
      this.downloadConfirm(this.confirmExcelDown);
    },
    confirmExcelDown: function (param) {
      this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
    },
    excelDown: function (paramdata) {
        let params = {};

        if (this.filter) {
          params = {
            page: 0,
            size: 100000,
            answerType: this.masterId,
            detailId: this.detailId,
            identityNo: this.identityNo,
            empDept: this.empDept,
            identityName: this.identityName,
            wardNm: this.wardNm,
            roomNm: this.roomNm,
            identityPhone: this.identityPhone,
          };
        } else {
          params = {
            page: 0,
            size: 100000,
            answerType: this.masterId,
            detailId: this.detailId,
          };
        }
        this.$axios
        .get("/api/mng/inquiry/v1/emergency/detail/excel", { params: params, responseType: "blob"})
        .then((response) => {
            console.log(response);
            const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download','긴급방문력조사_상주보호자_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx');
            document.body.appendChild(link);
            link.click();

            if(response.status == 200) {
                let param = {
                    fileName: '긴급방문력조사_상주보호자_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                    fileSize: response.data.size,
                    purpose: paramdata.purpose,
                    purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                }
                this.setDownloadHistory(param);
            }
        })
    },
    convertIdentityNoRnok: function (value) {
        if(null !== value) {
            return value.replace("A", "");
        }
    }
  },
};
</script>
<style scoped="scoped">
.search_footer .btn_filered {
  margin-right: auto;
  justify-self: start;
  background-color: #e85656;
  border: 1px solid #e85656;
  color: white;
}


.table {
  overflow: auto;
  height: 600px;
}

table {
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 0px !important;
}

table th {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 50px;
  border: solid 1px #e8e9eb;
  min-width: 200px;
}

table td {
  z-index: 0;
  background: #ffffff;
  min-width: 200px;
}

table th:nth-child(1) {
  width: 100px;
  left: 0px;
  z-index: 7;
   min-width: 100px;
}
table th:nth-child(2) {
  width: 100px;  
  left: 100px;
  z-index: 7;
   min-width: 100px;
}
table th:nth-child(3) {
  width: 100px;
  left: 200px;
  z-index: 7;
    min-width: 100px;
}
table th:nth-child(4) {
  width: 100px;
  left: 300px;
  z-index: 7;
   min-width: 100px;
}
table th:nth-child(5) {
  width: 100px;
  left: 400px;
  z-index: 7;
   min-width: 100px;  
}
table th:nth-child(6) {
  width: 150px;
  left: 500px;
  z-index: 7;
   min-width: 150px;
}
table th:nth-child(7) {
  width: 150px;
  left: 650px;
  z-index: 7;
  min-width: 150px;
}
table th:nth-child(8) {
  width: 150px;
  left: 800px;
  z-index: 7;
  min-width: 150px;
}


table td:nth-child(1) {
 position: sticky;
  width: 100px;
  left: 0px;
  z-index: 6;
   min-width: 100px;
}
table td:nth-child(2) {
   position: sticky;
  width: 100px;  
  left: 100px;
  z-index: 6;
   min-width: 100px;
}
table td:nth-child(3) {
   position: sticky;
  width: 100px;
  left: 200px;
  z-index: 6;
    min-width: 100px;
}
table td:nth-child(4) {
 position: sticky;
  width: 100px;
  left: 300px;
  z-index: 6;
   min-width: 100px;
}
table td:nth-child(5) {
 position: sticky;
  width: 100px;
  left: 400px;
  z-index: 6;
   min-width: 100px;  
}
table td:nth-child(6) {
 position: sticky;
  width: 150px;
  left: 500px;
  z-index: 6;
   min-width: 150px;
}
table td:nth-child(7) {
 position: sticky;
  width: 150px;
  left: 650px;
  z-index: 6;
  min-width: 150px;
}
table td:nth-child(8) {
 position: sticky;
  width: 150px;
  left: 800px;
  z-index: 6;
  min-width: 150px;
}

.search {
  z-index: 11;
}
</style>
