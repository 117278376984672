<template>
    <div id="popup_reg" class="pop pop_md">

        <div class="pop_head">
            <h2>보호자 출입증 만료</h2>
            <a class="btn_pop_close" href="javascript:void(0)" @click="close">닫기</a>
        </div>
        <div class="pop_body">
            <dl>        
            <dt>기존 보호자 전화번호</dt>
                <dd>    
                    <input
                        type="text"
                        v-model="IdNo"
                        placeholder="기존 보호자 전화번호"
                        readonly="readonly"
                        style="width:60%;"/>
                </dd>
            </dl>
            <dl>
            <dt>새 보호자 전화번호</dt>
                <dd>
                    <input
                        type="text"
                        v-model="newIdNo"
                        placeholder="새 보호자 전화번호"
                        style="width:60%;"/>
                </dd>
            </dl>
        </div>
        <div class="pop_footer">
            <button class="btn_secondary" @click="cancelBtn">취소</button>
            <button class="btn_secondary" @click="okBtn">변경</button>
        </div>
    </div>
</template>
<script>
    import mixin from '../mixin/mixin.js';
    export default {
        mixins: [mixin],
        data: () => {
            return {
                newIdNo: null
            }
        },
        props:['setExpireAnswer', 'IdNo','answers'],
        watch: {
            IdNo: function (newVal) {
                IdNo = newVal
                    .replace(/[^0-9]/g, '')
                    .substring(0, 11);
            },
            newIdNo: function (newVal) {
                this.newIdNo = newVal
                    .replace(/[^0-9]/g, '')
                    .substring(0, 11);
            },
        },
        methods: {
            cancelBtn() {
                this.$emit('close');
            },
            okBtn() {
                if (this.newIdNo == null || this.newIdNo == ''){
                    this.openAlert("새 보호자 전화번호를 입력해주세요");
                    return;
                }
                this.newIdNo = this.newIdNo.replace(/-/gi, "").trim();
                if (this.newIdNo.length > 11) {
                    this.openAlert("휴대전화번호는 11자를 넘을 수 없습니다.");
                    return;
                }
                this.setExpireAnswer(this.answers, this.newIdNo);
                this.$emit('close');
            },
            close() {
                this.$emit('close');
            },
        }
    }

</script>
<style scoped="scoped">
    .pop {
        display: inline-block;
        position: unset;
    }
</style>