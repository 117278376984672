<template>
    <section id="tab_content3">
        <h3>
            <div class="btn-right">
                <button class="btn_search" @click="openSearch()">검색</button>
                <button class="btn_download" @click="confirmDownload()">다운로드</button>
                <div class="search">
                    <h3>검색</h3>
                    <div class="search_detail">
                        <dl class="full_width">
                            <dt>내원일</dt>
                            <dd>
                                <input type='date' v-model='startDate' @change="changeStartDate"/>
                                ~
                                <input type='date' v-model='endDate' @change="changeEndDate"/>
                            </dd>
                        </dl>
                        <dl class="full_width">
                            <dt>환자등록번호</dt>
                            <dd><input type="text" v-model='identityNo'/></dd>
                        </dl>
                        <dl class="full_width">
                            <dt>면회객 연락처</dt>
                            <dd><input type="text" v-model='phone'/></dd>
                        </dl>
                        <dl class="full_width">
                            <dt>방문장소</dt>
                            <dd><input type="text" v-model='purpose'/></dd>
                        </dl>

                    </div>
                    <div class="search_footer">
                        <button
                            class="btn_secondary btn_md btn_filer"
                            :class="{'btn_filered' : filter}"
                            @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                        <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                        <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
                    </div>
                </div>
            </div>
        </h3>
        <div class="table table_line">
            <table>
                <tr>
                    <th>환자등록번호</th>
                    <th>구분</th>
                    <th>면회객 연락처</th>
                    <th>방문장소</th>
                    <th>출입일시</th>
                    <th>출입장소</th>
                    <th>퇴실일시</th>
                    <th>퇴실장소</th>
                    <th>출입기록</th>
                </tr>
                <tr v-for="(item,index) in this.plist" :key="index">
                    <td>{{item.refNo}}</td>
                    <td>{{item.gubun}}</td>
                    <td>{{convertPhone(item.phone)}}</td>
                    <td>{{item.purpose}}</td>
                    <td>{{item.regDateIn}}</td>
                    <td>{{item.placeNameIn}}</td>
                    <td>{{item.regDateOut}}</td>
                    <td>{{item.placeNameOut}}</td>
                    <td style="color: #bdbdbd">
                        <button v-if="item.regDateIn !== null" @click="getInoutDetail(item)">보기</button>
                        <button v-if="checkAnswerYn(item)" @click="expireAnswer(item)">출입증 만료</button>
                        {{ checkAnswerYn(item) ? '' :'(만료됨)'}}
                    </td>
                </tr>
            </table>
        </div>
      <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" 
            :startDate="startDate" :endDate="endDate" :phone='phone' :purpose='purpose'
            :identityNo="identityNo" :tab="$route.query.tab"/>
    </section>
</template>
<script>
import pagination from '../../components/commonPagination.vue'

import mixin from '../../mixin/mixin.js';
import moment from 'moment';
import $ from 'jquery';

export default {
    mixins: [mixin],
    data: () => {
        return {plist: [], filter: false, startDate: moment().format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD"), identityNo: null, phone: null, purpose: null}
    },
    mounted: function () {
        if(this.$route.query.page) {
            this.changeFilter()
            this.startDate = this.$route.query.startDate || moment.format("YYYY-MM-DD")
            this.endDate = this.$route.query.endDate || moment.format("YYYY-MM-DD")
            this.phone= this.$route.query.phone || null
            this.identityNo = this.$route.query.identityNo || null
            this.purpose= this.$route.query.purpose || null
            this.getPassPtNokResult(this.$route.query.page);
        } else {
            this.getPassPtNokResult(0);
        }
    },
    components : {
        pagination
    },
    methods: {
        getPassPtNokResult: function (page) {
            if (page < 0 || this.page.totalPages < page) {
                return;
            }

            let params = {};

            if (this.filter) {
                let filterObj = {
                    page: page,
                    size: 30,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    patno: this.identityNo,
                    phone: this.phone,
                    purpose: this.purpose,
                }
                params = filterObj;
            } else {
                let filterObj = {
                    page: page,
                    size: 30,
                    startDate: this.startDate,
                    endDate: this.endDate
                }
                params = filterObj;
            }
            this.$axios
            .get('/api/mng/entrance/v2/pass/icuGuset', {params: params})
            .then((response) => {
                this.plist = response.data.result.content;

                //console.log(JSON.stringify(this.plist));
                let pageInfo = response.data.result;
                this.totalCount = pageInfo.totalElements;
                this.$emit('totalCountFunc', this.totalCount);

                this.pagerHandler(
                    pageInfo.number,
                    pageInfo.first,
                    pageInfo.last,
                    pageInfo.totalPages
                );
            })
        },
        firstSearch: function () {
            let pageQuery = {
                page : 0,
                tab : this.$route.query.tab,
                identityNo : this.identityNo || null,
                phone : this.phone || null,
                purpose : this.purpose || null,
                startDate : this.startDate|| null,
                endDate : this.endDate|| null
            }
            this.$router.push({
                path: this.$route.path,
                query: pageQuery
            }).catch(()=>{});
        },
        openSearch: function () {
            $('.search').slideToggle('down');
        },
        closeSearch: function () {
            $('.search').slideToggle('up');
        },
        changeFilter: function () {
            this.filter = !this.filter;
            if (!this.filter) {
                this.initSearchCnd();
            }
        },
        initSearchCnd: function () {
            this.startDate = moment().format("YYYY-MM-DD");
            this.endDate = moment().format("YYYY-MM-DD");
            this.name = null;
            this.identityNo = null;
        },
        changeStartDate(event) {
            this.startDate = event.target.value;
        },
        changeEndDate(event) {
            this.endDate = event.target.value;
        },
        confirmDownload: function () {
            this.downloadConfirm(this.confirmExcelDown);
        },
        confirmExcelDown: function (param) {
            this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
        },
        excelDown: function (paramdata) {
            let params = {};

            if (this.filter) {
                let filterObj = {
                    page: 0,
                    size: 100000,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    patno: this.identityNo,
                    phone: this.phone,
                }
                params = filterObj;
            } else {
                let filterObj = {
                    page: 0,
                    size: 100000,
                    startDate: this.startDate,
                    endDate: this.endDate
                }
                params = filterObj;
            }
            this.$axios
            .get('/api/mng/entrance/v1/pass/icuGuset/excel', {params: params,responseType: "blob"})
            .then((response) => {
                console.log(response);
                const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download','출입자관리_중환자실면회객_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx');
                document.body.appendChild(link);
                link.click();

                if(response.status == 200) {
                      let param = {
                          fileName: '출입자관리_중환자실면회객_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                          fileSize: response.data.size,
                          purpose: paramdata.purpose,
                          purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                      }
                      this.setDownloadHistory(param);
                }
            })
        },
        getInoutDetail: function(item) {
            this.$router.push({
                name: 'detail-inout',
                params: {
                    userId: item.identityNo,
                    visitDate: item.visitDate,
                    name: item.name,
                    gubun: item.gubun
                }
            });
        },
        checkAnswerYn: function(item) {
            return (item.useYn == 'Y')
        },
        expireAnswer: function(item){
            this.$axios
            .put('/api/mng/entrance/v2/pass/expireICUGST', {
                startDate: item.visitDate, //문진 검색시에 필요해서 임시로 사용
                endDate: this.endDate,
                identityNo: item.identityNo,
            })
            .then((response) => {
                if (response.data.success) {
                    console.log(response.data.result);
                    this.openAlert("해당 출입증을 만료하였습니다.");
                } else {
                    this.openAlert("오류가 발생했습니다.");
                }
            })
            .catch(() => {
                this.openAlert("오류가 발생했습니다.");
            })
            .finally(() => {
              if(this.$route.query.page) {
                this.getPassPtNokResult(this.$route.query.page);
              } else {
                this.getPassPtNokResult(0);
              }
            })
        }
    }
};
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
    .btn_md_custom {
        padding: 0 5px;
        min-width: auto;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        display: inline;
        font-weight: 600;
        color: #ffffff;
        border: 1px solid transparent
    }
</style>
