<template>
  <div id="popup_reg" class="pop pop_md">
    <div class="pop_head">
      <h2>응급실 보호자 추가 인적사항 기재</h2>
      <a class="btn_pop_close" href="javascript:void(0)" @click="close">닫기</a>
    </div>
    <div class="pop_body">
      <dl>
        <dt>이름</dt>
        <dd>
          <input type="text" v-model="nokName" style="width:60%;">
        </dd>
      </dl>
      <dl>
        <dt>연락처</dt>
        <dd>
          <input type="text" v-model="nokPhone" style="width:60%;">
        </dd>
      </dl>
      <dl>
        <dt>관계</dt>
        <dd>
          <input type="text" v-model="relation" style="width:60%;">
        </dd>
      </dl>
    </div>
    <div class="pop_footer">
      <button class="btn_secondary" @click="cancelBtn">취소</button>
      <button class="btn_secondary" @click="okBtn">변경</button>
    </div>
  </div>
</template>
<script>
import mixin from '../mixin/mixin.js';

export default {
  mixins: [mixin],
  data: () => {
    return {
      nokName: null,
      nokPhone: null,
      relation: null,
    }
  },
  props: {
    patient: Object //추가 버튼 클릭 시 넘어오는 환자 정보
  },
  watch: {},
  methods: {
    cancelBtn() {
      this.$emit('close');
    },
    async okBtn() {
      // 추가 로직
      if (!this.nokPhone && !this.nokName && !this.relation) {
        this.openAlert("보호자 인적사항을 입력하여 주세요.");
        return;
      }
      const phoneEx = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/;
      if (this.nokPhone && !phoneEx.test(this.nokPhone)) {
        this.openAlert('올바른 전화번호 형식을 입력하세요.')
        return;
      }
      await this.$axios.post('/api/mng/entrance/emma/relation', {
        seq: this.patient.seq,
        nokName: this.nokName,
        nokPhone: this.convertPhone(this.nokPhone),
        relation: this.relation
      }).then( response => {
         if(!response.data.success) {
           this.openAlert("보호자 등록중 에러가 발생했습니다.");
         } else {
           this.$emit('save', 'save!!');
         }
      }).catch(() => {
        this.openAlert("보호자 등록중 에러가 발생했습니다.");
      })
    },
    close() {
      this.$emit('close');
    },
  }
}

</script>
<style scoped="scoped">
.pop {
  display: inline-block;
  position: unset;
}
</style>