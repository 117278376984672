<template>

    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">마스터관리</a>
            </li>
            <li>
                <a href="javascript:void(0)">유행감염병관리</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>유행감염병관리 ({{totalCount | comma}}건)</h2>
            </div>
            <div class="infection_wrap">
                <div class="infection_list">
                    <h3>유행감염병관리
                        <div class="btn-right">
                            <button class="btn_primary" @click="syncConfirm">동기화</button>
                            <button class="btn_search" @click="openSearch1">검색</button>
                            <div class="search first">
                                <h3>검색</h3>
                                <div class="search_detail">
                                    <dl class="full_width">
                                        <dt>등급</dt>
                                        <dd><input type="text" v-model="diseaseGrade"/></dd>
                                    </dl>
                                    <dl class="full_width">
                                        <dt>감염병 이름</dt>
                                        <dd><input type="text" v-model="diseaseName"/></dd>
                                    </dl>
                                </div>
                                <div class="search_footer">
                                    <button
                                        class="btn_secondary btn_md btn_filer"
                                        :class="{'btn_filered' : filter1}"
                                        @click="changeFilter1()">{{this.filter1 ? "필터해제" : "필터적용" }}</button>
                                    <button class="btn_secondary btn_md" @click="closeSearch1()">취소</button>
                                    <button class="btn_primary btn_md" @click="getList(0)">적용</button>
                                </div>
                            </div>
                        </div>
                    </h3>
                    <div class="table table_line">
                        <table>
                            <tr>
                                <th>분류</th>
                                <th>감염병</th>
                            </tr>
                            <tr v-for="(item,index) in this.diseasesList" :key="index" @click="detailDiseases(item.seq)">
                				<td v-if="item.seq == indsCd" class="tablelineTd">{{item.diseaseGrade}}</td>
                                <td v-else>{{item.diseaseGrade}}</td>
                                <td v-if="item.seq == indsCd" class="tablelineTd">{{item.diseaseName}}</td>
                				<td v-else>{{item.diseaseName}}</td>
                			</tr>
                        </table>
                    </div>
                </div>
                <div class="code_list">
                    <h3>분류코드관리
                        <div class="btn-right">
                            <input
                                type="checkbox"
                                id="useYnSearch"
                                v-model="useYnSearch"
                                class="checkbox_right"
                                value="Y"
                                @click="checkSearchUseYn($event)"/>
                            <label for="useYnSearch">
                                <span>사용안함 포함
                                </span>
                            </label>
                            <button class="btn_primary" @click="addRow">신규등록</button>
                            <button class="btn_search" @click="openSearch2">검색</button>
                            <div class="search second">
                                <h3>검색</h3>
                                <div class="search_detail">
                                    <dl class="full_width">
                                        <dt>분류코드</dt>
                                        <dd><input type="text" v-model="classCodeSearch"/></dd>
                                    </dl>
                                    <dl class="full_width">
                                        <dt>분류명</dt>
                                        <dd><input type="text" v-model="classCodeNameSearch"/></dd>
                                    </dl>
                                </div>
                                <div class="search_footer">
                                    <button
                                        class="btn_secondary btn_md btn_filer"
                                        :class="{'btn_filered' : filter2}"
                                        @click="changeFilter2()">{{this.filter2 ? "필터해제" : "필터적용" }}</button>
                                    <button class="btn_secondary btn_md" @click="closeSearch2()">취소</button>
                                    <button class="btn_primary btn_md" @click="getPlaceClass(0)">적용</button>
                                </div>
                            </div>
                        </div>
                    </h3>
                    <div class="table table_line">
                        <table>
                            <colgroup>
                                <col width="10%"/>
                                <col width="10%"/>
                                <col width="20%"/>
                                <col width="10%"/>
                                <col width="10%"/>
                                <col width="10%"/>
                                <col width="10%"/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>분류코드</th>
                                    <th>분류명</th>
                                    <th>비고</th>
                                    <th>등록일시</th>
                                    <th>등록자</th>
                                    <th>사용여부</th>
                                    <th>관리</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in this.placeClassList" :key="index" @click="detailPlaceClassCode(item.classCode, item.addYnClassCode)">
                                    <template v-if="item.addYnClassCode">
                                        <td><input style="width: 100%;" type="text" v-model="item.classCode" readonly/></td>
                                    </template>
                                    <template v-else>
                                        <td v-if="item.classCode == classCode" class="tablelineTd">
                                            {{item.classCode}}
                                        </td>
                                        <td v-else>{{item.classCode}}</td>
                                    </template>
                                    <template v-if="item.addYnClassCode">
                                        <td><input style="width: 100%;" type="text" v-model="item.className"/></td>
                                    </template>
                                    <template v-else>
                                        <td v-if="item.classCode == classCode" class="tablelineTd">
                                            {{item.className}}
                                        </td>
                                        <td v-else>{{item.className}}</td>
                                    </template>
                                    <template v-if="item.addYnClassCode">
                                        <td><input style="width: 100%;" type="text" v-model="item.comment"/></td>
                                    </template>
                                    <template v-else>
                                        <td v-if="item.classCode == classCode" class="tablelineTd">
                                            {{item.comment}}
                                        </td>
                                        <td v-else>{{item.comment}}</td>
                                    </template>
                                    <template v-if="item.addYnClassCode">
                                        <td></td>
                                    </template>
                                    <template v-else>
                                        <td v-if="item.classCode == classCode" class="tablelineTd">
                                            {{convertDate(item.regDate)}}
                                        </td>
                                        <td v-else>{{convertDate(item.regDate)}}</td>
                                    </template>
                                    <template v-if="item.addYnClassCode">
                                        <td></td>
                                    </template>
                                    <template v-else>
                                        <td v-if="item.classCode == classCode" class="tablelineTd">
                                            {{item.regUser}}
                                        </td>
                                        <td v-else>{{item.regUser}}</td>
                                    </template>
                                    <template v-if="item.addYnClassCode">
                                        <td></td>
                                    </template>
                                    <template v-else>
                                        <td v-if="item.classCode == classCode" class="tablelineTd">
                                            {{item.useYn == "Y" ? "사용" : "사용안함"}}
                                        </td>
                                        <td v-else>{{item.useYn == "Y" ? "사용" : "사용안함"}}</td>
                                    </template>
                                    <template v-if="item.addYnClassCode">
                                        <td><button @click="removeRow(item)">취소</button>
                                            <button @click="addPlaceClass(item)">추가</button></td>
                                    </template>
                                    <template v-else>
                                        <td v-if="item.classCode == classCode" class="tablelineTd">
                                            <button @click="modifyPlaceClass(item.classCode)">수정</button>
                                        </td>
                                        <td v-else><button @click="modifyPlaceClass(item.classCode)">수정</button></td>
                                    </template>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="infection_date">
                        <h3>적용일자관리
                            <div class="btn-right">
                                <div class="btn-right">
                                    <button class="btn_secondary" v-if="this.modifyYnApplyDate" @click="setCancelApplyDate">취 소</button>
                                    <button class="btn_primary" v-if="this.modifyYnApplyDate" @click="confirmSaveApplyDate">저 장</button>
                                    <button class="btn_primary" v-if="this.empList.length > 0 && !this.modifyYnApplyDate" @click="setModifyApplyDate">수정</button>
                                </div>
                            </div>
                        </h3>
                        <div class="table table_line">
                            <table>
                                <tr>
                                    <th>유형</th>
                                    <th>기준일</th>
                                    <th>1등급</th>
                                    <th>2등급</th>
                                    <th>3등급</th>
                                    <th>4등급</th>
                                    <th>5등급</th>
                                </tr>
                                <tr v-if="!modifyYnApplyDate">
                                    <td>직원</td>
                                    <td v-for="(item) in this.empList.filter(item => {return item.applyDateGrade == 1})" :key="item.applyCode">
                                        {{convertReferDateValue(item.referDateValue)}}
                                    </td>
                                    <td
                                        v-for="(item) in this.empList.filter(item => {return item.applyDateGrade == 1})"
                                        :key="item.applyCode">{{item.applyDateValue}}일</td>
                                    <td
                                        v-for="(item) in this.empList.filter(item => {return item.applyDateGrade == 2})"
                                        :key="item.applyCode">{{item.applyDateValue}}일</td>
                                    <td
                                        v-for="(item) in this.empList.filter(item => {return item.applyDateGrade == 3})"
                                        :key="item.applyCode">{{item.applyDateValue}}일</td>
                                    <td
                                        v-for="(item) in this.empList.filter(item => {return item.applyDateGrade == 4})"
                                        :key="item.applyCode">{{item.applyDateValue}}일</td>
                                    <td
                                        v-for="(item) in this.empList.filter(item => {return item.applyDateGrade == 5})"
                                        :key="item.applyCode">{{item.applyDateValue}}일</td>
                                </tr>
                                <tr v-if="modifyYnApplyDate">
                                    <td>직원</td>
                                    <td v-for="(item) in this.empList.filter(item => {return item.applyDateGrade == 1})" :key="item.applyCode">
                                        <select v-model="item.referDateValue" @change="changeEmpReferDateValue">
                                            <option v-for="referDateValue in referDateValueList" :value="referDateValue.value" :key="referDateValue.value">
                                                {{referDateValue.text}}
                                            </option>
                                        </select>
                                    </td>
                                    <td
                                        v-for="(item) in this.empList.filter(item => {return item.applyDateGrade == 1})"
                                        :key="item.applyCode"><input type="text" v-model="item.applyDateValue"/></td>
                                    <td
                                        v-for="(item) in this.empList.filter(item => {return item.applyDateGrade == 2})"
                                        :key="item.applyCode"><input type="text" v-model="item.applyDateValue"/></td>
                                    <td
                                        v-for="(item) in this.empList.filter(item => {return item.applyDateGrade == 3})"
                                        :key="item.applyCode"><input type="text" v-model="item.applyDateValue"/></td>
                                    <td
                                        v-for="(item) in this.empList.filter(item => {return item.applyDateGrade == 4})"
                                        :key="item.applyCode"><input type="text" v-model="item.applyDateValue"/></td>
                                    <td
                                        v-for="(item) in this.empList.filter(item => {return item.applyDateGrade == 5})"
                                        :key="item.applyCode"><input type="text" v-model="item.applyDateValue"/></td>
                                </tr>
                                <tr v-if="!modifyYnApplyDate">
                                    <td>직원외</td>
                                    <td v-for="(item) in this.etcList.filter(item => {return item.applyDateGrade == 1})" :key="item.applyCode">
                                        {{convertReferDateValue(item.referDateValue)}}
                                    </td>
                                    <td
                                        v-for="(item) in this.etcList.filter(item => {return item.applyDateGrade == 1})"
                                        :key="item.applyCode">{{item.applyDateValue}}일</td>
                                    <td
                                        v-for="(item) in this.etcList.filter(item => {return item.applyDateGrade == 2})"
                                        :key="item.applyCode">{{item.applyDateValue}}일</td>
                                    <td
                                        v-for="(item) in this.etcList.filter(item => {return item.applyDateGrade == 3})"
                                        :key="item.applyCode">{{item.applyDateValue}}일</td>
                                    <td
                                        v-for="(item) in this.etcList.filter(item => {return item.applyDateGrade == 4})"
                                        :key="item.applyCode">{{item.applyDateValue}}일</td>
                                    <td
                                        v-for="(item) in this.etcList.filter(item => {return item.applyDateGrade == 5})"
                                        :key="item.applyCode">{{item.applyDateValue}}일</td>
                                </tr>
                                <tr v-if="modifyYnApplyDate">
                                    <td>직원외</td>
                                    <td v-for="(item) in this.etcList.filter(item => {return item.applyDateGrade == 1})" :key="item.applyCode">
                                        <select v-model="item.referDateValue" @change="changeEtcReferDateValue">
                                            <option v-for="referDateValue in referDateValueList" :value="referDateValue.value" :key="referDateValue.value">
                                                {{referDateValue.text}}
                                            </option>
                                        </select>
                                    </td>
                                    <td
                                        v-for="(item) in this.etcList.filter(item => {return item.applyDateGrade == 1})"
                                        :key="item.applyCode">
                                        <input type="text" v-model="item.applyDateValue"/></td>
                                    <td
                                        v-for="(item) in this.etcList.filter(item => {return item.applyDateGrade == 2})"
                                        :key="item.applyCode">
                                        <input type="text" v-model="item.applyDateValue"/></td>
                                    <td
                                        v-for="(item) in this.etcList.filter(item => {return item.applyDateGrade == 3})"
                                        :key="item.applyCode">
                                        <input type="text" v-model="item.applyDateValue"/></td>
                                    <td
                                        v-for="(item) in this.etcList.filter(item => {return item.applyDateGrade == 4})"
                                        :key="item.applyCode">
                                        <input type="text" v-model="item.applyDateValue"/></td>
                                    <td
                                        v-for="(item) in this.etcList.filter(item => {return item.applyDateGrade == 5})"
                                        :key="item.applyCode">
                                        <input type="text" v-model="item.applyDateValue"/></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';
    import $ from 'jquery'

    export default {
        data: () => {
            return {
                diseasesList: [], totalCount: 0, diseaseGrade: null, diseaseName: null,
                placeClassList: [], placeClassAll: [], classCode: null, classCodeName: null, useYn: 'Y', useYnSearch: false, classCodeSearch: null, classCodeNameSearch: null,
                etcList: [], empList: [], modifyYnApplyDate: false,
                indsCd: null, addYnClassCode: false,
                filter1: false, filter2: false,
                referDateValueList: [
                    {value:"EXP", text:"노출종료일자"},
                    {value:"REG", text:"등록일자"},
                    {value:"UPD", text:"수정일자"},
                ]
            }
        },
        created: function () {
            this.getPlaceClassAll(0)
            this.getList(0);
            this.indsCd = this.$route.params.code;
            if(this.indsCd !== null && this.indsCd !== undefined) {
                this.detailDiseases(this.indsCd);
            }
        },
        mixins: [mixin],
        filters: {
          comma(val) {
            if (val !== null)
              return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
        methods: {
            getList: function (page) {
                let param = {};
                if (this.filter1) {
                    param = {
                        page: 0,
                        size: 100000,
                        diseaseGrade: this.diseaseGrade,
                        diseaseName: this.diseaseName
                    }
                } else {
                    param = {
                        page: 0,
                        size: 100000,
                    }
                }
                console.log(param)
                this
                .$axios
                .get('/api/mng/service/master/v1/epidemic', {
                    params: param
                })
                .then((response) => {
                    if (response.data.success) {
                        this.diseasesList = response.data.result.content;
                        let pageInfo = response.data.result;
                        this.totalCount = pageInfo.totalElements;
                    }
                })
                .catch(() => {
                    this.openAlert("감염병 조회중 오류가 발생했습니다.");
                });
            },
            detailDiseases: function(code) {
                this.classCode = null;
                this.indsCd = code;
                this.empList = [];
                this.etcList = [];
                this.getPlaceClass(0);
            },
            detailPlaceClassCode: function(classCode, addYnClassCode) {
                if(!addYnClassCode) {
                    this.classCode = classCode;
                    this.getApplyDate();
                }
            },
            syncConfirm: function() {
                this.openConfirm("감염병코드 동기화를 하시겠습니까?", this.getEpidemic);
            },
            getEpidemic: function () {
                this
                .$axios
                .get('/api/v1/epidemic')
                .then((response) => {
                    if (response.data.success) {
                        this.openAlertClosed("감염병코드 동기화가 완료되었습니다.", this.getList(0));
                    }
                })
            },
            openSearch1: function () {
                $('.first').slideToggle('down');
            },
            closeSearch1: function () {
                $('.first').slideToggle('up');
            },
            changeFilter1: function () {
                this.filter1 = !this.filter1;
                if (!this.filter1) {
                    this.initSearchCnd1();
                }
            },
            initSearchCnd1: function () {
                this.diseaseGrade = null;
                this.diseaseName = null;
            },
            openSearch2: function () {
                $('.second').slideToggle('down');
            },
            closeSearch2: function () {
                $('.second').slideToggle('up');
            },
            changeFilter2: function () {
                this.filter2 = !this.filter2;
                if (!this.filter2) {
                    this.initSearchCnd2();
                }
            },
            initSearchCnd2: function () {
                this.classCodeSearch = null;
                this.classCodeNameSearch = null;
            },
            getPlaceClass: function (page) {

                if (page < 0 || this.page.totalPages < page) {
                    return;
                }
                let param = {};
                if (this.filter2) {
                    param = {
                        page: page,
                        size: 100000,
                        code: this.indsCd,
                        classCode: this.classCodeSearch,
                        className: this.classCodeNameSearch,
                        useYn : this.useYnSearch ? null : 'Y',
                    }
                } else {
                    param = {
                        page: page,
                        size: 100000,
                        code: this.indsCd,
                        useYn: this.useYnSearch ? null : 'Y',
                    }
                }

                this
                .$axios
                .get('/api/mng/service/master/v1/place-class', {params: param})
                .then((response) => {
                    this.placeClassList = response.data.result.content;
                })
            },
            getPlaceClassAll: function (page) {

                if (page < 0 || this.page.totalPages < page) {
                    return;
                }
                let param = {
                    page: page,
                    size: 100000,
                }
                this
                .$axios
                .get('/api/mng/service/master/v1/place-class', {params: param})
                .then((response) => {
                    this.placeClassAll = response.data.result.content;
                })
            },
            addPlaceClass: function (item) {

                this
                .$axios
                .post('/api/mng/service/master/v1/place-class', {
                    code: this.indsCd,
                    className: item.className,
                    classCode: item.classCode,
                    comment: item.comment,
                    useYn: 'Y'
                })
                .then((response) => {
                    if (response.data.success) {
                        this.openAlertClosed("저장하였습니다.", this.detailDiseases(this.indsCd));

                    } else {
                        this.openAlert("저장중 오류가 발생했습니다.");
                    }

                    this.btnLock = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.openAlert("저장중 오류가 발생했습니다.");
                    this.btnLock = false;
                })
            },
            modifyPlaceClass: function (classCode) {
                this
                .$router
                .push({
                    name: 'placeClassUpd',
                    query: {
                        code: window.btoa(this.indsCd),
                        classCode: window.btoa(classCode)
                    }
                })
            },
            getApplyDate: function () {
                this
                .$axios
                .get('/api/mng/service/master/v1/apply-date',{
                    params: {
                        code: this.indsCd,
                        placeClassCode: this.classCode
                    }
                })
                .then((response) => {
                    console.log(JSON.stringify(response.data.ETC))
                    if(response.data.ETC !== null && response.data.ETC !== undefined) {
                        this.etcList = response.data.ETC;
                    } else {
                        this.etcList = [
                            {
                              "referDateValue": "EXP",
                              "applyDateTarget": "ETC",
                              "applyDateGrade": "5",
                              "applyDateValue": 0
                            },
                            {
                              "referDateValue": "EXP",
                              "applyDateTarget": "ETC",
                              "applyDateGrade": "4",
                              "applyDateValue": 0
                            },
                            {
                              "referDateValue": "EXP",
                              "applyDateTarget": "ETC",
                              "applyDateGrade": "3",
                              "applyDateValue": 0
                            },
                            {
                              "referDateValue": "EXP",
                              "applyDateTarget": "ETC",
                              "applyDateGrade": "2",
                              "applyDateValue": 0
                            },
                            {
                              "referDateValue": "EXP",
                              "applyDateTarget": "ETC",
                              "applyDateGrade": "1",
                              "applyDateValue": 0
                            }
                        ];
                    }

                    if(response.data.EMP !== null && response.data.EMP !== undefined) {
                        this.empList = response.data.EMP;
                    } else {
                        this.empList = [
                            {
                              "referDateValue": "EXP",
                              "applyDateTarget": "EMP",
                              "applyDateGrade": "5",
                              "applyDateValue": 0
                            },
                            {
                              "referDateValue": "EXP",
                              "applyDateTarget": "EMP",
                              "applyDateGrade": "4",
                              "applyDateValue": 0
                            },
                            {
                              "referDateValue": "EXP",
                              "applyDateTarget": "EMP",
                              "applyDateGrade": "3",
                              "applyDateValue": 0
                            },
                            {
                              "referDateValue": "EXP",
                              "applyDateTarget": "EMP",
                              "applyDateGrade": "2",
                              "applyDateValue": 0
                            },
                            {
                              "referDateValue": "EXP",
                              "applyDateTarget": "EMP",
                              "applyDateGrade": "1",
                              "applyDateValue": 0
                            }
                        ];
                    }

                })
            },
            setModifyApplyDate: function () {
                this.modifyYnApplyDate = true;
            },
            setCancelApplyDate: function () {
                this.modifyYnApplyDate = false;
                this.detailPlaceClassCode(this.classCode)
            },
            confirmSaveApplyDate: function () {
                let list = [];

                for (let item of this.empList) {
                    if (item.applyDateValue == null) {
                        this.openAlert("적용일자는 필수값 입니다.");
                        return;
                    }
                    if (/[^\d$]/.test(item.applyDateValue)) {
                        this.openAlert("적용일자는 숫자만 입력 가능합니다.");
                        return;
                    }
                    if (item.applyDateValue > 1000) {
                        this.openAlert("적용일자는 1000일을 넘어갈 수 없습니다.");
                        return;
                    }
                    list.push(item);
                }

                for (let item of this.etcList) {
                    if (item.applyDateValue == null) {
                        this.openAlert("적용일자는 필수값 입니다.");
                        return;
                    }
                    if (/[^\d$]/.test(item.applyDateValue)) {
                        console.log(item);
                        this.openAlert("적용일자는 숫자만 입력 가능합니다.");
                        return;
                    }
                    if (item.applyDateValue > 1000) {
                        this.openAlert("적용일자는 1000일을 넘어갈 수 없습니다.");
                        return;
                    }
                    list.push(item);
                }

                this.openConfirm("작성된 내용을 저장하시겠습니까?", this.saveApplyDate, list);

            },
            saveApplyDate: function (param) {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                this
                .$axios
                .post('/api/mng/service/master/v1/apply-date/modify', {applyList: param, code: this.indsCd, placeClassCode: this.classCode})
                .then((response) => {
                    if (response.data.success) {
                        this.openAlertClosed("정상적으로 수정하였습니다.", this.detailDiseases(this.indsCd));

                    } else {
                        this.openAlert("수정중 오류가 발생하였습니다.");
                    }
                    this.btnLock = false;
                    this.modifyYnApplyDate = false;
                })
                .catch(() => {
                    this.openAlert("수정중 오류가 발생하였습니다.");
                    this.btnLock = false;
                });
            },
            makecode: function () {
                let text = "";
                let str = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
                for (var i = 0; i < 4; i++)
                    text += str.charAt(Math.floor(Math.random() * str.length));
                return text;
            },
            addRow: function(){
                this.empList = [];
                this.etcList = [];
                // 랜덤문자 생성후 중복 체크
                let cCode = this.makecode();
                let bool = false;
                for (let item of this.placeClassAll) {
                    if (item.classCode == cCode) {
                        bool = true;
                    }
                }
                if(this.placeClassList.length == 0) {
                    if (!bool) {
                        this.placeClassList.push({
                            classCode: cCode,
                            className: "",
                            comment: "",
                            useYn: "Y",
                            addYnClassCode: true
                        });
                    }
                } else {
                    const addyn = this.placeClassList[this.placeClassList.length-1].addYnClassCode;
                    console.log(addyn)
                    if(!addyn && !bool) {
                        this.placeClassList.push({
                            classCode: cCode,
                            className: "",
                            comment: "",
                            useYn: "Y",
                            addYnClassCode: true
                        });
                    }
                }
            },
            removeRow: function(row){
                this.placeClassList.pop({
                    row
                });
            },
            checkSearchUseYn: function (event) {
                this.useYnSearch = event.target.checked;
                this.getPlaceClass(0);
            },
            convertReferDateValue: function (value) {
                if (value != null) {
                    if(value == 'EXP') value = '노출종료일자';
                    else if(value == 'REG') value = '등록일자';
                    else if(value == 'UPD') value = '수정일자';
                }
                return value;
            },
            changeEmpReferDateValue(event) {
                for (let item of this.empList) {
                    item.referDateValue = event.target.value;
                }
            },
            changeEtcReferDateValue(event) {
                for (let item of this.etcList) {
                    item.referDateValue = event.target.value;
                }
            }
        }
    }
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
    .tablelineTd {
        background-color: #F5F6FB;
    }
</style>
