<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">문진 관리</a>
            </li>
            <li>
                <a href="javascript:void(0)">문진 설정</a>
            </li>
            <li>
                <a href="javascript:void(0)">새 설문 등록</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>새 버전 등록</h2>
            </div>
            <div class="version_info">
                <dl>
                    <!-- <dt>버전</dt> <dd>v{{detailVersion}}</dd> -->
                </dl>
                <dl>
                    <dt>시작일자</dt>
                    <dd>
                        <v-app>
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="publishStartDate"
                                transition="scale-transition"
                                offset-y="offset-y"
                                min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <input
                                        class="input_cal"
                                        v-model="publishStartDate"
                                        label="적용일자를 선택하세요."
                                        readonly="readonly"
                                        v-bind="attrs"
                                        v-on="on"
                                        id="startDate"/>
                                </template>
                                <v-date-picker
                                    v-model="publishStartDate"
                                    :weekday-format="getWeekDay"
                                    :month-format="getMonth"
                                    :header-date-format="getHeader"
                                    no-title="no-title"
                                    scrollable="scrollable">
                                    <v-spacer></v-spacer>
                                    <v-btn text="text" color="primary" @click="menu = false">
                                        취소
                                    </v-btn>
                                    <v-btn text="text" color="primary" @click="$refs.menu.save(publishStartDate)">
                                        확인
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>

                        </v-app>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <v-app>
                            <v-menu
                                ref="menu3"
                                v-model="menu3"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="publishStartTime"
                                transition="scale-transition"
                                offset-y="offset-y"
                                max-width="290px"
                                min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <input
                                        type="text"
                                        v-model="publishStartTime"
                                        label="Picker in menu"
                                        prepend-icon="mdi-clock-time-four-outline"
                                        readonly="readonly"
                                        v-bind="attrs"
                                        v-on="on"/>
                                </template>
                                <v-time-picker
                                    v-if="menu3"
                                    v-model="publishStartTime"
                                    full-width="full-width"
                                    color="#006769"
                                    @click:minute="$refs.menu3.save(publishStartTime)"></v-time-picker>
                            </v-menu>
                        </v-app>
                        <!-- <input type="text" v-model="publishStartTimeInput" style="vertical-align:
                        inherit; margin-left:5px"/> -->
                        <!-- <span>지정한 날짜부터 현재 버전이 자동으로 적용 됩니다.</span> -->
                    </dd>
                </dl>
                <dl>
                    <dt>종료일자</dt>
                    <dd>

                        <v-app>
                            <v-menu
                                ref="menu2"
                                v-model="menu2"
                                :close-on-content-click="false"
                                :return-value.sync="publishEndDate"
                                transition="scale-transition"
                                offset-y="offset-y"
                                min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <input
                                        class="input_cal"
                                        v-model="publishEndDate"
                                        label="적용일자를 선택하세요."
                                        readonly="readonly"
                                        v-bind="attrs"
                                        v-on="on"
                                        id="endDate"/>
                                </template>
                                <v-date-picker
                                    v-model="publishEndDate"
                                    :weekday-format="getWeekDay"
                                    :month-format="getMonth"
                                    :header-date-format="getHeader"
                                    no-title="no-title"
                                    scrollable="scrollable">
                                    <v-spacer></v-spacer>
                                    <v-btn text="text" color="primary" @click="menu2 = false">
                                        취소
                                    </v-btn>
                                    <v-btn text="text" color="primary" @click="$refs.menu2.save(publishEndDate)">
                                        확인
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-app>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <v-app>
                            <v-menu
                                ref="menu4"
                                v-model="menu4"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="publishEndTime"
                                transition="scale-transition"
                                offset-y="offset-y"
                                max-width="290px"
                                min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <input
                                        type="text"
                                        v-model="publishEndTime"
                                        label="Picker in menu"
                                        prepend-icon="mdi-clock-time-four-outline"
                                        readonly="readonly"
                                        v-bind="attrs"
                                        v-on="on"/>
                                </template>
                                <v-time-picker
                                    v-if="menu4"
                                    v-model="publishEndTime"
                                    full-width="full-width"
                                    color="#006769"
                                    @click:minute="$refs.menu4.save(publishEndTime)"></v-time-picker>
                            </v-menu>
                        </v-app>

                    </dd>
                </dl>
                <dl>
                    <dt>개인정보</dt>
                    <dd>
                        <select v-model="privacyCode">
                            <option value="" disabled="disabled">개인정보 버전을 선택하세요.</option>
                            <option
                                v-for="(item,index) in privacyList"
                                :key="index"
                                :value="item.privacyCode">{{item.privacyVer}}</option>
                        </select>

                    </dd>
                </dl>
                <dl>
                    <dt>민감정보</dt>
                    <dd>
                        <select v-model="sensitiveCode">
                            <option value="" disabled="disabled">민감정보 버전을 선택하세요.</option>
                            <option
                                v-for="(item,index) in sensitiveList"
                                :key="index"
                                :value="item.privacyCode">{{item.privacyVer}}</option>
                        </select>

                    </dd>
                </dl>
            </div>
            <div class="question_wrap">
                <!-- <h3>문진구성 <div class="q_road"> <button class="btn_primary" id="btn_q_road"
                @click="getTypeBtn()">문진구성 불러오기</button> </div> </h3> -->

                <div
                    class="q_card_group"
                    v-for="(item , motherIndex) in items"
                    :key="item.codeDetailId">
                    <div class="q_card" v-if="item.categoryType == 'N'">
                        <div class="sort_btn">
                            <button class="btn_up" @click="gubunUp(motherIndex)">위로</button>
                            <button class="btn_down" @click="gubunDown(motherIndex)">아래로</button>
                            <button class="btn_del" @click="removeCategory(motherIndex)">삭 제</button>
                        </div>
                        <dl>
                            <dt>타입</dt>
                            <dd>일반문항(기본)</dd>
                        </dl>
                        <dl>
                            <dt>구분</dt>
                            <dd>
                                <auto-search
                                    :prevVal="item.questionContents"
                                    :searchList="itemList"
                                    inputName="질문을"
                                    :target="['Q',motherIndex]"
                                    @selectedVal="selectSearchWord"
                                    style="width: 50%;"></auto-search>
                            </dd>
                        </dl>
                        <dl>
                            <dt>설명</dt>
                            <dd>
                                <input type="text" value="문진설명 입력" v-model="item.categoryDesc"/></dd>
                        </dl>
                        <dl>
                            <dt>항목</dt>
                            <dd>
                                <auto-search
                                    :prevVal="defaultSearchText"
                                    :searchList="itemList"
                                    inputName="보기를"
                                    :target="['A', motherIndex]"
                                    @selectedVal="selectSearchWord"
                                    style="width: 50%;"></auto-search>
                                <table>
                                    <colgroup>
                                        <col/>
                                        <col width="10%"/>
                                    </colgroup>
                                    <tr>
                                        <th>항목</th>
                                        <th>삭제</th>
                                    </tr>
                                    <tr v-for="(subItem , index ) in item.inquiryList" :key="index">
                                        <td>{{subItem.inquiryContents}}</td>
                                        <td>
                                            <button class="btn_del" @click="removeItem(motherIndex, index)">삭제</button>
                                        </td>
                                    </tr>
                                </table>
                            </dd>
                        </dl>
                    </div>

                    <div class="q_card" v-if="item.categoryType == 'I'">
                        <div class="sort_btn">
                            <button class="btn_up" @click="gubunUp(motherIndex)">위로</button>
                            <button class="btn_down" @click="gubunDown(motherIndex)">아래로</button>
                            <button class="btn_del" @click="removeCategory(motherIndex)">삭 제</button>
                        </div>
                        <dl>
                            <dt>타입</dt>
                            <dd>일반문항(표/이미지)</dd>
                        </dl>
                        <dl>
                            <dt>구분</dt>
                            <dd>
                                <auto-search
                                    :prevVal="item.questionContents"
                                    :searchList="itemList"
                                    inputName="질문을"
                                    :target="['Q',motherIndex]"
                                    @selectedVal="selectSearchWord"
                                    style="width: 50%;"></auto-search>
                            </dd>
                        </dl>
                        <dl>
                            <dt>설명</dt>
                            <dd>
                                <input
                                    type="text"
                                    placeholder="문진 타입에 대한 설명을 입력하세요."
                                    v-model="item.categoryDesc"/></dd>
                        </dl>
                        <dl>
                            <dt>항목</dt>
                            <dd>
                                <auto-search
                                    :prevVal="defaultSearchText"
                                    :searchList="itemList"
                                    inputName="보기를"
                                    :target="['A',motherIndex]"
                                    @selectedVal="selectSearchWord"
                                    style="width: 50%;"></auto-search>
                                <table>
                                    <colgroup>
                                        <col/>
                                        <col width="20%"/>
                                        <col width="20%"/>
                                        <col width="10%"/>
                                    </colgroup>
                                    <tr>
                                        <th>항목</th>
                                        <th>기존 등록된 이미지</th>
                                        <th>이미지첨부</th>
                                        <th>삭제</th>
                                    </tr>
                                    <tr v-for="(subItem , index ) in item.inquiryList" :key="index">
                                        <td>{{subItem.inquiryContents}}
                                        </td>
                                        <td>
                                            <img
                                                v-if="subItem.inquiryPicture"
                                                :src="'data:image/png;base64,' +subItem.inquiryPicture"
                                                style="max-width:100%;"/>
                                        </td>
                                        <td>
                                            <input
                                                type="file"
                                                @change="putFile($event,motherIndex, index)"
                                                accept=".gif, .jpg, .png, .jpeg"
                                                name="file"
                                                label="이미지를 첨부하세요."/></td>
                                        <td>
                                            <button class="btn_del" @click="removeItem(motherIndex, index)">삭제</button>
                                        </td>
                                    </tr>
                                </table>
                            </dd>
                        </dl>
                    </div>
                    <div class="q_card" v-if="item.categoryType == 'H'">
                        <div class="sort_btn">
                            <button class="btn_up" @click="gubunUp(motherIndex)">위로</button>
                            <button class="btn_down" @click="gubunDown(motherIndex)">아래로</button>
                            <button class="btn_del" @click="removeCategory(motherIndex)">삭 제</button>
                        </div>
                        <dl>
                            <dt>타입</dt>
                            <dd>방문력</dd>
                        </dl>
                        <dl>
                            <dt>설명</dt>
                            <dd>
                                <input
                                    type="text"
                                    placeholder="방문력에 대한 설명을 입력하세요."
                                    v-model="item.categoryDesc"/></dd>
                        </dl>
                        <dl>
                            <dt>자료</dt>
                            <dd>
                                <select v-model="item.placeDiseaseCode">
                                    <option disabled="disabled" value="">방문력을 선택하세요.</option>
                                    <option v-for="(item,index) in diseases" :key="index" :value="item.seq">{{item.diseaseName}}</option>
                                </select>
                                연동할 방문력을 선택하세요.
                            </dd>
                        </dl>
                    </div>
                    <div class="q_card" v-if="item.categoryType == 'P'">
                        <div class="sort_btn">
                            <button class="btn_up" @click="gubunUp(motherIndex)">위로</button>
                            <button class="btn_down" @click="gubunDown(motherIndex)">아래로</button>
                            <button class="btn_del" @click="removeCategory(motherIndex)">삭 제</button>
                        </div>
                        <dl>
                            <dt>타입</dt>
                            <dd>기타(종이문진 전용)</dd>
                        </dl>
                        <dl>
                            <dt>설명</dt>
                            <dd>
                                <input
                                    type="text"
                                    placeholder="문진 타입에 대한 설명을 입력하세요."
                                    v-model="item.categoryDesc"/></dd>
                        </dl>
                        <dl>
                            <!-- <input type="text" placeholder="문진제목 입력"/> <button class="btn_q_add
                            btn_primary">추가</button> -->
                            <dt>질문</dt>
                            <dd>
                                <auto-search
                                    :prevVal="item.questionContents"
                                    :searchList="itemList"
                                    inputName="질문을"
                                    :target="['Q',motherIndex]"
                                    @selectedVal="selectSearchWord"
                                    style="width: 50%;"></auto-search>
                            </dd>

                        </dl>
                    </div>
                    <div class="q_card" v-if="item.categoryType == 'M'">
                        <!-- 객관식형 -->
                        <div class="sort_btn">
                            <button class="btn_up" @click="gubunUp(motherIndex)">위로</button>
                            <button class="btn_down" @click="gubunDown(motherIndex)">아래로</button>
                            <button class="btn_del" @click="removeCategory(motherIndex)">삭 제</button>
                        </div>
                        <dl>
                            <dt>타입</dt>
                            <dd>객관식형 &nbsp; &nbsp;
                                <!-- <input
                                    type="checkbox"
                                    id="anser_go"
                                    name="anser_go"
                                    @change="initConnect(motherIndex)"
                                    v-model="item.connectCategoryYn"/>
                                <label for="anser_go" style="margin-left: 10px;">
                                    <span>답변을 기준으로 질문 이동
                                    </span>
                                </label> -->
                            </dd>
                        </dl>
                        <dl>
                            <dt>질문</dt>
                            <dd>
                                <!-- <input type="text" placeholder="질문 입력" v-model="item.inquiryItemCodeDesc"/>
                                -->
                                <auto-search
                                    :prevVal="item.questionContents"
                                    :searchList="itemList"
                                    inputName="질문을"
                                    :target="['Q',motherIndex]"
                                    @selectedVal="selectSearchWord"
                                    style="width: 50%;"></auto-search>

                            </dd>
                        </dl>
                        <dl>
                            <dt>보기</dt>
                            <dd>
                                <table class="anser_table">
                                    <colgroup >
                                        <col/>
                                        <col width="750px;"/>
                                    </colgroup>
                                    <tr v-for="(maItem, maIndex) in item.inquiryList" :key="maIndex">
                                        <td width="100px">
                                            <button class="btn_up" @click="moveUpAnswer(motherIndex,maIndex)"></button>
                                            <button class="btn_down" @click="moveDownAnswer(motherIndex,maIndex)"></button>
                                            &nbsp;
                                            <input type="radio" id="anser_option" name="anser_option" disabled="disabled"/>
                                            <label
                                                for="anser_option"
                                                style="height: 23px; vertical-align: middle; margin-left: 10px;"></label>
                                        </td>
                                        <td style="">
                                            <!-- <input type="text" placeholder="옵션1"/> <button
                                            class="btn_del_line">삭제</button> -->
                                            <auto-search
                                                inputName="보기를"
                                                :prevVal="maItem.inquiryContents"
                                                :searchList="itemList"
                                                :target="['MA',motherIndex,maIndex]"
                                                @selectedVal="selectSearchWord"
                                                style="width: 50%;"></auto-search>
                                            <button class="btn_del_line" @click="deleteMaAnswer(motherIndex,maIndex)">삭제</button>
                                        </td>
                                        <td>
                                            <select v-model="maItem.acceptYn">
                                                <option value="" disabled="disabled">정상 여부 선택</option>
                                                <option value="Y">정상</option>
                                                <option value="N">비정상</option>
                                            </select>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <!-- <button class="btn_up"></button> <button class="btn_down"></button> -->
                                            &nbsp;
                                            <input type="radio" id="anser_option" name="anser_option"/>
                                            <label for="anser_option">
                                                &nbsp;
                                            </label>
                                        </td>
                                        <td>
                                            <button style="height:30px; width: 90%;" @click="addMaAnswer(motherIndex)">
                                                클릭하여 옵션 추가</button>
                                        </td>
                                        <td></td>
                                    </tr>
                                </table>
                            </dd>
                        </dl>
                    </div>
                    <!-- // 객관식형 -->
                    <div class="q_card" v-if="item.categoryType == 'T'">
                        <!-- 주관식형 -->
                        <div class="sort_btn">
                            <button class="btn_up" @click="gubunUp(motherIndex)">위로</button>
                            <button class="btn_down" @click="gubunDown(motherIndex)">아래로</button>
                            <button class="btn_del" @click="removeCategory(motherIndex)">삭 제</button>
                        </div>
                        <dl>
                            <dt>타입</dt>
                            <dd>주관식형</dd>
                        </dl>
                        <dl>
                            <dt>질문</dt>
                            <dd>
                                <auto-search
                                    :prevVal="item.questionContents"
                                    :searchList="itemList"
                                    inputName="질문을"
                                    :target="['Q',motherIndex]"
                                    @selectedVal="selectSearchWord"
                                    style="width: 50%;"></auto-search>
                            </dd>
                        </dl>
                    </div>
                    <!-- // 주관식형 -->
                    <div class="q_card" v-if="item.categoryType == 'E'">
                        <!-- 안내문구 -->
                        <div class="sort_btn">
                            <button class="btn_up" @click="gubunUp(motherIndex)">위로</button>
                            <button class="btn_down" @click="gubunDown(motherIndex)">아래로</button>
                            <button class="btn_del" @click="removeCategory(motherIndex)">삭 제</button>
                        </div>
                        <dl>
                            <dt>타입</dt>
                            <dd>안내문구</dd>
                        </dl>
                        <dl>
                            <div class="editor editorVue">
                                <editor
                                    @setEditorHtml="setEditorHtml"
                                    :contents="item.categoryDesc"
                                    :motherIndex="motherIndex"
                                    :editorId="'editor' + motherIndex"></editor>
                            </div>
                        </dl>
                    </div>
                    <!-- //안내문구 -->
                </div>

                <select @change="addCategory">
                    <option disabled="disabled" selected="selected" value="">문진유형</option>
                    <option
                        v-for="(item, index) in addInquiryTypeList"
                        :key="index"
                        :value="item.value">{{item.text}}</option>
                </select>

            </div>
            <div class="btn_wrap">
                <div class="q_card send-list">
                    <dl>
                        <dt>수신 대상자 선택</dt>
                        <dd>
                            <input v-model="sendTarget[0]" type="checkbox" id="RPT" name="answerType" class="checkbox_right" value="RPT">
                            <label for="RPT">
                                <span>재원환자</span>
                            </label>
                            <button class='etcBtn' @click="checkTarget('RPT')">발송대상 조회</button> 
                        </dd>
                        <dd>
                            <input v-model="sendTarget[1]" type="checkbox" id="EMP" name="answerType" class="checkbox_right" value="EMP">
                            <label for="EMP">
                                <span>직원</span>
                            </label>
                            <button class='etcBtn' @click="checkTarget('EMP')">발송대상 조회</button> 
                        </dd>
                        <dd>
                            <input v-model="sendTarget[2]" type="checkbox" id="RNOK" name="answerType" class="checkbox_right" value="RNOK">
                            <label for="RNOK">
                                <span>상주보호자</span>
                            </label>
                            <button class='etcBtn' @click="checkTarget('RNOK')">발송대상 조회</button> 
                        </dd>
                    </dl>
                </div>
                <div class="btn_right">
                    <button
                        class="btn_secondary"
                        @click="$router.push({name: 'inquiry-emergency'})">취 소</button>
                    <button class="btn_secondary" id="btn_q_mob" @click="mobile()">미리보기</button>
                    <button class="btn_important" @click="saveInquiry('T')">임시저장</button>
                    <button class="btn_secondary" @click="sendTest()">테스트 발송</button>
                    <button class="btn_primary" @click="saveInquiry('F') ">발 송</button>
                </div>
            </div>
        </div>
        <modal
            name="targetContainer"
            :width="400"
            :height="600"
            :adaptive="true"
            :clickToClose="true">
            <div class="targetContainer">
                <table>
                    <colgroup>
                        <col width="30%"/>
                        <col width="70%"/>
                    </colgroup>
                    <tr>
                        <th>발송대상 이름</th>
                        <th>발송대상 수신정보</th>                       
                    </tr>                
                    <tr v-for="target in targetList" :key=target.targetId> 
                        <td>
                            {{ target.targetNm }}
                        </td>
                        <td>
                            {{ target.targetId }}
                        </td>
                    </tr>    
                </table>


            </div>       
        </modal>

        <!-- 테스트 발송 팝업 -->
        <modal
            name="sendTestPop"
            :width="600"
            :height="200"
            :adaptive="true"
            :clickToClose="true">
            <div class="sendTestPop">

                <div style="margin: 20px 30px; ">
                        <input
                                type="radio"
                                name="target_type"
                                id="testEmp"
                                value="E"
                                v-model="testType"
                            />
                        <label for="testEmp" style="display: inline-block">
                            <span>직원 사번으로 테스트 발송</span>
                        </label>

                        <input
                                type="radio"
                                name="target_type"
                                id="testPhone"
                                value="P"
                                v-model="testType"
                            />
                        <label for="testPhone" style="display: inline-block; margin-left : 50px ">
                            <span>휴대전화로 테스트 발송</span>
                        </label>
                </div> 
                <div style="margin: 20px 30px;" v-if="testType === 'E'">                   
                    <button class="btn_secondary" @click="openSearchUser()">사번 검색</button>
                     <input type="text" v-model='selectedEmpNo' placeholder="사번을 선택해 주세요." style="width:70%;" disabled/>
                </div>  
                 <div style="margin: 20px 30px;" v-if="testType === 'P'">                   
                    <input type="text" v-model='testUserPhone' placeholder="휴대전화번호를 입력해주세요." style="width:70%;"/>
                </div>  
                <div class="btn_wrap">
                    <button class="btn_primary" @click="testInquiry()">테스트 발송</button>
                </div>
            </div>
        </modal>


        <div class="reg_loader">
            <div style="display:none;" id="loader" ref="loader"></div>
            <div style="display:none;" id="loader_txt" ref="loader_txt" class="loader_txt">긴급방문력을 발송하고 있습니다.</div>

        </div>

    </div>
</template>
<script>
    import previewMob from '../../components/previewMob.vue';
    import preivewKiosk from '../../components/previewKiosk.vue'
    import autoSearch from '../../components/autoSearch.vue';
    import mixin from '../../mixin/mixin.js';
    import editor from '../../components/editor.vue';
    import moment from 'moment';
    import searchUser from '../../components/userSearch.vue';

    export default {
        name:"InquiryEmergencyItems",
        props: {
            eid: { type: String },
            did: { type: String },
        },
        data: () => {
            return {
                publishStartDate: moment().format("YYYY-MM-DD"), //시작일
                publishStartTime: moment().format("HH:mm"), // 시작 시간
                publishEndDate: moment().format("YYYY-MM-DD"), //종료일
                publishEndTime: '23:59',
                menu: false, // 시작이 달력 라이브러리 보이기
                menu2: false, //종료일 달력 라이브러리 보이기
                menu3: false, //시작시간 시계 라이브러리 보이기
                menu4: false,
                detailVersion: '', //상세버전
                items: [],
                catObj: {
                    categoryId: null,
                    categoryType: null,
                    categoryOrder: null,
                    categoryDesc: '',
                    connectCategoryYn: false,
                    subCategoryYn: false,
                    questionType: "D",
                    questionId: null,
                    questionContents: null,
                    inquiryList: [],
                    placeDiseaseCode: ''
                },
                itemObj: {
                    inquiryId: null,
                    inquiryContents: '',
                    inquiryPicture: null,
                    file: null,
                    targetCategoryId: null,
                    targetCategoryContents: null,
                    acceptYn: "N",
                    check: null,
                    answerId: null,
                    itemOrder: null
                },
                addInquiryTypeList: [
                    {
                        text: '객관식형',
                        value: 'M'
                    },
                    {
                        text: '주관식형',
                        value: 'T'
                    },
                     {
                        text: '안내문구(에디터)',
                        value: 'E'
                    },
                ],
                selectVer: '', //문진 불러오기 해당 문진 유형의 버전
                selectType: '', //문진 불러오기 문진 유형
                masterList: [], //문진 마스터 리스트
                detailList: [], //문진상세 리스트
                itemList: [], //검색어 리스트
                defaultSearchText: '', //검색어 초기화
                diseases: [], //질병 분류코드
                susikList: [], //수식관리 리스트
                privacyList: [],
                privacyCode: "",
                sensitiveList: [],
                sensitiveCode: "",
                sendTarget: [false, false, false],
                isUpdate: false,
                targetList :[],
                testType: 'E',
                testUserId: null,
                testUserNm: null,
                testDeptCd: null,
                testUserPhone: '',
                
            }
        },
        mixins: [mixin],
        created: function () {
            this.getMasterInfo();
            this.getAllItem();
            this.getDisease();
            this.getPrivacyList();
            if (this.eid && this.did) {
                this.isUpdate = true;
                this.publishStartDate = this.$route.params.startDate;
                this.publishStartTime = this.$route.params.startTime;
                this.publishEndDate = this.$route.params.endDate;
                this.publishEndTime = this.$route.params.endTime;
                this.privacyCode = this.$route.params.privacyCode;
                this.sensitiveCode = this.$route.params.sensitiveCode;
                this.getInquiryDetail(this.did);
                // update 페이지로 동작
            }
        },
        watch: {
            testUserPhone: function (newVal) {
                newVal = newVal.replace(/-/gi, "");
                if (this.testUserPhone.length > 11) {
                    newVal = newVal.substring(0, 11);
                }
                newVal = this.convertPhone(newVal);
                console.log("convert", newVal);
                this.testUserPhone = newVal;
            },
            testType: function(){
                this.testUserId = null;
                this.testUserNm = null;
                this.testDeptCd = null;
                this.testUserPhone = '';
            }
        },
        components: {
            'auto-search': autoSearch,
            'editor': editor
        },
        computed: {
            sendTargetList() {
                const returnValue = [];
                this.sendTarget[0] && returnValue.push('RPT');
                this.sendTarget[1] && returnValue.push('EMP');
                this.sendTarget[2] && returnValue.push('RNOK');
                return returnValue;
            },
            selectedEmpNo() {
                if(this.testUserId != null) {
                    return this.testUserNm + " ("+ this.testUserId +")";
                } else {
                    return null;
                }
               
            }
        },
        methods: {
            formatDate(date) {
                if (!date)
                    return null;

                const [year, month, day] = date.split('-')
                return `${year}/${month}/${day}`
            },
            parseDate(date) {
                if (!date)
                    return null;

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            addCategory: function (event) { //문진 카테고리를 추가한다.

                if (event.target.value == 'H') { //방문력은 한번만 추가 할 수 있다.
                    for (let item of this.items) {
                        if (item.categoryType == 'H') {
                            this.openAlert("방문력은 문진에서 한번만 삽입할 수 있습니다.");
                            event.target.value = '';
                            return;
                        }
                    }
                }

                let obj = Object.assign({}, this.catObj);
                obj.categoryType = event.target.value;
                this
                    .items
                    .push(Object.assign({}, obj));
                event.target.value = "";

            },
            gubunUp: function (motherIndex) {
                if (motherIndex == 0) {
                    alert("첫번째 항목입니다.");
                    return;
                }
                let tempItems = this.items[motherIndex];
                this
                    .items
                    .splice(motherIndex, 1);
                this
                    .items
                    .splice(motherIndex - 1, 0, tempItems);
            },
            gubunDown: function (motherIndex) {
                if (motherIndex == (this.items.length - 1)) {
                    alert("마지막 항목입니다.");
                    return;
                }
                let tempItems = this.items[motherIndex];
                this
                    .items
                    .splice(motherIndex, 1);
                this
                    .items
                    .splice(motherIndex + 1, 0, tempItems);
            },
            getMasterInfo: function () {
                this
                    .$axios
                    .get("/api/mng/inquiry/v1/master")
                    .then((response) => {
                        this.placeConnList = [];
                        let masters = response.data.result;
                        if (masters == null) {
                            this.openAlert("마스터 정보가 없습니다.");
                            return;
                        }
                        for (var i = 0; i < masters.length; i++) {
                            let temp = {
                                text: masters[i].masterName,
                                value: masters[i].masterId
                            }

                            this
                                .masterList
                                .push(temp);
                        }

                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            getDetailInfo: function (event) {
                this.selectVer = "";
                let value = event.target.value;
                console.log(value);
                this
                    .$axios
                    .get("/api/mng/inquiry/v1/detail", {
                        params: {
                            masterId: value
                        }
                    })
                    .then((response) => {
                        let detailList = response.data.result;
                        console.log(detailList);
                        detailList.sort((a, b) => {
                            return a.regDate < b.regDate
                                ? -1
                                : a.regDate > b.regDate
                                    ? 1
                                    : 0;
                        })
                        let result = [];
                        for (var i = 0; i < detailList.length; i++) {
                            result.push({text: detailList[i].detailVer, value: detailList[i].detailId})
                        }
                        console.log(result);
                        this.detailList = result;
                    })
                    .catch(() => {
                        this.openAlert("해당 버전을 가져오는 도중 오류가 발생했습니다.");
                    })

                },
            getAllItem: function () {
                this
                    .$axios
                    .get('/api/mng/inquiry/v1/item')
                    .then((response) => {
                        let result = response.data.result;
                        this.makeItemList(result);
                    })
            },
            //////////////////////////////////////////////////////
            addItemContents: function (itemContents) { //자동완성검색에서 새로 추가한 검색어를 저장한다.
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                return this
                    .$axios
                    .post('/api/mng/inquiry/v1/add-item', {itemContents: itemContents})
                    .then((response) => {
                        if (!response.data.success) {
                            this.openAlert("항목을 추가하지 못했습니다. 다시 시도해 주세요.");
                            this.btnLock = false;
                            return false;
                        }
                        this.makeItemList(response.data.result);
                        this.btnLock = false;
                        return true;
                    })

            },
            makeItemList: function (itemList) { //파라미터로 받은 리스트를 자동완성검색에 전달할 리스트로 변환한다.
                this.itemList = [];
                for (let item of itemList) {
                    let tempObj = {
                        name: item.itemContents,
                        id: item.itemId,
                        selected: false
                    }
                    this
                        .itemList
                        .push(tempObj);
                }
            },
            selectSearchWord: async function (target, searchText) { //자동검색에서 선택또는 추가한 항목을 해당하는 곳에 세팅
                let isAdd = true;
                let searchId = null;
                for (let item of this.itemList) {
                    if (item.name == searchText) { //새로 추가인지 체크
                        isAdd = false;
                        searchId = item.id;
                        break;
                    }
                }
                if (isAdd) { //새로 추가이면 항목을 먼저 서버에 저장.
                    if (!await this.addItemContents(searchText)) {
                        return;
                    }
                    for (let item of this.itemList) {
                        if (item.name == searchText) {
                            searchId = item.id;
                            break;
                        }
                    }
                }

                //검색어에 입력한 내용을 items에 추가. 유형별로 추가해 줘야 할 키가 다르기때문에 분기
                if (target[0] == "Q") { //질문에 추가는경우라면 질문에 추가.

                    this
                        .items[target[1]]
                        .questionContents = searchText;
                    this
                        .items[target[1]]
                        .questionId = searchId;
                } else if (target[0] == "A") { //보기에 추가 해야 하는 경우

                    let tempObj = Object.assign({}, this.itemObj);
                    tempObj.inquiryId = searchId;
                    tempObj.inquiryContents = searchText;
                    let tempList = Object.assign([], this.items[target[1]].inquiryList);
                    tempList.push(tempObj);
                    this.$set(this.items[target[1]], 'inquiryList', tempList);

                } else if (target[0] == "MA") { //객관식의 보기일경우
                    this.$set(this.items[target[1]].inquiryList[target[2]], 'inquiryId', searchId);
                    this.$set(
                        this.items[target[1]].inquiryList[target[2]],
                        'inquiryContents',
                        searchText
                    );
                }
            },
            addMaAnswer: function (motherIndex) { //객관식에서 보기 추가
                let tempObj = Object.assign({}, this.itemObj);
                let tempList = Object.assign([], this.items[motherIndex].inquiryList);
                tempList.push(tempObj);
                this.$set(this.items[motherIndex], 'inquiryList', tempList);
            },
            initConnect: function (motherIndex) { //객관식에서 답변을 기준으로 질문이동 체크박스 이벤트 발생시마다 하위 선택되는 항목 초기화.
                for (let [index] of this.items[motherIndex].inquiryList.entries()) {
                    this.$set(this.items[motherIndex].inquiryList[index], 'acceptYn', 'N');
                    this.$set(this.items[motherIndex].inquiryList[index], 'targetCategoryId', null);
                }
            },
            removeCategory: function (motherIndex) { //카테고리 삭제
                let tempItems = [];
                tempItems = Object.assign([], this.items);
                tempItems.splice(motherIndex, 1);
                this.items = tempItems;

            },
            removeItem: function (motherIndex, index) { //카테고리에 속한 문진 항목 삭제.
                let tempItems = [];
                tempItems = Object.assign([], this.items[motherIndex].inquiryList)
                tempItems.splice(index, 1);
                this.$set(this.items[motherIndex], 'inquiryList', tempItems);
                index = 0;
            },
            moveUpAnswer: function (motherIndex, maIndex) { //객관식 보기 순서 위로 이동
                if (maIndex == 0) {
                    alert("첫번째 항목입니다.");
                    return;
                }
                let tempObj = this
                    .items[motherIndex]
                    .inquiryList[maIndex];
                this
                    .items[motherIndex]
                    .inquiryList
                    .splice(maIndex, 1);
                this
                    .items[motherIndex]
                    .inquiryList
                    .splice(maIndex - 1, 0, tempObj);
                let tempArr = this
                    .items[motherIndex]
                    .inquiryList;
                this.$set(this.items[motherIndex], 'inquiryList', tempArr);
            },
            moveDownAnswer: function (motherIndex, maIndex) { //객관식 보기 순서 아래로 이동
                if (maIndex >= this.items[motherIndex].inquiryList.length) {
                    alert("마지막 항목입니다.");
                    return;
                }
                let tempObj = this
                    .items[motherIndex]
                    .inquiryList[maIndex];
                this
                    .items[motherIndex]
                    .inquiryList
                    .splice(maIndex, 1);
                this
                    .items[motherIndex]
                    .inquiryList
                    .splice(maIndex + 1, 0, tempObj);
                let tempArr = this
                    .items[motherIndex]
                    .inquiryList;
                this.$set(this.items[motherIndex], 'inquiryList', tempArr);
                // this.items[motherIndex] = Object.assign({}, this.items[motherIndex]);
            },
            deleteMaAnswer: function (motherIndex, maIndex) {
                let tempArr = this
                    .items[motherIndex]
                    .inquiryList;
                console.log(tempArr);
                tempArr.splice(maIndex, 1);
                this.$set(this.items[motherIndex], 'inquiryList', tempArr);
            },
            getDisease: function (code) { //질병 분류코드를 가져온다.
                this
                    .$axios
                    .get('/api/mng/inquiry/v1/disease', {
                        params: {
                            seq: code
                        }
                    })
                    .then((response) => {
                        if (response.data.success) {
                            this.diseases = response.data.result;
                        } else {
                            this.openAlert("질병 분류 코드를 가져오지 못했습니다.");
                        }
                    })
                    .catch(() => {
                        this.openAlert("질병 분류 코드를 가져오는 도중 오류가 발생했습니다.");
                    });
            },
            setEditorHtml: function (motherIndex, contents) { //에디터에서 변경된 내용을 세팅한다.
                this.$set(this.items[motherIndex], 'categoryDesc', contents);
            },
            validation: function (text, msg) {
                if (text == null) {
                    this.openAlert(msg + "을(를) 입력하세요.");
                    return true;
                } else if (text.trim().length == 0) {
                    this.openAlert(msg + "을(를) 입력하세요.");
                    return true;
                } else if (text.trim().length > 200) {
                    this.openAlert(msg + "은(는) 200자를 넘을 수 없습니다.");
                    return true;
                } else {
                    return false;
                }
            },
            saveInquiry: function (type) {
                if (this.privacyCode == "") {
                    this.openAlert("개인정보를 선택하세요.");
                    return;
                }

                if (this.sensitiveCode == "") {
                    this.openAlert("민감정보를 선택하세요.");
                    return;
                }

                if (this.items.length == 0) {
                    this.openAlert("문진유형을 추가하여 문진표를 작성해 주세요");
                    return;
                }

                if (type == "F" && this.sendTargetList.length == 0) {
                    this.openAlert("발송 대상을 선택하여 주세요.");
                    return;
                }

                //발리데이션 체크 시작
                for (let item of this.items) {
                    if (item.categoryType == "H") { //방문력의 경우
                        console.log("방문력");
                        console.log('code', item.placeDiseaseCode);
                        if (item.placeDiseaseCode == '') {
                            this.openAlert("연동할 방문력을 선택하세요.");
                            return;
                        }
                    } else if (item.categoryType == "N") { // 일반 기본 문진의 경우
                        if (this.validation(item.questionContents, "일반(기본)문진의 구분")) {
                            return;
                        }
                        for (let subItem of item.inquiryList) {
                            if (this.validation(subItem.inquiryContents, "일반(기본)문진의 항목")) {
                                return;
                            }
                        }
                    } else if (item.categoryType == "I") { //일반 표이미지 문진의 경우
                        if (this.validation(item.questionContents, "일반(표/이미지)문진의 및 구분")) {
                            return;
                        }
                        for (let subItem of item.inquiryList) {
                            if (this.validation(subItem.inquiryContents, "일반(표/이미지)문진의 항목")) {
                                return;
                            }
                        }
                    } else if (item.categoryType == "P") { //종이 문진의 경우
                        if (this.validation(item.questionContents, "종이문진  질문")) {
                            return;
                        }
                    } else if (item.categoryType == "M") { //객관식 문진의 경우
                        if (this.validation(item.questionContents, "객관식문진 질문")) {
                            return;
                        }
                        for (let subItem of item.inquiryList) {
                            if (this.validation(subItem.inquiryContents, "객관식문진 보기")) {
                                return;
                            }
                        }
                    } else if (item.categoryType == "T") { //주관식 문진의 경우
                        if (this.validation(item.questionContents, "주관식문진 질문")) {
                            return;
                        }
                    } else if (item.categoryType == "E") { //안내문구(에디터)문진의 경우
                        if (item.categoryDesc == null) {
                            this.openAlert("안내문구(에디터) 문진을 입력해 주세요.")
                            return;
                        } else if (item.categoryDesc.trim() == '') {
                            this.openAlert("안내문구(에디터) 문진을 입력해 주세요.")
                            return;
                        }
                    }
                }
                if (type == "F") {
                    this.openConfirm("작성한 설문을 발송하시겠습니까?", this.save, type);
                } else {
                    this.openConfirm("작성한 설문을 임시저장 하시겠습니까?", this.save, type);
                }

            },
            save: async function (type) {
                //문진 저장
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                type === "F" && this.openLoading("긴급 방문력을 발송 중입니다.");

                try {
                    let response;
                    if (this.isUpdate) {
                        // update
                        response = await this.$axios.put("/api/mng/inquiry/v1/emergency", {
                            emParam: {
                            emInqId: this.eid,
                            detailId: this.did,
                            startDate: this.publishStartDate,
                            startDateTime: this.publishStartTime,
                            endDate: this.publishEndDate,
                            endDateTime: this.publishEndTime,
                            state: type,
                            privacyCode: this.privacyCode,
                            sensitiveCode: this.sensitiveCode,
                            },
                            inqParam: {
                            categoryList: this.items,
                            },
                            target: this.sendTargetList,
                        });
                    } else {
                        // insert
                        response = await this.$axios.post("/api/mng/inquiry/v1/emergency", {
                            emParam: {
                            startDate: this.publishStartDate,
                            startDateTime: this.publishStartTime,
                            endDate: this.publishEndDate,
                            endDateTime: this.publishEndTime,
                            state: type,
                            privacyCode: this.privacyCode,
                            sensitiveCode: this.sensitiveCode,
                            },
                            inqParam: {
                            categoryList: this.items,
                            },
                            target: this.sendTargetList,
                        });
                    }

                    if (!response.data.success) throw new Error("error");
                    this.closeLoading();
                    this.btnLock = false;
                    if(type == "F") this.openAlertClosed("요청이 완료되었습니다.", this.goBack, {});
                    else this.openAlertClosed("임시저장이 완료되었습니다.", this.goBack, {});

                } catch (error) {
                    this.closeLoading();
                    this.openAlert("저장중 오류가 발생했습니다.");
                    this.btnLock = false;
                }
            },
            goBack: function() {
                this.$router.back();
            },
            getInquiryDetail: async function (detailId) {
                try {
                    if (this.btnLock) {
                        return;
                    } else {
                        this.btnLock = true;
                    }
                    const response = await this.$axios.get(
                        '/api/mng/inquiry/v1/inquiry',
                        { params: { detailId }});
                    if (!response.data.success) throw new Error('error');
                    this.btnLock = false;
                    this.items = Object.assign([], response.data.result.categoryList);
                    // data insert
                } catch (error) {
                    this.openAlert("설문 정보 조회 중 오류가 발생했습니다.");
                    this.btnLock = false;
                }
            },
            openPreviewPopup: function (
              strWindowFeatures,
              windowName,
              popupHost,
              subUrl,
              categoryList,
              language
            ) {
              let items = Object.assign([], categoryList);
              const eventListenerFtn = function (e) {
                if (e.origin === popupHost && e.data.method === "needCategoryData") {
                  e.source.postMessage(
                    {
                      method: e.data.method,
                      categoryList: items,
                      inquiryType: null,
                      language: 'ko',
                    },
                    popupHost
                  );
                  window.removeEventListener("message", eventListenerFtn);
                }
              };
              window.addEventListener("message", eventListenerFtn);
              window.open(
                `${popupHost}${subUrl}${new Date().getTime()}`,
                windowName,
                strWindowFeatures
              );
            },
            mobile: function () {
                console.log(this.items)
              const strWindowFeatures =
                "menubar=no,location=no,resizable=no,scrollbars=yes,status=no,width=375,height=712,left=200,top=100";
              const windowName = "ASAN_Emer_Mobile_Preview";
              this.openPreviewPopup(
                strWindowFeatures,
                windowName,
                `${window.location.protocol}//${window.location.host}`,
                "/pre/#/emer-invst/",
                this.items,
                "ko"
              );
            },
            kiosk: function () {
                this
                    .$modal
                    .show(preivewKiosk, {
                        items: this.items
                    }, {
                        width: '580px',
                        height: '800px',
                        adaptive: true,
                        clickToClose: true
                    });
            },
            putFile: function (event, motherIndex, index) {

                // this.$set(     this.items[motherIndex].inquiryList[index],     'file',
                // event.target.files[0] ); this.items= JSON.parse(JSON.stringify(this.items));

                console.log(event.target.files[0]);
                this
                    .items[motherIndex]
                    .inquiryList[index]
                    .file = event
                    .target
                    .files[0];

            },
            getPrivacyList: function () {
                this
                    .$axios
                    .get('/api/mng/common/v1/privacy')
                    .then((response) => {
                        console.log(response.data);
                        let privacy = response.data.result;

                        if (privacy.privacyList.length > 0) {
                            this.privacyList = privacy.privacyList;
                        } else {
                            this.openAlert("등록된 개인정보가 없습니다.");
                        }

                        if (privacy.sensitiveList.length > 0) {
                            this.sensitiveList = privacy.sensitiveList;
                        } else {
                            this.openAlert("등록된 민감정보가 없습니다.");
                        }
                    })
                    .catch(() => {
                        this.openAlert("정보동의서를 가져오는데 오류가 발생했습니다.");
                    });
            },
            checkTarget: async function (type) {
                console.log(type);
                const response = await this.$axios.get(
                        '/api/mng/inquiry/v1/check-target',
                        { params: { 
                            type : type 
                            }
                        });
                console.log(response);

                if(response.data.resultCd === 0) {
                        this.targetList = response.data.result;
                        this.$modal.show('targetContainer');
                } else {
                    this.openAlert("발송대상 리스트를 불러오는 도중 오류가 발생했습니다.");
                }
            },
            sendTest() {
                if(!this.isUpdate) {
                    this.openAlert("테스트 발송은 임시저장 이후에 발송 가능합니다.");
                    return;
                }
                this.$modal.show('sendTestPop');
            },
            openSearchUser: function () {
                this
                    .$modal
                    .show(searchUser, {
                        getSearchUserInfo: this.getSearchUserInfo
                    }, {
                        width: '640px',
                        height: 'auto',
                        adaptive: true,
                        clickToClose: false
                    },)
            },
            getSearchUserInfo: function (user, dept) {
                this.testUserId = user;
                this.testDeptCd = dept;
                this.getDetpUser();
            },
            getDetpUser: function () {
                this
                    .$axios
                    .get('/api/mng/common/v1/dept-user', {
                        params: {
                            userId: this.testUserId,
                            deptCd: this.testDeptCd
                        }
                    })
                    .then((response) => {
                        if (response.data.success) {
                            this.testUserNm = response.data.result.korNm;
                        } else {
                            this.openAlert("사용자 정보를 가져오지 못했습니다.")
                        }

                    })
                    .catch(() => {
                        this.openAlert("사용자 정보를 가져오지 못했습니다.")
                    })
                },

             testInquiry: function () {
                if (this.privacyCode == "") {
                    this.openAlert("개인정보를 선택하세요.");
                    return;
                }

                if (this.sensitiveCode == "") {
                    this.openAlert("민감정보를 선택하세요.");
                    return;
                }

                if (this.items.length == 0) {
                    this.openAlert("문진유형을 추가하여 문진표를 작성해 주세요");
                    return;
                }
               
                //발리데이션 체크 시작
                for (let item of this.items) {
                    if (item.categoryType == "H") { //방문력의 경우
                        console.log("방문력");
                        console.log('code', item.placeDiseaseCode);
                        if (item.placeDiseaseCode == '') {
                            this.openAlert("연동할 방문력을 선택하세요.");
                            return;
                        }
                    } else if (item.categoryType == "N") { // 일반 기본 문진의 경우
                        if (this.validation(item.questionContents, "일반(기본)문진의 구분")) {
                            return;
                        }
                        for (let subItem of item.inquiryList) {
                            if (this.validation(subItem.inquiryContents, "일반(기본)문진의 항목")) {
                                return;
                            }
                        }
                    } else if (item.categoryType == "I") { //일반 표이미지 문진의 경우
                        if (this.validation(item.questionContents, "일반(표/이미지)문진의 및 구분")) {
                            return;
                        }
                        for (let subItem of item.inquiryList) {
                            if (this.validation(subItem.inquiryContents, "일반(표/이미지)문진의 항목")) {
                                return;
                            }
                        }
                    } else if (item.categoryType == "P") { //종이 문진의 경우
                        if (this.validation(item.questionContents, "종이문진  질문")) {
                            return;
                        }
                    } else if (item.categoryType == "M") { //객관식 문진의 경우
                        if (this.validation(item.questionContents, "객관식문진 질문")) {
                            return;
                        }
                        for (let subItem of item.inquiryList) {
                            if (this.validation(subItem.inquiryContents, "객관식문진 보기")) {
                                return;
                            }
                        }
                    } else if (item.categoryType == "T") { //주관식 문진의 경우
                        if (this.validation(item.questionContents, "주관식문진 질문")) {
                            return;
                        }
                    } else if (item.categoryType == "E") { //안내문구(에디터)문진의 경우
                        if (item.categoryDesc == null) {
                            this.openAlert("안내문구(에디터) 문진을 입력해 주세요.")
                            return;
                        } else if (item.categoryDesc.trim() == '') {
                            this.openAlert("안내문구(에디터) 문진을 입력해 주세요.")
                            return;
                        }
                    }
                } 
                
                

                let target = [];

                if(this.testType === 'E') {
                    if(this.testUserId == null || this.testUserId == ''){
                        this.openAlert("테스트 발송할 직원을 선택해 주세요.");
                        return;
                    } 
                    target.push(this.testUserId);
                } else if (this.testType === 'P'){
                    if(this.testUserPhone == null || this.testUserPhone == ''){
                        this.openAlert("테스트 발송할 전화번호를 입력해주세요.");
                        return;
                    } 
                    if (!/^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/.test(
                            this.testUserPhone
                            )
                        ) {
                            this.openAlert("올바른 핸드폰 형식을 입력해 주세요.");
                            return;
                        }
                        this.testUserPhone = this.testUserPhone.replace(/-/gi, "").trim();
                     
                    target.push(this.testUserPhone);
                }    
               
                this.openConfirm("작성한 설문을 발송하시겠습니까?", this.sendTestInquiry, target);           

            },

            sendTestInquiry: async function(target) {
                //문진 저장
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                  try {
                    let response;
                   
                    // insert
                    response = await this.$axios.post("/api/mng/inquiry/v1/emergency-test", {
                        emParam: {
                            emInqId: this.eid,
                            detailId: this.did,
                            startDate: this.publishStartDate,
                            startDateTime: this.publishStartTime,
                            endDate: this.publishEndDate,
                            endDateTime: this.publishEndTime,
                            privacyCode: this.privacyCode,
                            sensitiveCode: this.sensitiveCode,
                        },
                        type : this.testType,
                        inqParam: {
                            categoryList: this.items,
                        },
                        target: target,
                    });        

                    if (!response.data.success) throw new Error("error");

                    // if (!this.isUpdate) {
                    //     this.isUpdate = true;
                    //     this.publishStartDate =  moment(response.data.result.startDate).format("YYYY-MM-DD");
                    //     this.publishStartTime =  moment(response.data.result.startDate).format("HH:mm");
                    //     this.publishEndDate = moment(response.data.result.endDate).format("YYYY-MM-DD");
                    //     this.publishEndTime = moment(response.data.result.endDate).format("HH:mm");
                    //     this.privacyCode = response.data.result.privacyCode;
                    //     this.sensitiveCode = response.data.result.sensitiveCode;
                    //     this.getInquiryDetail(response.data.result.detailId);
                    //     // update 페이지로 동작
                    // }

                    this.closeLoading();
                    this.btnLock = false;
                    this.$modal.hide('sendTestPop');
                    this.openAlert("테스트 발송을 완료하였습니다.");
                } catch (error) {
                    this.closeLoading();
                    this.openAlert("요청중 오류가 발생했습니다.");
                    this.$modal.hide('sendTestPop');
                    this.btnLock = false;
                }
            }

           
        }
    }
</script>

<style scoped="scoped">
    .autoSearch {
        position: absolute;
        display: none;
        z-index: 1;
        background-color: #ffffff;
        padding: 10px;
        border: #c7c7c7 1px solid;
        max-height: 500px;
        overflow-y: auto;
        top: 153px;
        left: 80px;
        width: calc(50% - 55px);
    }
    .autoSearch ul {
        list-style: none;
    }

    .autoSearch ul li {
        margin-bottom: 2px;
        padding: 2px 0;
    }
    .autoSearch ul li:hover {
        background-color: #e2e2e2;
    }

    .autoSearch ul li.active {
        background-color: #e2e2e2;
    }

    .autoSearch ul .searchAction {
        float: right;
    }
    .autoSearch ul .searchAction::after {
        clear: both;
    }

    .autoSearch ul .searchAction a {
        text-decoration: none;
    }

    .searchHide {
        display: none;
    }

    .autoSearch2 {
        top: 105px;
        z-index: 2;
    }

    .q_card {
        overflow: visible;
    }

    .input_cal {
        height: 36px;
        padding: 0 8px;
        line-height: 36px;
        border: solid 1px #E3EBF6;
        border-radius: 3px;
        box-shadow: none;
        -webkit-appearance: none;
        font-size: 14px;
    }

    .contents {
        min-height: 800px;
    }

    .pop {
        display: inline-block;
    }

    .sort_btn .btn_del {
        background: url("/img/ico_del.2a36f96e.svg") no-repeat 3px 0;
    }
    .sort_btn .btn_del {
        padding: 3px 10px 2px 25px;
        font-size: 12px;
        border: 1px solid #A0A3B9;
        border-radius: 3px;
    }
    .input_cal {
        height: 36px;
        padding: 0 8px;
        line-height: 36px;
        border: solid 1px #AAACB4;
        border-radius: 3px;
        box-shadow: none;
        -webkit-appearance: none;
        font-size: 14px;
        background: #ffffff;
    }
    .editor {
        border: 1px solid rgb(255, 255, 255) !important;
    }

    .btn_wrap {
        display: flex;
        flex-flow: column nowrap;
    }
    .btn_right {
        align-self: flex-end;
    }
    .send-list {
        padding: 10px 20px;
    }
    .send-list dd{
        flex-basis: auto;
        flex-grow: 0;
        margin-left: 10px;
    }
    .send-list dt{
        flex-basis: auto;
        flex-grow: 0;
    }
    .send-list dt::after {
        content: '';
        border-radius: 4px;
        border: 1px solid #E8EAF0;
        margin-left: 10px;
    }
    .etcBtn {
        padding: 0 15px;
        height: 26px;
        line-height: 25px;
        border: 1px solid #A0A3B9;
        border-radius: 3px;
        color: #A0A3B9;
        font-size: 11px;
        margin-left: 7px;
    }

    .targetContainer{
        height: 600px;
        width: 100%;
        overflow-y: auto;
    }
</style>
