<template>

    <div class="contents" ref="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">출입자</a>
            </li>
            <li>
                <a href="javascript:void(0)">출입기록</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>출입기록</h2>
            </div>
            <h3>
                <div class="btn-right">
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                </div>
            </h3>
            <div class="table table_line">
                <table>
                    <tr>
                        <th>고유번호</th>
                        <th>구분</th>
                        <th>이름</th>
                        <th>출입구분</th>
                        <th>출입시각</th>
                        <th>출입장소</th>
                        <th>비고</th>
                    </tr>
                    <tr v-for="(item, index) in dlist" :key="index">
                        <td>{{convertIdentityNo(item.userId)}}</td>
                        <td>{{item.gubun}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.inOutStatus}}</td>
                        <td>{{item.regDate}}</td>
                        <td>{{item.placeName}}</td>
                        <td>{{convertEventTypeNm(item.eventTypeCode)}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import mixin from '../../mixin/mixin.js';
import moment from 'moment';
import $ from 'jquery';

export default {
    mixins: [mixin],
    data: () => {
        return {dlist: []}
    },
    created: function () {
        this.userId = this.$route.params.userId
        this.visitDate = this.$route.params.visitDate
        this.gubun = this.$route.params.gubun
        this.name = this.$route.params.name
        this.getInoutDetail();
    },
    methods: {
        getInoutDetail: function () {
            let params = {
                userId: this.userId,
                visitDate: this.visitDate,
                gubun: this.gubun,
                name: this.name
            };
            this.$axios
            .get('/api/mng/entrance/v1/pass/pt-nok/detail', {params: params})
            .then((response) => {
                this.dlist = response.data.result.list;
            })
        },
        convertEventTypeNm: function (value) {
            if (value != null) {
                if(value == '4102') value = '';
                else if(value == '6147') value = '측정 오류';
                else if(value == '6419') value = '발열 감지';
                else if(value == '6421') value = '마스크 미착용';
                else if(value == '6420') value = '측정 오류';
                else if(value == '6403') value = '문진 미작성';
                else if(value == '6405') value = '중복 입장';
                else if(value == '6408') value = '중복 입장';
                else if(value == '4354') value = '인증 실패';
            }
            return value
        },
        confirmDownload: function () {
            this.downloadConfirm(this.confirmExcelDown);
        },
        confirmExcelDown: function (param) {
            this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
        },
        excelDown: function (paramdata) {
            let params = {
                userId: this.userId,
                visitDate: this.visitDate,
                gubun: this.gubun,
                name: this.name
            };
            this
            .$axios
            .get('/api/mng/entrance/v1/pass/pt-nok/detail/excel', {
                params: params,
                responseType: "blob"
            })
            .then((response) => {
                console.log(response);
                const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download',this.userId+"_"+'출입기록_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx');
                document.body.appendChild(link);
                link.click();

                if(response.status == 200) {
                      let param = {
                          fileName: this.userId+"_"+'출입기록_' +  moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                          fileSize: response.data.size,
                          purpose: paramdata.purpose,
                          purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                      }
                      this.setDownloadHistory(param);
                }
            })
        }
    }
};
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
</style>
