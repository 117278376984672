<template>
  <div class="contents">
    <div class="loc">
      <li>
        <a href="javascript:void(0)">출입자</a>
      </li>
      <li>
        <a href="javascript:void(0)">협력업체 관리</a>
      </li>
    </div>
    <div class="wapper" style="min-height: 800px;">
      <div class="title">
        <h2>협력업체 관리</h2>
      </div>
      <h3>
        <div class="btn-left">
          <button class="btn_primary" @click="sendConfirm">알림발송</button>
          <button class="btn_primary" @click="deletePartner">선택삭제</button>
<!--          <button class="btn_primary" @click="deleteEmpnm">등록직원삭제</button> 2023.08.08 협력업체삭제하는걸로 오해의 소지가 있는 화면으로 판단되어 빼고, 선택삭제 버튼 추가. (23년 2차 추가개발 TF결정사항)-->
          <button class="btn_primary" @click="updPartnerRegId">발열감지알림수신자변경</button>
        </div>
        <div class="btn-right">
          <select @change="addPartner($event)">
            <option value="" selected="selected" disabled="disabled">등록방식 선택</option>
            <option value="I">개별등록</option>
            <option value="E">엑셀일괄등록</option>
          </select>
          <button class="btn_search" @click="openSearch()">검색</button>
          <button class="btn_download" @click="confirmDownload()">다운로드</button>
          <div class="search">
            <h3>검색</h3>
            <div class="search_detail">
              <dl class="full_width">
                <dt>등록자(사번)</dt>
                <dd>
                  <input type="text" v-model="regUser"/></dd>
              </dl>
              <dl class="full_width">
                <dt>발열감지알림수신자(사번)</dt>
                <dd>
                  <input type="text" v-model="filterpartnerregID"/></dd>
              </dl>
              <dl class="full_width">
                <dt>업체명</dt>
                <dd>
                  <input type="text" v-model="partnerName"/></dd>
              </dl>
              <dl class="full_width">
                <dt>사업자번호</dt>
                <dd>
                  <input type="text" v-model="partnerRegNo"/></dd>
              </dl>
              <dl class="full_width">
                <dt>이름</dt>
                <dd>
                  <input type="text" v-model="empNm"/></dd>
              </dl>
              <dl class="full_width">
                <dt>전화번호</dt>
                <dd>
                  <input type="text" v-model="empPhone"/></dd>
              </dl>
              <dl class="full_width">
                <dt>주소</dt>
                <dd>
                  <input type="text" v-model="addr"/></dd>
              </dl>
            </div>
            <div class="search_footer">
              <button
                  class="btn_secondary btn_md btn_filer"
                  :class="{'btn_filered' : filter}"
                  @click="changeFilter()">{{ this.filter ? "필터해제" : "필터적용" }}
              </button>
              <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
              <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
            </div>
          </div>
        </div>
      </h3>
      <div class="table table_line">
        <table>
          <tr>
            <th><input type="checkbox" v-model="allChecked" @click="checkedAll($event.target.checked)"/></th>
            <th>등록일시</th>
            <th>등록자</th>
            <th>발열감지알림수신자</th>
            <th>업체명</th>
            <th>사업자번호</th>
            <th>주소</th>
            <th>대표번호</th>
            <th>대표자 명</th>
            <th>관리자 명</th>
            <th>관리자 전화번호</th>
            <th>직원등록 권한</th>
            <th>비고</th>
          </tr>
          <tr v-for="(item, index) in partnerList" :key="index">
            <td>
              <input type="checkbox"
                     :id="'check_' + item.partnerCode"
                     :value="item.partnerCode"
                     v-model="item.selected"
                     @change="selected($event)">
            </td>
            <td>{{ convertDate(item.regDate) }}</td>
            <td>{{ item.regUser }}</td>
            <td>{{ item.partnerRegNm + '(' + item.partnerRegId + ')' }}</td>
            <td>{{ item.partnerName }}</td>
            <td>{{ convertPartnerRegNo(item.partnerRegNo) }}</td>
            <td>{{ item.addr }}</td>
            <td>{{ convertPhone(item.phone) }}</td>
            <td>{{ item.repName }}</td>
            <td>{{ item.managerName }}</td>
            <td>{{ convertPhone(item.managerPhone) }}</td>
            <td>{{ item.empAurth ? '있음' : '없음' }}</td>
            <td>
              <button @click="goModify(item.partnerCode)">수정</button>
              <button @click="goEmp(item.partnerCode, item.partnerName)">등록직원 조회</button>
              <button @click="reSend(item)">재발송</button>
            </td>
          </tr>
        </table>
      </div>
      <pagination :pageArray ="pageNation" :page ="page"
              v-if="pageNation.length != 0"
          :regUser="regUser" :filterpartnerregID="filterpartnerregID" :partnerName="partnerName"
        :partnerRegNo="partnerRegNo" :empNm="empNm" :empPhone="empPhone" :addr="addr"
      />
    </div>
  </div>

</template>

<script>
import pagination from '../../components/commonPagination.vue';
import userSearchConf from '../../components/partnergrouplist/userSearchConf.vue';
import mixin from '../../mixin/mixin.js';
import moment from 'moment';
import $ from 'jquery'

export default {
  data: () => {
    return {
      partnerName: null,
      partnerList: [],
      filter: false,
      allChecked: false,
      empNm: null,
      empPhone: null,
      regUser: null,
      partnerRegNo: null,
      addr: null,
      partner_reg_userNm: null,
      partner_reg_deptNm: null,
      partner_reg_userId: null,
      partner_reg_deptCd: null,
      filterpartnerregID: null
    }
  },
  mixins: [mixin],
  mounted: function() {
            if(this.$route.query.page) {
                this.changeFilter()
                this.regUser=this.$route.query.regUser||null;
                this.partnerName=this.$route.query.partnerName||null;
                this.partnerRegNo=this.$route.query.partnerRegNo||null;
                this.filterpartnerregID=this.$route.query.filterpartnerregID||null;
                this.empNm=this.$route.query.empNm||null;
                this.empPhone=this.$route.query.empPhone||null;
                this.addr=this.$route.query.addr||null;
                this.getList(this.$route.query.page);
            } else {
    this.getList(0);}

        },
        components : {
            pagination
  },
  methods: {
    getList: function (page) {
      if (page < 0 || this.page.totalPages < page) {
        return;
      }

      let param = {};

      if (this.filter) {
        if (this.empNm !== null || this.empPhone !== null) {
          //firstSearch()에서 이미 rout.push를 진행하기 때문에 2중 routing이 되서 뒤로가기 기능이 동작하지 않는 이슈를 방지하기 위해 replace 사용this
          this
              .$router
              .replace({
                name: 'partnerEmpList',
                query: {
                  empNm: this.empNm,
                  empPhone: this.empPhone,
                  searchYn: "Y"
                }
              });
        } else {
          param = {
            page: page,
            size: 30,
            partnerNm: this.partnerName,
            regUser: this.regUser,
            partnerRegNo: this.partnerRegNo,
            addr: this.addr,
            partnerRegUserId: this.filterpartnerregID
          }
        }
      } else {
        param = {
          page: page,
          size: 30
        }
      }
      if (this.filter) {
        this
            .$axios
            .get('/api/mng/partner/group/v2/list', {params: param})
            .then((response) => {
              console.log(response.data);

              if (response.data.success) {
                let result = response.data.result.content;

                this.partnerList = result;

                let pageInfo = response.data.result;

                this.pagerHandler(
                    pageInfo.number,
                    pageInfo.first,
                    pageInfo.last,
                    pageInfo.totalPages
                );
              } else {
                this.openAlert("협력업체를 불러오는 도중 오류가 발생하였습니다.");
              }
            })
            .catch(() => {
              this.openAlert("협력업체를 불러오는 도중 오류가 발생하였습니다.");
            });
      } else {
        this
            .$axios
            .get('/api/mng/partner/group/v1/list', {params: param})
            .then((response) => {
              console.log(response.data);

              if (response.data.success) {
                let result = response.data.result.content;

                this.partnerList = result;

                let pageInfo = response.data.result;

                this.pagerHandler(
                    pageInfo.number,
                    pageInfo.first,
                    pageInfo.last,
                    pageInfo.totalPages
                );
              } else {
                this.openAlert("협력업체를 불러오는 도중 오류가 발생하였습니다.");
              }
            })
            .catch(() => {
              this.openAlert("협력업체를 불러오는 도중 오류가 발생하였습니다.");
            });
      }

    },
    firstSearch: function () {
                let pageQuery = {
                    page : 0,
                    regUser:this.regUser,
                    partnerName:this.partnerName,
                    partnerRegNo:this.partnerRegNo,
                    filterpartnerregID:this.filterpartnerregID,
                    empNm:this.empNm,
                    empPhone:this.empPhone,
                    addr:this.addr,
                }
                this.$router.push({
                    path: this.$route.path,
                    query: pageQuery
                }).catch(()=>{});
            }
    ,openSearch: function () {
      $('.search').slideToggle('down');
    },
    closeSearch: function () {
      $('.search').slideToggle('up');
    },
    changeFilter: function () {
      this.filter = !this.filter;
      if (!this.filter) {
        this.initSearchCnd();
      }
    },
    initSearchCnd: function () {
      this.partnerName = null;
      this.regUser = null;
      this.partnerRegNo = null;
      this.addr = null;
    },
    addPartner: function (event) {
      if (event.target.value == 'I') {
        this
            .$router
            .push({
              name: 'partnerGroupAdd',
              query: {
                list: this.partnerList
              }
            });
      } else {
        this.$router.push({
          name: 'partnerGroupAddExcel',
          query: {
            list: this.partnerList
          }
        });
      }

    },
    goModify: function (partnerCode) {
      this
          .$router
          .push({
            name: 'partnerGroupUpd',
            query: {
              pid: partnerCode
            }
          });
    },
    goEmp: function (partnerCode, partnerName) {
      this
          .$router
          .push({
            name: 'partnerEmpList',
            query: {
              pid: partnerCode,
              pname: partnerName
            }
          });
    },
    confirmDownload: function () {
      this.downloadConfirm(this.confirmExcelDown);
    },
    confirmExcelDown: function (param) {
      this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
    },
    excelDown: function (paramdata) {
      let param = {};
      if (this.filter) {
        param = {
          page: 0,
          size: 100000,
          partnerName: this.partnerName,
          regUser: this.regUser,
          partnerRegNo: this.partnerRegNo,
          addr: this.addr
        }
      } else {
        param = {
          page: 0,
          size: 100000
        }
      }
      this
          .$axios
          .get('/api/mng/partner/group/v1/list/excel', {params: param, responseType: "blob"})
          .then((response) => {
            console.log(response);
            const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '협력업체관리_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx');
            document.body.appendChild(link);
            link.click();

            if (response.status == 200) {
              let param = {
                fileName: '협력업체관리_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                fileSize: response.data.size,
                purpose: paramdata.purpose,
                purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
              }
              this.setDownloadHistory(param);
            }
          })
    },
    reSend: function (item) {
      this.$axios.post('/api/mng/partner/group/v1/list/resend', {
        partnerCode: item.partnerCode,
        repName: item.repName,
        managerPhone: item.managerPhone
      })
          .then((response) => {
            if (response.data.success) {
              this.openAlert("메시지를 재발송하였습니다.");
            } else {
              this.openAlert("메시지 발송중 오류가 발생했습니다.");
            }
          }).catch(() => {
        this.openAlert("메시지 발송중 오류가 발생했습니다.");
      })
    },
    deleteEmpnm: function () {
      let list = this.partnerList;
      let selectedYn = false;
      for (let i in list) {
        if (list[i].selected) selectedYn = true;
      }
      if (selectedYn) this.openConfirm("선택하신 협력업체의 등록직원을 삭제합니다.", this.checkedDelete);
      else this.openAlert("협력업체를 선택해 주세요.");
    },
    deletePartner() {
      let list = this.partnerList;
      let selectedYn = false;
      for (let i in list) {
        if (list[i].selected) selectedYn = true;
      }
      if (selectedYn) this.openConfirm("선택하신 협력업체와 해당 등록직원을 삭제합니다.", this.checkedPartnerGroupDelete);
      else this.openAlert("협력업체를 선택해 주세요.");
    },
    openSearchUser: function () {
      this
          .$modal
          .show(userSearchConf, {
            searchUserConf: this.searchUserConf
          }, {
            width: '540px',
            height: 'auto',
            adaptive: true,
            clickToClose: false
          },)

    },
    searchUserConf: function (userid, usernm) {
      this.partner_reg_userId = userid;
      this.partner_reg_userNm = usernm;
      this.CheckedUpdPartnerRegId()
    },
    CheckedUpdPartnerRegId: function () {
      let list = this.partnerList;
      for (let i in list) {
        if (list[i].selected) {
          console.log(list[i].partnerCode);
          this.confirmSave(list[i]);
          list[i].partnerRegId = this.partner_reg_userId
          list[i].partnerRegNm = this.partner_reg_userNm
        }
      }
    },
    updPartnerRegId: function () {
      let list = this.partnerList;
      let selectedYn = false;
      for (let i in list) {
        if (list[i].selected) selectedYn = true;
      }
      if (selectedYn) this.openSearchUser();
      else this.openAlert("협력업체를 선택해 주세요.");
    },
    checkDoDelete: function (item) {
      console.log(item);
      this.$axios.post('/api/mng/partner/group/v1/list/checkDeleteEmp', {
        partnerCode: item.partnerCode
      })
          .then((response) => {
            if (response.data.success) {
            }
          }).catch(() => {
        this.openAlert("등록직원 삭제중 오류가 발생했습니다.");
      })
    },
    checkedDelete: function () {
      let list = this.partnerList;
      let selectedYn = false;
      for (let i in list) {
        if (list[i].selected) {
          console.log(list[i].partnerCode);
          this.checkDoDelete(list[i]);
          selectedYn = true;
        }
      }
      if (selectedYn) this.openAlert("등록직원을 삭제하였습니다.");
    },
    checkedPartnerGroupDelete() {
      let list = this.partnerList;
      const deleteTarget = [];
      for (let i in list) {
        if (list[i].selected) {
          deleteTarget.push(list[i].partnerCode)
        }
      }
      this.$axios.post("/api/mng/partner/group/v1/list/checkDeletePartner", {
        groupCodeList : deleteTarget
      }).then( response => {
       if(response.data.success){
         const empCount = response.data.result.partnerEmpCount;
         const partnerCount = response.data.result.partnerGroupCount;
         this.openAlert("협력업체 " + partnerCount +"곳, 등록직원 " + empCount + "명을 모두 삭제하였습니다.")
         this.getList(0);
       } else {
         this.openAlert("협력업체를 삭제하는 도중 오류가 발생했습니다. 관리자에게 문의 하세요.");
       }
      }).catch( () => {
        this.openAlert("협력업체를 삭제하는 도중 오류가 발생했습니다. 관리자에게 문의 하세요.");
      })
    },
    sendConfirm: function () {
      let list = this.partnerList;
      let selectedYn = false;
      for (let i in list) {
        if (list[i].selected) selectedYn = true;
      }
      if (selectedYn) this.openConfirm("선택하신 협력업체 직원분들께 알림을 발송합니다.", this.checkedSend);
      else this.openAlert("협력업체를 선택해 주세요.");
    },
    checkReSend: function (item) {
      console.log(item);
      this.$axios.post('/api/mng/partner/group/v1/list/checkResend', {
        partnerCode: item.partnerCode
      })
          .then((response) => {
            if (response.data.success) {
            }
          }).catch(() => {
        this.openAlert("메시지 발송중 오류가 발생했습니다.");
      })
    },
    checkedSend: function () {
      let list = this.partnerList;
      let selectedYn = false;
      for (let i in list) {
        if (list[i].selected) {
          console.log(list[i].partnerCode);
          this.checkReSend(list[i]);
          selectedYn = true;
        }
      }
      if (selectedYn) this.openAlert("메시지를 발송하였습니다.");
    },
    checkedAll: function (checked) {
      this.allChecked = checked
      for (let i in this.partnerList) {
        this.partnerList[i].selected = this.allChecked;
      }
    },
    selected(e) {
      for (let i in this.partnerList) {
        if (!this.partnerList[i].selected) {
          this.allChecked = false;
          return;
        } else {
          this.allChecked = true;
        }
      }
    },
    confirmSave: function (item) {
      let checkList = [
        {
          id: item.partnerName,
          name: '업체명'
        }, {
          id: item.addr,
          name: '주소'
        }, {
          id: item.phone,
          name: '대표번호'
        }, {
          id: item.repName,
          name: '대표자이름'
        }, {
          id: item.managerName,
          name: '관리자이름'
        }, {
          id: item.managerPhone,
          name: '관리자전화번호'
        }, {
          id: this.partner_reg_userId,
          name: '발열감지알림수신자'
        }
      ]

      let partnerRegNo = item.partnerRegNo;
      let phone = item.phone;
      let managerPhone = item.managerPhone;
      partnerRegNo = partnerRegNo.replaceAll('-', '');
      phone = phone.replaceAll('-', '');
      managerPhone = managerPhone.replaceAll('-', '');

      if (!this.nullChecker(checkList)) {
        return;
      }
      if (item.partnerName.trim().length > 50) {
        this.openAlert('업체명은 50자를 넘을 수 없습니다.');
        return false;
      }
      if (partnerRegNo != null) {
        if (partnerRegNo.trim().length > 10) {
          this.openAlert('사업자번호는 10자를 넘을 수 없습니다.');
          return false;
        }
      }
      if (item.addr.trim().length > 100) {
        this.openAlert('주소는 100자를 넘을 수 없습니다.');
        return false;
      }
      if (phone.trim().length > 11) {
        this.openAlert('대표번호는 11자를 넘을 수 없습니다.');
        return false;
      }
      if (item.repName.trim().length > 20) {
        this.openAlert('대표자명은 20자를 넘을 수 없습니다.');
        return false;
      }
      if (item.managerName.trim().length > 20) {
        this.openAlert('관리자명은 20자를 넘을 수 없습니다.');
        return false;
      }
      if (managerPhone.trim().length > 11) {
        this.openAlert('관리자 전화번호는 11자를 넘을 수 없습니다.');
        return false;
      }

      this
          .$axios
          .put('/api/mng/partner/group/v1/list', {
            partnerCode: item.partnerCode,
            partnerRegNo: partnerRegNo,
            partnerName: item.partnerName,
            addr: item.addr,
            phone: phone,
            repName: item.repName,
            managerName: item.managerName,
            managerPhone: managerPhone,
            empAurth: item.empAurth,
            partnerRegNm: this.partner_reg_userNm,
            partnerRegId: this.partner_reg_userId
          })
          .then((response) => {
            console.log(response.data);

            if (!response.data.success) {
              this.openAlert("저장하는 도중 오류가 발생하였습니다.");
            }
          })
          .catch(() => {
            this.openAlert("저장하는 도중 오류가 발생하였습니다.");
          });

    },
  }
}
</script>
<style scoped="scoped">
.search_footer .btn_filered {
  margin-right: auto;
  justify-self: start;
  background-color: #E85656;
  border: 1px solid #E85656;
  color: white;
}

.contents input[type="checkbox"] {
  display: block;
}
</style>
