<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">출입자</a>
            </li>
            <li>
                <a href="javascript:void(0)">환자·보호자</a>
            </li>
        </div>
        <div class="wapper" style="min-height:700px">
            <div class="title">
                <h2>출입자 관리 (환자·보호자)</h2>
                <p v-if="activeTab == 'nokList'" class="sub_title">{{convertDateDay(new Date())}} 기준 출입대상 (상주보호자) 목록입니다.(총
                {{totalCount}}명)</p>
                <p v-else-if="activeTab == 'icuGstList'" class="sub_title">{{convertDateDay(new Date())}} 기준 출입대상 (중환자실 면회객) 목록입니다.(총
                {{totalCount}}명)</p>
                <p v-else class="sub_title">{{convertDateDay(new Date())}} 기준 출입대상 (환자·보호자) 목록입니다.(총
                {{totalCount}}명)</p>
            </div>
            <div class="tabArea">
                <input id="tab1" type="radio" name="tabs" :checked="activeTab == 'ptList'" @click="tabChange('ptList')"/>
                <label for="tab1">환자·보호자(외래/검사)</label>
                <input id="tab2" type="radio" name="tabs" :checked="activeTab == 'nokList'" @click="tabChange('nokList')">
                <label for="tab2">상주보호자</label>
                <input id="tab3" type="radio" name="tabs" :checked="activeTab == 'icuGstList'" @click="tabChange('icuGstList')" >
                <label for="tab3">중환자실 면회객</label>
                <input id="tab4" type="radio" name="tabs" :checked="activeTab == 'emmaList'" @click="tabChange('emmaList')">
                <label for="tab4">환자·보호자(응급실) </label>
                <ptList id="tab_content1" @totalCountFunc="totalCountFunc" v-if="activeTab == 'ptList'" ></ptList>
                <nokList id="tab_content2" @totalCountFunc="totalCountFunc" v-if="activeTab == 'nokList'" ></nokList>
                <icuGstList id="tab_content3" @totalCountFunc="totalCountFunc" v-if="activeTab == 'icuGstList'" ></icuGstList>
                <emmaList id="tab_content4" @totalCountFunc="totalCountFunc" v-if="activeTab == 'emmaList'" ></emmaList>
            </div>
        </div>
    </div>
</template>
<script>
    import mixin from '../../mixin/mixin.js';
    import ptList from './ptList.vue';
    import nokList from './nokList.vue';
    import emmaList from './emmaList.vue';
    import icuGstList from './icuGstList.vue';

    export default {
        mixins: [mixin],
        data: () => {
            return {
                totalCount: 0,
                activeTab:'ptList'
            }
        },
        created: function () {
             if (this.$route.query.tab) {
                this.activeTab = this.$route.query.tab
            }
        },
        components: {
            ptList,
            nokList,
            icuGstList,
            emmaList
        },
        methods: {
            totalCountFunc: function (value) {
                this.totalCount = value;
            },
            tabChange(tab) {
                this.activeTab = tab;
                this.$router.push({
                    name: this.$route.name,
                    query: {
                        tab
                    }
                })

            }
        }
    }
</script>