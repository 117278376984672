<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">문진</a>
            </li>
            <li>
                <a href="javascript:void(0)">방문력관리(영어)</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>방문력 다국어관리</h2>
            </div>
            <div class="version_info">
                <dl>
                    <!-- <dt></dt> -->
                    <dd>
                        <!-- <select> <option>선택</option> <option>English</option> </select> &nbsp; -->
                        <span>해당 일에 표출된 방문력을 표시합니다.</span>
                    </dd>
                </dl>
            </div>
            <div class="question_wrap">
                <h3>방문력
                    <!-- <div class="q_road"> <button class="btn_primary" id="btn_q_road">문진구성
                    불러오기</button> </div> -->
                </h3>
                <div class="q_card_group">
                    <div class="q_card">
                        <!-- <dl> <dt>구분</dt> <dd> <table> <tr> <td style="width:220px;">증상</td> </tr>
                        </table> </dd> </dl> -->
                        <dl>
                            <dt>항목</dt>
                            <dd>
                                <table>
                                    <tr>
                                        <th>상호</th>
                                        <th>번역</th>
                                    </tr>
                                    <tr v-for="(item,index) in this.placeList" :key="index">
                                        <td style="width:50%">{{item.storeName}}
                                            <br/>
                                            <h6 style="color:#ababab;">{{' [' + item.local1 + ' ' + item.local2 + ' ' +item.local3+ '(' + item.localEn1 + ' ' +item.localEn2 + ' ' +item.localEn3+ ')]'}}</h6>
                                        </td>
                                        <td><input type="text" v-model="item.storeNameEn" placeholder="해당언어 입력"/></td>
                                    </tr>

                                </table>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
            <div class="btn_wrap">
                <div class="btn_right">
                    <button class="btn_secondary" @click="$router.push({name :'placeManage'})">취 소</button>
                    <button class="btn_primary" @click="saveConfrim()">저 장</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import mixin from '../../mixin/mixin.js';
    export default {
        data: () => {
            return {placeList: []}
        },
        mixins: [mixin],
        created: function () {
            this.getResult();
        },
        methods: {
            getResult: function () {
                this
                    .$axios
                    .get('/api/mng/placehistory/v1/inter')
                    .then((response) => {
                        console.log(response.data);
                        this.placeList = response.data.result;
                    })
            },
            saveConfrim: function () {
                for (let item of this.placeList) {
                    if (item.storeNameEn == null) {
                        this.openAlert("모든 방문력을 입력해야 저장할 수 있습니다. <br/> 누락된 상호 :" + item.storeName);
                        return;
                    }
                }
                this.openConfirm("영문 방문력을 저장하시겠습니까?", this.save);

            },
            save: function () {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                this
                    .$axios
                    .post('/api/mng/placehistory/v1/inter', {interList: this.placeList})
                    .then((response) => {
                        if (response.data.success) {
                            this.openAlert("저장이 완료되었습니다.");
                            this.getResult();

                        } else {
                            this.openAlert("저장중 오류가 발생했습니다.");
                        }
                        this.btnLock = false;
                    })
                    .catch(() => {
                        this.openAlert("저장중 오류가 발생했습니다.");
                        this.btnLock = false;
                    })
                }
        }

    }
</script>