<template>
    <div>
        <v-app-bar app="app">
            <v-toolbar-title>디바이스 관리</v-toolbar-title>
        </v-app-bar>

        <!-- Sizes your content based upon application components -->
        <v-main>

            <!-- Provides the application the proper gutter -->
            <v-container fluid="fluid">
                <v-container class="grey lighten-5">
                    <v-list-item two-line="two-line">
                        <v-list-item-content>
                            <v-list-item-title class="headline" v-if="!updMode">
                                디바이스 등록
                            </v-list-item-title>
                            <v-list-item-title class="headline" v-if="updMode">
                                디바이스 수정
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-row no-gutters="no-gutters">
                        <v-col cols="12" sm="12">
                            <v-simple-table v-if="!updMode">
                                <template v-slot:default="">
                                    <thead>
                                        <tr>
                                            <th>
                                                디바이스 ID
                                            </th>
                                            <th>
                                                디바이스 IP
                                            </th>
                                            <th>
                                                디바이스 이름
                                            </th>
                                            <th>
                                                디바이스 유형 코드
                                            </th>
                                            <th>
                                                디바이스 유형 이름
                                            </th>
                                            <th>
                                                위치
                                            </th>
                                            <th>
                                                위치 코드
                                            </th>
                                            <th>
                                                위치 상세
                                            </th>
                                            <th>
                                                위치 상세 코드
                                            </th>
                                            <th>
                                                상위 디바이스
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style="  border: thin solid rgba(0, 0, 0, 0.12);">
                                                <v-text-field v-model="deviceId" label="디바이스 ID" hide-details="auto"></v-text-field>
                                            </td>
                                            <td style=" border: thin solid rgba(0, 0, 0, 0.12);">
                                                <v-text-field v-model="deviceIp" label="디바이스 IP" hide-details="auto"></v-text-field>
                                            </td>
                                            <td style=" border: thin solid rgba(0, 0, 0, 0.12);">
                                                <v-text-field v-model="deviceName" label="디바이스 이름" hide-details="auto"></v-text-field>
                                            </td>
                                            <td style=" border: thin solid rgba(0, 0, 0, 0.12);">
                                                <v-text-field v-model="deviceTypeCd" label="디바이스 유형 코드" hide-details="auto"></v-text-field>
                                            </td>
                                            <td style=" border: thin solid rgba(0, 0, 0, 0.12);">
                                                <v-text-field v-model="deviceTypeNm" label="디바이스 유형 이름" hide-details="auto"></v-text-field>
                                            </td>
                                            <td style=" border: thin solid rgba(0, 0, 0, 0.12);">
                                                <v-text-field v-model="location" label="위치" hide-details="auto"></v-text-field>
                                            </td>
                                            <td style=" border: thin solid rgba(0, 0, 0, 0.12);">
                                                <v-text-field v-model="locationCd" label="위치 코드" hide-details="auto"></v-text-field>
                                            </td>
                                            <td style=" border: thin solid rgba(0, 0, 0, 0.12);">
                                                <v-text-field v-model="locationDesc" label="위치 상세" hide-details="auto"></v-text-field>
                                            </td>
                                            <td style=" border: thin solid rgba(0, 0, 0, 0.12);">
                                                <v-text-field v-model="locationDescCd" label="위치 상세 코드" hide-details="auto"></v-text-field>
                                            </td>
                                            <td style=" border: thin solid rgba(0, 0, 0, 0.12);">
                                                <v-text-field v-model="parentDeviceId" label="상위 디바이스" hide-details="auto"></v-text-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>

                            <v-simple-table v-if="updMode">
                                <template v-slot:default="">
                                    <thead>
                                        <tr>
                                            <th>
                                                디바이스 ID
                                            </th>
                                            <th>
                                                디바이스 IP
                                            </th>
                                            <th>
                                                디바이스 이름
                                            </th>
                                            <th>
                                                디바이스 유형 코드
                                            </th>
                                            <th>
                                                디바이스 유형 이름
                                            </th>
                                            <th>
                                                위치
                                            </th>
                                            <th>
                                                위치 코드
                                            </th>
                                            <th>
                                                위치 상세
                                            </th>
                                            <th>
                                                위치 상세 코드
                                            </th>
                                            <th>
                                                상위 디바이스
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style="  border: thin solid rgba(0, 0, 0, 0.12);">
                                                <v-text-field
                                                    v-model="deviceId"
                                                    label="디바이스 ID"
                                                    hide-details="auto"
                                                    readonly="readonly"></v-text-field>
                                            </td>
                                            <td style=" border: thin solid rgba(0, 0, 0, 0.12);">
                                                <v-text-field v-model="deviceIp" label="디바이스 IP" hide-details="auto"></v-text-field>
                                            </td>
                                            <td style=" border: thin solid rgba(0, 0, 0, 0.12);">
                                                <v-text-field v-model="deviceName" label="디바이스 이름" hide-details="auto"></v-text-field>
                                            </td>
                                            <td style=" border: thin solid rgba(0, 0, 0, 0.12);">
                                                <v-text-field v-model="deviceTypeCd" label="디바이스 유형 코드" hide-details="auto"></v-text-field>
                                            </td>
                                            <td style=" border: thin solid rgba(0, 0, 0, 0.12);">
                                                <v-text-field v-model="deviceTypeNm" label="디바이스 유형 이름" hide-details="auto"></v-text-field>
                                            </td>
                                            <td style=" border: thin solid rgba(0, 0, 0, 0.12);">
                                                <v-text-field v-model="location" label="위치" hide-details="auto"></v-text-field>
                                            </td>
                                            <td style=" border: thin solid rgba(0, 0, 0, 0.12);">
                                                <v-text-field v-model="locationCd" label="위치 코드" hide-details="auto"></v-text-field>
                                            </td>
                                            <td style=" border: thin solid rgba(0, 0, 0, 0.12);">
                                                <v-text-field v-model="locationDesc" label="위치 상세" hide-details="auto"></v-text-field>
                                            </td>
                                            <td style=" border: thin solid rgba(0, 0, 0, 0.12);">
                                                <v-text-field v-model="locationDescCd" label="위치 상세 코드" hide-details="auto"></v-text-field>
                                            </td>
                                            <td style=" border: thin solid rgba(0, 0, 0, 0.12);">
                                                <v-text-field v-model="parentDeviceId" label="상위 디바이스" hide-details="auto"></v-text-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>

                        </v-col>
                    </v-row >
                    <v-row no-gutters="no-gutters" v-if="!updMode">
                        <v-col cols="12" sm="12" class="text-center">
                            <v-btn color="success" dark="dark" large="large" @click="save()">
                                디바이스 등록
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row no-gutters="no-gutters" v-if="updMode">
                        <v-col cols="12" sm="12" class="text-center">
                            <v-btn class="mr-2" color="success" dark="dark" large="large" @click="update()">
                                디바이스 수정
                            </v-btn>
                            <v-btn color="gray" dark="dark" large="large" @click="updCancel()">
                                취소
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-divider class="mt-5"></v-divider>

                    <v-list-item two-line="two-line">
                        <v-list-item-content>
                            <v-list-item-title class="headline">
                                디바이스 리스트
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-row>
                        <v-col sm="12">
                            <v-data-table
                                :headers="headers"
                                :items="deviceList"
                                disable-pagination="disable-pagination"
                                disable-items-per-page="true"
                                hide-default-footer="hide-default-footer"
                                class="elevation-1">
                                <template v-slot:item.regDate="{ item }">
                                    {{ convertDate(item.regDate) }}
                                </template>
                                <template v-slot:item.updDate="{ item }">
                                    {{ convertDate(item.updDate) }}
                                </template>
                                <template v-slot:item.manage="{ item }">
                                    <v-btn icon="icon" @click="manage(item.deviceId)">
                                        <v-icon color="grey lighten-1">mdi-cog</v-icon>
                                    </v-btn>
                                    <v-btn icon="icon" @click="deleteApi(item.deviceId)">
                                        <v-icon color="red lighten-1">mdi-delete-forever</v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>

                </v-container>
            </v-container>
        </v-main>
    </div>
</template>
<script>
    export default {
        data: () => ({
            updMode: false,
            deviceList: [],
            deviceId: '',
            deviceIp: '',
            deviceName: '',
            deviceTypeCd: '',
            deviceTypeNm: '',
            location: '',
            locationCd: '',
            locationDesc: '',
            locationDescCd: '',
            parentDeviceId: '',
            headers: [
                {
                    text: '디바이스 ID',
                    align: 'start',
                    sortable: false,
                    value: 'deviceId'
                }, {
                    text: '디바이스 IP',
                    sortable: false,
                    value: 'deviceIp'
                }, {
                    text: '디바이스 이름',
                    sortable: false,
                    value: 'deviceName'
                }, {
                    text: '디바이스 유형 코드',
                    sortable: false,
                    value: 'deviceTypeCd'
                }, {
                    text: '디바이스 유형 이름',
                    sortable: false,
                    value: 'deviceTypeNm'
                }, {
                    text: '위치',
                    sortable: false,
                    value: 'location'
                }, {
                    text: '위치 코드',
                    sortable: false,
                    value: 'locationCd'
                }, {
                    text: '위치 상세',
                    sortable: false,
                    value: 'locationDesc'
                }, {
                    text: '위치 상세 코드',
                    sortable: false,
                    value: 'locationDescCd'
                }, {
                    text: '상위 디바이스',
                    sortable: false,
                    value: 'parentDeviceId'
                }, {
                    text: '등록자',
                    sortable: false,
                    value: 'regUser'
                }, {
                    text: '등록일',
                    sortable: false,
                    value: 'regDate'
                }, {
                    text: '수정자',
                    sortable: false,
                    value: 'updUser'
                }, {
                    text: '수정일',
                    sortable: false,
                    value: 'updDate'
                }, {
                    text: '관리',
                    sortable: false,
                    value: 'manage'
                }
            ]
        }),
        computed: {},
        created: function () {
            this.getDeviceList();
        },
        mounted: function () {},
        methods: {
            getDeviceList: function () {
                this
                    .$axios
                    .get('/api/mng/device-list')
                    .then((response) => {
                        this.deviceList = response.data.result;
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                },
            convertDate: function (date) {
                if (date == null || date == undefined) {
                    return '';
                }
                let dateVal = new Date(date);
                let year = dateVal.getFullYear();
                let month = dateVal.getMonth() + 1;
                month = month >= 10
                    ? month
                    : '0' + month;
                let day = dateVal.getDate();
                let hour = dateVal.getHours();
                let minute = dateVal.getMinutes();

                let result = year + '-' + month
                    .toString()
                    .padStart('2', '0') + '-' + day
                    .toString()
                    .padStart('2', '0') + ' ' + hour
                    .toString()
                    .padStart('2', '0') + ':' + minute
                    .toString()
                    .padStart('2', '0');

                return result;
            },
            manage: function (deviceId) {
                console.log('deviceId : ', deviceId);
                console.log(this.deviceList)
                this.updMode = true;
                for (var i = 0; i < this.deviceList.length; i++) {
                    if (this.deviceList[i].deviceId == deviceId) {
                        var device = this.deviceList[i];
                        this.deviceId = device.deviceId;
                        this.deviceIp = device.deviceIp;
                        this.deviceName = device.deviceName;
                        this.deviceTypeCd = device.deviceTypeCd;
                        this.deviceTypeNm = device.deviceTypeNm;
                        this.location = device.location;
                        this.locationCd = device.locationCd;
                        this.locationDesc = device.locationDesc;
                        this.locationDescCd = device.locationDescCd;
                        break;
                    }
                }
            },
            updCancel: function () {
                this.updMode = false;
                this.deviceId = '';
                this.deviceIp = '';
                this.deviceName = '';
                this.deviceTypeCd = '';
                this.deviceTypeNm = '';
                this.location = '';
                this.locationCd = '';
                this.locationDesc = '';
                this.locationDescCd = ''
            },
            save: function () {
                console.log("save !!");
                if (this.deviceId == '' || this.deviceId == null) {
                    alert("디바이스 Id를 입력하세요.");
                    return;
                }
                this
                    .$axios
                    .post('/api/mng/device-manage', {
                        deviceId: this.deviceId,
                        deviceIp: this.deviceIp,
                        deviceName: this.deviceName,
                        deviceTypeCd: this.deviceTypeCd,
                        deviceTypeNm: this.deviceTypeNm,
                        location: this.location,
                        locationCd: this.locationCd,
                        locationDesc: this.locationDesc,
                        locationDescCd: this.locationDescCd
                    })
                    .then((response) => {
                        console.log(response);
                        this
                            .$router
                            .go();
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                },
            update: function () {
                console.log("update !!");
                if (this.deviceId == '' || this.deviceId == null) {
                    alert("디바이스 Id를 입력하세요.");
                    return;
                }
                this
                    .$axios
                    .put('/api/mng/device-manage', {
                        deviceId: this.deviceId,
                        deviceIp: this.deviceIp,
                        deviceName: this.deviceName,
                        deviceTypeCd: this.deviceTypeCd,
                        deviceTypeNm: this.deviceTypeNm,
                        location: this.location,
                        locationCd: this.locationCd,
                        locationDesc: this.locationDesc,
                        locationDescCd: this.locationDescCd
                    })
                    .then((response) => {
                        console.log(response);
                        this
                            .$router
                            .go();
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                },
            deleteApi: function (apiId) {
                console.log(apiId);
                if (confirm("해당 디바이스정보를 삭제하시겠습니까?")) {
                    this
                        .$axios
                        .delete('/api/mng/device-manage?deviceId=' + apiId)
                        .then((response) => {
                            console.log(response);
                            this
                                .$router
                                .go();
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                    }
            }

        }
    }
</script>