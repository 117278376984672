<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">출입자</a>
            </li>
            <li>
                <a href="javascript:void(0)">보호자 관리</a>
            </li>
        </div>
        <div class="wapper" style="min-height:700px">
            <div class="title">
                <h2>보호자 관리</h2>
                <p class="sub_title">{{convertDate(new Date())}}. 보호자 관리 목록입니다.(총
                    {{totalCount}}명)</p>
            </div>
            <h3>
                <div class="btn-right">
                    <button class="btn_search" @click="openSearch()">검색</button>
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">
                            <dl class="full_width">
                                <dt>날짜</dt>
                                <dd><input type='date' v-model='visitDate' @change="changeVisitDate"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>환자명</dt>
                                <dd><input type="text" v-model='identity_name'/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>환자 번호</dt>
                                <dd><input type="text" v-model='identity_no'/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>보호자 연락처</dt>
                                <dd><input type="text" v-model='phone_no'/></dd>
                            </dl>
                        </div>
                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'btn_filered' : filter}"
                                @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                            <button class="btn_primary btn_md" @click="getNokMngResult(0)">적용</button>
                        </div>
                    </div>
                </div>
            </h3>
            <div class="table table_line">
                <table>
                    <colgroup>
                        <col width="30%"/>
                        <col width="30%"/>
                        <col width="40%"/>
                    </colgroup>
                    <tr>
                        <th>환자명</th>
                        <th>환자 등록 번호</th>
                        <th>등록된 보호자</th>
                    </tr>
                    <tr v-for="(item,index) in this.list" :key="index">
                        <td>{{item.identity_name}}</td>
                        <td>{{item.identity_no}}</td>
                        <td v-html="convertPhoneNok(item.phone_no)"></td>
                    </tr>
                </table>
            </div>
            <div class="pagination">
                <ul>
                    <li>
                        <a href="javascript:void(0)" @click="getNokMngResult(page.number - 1)">이전</a>
                    </li>
                    <li
                        v-for="(item, index ) in pageNation"
                        :key="index"
                        class="pageNo"
                        :class="{'active': item == page.number +1}">
                        <a href="javascript:void(0)" @click="getNokMngResult(item - 1)">{{item}}</a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="getNokMngResult(page.number + 1)">다음</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';
    import $ from 'jquery'

    export default {
        mixins: [mixin],
        data: () => {
            return {list: [], filter: false, totalCount: 0,
                visitDate: moment().format("YYYY-MM-DD"),
                identity_name: null,
                identity_no: null,
                phone_no: null
            }
        },
        created: function () {
            this.getNokMngResult(0);
        },
        methods: {
            getNokMngResult: function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }

                let params = {};

                if (this.filter) {
                    params = {
                        page: page,
                        size: 30,
                        visitDate: this.visitDate,
                        identity_name: this.identity_name,
                        identity_no: this.identity_no,
                        phone_no: this.phone_no
                    }
                } else {
                    params = {
                        page: page,
                        size: 30,
                        visitDate: this.visitDate
                    }
                }
                console.log(JSON.stringify(params));
                this.$axios
                    .get('/api/mng/entrance/v1/pass/nok-Mng', {params: params})
                    .then((response) => {
                        this.list = response.data.result.list;

                        let pageInfo = response.data.result.pageInfo;
                        this.totalCount = pageInfo.totalCount;

                        this.pagerHandler(
                            pageInfo.number,
                            pageInfo.first,
                            pageInfo.last,
                            pageInfo.totalPages
                        );
                    })
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.visitDate = moment().format("YYYY-MM-DD");
                identity_name = null;
                identity_no = null;
                phone_no = null;
            },
            changeVisitDate(event) {
                this.visitDate = event.target.value;
            },
            convertPhoneNok(value) {
                if(value !== undefined) {
                    value = value.replaceAll(', ', '<br />');
                }
                return value;
            },
            confirmDownload: function () {
                  this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
                console.log(param.purpose);
                console.log(param.purpose_etc);
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {
                let params = {};
                if (this.filter) {
                    params = {
                        page: 0,
                        size: 100000,
                        visitDate: this.visitDate,
                        deptNm: this.deptNm,
                        usrName: this.usrName,
                        usrId: this.usrId
                    }
                } else {
                    params = {
                        page: 0,
                        size: 100000,
                        visitDate: this.visitDate
                    }
                }
                this.$axios
                .get('/api/mng/entrance/v1/pass/nok-Mng/excel', {params: params, responseType: "blob"})
                .then((response) => {
                    console.log(response);
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type']}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '보호자관리_'+moment().format("YYYYMMDD_HHmmss")+'.xlsx');
                    document.body.appendChild(link);
                    link.click();

                    if(response.status == 200) {
                        let param = {
                          fileName: '보호자관리_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                          fileSize: response.data.size,
                          purpose: paramdata.purpose,
                          purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                        }
                        this.setDownloadHistory(param);
                    }
                })
            }
        }
    }
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
</style>
