<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">기본환경</a>
            </li>
            <li>
                <a href="javascript:void(0)">단말 온도 보정</a>
            </li>
        </div>
        <div class="wapper" style="min-height:700px">
            <div class="title">
                <h2>단말 온도 보정</h2>
            </div>
            <h3>
                <div class="select-box">
                    <select
                        v-model="placeId"
                        @change="selectGroup()"
                        style="width: 180px; text-align: center;">
                        <option value="" disabled="disabled">구역을 선택하세요.</option>
                        <option v-for="(item, index) of this.groupList" :key="index" :value="item.id">{{item.name}}</option>
                    </select>
                </div>
            </h3>
            <div class="title">
                <h3>전체 제어</h3>
            </div>

            <div class="table table_line">
                <table>
                    <tr>
                        <th>그룹</th>
                        <th width="10%">보정값</th>
                        <th width="10%">관리</th>
                    </tr>
                    <tr>
                        <td>{{this.placeName}}</td>
                        <td><input type="text" v-model="tempAll"/></td>
                        <td>
                            <button class="modify-btn" @click="modifyPlaceConfirm()">적용</button>
                        </td>
                    </tr>
                </table>
                <br/>
                <br/>
                <div class="title">
                    <h3>개별 제어</h3>

                </div>
                <table>
                    <tr>
                        <th>그룹</th>
                        <th>장치명</th>
                        <th>장치 아이디</th>
                        <th>장치 유형</th>
                        <th width="10%">
                            보정값</th>
                        <th width="10%">관리</th>
                    </tr>
                    <tr v-for="(item,index) of this.placeList" :key="index">
                        <td>{{item.placeName}}</td>
                        <td>{{item.deviceName}}</td>
                        <td>{{item.deviceId}}</td>
                        <td>{{item.deviceTypeName}}</td>
                        <td><input type="text" v-model="item.tempValue"/></td>
                        <td>
                            <button
                                class="modify-btn"
                                @click="modifyConfirm(item.deviceId, item.tempValue)">적용</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>

</template>
<script>
    import mixin from '../../mixin/mixin.js';
    export default {
        data: () => {
            return {groupList: [], tempAll: 0, placeId: '', placeList: [], placeName: null}
        },
        mounted: function () {
            this.getGroupList();
        },
        mixins: [mixin],
        created: function () {},

        methods: {
            modifyConfirm: function (deviceId, temp) {
                if (temp == null) {
                    this.openAlert("단말 온도를 설정해주세요.");
                    return;
                } else if (!/^\-?[0-9]\d{0,2}(\.\d*)?$/.test(temp)) {
                    this.openAlert("음수 또는 양수의 숫자로 설정해 주세요.");
                    return;
                } else {
                    let param = {
                        deviceId: deviceId,
                        temp: temp
                    }

                    this.openConfirm("보정값을 변경하시겠습니까?", this.modifyTemp, param);
                }

            },

            modifyPlaceConfirm: function () {
                console.log(this.tempAll);
                if (this.placeId == null || this.placeId == '') {
                    this.openAlert("구역을 선택해 주세요.");
                    return;
                }
                if (this.tempAll == null) {
                    this.openAlert("단말 온도를 설정해주세요.");
                    return;
                } else if (!/^\-?[0-9]\d{0,2}(\.\d*)?$/.test(this.tempAll)) {
                    this.openAlert("음수 또는 양수의 숫자로 설정해 주세요.");
                    return;
                } else {
                    this.openConfirm("보정값을 변경하시겠습니까?", this.modifyPlaceTemp);
                }

            },

            modifyTemp: function (param) {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                this
                    .$axios
                    .post('/api/mng/setting/v1/device/device-temp', {
                        deviceId: param.deviceId,
                        temp: param.temp
                    })
                    .then((response) => {
                        if (response.data.success) {
                            this.openAlert("온도 보정에 성공했습니다.");
                            this.getPlaceList(this.placeId);
                            this.btnInit();
                            this.btnLock = false;
                        }
                    })
                    .catch(() => {
                        this.openAlert("온도 보정을 적용하지 못했습니다.");
                        this.btnLock = false;
                    });

            },

            modifyPlaceTemp: function () {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                // 일괄 온도 보정
                this
                    .$axios
                    .post(' /api/mng/setting/v1/device/place-temp', {
                        placeId: this.placeId,
                        temp: this.tempAll
                    })
                    .then((response) => {
                        if (response.data.success) {
                            this.openAlert("온도 보정에 성공했습니다.");
                            this.getPlaceList(this.placeId);
                            this.btnInit();
                            this.btnLock = false;
                        }
                    })
                    .catch(() => {
                        this.openAlert("온도 보정을 적용하지 못했습니다.");
                        this.btnLock = false;
                    });

            },

            // 셀렉트 박스 리스트
            getGroupList: function () {
                this
                    .$axios
                    .get('/api/mng/setting/v1/device/device-group')
                    .then((response) => {
                        if (response.data.success) {
                            this.groupList = response.data.result;
                        }
                    })
                    .catch((err) => {
                        console.log("에러 메시지 :" + err);
                    });
            },
            // 클릭 이벤트
            selectGroup: function () {
                console.log(this.placeId);
                console.log(this.placeList.length);
                for (let group of this.groupList) {
                    console.log(group);
                    if (group.id === this.placeId) {
                        this.placeName = group.name;
                    }
                }
                this.getPlaceList(this.placeId);
            },

            //  리스트
            getPlaceList: function (placeId) {

                console.log(placeId);
                this
                    .$axios
                    .get(' /api/mng/setting/v1/device/devices', {
                        params: {
                            placeId: placeId
                        }
                    })
                    .then((response) => {

                        if (response.data.success) {
                            console.log(response.data);
                            this.placeList = response.data.result;
                        } else {
                            this.openAlert("장소 정보를 가져오는 도중 오류가 발생했습니다.");
                        }

                    })

            },
            btnInit: function () {
                this.tempSingle = '',
                this.tempAll = 0,
                this.deviceId = null
            }
        }
    }
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
    .title h3 {
        color: #868585;
        border-bottom: 1px solid #F0F1F6;
        margin-bottom: 10px;
    }
    .table .modify-btn {
        width: 80px;
        margin: auto;
        display: block;
    }
</style>