<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">방문신청</a>
            </li>
            <li>
                <a href="javascript:void(0)">집합행사관리</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>집합행사 등록</h2>
            </div>
            <div class="register">
                <dl>
                    <dt>문진유형</dt>
                    <dd>
                        <select v-model="masterId">
                            <option
                                v-for="(item,index) of this.masterTypeList.filter((item)=>{return item.inquiryType === 'GST'})"
                                :key="index"
                                :value="item.inquiryType">                
                                {{item.inquiryTypeName}}</option>
                        </select>
                    </dd>
                </dl>

                <dl>
                    <dt>행사명</dt>
                    <dd><input type="text" v-model="meetingName"/></dd>
                </dl>
                <dl>
                    <dt>행사기간</dt>
                    <dd>
                        <date-picker
                            :dateParam.sync="startDate"
                            @dateVal="startDateFunc"
                            style="width: 150px; height:40px;"></date-picker>
                        ~
                        <date-picker
                            :dateParam.sync="endDate"
                            @dateVal="endDateFunc"
                            style="width: 150px; height:40px;"></date-picker>
                    </dd>
                </dl>
                <dl>
                    <dt>장소</dt>
                    <dd><input type="text" v-model="meetingPlace"/></dd>
                </dl>
                <dl>
                    <dt>행사성격 <br>(참가자 관리) </dt>
                    <dd>
                        <input
                            type="radio"
                            name="check_radio"
                            id="check_kiosk"
                            value="Y"
                            v-model="openYn"
                            @click="checkOpenYn"/>
                        <label for="check_kiosk">
                            <span>공개</span>
                        </label>
                        <div class="radio_kiosk">
                            <li><input
                                type="text"
                                placeholder="키오스크에 노출될 버튼명(행사명)을 입력하세요."
                                :disabled="this.openYn=='N'"
                                v-model="btnName"/></li>
                            <li><input
                                type="text"
                                placeholder="초대코드 숫자 4자리를 입력하세요."
                                :disabled="this.openYn=='N'"
                                v-model="inviteCode"/></li>
                            <p class="txt_info txt_title">대규모 행사 또는 참가자가 불분명한 경우에 적합합니다.</p>
                            <p class="txt_info"> 집합행사 등록 후 관련 정보(행사명, 문진 URL, 초대코등 등)를 공지하세요</p>
                            <p class="txt_info">참가자는 초대코드 입력 후 문진을 이용할 수 있습니다.</p>
                        </div>
                        <input
                            type="radio"
                            name="check_radio"
                            id="check_mobile"
                            value="N"
                            @click="initSiteVal()"
                            v-model="openYn"/>
                        <label for="check_mobile">
                            <span>비공개</span>
                        </label>
                        <div class="radio_mobile" style="display: block;">
                            <p class="txt_info txt_title"> 비공개 그룹, 참가자의 신원이 확인된 경우에 적합합니다.</p>
                            <p class="txt_info">집합행사 등록 후 참석대상자를 등록해주세요.</p>
                            <p class="txt_info">대상자에게 문진 URL이 발송됩니다.</p>
                        </div>
                    </dd>
                </dl>
            </div>
            <div class="btn_wrap">
                <div class="btn_left">
                    <button class="btn_secondary" @click="cancel()">취 소</button>
                    <button class="btn_primary" @click="confirmSave()">저 장</button>
                </div>
            </div>
        </div>
    </div>

</template>
<script>

    import mixin from '../../mixin/mixin.js';
    import datePicker from '../../components/datePicker.vue';
    import moment from 'moment';
    export default {
        data: () => {
            return {
                masterId: 'GST',
                meetingName: null,
                meetingPlace: null,
                inviteCode: null,
                btnName: null,
                openYn: 'N',
                startDate: null,
                endDate: null,
                masterTypeList: []
            }
        },
        components: {
            'date-picker': datePicker
        },
        created: function () {
            this.getMasterType();
        },
        watch: {},
        mixins: [mixin],
        methods: {
            startDateFunc: function (date) {
                this.startDate = date;
            },
            endDateFunc: function (date) {
                this.endDate = date;
            },
            getMasterType: function () {
                this
                    .$axios
                    .get('/api/mng/common//v1/master-type')
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.success) {
                            this.masterTypeList = response.data.result;
                        } else {
                            this.openAlert("문진 유형을 불러오지 못하였습니다.");
                        }
                    })
                    .catch(() => {
                        this.openAlert("문진 유형을 불러오지 못하였습니다.");
                    })
                },
            confirmSave() {
                if (this.meetingName == null) {
                    this.openAlert("행사명은 필수 입니다.");
                    return;
                }
                if (this.meetingName.trim() == '') {
                    this.openAlert("행사명은 필수 입니다.");
                    return;
                }
                if (this.meetingName.length > 100) {
                    this.openAlert("행사명은 100자를 넘을 수 없습니다.");
                    return;
                }
                if (this.openYn == "Y") {
                    if (this.btnName == null) {
                        this.openAlert("버튼명은 필수 입니다.");
                        return;
                    }
                    if (this.btnName.trim() == '') {
                        this.openAlert("버튼명은 필수 입니다.");
                        return;
                    }
                    if (this.btnName.length > 100) {
                        this.openAlert("버튼명은 100자를 넘을 수 없습니다.");
                        return;
                    }
                    if (this.inviteCode == null) {
                        this.openAlert("초대코드는 필수 입니다.");
                        return;
                    }
                    if (this.inviteCode.trim() == '') {
                        this.openAlert("초대코드는 필수 입니다.");
                        return;
                    }
                    if(!/^[0-9]{4}$/.test(this.inviteCode)){
                        this.openAlert("초대코드는 숫자 4자리로 만들어야 합니다.");
                        return;
                    }
                    if (this.inviteCode.length > 4) {
                        this.openAlert("초대코드는 4자를 넘을 수 없습니다.");
                        return;
                    }
                }
                if (this.meetingPlace != null) {
                    if (this.meetingPlace.trim().length > 20) {
                        this.openAlert("장소는 20자를 넘을 수 없습니다.");
                        return;
                    }
                }

                if (this.startDate == null) {
                    this.openAlert("시작 날짜는 필수 입니다.");
                    return;
                }
                if (this.endDate != null) {
                    if (this.endDate.trim() != '') {
                        let enddate = new Date(this.endDate);
                        let startDate = new Date(this.startDate);
                        if (startDate > enddate) {
                            this.openAlert("종료날짜는 시작날짜보다 이전일 수 없습니다.");
                            return;
                        }
                    }
                }

                // 오늘 날짜와 선택한 날짜를 비교
                if(moment(moment().format("YYYY-MM-DD")).isAfter(this.startDate)) {
                    this.openAlert("행사기간은 오늘 이후 날짜로 지정하여야 합니다");
                    return;
                }

                this.openConfirm('집합행사를 저장하시겠습니까?', this.save);

            },
            save: function () {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                this
                    .$axios
                    .post('/api/mng/group-event/v1/group', {
                        masterId: this.masterId,
                        meetingName: this.meetingName,
                        meetingPlace: this.meetingPlace,
                        inviteCode: this.inviteCode,
                        btnName: this.btnName,
                        openYn: this.openYn,
                        startDate: this.startDate,
                        endDate: this.endDate
                    })
                    .then((response) => {

                        if (response.data.success) {
                            this.openAlertClosed("저장이 완료되었습니다.", this.cancel);
                        }
                        this.btnLock = false;

                    })
                    .catch(() => {
                        this.openAlert('저장중 오류가 발생했습니다.');
                        this.btnLock = false;
                    })
                },
            cancel: function () {
                this
                    .$router
                    .go(-1);
                    // .push({name: 'groupEvent'});
            },
            initSiteVal: function () {
                this.btnName = null;
                this.inviteCode = null;
            },
            generateInviteCode: function (n) {
                let str = ''
                for (let i = 0; i < n; i++) {
                    str += Math.floor(Math.random() * 10)
                }
                // 첫번째 자리가 0이면 1로 치환
                if(str.charAt(0) == '0') {
                    str = str.replace(/(^0+)/, "1");
                }
                return str
            }, checkOpenYn: function () {
                this.inviteCode = this.generateInviteCode(4);
            }

        }
    }
</script>
<style scoped>
.register .txt_info {
    line-height: 23px;
}

</style>