import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {router} from '@/router'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VModal from 'vue-js-modal';
import moment from "moment";
import i18n from './i18n'
import loading from './components/loading.vue';
import alertCallback from "@/components/alertWitheCallback";


const backendAxios = axios.create();
Vue.config.productionTip = false
Vue.prototype.$axios = backendAxios
Vue.use(Vuex);
Vue.use(VModal);

backendAxios.interceptors.request.use(
    function (config) {
        // Loading Spinner Modal
        Vue.prototype.$modal.show(
            loading,
            {
                alertMsg: "Loading",
            },
            {
                name: "axiosLoadingModal",
                width: "300px",
                height: "auto",
                adaptive: true,
                clickToClose: false,
                styles: {
                    background: "rgba(0, 0, 0, 0)",
                    "box-shadow": "none",
                },
            }
        );

        if (store.state.token === null) {
            //store에 토큰정보가 없으면 쿠키에서 가져온다.
            store.commit("setToken", {token: localStorage.token});
        }
        if (router.currentRoute.name !== 'index'
            && router.currentRoute.name !== 'login') {
            if (store.state.lastApicallTimestamp &&
                store.state.lastApicallTimestamp.add(10, "minutes") < moment()
            ) {
                console.log("session token Expired!");
                store.commit('setToken', {token: null});
                localStorage.removeItem('token');
            }
        }

        //헤더에 발급받은 토큰정보를 넣어서 요청
        config.headers.Authorization = "Bearer " + store.state.token;
        return config;
    },
    function (error) {
        // Loading Spinner Modal Hide
        Vue.prototype.$modal.hide("axiosLoadingModal");
        // Do something with request error
        return Promise.reject(error);
    }
);

backendAxios.interceptors.response.use(
    function (response) {
        // Loading Spinner Modal Hide
        Vue.prototype.$modal.hide("axiosLoadingModal");
        store.commit("setTimestamp");
        return response;
    },
    function (error) {
        // Loading Spinner Modal Hide
        Vue.prototype.$modal.hide("axiosLoadingModal");
        if (error.response.data) {
            let status = error.response.data.status;
            if (status === 401 || status === 403) {
                alert("세션이 만료되었습니다. 다시 로그인해주세요.");
                router.replace({name: 'login'})

                // Vue.prototype
                //     .$modal
                //     .show(alertCallback, {
                //         alertMsg: '세션이 만료되었습니다. 다시 로그인해주세요.',
                //         callback: () => {
                //             router.push({name: 'login'})
                //         },
                //         callbackParam: null
                //     }, {
                //         name: 'alertCallback',
                //         width: '540px',
                //         height: 'auto',
                //         adaptive: true,
                //         clickToClose: false
                //     });
                return new Promise(() => {
                });
            }
        }
        return Promise.reject(error);
    }
);

const store = new Vuex.Store({
    state: {
        masterId: '',
        detailId: '',
        masterInfo: '',
        detailList: [],
        token: null,
        lastApicallTimestamp: null,
    },
    mutations: {
        setMasterId(state, payload) {
            state.masterId = payload.masterId
        },
        setDetailId(state, payload) {
            state.detailId = payload.detailId
        },
        initState() {
            this.state.masterId = '',
                this.state.detailId = '',
                this.state.masterInfo = '',
                this.state.detailList = []
        },
        setMasterInfo(state, payload) {
            state.masterInfo = payload.masterInfo;
        },
        setDetailList(state, payload) {
            state.detailList = payload.detailList;
        },
        setToken(state, payload) {
            state.token = payload.token;
        },
        setTimestamp(state) {
            state.lastApicallTimestamp = moment();
        },
    }
});

new Vue({
    vuetify,
    store,
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')