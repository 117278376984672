<template>
    <div id="popup_road_staff" class="pop pop_md">
        <!-- 새 문진 등록 -->
        <div class="pop_head">
            <h2>소속부서 및 담당자 찾기</h2>
            <a class="btn_pop_close"  @click="close()">닫기</a>
        </div>
        <div class="pop_body">
            <div class="road_staff">
                <input type="text" v-model="keyword" placeholder="이름" style="width:50%;" @keyup.enter="searchUserName"/>
                <button class="btn_secondary" @click="searchUserName">검색</button>
                <div class="head">
                    <ul>조직도</ul>
                    <ul>구성원</ul>
                </div>
                <div class="body">
                    <ul class="org_tree" style="height:500px;">
                        <v-app id="tree">
                            <v-treeview
                                v-model="selection"
                                :items="items"
                                @update:active="onUpdate"
                                activatable="activatable"
                                item-key="id"></v-treeview>
                        </v-app>
                    </ul>
                    <ul style="height:500px">
                        <li v-for="(item, index) in this.userList" :key="index">
                            <input type="radio" name="staff_mem" :id="item.userId" :value="item.userId" v-model="user" @click="inputRadioClick(item.deptCd)"/>
                            <label :for="item.userId">
                                <span v-if="korNm == null">{{item.korNm + ' ' + item.jobposNm}}</span>
                                <span v-else>{{item.korNm + ' (' + item.deptNm+')'}}</span>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="pop_footer">
            <button class="btn_secondary" id="btn_reg_cancel" @click="close()">취 소</button>
            <button class="btn_primary" @click="sendSelection()">확인</button>
        </div>
    </div>
</template>
<script>
  import mixin from '../mixin/mixin.js';

    export default { 
        data: function () {
            return {items: [], selection: [], userList: [], user: '', keyword: '', deptname: '', korNm: null,deptCd:null}
        },
        mixins: [mixin],
        created: function () {
            this.getDeptLvl();
        },
        props:['getSearchUserInfo'],
        methods: {
            getDeptLvl: function () {
                this
                .$axios
                .get('/api/mng/common/v1/dept-lvl')
                .then((response) => {
                    if (response.data.success) {
                        this.items = response.data.result;
                    } else {
                        this.openAlert("부서정보 리스트를 가져오지 못했습니다.");
                    }

                })
                .catch(() => {
                    this.openAlert("부서정보 리스트를 가져오지 못했습니다.");
                })
            },
            getUsers: function (deptCode) {
                this
                .$axios
                .get('/api/mng/common/v1/user-list', {
                    params: {
                        deptCd: deptCode
                    }
                })
                .then((response) => {
                    if(response.data.success) {
                         this.userList = response.data.result;
                    }else {
                         this.openAlert("사용자 정보 리스트를 가져오지 못했습니다.");
                    }

                }).catch(() => {
                    this.openAlert("사용자 정보 리스트를 가져오지 못했습니다.");
                });
            },
            onUpdate(selection) {
                this.korNm = null;
                this.keyword = '';
                if (selection.length > 0) {
                    this.getUsers(selection[0]);
                    this.selection = selection;
                }
            },
            close: function () {
                this.$emit('close');
            },
            sendSelection: function( ) {
                if(( this.user != null && this.user != "" ) && ( this.deptCd != null && this.deptCd != "" )){
                this.getSearchUserInfo(this.user, this.deptCd);
                this.$emit('close');
                }else{
                    this.openAlert("구성원을 선택해주세요.")
                }

            },
            searchUserName: function(e) {
                this.korNm = this.keyword;
                this
                .$axios
                .get('/api/mng/common/v1/search-username', {
                    params: {
                        korNm: this.keyword
                    }
                })
                .then((response) => {
                    if(response.data.success) {
                        this.userList = response.data.result;
                        this.selection = [];
                    }else {
                        this.userList = [];
                    }

                }).catch(() => {
                    this.openAlert("사용자 정보 리스트를 가져오지 못했습니다.");
                });
            },
            inputRadioClick: function(deptCd) {
                this.deptCd = deptCd;
            }
        }
    }
</script>
<style scoped="scoped">
    .pop {
        display: block;
        position: unset;
        width: 640px;
    }

    #tree >>> .v-treeview-node__root {
        display: flex;
        align-items: center;
        min-height: 25px;
        padding-left: 8px;
        padding-right: 8px;
        position: relative;
    }
</style>
