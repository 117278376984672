<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">출입자</a>
            </li>
            <li>
                <a href="javascript:void(0)">협력업체 관리</a>
            </li>
            <li>
                <a href="javascript:void(0)">협력업체 사원 신규 등록</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>등록직원 신규 등록</h2>
            </div>
            <div class="register">
                <dl>
                    <dt>이름</dt>
                    <dd><input type="text" v-model="empNm"/></dd>
                </dl>
                <dl>
                    <dt>휴대전화번호</dt>
                    <dd><input type="text" v-model="empPhone"/></dd>
                </dl>
            </div>
            <div class="btn_wrap">
                <div class="btn_left">
                    <button class="btn_secondary" @click="goList()">취 소</button>
                    <button class="btn_primary" @click="confirmSave()">저 장</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import mixin from '../../mixin/mixin.js';
    export default {
        data: () => {
            return {empNm: null, empPhone: null, partnerCode: null, pname: null}
        },
        created: function () {
            this.partnerCode = this.$route.query.pid
            this.pname = this.$route.query.pname
        },
        watch: {
            empPhone: function (newVal) {
                let convert = newVal.replaceAll("-", "");

                if (convert.length > 11) {
                    convert = convert.substring(0, 11);
                }

                convert = convert.replace(
                    /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/,
                    '$1-$2-$3'
                );

                this.empPhone = convert
            }
        },
        mixins: [mixin],
        methods: {
            confirmSave: async function () {
                let checkList = [
                    {
                        id: this.empNm,
                        name: '이름'
                    }, {
                        id: this.empPhone,
                        name: '휴대번호'
                    }
                ]

                let empPhone = this.empPhone;
                empPhone = empPhone.replaceAll('-','');

                if (!this.nullChecker(checkList)) {
                    return;
                }
                if (this.empNm.trim().length > 20) {
                    this.openAlert('이름은 20자를 넘을 수 없습니다.');
                    return false;
                }
                if (empPhone.trim().length > 11) {
                    this.openAlert('휴대 전화번호는 11자를 넘을 수 없습니다.');
                    return false;
                }
                let duplicate = await this.$axios.get("/api/mng/partner/emp/v1/list/add-check", {
                  params: {
                    partnerPid: this.partnerCode,
                    empPhone: empPhone
                  }
                })

                if(duplicate.data.result.duplicate) {
                    let maxCnt = 3;
                    let duplicateList =  duplicate.data.result.duplicateList;
                    if(duplicateList.length < maxCnt) {
                      maxCnt = duplicate.data.result.duplicateList.length
                    }
                    let msg = "중복된 휴대 전화번호가 있습니다.";
                    for (let i =0; i < maxCnt; i++) {
                      msg += "</br> 업체명 : " +  duplicateList[i].groupName + ", 직원명 : " + duplicateList[i].empName;
                    }
                    this.openAlert(msg);
                    return
                }
                if (this.btnLock) {
                  return;
                } else {
                  this.btnLock = true;
                }

                this
                    .$axios
                    .post('/api/mng/partner/emp/v1/list', {
                        partnerCode: this.partnerCode,
                        empNm: this.empNm,
                        empPhone: empPhone
                    })
                    .then((response) => {
                        console.log(response.data);

                        if (response.data.success) {

                            this.openAlertClosed('저장이 완료되었습니다.', this.goList);

                        } else {
                            this.openAlert("저장하는 도중 오류가 발생하였습니다.");
                        }
                        this.btnLock = false;
                    })
                    .catch(() => {
                        this.openAlert("저장하는 도중 오류가 발생하였습니다.");
                        this.btnLock = false;
                    });

            },
            goList: function () {
                this
                .$router
                .go(-1)
                // .push({
                //     name: 'partnerEmpList',
                //     query: {
                //         pid: this.partnerCode,
                //         pname: this.pname
                //     }
                // });
            },

        },

    }
</script>
