<template>
  <div class="contents">
    <div class="loc">
      <li>
        <a
          @click="
            () => {
              this.$router.push({
                name: 'inq-list',
              });
            }
          "
          >문진 관리</a
        >
      </li>
      <li>
        <a>문진 설정</a>
      </li>
      <li>
        <a href="javascript:void(0)">문진 수정</a>
      </li>
    </div>
    <div class="wapper">
      <div class="title">
        <h2>다국어 관리</h2>
      </div>
      <div class="version_info">
        <dl>
          <dt>언어선택</dt>
          <dd>
            <select v-model="selectedLang" @change="setLang()">
              <option disabled="disabled" selected="selected" value="">
                언어 선택
              </option>
              <option
                v-for="(value, name) in languageList"
                :key="name"
                :value="name"
              >
                {{ value }}
              </option>
            </select>
            <span>입력을 완료한 언어만 문진에 반영 됩니다.</span>
          </dd>
        </dl>
      </div>
      <div class="question_wrap">
        <h3>다국어 입력</h3>
        <div class="q_lang_group">
          <div class="q_card" v-for="(item, index) in this.items" :key="index">
            <dl>
              <dt>타입</dt>
              <dd v-if="item.categoryType == 'N'">일반문항(기본)</dd>
              <dd v-if="item.categoryType == 'I'">일반문항(표/이미지)</dd>
              <dd v-if="item.categoryType == 'P'">기타(종이문진 전용)</dd>
              <dd v-if="item.categoryType == 'M'">객관식형</dd>
              <dd v-if="item.categoryType == 'T'">주관식형</dd>
              <dd v-if="item.categoryType == 'H'">방문력</dd>
            </dl>
            <dl  v-if="item.questionContents != null && item.questionContents != ''">
              <dt>구분</dt>
              <dd>
                {{ item.questionContents }}
                <input
                  type="text"
                  v-model="item.langQuestion"
                  @click="valid(item.langCode)"
                  :readonly="item.langCode == null"
                  placeholder="해당언어 입력"
                />
              </dd>
            </dl>
            <dl v-if="item.categoryDesc != null && item.categoryDesc != ''">
              <dt>설명</dt>
              <dd>
                <label v-html="convertCategoryDesc(item.categoryDesc)"></label>
                <br/>
                <textarea style="width: 50%; border: solid 1px #AAACB4; height: 36px; padding: 0 8px; padding-top: 6px;"
                  type="text"
                  v-model="item.langCategoryDesc"
                  @click="valid(item.langCode)"
                  :readonly="item.langCode == null"
                  placeholder="해당언어 입력"
                />
                ( * 다국어 조회가 안 될 시 직접 입력 후 저장해 주세요.)
              </dd>
            </dl>
            <dl v-if="item.inquiryList.length > 0">
              <dt>항목</dt>
              <dd>
                <table>
                  <tr>
                    <th>항목</th>
                    <th>번역</th>
                  </tr>
                  <tr
                    v-for="(subItem, subIndex) in item.inquiryList"
                    :key="subIndex"
                  >
                    <td style="width: 30%">{{ subItem.inquiryContents }}</td>
                    <td>
                      <input
                        type="text"
                        v-model="subItem.langContents"
                        @click="valid(subItem.langCode)"
                        :readonly="subItem.langCode == null"
                        placeholder="해당언어 입력"
                      />
                    </td>
                  </tr>
                </table>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div class="btn_wrap">
        <div class="btn_right">
          <button class="btn_secondary" @click="goBack">취 소</button>
          <button class="btn_primary" @click="saveConfirm()">저 장</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from "../../mixin/mixin.js";

export default {
  data: () => {
    return {
      items: [],
      langList: [],
      selectedLang: "",
      detailId: null,
      interList: [],
      languageList: {
        en: "영어",
        ja: "일본어",
        zh: "중국어",
        ru: "러시아어",
        vi: "베트남어",
        ar: "아랍어",
        mn: "몽골어",
      },
    };
  },
  mixins: [mixin],
  mounted: function () {
    this.detailId = this.getInqInfo(this.$route.query.did);
  },
  computed: {
    isEmptyInputBox() {
      for (const item of this.items) {
        // langQuestion 비어있는지 체크
        if ((item.questionContents != null  && item.questionContents != "" ) && (!item.langQuestion || item.langQuestion === "")) return true;
        // langCategoryDesc 비어있는지 체크 (categoryDesc 이 있는 경우만)
        if (
          item.categoryDesc &&
          item.categoryDesc !== "" &&
          (!item.langCategoryDesc || item.langCategoryDesc === "")
        )
          return true;
        // langContents 비어있는지 체크
        for (const subItem of item.inquiryList) {
          if (!subItem.langContents || subItem.langContents === "") return true;
        }
      }
      return false;
    },
  },
  methods: {
    getInqInfo: async function (detailId) {
      try {
        const response = await this.$axios.get("/api/mng/inter/v1/inquiry", {
          params: { detailId },
        });
        if (!response.data.success) throw new Error("error");
        this.items = response.data.result.categoryList.filter(
          (el) => el.categoryType !== "E" );
      } catch (error) {
        this.openAlert("문진표 정보를 조회하는 도중 오류가 발생하였습니다.");
      }
    },
    setLang: function () {
      this.$axios
        .get("/api/mng/inter/v1/inqlnag", {
          params: {
            langCode: this.selectedLang,
          },
        })
        .then((response) => {
          let interList = response.data.result;
          //lang 초기화
          for (let cat of this.items) {
            cat.langCode = null;
            cat.langCategoryDesc = null;
            cat.langQuestion = null;
            for (let item of cat.inquiryList) {
              item.langCode = null;
              item.langContents = null;
            }
          }

          for (let cat of this.items) {
            cat.langCode = this.selectedLang;

            for (let inter of interList) {
              if (cat.categoryDescId == inter.refId) {
                cat.langCategoryDesc = inter.langContents;
              }
              if (cat.questionId == inter.refId) {
                cat.langQuestion = inter.langContents;
              }
            }

            for (let item of cat.inquiryList) {
              item.langCode = this.selectedLang;

              for (let inter of interList) {
                if (item.inquiryId == inter.refId) {
                  item.langContents = inter.langContents;
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.openAlert("해당 언어를 가져오는데 실패했습니다.");
        });
    },
    valid: function (langCode) {
      if (langCode === null || langCode === "") {
        this.openAlert("언어를 먼저 선택해주세요.");
      }
    },
    saveConfirm: function () {
      if (this.selectedLang === "") {
        this.openAlert("언어를 선택해주세요.");
      } else {
        this.openConfirm(
          "작성하지 않은 다국어 항목은 저장되지 않습니다. 최종 저장 하시겠습니까?",
          this.save
        );
      }
    },
    save: function () {
      if (this.btnLock) {
        return;
      } else {
        this.btnLock = true;
      }

      this.$axios
        .post("/api/mng/inter/v1/inquiry", { categoryList: this.items })
        .then((response) => {
          if (response.data.success) {
            this.openAlert("저장이 완료되었습니다.");
            this.setLang();
            this.$router.push({
              name: "inq-detail",
              query: {
                masterId: this.$route.query.mid,
              },
            });
          } else {
            this.openAlert("저장중 오류가 발생하였습니다.");
          }
          this.btnLock = false;
        })
        .catch((err) => {
          console.log(err);
          this.openAlert("저장중 오류가 발생하였습니다.");
          this.btnLock = false;
        });
    },
    goBack: function () {
      this.$router.back();
    },
    convertCategoryDesc: function (value) {
       if(null !== value) {
           return value.replaceAll("\n","<br/>");
       }
    }
  },
};
</script>

<style scoped="scoped">
.autoSearch {
  position: absolute;
  display: none;
  z-index: 1;
  background-color: #ffffff;
  padding: 10px;
  border: #c7c7c7 1px solid;
  max-height: 500px;
  overflow-y: auto;
  top: 153px;
  left: 80px;
  width: calc(50% - 55px);
}
.autoSearch ul {
  list-style: none;
}

.autoSearch ul li {
  margin-bottom: 2px;
  padding: 2px 0;
}
.autoSearch ul li:hover {
  background-color: #e2e2e2;
}

.autoSearch ul li.active {
  background-color: #e2e2e2;
}

.autoSearch ul .searchAction {
  float: right;
}
.autoSearch ul .searchAction::after {
  clear: both;
}

.autoSearch ul .searchAction a {
  text-decoration: none;
}

.searchHide {
  display: none;
}

.autoSearch2 {
  top: 105px;
  z-index: 2;
}

.q_card {
  overflow: visible;
}

.input_cal {
  height: 36px;
  padding: 0 8px;
  line-height: 36px;
  border: solid 1px #e3ebf6;
  border-radius: 3px;
  box-shadow: none;
  -webkit-appearance: none;
  font-size: 14px;
}

.contents {
  min-height: 800px;
}

.pop {
  display: inline-block;
}

.sort_btn .btn_del {
  background: url("/img/ico_del.2a36f96e.svg") no-repeat 3px 0;
}
.sort_btn .btn_del {
  padding: 3px 10px 2px 25px;
  font-size: 12px;
  border: 1px solid #a0a3b9;
  border-radius: 3px;
}
textarea::placeholder {
  color: #BCBDC1;
}
</style>
