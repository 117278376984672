<template>
    <div id="popup_reg" class="pop pop_md">

        <div class="pop_head">
            <h2>출입증 재인쇄 허용</h2>
            <a class="btn_pop_close" href="javascript:void(0)" @click="close">닫기</a>
        </div>
        <div class="pop_body">
                    <dd>
                        <input
                            type="text"
                            v-model="IdNo"
                            placeholder="환자번호 또는 보호자 전화번호"
                            readonly="readonly"
                            style="width:60%;"/>
                    </dd>
        </div>
        <div class="pop_footer">
            <button class="btn_secondary" @click="cancelBtn">아니오</button>
            <button class="btn_secondary" @click="okBtn">네</button>
        </div>
    </div>
</template>
<script>
    
    export default {
        data: () => {
            return {
                IdNo: null,
            }
        },
        props:['setReprintYn', 'IdNo'],
        watch: {
            IdNo: function (newVal) {
                this.IdNo = newVal
                    .replace(/[^0-9]/g, '')
                    .substring(0, 11);
            },
        },
        methods: {
            cancelBtn() {
                this.$emit('close');
            },
            okBtn() {
                this.setReprintYn(this.IdNo);
                this.$emit('close');
            },
            close() {
                this.$emit('close');
            },
        }
    }

</script>
<style scoped="scoped">
    .pop {
        display: inline-block;
        position: unset;
    }
</style>