<template>
  <div class="reg_loader">
    <!-- <div id="loader" ref="loader"></div> -->

    <div class="pop_bg">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div v-if="alertMsg" id="loader_txt" ref="loader_txt" class="loader_txt">
      {{ alertMsg }}
    </div>
  </div>
</template>
<script>
export default {
  props: ["alertMsg"],
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
  },
};
</script>

<style scoped="scoped">
.pop {
  display: inline-block;
  position: unset;
}
.loader_txt {
  padding: 5px 30px;
}
</style>

<style scoped>
.loader_txt {
  text-shadow: 0.5px 0.5px 0.5px gray;
}
.pop_bg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 50%;
  /* top: 50%; */
  z-index: 1;
  margin: -50px 0 0 -50px;
  width: 100px;
  height: 100px;
}

.pop_bg div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 80px;
  height: 80px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  /*! autoprefixer: off */
  -webkit-animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent;
}

.pop_bg div:first-child {
  /*! autoprefixer: off */
  -webkit-animation-delay: -0.45s;
  animation-delay: -0.45s;
}

.pop_bg div:nth-child(2) {
  /*! autoprefixer: off */
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.pop_bg div:nth-child(3) {
  /*! autoprefixer: off */
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
}
/*! autoprefixer: off */
@-webkit-keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
@keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
</style>
