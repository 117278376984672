<template>
    <div id="wrapper">
        <div id="contents">
            <!-- 로그인 -->
            <section id="loginArea">
                <h1 class="logo_login">아산</h1>
                <h2>비밀번호 변경 및 초기화</h2>

                <li>
                    <label>사번</label><input
                        v-model="username"
                        type="text"
                        maxlength="15"
                        placeholder="변경 혹은 초기화할 사번 입력"
                        required="required"/></li>
                <!-- <li> <label>인증번호</label><input type="text" maxlength="15"
                placeholder="숫자만입력" required="required"/> <button class="btn_primary
                btn_sm">인증하기</button> </li> -->
                <li v-if="sendbtn">
                    <button style="margin:0" class="btn_primary" @click="sendAuthNo()">아산웍스로 인증번호 발송</button>
                </li>
                <li v-if="send">
                    <label>인증번호</label><input
                        v-model="authNo"
                        type="text"
                        maxlength="15"
                        placeholder="숫자만입력"
                        required="required"/>
                    <div class="time">{{rTime}}</div>
                    <button class="btn_primary btn_sm" @click="sendAuthNoCheck()">인증확인</button>
                    <p class="txt_error" v-if="timeOut">입력시간 초과</p>
                </li>
                <li v-if="setPw">
                    <label>새로운 패스워드</label><input
                        v-model="pw"
                        type="password"
                        maxlength="30"
                        placeholder="Password"
                        required="required"
                        @keydown.enter.down="enterFocus"/>                       
                </li>
                <li v-if="setPw">
                    <label>패스워드 확인</label><input
                        v-model="pwConfirm"
                        type="password"
                        maxlength="30"
                        placeholder="Password"
                        required="required"
                        ref="pwConfirm"
                        @keydown.enter="renewPw"/>
                </li>
                <li v-if="setPw">
                    <button style="margin:0" class="btn_primary" @click="renewPw">비밀번호 변경</button>
                </li>
                <li>
                    <button
                        class="btn_secondary"
                        type="submit"
                        @click="$router.push({name:'login'})">닫기</button>
                </li>
            </section>
            <!-- #loginArea -->
            <!-- //로그인 -->
        </div>
    </div>
</template>
<script>
    import mixin from '../../mixin/mixin.js';
    import {mapMutations} from 'vuex';;

    export default {
        data: () => {
            return {
                username: null,
                authNo: null,
                sendbtn: true,
                timeOut: false,
                send: false,
                rTime: '00:00',
                interval: '',
                setPw: false,
                pw: null,
                pwConfirm: null
            }
        },
        mixins: [mixin],
        created: function () {},
        destroyed: function () {
            clearInterval(this.interval);
        },
        watch: {
            username: function (newVal) {
                let id = newVal;
                id = id.toUpperCase();
                if (id.length > 7) {
                    id = id.substring(0, 7);
                }
                id = id.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '');
                this.username = id;
            },
            authNo: function (newVal) {
                let auth = newVal;
                auth = auth.replace(/[^0-9]/g, '');
                if (auth.length > 4) {
                    auth = auth.substring(0, 4);
                }
                this.authNo = auth;

            }
        },
        methods: {
          ...mapMutations(['setTimestamp']),
            sendAuthNo: function () {

                if (this.username == null) {
                    this.openAlert("사번을 입력하여 주세요.");
                    return;
                }
                if (this.username.length > 10) {
                    this.openAlert("입력자리수를 초과하였습니다.");
                    return;
                }
                if (this.username.trim() == '') {
                    this.openAlert("사번을 입력하여 주세요.");
                    return;
                }
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                this.username = this
                    .username
                    .trim()
                    .toUpperCase();

                this
                    .$axios
                    .post('/api/v1/renew-no', {"username": this.username})
                    .then((response) => {
                        if (response.data.success) {
                            this.openAlert('아산웍스으로 인증번호가 발송되었습니다. 인증번호를 입력하여 주세요.');
                            this.send = true;
                            this.sendbtn = false;
                            this.countDown();
                        } else {
                            this.openAlertClosed('인증번호 요청에 실패하였습니다. 관리자에게 문의하세요.');
                        }
                        this.btnLock = false;
                    })
                    .catch(() => {
                        this.openAlert('인증번호 요청에 실패하였습니다. 관리자에게 문의하세요.');
                        this.btnLock = false;
                    });
            },
            sendAuthNoCheck: function () {

                if (this.username == null) {
                    this.openAlert("사번을 입력하여 주세요.");
                    return;
                }
                if (this.username.length > 10) {
                    this.openAlert("입력자리수를 초과하였습니다.");
                    return;
                }
                if (this.username.trim() == '') {
                    this.openAlert("사번을 입력하여 주세요.");
                    return;
                }

                if (this.authNo == null) {
                    this.openAlert("인증번호를 입력하여 주세요.");
                    return;
                }
                if (this.authNo.length > 4) {
                    this.openAlert("인증번호 4자리를 초과하였습니다.");
                    return;
                }
                if (this.authNo.trim() == '') {
                    this.openAlert("사번을 입력하여 주세요.");
                    return;
                }

                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                    //인증번호 확인 유효시간을 3분에서 30분으로 변경 요청(네이버웍스 늦게 도달 하는 이슈로 이하여 고영준 과장으로부터 변경 요청 접수 받음 2023.04.17)
                    this.setTimestamp();
                }

                this.username = this
                    .username
                    .trim()
                    .toUpperCase();
                this.authNo = this
                    .authNo
                    .trim();
                this
                    .$axios
                    .post('/api/v1/renew-no-check', {
                        username: this.username,
                        renewNo: this.authNo
                    })
                    .then((response) => {
                        if (response.data.success) {
                            this.send = false;
                            this.setPw = true;
                            clearInterval(this.interval);
                        } else {
                            this.openAlert('인증번호 요청 검증에 실패했습니다. 다시 시도하세요.');
                        }
                        this.btnLock = false;
                    })
                    .catch(() => {
                        this.openAlert('인증번호 요청 검증에 실패했습니다. 다시 시도하세요.');
                        this.btnLock = false;
                    });
            },
            renewPw: function () {

                if (this.username == null) {
                    this.openAlert("사번을 입력하여 주세요.");
                    return;
                }
                if (this.username.length > 10) {
                    this.openAlert("입력자리수를 초과하였습니다.");
                    return;
                }
                if (this.username.trim() == '') {
                    this.openAlert("사번을 입력하여 주세요.");
                    return;
                }

                if (this.authNo == null) {
                    this.openAlert("인증번호를 입력하여 주세요.");
                    return;
                }
                if (this.authNo.length > 4) {
                    this.openAlert("인증번호 4자리를 초과하였습니다.");
                    return;
                }
                if (this.authNo.trim() == '') {
                    this.openAlert("사번을 입력하여 주세요.");
                    return;
                }

                if (this.pw == null) {
                    this.openAlert("비밀번호를 입력하여 주세요.");
                    return;
                }
                if (this.pw.trim() == '') {
                    this.openAlert("비밀번호를 입력하여 주세요.");
                    return;
                }
                // if (this.pw.trim().length > 20) {
                //     this.openAlert("20자 이상 비밀번호를 설정할 수 없습니다.");
                //     return;
                // }
                if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@!%*?&#^])[A-Za-z\d$@!%*?&#^]{8,12}/.test(this.pw)) {
                     this.openAlert("비밀번호는 영문 대소문자, 특수문자, 숫자를 모두 포함한 8자리 이상 12자리 이하로 설정해야 합니다.");
                    return;
                }
                if (this.pw != this.pwConfirm) {
                    this.openAlert("비밀번호확인이 일치하지 않습니다.");
                    return;
                }

                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                this.username = this
                    .username
                    .trim()
                    .toUpperCase();
                this.authNo = this
                    .authNo
                    .trim();

                this.pw = this
                    .pw
                    .trim();
                this.pwConfirm = this
                    .pwConfirm
                    .trim();

                this
                    .$axios
                    .post('/api/v1/renew', {
                        username: this.username,
                        password: this.pw,
                        renewNo: this.authNo
                    })
                    .then((response) => {

                        this.btnLock = false;
                        if (response.data.success) {
                            this.openAlertClosed("비밀번호가 변경되었습니다.", () => {
                                this
                                    .$router
                                    .push({name: 'login'})
                            })
                        } else {
                            if (response.data.resultCd == "102") {
                                this.openAlert(response.data.resultMsg);

                            } else {
                                this.openAlert("비밀번호 초기화에 실패했습니다. 다시 시도하세요.");
                                this.sendbtn = true;
                                this.send = false;
                                this.setPw = false;

                            }

                        }

                    })
                    .catch(() => {
                        this.openAlert('비밀번호 초기화에 실패했습니다. 다시 시도하세요.');
                        this.btnLock = false;
                    });

            },
            countDown: function () {
                let count = 1800;
                this.interval = setInterval(() => {
                    count--;
                    if (count > 0) {
                        let min = Math.floor(count / 60);
                        let sec = count % 60;
                        let style = min
                            .toString()
                            .padStart(2, "0") + ":" + sec
                            .toString()
                            .padStart(2, "0");
                        console.log(style);
                        this.rTime = style;
                    } else {
                        clearInterval(this.interval);
                        this
                            .$router
                            .push({name: 'login'})
                    }
                }, 1000);
            },
            enterFocus: function(){              
                this.$refs.pwConfirm.focus();               
                
            }
        }
    }
</script>