<template>
  <div class="contents">
    <div class="loc">
      <li>
        <a
          @click="
            () => {
              this.$router.push({
                name: 'inq-list',
              });
            }
          "
          >문진 관리</a
        >
      </li>
      <li>
        <a>문진 설정</a>
      </li>
      <li>
        <a href="javascript:void(0)">새 문진 등록</a>
      </li>
    </div>
    <div class="wapper">
      <div class="title">
        <h2>수식관리</h2>
      </div>
      <div class="version_info">
        <dl>
          <dt>버전</dt>
          <dd>v{{ this.version }}</dd>
        </dl>
        <dl>
          <dt>적용일자</dt>
          <dd>
            {{ this.convertDate(this.startDate) }}
          </dd>
        </dl>
        <dl>
          <dt>종료일자</dt>
          <dd>
            {{ this.convertDate(this.endDate) }}
          </dd>
        </dl>
      </div>

      <div class="question_wrap susik">
        <h3>수식관리</h3>
        <!-- 수식관리 -->
        <div
          class="q_card"
          v-for="(item, index) in this.susikList"
          :key="index"
        >
          <div class="sort_btn">
            <button class="btn_up" @click="gubunUp(index)">위로</button>
            <button class="btn_down" @click="gubunDown(index)">아래로</button>
            <button class="btn_del" @click="removeSusik(index)">삭 제</button>
          </div>
          <dl>
            <dt>수식 순위</dt>
            <dd>
              <h2>{{ "# " + (index * 1 + 1) }}</h2>
            </dd>
          </dl>
          <dl>
            <dt>수식 이름</dt>
            <dd><input type="text" v-model="item.susikName" /></dd>
          </dl>
          <dl>
            <dt>방문목적</dt>
            <dd>
              <select v-model="item.visitPurpose">
                <option value="" disabled>방문목적을 선택하세요.</option>
                <option value="A">전체</option>
                <option value="I">입원(OSC/입원지원센터/임신등록 포함)</option>
                <option value="E">검사(검체 포함)</option>
                <option value="O">외래</option>
                <option value="H">건진</option>
              </select>
            </dd>
          </dl>
          <dl>
            <dt>조건</dt>
            <dd>
              <table>
                <colgroup>
                  <col width="40%" />
                  <col width="60%" />
                </colgroup>
                <tr>
                  <th>카테고리</th>
                  <th>항목</th>
                </tr>
                <tr v-for="(cat, catIndex) in items" :key="catIndex">
                  <template v-if="cat.categoryType !== 'H'">
                    <td>
                      <input
                        type="checkbox"
                        :id="cat.categoryId + index"
                        v-model="item.targetSusik[catIndex].checked"
                      />
                      <label :for="cat.categoryId + index">
                        <span style="vertical-align: -webkit-baseline-middle">
                          {{ cat.questionContents }}
                        </span>
                      </label>
                    </td>
                    <td>
                      <table style="margin: 0; width: 100%">
                        <tr
                          v-for="(inq, inqIndex) in cat.inquiryList"
                          :key="inqIndex"
                        >
                          <td>
                            <input
                              type="checkbox"
                              :id="inq.inquiryMappingId + index"
                              v-model="
                                item.targetSusik[catIndex].items[inqIndex]
                                  .checked
                              "
                            />
                            <label :for="inq.inquiryMappingId + index">
                              <span
                                style="vertical-align: -webkit-baseline-middle"
                              >
                                {{ inq.inquiryContents }}
                              </span>
                            </label>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </template>
                  <template v-if="cat.categoryType === 'H'">
                    <td> <input
                        type="checkbox"
                        :id="cat.categoryId + index"
                        v-model="item.targetSusik[catIndex].checked"
                      />
                      <label :for="cat.categoryId + index">
                        <span style="vertical-align: -webkit-baseline-middle">
                          방문력
                        </span>
                      </label></td>
                    <td>
                      <table style="margin: 0; width: 100%">
                        <tr>
                          <td>
                            <input
                              type="checkbox"
                              :id="'Y' + index"
                              v-model="
                                item.targetSusik[catIndex].items[0].checked
                              "
                            />
                            <label :for="'Y' + index">
                              <span
                                style="vertical-align: -webkit-baseline-middle"
                              >
                                방문력 해당있음
                              </span>
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              type="checkbox"
                              :id="'N' + index"
                              v-model="
                                item.targetSusik[catIndex].items[1].checked
                              "
                            />
                            <label :for="'N' + index">
                              <span
                                style="vertical-align: -webkit-baseline-middle"
                              >
                                방문력 해당없음
                              </span>
                            </label>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </template>
                </tr>
              </table>
            </dd>
          </dl>
        </div>
        <button class="btn_secondary" @click="addSusik()">수식관리 추가</button>
      </div>
      <div class="btn_wrap">
        <div class="btn_right">
          <button class="btn_secondary" @click="goBack">취 소</button>
          <button class="btn_primary" @click="saveConfirm()">저 장</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from "../../mixin/mixin.js";
export default {
  data: () => {
    return {
      detailId: null, //문진상세 버전
      detailInfo: null, //문진 상세 정보.
      version: null, //문진상세 버전
      startDate: null, //문진상세 적용일
      endDate: null, //문진상세 종료일
      items: [], //문진 항목
      susikList: [], //수식 리스트
    };
  },
  mixins: [mixin],
  created: function () {
    this.detailId = this.$route.query.did;
    this.getInquiry(this.detailId);
  },
  methods: {
    getInquiry: function (detailId) {
      //해당 문진상세에 등록된 문진 항목을 가져온다.
      this.$axios
        .get("/api/mng/inquiry/v1/inquiry", {
          params: {
            detailId: detailId,
          },
        })
        .then((response) => {
          if (response.data.success) {
            this.detailInfo = response.data.result;
            this.items = this.detailInfo.categoryList.filter(
              (el) =>
                el.categoryType == "M" ||
                el.categoryType == "I" ||
                el.categoryType == "N" ||
                el.categoryType == "H"
            );
            this.version = this.detailInfo.detailVer;
            let startDate = new Date(this.detailInfo.publishStartDate);
            startDate.setTime(startDate.getTime() + 9);
            this.startDate = startDate;
            if (this.detailInfo.publishEndDate) {
              let endDate = new Date(this.detailInfo.publishEndDate);
              endDate.setTime(startDate.getTime() + 9);
              this.endDate = endDate;
            }
            this.getSusik();
          } else {
            this.openAlert(
              "문진상세 항목을 불러오는 도중 오류가 발생했습니다."
            );
          }
          console.log(response.data);
        })
        .catch(() => {
          this.openAlert("문진상세 항목을 불러오는 도중 오류가 발생했스니다.");
        });
    },
    getSusik: function () {
      //기존의 저장되어있는 수식을 가져온다.
      this.$axios
        .get("/api/mng/inquiry/v1/susik", {
          params: {
            detailId: this.detailId,
          },
        })
        .then((response) => {
          if (!response.data.success) {
            this.openAlert("수식 정보를 가져오는데 실패하였습니다.");
          }
          console.log(response.data);
          if (response.data.result.length > 0) {
            console.log(response.data.result);
            this.susikList = response.data.result;
          }
        })
        .catch(() => {
          this.opneAlert("수식 정보를 가져오는데 실패하였습니다.");
        });
    },
    addSusik: function () {
      //수식관리 추가 버튼을 눌렀을 때 동작
      let susikList = this.susikList;
      let obj = {
        visitPurpose: "",
        targetSusik: [],
        susikId: null,
        susikName: null,
      };
      for (let cat of this.items) {
        if ("H" === cat.categoryType) {
          let catObj = {
            categoryId: "H",
            items: [],
            checked: false,
          };
          let inqObjY = {
            inquiryMappingId: "Y",
            checked: false,
          };
          catObj.items.push(inqObjY);
          let inqObjN = {
            inquiryMappingId: "N",
            checked: false,
          };
          catObj.items.push(inqObjN);
          obj.targetSusik.push(catObj);
        } else {
          let catObj = {
            categoryId: cat.categoryId,
            items: [],
            checked: false,
          };
          for (let inq of cat.inquiryList) {
            let inqObj = {
              inquiryMappingId: inq.inquiryMappingId,
              checked: false,
            };
            catObj.items.push(inqObj);
          }
          obj.targetSusik.push(catObj);
        }
      }
      susikList.push(obj);
      this.susikList = JSON.parse(JSON.stringify(susikList));
    },
    removeSusik: function (index) {
      let susikList = this.susikList;
      susikList.splice(index, 1);
      this.susikList = JSON.parse(JSON.stringify(susikList));
    },
    gubunUp: function (index) {
      if (index == 0) {
        alert("첫번째 항목입니다.");
        return;
      }
      let tempItems = this.susikList[index];
      this.susikList.splice(index, 1);
      this.susikList.splice(index - 1, 0, tempItems);

      // this.items = JSON.parse(JSON.stringify(this.items));
    },
    gubunDown: function (index) {
      if (index == this.susikList.length - 1) {
        alert("마지막 항목입니다.");
        return;
      }
      let tempItems = this.susikList[index];
      this.susikList.splice(index, 1);
      this.susikList.splice(index + 1, 0, tempItems);
      //  this.items = JSON.parse(JSON.stringify(this.items));
    },
    saveConfirm: function () {
      //수식을 저장할 것인지 컨펌.
      for (let susik of this.susikList) {
        let isChecked = false;
        if (susik.susikName == null) {
          this.openAlert("수식 이름을 입력하여 주세요.");
          return;
        }
        if (susik.susikName.trim() == "") {
          this.openAlert("수식 이름을 입력하여 주세요.");
          return;
        }
        for (let cat of susik.targetSusik) {
          for (let inq of cat.items) {
            if (inq.checked) {
              isChecked = true;
              break;
            }
          }
        }
        if (this.detailId == null) {
          this.opneAlert("유효하지 않은 문진상세 버전입니다.");
          return;
        }

        if (!isChecked) {
          this.openAlert("수식에 추가할 항목을 한개 이상 선택해 주세요.");
          return;
        }
      }

      this.openConfirm("입력한 수식을 저장하시겠습니까?", this.save);
    },
    save: function () {
      //수식을 저장.
      if (this.btnLock) {
        return;
      } else {
        this.btnLock = true;
      }

      this.$axios
        .post("/api/mng/inquiry/v1/susik", {
          susikList: this.susikList,
          detailId: this.detailId,
        })
        .then((response) => {
          if (response.data.success) {
            this.openAlertClosed(
              "수식을 저장하였습니다.",
              this.$router.push({ name: "inq-list" })
            );
          } else {
            this.openAlert("수식 저장중 오류가 발생했습니다.");
          }
          this.btnLock = false;
        })
        .catch(() => {
          this.openAlert("수식을 저장중 오류가 발생했습니다.");
          this.btnLock = false;
        });
    },
    goBack: function () {
      this.$router.back();
    },
  },
};
</script>

<style scoped="scoped">
.q_card {
  overflow: visible;
}

.contents {
  min-height: 800px;
}

.pop {
  display: inline-block;
}

.sort_btn .btn_del {
  background: url("/img/ico_del.2a36f96e.svg") no-repeat 3px 0;
}
.sort_btn .btn_del {
  padding: 3px 10px 2px 25px;
  font-size: 12px;
  border: 1px solid #a0a3b9;
  border-radius: 3px;
}

.susik dt {
  flex-grow: 5;
}
</style>
