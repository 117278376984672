import { render, staticRenderFns } from "./emmaList.vue?vue&type=template&id=15654cf6&scoped=true&"
import script from "./emmaList.vue?vue&type=script&lang=js&"
export * from "./emmaList.vue?vue&type=script&lang=js&"
import style0 from "./emmaList.vue?vue&type=style&index=0&id=15654cf6&prod&scoped=scoped&lang=css&"
import style1 from "./emmaList.vue?vue&type=style&index=1&id=15654cf6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15654cf6",
  null
  
)

export default component.exports