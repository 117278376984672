<template>
  <div class="contents">
    <div class="loc">
      <li>
        <a href="">기본환경</a>
      </li>
      <li>
        <a href="">체류시간 안내문구 관리</a>
      </li>
    </div>
    <div class="wapper">
      <div class="title">
        <h2>체류시간 안내문구 관리</h2>
        <div class="btn-right">
          <button class="btn_primary" @click="getPrevList()">이전 버전 불러오기</button>
        </div>
      </div>
      <div class="details">
        <div class="detail_conts">
          <h4>한국어 버전 </h4>
          <blockquote>문진에서 한국어 선택시 보여집니다.</blockquote>
          <br/>
          <h4>제목</h4>
          <div style="width: 100%;"><textarea type="text" style="width: 100%;" v-model="title"> </textarea></div>
          <br/>
          <h4>내용</h4>
<!--          <div id="editor"></div>-->
          <textarea v-model="editor"></textarea>
        </div>
        <div class="detail_conts">
          <h4>영문 버전 </h4>
          <blockquote>문진에서 한국어 이외의 언어를 선택시 보여집니다.</blockquote>
          <br/>
          <h4>제목</h4>
          <div style="width: 100%;"><textarea type="text" style="width: 100%;" v-model="titleEn"></textarea></div>
          <br/>
          <h4>내용</h4>
<!--          <div id="editor2"></div>-->
          <textarea v-model="editor2"></textarea>
        </div>
      </div>
      <div class="title">
        <div class="btn-right">
          <button class="btn_primary" @click="savePrivacy()">저장</button>
          <button class="btn_secondary" @click="goBack()">취소</button>
        </div>
      </div>
    </div>
    <modal
        name="alertMsg"
        :width="540"
        :height="190"
        :adaptive="true"
        :clickToClose="false">
      <div id="popup_reg" class="pop pop_md">
        <div class="pop_head">
          <h2>알림</h2>
          <a class="btn_pop_close" @click="closePop('alertMsg')">닫기</a>
        </div>
        <div class="pop_body">
          {{ errMsg }}
        </div>
        <div class="pop_footer">
          <button class="btn_secondary" @click="closePop('alertMsg')">확 인</button>
        </div>
      </div>
    </modal>
    <modal
        name="success"
        :width="540"
        :height="190"
        :adaptive="true"
        :clickToClose="false"
        @closed="goBack">
      <div id="popup_reg" class="pop pop_md">
        <div class="pop_head">
          <h2>알림</h2>
          <a class="btn_pop_close" @click="closePop('success')">닫기</a>
        </div>
        <div class="pop_body">
          {{ errMsg }}
        </div>
        <div class="pop_footer">
          <button class="btn_secondary" @click="closePop('success')">확 인</button>
        </div>
      </div>
    </modal>
    <modal
        name="popup_prev_road"
        :width="320"
        :height="228"
        :adaptive="true"
        :clickToClose="false">
      <div id="popup_prev_road" class="pop pop_sm">
        <!-- 새 문진 등록 -->
        <div class="pop_head">
          <h2>이전 버전 불러오기</h2>
          <a class="btn_pop_close" @click="closePop('popup_prev_road')">닫기</a>
        </div>
        <div class="pop_body">
          <dl>
            <dt>버전 선택</dt>
            <dd>
              <select @change="selectPrevVer($event)">
                <option value="" disabled="disabled" selected="selected">버전</option>
                <option v-for="(item, index) in prevList" :key="index" :value="item.privacyCode">{{ item.privacyVer }}
                </option>
              </select>
            </dd>
          </dl>
        </div>
        <div class="pop_footer">
          <button class="btn_secondary" id="btn_reg_cancel" @click="closePop('popup_prev_road')">취 소</button>
          <button class="btn_primary" @click="getPrivacyInfo()">확인</button>
        </div>
      </div>
    </modal>
  </div>

</template>
<script>
export default {
  mounted() {
  },
  data: () => {
    return {
      editor: null,
      editor2: null,
      errMsg: null,
      btnLock: false,
      prevList: [],
      prevVer: null,
      title: null,
      titleEn: null,
    }
  },
  methods: {
    goBack: function () {
      this
          .$router
          .push({name: 'residenceTime'});
    },
    savePrivacy: function () {
      if (this.btnLock) {
        return;
      } else {
        this.btnLock = true;
      }
      try {
        if (!this.title) {
          this.errMsg = "한국어 제목을 입력하세요.";
          this
              .$modal
              .show('alertMsg');
          return;
        }
        if (!this.titleEn) {
          this.errMsg = "영어 제목을 입력하세요.";
          this
              .$modal
              .show('alertMsg');
          return;
        }
        let text = this.editor;
        let text2 = this.editor2;
        if (text.length <= 0) {
          this.errMsg = "한국어 내용을 입력하세요.";
          this
              .$modal
              .show('alertMsg');
          return;
        }
        if (text2.length <= 0) {
          this.errMsg = "영어 내용을 입력하세요.";
          this
              .$modal
              .show('alertMsg');
          return;
        }
        this
            .$axios
            .post('/api/mng/v1/residence',
                {
                  privacyContents: text,
                  privacyContentsEng: text2,
                  title: this.title,
                  titleEn: this.titleEn
                })
            .then((response) => {

              if (response.data.success) {
                this.errMsg = "저장이 완료되었습니다. \n 리스트 페이지로 이동합니다.";
                this
                    .$modal
                    .show('success');
              } else {
                this.errMsg = "저장중 오류가 발생했습니다.";
                this
                    .$modal
                    .show('alertMsg');
              }
              this.btnLock = false;

            })
            .catch((err) => {
              this.btnLock = false;
              this.errMsg = err.response.data;
              this
                  .$modal
                  .show('alertMsg');
            })
      } finally {
        this.btnLock = false;
      }

    },
    async getPrevList() {
      await this.getPrivacy();
      this.$modal.show('popup_prev_road');
    },
    async getPrivacy() {
      await this
          .$axios
          .get('/api/mng/v1/residence',{
            params : {
              page :0,
              size : 100
            }
          })
          .then((response) => {
            if (response.data.success) {
              this.prevList = response.data.result.content;
            }

          })
          .catch();
    },
    selectPrevVer(event) {
      let code = event.target.value;
      if (code) {
        this.prevVer = code;
      }
    },
    async getPrivacyInfo() {
      if (this.prevVer) {
        await this
            .$axios
            .get('/api/mng/v1/residence/' + this.prevVer)
            .then((response) => {
              if (response.data.success) {
                this.editor = response.data.result.privacyContents;
                this.editor2 = response.data.result.privacyContentsEng;
                this.title = response.data.result.title;
                this.titleEn = response.data.result.titleEn;
              } else {
                this.openAlert("개인정보 정보를 가져오는 도중 오류가 발생했습니다.");
              }
            })
            .catch(() => {
              this.openAlert("개인정보 정보를 가져오는 도중 오류가 발생했습니다.");
            })
      }
      this.prevVer = null;
      this.$modal.hide('popup_prev_road')
    },
    closePop: function (target) {
      this.errMsg = null;
      this
          .$modal
          .hide(target);
    },
  }
};
</script>
<style scoped="scoped">
#popup_reg {
  display: inline-block;
}

.details {
  padding: 20px 0;
  display: flex;
}

.details .detail_conts {
  width: 48%;
  margin: 0 1%;
}

.pop {
  display: inline-block;
}

.detail_conts textarea {
  width: 100%;
  height: 200px;
  border: solid 1px #AAACB4;
  resize: none;
}

</style>
