<template>

    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">마스터관리</a>
            </li>
            <li>
                <a href="javascript:void(0)">분류코드관리</a>
            </li>
        </div>
        <div class="wapper" style="min-height: 600px">
            <div class="title">
                <h2>분류코드관리</h2>
            </div>
            <h3>
                <div class="btn-right">
                    <button class="btn_primary" @click="goRegister">신규등록</button>
                    <button class="btn_search" @click="this.openSearch">검색</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">

                            <dl class="full_width">
                                <dt>분류명</dt>
                                <dd>
                                    <input type="text" v-model="classCodeName"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>비고</dt>
                                <dd>
                                    <input type="text" v-model="comment"/></dd>
                            </dl>
                            <dl class="full_width">
                                <input
                                    type="checkbox"
                                    id="useYn"
                                    name="useYn"
                                    v-model="useYn"/>
                                <label for="useYn" style="margin-left: 10px;">
                                    <span>사용여부
                                    </span>
                                </label>
                            </dl>
                        </div>
                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'btn_filered' : filter}"
                                @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                            <button class="btn_primary btn_md" @click="getPlaceClass(0)">적용</button>
                        </div>
                    </div>
                </div>
            </h3>
            <div class="table table_line">
                <table>
                    <tr>
                        <th>분류코드</th>
                        <th>분류명</th>
                        <th>비고</th>
                        <th>사용여부</th>
                        <th>관리</th>
                    </tr>
                    <tr v-for="(item,index) in this.placeClassList" :key="index">
                        <td>{{item.classCode}}</td>
                        <td>{{item.className}}</td>
                        <td>{{item.comment}}</td>
                        <td>{{item.useYn == "Y" ? "사용" : "사용안함"}}</td>
                        <td>
                            <button @click="modify(item.classCode)">수정</button>
                        </td>
                    </tr>

                </table>
            </div>
            <div class="pagination">
                <ul>
                    <li>
                        <a href="javascript:void(0)" @click="getPlaceClass(page.number - 1)">이전</a>
                    </li>
                    <li
                        v-for="(item, index ) in pageNation"
                        :key="index"
                        class="pageNo"
                        :class="{'active': item == page.number +1}">
                        <a href="javascript:void(0)" @click="getPlaceClass(item - 1)">{{item}}</a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="getPlaceClass(page.number + 1)">다음</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import mixin from '../../mixin/mixin.js';
    import $ from 'jquery'

    export default {
        data: () => {
            return {
                placeClassList: [],
                classCode: null,
                classCodeName: null,
                comment: null,
                useYn: 'Y',
                filter: false
            }
        },
        mixins: [mixin],
        created: function () {
            this.getPlaceClass(0);
        },
        methods: {
            getPlaceClass: function (page) {

                if (page < 0 || this.page.totalPages < page) {
                    return;
                }
                let params = {};
                if (this.filter) {
                    let obj = {
                        page: page,
                        size: 30,
                        className: this.classCodeName,
                        comment: this.comment,
                        useYn : this.useYn ? 'Y': 'N'
                    }
                    params = obj
                } else {
                    let obj = {
                        page: page,
                        size: 30
                    }
                    params = obj
                }

                this
                    .$axios
                    .get('/api/mng/service/master/v1/place-class', {params: params})
                    .then((response) => {
                        console.log(response.data);

                        this.placeClassList = response.data.result.content;
                        let pageInfo = response.data.result;
                        this.totalCount = pageInfo.totalElements;
                        this.pagerHandler(
                            pageInfo.number,
                            pageInfo.first,
                            pageInfo.last,
                            pageInfo.totalPages
                        );
                    })
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            goRegister: function () {
                // 랜덤문자 생성후 중복 체크
                let cCode = this.makecode();
                let bool = false;
                for (let item of this.placeClassList) {
                    if (item.classCode == cCode) {
                        bool = true;
                    }
                }
                if (!bool) {
                    this
                        .$router
                        .push({
                            name: 'placeClassMng',
                            query: {
                                classCode: cCode
                            }
                        });
                }
            },
            makecode: function () {
                let text = "";
                let str = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
                for (var i = 0; i < 4; i++) 
                    text += str.charAt(Math.floor(Math.random() * str.length));
                return text;
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.classCodeName = null;
                this.comment = null;
            },
            modify: function (classCode) {
                this
                    .$router
                    .push({
                        name: 'placeClassUpd',
                        query: {
                            code: window.btoa(classCode)
                        }
                    })
            }

        }

    }
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
</style>