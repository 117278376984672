<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="">방문신청</a>
            </li>
            <li>
                <a href="">집합행사관리</a>
            </li>
        </div>
        <div class="wapper" style="min-height:700px">
            <div class="title">
                <h2>집합행사 관리</h2>
            </div>
            <h3>
                <div class="btn-right">
                    <button class="btn_primary" @click="$router.push({name: 'groupEventMng'})">신규등록</button>
                    <button class="btn_search" @click="openSearch()">검색</button>
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">

                            <dl class="full_width">
                                <dt>행사명</dt>
                                <dd>
                                    <input type="text" v-model="meetingName"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>등록자</dt>
                                <dd>
                                    <input type="text" v-model="regUser"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>장소</dt>
                                <dd>
                                    <input type="text" v-model="meetingPlace"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>시작/종료일</dt>
                                <dd>
                                    <input type='date' v-model='startDate' @change="changeStartDate"/>
                                    ~
                                    <input type='date' v-model='endDate' @change="changeEndDate"/>
                                </dd>
                            </dl>
                        </div>
                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'btn_filered' : filter}"
                                @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                            <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
                        </div>
                    </div>
                </div>
            </h3>
            <div class="table table_line">
                <table>
                    <tr>
                        <th>등록일시</th>
                        <th>등록자</th>
                        <th>유형</th>
                        <th>행사명</th>
                        <th>시작일</th>
                        <th>종료일</th>
                        <th>장소</th>
                        <th>문진</th>
                        <th>초대코드</th>
                        <th>상태</th>
                        <th>비고</th>
                    </tr>
                    <tr v-for="(item, index) in this.eventList" :key="index">
                        <td>{{convertDate(item.regDate)}}</td>
                        <td>{{item.regUser}}</td>
                        <td>{{item.masterIdName}}</td>
                        <td>{{item.meetingName}}</td>
                        <td>{{convertDate(item.startDate)}}</td>
                        <td>{{convertDate(item.endDate)}}</td>
                        <td>{{item.meetingPlace}}</td>
                        <td>{{item.openYn == 'Y' ? '공개':'비공개'}}</td>
                        <td>{{item.inviteCode}}</td>
                        <td>{{item.status}}</td>
                        <td>
                            <button v-if="item.regUser == username" @click="modify(item.meetingId, item.regUser)">수정</button>
                            <button v-if="item.openYn == 'N'" @click="participants(item.meetingId, item.statusCd, item.regUser)">대상자 조회</button>
                            <button class="urlBtn" v-if="item.openYn == 'Y'" @click="copyUrl(item.meetingId)">URL복사</button>
                        </td>
                    </tr>
                </table>
                <input type="hidden" id="copyUrl" ref="copyUrl" value=""/>
            </div>
            <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" 
            :startDate="startDate" :endDate="endDate" :meetingName="meetingName" :regUser="regUser" :meetingPlace="meetingPlace"/>
        </div>
    </div>
</template>
<script>
    import pagination from '../../components/commonPagination.vue'
    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';
    import $ from 'jquery'
    export default {
        data: () => {
            return {eventList: [], filter: false, meetingName: null, meetingPlace: null, regUser: null, startDate: moment().format("YYYY-MM-DD"), endDate: moment().add(1, 'M').format("YYYY-MM-DD")}
        },
        mixins: [mixin],
        created: function () {
            this.getMyinfo();      
        },
        mounted: function() {
            if(this.$route.query.page) {
                this.changeFilter() //검색 param을 넣기 위해서 필요
                this.meetingName = this.$route.query.meetingName || null
                this.meetingPlace = this.$route.query.meetingPlace|| null
                this.regUser = this.$route.query.regUser|| null
                this.startDate = this.$route.query.startDate|| null
                this.endDate = this.$route.query.endDate|| null
                this.getList(this.$route.query.page);
            } else {
                this.getList(0);
            }
                        
        },
        components : {
            pagination
        },
        methods: {
            getList: function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }

                let param = {};

                if (this.filter) {
                    param = {
                        page: page,
                        size: 30,
                        meetingName: this.meetingName,
                        meetingPlace: this.meetingPlace,
                        regUser: this.regUser,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                } else {
                    param = {
                        page: page,
                        size: 30,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                }

                this
                .$axios
                .get('/api/mng/group-event/v1/group', {params: param})
                .then((response) => {
                    console.log(response.data);
                    let today = new Date();

                    if (response.data.success) {
                        let result = response.data.result.content;
                        for (let item of result) {
                            let startDate = new Date(item.startDate);
                            let endDate = null;
                            if (item.endDate == null) {
                                endDate = new Date('2999-12-31');
                            } else {
                                endDate = new Date(item.endDate);
                            }
                            console.log(startDate > today);

                            if (startDate > today) {
                                console.log('대기');
                                item.status = "대기중"
                                item.statusCd = "wait"
                                continue;
                            } else if (startDate < today && today < endDate) {
                                console.log('사용');
                                item.status = "사용중"
                                item.statusCd = "use"
                                continue;
                            } else if (endDate < today) {
                                console.log('종료');
                                item.status = "종료"
                                item.statusCd = "end"
                                continue;
                            }
                        }
                        this.eventList = result;

                        let pageInfo = response.data.result;

                        this.pagerHandler(
                            pageInfo.number,
                            pageInfo.first,
                            pageInfo.last,
                            pageInfo.totalPages
                        );
                    } else {
                        this.openAlert("집합행사를 불러오는 도중 오류가 발생하였습니다.");
                    }
                })
                .catch(() => {
                    this.openAlert("집합행사를 불러오는 도중 오류가 발생하였습니다.");
                });
            },
            firstSearch: function () {
                let pageQuery = {
                    page : 0,
                    meetingName : this.meetingName || null,
                    meetingPlace : this.meetingPlace|| null,
                    regUser : this.regUser|| null,
                    startDate : this.startDate|| null,
                    endDate : this.endDate|| null
                }
                this.$router.push({
                    path: this.$route.path,
                    query: pageQuery
                }).catch(()=>{});
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            confirmDownload: function () {
                this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {
                let param = {};
                if (this.filter) {
                    param = {
                        page: 0,
                        size: 100000,
                        meetingName: this.meetingName,
                        meetingPlace: this.meetingPlace,
                        regUser: this.regUser,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                } else {
                    param = {
                        page: 0,
                        size: 100000
                    }
                }
                this
                .$axios
                .get('/api/mng/group-event/v1/group/excel', {params: param, responseType: "blob"})
                .then((response) => {
                    console.log(response);
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type']}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '집행행사관리_'+moment().format("YYYYMMDD_HHmmss")+'.xlsx');
                    document.body.appendChild(link);
                    link.click();

                    if(response.status == 200) {
                        let param = {
                            fileName: '집행행사관리_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                            fileSize: response.data.size,
                            purpose: paramdata.purpose,
                            purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                        }
                        this.setDownloadHistory(param);
                    }
                })
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.meetingName = null;
                this.regUser = null;
                this.startDate = moment().format("YYYY-MM-DD");
                this.endDate = moment().add(1, 'M').format("YYYY-MM-DD");
            },
            modify: function (id, regUser) {
                this
                .$router
                .push({
                    name: 'groupEventUpd',
                    query: {
                        mid: window.btoa(id),
                        regUser: regUser
                    }
                })
            },
            participants: function (meetingId, statusCd, regUser) {
                this
                .$router
                .push({
                    name: 'participants',
                    query: {
                        mid: window.btoa(meetingId),
                        statusCd: statusCd,
                        usr: window.btoa(regUser)
                    }
                });
            },
            changeStartDate(event) {
                this.startDate = event.target.value;
            },
            changeEndDate(event) {
                this.endDate = event.target.value;
            },
            copyUrl: function (meetingId) {
                let urlInput = this.$refs.copyUrl;
                this
                    .$axios
                    .get('/api/mng/common/v1/get-url', {
                        params: {
                            masterId: 'GST'
                        }
                    })
                    .then((response) => {
                        if (response.data.success) {
                            console.log(response.data.result);
                            urlInput.value = response.data.result.url+'?code='+meetingId;
                            urlInput.type = 'text';
                            urlInput.select();
                            document.execCommand("Copy");
                            urlInput.type = 'hidden';
                            this.openAlert("URL이 복사 되었습니다.");
                        } else {
                            this.openAlert(response.data.resultMsg);
                        }
                    })
                    .catch(() => {
                        this.openAlert("URL 데이터를 가져오는 도중 오류가 발생했습니다.");
                    });
            }
        }
    }

</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
    .urlBtn {
        background-color: rgb(150, 150, 150);
        color: white;
    }
</style>
