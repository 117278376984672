<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javscript:void(0)">통계</a>
            </li>
            <li>
                <a href="javscript:void(0)">일별통계</a>
            </li>
            <li>
                <a href="javscript:void(0)">스피드게이트 인증결과</a>
            </li>
        </div>
        <div class="wapper" style="min-height: 700px;">
            <div class="title">
                <h2>스피드게이트 출입인증결과</h2>
            </div>
            <div class="infection_search">
                <dt>날짜</dt>
                <dd>
                    <select @change="changeSearchDate">
                        <option value='recentWeek'>최근일주일</option>
                        <option value='prevWeek'>지난주</option>
                        <option value='recentMonth'>최근 1개월</option>
                        <option value='customDate'>직접지정</option>
                    </select>
                    <input type="date" :disabled="datedisabled" v-model="startDate"> ~ <input type="date" :disabled="datedisabled" v-model="endDate">
                    <button class="btn_search">조회</button>
                </dd>
            </div>
            <h3>
                구역 선택
                    <div class="select_data">
                                <select>
                                <option>전체</option>
                                <option>서관1</option>
                                <option>서관2</option>
                                <option>동관정문</option>
                                <option>동관후문</option>
                                <option>동관B1</option>
                                <option>신관</option>
                                <option>신관B1</option>
                            </select>
                        <div class ="checkbox">                      
                            <input type="checkbox" id="sort_all" name="sort_all" v-model="paramAll" @change="paramAllCheck"><label for="sort_all"><span>전체</span></label>
                            <input type="checkbox" id="sort_1" name="sort_1" v-model="paramSuccess"><label for="sort_1"><span>인증성공</span></label> 
                            <input type="checkbox" id="sort_2" name="sort_2" v-model="paramFailmatch"><label for="sort_2"><span>1:1인증실패</span></label> 
                            <input type="checkbox" id="sort_3" name="sort_3" v-model="paramFailtime"><label for="sort_3"><span>실패(시간초과)</span></label> 
                            <input type="checkbox" id="sort_4" name="sort_4" v-model="paramFailtmp"><label for="sort_4"><span>이상온도(오류)</span></label>                   
                            <input type="checkbox" id="sort_5" name="sort_5" v-model="paramFailfever"><label for="sort_5"><span>이상온도(발열)</span></label>                   
                            <input type="checkbox" id="sort_6" name="sort_6" v-model="paramReject"><label for="sort_6"><span>출입거부</span></label>                       
                        </div>
                    </div>
                   
                <div class="btn-right">
                    <button class="btn_download">다운로드</button>
                </div>
            </h3>
            <!-- 그래프 -->
            <div class="chart_wrap">
                <div style="width: 100%;">
                    <bar-chart :chartData="chartData"></bar-chart>
                </div>
            </div>
            <!-- 데이터 -->
            <div class="table statistics">
                <table>
                    <tr>
                        <th>날짜</th><th>요일</th><th>인증성공</th><th>1:1인증실패</th><th>실패(시간초과)</th><th>이상온도(오류)</th><th>이상온도(발열)</th><th>출입거부</th><th>합계</th>
                    </tr>
                    <tr>
                        <th>10/25</th>
                        <th>월</th>
                        <td>6,068</td>
                        <td>5,273</td>
                        <td>16,702 </td>
                        <td>6,452</td>
                        <td>6,137</td>
                        <td>9,933</td>
                        <td>53,741</td>
                    </tr>
                    <tr>
                        <th>10/26</th>
                        <th>화</th>
                        <td>5,461</td>
                        <td>4,746</td>
                        <td>39,391 </td>
                        <td>6,452</td>
                        <td>6,137</td>
                        <td>9,933</td>
                        <td>53,741</td>                        
                    </tr>
                    <tr>
                        <th>10/27</th>
                        <th>수</th>
                        <td>23,232</td>
                        <td>4,545</td>
                        <td>27,777 </td>
                        <td>6,452</td>
                        <td>6,137</td>
                        <td>9,933</td>
                        <td>53,741</td>
                    </tr>
                    <tr>
                        <th>10/28</th>
                        <th>목</th>
                        <td>35,625</td>
                        <td>7,125</td>
                        <td>42,750 </td>
                        <td>6,452</td>
                        <td>6,137</td>
                        <td>9,933</td>
                        <td>53,741</td>
                    </tr>
                    <tr>
                        <th>10/29</th>
                        <th>금</th>
                        <td>35,625</td>
                        <td>7,125</td>
                        <td>42,750 </td>
                        <td>6,452</td>
                        <td>6,137</td>
                        <td>9,933</td>
                        <td>53,741</td>
                    </tr>                    
                    <tfoot>
                        <tr>
                        <th colspan="2">합계</th>
                        <td>136,178</td>
                        <td>26,526</td>
                        <td>162,704</td>
                        <td>6,452</td>
                        <td>6,137</td>
                        <td>9,933</td>
                        <td>53,741</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
    import mixin from '../../mixin/mixin.js';
    import BarChart from "../../components/barChart.vue";
    import moment from 'moment';
    export default {
        components: {
            'bar-chart': BarChart
        },
        data: () => {
            return {
                datedisabled: true,
                startDate: moment().add(-7, 'd').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
                chartData: null,
                list: null,
                paramAll:true,
                paramSuccess: true,
                paramFailmatch:true,
                paramFailtime:true,
                paramFailtmp:true,
                paramFailfever:true,
                paramReject:true,


            }
        },
        mixins: [mixin],
        mounted: function () {
            this.chartData = {
                labels: [['월', '10/25'], ['화', '10/26'], ['수','10/27'], ['목', '10/28'], ['금','10/29']],
                datasets: [
                    {
                        type:'bar',
                        label: '인증성공',
                        stack: 'type',
                        backgroundColor: this.colorList[0],
                        data: [35625, 32555, 23232, 25545, 20221],
                    },
                    {
                        type:'bar',
                        label: '1:1인증실패',
                        stack: 'type',
                        backgroundColor: this.colorList[1],
                        data: [7125, 6836, 4545, 5021, 2999]
                    },
                    {
                        type:'bar',
                        label: '실패(시간초과)',
                        stack: 'type',
                        backgroundColor: this.colorList[2],
                        data: [7125, 6836, 4545, 5021, 2999]
                    },
                    {
                        type:'bar',
                        label: '이상온도(오류)',
                        stack: 'type',
                        backgroundColor: this.colorList[3],
                        data: [7125, 6836, 4545, 5021, 2999]
                    },
                    {
                        type:'bar',
                        label: '이상온도(발열)',
                        stack: 'type',
                        backgroundColor: this.colorList[4],
                        data: [7125, 6836, 4545, 5021, 2999]
                    },
                                        {
                        type:'bar',
                        label: '출입거부',
                        stack: 'type',
                        backgroundColor: this.colorList[5],
                        data: [7125, 6836, 4545, 5021, 2999]
                    },
                ],
                options: {
                    scales: {
                        x: {stacked: true},
                        y: {stacked: true}
                    }
                }
            }
        },
        watch: {           
            paramSuccess(){
                if(this.paramSuccess && this.paramFailmatch && this.paramFailtime && this.paramFailtmp && this.paramFailfever &&this.paramReject){
                    this.paramAll = true;
                } else {
                    this.paramAll = false;
                }
            },
            paramFailmatch(){
                if(this.paramSuccess && this.paramFailmatch && this.paramFailtime && this.paramFailtmp &&this.paramFailfever &&this.paramReject){
                    this.paramAll = true;
                } else {
                    this.paramAll = false;
                }
            },
            paramFailtime(){
                if(this.paramSuccess && this.paramFailmatch && this.paramFailtime && this.paramFailtmp &&this.paramFailfever &&this.paramReject){
                    this.paramAll = true;
                } else {
                    this.paramAll = false;
                }
            },
            paramFailtmp(){
                if(this.paramSuccess && this.paramFailmatch && this.paramFailtime && this.paramFailtmp &&this.paramFailfever &&this.paramReject){
                    this.paramAll = true;
                } else {
                    this.paramAll = false;
                }
            },

            paramFailfever(){
                if(this.paramSuccess && this.paramFailmatch && this.paramFailtime && this.paramFailtmp &&this.paramFailfever &&this.paramReject){
                    this.paramAll = true;
                } else {
                    this.paramAll = false;
                }
            },
            paramReject(){
                if(this.paramSuccess && this.paramFailmatch && this.paramFailtime && this.paramFailtmp &&this.paramFailfever &&this.paramReject){
                    this.paramAll = true;
                } else {
                    this.paramAll = false;
                }
            }
        },         
        methods: {
            changeSearchDate(event) {
                if(event.target.value == "recentWeek"){
                    this.datedisabled = true;
                    this.startDate = moment().add(-7, 'd').format("YYYY-MM-DD")
                    this.endDate = moment().format("YYYY-MM-DD")
                } else if(event.target.value == "prevWeek"){
                    this.datedisabled = true;
                    this.startDate = moment().add(-1, 'w').isoWeekday(1).format("YYYY-MM-DD")
                    this.endDate = moment().add(-1, 'w').isoWeekday(5).format("YYYY-MM-DD")
                } else if(event.target.value == "recentMonth"){
                    this.datedisabled = true;
                    this.startDate = moment().add(-1, 'M').format("YYYY-MM-DD")
                    this.endDate = moment().format("YYYY-MM-DD")
                } else if(event.target.value == "customDate"){
                    this.datedisabled = false;
                    this.startDate = moment().format("YYYY-MM-DD")
                    this.endDate = moment().format("YYYY-MM-DD")
                }
            },
                getList: function () {      
                
                if(this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                
                 if(this.searchConditionChecker()) {
                     this.openAlert("한개이상의 구분을 선택하세요.");
                      this.btnLock = false;
                     return;
                 } 

                const param = {
                    startDate: this.startDate,
                    endDate: this.endDate,
                    paramSuccess: this.paramSuccess  ? 'Y' :'N' ,
                    paramFailmatch: this.paramFailmatch ? 'Y' :'N',
                    paramFailtime: this.paramFailtime ? 'Y' :'N',                    
                    paramFailtmp: this.paramFailtmp ? 'Y' :'N',
                    paramFailfever: this.paramFailfever ? 'Y' :'N',
                    paramReject: this.paramReject ? 'Y' :'N'
                }
                this
                .$axios
                .get('/api/statistics/',{
                    params: param
                })
                .then((response) => {
                     this.btnLock = false;
                    if (response.data.success) {
                        this.summary = response.data.result.summaryEmp;
                        this.dept = response.data.result.deptList;

                        let smrDiagnosisAvg = 0;
                        let smrDoctorAvg = 0;
                        let smrExceptDoctorAvg  = 0;
                        let smrNormalEmpAvg  = 0;
                        let smrInternAvg  = 0;
                        let smrStudentAvg   = 0;
                        let smrTotalAvg  = 0;

                        let listSize = this.summary.length ? this.summary.length : 1;


                        for(let item of this.summary) {
                            smrDiagnosisAvg += item.diagnosisRate;
                            smrDoctorAvg += item.doctorInqRate;
                            smrExceptDoctorAvg += item.exceptDoctorRate;
                            smrNormalEmpAvg += item.normalEmpRate;
                            smrInternAvg += item.internRate;
                            smrStudentAvg += item.studentRate;
                            smrTotalAvg += item.totalRate;
                        }

                        this.smrDiagnosisAvg = (smrDiagnosisAvg / listSize);
                        this.smrDoctorAvg = (smrDoctorAvg / listSize);
                        this.smrExceptDoctorAvg = (smrExceptDoctorAvg / listSize);
                        this.smrNormalEmpAvg = (smrNormalEmpAvg / listSize);
                        this.smrInternAvg = (smrInternAvg / listSize);
                        this.smrStudentAvg = (smrStudentAvg / listSize);
                        this.smrTotalAvg = (smrTotalAvg / listSize);


                    } else {
                        this.openAlert("리스트 조회에 실패했습니다.");
                    }
                    
                })
                .catch(() => {
                    this.openAlert("리스트 조회에 실패했습니다.");
                    this.btnLock = false;
                });
            },
            confirmDownload: function () {
                this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            // excelDown: function (paramdata) {
            //     const param = {
            //         page: 0,
            //         size: 2000,
            //         inqType: this.inqType,
            //         startDate: this.startDate,
            //         endDate: this.endDate
            //     }
            //     this
            //     .$axios
            //     .get('/api/statistics/analysis/getNoklist/excel', {
            //         params: param,
            //         responseType: "blob"
            //     })
            //     .then((response) => {
            //         console.log(response);
            //         const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
            //         const link = document.createElement('a');
            //         link.href = url;
            //         link.setAttribute('download','문진표통계분석_일반보호자_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx');
            //         document.body.appendChild(link);
            //         link.click();

            //         if(response.status == 200) {
            //               let param = {
            //                   fileName: '문진표통계분석_일반보호자_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
            //                   fileSize: response.data.size,
            //                   purpose: paramdata.purpose,
            //                   purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
            //               }
            //               this.setDownloadHistory(param);
            //         }
            //     })
            // },
            changeStartDate(event) {
                this.startDate = event.target.value;
            },
            changeEndDate(event) {
                this.endDate = event.target.value;
            },           
           checkUnderAvg(targetAvg) {
                if(this.smrTotalAvg) {
                    if(targetAvg != 0) {
                        if(targetAvg < this.smrTotalAvg) {
                            return true;
                        }
                    }

                }
                return false;
            },
            paramAllCheck() {
                if(this.paramAll) {
                    this.paramSuccess = true;
                    this.paramFailmatch= true;
                    this.paramFailtime = true;                    
                    this.paramFailtmp = true;
                    this.paramFailfever = true;
                    this.paramReject = true;
                } else {
                    this.paramSuccess= false;
                    this.paramFailmatch = false;
                    this.paramFailtime = false;                    
                    this.paramFailtmp = false;
                    this.paramFailfever = false;
                    this.paramReject = false;
                }
            
            },  
            searchConditionChecker: function() { 


                if((!this.paramSuccess && !this.paramFailmatch && !this.paramFailtime && !this.paramFailtmp && !this.paramFailfever  && !this.paramRejec )) {
                    return true;
                } else {
                    return false;
                }
            }              
        }
    }


</script>
<style scoped>
.select_data .checkbox{
    line-height: 35px;
}
.checkbox span{
    margin-right: 10px;
}
h3{
    margin-bottom: 70px;
}

</style>