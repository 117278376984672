<template>
    <div style="position: relative; display:inline-block;">
        <input
            v-bind:prevVal="prevVal"
            type="text"
            ref="searchInput"
            :placeholder='inputName + " 입력하세요."'
            v-on:input="openBox()"
            @keydown.up="moveList('up')"
            @keydown.down="moveList('down')"
            @keydown.enter="selectSearchWord()"
            autocomplete="off"
            style="width:100%"/>
        <div class="autoSearch" v-if="searchBox" @mousemove="mouseMove()">
            <ul>
                <li
                    v-for="(subitem, index) in searchWordList"
                    :key="index"
                    :ref="'list' + index"
                    @click="clickSearchWord(index)"
                    :class="{'active' : subitem.selected}">
                    <div>
                        <span :name="subitem.name" v-html="highlightDetector(subitem.name,searchText)"></span>
                        <span class="searchAction">
                            <a href="javascript:void(0)" v-if="subitem.add != true">추가</a>
                            <a href="javascript:void(0)" v-if="subitem.add == true">새로등록</a>
                        </span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    import mixin from '../mixin/mixin.js';
    let index = 0;
    export default {
        mixins: [mixin],
        data: function () {
            return {searchText: '', searchBox: false, searchWordList: []}
        },
        mounted: function () {
            if (this.prevVal != null) {
                this.$refs.searchInput.value = this.prevVal;
            }
        },
        watch: {
            searchText: function (newVal) {
                this.$refs.searchInput.value = newVal;
            },
            prevVal: function (newVal) {
                this.searchText = newVal;
                this.$refs.searchInput.value = newVal;
            }
        },
        props: [
            'searchList', 'inputName', 'target', 'prevVal', 'checkLength'
        ],
        methods: {
            highlightDetector(text, findWord) {
                // var regex = new RegExp(findWord, "gi"); text = text.replace(regex, "<span
                // class='highlight'>" + findWord + "</span>");

                text = text.replace(
                    findWord,
                    "<span class='highlight'>" + findWord + "</span>"
                );
                return text;
            },
            moveList: function (keyCode) {
                for (let item of this.searchWordList) {
                    item.selected = false;
                }
                if (keyCode == 'up') {
                    index--;
                } else if (keyCode == 'down') {
                    index++;
                }
                if (index < 0) {
                    index = 0;
                }
                if (index >= this.searchWordList.length) {
                    index = this.searchWordList.length - 1;
                }
                this
                    .searchWordList[index]
                    .selected = true;
            },
            openBox: function () {
                this.searchText = this.$refs.searchInput.value;
                // let reg = /[^가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9?*|\s]/.test(this.searchText); let reg =
                // /(^\s*)|(\s*$)/g.test(this.searchText); console.log('reg', reg);
                if(this.checkLength === 'Y' && this.getByte(this.searchText) > 99) {
                  this.openAlert("최대 글자 수는 100Byte(한글 약 50자)를 넘을 수 없습니다.")
                  const convertedStr = this.getLimitedByteText(this.searchText, 99);
                  this.$refs.searchInput.value = convertedStr;
                  this.searchText = convertedStr;
                }
                if (this.searchText.length == 0) {
                    this.searchBox = false;
                    index = 0;
                }
                if (this.searchText !== '') {
                    if (this.searchText.trim() != '') {
                        this.searchWordList = this
                            .searchList
                            .filter((el) => {
                                if (el.name != null) {
                                  if(this.checkLength === 'Y') {
                                    if(this.getByte(el.name) <= 99) {
                                      return (el.name.match(this.searchText));
                                    }
                                  } else {
                                    return (el.name.match(this.searchText));
                                  }
                                }
                            })
                    }

                    let matched = false;
                    for (var i = 0; i < this.searchWordList.length; i++) {
                        if (this.searchWordList[i].name == this.searchText) {
                            matched = true;
                            break;
                        }
                    }
                    if (!matched) {
                        this
                            .searchWordList
                            .push({add: true, name: this.searchText, selected: false})
                    }
                    if (this.searchText.length > 0) {
                        this.searchBox = true;
                        index = 0;
                    } else {
                        this.searchBox = false;
                        index = 0;
                    }
                    if (this.searchWordList.length > 0) {
                        this
                            .searchWordList[0]
                            .selected = true;
                    }
                }
            },
            selectSearchWord: function () {
                for (let item of this.searchWordList) {
                    if (item.selected) {
                      
                        this.searchText = item.name;
                    }
                    item.selected = false;
                }
                this.searchBox = false;
                index = 0;
                this.$emit('selectedVal', this.target, this.searchText);
                this.$refs.searchInput.value = this.prevVal;
            },
            clickSearchWord: function (index) {
                for (let item of this.searchWordList) {
                    item.selected = false;
                }
                this.searchText = this
                    .searchWordList[index]
                    .name;

                this.searchBox = false;
                index = 0;
                this.$emit('selectedVal', this.target, this.searchText);
                this.$refs.searchInput.value = this.prevVal;
            },
            mouseMove: function () {
                for (let item of this.searchWordList) {
                    item.selected = false;
                }
                index = 0;
            }
        }
    }
</script>
<style scoped="scoped">
    .autoSearch {
        position: absolute;
        z-index: 1;
        background-color: #ffffff;
        padding: 10px;
        border: #c7c7c7 1px solid;
        max-height: 500px;
        overflow-y: auto;
        top: 38px;
        left: 0;
        width: 100%;
    }
    .autoSearch ul {
        list-style: none;
    }

    .autoSearch ul li {
        margin-bottom: 2px;
        padding: 2px 0;
    }
    .autoSearch ul li:hover {
        background-color: #e2e2e2;
    }

    .autoSearch ul li.active {
        background-color: #e2e2e2;
    }

    .autoSearch ul .searchAction {
        float: right;
    }
    .autoSearch ul .searchAction::after {
        clear: both;
    }

    .autoSearch ul .searchAction a {
        text-decoration: none;
    }
</style>