<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="">기본환경</a>
            </li>
            <li>
                <a href="">출입증 재출력규칙 관리</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>출입증 재출력규칙 관리</h2>
            </div>
            <div class="table table_line">
                <table>
                    <tr>
                        <th></th>
                        <th>구분</th>
                        <th>출력시간(분)</th>
                        <th>설정</th>
                    </tr>
                    <tr>
                        <td>
                        </td>
                        <td>
                            <div v-if="ruleList.flagValue=='T'">
                                특정시간당 1회
                            </div>
                            <div v-else>
                                1일 1회
                            </div>
                        </td>
                        <td>
                            <div v-if="ruleList.flagValue=='T'">
                                {{ruleList.detailValue}} 분
                            </div>
                            <div v-else>
                                없음
                            </div>
                        </td>
                        <td>
                            <button @click="openmodify()">설정</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <!-- 팝업 -->
        <modal
            name="popup_q_road"
            :width="320"
            :height="220"
            :adaptive="true"
            :clickToClose="false">
            <div id="popup_q_road" class="pop pop_sm">
                <!-- 새 문진 등록 -->
                <div class="pop_head">
                    <h2>설정</h2>
                    <a class="btn_pop_close" @click="closeModifyModal()">닫기</a>
                </div>
                <div class="pop_body">
                    <dl>
                        <select v-model="permitType">
                        <option v-for="option in options" v-bind:value="option.value">
                            {{ option.text }}
                        </option>
                        </select>
                        <dd>
                            <v-app>
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    :return-value.sync="permitTime"
                                    transition="scale-transition"
                                    offset-y="offset-y"
                                    max-width="290px"
                                    min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-show="permitType=='T'">
                                        <input
                                            type="text"
                                            v-model="permitTime"
                                            style="display:inlin-block; width:100px;"
                                            v-bind="attrs"
                                            v-on="on"/>
                                            분
                                        </div>
                                    </template>
                                </v-menu>
                            </v-app>
                        </dd>
                    </dl>
                </div>
                <div class="pop_footer">
                    <button class="btn_secondary" id="btn_reg_cancel" @click="closeModifyModal()">취 소</button>
                    <button class="btn_primary" @click="modifyConfirm()">확인</button>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
    import mixin from '../../mixin/mixin.js';
    export default {
        data: () => {
            return {
                ruleList: [],
                resultList: [],
                permitType: null,
                permitTime: null,
                useYn: null,
                menu: false,
                options: [
                    { text: '1일 1회', value: 'D' },
                    { text: '특정시간당 1회', value: 'T' }
                ]
            }
        },
        mixins: [mixin],
        created: function () {
            this.getRuleList();
        },
        methods: {
            getRuleList: function () {
                this
                .$axios
                .get('/api/mng/service/reprint-rule/v1/rule')
                .then((response) => {
                    if (response.data.success) {
                        console.log(response.data.result);
                        this.ruleList = response.data.result[0];

                    } else {
                        this.openAlert("리스트 조회에 실패했습니다.");
                    }
                })
                .catch(() => {
                    this.openAlert("재인쇄규칙을 가져오지 못하였습니다.");
                })
            },
            openmodify: function () {
                this.permitType = this
                    .ruleList.flagValue;
                this.permitTime = this
                    .ruleList.detailValue;
                this
                    .$modal
                    .show("popup_q_road");
            },
            closeModifyModal: function () {
                this.permitType = null;
                this.permitTime = null;
                this
                    .$modal
                    .hide("popup_q_road");
            },
            modifyConfirm: function () {
                if (this.permitType=='T' && this.permitTime == null) {
                    this.openAlert("재인쇄 허용 시간을 입력하여 주세요.");
                    return;
                }
                if (this.permitType=='T' && this.permitTime.trim() == '') {
                    this.openAlert("재인쇄 허용 시간을 입력하여 주세요.");
                    return;
                }
            
                if (/[^0-9]/.test(this.permitTime)){
                    this.openAlert("재인쇄 허용 시간은 숫자만 입력하여 주세요.");
                    return;
                }
                this.permitTime = this
                    .permitTime
                    .trim();
                this.openConfirm("변경한 내용을 저장하시겠습니까?", this.modifypermitTime);
            },
            modifypermitTime: function () {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                this
                .$axios
                .put('/api/mng/service/reprint-rule/v1/rule', {
                    flagId: "REPRINT",
                    detailValue: this.permitTime,
                    flagValue: this.permitType,
                    
                })
                .then((response) => {
                    if (response.data.success) {
                    this.openAlert('수정이 완료되었습니다.');
                    this.closeModifyModal();
                    this.getRuleList();
                    this.btnLock = false;
                    }
                })
                .catch(() => {
                    this.openAlert('수정중 에러가 발생했습니다.');
                    this.btnLock = false;
                });
            },
        }
    }
</script>
<style scoped="scoped">
    .pop {
        display: inline-block;
        position: unset;
    }
</style>
