<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javscript:void(0)">감사</a>
            </li>
            <li>
                <a href="javscript:void(0)">파일다운로드 이력관리</a>
            </li>
        </div>
        <div class="wapper" style="min-height:700px">
            <div class="title">
                <h2>파일다운로드 이력관리 ({{totalCount | comma}}건)</h2>
                <div class="btn-right">
                    <button class="btn_search" @click="openSearch">검색</button>
                    <button class="btn_download" @click="confirmDownload">다운로드</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">
                            <dl class="full_width">
                                <dt>ID</dt>
                                <dd><input type="text" v-model="userId"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>사용자이름</dt>
                                <dd><input type="text" v-model="userName"/></dd>
                            </dl>                            
                            <dl class="full_width">
                                <dt>파일명</dt>
                                <dd><input type="text" v-model="fileName"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>활용목적</dt>
                                <dd><select v-model="purpose" @change="changePurpose">
                            			<option value="all">전체</option>
                            			<option value="task">업무</option> <!--  task : 업무 -->
                            			<option value="backup">백업</option> <!--  backup : 백업 -->
                            			<option value="etc">기타</option> <!--  etc : 기타 -->
                            		</select></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>다운로드일시</dt>
                                <dd>
                                    <input type='date' v-model='startDate' @change="changeStartDate"/>
                                    ~
                                    <input type='date' v-model='endDate' @change="changeEndDate"/>
                                </dd>
                            </dl>
                        </div>
                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'btn_filered' : filter}"
                                @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                            <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table table_line">
                <table>
                    <tr>
                        <th>ID</th>
                        <th>사용자</th>
                        <th>파일명</th>
                        <th>용량</th>
                        <th>다운로드 일시</th>
                        <th>활용목적</th>
                        <th>기타</th>
                    </tr>
                    <tr v-for="(list, index) in this.historyList" :key="index">
                        <td>{{list.userId}}</td>
                        <td>{{list.userDept}}</td>
                        <td>{{list.fileName}}</td>
                        <td>{{convertSize(list.fileSize)}}</td>
                        <td>{{convertDate(list.downloadDate)}}</td>
                        <td>{{list.purpose}}</td>
                        <td>{{list.purposeEtc}}</td>
                    </tr>
                </table>
            </div>
            <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" 
            :startDate="startDate" :endDate="endDate" 
            :usr="userId" :name="userName" :target="fileName" :purpose="purpose"
            />
        </div>
    </div>

</template>
<script>
    import pagination from '../../components/commonPagination.vue'
    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';
    import $ from 'jquery'

    export default {
        mixins: [mixin],
        data: () => {
            return {historyList: [],
                userId: null,
                userName: null,
                fileName: null,
                purpose: 'all',
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
                filter: false,
                totalCount: 0
            }
        },
        created: function () {
            if(this.$route.query.page) {
                this.changeFilter()
                this.userId = this.$route.query.usr || null;
                this.userName = this.$route.query.name|| null;
                this.fileName = this.$route.query.target|| null;
                this.purpose = this.$route.query.purpose|| null;
                this.startDate = this.$route.query.startDate|| moment().format("YYYY-MM-DD");
                this.endDate = this.$route.query.endDate|| moment().format("YYYY-MM-DD");
                this.getHIstoryList(this.$route.query.page);
            } else {
                this.getHIstoryList(0);
            }
        },
        components : {
            pagination
        },
        filters: {
          comma(val) {
            if (val !== null)
              return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
        methods: {

            getHIstoryList: function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }

                let param = {};
                if (this.filter) {
                    param = {
                        page: page,
                        size: 30,
                        userId: this.userId,
                        userName: this.userName,
                        fileName: this.fileName,
                        purpose: this.purpose,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                } else {
                    param = {
                        page: page,
                        size: 30,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                }

                this
                    .$axios
                    .get('/api/mng/setting/v1/log/down/list', {params: param})
                    .then((response) => {
                        this.historyList = response.data.result.content;

                        let pageInfo = response.data.result;
                        this.totalCount = pageInfo.totalElements;

                        this.pagerHandler(
                            pageInfo.number,
                            pageInfo.first,
                            pageInfo.last,
                            pageInfo.totalPages
                        );
                    })
            },
            firstSearch: function () {
                let pageQuery = {
                    page : 0,
                    usr : this.userId || null,
                    name : this.userName|| null,
                    target : this.fileName|| null,
                    purpose : this.purpose|| null,
                    startDate : this.startDate|| null,
                    endDate : this.endDate|| null
                }
                this.$router.push({
                    path: this.$route.path,
                    query: pageQuery
                }).catch(()=>{});
            },
            confirmDownload: function () {
            	this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
            	this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {

                let param = {};
                if (this.filter) {
                    param = {
                        page: 0,
                        size: 100000,
                        userId: this.userId,
                        userName: this.userName,
                        fileName: this.fileName,
                        purpose: this.purpose,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                } else {
                    param = {
                        page: 0,
                        size: 100000,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                }

                this
                .$axios
                .get('/api/mng/setting/v1/log/down/list/excel', {params: param, responseType: "blob"})
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type']}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '파일다운로드_'+moment().format("YYYYMMDD_HHmmss")+'.xlsx');
                    document.body.appendChild(link);
                    link.click();

                    if(response.status == 200) {
                    	let param = {
                    		fileName: '파일다운로드_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                    		fileSize: response.data.size,
                    		purpose: paramdata.purpose,
                    		purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                    	}
                    	this.setDownloadHistory(param);
                        this.getHIstoryList(0);
                    }
                })
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.userId = null;
                this.userName = null;
                this.fileName = null;
                this.purpose = 'all';
                this.startDate = moment().format("YYYY-MM-DD");
                this.endDate = moment().format("YYYY-MM-DD");
            },
            convertSize: function (size) {
                if (size != null) {
                    return parseFloat(size / 1024).toFixed(2)+'KB';
                }
            },
            changePurpose(event) {
                this.purpose = event.target.value;
            },
            changeStartDate(event) {
                this.startDate = event.target.value;
            },
            changeEndDate(event) {
                this.endDate = event.target.value;
            }
        }

    }
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
</style>
