import Vue from 'vue';
import VueRouter from 'vue-router';
import $ from 'jquery';
import inquiryList from '../view/inquiry/inquiryList.vue';
import inquiryDetail from '../view/inquiry/inquiryDetail.vue';
import inquiryItems from '../view/inquiry/inquiryItems.vue';
import inquiryItemsUpd from '../view/inquiry/inquiryItemsUpd.vue';
import inquirySusik from '../view/inquiry/inquirySusik.vue';
import inquiryPopup from "../view/inquiry/inquiryPopup.vue";

// import inquiryMaster from '../view/inquiry/inquiryMaster.vue'; import
// placeHistory from '../view/placeHistory.vue'; import placeHistory from
// '../view/inquiry/inquiryPlace.vue';
import apiList from '../view/serviceMaster/apiList.vue';
import deviceManage from '../view/deviceManage.vue';

import alertSendRule from '../view/serviceNotice/sendRule.vue';
import alertSendRuleList from '../view/serviceNotice/sendRuleList.vue';
import userList from '../view/user/list.vue';
import userUpd from '../view/user/upd.vue';
import userAdd from '../view/user/add.vue';
import deviceRemote from '../view/device/remote.vue';
import connectionLog from '../view/connection/log.vue';
import authHistory from '../view/connection/authHistory.vue';
import filedownHistory from '../view/connection/filedownHistory.vue';
import searchList from '../view/connection/searchList.vue'
import controlLog from '../view/connection/controlLog.vue';
import partnerManageLog from '../view/connection/partnerManageLog.vue';
import entrancePtNok from '../view/entrance/ptNok.vue';
import detailInout from '../view/entrance/detailInout.vue';
import entranceDeptPartner from '../view/entrance/detp-partner.vue';
import entranceGstList from '../view/entrance/gstList.vue';
import entranceWGstList from '../view/entrance/wGstList.vue'
import permitInquiry from '../view/entrance/permitInquiry.vue';

import exceptionList from '../view/entrance/exceptionList.vue';
import exceptionPermitList from '../view/entrance/exceptionPermitList.vue';

import emmaResidenceTime from '../view/emma/residenceTimeList'
import emmaResidenceTimeMng from '../view/emma/residenceTimeMng'
import emmaResidenceTimeView from '../view/emma/residenceTimeView'
import emmaResidenceAgreeList from '../view/emma/residenceAgreeList'
// import entrancePartnerAdd from '../view/entrance/partnerAdd.vue';
import entranceAuthInfectionMng from '../view/entranceAuth/infectionMng.vue';
import entranceAuthentranceSummary from '../view/entranceAuth/entranceSummary.vue';
import entranceAuthPass from '../view/entranceAuth/pass.vue';
import inquiryNokMng from '../view/entrance/nokMng.vue';
// import inquiryNokMng2 from '../view/entrance/nokMng2.vue';
import partnerGroupList from '../view/entrance/partnerGroupList.vue';
import partnerGroupAdd from '../view/entrance/partnerGroupAdd.vue';
import partnerGroupUpd from '../view/entrance/partnerGroupUpd.vue';
import partnerGroupAddExcel from '../view/entrance/partnerGroupAddExcel.vue';

import partnerEmpList from '../view/entrance/partnerEmpList.vue';
import partnerEmpAdd from '../view/entrance/partnerEmpAdd.vue';
import partnerEmpUpd from '../view/entrance/partnerEmpUpd.vue';
import partnerEmpAddExcel from '../view/entrance/partnerEmpAddExcel.vue';

import login from '../view/login/login.vue';
import resetPw from '../view/login/resetPw.vue';

import disaster from '../view/inquiry/disaster.vue';
import previewKiosk from '../components/previewKiosk';
import placeMange from '../view/placeHistory/placeHistory.vue';

//서비스 관리
import privacyAgreeList from '../view/privacy/privacyAgreeList.vue';
import privacyList from '../view/privacy/privacyList.vue';
import privacyView from '../view/privacy/privacyView.vue';
import privacyMng from '../view/privacy/privacyMng.vue';
import privacyMngUpd from '../view/privacy/privacyMngUpd.vue';
import sensitiveAgreeList from '../view/sensitiveInfo/sensitiveAgreeList.vue';
import sensitiveList from '../view/sensitiveInfo/sensitiveList.vue';
import sensitiveView from '../view/sensitiveInfo/sensitiveView.vue';
import sensitiveMng from '../view/sensitiveInfo/sensitiveMng.vue';
import sensitiveMngUpd from '../view/sensitiveInfo/sensitiveMngUpd.vue';
import feverdetectionList from '../view/serviceMaster/feverdetectionList.vue';
import deviceList from '../view/serviceMaster/deviceList.vue';
import placeList from '../view/serviceMaster/placeList.vue';
import blackList from '../view/serviceMaster/blackList.vue';
import reprintRule from '../view/serviceNotice/reprintRule.vue';

import blackListNotice from '../view/serviceNotice/blackListNotice.vue';
import blackListNoticeUpd from '../view/serviceNotice/blackListNoticeUpd.vue';
import blackListNoticeList from '../view/serviceNotice/blackListNoticeList.vue';
import sendAnalisys from '../view/serviceNotice/sendAnalisys.vue';
import empInfManage from '../view/serviceNotice/empInfManage.vue';
import empInfAdminAdd from '../view/serviceNotice/empInfAdminAdd.vue';
import empInfManageAdd from '../view/serviceNotice/empInfManageAdd.vue';
import empInfExceptAdd from '../view/serviceNotice/empInfExceptAdd.vue';

import placeClass from '../view/serviceMaster/placeClass.vue';
import placeClassMng from '../view/serviceMaster/placeClassMng.vue';
import placeClassUpd from '../view/serviceMaster/placeClassUpd.vue';
import applyDate from '../view/serviceMaster/applyDate.vue';
import diseasesList from '../view/serviceMaster/diseasesList.vue';
import diseasesCodeList from '../view/serviceMaster/diseasesCodeList.vue';
import tempAdjust from '../view/serviceMaster/tempAdjust.vue';
import emmaDeleteList from "@/view/entrance/emmaDeleteList.vue";

//방문신청
import visitor from '../view/visitor/visitor.vue';
import visitorMng from '../view/visitor/visitorMng.vue';
import visitorUpd from '../view/visitor/visitorUpd.vue';
import inquiryResult from '../view/visitor/inquiryResult.vue';
import inquiryIndividual from '../view/visitor/inquiryIndividual.vue';
import visitPatient from '../view/visitor/visitPatient.vue';

import groupEvent from '../view/groupEvent/groupEvent.vue';
import groupEventMng from '../view/groupEvent/groupEventMng';
import groupEventUpd from '../view/groupEvent/groupEventUpd';
import participants from '../view/groupEvent/groupParticipants';
import participantsMng from '../view/groupEvent/groupParticipantsMng';
import participantsUpd from '../view/groupEvent/groupParticipantsUpd';
import participantsExcel from '../view/groupEvent/groupParticipantsExcel.vue';

//감염관리
import inquiryItemsInter from '../view/inquiry/inquiryItemsInter';
import placeHistoryInter from '../view/placeHistory/placeHistoryInter.vue';
import inquiryEmergency from '../view/inquiry/InquiryEmergency.vue';
import inquiryEmergencyItems from '../view/inquiry/inquiryEmergencyItems.vue';
import inquiryEmergencyResult from '../view/inquiry/inquiryEmergencyResult.vue';
import inquiryStatus from "../view/inquiry/inquiryStatus";

// 문진표통계분석 (감염관리)
import statisticsEmplist from '../view/statistics/emplist.vue';
import statisticsEtclist from '../view/statistics/etclist.vue';
import statisticsPtlist from '../view/statistics/ptlist.vue';
import statisticsRnoklist from '../view/statistics/rnoklist.vue';
import statisticsNoklist from '../view/statistics/noklist.vue';
import statisticsGstlist from '../view/statistics/gstlist.vue';
import statisticsIcugstlist from '../view/statistics/icugstlist.vue';
import statisticswgst from '../view/statistics/wgst.vue';

// 출입문진통계 (통계)
import accessStatisticsAnswerType from '../view/accessStatistics/answerType.vue';
import accessStatisticsVisitorType from '../view/accessStatistics/visitorType.vue';
import accessStatisticsAnswerResult from '../view/accessStatistics/answerResult.vue';
import accessStatisticsAnswerResultAb from '../view/accessStatistics/answerResultAb.vue';
import accessStatisticsSusicType from '../view/accessStatistics/susicType.vue';
import accessStatisticsKioskLocation from '../view/accessStatistics/kioskLocation.vue';
import accessStatisticsKioskTicket from '../view/accessStatistics/kioskTicket.vue';
import accessStatisticsSpeedGateEnter from '../view/accessStatistics/speedgateEnter.vue';
import accessStatisticsLocationFeverDetection from '../view/accessStatistics/locationFeverdetection.vue';
import accessStatisticsSpeedGatePassResult from '../view/accessStatistics/speedgatePassResult.vue';
import accessStatisticsVisitorTypeHour from '../view/accessStatistics/visitorTypeHour.vue';
import accessStatisticsKioskTimeType from '../view/accessStatistics/kioskTimeType.vue';
import accessStatisticsEntryPassResult from '../view/accessStatistics/entryPassResult.vue';
import accessStatisticsSpeedGateExit from '../view/accessStatistics/speedgateExit.vue';
import accessStatisticsSpeedGateCheck from '../view/accessStatistics/speedgateCheck.vue';
import accessStatisticsAnswerTypeHour from '../view/accessStatistics/answerTypeHour.vue';
import accessStatisticsKioskTicketHour from '../view/accessStatistics/KioskTicketHour.vue';
import accessStatisticsSpeedGateInTimePlace from '../view/accessStatistics/speedgateInTimePlace.vue';
import accessStatisticsSpeedGateOutTimePlace from '../view/accessStatistics/speedgateOutTimePlace.vue';


Vue.use(VueRouter);

const requireAuth = (value) => async (to, from, next) => {
    console.log("requireAuth : " + value);
    if (from.name !== 'index' && from.name !== 'login' && from.name !== 'inquiry-popup') {
      await Vue.prototype.$axios.get('/api/v1/check/menu', {params: {category: value}})
          .then(response => {
              if (response.data.result == 1) next();
      });

        // $.ajax({
        //     url: '/api/v1/check/menu',
        //     data: { category: value },
        //     headers: { 'Authorization': 'Bearer ' + localStorage.token },
        //     type: 'GET',
        //     success: function(data) {
        //         if (data.result == 1) next()
        //     }
        // })
    } else {
        next()
    }

}

export const router = new VueRouter({
    // mode: 'history',
    routes: [{
            name: 'index',
            path: '/',
            component: login
        }, {
            name: 'resetPw',
            path: '/resetPw',
            component: resetPw
        }, {
            name: 'inq-list',
            path: '/inq-list',
            component: inquiryList,
            beforeEnter: requireAuth('C')
        }, {
            name: 'inq-detail',
            path: '/inq-detail',
            component: inquiryDetail
        }, {
            name: 'inq-items',
            path: '/inq-items',
            component: inquiryItems
        }, {
            name: 'inq-place',
            path: '/inq-place',
            component: placeMange
        }, {
            name: 'inq-susik',
            path: '/inq-susik',
            component: inquirySusik
        }, {
            name: 'device-manage',
            path: '/device-manage',
            component: deviceManage
        }, {
            name: 'inquiry-items-upd',
            path: '/inquiry-items-upd',
            component: inquiryItemsUpd
        }, {
            name: 'privacy-agree-list',
            path: '/privacy/agree-list',
            component: privacyAgreeList,
            beforeEnter: requireAuth('D')
        }, {
            name: 'inquiryItemsInter',
            path: '/inquiry-items-inter',
            component: inquiryItemsInter
        }, {
            name: 'privacy-list',
            path: '/privacy/list',
            component: privacyList,
            beforeEnter: requireAuth('D')
        }, {
            name: 'privacy-mng',
            path: '/privacy/mng',
            component: privacyMng
        }, {
            name: 'privacy-mng-upd',
            path: '/privacy/mng-upd',
            component: privacyMngUpd
        }, {
            name: 'privacy-view',
            path: '/privacy/view',
            component: privacyView
        }, {
            name: 'sensitive-list',
            path: '/sensitive/list',
            component: sensitiveList,
            beforeEnter: requireAuth('D')
        }, {
            name: 'sensitive-mng',
            path: '/sensitive/mng',
            component: sensitiveMng
        }, {
            name: 'sensitive-mng-upd',
            path: '/sensitive/mng-upd',
            component: sensitiveMngUpd
        }, {
            name: 'sensitive-view',
            path: '/sensitive/view',
            component: sensitiveView
        }, {
            name: 'sensitive-agree-list',
            path: '/sensitive/agree-list',
            component: sensitiveAgreeList,
            beforeEnter: requireAuth('D')
        }, {
            name: 'feverdetection-list',
            path: '/service/feverdetection',
            component: feverdetectionList,
            beforeEnter: requireAuth('D')
        }, {
            name: 'user-list',
            path: '/user/list',
            component: userList,
            beforeEnter: requireAuth('A')
        }, {
            name: 'user-upd',
            path: '/user/upd.vue',
            component: userUpd
        }, {
            name: 'user-add',
            path: '/user/add.vue',
            component: userAdd
        }, {
            name: 'device-remote',
            path: '/device/remote',
            component: deviceRemote
        }, {
            name: 'connection-log',
            path: '/connection/log',
            component: connectionLog,
            beforeEnter: requireAuth('D')
        },{
            name: 'auth-History',
            path: '/connection/authHistory',
            component: authHistory,
            beforeEnter: requireAuth('D')
        },{
            name: 'filedown-history',
            path: '/connection/filedownHistory',
            component: filedownHistory,
            beforeEnter: requireAuth('D')
        },{
            name: 'search-list',
            path: '/connection/searchList',
            component: searchList,
            beforeEnter: requireAuth('D')
        },{
            name: 'control-log',
            path: '/connection/controlLog',
            component: controlLog,
            beforeEnter: requireAuth('D')
        }, {
            name: 'partnerManage-log',
            path: '/connection/partnerManageLog',
            component: partnerManageLog,
            beforeEnter: requireAuth('D')
        }, {
            name: 'entrance-pt-nok',
            path: '/entrance/pt-nok',
            component: entrancePtNok,
            beforeEnter: requireAuth('B')
        }, {
            name: 'detail-inout',
            path: '/entrance/detailInout',
            component: detailInout
        }, {
            name: 'partnerGroupList',
            path: '/entrance/partner/list',
            component: partnerGroupList,
            beforeEnter: requireAuth('A')
        }, {
            name: 'partnerGroupAddExcel',
            path: '/entrance/partner/list/add',
            component: partnerGroupAddExcel
        }, {
            name: 'partnerGroupUpd',
            path: '/entrance/partner/upd',
            component: partnerGroupUpd
        }, {
            name: 'partnerGroupAdd',
            path: '/entrance/partner/add',
            component: partnerGroupAdd
        }, {
            name: 'entrance-dept-partner',
            path: '/entrance/dept-partner',
            component: entranceDeptPartner,
            beforeEnter: requireAuth('B')
        }, {
            name: 'entrance-gstList',
            path: '/entrance/gstList',
            component: entranceGstList,
            beforeEnter: requireAuth('B')
        }, {
            name: 'entrance-wgstList',
            path: '/entrance/wGstList',
            component: entranceWGstList,
            beforeEnter: requireAuth('B')
        }, {
            name: 'exceptionList',
            path: '/entrance/exceptionList',
            component: exceptionList,
            beforeEnter: requireAuth('B')
        }, {
            name: 'exceptionPermitList',
            path: '/entrance/exceptionPermitList',
            component: exceptionPermitList,
            beforeEnter: requireAuth('B')
        }, {
            name: 'partnerEmpList',
            path: '/entrance/partner/emp/list',
            component: partnerEmpList
        }, {
            name: 'partnerEmpAdd',
            path: '/entrance/partner/emp/add',
            component: partnerEmpAdd
        }, {
            name: 'partnerEmpUpd',
            path: '/entrance/partner/emp/upd',
            component: partnerEmpUpd
        }, {
            name: 'partnerEmpAddExcel',
            path: '/entrance/partner/emp/upd/excel',
            component: partnerEmpAddExcel
        }, {
            name: 'entranceAuth-infection-mng',
            path: '/entranceAuth/infection-mng',
            component: entranceAuthInfectionMng
        }, {
            name: 'entranceAuth-summary',
            path: '/entranceAuth/summary',
            component: entranceAuthentranceSummary,
            beforeEnter: requireAuth('B')
        }, {
            name: 'entranceAuth-pass',
            path: '/entranceAuth/pass',
            component: entranceAuthPass,
            beforeEnter: requireAuth('B')
        }, {
            name: 'inquriy-nok-mng',
            path: '/inquriy/nok-mng',
            component: inquiryNokMng
        },{
            name: 'reprintrule',
            path: '/service/reprintrule',
            component: reprintRule
        }, {
            name: 'inquiry-emergency',
            path: '/inquriy/emergency',
            component: inquiryEmergency,
            beforeEnter: requireAuth('C')
        }, {
            name: "inquiry-views",
            path: "/inquiry/status",
            component: inquiryStatus,
            beforeEnter: requireAuth('A')
        }, {
            name: "inquiry-popup",
            path: "/inquiryPopup",
            component: inquiryPopup
        }, {
            name: 'inquiry-emergency-item',
            path: '/inquriy/emergency-item',
            component: inquiryEmergencyItems,
            props: true,
        }, {
            name: 'inquiry-emergency-result',
            path: '/inquriy/emergency-result',
            component: inquiryEmergencyResult,
            props: true,
        }, {
            name: 'login',
            path: '/login',
            component: login
        }, {
            name: 'disaster',
            path: '/inquiry/disaster',
            component: disaster
        }, {
            name: 'previewKiosk',
            path: '/preview/kiosk',
            component: previewKiosk
        }, {
            name: 'placeManage',
            path: '/place/manage',
            component: placeMange,
            beforeEnter: requireAuth('C')
        }, {
            name: 'placeClass',
            path: '/service/place-class',
            component: placeClass,
            beforeEnter: requireAuth('C')
        }, {
            name: 'placeClassMng',
            path: '/service/place-class/add',
            component: placeClassMng
        }, {
            name: 'placeClassUpd',
            path: '/service/place-class/upd',
            component: placeClassUpd
        }, {
            name: 'applyDate',
            path: '/service/apply-date',
            component: applyDate,
            beforeEnter: requireAuth('C')
        }, {
            name: 'diseasesCodeList',
            path: '/service/diseases-code',
            component: diseasesCodeList,
            beforeEnter: requireAuth('C')
        }, {
            name: 'diseasesList',
            path: '/service/diseases',
            component: diseasesList,
            beforeEnter: requireAuth('C')
        }, {
            name: 'apiList',
            path: '/service/api-list',
            component: apiList
        }, {
            name: 'deviceList',
            path: '/service/device-list',
            component: deviceList,
            beforeEnter: requireAuth('D')
        }, {
            name: 'placeList',
            path: '/service/place-list',
            component: placeList
        }, {
            name: 'blackList',
            path: '/service/black-list',
            component: blackList
        }, {
            name: 'blackListNotice',
            path: '/service/black-list-notice',
            component: blackListNotice
        }, {
            name: 'blackListNoticeUpd',
            path: '/service/black-list-notice-upd',
            component: blackListNoticeUpd
        }, {
            name: 'blackListNoticeList',
            path: '/service/black-list-notice-list',
            component: blackListNoticeList,
            beforeEnter: requireAuth('D')
        }, {
            name: 'sendAnalisys',
            path: '/service/send-analisys',
            component: sendAnalisys
        }, {
            name: 'empInfManage',
            path: '/service/emp-inf-manage',
            component: empInfManage
        }, {
            name: 'empInfManageAdd',
            path: '/service/emp-inf-manage-add',
            component: empInfManageAdd
        }, {
            name: 'empInfExceptAdd',
            path: '/service/emp-inf-except-add',
            component: empInfExceptAdd
        }, {
            name: 'empInfAdminAdd',
            path: '/service/emp-inf-admin-add',
            component: empInfAdminAdd
        }, {
            name: 'alert-send-rule',
            path: '/service/send-rule',
            component: alertSendRule,
            beforeEnter: requireAuth('D')
        }, {
            name: 'alert-send-rule-list',
            path: '/service/send-rule-list',
            component: alertSendRuleList,
            beforeEnter: requireAuth('D')
        }, {
            name: 'visitor',
            path: '/visit/visitor',
            component: visitor,
            beforeEnter: requireAuth('A')
        }, {
            name: 'visitorMng',
            path: '/visit/visitor-mng',
            component: visitorMng
        }, {
            name: 'visitorUpd',
            path: '/visit/visitor-upd',
            component: visitorUpd
        },  {
            name: 'inquiryResult',
            path: '/visit/inquiry-result',
            component: inquiryResult
        }, {
            name: "inquiryIndividual",
            path: "/visit/inquiryIndividual",
            component: inquiryIndividual,
            props: true,
        },
        {
            name: 'visitPatient',
            path: '/visit/visit-patient',
            component: visitPatient
        },{
            name: 'groupEvent',
            path: '/visit/group-event',
            component: groupEvent,
            beforeEnter: requireAuth('A')
        }, {
            name: 'groupEventMng',
            path: '/visit/group-event/add',
            component: groupEventMng
        }, {
            name: 'groupEventUpd',
            path: '/visit/group-event/upd',
            component: groupEventUpd
        }, {
            name: 'participants',
            path: '/visit/group-prtcpnt',
            component: participants
        }, {
            name: 'participantsMng',
            path: '/visit/group-prtcpnt/add',
            component: participantsMng
        }, {
            name: 'participantsUpd',
            path: '/visit/group-prtcpnt/upd',
            component: participantsUpd
        }, {
            name: 'participantsExcel',
            path: '/visit/group-prtcpnt/excel',
            component: participantsExcel
        }, {
            name: 'placeHistoryInter',
            path: '/place/inter',
            component: placeHistoryInter,
            beforeEnter: requireAuth('C')
        }, {
            name: 'statisticsEmplist',
            path: '/statistics/emplist',
            component: statisticsEmplist,
            beforeEnter: requireAuth('C')
        }, {
            name: 'statisticsEtclist',
            path: '/statistics/etclist',
            component: statisticsEtclist,
            beforeEnter: requireAuth('C')
        }, {
            name: 'statisticsPtlist',
            path: '/statistics/ptlist',
            component: statisticsPtlist,
            beforeEnter: requireAuth('C')
        }, {
            name: 'statisticsRnoklist',
            path: '/statistics/rnoklist',
            component: statisticsRnoklist,
            beforeEnter: requireAuth('C')
        }, {
            name: 'statisticsNoklist',
            path: '/statistics/noklist',
            component: statisticsNoklist,
            beforeEnter: requireAuth('C')
        }, {
            name: 'statisticsIcugstlist',
            path: '/statistics/icugstlist',
            component: statisticsIcugstlist,
            beforeEnter: requireAuth('C')
        }, {
            name: 'statisticswgst',
            path: '/statistics/wgst',
            component: statisticswgst,
            beforeEnter: requireAuth('C')
        }, {
            name: 'statisticsGstlist',
            path: '/statistics/gstlist',
            component: statisticsGstlist,
            beforeEnter: requireAuth('C')
        }, {
            name: 'accessStatisticsAnswerType',
            path: '/accessStatistics/answerType',
            component: accessStatisticsAnswerType,
            beforeEnter: requireAuth('C')
        }, {
            name: 'accessStatisticsVisitorType',
            path: '/accessStatistics/visitorType',
            component: accessStatisticsVisitorType,
            beforeEnter: requireAuth('C')
        }, {
            name: 'accessStatisticsAnswerResult',
            path: '/accessStatistics/answerResult',
            component: accessStatisticsAnswerResult,
            beforeEnter: requireAuth('C')
        }, {
            name: 'accessStatisticsAnswerResultAb',
            path: '/accessStatistics/answerResultAb',
            component: accessStatisticsAnswerResultAb,
            beforeEnter: requireAuth('C')
        }, {
            name: 'accessStatisticsSusicType',
            path: '/accessStatistics/susicType',
            component: accessStatisticsSusicType,
            beforeEnter: requireAuth('C')
        }, {
            name: 'accessStatisticsKioskLocation',
            path: '/accessStatistics/kioskLocation',
            component: accessStatisticsKioskLocation,
            beforeEnter: requireAuth('C')
        }, {
            name: 'accessStatisticsKioskTicket',
            path: '/accessStatistics/kioskTicket',
            component: accessStatisticsKioskTicket,
            beforeEnter: requireAuth('B')
        }, {
            name: 'accessStatisticsSpeedGateEnter',
            path: '/accessStatistics/speedgateEnter',
            component: accessStatisticsSpeedGateEnter,
            beforeEnter: requireAuth('B')
        }, {
            name: 'accessStatisticsLocationFeverDetection',
            path: '/accessStatistics/locationFeverDetection',
            component: accessStatisticsLocationFeverDetection,
            beforeEnter: requireAuth('B')
        }, {
            name: 'accessStatisticsSpeedGatePassResult',
            path: '/accessStatistics/speedGatePassResult',
            component: accessStatisticsSpeedGatePassResult,
            beforeEnter: requireAuth('B')
        }, {
            name: 'accessStatisticsVisitorTypeHour',
            path: '/accessStatistics/visitorTypeHour',
            component: accessStatisticsVisitorTypeHour,
            beforeEnter: requireAuth('C')
        }, {
            name: 'accessStatisticsKioskTimeType',
            path: '/accessStatistics/kioskTimeType',
            component: accessStatisticsKioskTimeType,
            beforeEnter: requireAuth('C')
        }, {
            name: 'accessStatisticsEntryPassResult',
            path: '/accessStatistics/entryPassResult',
            component: accessStatisticsEntryPassResult,
            beforeEnter: requireAuth('B')
        }, {
            name: 'accessStatisticsSpeedGateExit',
            path: '/accessStatistics/speedgateExit',
            component: accessStatisticsSpeedGateExit,
            beforeEnter: requireAuth('B')
        }, {
            name: 'accessStatisticsSpeedGateCheck',
            path: '/accessStatistics/speedgateCheck',
            component: accessStatisticsSpeedGateCheck,
            beforeEnter: requireAuth('B')
        }, {
            name: 'accessStatisticsAnswerTypeHour',
            path: '/accessStatistics/answerTypeHour',
            component: accessStatisticsAnswerTypeHour,
            beforeEnter: requireAuth('C')
        }, {
            name: 'accessStatisticsKioskTicketHour',
            path: '/accessStatistics/kioskTicketHour',
            component: accessStatisticsKioskTicketHour,
            beforeEnter: requireAuth('B')
        }, {
            name: 'tempAdjust',
            path: '/service/temp-adjust',
            component: tempAdjust,
            beforeEnter: requireAuth('D')
        }, {
            name: 'permitInquiry',
            path: '/entrance/permitInquiry',
            component: permitInquiry,
            beforeEnter: requireAuth('B')
        }, {
            name: 'accessStatisticsSpeedGateInTimePlace',
            path: '/accessStatistics/speedgateInTimePlace',
            component: accessStatisticsSpeedGateInTimePlace,
            beforeEnter: requireAuth('C')
        }, {
            name: 'accessStatisticsSpeedGateOutTimePlace',
            path: '/accessStatistics/speedgateOutTimePlace',
            component: accessStatisticsSpeedGateOutTimePlace,
            beforeEnter: requireAuth('C')
        }, {
            name: 'em-inq-list',
            path: '/emma/inq-list',
            component: inquiryList,
        },  {
            name: 'em-place-manage',
            path: '/emma/place-manage',
            component: placeMange,
        }, {
            name: 'residenceTime',
            path: '/emma/residenceTime',
            component: emmaResidenceTime
        }, {
            name: 'residenceTimeMng',
            path: '/emma/residenceTime/mng',
            component: emmaResidenceTimeMng
        }, {
            name: 'residenceTimeView',
            path: '/emma/residenceTime/view',
            component: emmaResidenceTimeView
        }, {
            name: 'emmaResidenceAgreeList',
            path: '/emma/residenceTime/agree',
            component: emmaResidenceAgreeList
        }, {
            name: 'emmaResidenceAgreeList',
            path: '/emma/relation/delete',
            component: emmaDeleteList
        },
    ]
})