<template>

    <div class="contents">
        <div class="loc">
            <li>
              <a v-if="$route.query.masterId === 'ERPER'"
                  @click="() => {this
                            .$router
                            .push({
                                name: 'em-inq-list'})}">문진 관리</a>
              <a v-else
                  @click="() => {this
                            .$router
                            .push({
                                name: 'inq-list'})}">문진 관리</a>

            </li>
            <li>
                <a href="javascript:void(0)">문진 설정</a>
            </li>
        </div>

        <div class="wapper">
            <div class="title">
                <h2>문진 설정 ({{masterIdNm}})</h2>
            </div>
            <div class="setVersion">
                <dl>
                    <dt>게시된 버전</dt>
                    <dd v-if="currentInfo != null">v{{currentInfo.detailVer}}
                        <p>{{convertDate(currentInfo.publishStartDate)}}부터 적용중입니다.</p>
                    </dd>
                    <dd v-else>미등록
                        <p>새 버전을 등록해 주세요.</p>
                    </dd>
                </dl>
                <dl>
                    <dt>다음 버전</dt>
                    <dd v-if="masterInfo[0] && masterInfo[0].nextInfo != null">v{{masterInfo[0].nextInfo.detailVer}}
                        <p v-if="masterInfo[0].nextInfo != null">'{{convertDate(masterInfo[0].nextInfo.publishStartDate)}}부터 자동 적용됩니다.
                        </p>
                    </dd>
                    <dd v-else>미등록
                        <p>새 버전을 등록해 주세요.</p>
                    </dd>
                </dl>
                <dl v-if="masterId !== 'ERPER'">
                    <dt>사용설정</dt>
                    <dd>
                        <!-- <label class="switch"> <input type="checkbox" id="guide_check" checked="">
                        <span class="slider"></span> </label> -->
                        <v-app style="max-height: 20px !important; max-width:46px!important">
                            <v-switch
                                v-model="useYn"
                                readonly
                                color="primary"
                                @click="changeUseYn()"
                                hide-details="hide-details"></v-switch>
                        </v-app>
                        <span class="txt">{{ useYn ? '사용중' : '미사용' }}</span>  
             
                    
                    </dd>
                    
                    <div style="display:inline-block" v-if="reserveDetail.result">    
                        <div class="end-date" v-if="reserveStatus=='on-detail' || reserveStatus=='off-detail'">
                            <p style="font-size:12px;">
                                {{convertDate(reserveDetail.result.targetTime)}}에 자동으로 {{reserveStatus=='off-detail'? "종료" : "시작"}}됩니다. </p>
              
                            <div class="dropdown">
                                <button class="dropbtn">더보기</button>
                                <div class="dropdown_conts">
                                    <a href="javascript:void(0)" @click="inqRuleTimer()">수정하기</a>
                                    <a
                                        href="javascript:void(0)"
                                        @click="delImqRuleComfirm()">취소하기</a>
                                </div>
                            </div>
                        </div>
                        <div v-else @click="inqRuleTimer()">
                            <img src = "@/assets/images/ico_stop-watch.png" style="width: 14px; margin-top: 4px; cursor: pointer;"/>
                            <a>예약{{reserveStatus=='off-show'? "종료" : "시작"}}</a>
                        </div>

                    </div>
                </dl>
                <div class="dropdown">
                    <button class="dropbtn">더보기</button>
                    <div class="dropdown_conts">
                        <a href="javascript:void(0)" @click="modify()">수정하기</a>
                        <a
                            href="javascript:void(0)"
                            @click="masterDelConfirm()"
                            v-if="!isModify">삭제하기</a>
                    </div>
                </div>
            </div>
            <h3>문진구성
                <div class="btn-right">
                    <button class="btn_primary" @click="createInquiry">새 문진 구성 등록</button>
                </div>
            </h3>
            <div class="table table_line">
                <table>
                    <tr>
                        <th>버전</th>
                        <th>상태</th>
                        <th>적용일자</th>
                        <th>마지막 수정</th>
                        <th>수정일시</th>
                        <th>미리보기</th>
                        <th>관리</th>
                    </tr>
                    <tr v-for="(item, index) in detailList" :key="index">
                        <td>{{'V ' + item.detailVer }}</td>
                        <td>{{item.status}}</td>
                        <td>{{ item.status != '임시저장' ? convertDate(item.publishStartDate) : null }}
                            {{ item.status != '임시저장' ? '~' : null }}
                            {{(index >= 1 && item.status != '운영중')?  convertDate(detailList[index - 1].publishStartDate) : null}}</td>
                        <td>{{item.updUser != null ? item.updUser : item.regUser}}</td>
                        <td>{{ convertDate(item.updDate != null ? item.updDate : item.regDate) }}</td>
                        <td>
                            <select @change="preView($event, 'mobile', item.detailId)">
                                <option selected disabled="disabled" value="">사전문진</option>
                                <option v-for="(value, name) in languageList" :value="name" :key="name">{{ value }}</option>
                            </select>
                            <select @change="preView($event, 'kiosk', item.detailId)">
                                <option selected disabled="disabled" value="">현장문진</option>
                                <option v-for="(value, name) in languageList" :value="name" :key="name">{{ value }}</option>
                            </select>
                            <select @change="preView($event, 'paper', item.detailId)">
                                <option selected disabled="disabled" value="">종이문진</option>
                                <option v-for="(value, name) in languageList" :value="name" :key="name">{{ value }}</option>
                            </select>
                        </td>
                        <td>
                            <button class="btn_lang" @click="lang(item.detailId)">다국어 설정</button>
                            <button class="btn_lang" @click="susik(item.detailId)">수식 설정</button>
                            <button
                                class="btn_modify"
                                v-if="item.status == '임시저장'"
                                @click="manage(item.detailId)">수정</button>
                            <button
                                v-if="item.status == '임시저장'"
                                class="btn_del"
                                @click="detailDellConfirm(item.detailVer, item.detailId)">삭제</button>
                        </td>

                    </tr>
                </table>
            </div>
        </div>
        <!-- 팝업 -->
        <modal
            name="addMaster"
            :width="540"
            :height="560"
            :adaptive="true"
            :clickToClose="false">
            <div id="popup_reg" class="pop pop_md pop_reg">
                <!-- 새 문진 등록 -->
                <div class="pop_head">
                    <h2>문진 수정</h2>
                    <a class="btn_pop_close" @click="closePop()">닫기</a>
                </div>
                <div class="pop_body">
                    <dl>
                        <dt>문진코드</dt>
                        <dd>
                            {{masterId}}
                        </dd>
                    </dl>
                    <dl>
                        <dt>문진명</dt>
                        <dd>
                            <input
                                type="text"
                                class="input_full"
                                v-model="masterIdNm"
                                placeholder="문진유형 명 입력"/>
                        </dd>
                    </dl>
                    <dl>
                        <dt>개인정보 처리방침</dt>
                        <dd>
                            <select v-model="privacy">
                                <option value="" disabled="disabled" selected="selected">개인정보 처리 방침을 선택해 주세요.</option>
                                <option v-for="(item, index) in privacyList" :key="index" :value="item.value">
                                    {{item.text}}</option>
                            </select>
                        </dd>
                    </dl>
                    <dl>
                        <dt>민감정보 처리방침</dt>
                        <dd>
                            <select v-model="sensitive">
                                <option value="" disabled="disabled" selected="selected">민감정보 처리 방침을 선택해 주세요.</option>
                                <option v-for="(item, index) in sensitiveList" :key="index" :value="item.value">
                                    {{item.text}}</option>
                            </select>
                        </dd>
                    </dl>
                </div>
                <div class="pop_footer">
                    <button class="btn_secondary" @click="closePop()">취 소</button>
                    <button class="btn_primary" @click="next()">다 음</button>
                </div>
            </div>
        </modal>
        <modal
            name="alertMsg"
            :width="540"
            :height="190"
            :adaptive="true"
            :clickToClose="false">
            <div id="popup_reg" class="pop pop_md">
                <!-- 새 문진 등록 -->
                <div class="pop_head">
                    <h2>알림</h2>
                    <a class="btn_pop_close">닫기</a>
                </div>
                <div class="pop_body">
                    {{errMsg}}
                </div>
                <div class="pop_footer">
                    <button class="btn_secondary" @click="closeAlert()">확 인</button>
                </div>
            </div>
        </modal>
        <modal
            name="inqRuleTimer"
            :width="540"
            :height="190"
            :adaptive="true"
            :clickToClose="false">
            <div id="popup_reg" class="pop pop_md">
                <div class="pop_head">
                    <h2>예약 {{typeText}}</h2>
                    <a class="btn_pop_close" @click="closeInqRuleTimer()">닫기</a>
                </div>
                <div class="pop_body modal-date-picker">
                    설정된 시간에 해당 문진유형이 자동으로 {{typeText}}됩니다.
                    <date-picker style="background-color:transparent" 
                        :dateParam.sync="reserveDate"
                         @dateVal="reserveDateFunc"
                    ></date-picker>
                   <time-picker style="background-color:transparent" 
                        :timeParam = reserveTime
                        @dateVal ="reserveTimeFunc">
                   </time-picker>
                        <!-- <input type="text" v-model="publishStartTimeInput" style="vertical-align:
                        inherit; margin-left:5px"/> -->
                </div>
                <div class="pop_footer">
                    <button class="btn_secondary" @click="closeInqRuleTimer()">취 소</button>
                    <button class="btn_secondary" v-if="typeText == '수정'" @click="putImqRuleTime()">확 인</button>
                    <button class="btn_secondary" v-else @click="setImqRuleTime()">확 인</button>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
    import preivewPaper from '../../components/previewPaper.vue';
    import datePicker from "../../components/datePicker.vue";
    import timePicker from "../../components/datePickerTime.vue";
    import moment from "moment";
    import mixin from '../../mixin/mixin.js';

    export default {
        name:'InqueryDetail',
        data: () => {
            return {
                masterId: '',
                useYn: true,
                masterInfo: [],
                detailList: [],
                disabled: false,
                privacy: "",
                privacyList: [],
                sensitive: "",
                sensitiveList: [],
                masterIdNm: null,
                errMsg: null,
                btnLock: false,
                currentInfo: {},
                reserveDetail: {},
                reserveDate: moment().format("YYYY-MM-DD"),
                reserveTime: moment().format("HH:mm"),
                menu: false,
                menu3: false,
                dialogModal:null,
                lockMasterIdList: [
                    'EMP',
                    'PT',
                    'NOK',
                    'GST',
                    'RNOK',
                    'RPT',
                    'ERPER',
                    'ICUGST',
                    'WGST',
                    'ADMINER',
                    'HSC'
                ],
                languageList: {
                    ko: '한국어',
                    en: '영어',
                    ja: '일본어',
                    zh: '중국어',
                    ru: '러시아어',
                    vi: '베트남어',
                    ar: '아랍어',
                    mn: '몽골어',
                },
            }
        },
        mixins: [mixin],
        components: {
           datePicker,
           timePicker,
        },
        created: function () {
            this.getDetailInfo(this.$route.query.masterId);
            this.masterId = this.$route.query.masterId;
        },
        computed: {
            isModify: function () {
                if (this.masterInfo[0]?.masterId) {
                    for (const item of this.lockMasterIdList) {
                        if (this.masterInfo[0].masterId === item) {
                            return true;
                        }
                    }
                }
                return false;
            },
            reserveStatus: function() {
                if(this.reserveDetail.result) {
                    if(this.useYn){
                        if(this.reserveDetail.result.seq){
                            return "off-detail" // 현재 사용 여부(꺼짐) - 예약 여부 (예약 있음)
                        }else{
                            return "off-show" // 현재 사용 여부(꺼짐) - 예약 여부 (예약 없음)
                        }
                    } else {
                        if(this.reserveDetail.result.seq){
                            return "on-detail" // 현재 사용 여부(사용중) - 예약 여부 (예약 있음)
                        }else{
                            return "on-show" // 현재 사용 여부(사용중) - 예약 여부 (예약 없음)
                        }
                    }
                } else {
                    return ''
                }
                
            },
            typeText(){
                if(this.reserveStatus=='on-show') return '시작'
                else if(this.reserveStatus=='off-show') return '종료' 
                else return '수정'
            }
            
        },
        methods: {
            reserveDateFunc(date){
                this.reserveDate = date
            },
            reserveTimeFunc(date){
                this.reserveTime = date
            },
            setImqRuleTime(){
                this.$axios.post('/api/reserveRule',{
                    targetCode: this.masterId,
                    targetTime: new Date(this.reserveDate + " " + this.reserveTime) ,
                    onOffType : this.reserveStatus == 'off-show' ? "OFF" : "ON"
                }).then(res => {
                    console.log(res)
                    // this.reserveStatus =  this.reserveStatus == 'off-show' ? "on-show" : 'off-show'
                    this.closeInqRuleTimer()
                    // this.getDetailInfo(this.masterId)
                    this.getReserveRule();
                }).catch((err) => {
                    console.log(err);
                });
          
            },
            putImqRuleTime(){
                this.$axios.put('/api/reserveRule',{
                    seq: this.reserveDetail.result.seq,
                    targetCode : this.masterId ,
                    targetTime: new Date(this.reserveDate + " " + this.reserveTime) ,
                    onOffType : this.reserveStatus == 'off-detail' ? "OFF" : "ON"
                }).then(res => {
                    console.log(res)
                    // this.reserveStatus =  this.reserveStatus == 'off-show' ? "on-show" : 'off-show'
                    this.closeInqRuleTimer()
                    // this.getDetailInfo(this.masterId)
                    this.getReserveRule();
                }).catch((err) => {
                    console.log(err);
                });
          
            },
            delImqRuleComfirm(){
                this.openConfirm('예약 스케줄을 취소하시겠습니까?', this.delImqRuleTime)
            },
            delImqRuleTime(){
                this.$axios.delete('/api/reserveRule?seq='+this.reserveDetail.result.seq)
                .then(res => {
                    console.log(res)
                        // this.reserveStatus =  this.reserveStatus == 'off-show' ? "on-show" : 'off-show'
                    this.closeInqRuleTimer()
                        // this.getDetailInfo(this.masterId)
                    this.getReserveRule();
                }).catch((err) => {
                    console.log(err);
                });
                
            },
            getDetailInfo: function (masterId) {
                // console.log(masterId);
                this
                    .$axios
                    .post('/api/mng/inq-detail', {masterId: masterId})
                    .then((response) => {
                        // console.log("getDetailInfo", response.data);
                        // this     .$store     .commit({type: 'setDetailList', detailList:
                        // response.data.detailList}); this     .$store     .commit({type:
                        // 'setMasterInfo', masterInfo: response.data.master}); this     .$store
                        // .commit({type: 'setMasterId', masterId: masterId});

                        this.detailList = response.data.detailList;
                        this.masterInfo = JSON.parse(JSON.stringify(response.data.master));
                        this.currentInfo = this
                            .masterInfo[0]
                            .currntInfo;
                        this.useYn = this
                            .masterInfo[0]
                            .useYn == 'Y'
                                ? true
                                : false;
                        this.masterIdNm = this
                            .masterInfo[0]
                            .masterName;
                        this.privacy = this
                            .masterInfo[0]
                            .privacyCode;

                        this.sensitive = this
                            .masterInfo[0]
                            .sensitiveCode;
                        var disabledArr = this.lockMasterIdList;

                        for (var i = 0; i < disabledArr.length; i++) {
                            if (this.masterId == disabledArr[i]) {
                                console.log(disabledArr[i]);
                                this.disabled = true;
                            }
                        }
                        this.getPrivacyList();
                        this.getSensitiveList();

                        this.getReserveRule();
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            
            getPrivacyList: function () {
                this
                    .$axios
                    .get('/api/mng/privacy')
                    .then((response) => {
                        // console.log(response.data);
                        var list = response.data.result.content;
                        if (list != null) {
                            for (var i = 0; i < list.length; i++) {
                                // console.log(list);
                                var privacy = {
                                    text: list[i].privacyVer,
                                    value: list[i].privacyCode
                                }

                                this
                                    .privacyList
                                    .push(privacy);
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            getSensitiveList: function () {
                this
                    .$axios
                    .get('/api/mng/sensitive', {
                        params: {
                            type: "S"
                        }
                    })
                    .then((response) => {
                        // console.log("sensitive", response.data);
                        var list = response.data.result.content;
                        if (list != null) {
                            for (var i = 0; i < list.length; i++) {
                                // console.log(list);
                                var sensitive = {
                                    text: list[i].privacyVer,
                                    value: list[i].privacyCode
                                }

                                this
                                    .sensitiveList
                                    .push(sensitive);
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },

            getReserveRule: function() {
                this.$axios.get('/api/reserveRule', {
                    params: {
                        targetCode: this.masterId,
                        onOffType: this.useYn ? "OFF":"ON",
                        jobStat: "RESERVE"
                    }
                }).then((response) => {
                    console.log("ReserveRule", response.data.result);
                    this.reserveDetail = response.data;
                    if (response.data.result.targetTime != null) {
                        this.reserveDate = moment(response.data.result.targetTime).format('YYYY-MM-DD')
                        this.reserveTime = moment(response.data.result.targetTime).format('HH:mm')
                    }
                })
            },
            convertDate: function (date) {
                let dateVal = new Date(date);
                let year = dateVal.getFullYear();
                let month = dateVal.getMonth() + 1;

                let day = dateVal.getDate();

                let hour = dateVal.getHours();

                let minute = dateVal.getMinutes();

                let result = year + '-' + month
                    .toString()
                    .padStart(2, '0') + '-' + day
                    .toString()
                    .padStart(2, '0') + ' ' + hour
                    .toString()
                    .padStart(2, '0') + ':' + minute
                    .toString()
                    .padStart(2, '0');

                return result;
            },
            createInquiry: function () {
                for (let detail of this.detailList) {
                    if (detail.status == "임시저장") {
                        this.openAlert("작성중인 임시저장이 있습니다. 해당 임시저장을 저장 또는 삭제한 후 새문진을 등록하여 주세요.");
                        return;
                    }
                }

                let version = '1.0'
                if (this.detailList.length == 0) {
                    console.log("no saved Version")
                    version = '0.9';
                } else {
                    console.log("max Version is " + this.detailList[0].detailVer);
                    version = this
                        .detailList[0]
                        .detailVer;
                }
                this
                    .$router
                    .push({
                        name: 'inq-items',
                        query: {
                            masterId: this.masterId,
                            maxVersion: version
                        }
                    })
            },
            changeUseYn: async function () {
              if (!this.useYn) {
                if (this.detailList.length === 0) {
                  this.openAlert("문진 구성을 등록하여 주세요.");
                  return;
                }
              }
              let yn = this.useYn ? "중단" : "사용";
              let msg = this.masterIdNm + "문진을 " + yn + "하시겠습니까?";
              if (this.reserveDetail.result?.seq) {
                msg = "예약이 존재합니다. 예약 설정을 취소하고 즉시 문진을 " + yn + "하시겠습니까?";
              }
              await this.openConfirm(msg, () => {
                this.useYn = !this.useYn;
                var value = 'Y';
                if (this.useYn) {
                  value = 'Y';
                } else {
                  value = 'N'
                }
                this
                    .$axios
                    .put('/api/mng/v2/inq-master', {
                      masterId: this.masterId,
                      useYn: value
                    })
                    .then((response) => {
                      console.log(response.data);
                    })
                    .catch((err) => {
                      console.log(err.response);
                    }).finally(() => {
                  this.getDetailInfo(this.$route.query.masterId);

                });
              });
            },
            detailDellConfirm: function (detailVer, detailId) {
                this.openConfirm("v" + detailVer + "을 삭제하시겠습니까?", this.detailDell, detailId);

            },
            detailDell: function (detailId) {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                this
                    .$axios
                    .post('/api/mng/inquiry/v1/detail/del', {detailId: detailId})
                    .then((response) => {
                        this.btnLock = false;
                        let masterId = this.$route.query.masterId;
                        console.log(masterId);
                        if (response.data.success) {
                            this.openAlert('삭제가 완료되었습니다.');
                            this.getDetailInfo(masterId);
                        } else {
                            this.openAlert(response.data.resultMsg);
                        }
                    })
                    .catch(() => {
                        this.btnLock = false;
                        this.openAlert('삭제중 오류가 발생했습니다.');
                    })

                },
            modify: function () {
                this
                    .$modal
                    .show('addMaster');
            },
            inqRuleTimer: function () {
              if (!this.useYn) {
                if (this.detailList.length === 0) {
                  this.openAlert("문진 구성을 등록하여 주세요.");
                  return;
                }
              }
              this
                  .$modal
                  .show('inqRuleTimer');
            },
            closeInqRuleTimer: function () {
                this
                    .$modal
                    .hide('inqRuleTimer');
            },
            closePop: function () {
                this.masterIdNm = this
                    .masterInfo[0]
                    .masterName;
                this.privacy = this
                    .masterInfo[0]
                    .privacyCode;
                this
                    .$modal
                    .hide('addMaster');
            },
            masterDelConfirm: function () {
                console.log(this.masterId);
                this.openConfirm(this.masterIdNm + "문진 유형을 삭제하시겠습니까?", this.masterDel)

            },
            masterDel: function () {

                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                this
                    .$axios
                    .post('/api/mng/inquiry/v1/master/del', {masterId: this.masterId})
                    .then((response) => {
                        this.btnLock = false;
                        if (response.data.success) {
                            this.openAlertClosed("삭제가 완료되었습니다.", () => {
                                this
                                    .$router
                                    .push({name: 'inq-list'})
                            });
                        } else {
                            this.openAlert('삭제중 오류가 발생하였습니다.');
                            return;
                        }
                    })
                    .catch(() => {
                        this.btnLock = false;
                        this.openAlert('삭제중 오류가 발생하였습니다.');
                        return;
                    })
                },
            manage: function (detailId) {

                this
                    .$router
                    .push({
                        name: 'inquiry-items-upd',
                        query: {
                            detailId: detailId,
                            masterId: this.masterId
                        }
                    })
            },
            next: function () {
                if (this.masterIdNm == null || this.masterIdNm.trim() == "") {
                    this.masterIdNm = this
                        .masterIdNm
                        .trim();
                    this.errMsg = "문진명을 입력하세요.";
                    this
                        .$modal
                        .show('alertMsg');
                    return;
                }
                if (this.masterIdNm.length > 20) {
                    this.errMsg = "문진명은 20글자를 초과하여 저장할 수 없습니다.";
                    this
                        .$modal
                        .show('alertMsg');
                    return;
                }

                if (this.privacy == null || this.privacy == "") {
                    this.errMsg = "개인정보 처리방침 버전을 선택하세요.";
                    this
                        .$modal
                        .show('alertMsg');
                    return;
                }

                if (this.btnConnYn == "Y") {
                    if (this.btnConnType == null || this.btnConnType == "") {
                        this.errMsg = "버튼을 연결하기 위해선 문진 화면에 연결할 버튼의 유형을 선택하여 주세요.";
                        this
                            .$modal
                            .show('alertMsg');
                        return;
                    }
                    if (this.btnName == null || this.btnName.trim() == "") {
                        this.btnName = this
                            .btnName
                            .trim();
                        this.errMsg = "버튼을 연결하기 위해선 버튼명을 입력하여 주세요.";
                        this
                            .$modal
                            .show('alertMsg');
                        return;
                    }
                    if (this.btnName.trim().length > 20) {
                        this.errMsg = "버튼명은 20글자 이상 입력할 수 없습니다.";
                        this
                            .$modal
                            .show('alertMsg');
                        return;
                    }
                }

                if (this.useInviteCodeYn == "Y") {
                    if (this.inviteCode.length == null || this.inviteCode.trim() == "") {
                        this.inviteCode = this
                            .inviteCode
                            .trim();
                        this.errMsg = "초대 코드를 설정하기 위해서 초대 코드를 입력하여야 합니다";
                        this
                            .$modal
                            .show('alertMsg');
                        return;
                    }

                    if (!/^\d{4}$/.test(this.inviteCode)) {
                        this.errMsg = "초대 코드는 반드시 4자리 숫자여야 합니다.";
                        this
                            .$modal
                            .show('alertMsg');
                        return;
                    }

                }

                let useVal = 'N';
                if (this.useYn) {
                    useVal = 'Y';
                } else {
                    useVal = 'N';
                }

                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                //master 저장
                this
                    .$axios
                    .put('/api/mng/inq-master', {
                        masterId: this.masterId,
                        masterIdNm: this.masterIdNm,
                        privacyCode: this.privacy,
                        btnConnYn: this.btnConnYn,
                        btnName: this.btnName,
                        useInviteCodeYn: this.useInviteCodeYn,
                        inviteCode: this.inviteCode,
                        btnConnType: this.btnConnType,
                        sensitiveCode: this.sensitive,
                        useYn: useVal
                    })
                    .then((response) => {
                        let result = response.data.result;
                        console.log('saveResult', result);
                        this.privacy = result.privacyCode;
                        this.btnConnType = result.btnConnType;
                        this.btnConnYn = result.btnConnYn;
                        this.btnName = result.btnName;
                        this.useInviteCodeYn = result.useInviteCodeYn;
                        this.inviteCode = result.inviteCode;
                        this.masterIdNm = result.masterIdNm;
                        this
                            .$modal
                            .hide("addMaster");

                        this.btnLock = false;
                        this.getDetailInfo(this.$route.query.masterId);
                    })
                    .catch((err) => {
                        console.log(err.response.data);
                        this.btnLock = false;
                    });

            },
            closeAlert: function () {
                this.errMsg = null;
                this
                    .$modal
                    .hide('alertMsg');
            },
            lang: function (detailId) {
                this
                    .$router
                    .push({
                        name: 'inquiryItemsInter',
                        query: {
                            did: detailId,
                            mid:this.masterId,
                        }
                    })

            },
            preView: async function (e, type, detailId) {
              try {
                const response = await this.$axios.get("/api/v1/inquiry/detailid", {
                  params: { detailId, language: e.target.value },
                });
                if (!response.data.success) throw new Error("error");

                switch (type) {
                  case "mobile":
                    this.mobile(response.data.result.categoryList, e.target.value);
                    break;
                  case "kiosk":
                    this.kiosk(response.data.result.categoryList, e.target.value);
                    break;
                  case "paper":
                    this.paper(response.data.result.categoryList, e.target.value);
                    break;
                  default:
                    break;
                }
              } catch (error) {
                this.openAlert("미리보기 화면 로딩 중 오류가 발생하였습니다.");
              }
              e.target.value = "";
            },
            openPreviewPopup: function (
              strWindowFeatures,
              windowName,
              popupHost,
              subUrl,
              categoryList,
              language
            ) {
              let items = Object.assign([], categoryList);
              const inquiryType = this.$route.query.masterId;
              const eventListenerFtn = function (e) {
                if (e.origin === popupHost && e.data.method === "needCategoryData") {
                  e.source.postMessage(
                    {
                      method: e.data.method,
                      categoryList: items,
                      inquiryType,
                      language,
                    },
                    popupHost
                  );
                  window.removeEventListener("message", eventListenerFtn);
                }
              };
              window.addEventListener("message", eventListenerFtn);
              window.open(
                `${popupHost}${subUrl}${new Date().getTime()}`,
                windowName,
                strWindowFeatures
              );
            },
            mobile: function (categoryList, language) {
              const strWindowFeatures =
                "menubar=no,location=no,resizable=no,scrollbars=yes,status=no,width=375,height=712,left=200,top=100";
              const windowName = "ASAN_Mobile_Preview";
              this.openPreviewPopup(
                strWindowFeatures,
                windowName,
                `${window.location.protocol}//${window.location.host}`,
                "/pre/#/inquiry/",
                categoryList,
                language
              );
            },
            kiosk: function (categoryList, language) {
              const strWindowFeatures =
                "menubar=no,location=no,resizable=no,scrollbars=yes,status=no,width=432,height=768,left=200,top=100";
              const windowName = "ASAN_Kiosk_Preview";
              this.openPreviewPopup(
                strWindowFeatures,
                windowName,
                `${window.location.protocol}//${window.location.host}`,
                "/site/#/preview/",
                categoryList,
                language
              );
            },
            paper: function (categoryList, language) {
                console.log(language);
              let items = Object.assign([], categoryList);
              console.log("items", items)
              this.$modal.show(
                preivewPaper,
                {
                  items: items,
                  masterName: this.masterIdNm,
                  masterId: this.$route.query.masterId,
                  language: language
                },
                {
                  width: "1080px",
                  height: "800px",
                  adaptive: true,
                  clickToClose: true,
                }
              );
            },
            susik: function (detailId) {

                this
                    .$router
                    .push({
                        name: 'inq-susik',
                        query: {
                            did: detailId
                        }
                    })

            }
        }
    }
</script>
<style>
.modal-date-picker input.input_cal{
    height: 36px;
    margin-top:0;
    margin-right:8px;
    font-size:14px;
}
.end-date {
    display:flex;
    align-items: center;
}
.setVersion .end-date .dropdown{
    position: relative;
    right: unset;
}
.end-date p{
    margin-right:5px
}
.end-date .dropbtn{
    border: none;
    background-size: contain;
    height: 24px;
    width:24px;
    background-repeat: no-repeat
    
}
.end-date .dropdown:hover .dropbtn {

    border: none;
    background-size: contain;
    height: 24px;
    width:24px;
    background-repeat: no-repeat
    
}
.end-date .dropdown_conts{
    right: unset;
}
</style>
<style scoped="scoped">
    .theme--light.v-application {
        background: #f0f1f6;
        color: rgba(0, 0, 0, 0.87);
        float: left;
    }
    .v-input--selection-controls {
        margin-top: 0;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    #app > div {
        flex: 1 1 auto;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        display: flex;
        flex-direction: column;
        min-height: 0 !important;
        max-width: 0 !important;
        position: relative;
    }
    .pop {
        display: inline-block;
        box-shadow: none;
    }

</style>