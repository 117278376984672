import { render, staticRenderFns } from "./groupEvent.vue?vue&type=template&id=29f2bee0&scoped=true&"
import script from "./groupEvent.vue?vue&type=script&lang=js&"
export * from "./groupEvent.vue?vue&type=script&lang=js&"
import style0 from "./groupEvent.vue?vue&type=style&index=0&id=29f2bee0&prod&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29f2bee0",
  null
  
)

export default component.exports