<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">출입자</a>
            </li>
            <li>
                <a href="javascript:void(0)">방문고객</a>
            </li>
        </div>
        <div class="wapper" style="min-height:700px">
            <div class="title">
                <h2>출입자 관리 (방문고객)</h2>
                <p class="sub_title">{{convertDateDay(new Date())}} 기준 출입대상 (방문고객) 목록입니다.(총
                    {{totalCount}}명)</p>
            </div>
            <h3>
                <div class="btn-right">
                    <button class="btn_search" @click="openSearch()">검색</button>
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">
                            <dl class="full_width">
                                <dt>기간</dt>
                                <dd>
                                    <input type='date' v-model='startDate' @change="changeStartDate"/>
                                    ~
                                    <input type='date' v-model='endDate' @change="changeEndDate"/>
                                </dd>
                            </dl>
                            <dl class="full_width">
                                <dt>휴대전화번호</dt>
                                <dd><input type="text" v-model='phone'/></dd>
                            </dl>
                        </div>
                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'btn_filered' : filter}"
                                @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                            <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
                        </div>
                    </div>
                </div>
            </h3>
            <div class="table table_line">
                <table>
                    <tr>
                        <th>구분</th>
                        <th>휴대전화번호</th>
                        <th>내원일</th>
                        <th>출입일시</th>
                        <th>출입장소</th>
                        <th>퇴실일시</th>
                        <th>퇴실장소</th>
                        <th>출입기록</th>
                    </tr>
                    <tr v-for="(item,index) in this.plist" :key="index">
                        <td>{{item.gubun}}</td>
                        <td>{{convertPhone(item.phone)}}<label v-if="item.blackYn == 'Y'" class="btn_primary btn_md_custom">B</label></td>
                        <td>{{item.visitDate}}</td>
                        <td>{{item.regDateIn}}</td>
                        <td>{{item.placeNameIn}}</td>
                        <td>{{item.regDateOut}}</td>
                        <td>{{item.placeNameOut}}</td>
                        <td><button v-if="item.regDateIn !== null" @click="getInoutDetail(item)">보기</button></td>
                    </tr>
                </table>
            </div>
            <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" 
            :startDate="startDate" :endDate="endDate" :phone='phone'/>
        </div>
    </div>
</template>
<script>
    import pagination from '../../components/commonPagination.vue'

    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';
    import $ from 'jquery'

    export default {
        mixins: [mixin],
        data: () => {
            return {totalCount: 0, plist: [], filter: false, startDate: moment().format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD"), phone: null}
        },
        mounted: function() {
            if(this.$route.query.page) {
                this.changeFilter()
                this.startDate = this.$route.query.startDate || moment.format("YYYY-MM-DD")
                this.endDate = this.$route.query.endDate || moment.format("YYYY-MM-DD")
                this.phone= this.$route.query.phone || null
                this.getGstListResult(this.$route.query.page);
            } else {
                this.getGstListResult(0);
            }
                        
        },
        components : {
            pagination
        },
        methods: {
            getGstListResult: function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }

                let params = {};

                if (this.filter) {
                    params = {
                        page: page,
                        size: 30,
                        gubun: 'GST',
                        startDate: this.startDate,
                        endDate: this.endDate,
                        phone: this.phone
                    }
                } else {
                    params = {
                        page: page,
                        size: 30,
                        gubun: 'GST',
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                }
                this.$axios
                .get('/api/mng/entrance/v1/pass/gst-list', {params: params})
                .then((response) => {
                    this.plist = response.data.result.content;

                    let pageInfo = response.data.result;
                    this.totalCount = pageInfo.totalElements;

                    this.pagerHandler(
                        pageInfo.number,
                        pageInfo.first,
                        pageInfo.last,
                        pageInfo.totalPages
                    );
                })
            },
            firstSearch: function () {
                let pageQuery = {
                    page : 0,
                    startDate:this.startDate|| null,
                    endDate:this.endDate || null,
                    phone:this.phone || null
                }
                this.$router.push({
                    path: this.$route.path,
                    query: pageQuery
                }).catch(()=>{});
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.startDate = moment().format("YYYY-MM-DD");
                this.endDate = moment().format("YYYY-MM-DD");
                this.phone = null;
            },
            changeStartDate(event) {
                this.startDate = event.target.value;
            },
            changeEndDate(event) {
                this.endDate = event.target.value;
            },
            confirmDownload: function () {
                this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {
                let params = {};
                if (this.filter) {
                    params = {
                        page: 0,
                        size: 100000,
                        gubun: 'GST',
                        startDate: this.startDate,
                        endDate: this.endDate,
                        phone: this.phone
                    }
                } else {
                    params = {
                        page: 0,
                        size: 100000,
                        gubun: 'GST',
                        startDate: this.startDate,
                        endDate: this.endDate,
                    }
                }
                this.$axios
                .get('/api/mng/entrance/v1/pass/gst-list/excel', {params: params, responseType: "blob"})
                .then((response) => {
                    console.log(response);
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type']}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '방문고객출입_'+moment().format("YYYYMMDD_HHmmss")+'.xlsx');
                    document.body.appendChild(link);
                    link.click();

                    if(response.status == 200) {
                        let param = {
                        fileName: '방문고객출입_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                        fileSize: response.data.size,
                        purpose: paramdata.purpose,
                        purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                    }
                        this.setDownloadHistory(param);
                    }
                })
            },
            getInoutDetail: function(item) {
                this.$router.push({
                    name: 'detail-inout',
                    params: {
                        userId: item.identityNo,
                        visitDate: item.visitDate,
                        name: item.kor_nm
                    }
                });
            }
        }
    }
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
    .btn_md_custom {
        padding: 0 5px;
        min-width: auto;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        display: inline;
        font-weight: 600;
        color: #ffffff;
        border: 1px solid transparent
    }
</style>
