<template>
    <div id="wrapper">
        <div id="contents">
            <!-- 로그인 -->
            <section id="loginArea">
                <h1 class="logo_login">아산</h1>
                <li><input
                    type="text"
                    placeholder="사번"
                    label="Username"
                    v-model="username"
                    required="required"/></li>
                <li><input
                    type="password"
                    placeholder="비밀번호"
                    required="required"
                    label="Password"
                    v-model="password"
                    @keydown.enter="loginSubmit()"/></li>
                <li><input type="checkbox" id="idsave" name="idsave" v-model="saveId"/>
                    <label for="idsave">
                        <span>
                            ID 저장하기</span>
                    </label>
                </li>
                <li>
                    <button
                        style="margin: 0"
                        class="btn_primary"
                        type="submit"
                        @click="loginSubmit()">로그인</button>
                </li>
                <li>
                    <button
                        class="btn_secondary"
                        type="submit"
                        @click="$router.push({name:'resetPw'})">비밀번호 변경 및 초기화</button>
                </li>
                <li v-if="devYn">
                    본 서비스는 테스트/개발 목적입니다.<br/>
                    <a style="color: blue; text-decoration:underline;" href="https://admin-smartpass.amc.kr/admin">https://admin-smartpass.amc.kr/admin</a> <br/>을 이용해주세요.
                </li>
                <!-- <div class="login_bottom"> <li> <a href="#">도움말</a> </li> </div> -->
            </section>
            <!-- #loginArea -->
            <!-- //로그인 -->
        </div>
    </div>
</template>
<script>

    import mixin from '../../mixin/mixin.js';
    export default {
        data: () => {
            return {username: '', password: '', saveId: false, devYn: true}
        },
        mounted: function () {
            console.log("mounted");
            if("aemsd1.amc.kr" == window.location.host) this.devYn = true;
            else this.devYn = false;
            console.log(window.location.host)
        },
        mixins: [mixin],
        watch: {
            username: function (newVal) {
                let id = newVal;
                id = id.toUpperCase();
                if (id.length > 7) {
                    id = id.substring(0, 7);
                }
                id = id.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '');
                id = id.replace(/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi, '');
                id = id.replace(/(\s*)/g, "");
                this.username = id;
            }
        },
        created: function () {
            let setId = localStorage.setId;
            if (setId != null) {
                this.username = setId;
                this.saveId = true;
            }
             localStorage.removeItem('token');
             localStorage.removeItem('permission');
             this.korName = null;
        },
        methods: {
            loginSubmit: function () {

                if (this.username == null || this.username == '') {
                    this.openAlert("아이디를 입력하세요.");
                    return;
                }
                if (this.password == null || this.password == '') {
                    this.openAlert("비밀번호를 입력하세요.");
                    return;
                }               

                this
                .$axios
                .post('/api/v1/sign-in', {
                    username: this.username,
                    password: this.password
                })
                .then((response) => {
                    console.log(response.data);

                     if(this.password === 'Asan1357!'){
                        this.openAlert("초기 비밀번호입니다. 새로운 비밀번호로 변경해 주세요.");
                        this.$router.push({name:'resetPw'});
                        return;                
                    }


                    if (response.data.resultCd == "0000") {
                        let token = response.data.token;
                        let permission = response.data.permission;
                        if (token != null || token != "") {
                            //vue store와 브라우저 쿠키(새로고침시 store초기화 예방)에 토큰정보 저장,
                            this.$store.commit('setToken', {token: `${token}`})
                            localStorage.setItem('token', `${token}`);
                            localStorage.setItem('permission', permission);
                            //this.$axios.defaults.headers.common["Authorization"] = `Bearer + ${token}`;
                            this.$store.commit({type: 'setToken', token: response.data.token});

                            if (this.saveId) {
                                localStorage.setItem('setId', this.username);
                            } else {
                                localStorage.removeItem('setId');
                            }
                            if(permission.indexOf("A") !== -1){
                                this.$router.push({name: 'visitor'});
                              
                                return;
                            }
                            if(permission.indexOf("B") !== -1){
                                this.$router.push({name: 'entranceAuth-summary'});
                              
                                return;
                            }
                            if(permission.indexOf("C") !== -1){
                                this.$router.push({name: 'inq-list'});
                                
                                return;
                            }
                            if(permission.indexOf("D") !== -1){
                                this.$router.push({name: 'user-list'});
                                
                                return;
                            }
                        }

                    }

                })
                .catch((err) => {

                    if (err.response) {
                        console.log(err.response);
                         if (err.response.status == 400) {
                             if(err.response.data.failCnt == null) {
                                  this.openAlert("로그인 정보를 잘못 입력하셨거나 권한이 있는 직원만 로그인 가능합니다. </br>사용 권한은 부서장에게 확인하세요");
                                  return;
                             } else {
                                  this.openAlert(err.response.data.resultMsg);
                                return;
                             }                           
                        }
                        if (err.response.data.resultCd == "9999") {
                            this.openAlert(err.response.data.resultMsg);
                            return;
                        }
                        if (err.response.status == 500) {
                            this.openAlert("서버와 통신이 원활하지 않습니다. 잠시후 다시 시도해 주세요.");
                            return;
                        }
                        if (err.response.status == 401) {
                            this.openAlert(err.response.data);
                            return;
                        }
                    }
                    this.openAlert("로그인 시도중 에러 발생. 다시 시도해 주세요.");
                })

            },
            setMenus: function () {

            }
        }
    }
</script>
