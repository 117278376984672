<template>
    <div class="pop">
        <div class="pop_head">
            <h2>다운로드</h2>
            <a class="btn_pop_close" href="javascript:void(0)" @click="close">닫기</a>
        </div>
        <div class="pop_body">
            <div class="pop_reg">
                <dl>
                    활용목적
                </dl>
                <dl>
                    <select class="width_fix" v-model="purpose" @change="changePurpose">
                        <option value="task">업무</option> <!--  task : 업무 -->
                        <option value="backup">백업</option> <!--  backup : 백업 -->
                        <option value="etc">기타</option> <!--  etc : 기타 -->
                    </select>
                </dl>
            </div>
            <div class="pop_reg">
                <dl></dl>
                <dl>
                    <input class="width_fix" ref="etcInput" v-model="purpose_etc" v-show="etcShow" type="text" placeholder="활용목적을 입력해 주세요."/>
                </dl>
            </div>
            <div class="pop_reg">
                <dl>다운로드</dl>
                <dl>
                    <button class="btn_primary width_fix" @click="okBtn">다운로드</button>
                </dl>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {purpose: null, purpose_etc: null, etcShow: false}
        },
        props: [
            'callback','callbackParam'
        ],
        methods: {
            okBtn() {
                let param =  {
                    purpose: this.purpose,
                    purpose_etc: this.purpose_etc,
                    etcShow: this.etcShow
                }
                if('' !== param.purpose && null !== param.purpose) {
                    this.callback(param);
                    this.$emit('close');
                }
            },
            close() {
                this.$emit('close');
            },
            changePurpose(event) {
                console.log(event.target.value);
                this.purpose = event.target.value;
                if("etc" == this.purpose) {
                    this.etcShow = true;
                    this.setFocus();
                } else {
                    this.purpose_etc = '';
                    this.etcShow = false;
                }
            },
            setFocus: function() {
                setTimeout(() => {
                    this.$refs.etcInput.focus();
                }, 1);
            }
        }
    }
</script>
<style scoped="scoped">
    .pop {
        display: inline-block;
        position: fixed;
        left: 45%;
        top: 40%;
        color: #393939;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 12px 14px 0 rgb(0 0 0 / 20%);
    }
    .pop_reg {
        display: flex;
        flex-direction: row;
    }
    .pop_reg dl {
        margin-bottom: 0px !important;
        padding: 10px 0px;
        border-bottom: 0px;
    }
    .width_fix {
        width: 180px;
    }
</style>
