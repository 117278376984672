<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">알림서비스</a>
            </li>
            <li>
                <a href="javascript:void(0)">블랙리스트내원알림</a>
            </li>
        </div>
        <div class="wapper" style="min-height:800px">
            <div class="title">
                <h2>블랙리스트내원알림</h2>
            </div>
            <h3>
                <div class="btn-right">
                    <button class="btn_search" @click="openSearch()">검색</button>
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                    <button class="btn_primary" @click="addReceiver()">신규등록</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">
                            <dl class="full_width">
                                <dt>등록일자</dt>
                                <dd>
                                    <input type='date' v-model='startDate' @change="changeStartDate"/>
                                    ~
                                    <input type='date' v-model='endDate' @change="changeEndDate"/>
                                </dd>
                            </dl>
                            <dl class="full_width">
                                <dt>부서명</dt>
                                <dd><input type="text" v-model="deptNm"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>이름</dt>
                                <dd><input type="text" v-model="receiverNm"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>수신유형</dt>
                                <dd>
                                    <select v-model="receiveType">
                                        <option value="all">전체</option>
                                        <option value="A">모든출입</option>
                                        <option value="O">최초1회</option>
                                    </select>
                                </dd>
                            </dl>
                            <dl class="full_width">
                                <dt>사용여부</dt>
                                <dd><select v-model="useYn" @change="changeUseYn">
                                    <option value='all'>전체</option>
                                    <option value='Y'>사용</option>
                                    <option value='N'>미사용</option>
                                </select></dd>
                            </dl>
                        </div>
                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'btn_filered' : filter}"
                                @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                            <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
                        </div>
                    </div>
                </div>
            </h3>
            <div class="table table_line">
                <table>
                    <tr>
                        <th>등록일자</th>
                        <th>부서명</th>
                        <th>이름</th>
                        <th>수신유형</th>
                        <th>사용여부</th>
                        <th>관리</th>
                    </tr>
                    <tr v-for="(item, index) in this.receiverList" :key="index">
                        <td>{{convertDate(item.regDate)}}</td>
                        <td>{{item.deptNm}}</td>
                        <td>{{item.receiverNm}}</td>
                        <td>{{item.receiveType == "A" ? '모든 출입' : '최초 1회 출입'}}</td>
                        <td>{{item.useYn ? '사용': '미사용'}}</td>
                        <td><button @click="modify(item.seq)" class="btn-primary">수정</button></td>

                    </tr>

                </table>
            </div>
            <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" 
            :startDate="startDate" :endDate="endDate" 
            :deptName="deptNm" :name="receiverNm" :type="receiveType" :status="useYn"   
            />
        </div>
    </div>
</template>
<script>
    import pagination from '../../components/commonPagination.vue'
    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';
    import $ from 'jquery'

    export default {
        data: () => {
            return {receiverList: [],
                startDate: moment().add(-1, 'y').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
                deptNm: null,
                receiverNm: null,
                receiveType: 'all',
                useYn: 'all',
                filter: false
            }
        },
        mixins: [mixin],
        created: function () {
            if(this.$route.query.page) {
                this.changeFilter() //검색 param을 넣기 위해서 필요
                this.deptNm = this.$route.query.deptName|| null
                this.receiverNm = this.$route.query.name || null
                this.receiveType = this.$route.query.type|| null
                this.useYn = this.$route.query.status|| null
                this.startDate = this.$route.query.startDate|| moment().add(-1, 'y').format("YYYY-MM-DD");
                this.endDate = this.$route.query.endDate|| moment().format("YYYY-MM-DD");
                this.getList(this.$route.query.page);
            } else {
                this.getList(0);
            }
        },
        components : {
            pagination
        },
        methods: {
            getList: function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }
                let param = {};

                if (this.filter) {
                    param = {
                        page: page,
                        size: 30,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        deptNm: this.deptNm,
                        receiverNm: this.receiverNm,
                        receiveType: this.receiveType,
                        useYn: this.useYn
                    }
                } else {
                    param = {
                        page: page,
                        size: 30,
                        startDate: this.startDate,
                        endDate: this.endDate,
                    }
                }
                this
                .$axios
                .get('/api/mng/service/send-rule/v1/amcblk', {
                    params: param
                })
                .then((response) => {
                    if (response.data.success) {
                        this.receiverList = response.data.result.content;
                        let pageInfo = response.data.result;
                        this.pagerHandler(
                            pageInfo.number,
                            pageInfo.first,
                            pageInfo.last,
                            pageInfo.totalPages
                        );
                    } else {
                        this.openAlert("리스트 조회에 실패했습니다.");
                    }
                })
                .catch(() => {
                    this.openAlert("리스트 조회에 실패했습니다.");
                });

            },
            addReceiver: function () {
                this
                    .$router
                    .push({name: 'blackListNotice'});
            },
            modify:function(seq) {
                this.$router.push({
                        name: 'blackListNoticeUpd',
                        query: {
                                bid : window.btoa(seq)
                        }
                })
            },
            firstSearch: function () {
                let pageQuery = {
                    page : 0,
                    deptName : this.deptNm|| null,
                    name : this.receiverNm || null,
                    type : this.receiveType|| null,
                    status : this.useYn|| null,
                    startDate : this.startDate|| null,
                    endDate : this.endDate|| null
                }
                this.$router.push({
                    path: this.$route.path,
                    query: pageQuery
                }).catch(()=>{});
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.startDate = moment().add(-1, 'y').format("YYYY-MM-DD");
                this.endDate = moment().format("YYYY-MM-DD");
                this.deptNm = null;
                this.receiverNm = null;
                this.receiveType = 'all';
                this.useYn = 'all';
            },
            changeStartDate(event) {
                this.startDate = event.target.value;
            },
            changeEndDate(event) {
                this.endDate = event.target.value;
            },
            changeUseYn(event) {
                this.useYn = event.target.value;
            },
            confirmDownload: function () {
                this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {
                let param = {};

                if (this.filter) {
                    param = {
                        page: 0,
                		size: 100000,
                		startDate: this.startDate,
                		endDate: this.endDate,
                		deptNm: this.deptNm,
                		receiverNm: this.receiverNm,
                		receiveType: this.receiveType,
                		useYn: this.useYn
                    }
                } else {
                    param = {
                        page: 0,
                        size: 100000,
                    }
                }
                this
                .$axios
                .get('/api/mng/service/send-rule/v1/amcblk/excel', {
                    params: param,
                    responseType: "blob"
                })
                .then((response) => {
                    console.log(response);
                    const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download','블랙리스트내원알림_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx');
                    document.body.appendChild(link);
                    link.click();

                    if(response.status == 200) {
                          let param = {
                              fileName: '블랙리스트내원알림_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                              fileSize: response.data.size,
                              purpose: paramdata.purpose,
                              purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                          }
                          this.setDownloadHistory(param);
                    }
                })
            }
        }
    }
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
</style>
