<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">알림서비스</a>
            </li>
            <li>
                <a href="javascript:void(0)">직원발열관리및조회</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>직원발열관리및조회</h2>

            </div>

            <div class="tabArea">
                <input id="tab1" type="radio" name="tabs" checked="checked" ref="tab1"/>
                <label for="tab1">직원발열 조회 및 미실시수신 관리</label>
                <input id="tab2" type="radio" name="tabs" ref="tab2"/>
                <label for="tab2">관리자사번</label>
                <input id="tab3" type="radio" name="tabs" ref="tab3"/>
                <label for="tab3">직원발열 제외 사번</label>
               
                <emp-receiver id="tab_content1"></emp-receiver>
                <emp-admin id="tab_content2"></emp-admin>
                <emp-except id="tab_content3"></emp-except>
            </div>
        </div>
    </div>
</template>

<script>
    import empReceiver from '../../components/empInfReceiver.vue';
    import empAdmin from '../../components/empInfAdmin.vue';
    import empExcept from '../../components/empInfExcept.vue';
    export default {
        components: {
            'emp-receiver': empReceiver,
            'emp-admin': empAdmin,
            'emp-except': empExcept
        },
        mounted: function(){
            if(this.$route.query.tid) {
                let tid = window.atob(this.$route.query.tid);
                console.log('tab' + tid);
                console.log(this.$refs['tab' + tid]);
                this.$refs['tab' + tid].checked = true;
            }   
        }
    }
</script>