<template>
    <div id="popup_reg" class="pop pop_md">

        <div class="pop_head">
            <h2>발열감지알림수신자변경</h2>
            <a class="btn_pop_close" href="javascript:void(0)" @click="close">닫기</a>
        </div>
        <div class="pop_body">
                    <dd>
                        <input
                            type="text"
                            v-model="partner_reg_deptNm"
                            placeholder="부서"
                            readonly="readonly"
                            style="width:60%;"/>
                    </dd>
                    <dd>
                        <input
                            type="text"
                            v-model="partner_reg_userNm"
                            placeholder="이름"
                            readonly="readonly"
                            style="width:60%;"/>
                        <button class="btn_secondary" @click="openSearchUser()">검색</button>
                    </dd>
        </div>
        <div class="pop_footer">
            <button class="btn_secondary" @click="cancelBtn">아니오</button>
            <button class="btn_secondary" @click="okBtn">네</button>
        </div>
    </div>
</template>
<script>
    import searchUser from '../../components/userSearch.vue';
    export default {
        data: () => {
            return {
                partner_reg_userNm: null,
                partner_reg_deptNm: null,
                partner_reg_userId: null,
                partner_reg_deptCd: null
            }
        },
        props:['searchUserConf'],
        mounted(){
            this.openSearchUser()
        },
        methods: {
            cancelBtn() {
                this.$emit('close');
            },
            okBtn() {
                this.searchUserConf(this.partner_reg_userId, this.partner_reg_userNm);
                this.$emit('close');
            },
            close() {
                this.$emit('close');
            },
            openSearchUser: function () {
                this
                    .$modal
                    .show(searchUser, {
                        getSearchUserInfo: this.getSearchUserInfo
                    }, {
                        width: '640px',
                        height: 'auto',
                        adaptive: true,
                        clickToClose: false
                    },)
            },
            getSearchUserInfo: function (user, dept) {
                this.partner_reg_userId = user;
                this.partner_reg_deptCd = dept;
                this.getDetpUser();
            },
            getDetpUser: function () {
                this
                    .$axios
                    .get('/api/mng/common/v1/dept-user', {
                        params: {
                            userId: this.partner_reg_userId,
                            deptCd: this.partner_reg_deptCd
                        }
                    })
                    .then((response) => {
                        if (response.data.success) {
                            this.partner_reg_userNm = response.data.result.korNm;
                            this.partner_reg_deptNm = response.data.result.deptNm;
                        } else {
                            this.openAlert("사용자 정보를 가져오지 못했습니다.")
                        }

                    })
                    .catch(() => {
                        this.openAlert("사용자 정보를 가져오지 못했습니다.")
                    })
                },
        }
    }

</script>
<style scoped="scoped">
    .pop {
        display: inline-block;
        position: unset;
    }
</style>