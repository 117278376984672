<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">방문신청</a>
            </li>
            <li>
                <a href="javascript:void(0)">집합행사관리</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>참석자 등록</h2>
            </div>
            <div class="register">
                <h3>개별등록</h3>
                <dl>
                    <dt>이름</dt>
                    <dd><input v-model="participantsName" type="text"/></dd>
                </dl>
                <dl>
                    <dt>휴대폰번호</dt>
                    <dd><input v-model="participantsPhone" type="text"/></dd>
                </dl>
            </div>
            <div class="btn_wrap">
                <div class="btn_left">
                    <button class="btn_secondary" @click="cancel()">취 소</button>
                    <button class="btn_primary" @click="confirmSave()">저 장</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import mixin from '../../mixin/mixin.js';
    export default {
        data: () => {
            return {participantsName: null, participantsPhone: ''}
        },
        watch: {
            participantsPhone: function (newVal) {
                let convert = newVal.replaceAll("-", "");

                if (convert.length > 11) {
                    convert = convert.substring(0, 11);
                }

                convert = convert.replace(
                    /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/,
                    '$1-$2-$3'
                );

                this.participantsPhone = convert
            }
        },
        mixins: [mixin],
        methods: {
            confirmSave() {
                let reg = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/
                if (this.participantsName == null) {
                    this.openAlert("이름은 필수 입니다.");
                    return;
                }
                if (this.participantsName.length > 20) {
                    this.openAlert("이름은 20자를 넘을 수 없습니다.");
                    return;
                }
                if (this.participantsPhone == null) {
                    this.openAlert("휴대폰번호는 필수 입니다.");
                    return;
                }

                if (!reg.test(this.participantsPhone)) {
                    this.openAlert("올바른 휴대전화 번호를 입력하세요.");
                    return;
                }

                let dupPhone = false;
                let phone = this.participantsPhone;
                phone = phone.replaceAll('-','');
                for (let i = 0; i < this.$route.query.list.length; i++) {
                    const item = this.$route.query.list[i];
                    if(item.participantsPhone == phone) dupPhone = true;
                }

                if(dupPhone) {
                    this.openAlert("중복된 휴대폰번호가 있습니다.");
                    return;
                }

                this.openConfirm('참석자를 저장하시겠습니까?', this.save);
            },
            save: function () {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                this
                    .$axios
                    .post('/api/mng/group-event/v1/participants', {
                        meetingId: window.atob(this.$route.query.mid),
                        participantsNm: this.participantsName,
                        participantsPhone: this.participantsPhone
                    })
                    .then((response) => {

                        if (response.data.success) {
                            this.openAlertClosed("저장이 완료되었습니다.", this.cancel);
                        }
                        this.btnLock = false;

                    })
                    .catch(() => {
                        this.openAlert('저장중 오류가 발생했습니다.');
                        this.btnLock = false;
                    });
            },

            cancel: function () {
                this
                    .$router
                    .go(-1)
                    // .push({name: 'participants', query: this.$route.query})
            },
            initSiteVal: function () {
                this.btnName = null;
                this.inviteCode = null;
            }
        }
    }
</script>
