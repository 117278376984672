import { render, staticRenderFns } from "./privacyList.vue?vue&type=template&id=6916669d&scoped=true&"
import script from "./privacyList.vue?vue&type=script&lang=js&"
export * from "./privacyList.vue?vue&type=script&lang=js&"
import style0 from "./privacyList.vue?vue&type=style&index=0&id=6916669d&prod&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6916669d",
  null
  
)

export default component.exports