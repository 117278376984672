<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">사용자</a>
            </li>
            <li>
                <a href="javascript:void(0)">권한관리</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>사용자 권한 수정</h2>
            </div>
            <div class="register">
                <dl>
                    <dt>소속/이름/직급</dt>
                    <dd>
                        {{this.deptNm +" "+this.userNm+" "+this.jobposNm}}
                    </dd>
                </dl>
                <dl>
                    <dt>사번</dt>
                    <dd>{{this.userId}}</dd>
                </dl>
                <dl>
                    <dt>권한설정</dt>
                    <dd>
                        <ul>
                            <dl>
                                <dt><b>사용권한</b></dt>
                                <dd>
                                     <select v-model="authority">
                                        <option value="ROLE_USER">일반사용자</option>
                                        <option value="ROLE_DEPT">권한대행</option>
                                        <option v-if="(role ? role == 'ROLE_DIAG' : false) || (role ? role == 'ROLE_ADMIN' : false)" value="ROLE_DIAG">진료지원실</option>
                                        <option v-if="role ? role == 'ROLE_ADMIN' : false" value="ROLE_ADMIN">관리자</option>
                                     </select>
                                </dd>
                            </dl>
                            <dl>
                                <dt>방문신청</dt>
                                <dd>
                                    <input v-model="categoryCode" type="checkbox" id="categoryCode_au" value="A" />
                                    <label for="categoryCode_au"><span></span></label>
                                </dd>
                            </dl>
                            <dl v-if="role == 'ROLE_DIAG' || role == 'ROLE_ADMIN'">
                                <dt>출입관리</dt>
                                <dd>
                                    <input v-model="categoryCode" type="checkbox" id="categoryCode_pu" value="B" />
                                    <label for="categoryCode_pu"><span></span></label>
                                </dd>
                            </dl>
                            <dl v-if="role == 'ROLE_DIAG' || role == 'ROLE_ADMIN'">
                                <dt>감염관리</dt>
                                <dd>
                                    <input v-model="categoryCode" type="checkbox" id="categoryCode_iu" value="C" />
                                    <label for="categoryCode_iu"><span></span></label>
                                </dd>
                            </dl>
                            <dl v-if="role == 'ROLE_DIAG' || role == 'ROLE_ADMIN' || myPermission.includes('F')">
                            <dt>응급관리</dt>
                            <dd>
                              <input v-model="categoryCode" type="checkbox" id="categoryCode_em" value="F" />
                              <label for="categoryCode_em"><span></span></label>
                            </dd>
                          </dl>
                            <dl v-if="role == 'ROLE_DIAG' || role == 'ROLE_ADMIN'">
                                <dt>서비스관리</dt>
                                <dd>
                                    <input v-model="categoryCode" type="checkbox" id="categoryCode_su" value="D" />
                                    <label for="categoryCode_su"><span></span></label>
                                </dd>
                            </dl>
                            <dl v-if="role == 'ROLE_DIAG' || role == 'ROLE_ADMIN'">
                                <dt>통계</dt>
                                <dd>
                                    <input v-model="categoryCode" type="checkbox" id="categoryCode_smu" value="E" />
                                    <label for="categoryCode_smu"><span></span></label>
                                </dd>
                            </dl>
                             <dl>
                                <dt>엑셀다운로드 권한</dt>
                                <dd>
                                    <input v-model="isDownload" type="checkbox" id="downloadAuth"/>
                                    <label for="downloadAuth"><span></span></label>
                                </dd>
                            </dl>
                        </ul>
                    </dd>
                </dl>
            </div>
            <div class="btn_wrap">
                <div class="btn_left">
                    <button class="btn_secondary" @click="goList">취 소</button>
                    <button class="btn_primary" @click="saveConfirm">저 장</button>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    import searchUser from '../../components/userSearch.vue';
    import mixin from '../../mixin/mixin.js';

    export default {
        data: () => {
            return {userNm: "",
              deptNm: "",
              jobposNm: "",
              userId: null,
              deptCd: null,
              emailAddr: null,
              categoryCode: [],
              authority: 'ROLE_USER',
              isDownload : false,
              userPermission : [],
              myPermission : [],
            }
        },
        mixins: [mixin],
        created: function () {
           if(!this.$route.params.userId) {
            alert('잘못된 접근입니다.')
            this.$router.go(-1)
           }
        },
        mounted: function() {
            this.userId = this.$route.params.userId
            let permission = localStorage.permission;
            let ps = JSON.stringify(permission.split(''));
            this.myPermission = JSON.parse(ps)
            this.myPermission.sort();
            this.userPermission = this.$route.params.permission
            this.deptNm = this.$route.params.deptNm
            this.userNm = this.$route.params.userNm
            this.jobposNm = this.$route.params.jobposNm
            this.$route.params.authority ? this.authority = this.$route.params.authority : 'ROLE_USER'
            if(this.$route.params.permission) {
                 console.log(this.permission)
                 let ps = JSON.stringify(this.userPermission.split(''));
                this.categoryCode = JSON.parse(ps)
            } 
            this.isDownload = this.$route.params.download === 'Y' ? true : false;
            this.getMyinfo();         
        },
        methods: {
            goList: function () {
                // this.$router.push({name: 'user-list'});
                this.$router.go(-1)
            },
            saveConfirm: function () {
                let permission = "";
                if(this.userId !== null && this.categoryCode.length > 0) {
                    for (let i in this.categoryCode) {
                        permission += this.categoryCode[i];
                    }
                    this.openConfirm("저장하시겠습니까?", this.save, permission);
                } else {
                    if(this.userId == null) this.openAlert("사용자를 선택해 주세요.");
                    else {
                        this.save('');
                    }
                }
            },
            save: function (permission) {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                this
                .$axios
                .put('/api/mng/service-user', {
                    permission: permission,
                    userName: this.userId,
                    authority: this.authority,
                    download : this.isDownload ? 'Y' : 'N'
                })
                .then((response) => {
                    if (response.data.success) {
                        if(this.userId === this.username) {
                          localStorage.setItem('permission', permission);
                        }
                        this.openAlertClosed("저장이 완료되었습니다.", this.goList);
                    } else {
                        this.openAlert('저장중 오류가 발생했습니다.');
                    }
                    this.btnLock = false;
                })
                .catch(() => {
                    this.openAlert('저장중 오류가 발생했습니다.');
                    this.btnLock = false;
                })
            }

        }
    }
</script>
<style scoped="scoped">
    #popup_road_staff {
        display: inline-block;
    }
</style>
