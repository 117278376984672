<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">기본환경</a>
            </li>
            <li>
                <a href="javascript:void(0)">민감정보 동의내역 관리</a>
            </li>
        </div>
        <div class="wapper" style="min-height:700px">
            <div class="title">
                <h2>민감정보 동의내역 관리</h2>
                <div class="btn-right">
                    <button class="btn_primary" id="btn_reg" @click="createPrivacy()">새 버전 등록</button>
                </div>
            </div>
            <h3>
                <div class="btn-right">
                    <button class="btn_search" @click="openSearch()">검색</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">
                            <dl class="full_width">
                                <dt>버전</dt>
                                <dd><input type="text" v-model='privacyVer'/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>등록자</dt>
                                <dd><input type="text" v-model='regUser'/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>등록일</dt>
                                <dd>
                                    <input type='date' v-model='startDate' @change="changeStartDate"/>
                                    ~
                                    <input type='date' v-model='endDate' @change="changeEndDate"/>
                                </dd>
                            </dl>
                        </div>
                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'btn_filered' : filter}"
                                @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                            <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
                        </div>
                    </div>
                </div>
            </h3>
            <div class="table table_line">
                <table>
                    <tr>
                        <th>버전</th>
                        <th>연결된 문진</th>
                        <th>동의자 수</th>
                        <th>등록자</th>
                        <th>등록일</th>
                        <th>관리</th>
                    </tr>
                    <tr v-for="(item, index) in list" :key="index">
                        <td>{{item.ver}}</td>
                        <td>{{item.connect}}</td>
                        <td>{{item.agree}}</td>
                        <td>{{item.regUser}}</td>
                        <td>{{ convertDate(item.regDate)}}</td>
                        <td>
                            <button @click="goToView(item.code,item.connect, item.agree)">조회</button>
                        </td>
                    </tr>
                </table>
            </div>
            <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" 
            :startDate="startDate" :endDate="endDate" 
            :version="privacyVer" :regUser="regUser"
            />
        </div>

    </div>

</template>
<script>
    import pagination from '../../components/commonPagination.vue'
    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';
    import $ from 'jquery'

    export default {
        data: () => {
            return {list: [], page: {}, pageNation: [],
                privacyVer: null,
                regUser: null,
                startDate: moment().add(-10, 'Y').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
                filter: false
            }
        },
        mixins: [mixin],
        created() {
            if(this.$route.query.page) {
                this.changeFilter();
                this.privacyVer = this.$route.query.version || null;
                this.regUser = this.$route.query.regUser|| null;
                this.startDate = this.$route.query.startDate|| moment().add(-10, 'Y').format("YYYY-MM-DD");
                this.endDate = this.$route.query.endDate|| moment().format("YYYY-MM-DD");
                this.getPrivacy(this.$route.query.page);
            } else {
                this.getPrivacy(0);
            }
        },
        components : {
            pagination
        },
        methods: {
            getPrivacy: function (page) {
                console.log('page', page);
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }
                let param = {};
                if (this.filter) {
                    param = {
                        page : page,
                        size : 30,
                        type : 'S',
                        state : 'A',
                        startDate: this.startDate,
                        endDate: this.endDate,
                        privacyVer: this.privacyVer,
                        regUser: this.regUser
                    }
                } else {
                    param = {
                        page : page,
                        size : 30,
                        type : 'S',
                        state : 'A',
                        startDate: this.startDate,
                        endDate: this.endDate,
                    }
                }
                this
                    .$axios
                    .get('/api/mng/sensitive',{
                        params: param
                    })
                    .then((response) => {
                        console.log(response.data);
                        this.list = [];
                        this.pageNation = [];
                        this.page = {};
                        let connList = response.data.connCount;
                         let agreeList = response.data.agreeCount;
                        let privacyList = response.data.result.content;
                        for (var i = 0; i < privacyList.length; i++) {
                            let obj = {
                                code: privacyList[i].privacyCode,
                                ver: privacyList[i].privacyVer,
                                connect: 0,
                                agree: 0,
                                regUser: privacyList[i].regUser,
                                regDate: privacyList[i].regDate
                            }
                            for (var k = 0; k < connList.length; k++) {
                                console.log("coount", connList[k].connCount);
                                if (privacyList[i].privacyCode == connList[k].privacyCode) {
                                    console.log("innerCnt", connList[k].connCount);
                                    obj.connect = connList[k].connCount;
                                    continue;
                                }
                            }

                             for (var l = 0; l < agreeList.length; l++) {
                                console.log("agree", agreeList[l].connCount);
                                if (privacyList[l].privacyCode == agreeList[l].privacyCode) {
                                    console.log("innerCnt", agreeList[l].agreeCount);
                                    obj.agree = agreeList[i].agreeCount;
                                    continue;
                                }
                            }

                            console.log("obj", obj);
                            this
                                .list
                                .push(obj);

                        }

                        let pageInfo = response.data.result;
                        this.pagerHandler(
                            pageInfo.number,
                            pageInfo.first,
                            pageInfo.last,
                            pageInfo.totalPages
                        );
                    })
                    .catch();
            },
            createPrivacy: function () {
                this
                    .$router
                    .push({name: 'sensitive-mng'})
            },

            goToView: function (privacyCode, conn, agree) {
                console.log(privacyCode);
                this
                .$router
                .push({
                    name: 'sensitive-view',
                    query: {
                        privacyCode: privacyCode,
                         ccount: window.btoa(conn),
                        acount: window.btoa(agree)
                    }
                });
            },
            firstSearch: function () {
                let pageQuery = {
                    page : 0,
                    version : this.privacyVer || null,
                    regUser : this.regUser|| null,
                    startDate : this.startDate|| null,
                    endDate : this.endDate|| null,
                }
                this.$router.push({
                    path: this.$route.path,
                    query: pageQuery
                }).catch(()=>{});
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.startDate = moment().add(-10, 'Y').format("YYYY-MM-DD");
                this.endDate = moment().format("YYYY-MM-DD");
                this.privacyVer = null;
                this.regUser = null;
            },
            changeStartDate(event) {
                this.startDate = event.target.value;
            },
            changeEndDate(event) {
                this.endDate = event.target.value;
            }
        }
    }
</script>
<style scoped="scoped">
    #popup_reg {
        display: inline-block;
    }
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
</style>
