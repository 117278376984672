<template>
    <section>
        <h3>
            <div class="btn-right">
                <button class="btn_primary" @click="addTarget()">신규등록</button>
            </div>
        </h3>
        <div class="table table_line">
            <table>
                <tr>
                    <th>사번</th>
                    <th>관리</th>
                </tr>
                <tr v-for="(item,index) in targetList" :key="index">
                    <td>{{item.usrEmpno}}
                    </td>
                    <td>
                        <button class="btn-secondary" @click="delConfirm(item.usrEmpno)">삭제</button>
                    </td>
                </tr>

            </table>
        </div>
        <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0"  />

    </section>
</template>
<script>
    import pagination from './commonPagination.vue'
    import mixin from '../mixin/mixin.js';

    export default {
        data: () => {
            return {targetList: []}
        },
        mixins: [mixin],
        created: function () {
            if(this.$route.query.page) {
                this.getList(this.$route.query.page);
            } else {
            this.getList(0);
            }
        },
         components : {
            pagination
        },
        methods: {
            getList: function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }
                this
                    .$axios
                    .get('/api/mng/service/empinqmng/v1/emp-except', {
                        params: {
                            size: 30,
                            number: page
                        }
                    })
                    .then((response) => {
                        if (response.data.success) {
                            console.log(response.data.result.content);
                            this.targetList = response.data.result.content;
                            let pageInfo = response.data.result;
                            this.pagerHandler(
                                pageInfo.number,
                                pageInfo.first,
                                pageInfo.last,
                                pageInfo.totalPages
                            );

                        } else {
                            this.openAlert("리스르틀 불러오는 도중 오류가 발생했습니다.");
                        }
                    })
                    .catch(() => {
                        this.openAlert("리스르틀 불러오는 도중 오류가 발생했습니다.");
                    });
            },
            addTarget: function () {
                this
                    .$router
                    .push({name: 'empInfExceptAdd'})
            },
            delConfirm: function (usrEmpno) {
                this.openConfirm("삭제하시겠습니까?", this.delReceiver, usrEmpno);

            },
            delReceiver: function (usrEmpno) {

                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                let exceptList = [];
                let obj = {
                    usrEmpno:usrEmpno
                }
                exceptList.push(obj)
                console.log(exceptList);


                this
                    .$axios
                    .post('/api/mng/service/empinqmng/v1/emp-except/del', {
                        exceptList: exceptList,
                    })
                    .then((response) => {
                        if (response.data.success) {
                            console.log(response.data.result);
                            this.openAlertClosed("삭제가 완료되었습니다.", this.getList);
                        } else {
                            this.openAlert(response.data.resultMsg);
                        }
                        this.btnLock = false;
                    })
                    .catch(() => {

                        this.openAlert('삭제중 오류가 발생했습니다.');

                        this.btnLock = false;
                    });
            }
        }
    }
</script>
