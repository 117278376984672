<template>
  <div class="contents">
    <div class="loc">
      <li>
        <a
            @click="() => {this
                            .$router
                            .push({
                                name: 'inq-list'})}">문진 관리</a>
      </li>
      <li>
        <a @click="() => {
                this.$router
                .push({
                  name : 'inq-detail',
                  query : {'masterId':$route.query.masterId}
                })
              }"
        >문진 설정</a>
      </li>
      <li>
        <a href="javascript:void(0)">새 문진 등록</a>
      </li>
    </div>
    <div class="wapper">
      <div class="title">
        <h2>새 버전 등록 :
          {{ masterIdName }}</h2>
      </div>
      <div class="version_info">
        <dl>
          <dt>버전</dt>
          <dd>v{{ detailVersion }}</dd>
        </dl>
        <dl>
          <dt>적용일자</dt>
          <dd>
            <v-app>
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="publishStartDate"
                  transition="scale-transition"
                  offset-y="offset-y"
                  min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <input
                      class="input_cal"
                      v-model="publishStartDate"
                      label="적용일자를 선택하세요."
                      readonly="readonly"
                      v-bind="attrs"
                      v-on="on"
                      id="startDate"/>
                </template>
                <v-date-picker
                    v-model="publishStartDate"
                    :weekday-format="getWeekDay"
                    :month-format="getMonth"
                    :header-date-format="getHeader"
                    no-title="no-title"
                    scrollable="scrollable">
                  <v-spacer></v-spacer>
                  <v-btn text="text" color="primary" @click="menu = false">
                    취소
                  </v-btn>
                  <v-btn text="text" color="primary" @click="$refs.menu.save(publishStartDate)">
                    확인
                  </v-btn>
                </v-date-picker>
              </v-menu>

            </v-app>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <v-app>
              <v-menu
                  ref="menu3"
                  v-model="menu3"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="publishStartTime"
                  transition="scale-transition"
                  offset-y="offset-y"
                  max-width="290px"
                  min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <input
                      type="text"
                      v-model="publishStartTime"
                      label="Picker in menu"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly="readonly"
                      v-bind="attrs"
                      v-on="on"/>
                </template>
                <v-time-picker
                    v-if="menu3"
                    v-model="publishStartTime"
                    full-width="full-width"
                    color="#006769"
                    @click:minute="$refs.menu3.save(publishStartTime)"></v-time-picker>
              </v-menu>
            </v-app>
            <!-- <input type="text" v-model="publishStartTimeInput" style="vertical-align:
            inherit; margin-left:5px"/> -->
            <span>지정한 날짜부터 현재 버전이 자동으로 적용 됩니다.</span>
          </dd>
        </dl>
        <dl>
          <dt>종료일자</dt>
          <dd>
            <p>
              <v-app>
                <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="publishEndDate"
                    transition="scale-transition"
                    offset-y="offset-y"
                    min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <input
                        class="input_cal"
                        v-model="publishEndDate"
                        label="적용일자를 선택하세요."
                        readonly="readonly"
                        v-bind="attrs"
                        v-on="on"
                        id="endDate"
                        :disabled="useEndDateYn"/>
                  </template>
                  <v-date-picker
                      v-model="publishEndDate"
                      :weekday-format="getWeekDay"
                      :month-format="getMonth"
                      :header-date-format="getHeader"
                      no-title="no-title"
                      scrollable="scrollable">
                    <v-spacer></v-spacer>
                    <v-btn text="text" color="primary" @click="menu2 = false">
                      취소
                    </v-btn>
                    <v-btn
                        text="text"
                        color="primary"
                        @click="() => {if(!useEndDateYn) {$refs.menu2.save(publishEndDate)} else {
                                                $refs.menu2.save(null);
                                            } }">
                      확인
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-app>
              <input
                  type="checkbox"
                  id="date_end_set"
                  name="date_end_set"
                  v-model="useEndDateYn"/>
              <label for="date_end_set">
                                <span>지정안함
                                </span>
              </label>

            </p>
            <p>
              <span>종료일자는 키오스크에 연결되지 않은 추가유형의 문진만 설정할 수 있습니다.</span>
            </p>
          </dd>
        </dl>
      </div>
      <div class="question_wrap">
        <h3>문진구성
          <div class="q_road">
            <button class="btn_primary" id="btn_q_road" @click="getTypeBtn()">문진구성 불러오기</button>
          </div>
        </h3>

        <div
            class="q_card_group"
            v-for="(item , motherIndex) in items"
            :key="item.codeDetailId">
          <div class="q_card" v-if="item.categoryType == 'N'">
            <div class="sort_btn">
              <button class="btn_up" @click="gubunUp(motherIndex)">위로</button>
              <button class="btn_down" @click="gubunDown(motherIndex)">아래로</button>
              <button class="btn_del" @click="removeCategory(motherIndex)">삭 제</button>
            </div>
            <dl>
              <dt>타입</dt>
              <dd>일반문항(기본)
                <input
                    type="checkbox"
                    :id="'indvd' + motherIndex"
                    name="anser_go"
                    @change="initConnect(motherIndex)"
                    v-model="item.indvdCheck"
                    style="margin-left: 30px;"/>
                <label :for="'indvd' + motherIndex">
                                    <span>
                                        개별 항목을 응답으로 수집
                                    </span>
                </label>
                <input
                    type="checkbox"
                    :id="'needAnswer' + motherIndex"
                    name="anser_go"
                    @change="initConnect(motherIndex)"
                    v-model="item.needAnswer"
                    style="margin-left: 30px;"/>
                <label :for="'needAnswer' + motherIndex" style="margin-left: 30px;">
                                    <span>
                                        한개이상의 응답을 필수 수집.
                                    </span>
                </label>
              </dd>
            </dl>
            <dl>
              <dt>유형</dt>
              <dd>
                <input
                    type="radio"
                    :id="'qTypeS' + motherIndex"
                    :name="'qType' + motherIndex"
                    value="S"
                    v-model="item.questionType"/>
                <label :for="'qTypeS' + motherIndex" style="display:inline-block;">
                  <span>증상에 해당하는 질문</span>
                </label><input
                  type="radio"
                  :id="'qTypeD' + motherIndex"
                  :name="'qType' + motherIndex"
                  value="D"
                  v-model="item.questionType"/>
                <label
                    :for="'qTypeD' + motherIndex"
                    style="display:inline-block; marginLeft: 20px;">
                  <span>역학적 연관성에 해당하는 질문</span>
                </label>

                <input
                    type="radio"
                    :id="'qTypeE' + motherIndex"
                    :name="'qType' + motherIndex"
                    value="E"
                    v-model="item.questionType"/>
                <label
                    :for="'qTypeE' + motherIndex"
                    style="display:inline-block; marginLeft: 20px;">
                  <span>기타</span>
                </label>
                <span style="marginLeft:20px;">
                                    *기타 유형은 통계에 카운팅 되지 않음. 출입허용을 하면 통계 카운팅 되지 않음.</span>

              </dd>
            </dl>
            <dl>
              <dt>연결</dt>
              <dd>
                <input
                    type="checkbox"
                    :id="'multi' + motherIndex"
                    name="anser_go"
                    @change="initConnect(motherIndex)"
                    v-model="item.connectCategoryYn"/>
                <label :for="'multi' + motherIndex">
                                    <span>
                                        한개라도 해당시 하위 객관식/주관식 질문 연결
                                    </span>
                </label>
                <select
                    v-if="item.connectCategoryYn"
                    style="margin-left: 50px;"
                    v-model="item.targetCategoryContents">
                  <option value="null" disabled="disabled">다음 객관식/주관식 질문으로 이동</option>
                  <option
                      v-for="(cnnItem, cnnIndex) in items.filter((cnnItem, cnnIndex) => {if((cnnItem.categoryType =='M' || cnnItem.categoryType =='T') && cnnItem.questionContents != null && cnnIndex > motherIndex){ return cnnItem} })"
                      :key="cnnIndex"
                      :value="cnnItem.questionContents">{{ cnnItem.questionContents }}
                  </option>
                </select>
              </dd>
            </dl>
            <dl>
              <dt>구분</dt>
              <dd>
                <auto-search
                    :prevVal="item.questionContents"
                    :searchList="itemList"
                    :check-length="'Y'"
                    inputName="질문을"
                    :target="['Q',motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
              </dd>

            </dl>
            <dl>
              <dt>설명</dt>
              <!-- <dd>
                  <input
                      type="text"
                      placeholder="응답자에게 노출될 문진에 대한 상세 설명"
                      v-model="item.categoryDesc"/></dd> -->
              <dd>
                <auto-search
                    :prevVal="[item.categoryDescId ? item.categoryDesc : '']"
                    :searchList="itemList"
                    inputName="설명을"
                    :target="['D',motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
              </dd>

            </dl>
            <dl>
              <dt>항목</dt>
              <dd>
                <auto-search
                    :prevVal="defaultSearchText"
                    :searchList="itemList"
                    :check-length="'Y'"
                    inputName="보기를"
                    :target="['A', motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
                <table>
                  <colgroup>
                    <col/>
                    <col width="10%"/>
                    <col width="10%"/>
                  </colgroup>
                  <tr>
                    <th>항목</th>
                    <th>삭제</th>
                    <th v-if="$route.query.masterId === 'ERPER' && item.questionType === 'E'">확인증 출력대상</th>
                    <th v-else-if="$route.query.masterId === 'ERPER' && item.questionType !== 'E'"></th>
                    <th v-else>출입 허용 여부</th>
                  </tr>
                  <tr v-for="(subItem , index ) in item.inquiryList" :key="index">
                    <td>{{ subItem.inquiryContents }}</td>
                    <td>
                      <button class="btn_del" @click="removeItem(motherIndex, index)">삭제</button>
                    </td>
                    <td v-if="$route.query.masterId === 'ERPER'">
                      <select v-if="item.questionType === 'E' && !subItem.connectCategoryYn" v-model="subItem.acceptYn">
                        <option value="" disabled="disabled">선택시 문진확인증 허용 여부</option>
                        <option value="Y">출력대상</option>
                        <option value="N">출력안함</option>
                      </select>
                    </td>
                    <td v-else>
                      <select v-if="!subItem.connectCategoryYn" v-model="subItem.acceptYn">
                        <option value="" disabled="disabled">선택시 출입허용 여부</option>
                        <option value="Y">출입허용</option>
                        <option value="N">출입불가</option>
                      </select>
                    </td>
                  </tr>
                </table>
              </dd>

            </dl>
          </div>

          <div class="q_card" v-if="item.categoryType == 'I'">
            <div class="sort_btn">
              <button class="btn_up" @click="gubunUp(motherIndex)">위로</button>
              <button class="btn_down" @click="gubunDown(motherIndex)">아래로</button>
              <button class="btn_del" @click="removeCategory(motherIndex)">삭 제</button>
            </div>
            <dl>
              <dt>타입</dt>
              <dd>일반문항(표/이미지)
                <input
                    type="checkbox"
                    :id="'indvd' + motherIndex"
                    name="anser_go"
                    @change="initConnect(motherIndex)"
                    v-model="item.indvdCheck"
                    style="margin-left: 30px;"/>
                <label :for="'indvd' + motherIndex">
                                    <span>
                                        개별 항목을 응답으로 수집
                                    </span>
                </label>
                <input
                    type="checkbox"
                    :id="'needAnswer' + motherIndex"
                    name="anser_go"
                    @change="initConnect(motherIndex)"
                    v-model="item.needAnswer"
                    style="margin-left: 30px;"/>
                <label :for="'needAnswer' + motherIndex" style="margin-left: 30px;">
                                    <span>
                                         한개이상의 응답을 필수 수집.
                                    </span>
                </label>
              </dd>
            </dl>
            <dl>
              <dt>유형</dt>
              <dd>
                <input
                    type="radio"
                    :id="'qTypeS' + motherIndex"
                    :name="'qType' + motherIndex"
                    value="S"
                    v-model="item.questionType"/>
                <label :for="'qTypeS' + motherIndex" style="display:inline-block;">
                  <span>증상에 해당하는 질문</span>
                </label><input
                  type="radio"
                  :id="'qTypeD' + motherIndex"
                  :name="'qType' + motherIndex"
                  value="D"
                  v-model="item.questionType"/>
                <label
                    :for="'qTypeD' + motherIndex"
                    style="display:inline-block; marginLeft: 20px;">
                  <span>역학적 연관성에 해당하는 질문</span>
                </label>

                <input
                    type="radio"
                    :id="'qTypeE' + motherIndex"
                    :name="'qType' + motherIndex"
                    value="E"
                    v-model="item.questionType"/>
                <label
                    :for="'qTypeE' + motherIndex"
                    style="display:inline-block; marginLeft: 20px;">
                  <span>기타</span>
                </label>
                <span style="marginLeft:20px;">
                                    *기타 유형은 통계에 카운팅 되지 않음. 출입허용을 하면 통계 카운팅 되지 않음.</span>

              </dd>
            </dl>
            <dl>
              <dt>연결</dt>
              <dd>
                <input
                    type="checkbox"
                    :id="'multi' + motherIndex"
                    name="anser_go"
                    @change="initConnect(motherIndex)"
                    v-model="item.connectCategoryYn"/>
                <label :for="'multi' + motherIndex">
                                    <span>
                                        한개라도 해당시 하위 객관식/주관식 질문 연결
                                    </span>
                </label>
                <select
                    v-if="item.connectCategoryYn"
                    style="margin-left: 50px;"
                    v-model="item.targetCategoryContents">
                  <option value="null" disabled="disabled">다음 객관식/주관식 질문으로 이동</option>
                  <option
                      v-for="(cnnItem, cnnIndex) in items.filter((cnnItem, cnnIndex) => {if((cnnItem.categoryType =='M' || cnnItem.categoryType =='T') && cnnItem.questionContents != null && cnnIndex > motherIndex){ return cnnItem} })"
                      :key="cnnIndex"
                      :value="cnnItem.questionContents">{{ cnnItem.questionContents }}
                  </option>
                </select>
              </dd>
            </dl>
            <dl>
              <dt>구분</dt>
              <dd>
                <auto-search
                    :prevVal="item.questionContents"
                    :searchList="itemList"
                    :check-length="'Y'"
                    inputName="질문을"
                    :target="['Q',motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
              </dd>
            </dl>
            <dl>
              <dt>설명</dt>
              <dd>
                <auto-search
                    :prevVal="[item.categoryDescId ? item.categoryDesc : '']"
                    :searchList="itemList"
                    inputName="설명을"
                    :target="['D',motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
              </dd>
            </dl>
            <dl>
              <dt>항목</dt>
              <dd>
                <auto-search
                    :prevVal="defaultSearchText"
                    :searchList="itemList"
                    :check-length="'Y'"
                    inputName="보기를"
                    :target="['A',motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
                <table>
                  <colgroup>
                    <col/>
                    <col width="20%"/>
                    <col width="20%"/>
                    <col width="10%"/>
                  </colgroup>
                  <tr>
                    <th>항목</th>
                    <th>기존 등록된 이미지</th>
                    <th>이미지첨부</th>
                    <th>삭제</th>
                    <th>출입 허용 여부</th>
                  </tr>
                  <tr v-for="(subItem , index ) in item.inquiryList" :key="index">
                    <td>{{ subItem.inquiryContents }}
                    </td>
                    <td>
                      <img
                          v-if="subItem.inquiryPicture"
                          :src="'data:image/png;base64,' +subItem.inquiryPicture"
                          style="max-width:100%;"/>
                    </td>
                    <td>
                      <input
                          type="file"
                          @change="putFile($event,motherIndex, index)"
                          accept=".gif, .jpg, .png, .jpeg"
                          name="file"
                          label="이미지를 첨부하세요."/></td>
                    <td>
                      <button class="btn_del" @click="removeItem(motherIndex, index)">삭제</button>
                    </td>
                    <td>
                      <select v-if="!subItem.connectCategoryYn" v-model="subItem.acceptYn">
                        <option value="" disabled="disabled">선택시 출입허용 여부</option>
                        <option value="Y">출입허용</option>
                        <option value="N">출입불가</option>
                      </select>
                    </td>
                  </tr>
                </table>
              </dd>
            </dl>
          </div>
          <div class="q_card" v-if="item.categoryType == 'H'">
            <div class="sort_btn">
              <button class="btn_up" @click="gubunUp(motherIndex)">위로</button>
              <button class="btn_down" @click="gubunDown(motherIndex)">아래로</button>
              <button class="btn_del" @click="removeCategory(motherIndex)">삭 제</button>
            </div>
            <dl>
              <dt>타입</dt>
              <dd>방문력
                <input
                    type="checkbox"
                    :id="'multi' + motherIndex"
                    name="anser_go"
                    @change="initConnect(motherIndex)"
                    v-model="item.indvdCheck"
                    style="margin-left: 30px;"/>
                <label :for="'multi' + motherIndex">
                                    <span>
                                        개별 항목을 응답으로 수집
                                    </span>
                </label>

              </dd>

            </dl>
            <dl>
              <dt>질문</dt>
              <dd>
                <auto-search
                    :prevVal="item.questionContents"
                    :searchList="itemList"
                    :check-length="'Y'"
                    inputName="질문을"
                    :target="['Q',motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
              </dd>
            </dl>
            <dl>
              <dt>설명</dt>
              <dd>
                <auto-search
                    :prevVal="[item.categoryDescId ? item.categoryDesc : '']"
                    :searchList="itemList"
                    inputName="설명을"
                    :target="['D',motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
              </dd>
            </dl>
            <dl>
              <dt>자료</dt>
              <dd>
                <select v-model="item.placeDiseaseCode">
                  <option disabled="disabled" value="">방문력을 선택하세요.</option>
                  <option v-for="(item,index) in diseases" :key="index" :value="item.seq">{{ item.diseaseName }}
                  </option>
                </select>
                연동할 방문력을 선택하세요.
              </dd>
            </dl>
          </div>
          <div class="q_card" v-if="item.categoryType == 'P'">
            <div class="sort_btn">
              <button class="btn_up" @click="gubunUp(motherIndex)">위로</button>
              <button class="btn_down" @click="gubunDown(motherIndex)">아래로</button>
              <button class="btn_del" @click="removeCategory(motherIndex)">삭 제</button>
            </div>
            <dl>
              <dt>타입</dt>
              <dd>기타(종이문진 전용)</dd>
            </dl>
            <dl>
              <dt>설명</dt>
              <dd>
                <auto-search
                    :prevVal="[item.categoryDescId ? item.categoryDesc : '']"
                    :searchList="itemList"
                    inputName="설명을"
                    :target="['D',motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
              </dd>
            </dl>
            <dl>
              <!-- <input type="text" placeholder="문진제목 입력"/> <button class="btn_q_add
              btn_primary">추가</button> -->
              <dt>질문</dt>
              <dd>
                <auto-search
                    :prevVal="item.questionContents"
                    :searchList="itemList"
                    inputName="질문을"
                    :target="['Q',motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
              </dd>

            </dl>
          </div>
          <div class="q_card" v-if="item.categoryType == 'M'">
            <!-- 객관식형 -->
            <div class="sort_btn">
              <button class="btn_up" @click="gubunUp(motherIndex)">위로</button>
              <button class="btn_down" @click="gubunDown(motherIndex)">아래로</button>
              <button class="btn_del" @click="removeCategory(motherIndex)">삭 제</button>
            </div>
            <dl>
              <dt>타입</dt>
              <dd>객관식형 &nbsp; &nbsp;
                <!-- <input type="checkbox" :id="'multi' + motherIndex" name="anser_go"
                @change="initConnect(motherIndex)" v-model="item.connectCategoryYn"/> <label
                :for="'multi' + motherIndex" style="margin-left: 10px;"> <span>답변을 기준으로 질문 이동
                </span> </label> -->
              </dd>
            </dl>
            <dl>
              <dt>연결</dt>
              <dd>
                <input
                    type="checkbox"
                    :id="'multi' + motherIndex"
                    name="anser_go"
                    @change="initConnect(motherIndex)"
                    v-model="item.connectCategoryYn"/>
                <label :for="'multi' + motherIndex">
                                    <span>답변을 기준으로 질문 이동
                                    </span>
                </label>
              </dd>

            </dl>

            <dl>
              <dt>질문</dt>
              <dd>
                <!-- <input type="text" placeholder="질문 입력" v-model="item.inquiryItemCodeDesc"/>
                -->
                <auto-search
                    :prevVal="item.questionContents"
                    :searchList="itemList"
                    :check-length="'Y'"
                    inputName="질문을"
                    :target="['Q',motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>

              </dd>
            </dl>
            <dl>
              <dt>설명</dt>
              <dd>
                <auto-search
                    :prevVal="[item.categoryDescId ? item.categoryDesc : '']"
                    :searchList="itemList"
                    inputName="설명을"
                    :target="['D',motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
              </dd>
            </dl>
            <dl>
              <dt>보기</dt>
              <dd>
                <table class="anser_table">
                  <colgroup>
                    <col/>
                    <col width="750px;"/>
                  </colgroup>
                  <tr v-for="(maItem, maIndex) in item.inquiryList" :key="maIndex">
                    <td width="70px">
                      <button class="btn_up" @click="moveUpAnswer(motherIndex,maIndex)"></button>
                      <button class="btn_down" @click="moveDownAnswer(motherIndex,maIndex)"></button>

                      <!-- <input type="radio" id="anser_option" name="anser_option"
                      disabled="disabled"/> <label for="anser_option" style="height: 23px;
                      vertical-align: middle; margin-left: 10px;"></label> -->
                    </td>
                    <td style="">
                      <!-- <input type="text" placeholder="옵션1"/> <button
                      class="btn_del_line">삭제</button> -->
                      <auto-search
                          inputName="보기를"
                          :prevVal="maItem.inquiryContents"
                          :searchList="itemList"
                          :check-length="'Y'"
                          :target="['MA',motherIndex,maIndex]"
                          @selectedVal="selectSearchWord"
                          style="width: 50%;"></auto-search>
                      <button class="btn_del_line" @click="deleteMaAnswer(motherIndex,maIndex)">삭제</button>
                    </td>
                    <td>
                      <select v-if="item.connectCategoryYn" v-model="maItem.targetCategoryContents">
                        <option value="null">다음 질문으로 이동</option>
                        <option
                            v-for="(cnnItem, cnnIndex) in items.filter((cnnItem, cnnIndex) => {if((cnnItem.categoryType =='N' || cnnItem.categoryType =='I' || cnnItem.categoryType =='M' || cnnItem.categoryType =='T') && cnnItem.questionContents != null && cnnIndex > motherIndex){ return cnnItem} })"
                            :key="cnnIndex"
                            :value="cnnItem.questionContents">{{ cnnItem.questionContents }}
                        </option>
                      </select>
                      <select v-if="!item.connectCategoryYn" v-model="maItem.acceptYn">
                        <option value="" disabled="disabled">선택시 출입허용 여부</option>
                        <option value="Y">출입허용</option>
                        <option value="N">출입불가</option>
                      </select>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <!-- <button class="btn_up"></button> <button class="btn_down"></button> -->
                      &nbsp;
                      <input type="radio" id="anser_option" name="anser_option"/>
                      <label for="anser_option">
                        &nbsp;
                      </label>
                    </td>
                    <td>
                      <button style="height:30px; width: 90%;" @click="addMaAnswer(motherIndex)">
                        클릭하여 옵션 추가
                      </button>
                    </td>
                    <td></td>
                  </tr>
                </table>
              </dd>
            </dl>
          </div>
          <!-- // 객관식형 -->
          <div class="q_card" v-if="item.categoryType == 'T'">
            <!-- 주관식형 -->
            <div class="sort_btn">
              <button class="btn_up" @click="gubunUp(motherIndex)">위로</button>
              <button class="btn_down" @click="gubunDown(motherIndex)">아래로</button>
              <button class="btn_del" @click="removeCategory(motherIndex)">삭 제</button>
            </div>
            <dl>
              <dt>타입</dt>
              <dd>주관식형</dd>
            </dl>
            <dl>
              <dt>질문</dt>
              <dd>
                <auto-search
                    :prevVal="item.questionContents"
                    :searchList="itemList"
                    :check-length="'Y'"
                    inputName="질문을"
                    :target="['Q',motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
              </dd>
            </dl>
            <dl>
              <dt>설명</dt>
              <dd>
                <auto-search
                    :prevVal="[item.categoryDescId ? item.categoryDesc : '']"
                    :searchList="itemList"
                    inputName="설명을"
                    :target="['D',motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
              </dd>
            </dl>
          </div>
          <!-- // 주관식형 -->
          <div class="q_card" v-if="item.categoryType == 'E'">
            <!-- 안내문구 -->
            <div class="sort_btn">
              <button class="btn_up" @click="gubunUp(motherIndex)">위로</button>
              <button class="btn_down" @click="gubunDown(motherIndex)">아래로</button>
              <button class="btn_del" @click="removeCategory(motherIndex)">삭 제</button>
            </div>
            <dl>
              <dt>타입</dt>
              <dd>안내문구</dd>
            </dl>
            <dl>
              <div class="editor editorVue">
                <editor
                    @setEditorHtml="setEditorHtml"
                    :contents="item.categoryDesc"
                    :motherIndex="motherIndex"
                    :editorId="'editor' + motherIndex"></editor>
              </div>
            </dl>
          </div>
          <!-- //안내문구 -->

          <!-- 동거가족용 증상 -->
          <div class="q_card" v-if="item.categoryType == 'FS'">
            <div class="sort_btn">
              <button class="btn_up" @click="gubunUp(motherIndex)">위로</button>
              <button class="btn_down" @click="gubunDown(motherIndex)">아래로</button>
              <button class="btn_del" @click="removeCategory(motherIndex)">삭 제</button>
            </div>
            <dl>
              <dt>타입</dt>
              <dd>동거가족용 - 증상 (직원전용)</dd>
            </dl>
            <dl>
              <dt>비고</dt>
              <dd>동거가족 1,2,3 동시 적용</dd>
            </dl>
            <dl>
              <dt>구분</dt>
              <dd>
                <auto-search
                    :prevVal="item.questionContents"
                    :searchList="itemList"
                    :check-length="'Y'"
                    inputName="질문을"
                    :target="['Q',motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
              </dd>

            </dl>
            <dl>
              <dt>설명</dt>
              <dd>
                <auto-search
                    :prevVal="[item.categoryDescId ? item.categoryDesc : '']"
                    :searchList="itemList"
                    inputName="설명을"
                    :target="['D',motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
              </dd>
            </dl>
            <dl>
              <dt>항목</dt>
              <dd>
                <auto-search
                    :prevVal="defaultSearchText"
                    :searchList="itemList"
                    :check-length="'Y'"
                    inputName="보기를"
                    :target="['A', motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
                <table>
                  <colgroup>
                    <col/>
                    <col width="10%"/>
                  </colgroup>
                  <tr>
                    <th>항목</th>
                    <th>삭제</th>
                  </tr>
                  <tr v-for="(subItem , index ) in item.inquiryList" :key="index">
                    <td>{{ subItem.inquiryContents }}</td>
                    <td>
                      <button class="btn_del" @click="removeItem(motherIndex, index)">삭제</button>
                    </td>
                  </tr>
                </table>
              </dd>

            </dl>
          </div>
          <!-- !동거가족용 증상 -->

          <!-- 동거가족용 역학적 연관성 -->
          <div class="q_card" v-if="item.categoryType == 'FD'">
            <div class="sort_btn">
              <button class="btn_up" @click="gubunUp(motherIndex)">위로</button>
              <button class="btn_down" @click="gubunDown(motherIndex)">아래로</button>
              <button class="btn_del" @click="removeCategory(motherIndex)">삭 제</button>
            </div>
            <dl>
              <dt>타입</dt>
              <dd>동거가족용 - 역학적 연관성 (직원전용)</dd>
            </dl>
            <dl>
              <dt>비고</dt>
              <dd>동거가족 1,2,3 동시 적용</dd>
            </dl>
            <dl>
              <dt>구분</dt>
              <dd>
                <auto-search
                    :prevVal="item.questionContents"
                    :searchList="itemList"
                    :check-length="'Y'"
                    inputName="질문을"
                    :target="['Q',motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
              </dd>

            </dl>
            <dl>
              <dt>설명</dt>
              <dd>
                <auto-search
                    :prevVal="[item.categoryDescId ? item.categoryDesc : '']"
                    :searchList="itemList"
                    inputName="설명을"
                    :target="['D',motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
              </dd>
            </dl>
            <dl>
              <dt>항목</dt>
              <dd>
                <auto-search
                    :prevVal="defaultSearchText"
                    :searchList="itemList"
                    :check-length="'Y'"
                    inputName="보기를"
                    :target="['A', motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
                <table>
                  <colgroup>
                    <col/>
                    <col width="10%"/>
                    <col width="10%"/>
                  </colgroup>
                  <tr>
                    <th>항목</th>

                    <th>삭제</th>
                  </tr>
                  <tr v-for="(subItem , index ) in item.inquiryList" :key="index">
                    <td>{{ subItem.inquiryContents }}</td>
                    <td>
                      <button class="btn_del" @click="removeItem(motherIndex, index)">삭제</button>
                    </td>
                  </tr>
                </table>
              </dd>

            </dl>
          </div>
          <!-- !동거가족용 역학적 -->

          <div class="q_card" v-if="item.categoryType == 'FT'">
            <!-- 주관식형 -->
            <div class="sort_btn">
              <button class="btn_up" @click="gubunUp(motherIndex)">위로</button>
              <button class="btn_down" @click="gubunDown(motherIndex)">아래로</button>
              <button class="btn_del" @click="removeCategory(motherIndex)">삭 제</button>
            </div>
            <dl>
              <dt>타입</dt>
              <dd>동거가족용 - 주관식형 (직원전용)</dd>
            </dl>
            <dl>
              <dt>질문</dt>
              <dd>
                <auto-search
                    :prevVal="item.questionContents"
                    :searchList="itemList"
                    :check-length="'Y'"
                    inputName="질문을"
                    :target="['Q',motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
              </dd>
            </dl>
            <dl>
              <dt>설명</dt>
              <dd>
                <auto-search
                    :prevVal="[item.categoryDescId ? item.categoryDesc : '']"
                    :searchList="itemList"
                    inputName="설명을"
                    :target="['D',motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
              </dd>
            </dl>
          </div>

          <!--해외방문력 (응급실 전용)-->
          <div class="q_card" v-if="item.categoryType === 'OV' || item.categoryType === 'HV'">
            <div class="sort_btn">
              <button class="btn_up" @click="gubunUp(motherIndex)">위로</button>
              <button class="btn_down" @click="gubunDown(motherIndex)">아래로</button>
              <button class="btn_del" @click="removeCategory(motherIndex)">삭 제</button>
            </div>
            <dl>
              <dt>타입</dt>
              <dd v-if="item.categoryType === 'OV'">해외방문력(응급실 전용) <span style="margin-left: 20px"> * 해외방문력 유형은 작성된 항목 리스트에 대하여 응답자로 부터 '해당 있음' 또는 '해당 없음'만 답변으로 받는다.</span>
              </dd>
              <dd v-if="item.categoryType === 'HV'">타원방문력(응급실 전용) <span style="margin-left: 20px"> * 타원방문력 유형은 작성된 항목 리스트에 대하여 응답자로 부터 '해당 있음' 또는 '해당 없음'만 답변으로 받는다.</span>
              </dd>
            </dl>
            <dl>
              <dt>구분</dt>
              <dd>
                <auto-search
                    :prevVal="item.questionContents"
                    :searchList="itemList"
                    :check-length="'Y'"
                    inputName="질문을"
                    :target="['Q',motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
              </dd>

            </dl>
            <dl>
              <dt>설명</dt>
              <!-- <dd>
                  <input
                      type="text"
                      placeholder="응답자에게 노출될 문진에 대한 상세 설명"
                      v-model="item.categoryDesc"/></dd> -->
              <dd>
                <auto-search
                    :prevVal="[item.categoryDescId ? item.categoryDesc : '']"
                    :searchList="itemList"
                    inputName="설명을"
                    :target="['D',motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
              </dd>

            </dl>
            <dl>
              <dt>항목</dt>
              <dd>
                <auto-search
                    :prevVal="defaultSearchText"
                    :searchList="itemList"
                    :check-length="'Y'"
                    inputName="보기를"
                    :target="['A', motherIndex]"
                    @selectedVal="selectSearchWord"
                    style="width: 50%;"></auto-search>
                <table>
                  <colgroup>
                    <col/>
                    <col width="10%"/>
                    <col width="10%"/>
                  </colgroup>
                  <tr>
                    <th>항목</th>
                    <th>삭제</th>
                    <th v-if="$route.query.masterId === 'ERPER' && item.categoryType === 'N' && item.questionType === 'E'">
                      확인증 출력대상
                    </th>
                    <th v-else-if="$route.query.masterId === 'ERPER' && item.categoryType === 'N' && item.questionType !== 'E'"></th>
                    <th v-else>출입 허용 여부</th>
                  </tr>
                  <tr v-for="(subItem , index ) in item.inquiryList" :key="index">
                    <td>{{ subItem.inquiryContents }}</td>
                    <td>
                      <button class="btn_del" @click="removeItem(motherIndex, index)">삭제</button>
                    </td>
                    <td v-if="$route.query.masterId === 'ERPER'">
                      <select
                          v-if="item.questionType === 'E' && item.categoryType === 'N' && !subItem.connectCategoryYn"
                          v-model="subItem.acceptYn">
                        <option value="" disabled="disabled">선택시 문진확인증 허용 여부</option>
                        <option value="Y">출력대상</option>
                        <option value="N">출력안함</option>
                      </select>
                    </td>
                    <td v-else>
                      <select v-if="!subItem.connectCategoryYn" v-model="subItem.acceptYn">
                        <option value="" disabled="disabled">선택시 출입허용 여부</option>
                        <option value="Y">출입허용</option>
                        <option value="N">출입불가</option>
                      </select>
                    </td>
                  </tr>
                </table>
              </dd>
            </dl>
          </div>
        </div>
        <select @change="addCategory">
          <option disabled="disabled" selected="selected" value="">문진유형</option>
          <option
              v-for="(item, index) in addInquiryTypeList"
              :key="index"
              :value="item.value">{{ item.text }}
          </option>
        </select>

      </div>
      <div class="btn_wrap">
        <div class="btn_left">
          <button class="btn_secondary" id="btn_q_mob" @click="mobile()">사전문진 미리보기</button>
          <button class="btn_secondary" id="btn_q_kiosk" @click="kiosk()">현장문진 미리보기</button>
          <button class="btn_secondary" id="btn_q_paper" @click="paper()">종이문진 미리보기</button>
        </div>
        <div class="btn_right">
          <button class="btn_secondary" @click="goPrev()">취 소</button>
          <button class="btn_important" @click="saveInquiry('T')">임시저장</button>
          <button class="btn_primary" @click="saveInquiry('W')">저 장</button>
        </div>
      </div>
    </div>

    <!-- 팝업 -->
    <modal
        name="popup_q_road"
        :width="320"
        :height="308"
        :adaptive="true"
        :clickToClose="false">
      <div id="popup_q_road" class="pop pop_sm">
        <!-- 새 문진 등록 -->
        <div class="pop_head">
          <h2>문진구성 불러오기</h2>
          <a class="btn_pop_close" @click="closeGetTypeBtn()">닫기</a>
        </div>
        <div class="pop_body">
          <dl>
            <dt>문진 선택</dt>
            <dd>
              <select v-model="selectType" @change="getDetailInfo($event)" ref="selectType">
                <option value="" disabled="disabled" selected="selected">문진유형</option>
                <option v-for="(item, index) in masterList" :key="index" :value="item.value">{{ item.text }}</option>
              </select>
            </dd>
          </dl>
          <dl>
            <dt>버전 선택</dt>
            <dd>
              <select v-model="selectVer" ref="selectVer">
                <option value="" disabled="disabled" selected="selected">버전</option>
                <option v-for="(item, index) in detailList" :key="index" :value="item.value">{{ item.text }}</option>
              </select>
            </dd>
          </dl>
        </div>
        <div class="pop_footer">
          <button class="btn_secondary" id="btn_reg_cancel" @click="closeGetTypeBtn()">취 소</button>
          <button class="btn_primary" @click="getInquiryDetail">확인</button>
        </div>
      </div>
    </modal>

  </div>
</template>
<script>
// import $ from 'jquery';
import preivewPaper from '../../components/previewPaper.vue';
import autoSearch from '../../components/autoSearch.vue';
import mixin from '../../mixin/mixin.js';
import editor from '../../components/editor.vue';

// let catObj = {     categoryId: null,     categoryType: null, categoryOrder:
// null,     categoryDesc: null,     connectCategoryYn: null, subCategoryYn:
// null,     questionId: null,     questionContents: null, inquiryList: [],
// placeTargetId: null } let itemObj = {     inquiryId: null, inquiryContents:
// '',     inquiryPicture: null,     file: null, targetCategoryId: null,
// acceptYn: null,     check: null,     answerId: null,     order: null }

export default {

  data: () => {
    return {
      publishStartDate: new Date() //시작일
          .toISOString()
          .substr(0, 10),
      publishStartTime: '00:00', // 시작 시간
      publishEndDate: null, // 종료일
      menu: false, // 시작이 달력 라이브러리 보이기
      menu2: false, //종료일 달력 라이브러리 보이기
      menu3: false, //시작시간 시계 라이브러리 보이기
      detailVersion: '', //상세버전
      useEndDateYn: true, //종료일 사용 여부
      items: [],
      catObj: {
        categoryId: null,
        categoryType: null,
        categoryValue: null,
        categoryOrder: null,
        categoryDesc: '',
        categoryDescId: null,
        connectCategoryYn: false,
        questionType: "E",
        targetCategoryId: null,
        targetCategoryContents: null,
        subCategoryYn: false,
        questionId: null,
        questionContents: null,
        inquiryList: [],
        placeDiseaseCode: '',
        show: true
      },
      itemObj: {
        inquiryId: null,
        inquiryContents: '',
        inquiryPicture: null,
        file: null,
        targetCategoryId: null,
        targetCategoryContents: null,
        acceptYn: "N",
        check: null,
        answerId: null,
        itemOrder: null,
        indvdCheck: false,
        needAnswer: false
      },
      addInquiryTypeList: [
        {
          text: '일반문항(기본)',
          value: 'N'
        }, {
          text: '일반문항(표/이미지)',
          value: 'I'
        }, {
          text: '방문력',
          value: 'H'
        }, {
          text: '기타(종이문진 전용)',
          value: 'P'
        }, {
          text: '객관식형',
          value: 'M'
        }, {
          text: '주관식형',
          value: 'T'
        }, {
          text: '안내문구(에디터)',
          value: 'E'
        }, {
          text: '동거가족 - 증상 (직원전용)',
          value: 'FS'
        }, {
          text: '동거가족 - 역학적 연관성 (직원전용)',
          value: 'FD'
        }, {
          text: '동거가족 - 주관식 (직원전용)',
          value: 'FT'
        }
      ],
      selectVer: '', //문진 불러오기 해당 문진 유형의 버전
      selectType: '', //문진 불러오기 문진 유형
      masterList: [], //문진 마스터 리스트
      detailList: [], //문진상세 리스트
      itemList: [], //검색어 리스트
      defaultSearchText: '', //검색어 초기화
      diseases: [], //질병 분류코드
      susikList: [], //수식관리 리스트
      inqImgFiles: [], //이미지파일 리스트
      masterIdName: null,
    }
  },
  mixins: [mixin],
  created: function () {
    this.getMasterInfo();
    this.getAllItem();
    this.getDisease();

  },
  mounted: function () {

    let paramVersion = this.$route.query.maxVersion;
    let frontNum = 0;
    let rearNum = 0;
    paramVersion = paramVersion.split('.');
    if ((paramVersion[1] * 1) >= 9) {
      frontNum = (paramVersion[0] * 1) + 1;
      rearNum = 0;
    } else {
      frontNum = paramVersion[0] * 1;
      rearNum = (paramVersion[1] * 1) + 1;
    }
    this.detailVersion = frontNum + "." + rearNum;
    if (this.$route.query.masterId == 'ERPER') {
      this.addInquiryTypeList.push({
        text: '해외방문력',
        value: 'OV'
      });
      this.addInquiryTypeList.push({
        text: '타원방문력',
        value: 'HV'
      })
    }
  },
  components: {

    'auto-search': autoSearch,
    'editor': editor
  },
  methods: {
    formatDate(date) {
      if (!date)
        return null;

      const [year, month, day] = date.split('-')
      return `${year}/${month}/${day}`
    },
    parseDate(date) {
      if (!date)
        return null;

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    checkValidCnt() {
      let itemCnt = 0;
      for (let cat of this.items) {
        itemCnt++;
        if (cat.inquiryList.length > 0) {
          itemCnt += cat.inquiryList.length;
        }
      }
      return itemCnt <= 60;
    },
    addCategory: function (event) { //문진 카테고리를 추가한다.
      if (event.target.value !== 'E' && !this.checkValidCnt()) {
        this.openAlert("허용 가능한 문진 구성 수를 초과하였습니다.");
        event.target.value = "";
        return;
      }
      if (event.target.value == 'H') { //방문력은 한번만 추가 할 수 있다.
        for (let item of this.items) {
          if (item.categoryType == 'H') {
            this.openAlert("방문력은 문진에서 한번만 삽입할 수 있습니다.");
            event.target.value = '';
            return;
          }
        }
      }

      let obj = Object.assign({}, this.catObj);
      obj.categoryType = event.target.value;
      if (obj.categoryType === 'OV' || obj.categoryType === 'HV') {
        obj.indvdCheck = false;
        obj.needAnswer = false;
      }
      this
          .items
          .push(Object.assign({}, obj));
      event.target.value = "";

    },
    getTypeBtn: function () { //문진 불러오기 열기

      if (this.items.length > 0) {
        this.openConfirm("현재 입력된 내용이 삭제됩니다. 계속하시겠습니까", () => {
          this
              .$modal
              .show("popup_q_road");
        })
        return;
      }

      this
          .$modal
          .show("popup_q_road");
    },
    closeGetTypeBtn: function () { //문진불러오기 닫기
      this.selectVer = "";
      this.selectType = "";
      this
          .$modal
          .hide("popup_q_road");
    },
    gubunUp: function (motherIndex) {
      if (motherIndex == 0) {
        alert("첫번째 항목입니다.");
        return;
      }
      let tempItems = this.items[motherIndex];
      this
          .items
          .splice(motherIndex, 1);
      this
          .items
          .splice(motherIndex - 1, 0, tempItems);

      // this.items = JSON.parse(JSON.stringify(this.items));
    },
    gubunDown: function (motherIndex) {
      if (motherIndex == (this.items.length - 1)) {
        alert("마지막 항목입니다.");
        return;
      }
      let tempItems = this.items[motherIndex];
      this
          .items
          .splice(motherIndex, 1);
      this
          .items
          .splice(motherIndex + 1, 0, tempItems);
      //  this.items = JSON.parse(JSON.stringify(this.items));
    },
    getMasterInfo: function () {
      this
          .$axios
          .get("/api/mng/inquiry/v1/master")
          .then((response) => {
            this.placeConnList = [];
            let masters = response.data.result;
            if (masters == null) {
              this.openAlert("마스터 정보가 없습니다.");
              return;
            }
            let masterId = this.$route.query.masterId;
            for (var i = 0; i < masters.length; i++) {
              let temp = {
                text: masters[i].masterName,
                value: masters[i].masterId
              }

              this
                  .masterList
                  .push(temp);

              console.log(masters[i].masterId);
              console.log(masterId);

              if (masters[i].masterId == masterId) {
                this.masterIdName = masters[i].masterName;
              }

            }

          })
          .catch((err) => {
            console.log(err);
          });
    },
    getDetailInfo: function (event) {
      this.selectVer = "";
      let value = event.target.value;
      console.log(value);
      this
          .$axios
          .get("/api/mng/inquiry/v1/detail", {
            params: {
              masterId: value
            }
          })
          .then((response) => {
            let detailList = response.data.result;
            console.log(detailList);
            detailList.sort((a, b) => {
              return a.regDate < b.regDate
                  ? -1
                  : a.regDate > b.regDate
                      ? 1
                      : 0;
            })
            let result = [];
            for (var i = 0; i < detailList.length; i++) {
              result.push({text: detailList[i].detailVer, value: detailList[i].detailId})
            }
            console.log(result);
            this.detailList = result;
          })
          .catch(() => {
            this.openAlert("해당 버전을 가져오는 도중 오류가 발생했습니다.");
          })

    },
    getAllItem: function () {
      this
          .$axios
          .get('/api/mng/inquiry/v1/item')
          .then((response) => {
            let result = response.data.result;
            this.makeItemList(result);
          })
    },
    //////////////////////////////////////////////////////
    addItemContents: function (itemContents) { //자동완성검색에서 새로 추가한 검색어를 저장한다.
      if (this.btnLock) {
        return;
      } else {
        this.btnLock = true;
      }
      return this
          .$axios
          .post('/api/mng/inquiry/v1/add-item', {itemContents: itemContents})
          .then((response) => {
            if (!response.data.success) {
              this.openAlert("항목을 추가하지 못했습니다. 다시 시도해 주세요.");
              this.btnLock = false;
              return false;
            }
            this.makeItemList(response.data.result);
            this.btnLock = false;
            return true;
          })

    },
    makeItemList: function (itemList) { //파라미터로 받은 리스트를 자동완성검색에 전달할 리스트로 변환한다.
      this.itemList = [];
      for (let item of itemList) {
        let tempObj = {
          name: item.itemContents,
          id: item.itemId,
          selected: false
        }
        this
            .itemList
            .push(tempObj);
      }
    },
    selectSearchWord: async function (target, searchText) { //자동검색에서 선택또는 추가한 항목을 해당하는 곳에 세팅
      if (!this.checkValidCnt()) {
        if (target[0] !== 'Q' && target[0] !== 'D') {
          this.openAlert("허용 가능한 문진 구성 수를 초과하였습니다.");
          return;
        }
      }
      let isAdd = true;
      let searchId = null;
      for (let item of this.itemList) {
        if (item.name == searchText) { //새로 추가인지 체크
          isAdd = false;
          searchId = item.id;
          break;
        }
      }
      if (isAdd) { //새로 추가이면 항목을 먼저 서버에 저장.
        if (!await this.addItemContents(searchText)) {
          return;
        }
        for (let item of this.itemList) {
          if (item.name == searchText) {
            searchId = item.id;
            break;
          }
        }
      }

      //검색어에 입력한 내용을 items에 추가. 유형별로 추가해 줘야 할 키가 다르기때문에 분기
      if (target[0] == "Q") { //질문에 추가는경우라면 질문에 추가.

        this
            .items[target[1]]
            .questionContents = searchText;
        this
            .items[target[1]]
            .questionId = searchId;
      } else if (target[0] == "A") { //보기에 추가 해야 하는 경우

        let tempObj = Object.assign({}, this.itemObj);
        tempObj.inquiryId = searchId;
        tempObj.inquiryContents = searchText;
        let tempList = Object.assign([], this.items[target[1]].inquiryList);
        tempList.push(tempObj);
        this.$set(this.items[target[1]], 'inquiryList', tempList);

      } else if (target[0] == "MA") { //객관식의 보기일경우
        this.$set(this.items[target[1]].inquiryList[target[2]], 'inquiryId', searchId);
        this.$set(
            this.items[target[1]].inquiryList[target[2]],
            'inquiryContents',
            searchText
        );
      } else if (target[0] == "D") {//카테고리 설명

        this
            .items[target[1]]
            .categoryDesc = searchText;
        this
            .items[target[1]]
            .categoryDescId = searchId;
      }
    },
    addMaAnswer: function (motherIndex) { //객관식에서 보기 추가
      let tempObj = Object.assign({}, this.itemObj);
      let tempList = Object.assign([], this.items[motherIndex].inquiryList);
      tempList.push(tempObj);
      this.$set(this.items[motherIndex], 'inquiryList', tempList);
    },
    initConnect: function (motherIndex) { //객관식에서 답변을 기준으로 질문이동 체크박스 이벤트 발생시마다 하위 선택되는 항목 초기화.
      for (let [index] of this.items[motherIndex].inquiryList.entries()) {
        this.$set(this.items[motherIndex].inquiryList[index], 'acceptYn', 'N');
        this.$set(this.items[motherIndex].inquiryList[index], 'targetCategoryId', null);
      }
    },
    removeCategory: function (motherIndex) { //카테고리 삭제
      let tempItems = [];
      tempItems = Object.assign([], this.items);
      tempItems.splice(motherIndex, 1);
      this.items = tempItems;

    },
    removeItem: function (motherIndex, index) { //카테고리에 속한 문진 항목 삭제.
      let tempItems = [];
      tempItems = Object.assign([], this.items[motherIndex].inquiryList)
      tempItems.splice(index, 1);
      this.$set(this.items[motherIndex], 'inquiryList', tempItems);
      index = 0;
    },
    moveUpAnswer: function (motherIndex, maIndex) { //객관식 보기 순서 위로 이동
      if (maIndex == 0) {
        alert("첫번째 항목입니다.");
        return;
      }
      let tempObj = this
          .items[motherIndex]
          .inquiryList[maIndex];
      this
          .items[motherIndex]
          .inquiryList
          .splice(maIndex, 1);
      this
          .items[motherIndex]
          .inquiryList
          .splice(maIndex - 1, 0, tempObj);
      let tempArr = this
          .items[motherIndex]
          .inquiryList;
      this.$set(this.items[motherIndex], 'inquiryList', tempArr);
    },
    moveDownAnswer: function (motherIndex, maIndex) { //객관식 보기 순서 아래로 이동
      if (maIndex >= this.items[motherIndex].inquiryList.length) {
        alert("마지막 항목입니다.");
        return;
      }
      let tempObj = this
          .items[motherIndex]
          .inquiryList[maIndex];
      this
          .items[motherIndex]
          .inquiryList
          .splice(maIndex, 1);
      this
          .items[motherIndex]
          .inquiryList
          .splice(maIndex + 1, 0, tempObj);
      let tempArr = this
          .items[motherIndex]
          .inquiryList;
      this.$set(this.items[motherIndex], 'inquiryList', tempArr);
      // this.items[motherIndex] = Object.assign({}, this.items[motherIndex]);
    },
    deleteMaAnswer: function (motherIndex, maIndex) {
      let tempArr = this
          .items[motherIndex]
          .inquiryList;
      console.log(tempArr);
      tempArr.splice(maIndex, 1);
      this.$set(this.items[motherIndex], 'inquiryList', tempArr);
    },
    getDisease: function (code) { //질병 분류코드를 가져온다.
      this
          .$axios
          .get('/api/mng/inquiry/v1/disease', {
            params: {
              seq: code
            }
          })
          .then((response) => {
            if (response.data.success) {
              this.diseases = response.data.result;
            } else {
              this.openAlert("질병 분류 코드를 가져오지 못했습니다.");
            }
          })
          .catch(() => {
            this.openAlert("질병 분류 코드를 가져오는 도중 오류가 발생했습니다.");
          });
    },
    setEditorHtml: function (motherIndex, contents) { //에디터에서 변경된 내용을 세팅한다.
      this.$set(this.items[motherIndex], 'categoryDesc', contents);
    },
    validation: function (text, msg) {
      if (text == null) {
        this.openAlert(msg + "을(를) 입력하세요.");
        return true;
      } else if (text.trim().length == 0) {
        this.openAlert(msg + "을(를) 입력하세요.");
        return true;
      } else if (text.trim().length > 200) {
        this.openAlert(msg + "은(는) 200자를 넘을 수 없습니다.");
        return true;
      } else {
        return false;
      }
    },
    saveInquiry: function (type) {

      if (this.items.length == 0) {
        this.openAlert("문진유형을 추가하여 문진표를 작성해 주세요");
        return;
      }

      //발리데이션 체크 시작
      for (let item of this.items) {
        if (item.categoryType == "H") { //방문력의 경우
          console.log("방문력");
          console.log('code', item.placeDiseaseCode);
          if (item.placeDiseaseCode == '') {
            this.openAlert("연동할 방문력을 선택하세요.");
            return;
          }
        } else if (item.categoryType == "N") { // 일반 기본 문진의 경우
          if (this.validation(item.questionContents, "일반(기본)문진의 구분")) {
            return;
          }
          for (let subItem of item.inquiryList) {
            if (this.validation(subItem.inquiryContents, "일반(기본)문진의 항목")) {
              return;
            }
          }
        } else if (item.categoryType == "I") { //일반 표이미지 문진의 경우
          if (this.validation(item.questionContents, "일반(표/이미지)문진의 및 구분")) {
            return;
          }
          for (let subItem of item.inquiryList) {
            if (this.validation(subItem.inquiryContents, "일반(표/이미지)문진의 항목")) {
              return;
            }
          }
        } else if (item.categoryType == "P") { //종이 문진의 경우
          if (this.validation(item.questionContents, "종이문진  질문")) {
            return;
          }
        } else if (item.categoryType == "M") { //객관식 문진의 경우
          if (this.validation(item.questionContents, "객관식문진 질문")) {
            return;
          }
          for (let subItem of item.inquiryList) {
            if (this.validation(subItem.inquiryContents, "객관식문진 보기")) {
              return;
            }
          }
        } else if (item.categoryType == "T") { //주관식 문진의 경우
          if (this.validation(item.questionContents, "주관식문진 질문")) {
            return;
          }
        } else if (item.categoryType == "E") { //안내문구(에디터)문진의 경우
          if (item.categoryDesc == null) {
            this.openAlert("안내문구(에디터) 문진을 입력해 주세요.")
            return;
          } else if (item.categoryDesc.trim() == '') {
            this.openAlert("안내문구(에디터) 문진을 입력해 주세요.")
            return;
          }
        }
      }
      this.openConfirm("작성한 문진표를 저장하시겠습니까?", this.save, type);

    },
    save: function (type) { //문진 저장
      if (this.btnLock) {
        return;
      } else {
        this.btnLock = true;
      }

      let useEndDate = 'N';
      if (!this.useEndDateYn) {
        useEndDate = 'Y'
      } else {
        useEndDate = 'N'
      }

      // var formDom = new FormData(); formDom.append('masterId',
      // this.$route.query.masterId); formDom.append('detailVer', this.detailVersion);
      // formDom.append('publishStartDate', this.publishStartDate);
      // formDom.append('publishStartTime', this.publishStartTime);
      // formDom.append('useEndDateYn', useEndDate); if (!this.useEndDateYn) {
      // formDom.append('publishEndDate', this.date2); } formDom.append('status',
      // type); let catIndex = 0; for (let cat of this.items) {     let catKeys =
      // Object.keys(cat);     for (let catKey of catKeys) {         if (catKey ==
      // 'inquiryList') {             let inquiryIndex = 0;             for (let
      // inquiry of cat[catKey]) {                 let inquiryKeys =
      // Object.keys(inquiry);                 for (let inquiryKey of inquiryKeys) {
      // if (inquiryKey == 'file') {                         console.log(inquiry);
      // console.log(inquiry[inquiryKey]);
      // console.log(this.items[catIndex].inquiryList[inquiryIndex][inquiryKey]); if
      // (this.items[catIndex].inquiryList[inquiryIndex][inquiryKey] == null) {
      // continue;                         }                     } formDom.append(
      // 'categoryList[' + catIndex + '].' + catKey + '[' + inquiryIndex + '].' +
      // inquiryKey,                         inquiry[inquiryKey] );                 }
      // inquiryIndex++;             }         } else if (catKey == 'placeList') {
      // continue;         } else { formDom.append('categoryList[' + catIndex + '].' +
      // catKey, cat[catKey]); }     }     catIndex++; } formDom, {     headers: {
      // 'Content-Type': 'multipart/form-data'     } } {     masterId:
      // this.$route.query.masterId, detailVer: this.detailVersion, publishStartDate:
      // this.publishStartDate, publishStartTime: this.publishStartTime,
      // publishEndDate: this.publishEndDate, categoryList: this.items, useEndDate:
      // useEndDate,     status: type }

      this
          .$axios
          .post('/api/mng/inquiry/v1/inquiry', {
            masterId: this.$route.query.masterId,
            detailVer: this.detailVersion,
            publishStartDate: this.publishStartDate,
            publishStartTime: this.publishStartTime,
            publishEndDate: this.publishEndDate,
            categoryList: this.items,
            useEndDate: useEndDate,
            status: type
          })
          .then((response) => {
            console.log(response.data);
            if (!response.data.success) {
              this.openAlert("저장중 오류가 발생했습니다.");
            } else {

              this.saveImage();

            }
            this.btnLock = false;
          })
          .catch(() => {
            this.openAlert("저장중 오류가 발생했습니다.");
            this.btnLock = false;
          });
    },
    getInquiryDetail: function () {
      this
          .$axios
          .get('/api/mng/inquiry/v1/inquiry', {
            params: {
              detailId: this.selectVer
            }
          })
          .then((response) => {
            console.log(response.data.result);
            this.closeGetTypeBtn();
            this.items = Object.assign([], response.data.result.categoryList);
          })
    },
    openPreviewPopup: function (
        strWindowFeatures,
        windowName,
        popupHost,
        subUrl,
        categoryList,
        language
    ) {
      let items = Object.assign([], categoryList);
      const inquiryType = this.$route.query.masterId;
      const eventListenerFtn = function (e) {
        if (e.origin === popupHost && e.data.method === "needCategoryData") {
          e.source.postMessage(
              {
                method: e.data.method,
                categoryList: items,
                inquiryType,
                language,
              },
              popupHost
          );
          window.removeEventListener("message", eventListenerFtn);
        }
      };
      window.addEventListener("message", eventListenerFtn);
      window.open(
          `${popupHost}${subUrl}${new Date().getTime()}`,
          windowName,
          strWindowFeatures
      );
    },
    mobile: function () {
      const strWindowFeatures =
          "menubar=no,location=no,resizable=no,scrollbars=yes,status=no,width=375,height=712,left=200,top=100";
      const windowName = "ASAN_Mobile_Preview";
      this.openPreviewPopup(
          strWindowFeatures,
          windowName,
          `${window.location.protocol}//${window.location.host}`,
          "/pre/#/inquiry/",
          this.items,
          "ko"
      );
    },
    kiosk: function () {
      const strWindowFeatures =
          "menubar=no,location=no,resizable=no,scrollbars=yes,status=no,width=432,height=768,left=200,top=100";
      const windowName = "ASAN_Kiosk_Preview";
      this.openPreviewPopup(
          strWindowFeatures,
          windowName,
          `${window.location.protocol}//${window.location.host}`,
          "/site/#/preview/",
          this.items,
          "ko"
      );
    },
    paper: function () {
      this
          .$modal
          .show(preivewPaper, {
            items: this.items,
            masterName: this.masterIdName,
            masterId: this.$route.query.masterId
          }, {
            width: '1080px',
            height: '800px',
            adaptive: true,
            clickToClose: true
          });
    },
    putFile: function (event, motherIndex, index) {
      // this     .items[motherIndex]     .inquiryList[index]     .file = event
      // .target     .files[0];

      let inqImgFile = {
        inquiryItemId: this
            .items[motherIndex]
            .inquiryList[index]
            .inquiryId,
        file: event
            .target
            .files[0]
      }
      console.log(inqImgFile);
      for (let i = 0; i < this.inqImgFiles.length; i++) {
        if (this.inqImgFiles[i].inquiryItemId == inqImgFile.inquiryItemId) {
          console.log(this.inqImgFiles[i].inquiryItemId);
          console.log(inqImgFile.inquiryItemId);
          this
              .inqImgFiles
              .splice(i, 1);
        }
      }
      this
          .inqImgFiles
          .push(inqImgFile);

    },
    goPrev: function () {
      this
          .$router
          .push({
            name: 'inq-detail',
            query: {
              masterId: this.$route.query.masterId
            }
          });
    },
    saveImage: function () {
      if (this.inqImgFiles.length == 0) {
        this.openAlertClosed("정상적으로 저장하였습니다.", () => {
          this
              .$router
              .push({
                name: 'inq-detail',
                query: {
                  masterId: this.$route.query.masterId
                }
              });
        })
        return;
      }

      var formDom = new FormData();
      for (let i = 0; i < this.inqImgFiles.length; i++) {
        formDom.append(
            'params[' + i + '].inquiryItemId',
            this.inqImgFiles[i].inquiryItemId
        );
        formDom.append('params[' + i + '].file', this.inqImgFiles[i].file);
      }

      this
          .$axios
          .post('/api/mng/inquiry/v1/inquiry-img', formDom, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((response) => {
            console.log(response.data.success);
            if (response.data.success) {
              this.openAlertClosed("정상적으로 저장하였습니다.", () => {
                this
                    .$router
                    .push({
                      name: 'inq-detail',
                      query: {
                        masterId: this.$route.query.masterId
                      }
                    });
              })
            } else {
              this.openAlert("이미지 저장에 실패하였습니다.");
            }
          })
          .catch(() => {
            this.openAlert("이미지 저장에 실패하였습니다.");
          })

    },
  }
}
</script>

<style scoped="scoped">
.autoSearch {
  position: absolute;
  display: none;
  z-index: 1;
  background-color: #ffffff;
  padding: 10px;
  border: #c7c7c7 1px solid;
  max-height: 500px;
  overflow-y: auto;
  top: 153px;
  left: 80px;
  width: calc(50% - 55px);
}

.autoSearch ul {
  list-style: none;
}

.autoSearch ul li {
  margin-bottom: 2px;
  padding: 2px 0;
}

.autoSearch ul li:hover {
  background-color: #e2e2e2;
}

.autoSearch ul li.active {
  background-color: #e2e2e2;
}

.autoSearch ul .searchAction {
  float: right;
}

.autoSearch ul .searchAction::after {
  clear: both;
}

.autoSearch ul .searchAction a {
  text-decoration: none;
}

.searchHide {
  display: none;
}

.autoSearch2 {
  top: 105px;
  z-index: 2;
}

.q_card {
  overflow: visible;
}

.input_cal {
  height: 36px;
  padding: 0 8px;
  line-height: 36px;
  border: solid 1px #E3EBF6;
  border-radius: 3px;
  box-shadow: none;
  -webkit-appearance: none;
  font-size: 14px;
}

.contents {
  min-height: 800px;
}

.pop {
  display: inline-block;
}

.sort_btn .btn_del {
  background: url("../../assets/images/ico_del.svg") no-repeat 3px 0;
}

.sort_btn .btn_del {
  padding: 3px 10px 2px 25px;
  font-size: 12px;
  border: 1px solid #A0A3B9;
  border-radius: 3px;
}

.input_cal {
  height: 36px;
  padding: 0 8px;
  line-height: 36px;
  border: solid 1px #AAACB4;
  border-radius: 3px;
  box-shadow: none;
  -webkit-appearance: none;
  font-size: 14px;
  background: #ffffff;
}

.editor {
  border: 1px solid rgb(255, 255, 255) !important;
}

textarea::placeholder {
  color: #BCBDC1;
}

.q_card {
  white-space: pre-wrap;
}
.version_info{
  overflow: visible;
}

.v-menu__content {
  max-width: unset !important;
}
</style>
