<template>
  <section>
    <h3>
      <div class="btn-right">
        <button class="btn_primary" @click="goBack">뒤로가기</button>           
      </div>
    </h3>
    <div class="table responsive">
      <table v-if="tableHeader.length > 0 && tableHeaderSub.length > 0">         
        <tr>
          <th>문진상태</th>
          <th>기준일</th>
          <th>문진작성일</th>
          <th>문진작성시간</th>
          <th colspan="2">기본정보</th>
          <th
            v-for="(header, index) in tableHeader"
            :key="`${index}_${header.itemContents}`"
            :colspan="header.colSpan"
          >
            {{ header.itemContents }}
          </th>
          <th>방문력</th>
        </tr>
        <tr>
          <th>구분</th>
          <th>일자</th>
          <th>작성일자</th>
          <th>문진작성시간</th>
          <th v-if="masterId ==  'GST' || masterId == 'NOK' || masterId == 'WGST' ">연락처</th>
          <th v-if="masterId == 'PT'">성명</th>
          <th v-if="masterId == 'GST' || masterId == 'WGST'">방문목적</th>
          <th v-if="masterId == 'PEMP'">협력업체</th>
          <th v-if="masterId == 'NOK'">환자병원등록번호</th>
          <th v-if="masterId == 'PT'">병원등록번호</th>
          <th
            class="sub-header"
            v-for="(header, index) in tableHeaderSub"
            :key="`${index}_${header.itemContents}`"
          >
            {{ header.itemContents }}
          </th>
          <th>여부</th>
        </tr>
        <tr v-for="(answer, index) in tableList" :key="`${index}`">
          <td v-for="(key, subIndex) in answer" :key="subIndex">{{ key }}</td>
        </tr>
      </table>
    </div>
   
    <!-- <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" 
    :startDate="startDate" :endDate="endDate"/> -->
  </section>
</template>

<script>
// import pagination from "./commonPagination.vue"
import DatePicker from "./datePicker2";
import mixin from "../mixin/mixin.js";
import moment from "moment";
import $ from "jquery";
// import inquiryIndividual from "../../visitor/inquiryIndividual"

export default {
  name: "individualStatusTable",
  components: {
    DatePicker,
    // pagination,
  },
  props: {
    masterId: {
      type: String,
      required: true,
    },
  },
  mixins: [mixin],
  
  data: () => {
    return {
      selectedVersion: null,
      inquiryVersionInfo: null,
      searchPatNo: null,
      searchPatName: null,
      searchPhoneNum: null,
      searchDeniedYn: 'all',
      searchStartDate: null,
      searchEndDate: null,
      tableHeader: [],
      tableHeaderSub: [],
      tableList: [],
      answerSize: 0,
      filter: false,
      queryList: {},
      detailId:null,
    };
  },
  computed: {
    versionList() {
      return this.inquiryVersionInfo?.map((item) => item.ver);
    },
  },
  methods: {
    // API 호출 Method
    getInquiryVersion: async function (masterId) {
      try {
        const response = await this.$axios.get(
          "/api/mng/inquiry/v1/inq-status/ver",
          {
            params: { masterId },
          }
        );
        if (!response.data.success) throw new Error("API Fail");
        this.inquiryVersionInfo = response.data.result;
        if (this.inquiryVersionInfo.length > 0)
          this.selectedVersion = this.inquiryVersionInfo[0].ver;
      } catch (error) {
        console.log("버전 오류 ")
        this.openAlert("문진표 정보를 불러오는 도중 오류가 발생했습니다.");
      }
    },
    getInquiryHeader: async function (detailId) {
      // init
      console.log("list_detail_id:" + detailId )
      this.tableHeader = [];
      this.tableHeaderSub = [];

      try {
        // const detailId = detailId;
        const masterId = this.masterId;
     
        const response = await this.$axios.get(
          "/api/mng/inquiry/v1/inq-status/list",
          {
            params: { detailId, masterId },
          }
        );
        if (!response.data.success) throw new Error("API Fail");
        const headerInfos = response.data.result;
        for (const headerInfo of headerInfos) {
          const tableHeader = {
            itemContents: headerInfo.itemContents,
            colSpan: headerInfo.subItems.length,
          };
          this.tableHeader.push(tableHeader);
          for (const subHeaderInfo of headerInfo.subItems) {
            console.log(subHeaderInfo);
            const tableSubHeader = {
              mappingId: subHeaderInfo.mappingId,
              itemContents: subHeaderInfo.itemContents,
            };
            this.tableHeaderSub.push(tableSubHeader);
          }
        }
        this.getInquiryAnswer(0);
      } catch (_error) {
        this.openAlert("문진표 정보를 불러오는 도중 오류가 발생했습니다.");
        console.log("리스트 오류")
      }
    },
    getInquiryAnswer: async function (page) {
      if (page < 0 || this.page.totalPages < page) {
        return;
      }
      // init
      this.tableList = [];
      this.queryList = this.$route.query.visitorList;
      // console.log("route:"+ this.queryList);
      try {
      // console.log("answer_detail_id: "+ this.detailId)
        let param = {};

          param = {
            page: page,
            size: 30,
            detailId: this.detailId,
            answerType: this.queryList.type,
            patNo: this.queryList.patno,
            phoneNum: this.queryList.phone,
            // name: this.queryList.name,
            startDate:this.queryList.reserveDate,
            endDate: this.queryList.reserveDate, 
          };
        
        const response = await this.$axios.get(
          "/api/mng/inquiry/v1/inq-status/personal-answer",
          {
            params: param,
          }
        );
        if (!response.data.success) throw new Error("API Fail");
        this.tableList = response.data.result.content;
        let pageInfo = response.data.result;

        this.pagerHandler(
          pageInfo.number,
          pageInfo.first,
          pageInfo.last,
          pageInfo.totalPages
        );
      } catch (_error) {
        this.openAlert("문진표 정보를 불러오는 도중 오류가 발생했습니다.");
        console.log("답변 오류")
      }
    },
    openSearch: () => $(".search").slideToggle("down"),
    confirmDownload: function () {
      this.downloadConfirm(this.confirmExcelDown);
    },
    
    checkPatNo: function (e) {
      this.searchPatNo = e.target.value.replace(/[^a-z|A-Z|0-9]/gi, "");
    },
    checkPatName: function (e) {
      this.searchPatName = e.target.value.replace(
        /[^ㄱ-ㅎ|가-힣|a-z|A-Z|\s]/gi,
        ""
      );
    },
    checkPhoneNum: function (e) {
      this.searchPhoneNum = e.target.value.replace(/[^0-9|-]/gi, "");
    },
    changeStartDate(event) {
        this.searchStartDate = event.target.value;
    },
    changeEndDate(event) {
        this.searchEndDate = event.target.value;
    },
    setStartDate: function (date) {
      this.searchStartDate = date;
    },
    setEndDate: function (date) {
      this.searchEndDate = date;
    },
    resetFilter: function () {
      this.searchPatNo = null;
      this.searchPatName = null;
      this.searchPhoneNum = null;
      this.searchStartDate = null;
      this.searchEndDate = null;
    },
    closeSearch: function () {
      $(".search").slideToggle("up");
    },
    changeFilter: function () {
        this.filter = !this.filter;
        if (!this.filter) {
            this.initSearchCnd();
        }
    },
    initSearchCnd: function () {
      this.searchPatNo = null;
      this.searchPatName = null;
      this.searchPhoneNum = null;
      this.searchDeniedYn = 'all';
      this.startDate = moment().format("YYYY-MM-DD");
      this.endDate = moment().format("YYYY-MM-DD");
    },
    changeDeniedYn: function (event) {
      this.searchDeniedYn = event.target.value;
    },
    goBack: function(){
      this.$router.go(-1);
    },
    getDetailId: async function(){
      this.queryList = this.$route.query.visitorList;
      let param ={};
      param = {
            identityNo: this.queryList.identityNo,
            visitDate: this.queryList.reserveDate,
            type: this.queryList.type
          };
       
        const response = await this.$axios.get(
          "/api/mng/inquiry/v1/inq-status/get-detailId",
          {
            params: param,
          }
        );
        if (!response.data.success) throw new Error("API Fail");
        this.detailId = response.data.result[0].detailId;
        
        this.getInquiryHeader(this.detailId);



    }    
  },
  created(){
    this.getDetailId(); // 해당 문진의 detailId를 얻기 위해 answerMaster를 조회한다
    console.log("detail_Id :"+ this.detailId)
    
  },
  mounted() {
    // this.getInquiryVersion(this.masterId); // 문진표 버전 조회 및 초기데이터 로딩
    // console.log("detail_Id :"+ this.detailId)
    
    // console.log(today);
   
    if(this.$route.query.page) {
      this.setStartDate(this.$route.query.startDate)
      this.setEndDate(this.$route.query.endDate)
      // this.getGstListResult(this.$route.query.page);
      
    } else {
      const today = moment().format("YYYY-MM-DD");
      this.searchStartDate = today;
      this.searchEndDate = today;
      // this.getGstListResult(0);
    }
  },
  watch: {

  },
};
</script>

<style lang="scss" scoped>
h3 {
  height: 50px;
  .btn-right {
    width: 100%;
    display: flex;
    > select {
      margin-right: auto;
      height: 30px;
      line-height: 30px;
      align-self: flex-end;
    }
    > label {
      padding: 0;
      margin-right: 10px;
      margin-bottom: 3px;
      align-self: flex-end;
    }
  }
}
 .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
.search {
  top: 36px;
}

.date-period {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
th {
  border: solid 1px #e8e9eb;
  &.sub-header {
    word-break: keep-all;
    max-width: 160px;
  }
}
.responsive table {
  width: 3800px !important;
  border-collapse: separate !important;
  border-spacing: 0px !important;
}

table tr:nth-child(1) th {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 50px;
  border: solid 1px #e8e9eb;
}

table tr:nth-child(2) th {
  position: sticky;
  top: 50px;
  z-index: 5;
  border: solid 1px #e8e9eb;
}

table tr:nth-child(1) th:nth-child(1) {
  left: 0px;
  z-index: 10;
}
table tr:nth-child(1) th:nth-child(2) {
  left: 77.2px;
  z-index: 10;
}
table tr:nth-child(1) th:nth-child(3) {
  left: 207.2px;
  z-index: 10;
}
table tr:nth-child(1) th:nth-child(4) {
  left: 337.2px;
  z-index: 10;
}
table tr:nth-child(1) th:nth-child(5) {
  left: 467.2px;
  z-index: 10;
}

table tr:nth-child(2) th:nth-child(1) {
  width: 50px;
  left: 0px;
  z-index: 10;
}
table tr:nth-child(2) th:nth-child(2) {
  width: 130px;  
  left: 77.2px;
  z-index: 10;
}
table tr:nth-child(2) th:nth-child(3) {
  width: 130px;
  left: 207.2px;
  z-index: 10;
}
table tr:nth-child(2) th:nth-child(4) {
  width: 130px;
  left: 337.2px;
  z-index: 10;
}
table tr:nth-child(2) th:nth-child(5) {
  width: 130px;
  left: 467.2px;
  z-index: 10;
}
table tr:nth-child(2) th:nth-child(6) {
  width: 130px;
  left: 597.2px;
  z-index: 10;
}

table td:nth-child(1) {
  position: sticky;
  width: 50px;
  left: 0;
  z-index: 5;
  border-bottom: solid 1px #e8e9eb;
  background: #ffffff;
}
table td:nth-child(2) {
  position: sticky;
  width: 130px;
  left: 77.2px;
  z-index: 5;
  border-bottom: solid 1px #e8e9eb;
  background: #ffffff;
}
table td:nth-child(3) {
  position: sticky;
  width: 130px;
  left: 207.2px;
  z-index: 5;
  border-bottom: solid 1px #e8e9eb;
  background: #ffffff;
}
table td:nth-child(4) {
  position: sticky;
  width: 130px;
  left: 337.2px;
  z-index: 5;
  border-bottom: solid 1px #e8e9eb;
  background: #ffffff;
}
table td:nth-child(5) {
  position: sticky;
  width: 130px;
  left: 467.2px;
  z-index: 5;
  border-bottom: solid 1px #e8e9eb;
  background: #ffffff;
}
table td:nth-child(6) {
  position: sticky;
  width: 130px;
  left: 597.2px;
  z-index: 5;
  border-bottom: solid 1px #e8e9eb;
  background: #ffffff;
}

.responsive {
  overflow: auto;
  height: 600px;
}
.search {
  z-index: 11;
}
.noShowBox{
  display: none;
}
</style>
