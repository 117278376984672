<template>

    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">마스터관리</a>
            </li>
            <li>
                <a href="javascript:void(0)">분류코드관리</a>
            </li>
            <li>
                <a href="javascript:void(0)">분류코드 신규등록</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>분류코드 신규등록</h2>
            </div>
            <div class="register">

                <dl>
                    <dt>분류코드</dt>
                    <dd><input type="text" v-model="classCode" readonly/></dd>
                </dl>
                <dl>
                    <dt>분류명</dt>
                    <dd><input type="text" v-model="className"/></dd>
                </dl>
                <dl>
                    <dt>비고</dt>
                    <dd><input type="text" v-model="comment"/></dd>
                </dl>
                <dl>
                    <dt>사용여부</dt>
                    <dd>
                        <select v-model="useYn">
                            <option value="Y">사용</option>
                            <option value="N">미사용</option>

                        </select>
                    </dd>
                </dl>
            </div>
            <div class="btn_wrap">
                <div class="btn_left">
                    <button class="btn_secondary" @click="goPrev()">취 소</button>
                    <button class="btn_primary" @click="confirmSave()">저 장</button>
                </div>
                
            </div>
        </div>
    </div>

</template>

<script>
    import mixin from '../../mixin/mixin.js';

    export default {
        data: () => {
            return {className: null, classCode: null, comment: null, useYn: "Y"}
        },
        mixins: [mixin],
        created: function (){
            this.classCode = this.$route.query.classCode;
        },
        methods: {
            confirmSave: function () {
                if (this.classCode == null) {
                    this.openAlert("분류코드는 필수 항목입니다.");
                    return;
                }
                if (this.classCode.trim() == '') {
                    this.openAlert("분류코드는 필수 항목입니다.");
                    return;
                }
                if (/[^a-zA-Z|\s]/.test(this.classCode)) {
                    this.openAlert("분류코드는 영문자만 사용가능합니다.");
                    return;
                }
                if (this.classCode.length > 10) {
                    this.openAlert("분류코드는 10자를 넘을 수 없습니다.");
                    return;
                }
                if (this.className == null) {
                    this.openAlert("분류명은 필수 항목입니다.");
                    return;

                }
                if (this.className.trim() == '') {
                    this.openAlert("분류명은 필수 항목입니다.");
                    return;
                }
                if (this.className.length > 20) {
                    this.openAlert("분류명은 20자를 넘을 수 없습니다.");
                    return;
                }
                if (this.comment != null) {
                    if (this.comment.length > 50) {
                        this.openAlert("분류명은 50자를 넘을 수 없습니다.");
                        return;
                    }
                }

                this.openConfirm("해당 내용을 저장하시겠습니까?", this.save);

            },
            save: function () {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                this
                    .$axios
                    .post('/api/mng/service/master/v1/place-class', {
                        className: this.className,
                        classCode: this.classCode,
                        comment: this.comment,
                        useYn: this.useYn

                    })
                    .then((response) => {
                        if (response.data.success) {
                            this.openAlertClosed("저장하였습니다.", this.goPrev);

                        } else {
                            this.openAlert("저장중 오류가 발생했습니다.");
                        }

                        this.btnLock = false;
                    })
                    .catch((err) => {
                        console.log(err);
                        this.openAlert("저장중 오류가 발생했습니다.");
                        this.btnLock = false;
                    })
                },
            goPrev: function () {
                this
                    .$router
                    .push({name: 'placeClass'})
            }

        }
    }
</script>