<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="">출입</a>
            </li>
            <li>
                <a href="">출입증 발급 현황</a>
            </li>
        </div>
        <div class="wapper" style="min-height:800px">
            <div class="title">
                <h2>출입증 발급 현황</h2>
                <p class="sub_title">{{convertDateDay(new Date())}}
                    기준 출입증 발급 목록입니다. (총
                    {{totalCount}}명)</p>
                <div class="btn-right">
                    <button class="btn_search" @click="openSearch()">검색</button>
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">
                            <dl>
                                <dt>구분</dt>
                                <dd>
                                    <select v-model="answerType" @change="changeAnswerType">
                                        <option v-for="(list,idx) in answerTypeList" :key="idx" :value=list.code>{{ list.name }}</option>
                                    </select>
                                </dd>
                            </dl>
                            <dl class="full_width">
                                <dt>발급일시</dt>
                                <dd>
                                    <input type='date' v-model='startDate' @change="changeStartDate"/>
                                    ~
                                    <input type='date' v-model='endDate' @change="changeEndDate"/>
                                </dd>
                            </dl>
                            <dl class="full_width">
                                <dt>이름</dt>
                                <dd>
                                    <input type="text" v-model="identityName"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>발송번호</dt>
                                <dd>
                                    <input type="text" v-model="identityNo"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>발급채널</dt>
                                <dd>
                                    <select v-model="deviceType">
                                        <option value="all">전체</option>
                                        <option value="K">현장문진</option>
                                        <option value="M">사전문진</option>
                                        <option value="A">환자상세</option>
                                    </select>
                                </dd>
                            </dl>
                            <dl class="full_width">
                                <dt>발급상태</dt>
                                <dd>
                                    <select v-model="status">
                                        <option value="">전체</option>
                                        <option value="Y">미발급</option>
                                        <option value="N">정상</option>
                                    </select>
                                </dd>
                            </dl>
                        </div>
                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'btn_filered' : filter}"
                                @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                            <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table table_line">
                <table>
                    <colgroup>
                        <col width="10%"/>
                        <col/>
                        <col/>
                        <col/>
                        <col/>
                        <col/>
                        <col width="30%"/>
                        <col/>
                        <col/>
                        <col/>
                        <col width="8%"/>
                    </colgroup>
                    <tr>
                        <th>발급일시</th>
                        <th>출입구분</th>
                        <th>고유번호</th>
                        <th>이름</th>
                        <th>발급채널</th>
                        <th>상태</th>
                        <th>발급불가사유</th>
                        <th>출입증 발급 직원</th>
                        <th>출입증 발급사유</th>
                        <th>출입증 발급일시</th>
                        <th>조치</th>
                    </tr>
                    <tr v-for="(item, index) in this.passResult" :key="index">
                        <td>{{ convertDate(item.answerDate)}}</td>
                        <!-- <td>{{item.answerTypeNm}}</td> -->
                        <td>{{convertAnswerType(item.answerType, item.answerTypeNm)}}</td>
                        <td>{{convertIdentityNo(item.identityNo)}}</td>
                        <td>{{item.identityName}}</td>
                        <td>{{convertDeviceType(item.deviceType)}}</td>
                        <td>{{item.agreePassYn === 'Y' ? '예외출입 허용' : item.status}}</td>
                        <td>{{item.statusReason}}</td>
                        <td>{{item.agreeRegUser}}</td>
                        <td>{{item.agreePassReason}}</td>
                        <td>{{convertDate(item.agreePassDate)}}</td>
                        <td>
                            <button
                                class="etcBtn"
                                v-if="item.status == '미발급' && item.agreePassYn != 'Y' && item.visitDate >= nowDate"
                                @click="openTicket(item)">출입증 발급</button>
                            <button
                                class="etcBtn"
                                v-if="item.answerType == 'PT' || item.answerType == 'RPT' || item.answerType == 'PEMP'"
                                @click="permitReInq(item)">재문진 허용</button>                                

                            <button
                                class="etcBtn"
                                v-if="item.limitedPrintYn == 'N' && item.visitDate >= nowDate"
                                @click="reprintPermit(item)">재인쇄 허용</button>

                            <button
                                class="etcBtn"
                                v-if="item.answerType == 'PT'"
                                @click="openClinic(item)">선별진료소안내</button>                                
                        </td>
                    </tr>
                    <!-- <tr> <td class="guardian">보호자</td> <td>Z22222</td> <td>김철수</td>
                    <td>사전문진</td> <td>미발급</td> <td>증상</td> <td>2021/01/17 12:01:01</td> </tr> -->
                </table>
            </div>
            <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" 
            :startDate="startDate" :endDate="endDate" :answerType='answerType' :answerTypeNm='answerTypeNm'
            :identityName="identityName"
            :deviceType="deviceType"
            :status="status"
            :identityNo="identityNo"
            />
        </div>
        <!-- 팝업 -->
        <modal
            name="addMaster"
            :width="540"
            :height="360"
            :adaptive="true"
            :clickToClose="false">
            <div id="popup_reg" class="pop pop_md pop_reg">
                <!-- 출입증 발급 -->
                <div class="pop_head">
                    <h2>출입증 발급</h2>
                    <a class="btn_pop_close" @click="closePop()">닫기</a>
                </div>
                <div class="pop_body">
                    <h4 class="modal-title">미발급 내용 확인</h4>
                    <dl>
                        <dt class="modal-title">고유번호</dt>
                        <dd>
                            <input type="text" disabled="disabled" v-model="identityNo"/></dd>
                    </dl>
                    <dl>
                        <dt class="modal-title">이름</dt>
                        <dd>
                            <input type="text" disabled="disabled" v-model="identityName"/></dd>
                    </dl>
                    <dl>
                        <dt class="modal-title">발급불가사유</dt>
                        <dd>
                            <input type="text" disabled="disabled" v-model="statusReason"/></dd>
                    </dl>
                    <dl>
                        <dt class="modal-title">출입증 발급사유
                        </dt>
                        <dd>
                            <select class="sBox" v-model="passReason">
                                <option value="" disabled="disabled" selected="selected">출입증 발급사유를 선택해주세요.</option>
                                <option value="분만장 응급 내원환자">분만장 응급 내원환자</option>
                                <option value="선별진료소 확인">선별진료소 확인
                                </option>
                                <option v-if="this.susikLabel != null" :value="this.susikLabel">{{this.susikLabel}}
                                </option>
                            </select>
                        </dd>
                    </dl>
                </div>
                <div class="pop_footer">
                    <button class="btn_secondary" @click="closePop()">취 소</button>
                    <button class="btn_primary" @click="next()">출입증 발급</button>
                </div>
            </div>
        </modal>
        <modal
        name="openClinic"
        :width="540"
        :height="360"
        :adaptive="true"
        :clickToClose="false">
        <div id="popup_reg" class="pop pop_md pop_reg">
            <!-- 출입증 발급 -->
            <div class="pop_head">
                <h2>선별진료안내</h2>
                <a class="btn_pop_close" @click="closeClinic()">닫기</a>
            </div>
            <div class="pop_body">
                <h4 class="modal-title">미발급 내용 확인</h4>
                <dl>
                    <dt class="modal-title">고유번호</dt>
                    <dd>
                        <input type="text" disabled="disabled" v-model="identityNo"/></dd>
                </dl>
                <dl>
                    <dt class="modal-title">이름</dt>
                    <dd>
                        <input type="text" disabled="disabled" v-model="identityName"/></dd>
                </dl>
                <dl>
                    <dt class="modal-title">발급불가사유</dt>
                    <dd>
                        <input type="text" disabled="disabled" v-model="statusReason"/></dd>
                </dl>
            </div>
            <div class="pop_footer">
                <button class="btn_secondary" @click="closeClinic()">취 소</button>
                <button class="btn_primary" @click="toScreeningclinic(identityNo)">선별진료소안내저장</button>
            </div>
        </div>
    </modal>
        <modal
            name="alertMsg"
            :width="540"
            :height="190"
            :adaptive="true"
            :clickToClose="false">
            <div id="popup_reg" class="pop pop_md">
                <!-- 새 문진 등록 -->
                <div class="pop_head">
                    <h2>알림</h2>
                    <a class="btn_pop_close">닫기</a>
                </div>
                <div class="pop_body">
                    {{errMsg}}
                </div>
                <div class="pop_footer">
                    <button class="btn_secondary" @click="closeAlert()">확 인</button>
                </div>
            </div>
        </modal>
        <modal
            name="renewList"
            :width="540"
            :height="190"
            :adaptive="true"
            :clickToClose="false">
            <div id="popup_reg" class="pop pop_md">
                <div class="pop_head">
                    <h2>알림</h2>
                    <a class="btn_pop_close">닫기</a>
                </div>
                <div class="pop_body">
                    {{errMsg}}
                </div>
                <div class="pop_footer">
                    <button class="btn_secondary" @click="closeAlert()">확 인</button>
                </div>
            </div>
        </modal>
    </div>

</template>
<script>
    import pagination from '../../components/commonPagination.vue'
    import permitReprint from '../../components/permiterReprint.vue'
    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';
    import $ from 'jquery'

    export default {
        data: () => {
            return {
                passResult: [], answerType: "all", // 응답자 타입.
                answerTypeNm: null, // 응답자 타입이름.
                identityNo: null, // 식별번호
                identityName: null, // 식별이름
                deviceType: "all", // 장치 타입
                status: "", // 상태
                startDate: moment()
                    .format("YYYY-MM-DD"), // 검색 시작일
                endDate: moment().format("YYYY-MM-DD"), // 검색 종료일
                totalCount: 0, // 총 인원
                masterList: [], //출입구분 리스트
                filter: false, //검색필터 적용여부.
                statusReason: '', // 발급불가사유
                passReason: '',
                errMsg: null,
                susikLabel: null,
                answerId: null,
                btnLock: false,
                page: [],
                nowDate: moment().format("YYYY-MM-DD")
            }
        },
        mixins: [mixin],
        mounted: function() {
            this.nowDate = moment().format("YYYY-MM-DD");
            if(this.$route.query.page) {
                this.changeFilter()
                this.startDate=this.$route.query.startDate|| moment().format("YYYY-MM-DD")
                this.endDate=this.$route.query.endDate ||moment().format("YYYY-MM-DD")
                this.answerType=this.$route.query.answerType || ''
                this.answerTypeNm=this.$route.query.answerTypeNm || ''
                this.identityName = this.$route.query.identityName ||null
                this.identityNo=this.$route.query.identityNo || null
                this.deviceType = this.$route.query.deviceType || null
                this.status = this.$route.query.status || null
                this.getPassResult(this.$route.query.page);
            } else {
                this.getPassResult(0);
            }
            this.getAllMaster();    //이건 뭐하는 API인지 ? 
        },
        components : {
            pagination
        },
        methods: {
            getPassResult: function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }

                let params = {};

                if (this.filter) {
                    let filterObj = {
                        page: page,
                        size: 30,
                        answerType: this.answerType, // 응답자 타입.
                        answerTypeNm: this.answerTypeNm, // 응답자 타입 이름
                        identityNo: this.identityNo, // 식별 번호
                        identityName: this.identityName, // 식별이름
                        deviceType: this.deviceType, // 장치 타입
                        status: this.status, //발급상태
                        startDate: this.startDate, // 검색 시작일자
                        endDate: this.endDate, //검색 종료일자
                    }
                    params = filterObj;
                } else {
                    let filterObj = {
                        page: page,
                        size: 30,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                    params = filterObj;
                }
                this
                    .$axios
                    .get('/api/mng/entrance/v2/pass', {params: params})
                    .then((response) => {
                        console.log(response.data);
                        this.passResult = response.data.result.content;

                        let pageInfo = response.data.result;
                        this.totalCount = pageInfo.totalElements;

                        this.pagerHandler(
                            pageInfo.number,
                            pageInfo.first,
                            pageInfo.last,
                            pageInfo.totalPages
                        );
                    })
            },
            firstSearch: function () {
                let pageQuery = {
                    page : 0,
                    startDate:this.startDate || null,
                    endDate:this.endDate || null,
                    answerType:this.answerType || null,
                    answerTypeNm:this.answerTypeNm || null,
                    identityName:this.identityName || null,
                    identityNo:this.identityNo || null,
                    deviceType:this.deviceType || null,
                    status:this.status || null
                }
                this.$router.push({
                    path: this.$route.path,
                    query: pageQuery
                }).catch(()=>{});
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            confirmDownload: function () {
                this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
                console.log(param.purpose);
                console.log(param.purpose_etc);
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            reprintPermit: function(item){
                this
                    .$modal
                    .show(permitReprint, {
                       setReprintYn : this.setReprintYn,
                       IdNo : item.identityNo
                    }, {
                        width: '540px',
                        height: 'auto',
                        adaptive: true,
                        clickToClose: false
                    },)
                    
            },
            setReprintYn: function(IdNo) {
                // this.searchReprintYn(IdNo);
                this.saveReprintYn(IdNo);
            },
            // searchReprintYn: function(IdNo) {
            //     if (this.btnLock) {
            //         return;
            //     } else {
            //         this.btnLock = true;
            //     }
            //     this
            //     .$axios
            //     .get('/api/print/v1/pass-log', {
            //         params: {
            //             identityNo: IdNo,
            //             visitDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            //             passType: "K"
            //         }
            //     })
            //     .then((response) => {
            //         console.log(response);

            //         if (response.data.resultCd == 0) {
            //             this.openAlert('인쇄가 제한된 사용자가 아닙니다.');
            //         } else {
            //             this.saveReprintYn(IdNo)
            //         }
            //         this.btnLock = false;
            //     })
            //     .catch(() => {
            //         this.openAlert('저장중 오류가 발생했습니다.');
            //         this.btnLock = false;
            //     })
            // },
            saveReprintYn: function(IdNo){
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                this
                .$axios
                .put('/api/print/v1/pass-log', {
                    identityNo: IdNo,
                    visitDate: moment().format("YYYY-MM-DD"),
                    passType: "K"
                })
                .then((response) => {
                    console.log(response);

                    if (response.data.resultCd == 0) {
                        this.openAlert('출입증 인쇄 제한을 해제했습니다.');
                    } else {
                        this.openAlert('저장중 오류가 발생했습니다.');
                    }
                    this.btnLock = false;
                })
                .catch(() => {
                    this.openAlert('저장중 오류가 발생했습니다.');
                    this.btnLock = false;
                })
            },
            excelDown: function (paramdata) {
                let params = {};
                if (this.filter) {
                    let filterObj = {
                        page: 0,
                        size: 300000,
                        answerType: this.answerType, // 응답자 타입.
                        answerTypeNm: this.answerTypeNm, // 응답자 타입 이름
                        identityNo: this.identityNo, // 식별 번호
                        identityName: this.identityName, // 식별이름
                        deviceType: this.deviceType, // 장치 타입
                        status: this.status, //발급상태
                        startDate: this.startDate, // 검색 시작일자
                        endDate: this.endDate, //검색 종료일자
                    }
                    params = filterObj;
                } else {
                    let filterObj = {
                        page: 0,
                        size: 300000,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                    params = filterObj;
                }
                this
                    .$axios
                    .get('/api/mng/entrance/v1/pass/excel', {
                        params: params,
                        responseType: "blob"
                    })
                    .then((response) => {
                        console.log(response);
                        const url = window
                            .URL
                            .createObjectURL(
                                new Blob([response.data], {type: response.headers['content-type']})
                            );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute(
                            'download',
                            '출입증발급현황_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx'
                        );
                        document
                            .body
                            .appendChild(link);
                        link.click();

                        if (response.status == 200) {
                            let param = {
                                fileName: '출입증발급현황_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                                fileSize: response.data.size,
                                purpose: paramdata.purpose,
                                purposeEtc: paramdata.purpose_etc == null
                                    ? ''
                                    : paramdata.purpose_etc
                            }
                            this.setDownloadHistory(param);
                        }
                    })
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            startDateFunc: function (date) {
                this.startDate = date;
            },
            endDateFunc: function (date) {
                this.endDate = date;
            },
            getAllMaster: function () {
                this
                    .$axios
                    .get('/api/mng/inquiry/v1/all-master')
                    .then((response) => {
                        this.masterList = response.data.result;
                    })
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.answerType = "all"; // 응답자 타입.
                this.answerTypeNm = null; // 응답자 타입 이름
                this.identityNo = null; // 식별 번호
                this.identityName = null; // 식별이름
                this.deviceType = "all"; // 장치 타입
                this.status = ""; //발급상태
                this.startDate = moment()
                    .add(-7, 'd')
                    .format("YYYY-MM-DD"); // 검색 시작일자
                this.endDate = moment().format("YYYY-MM-DD"); //검색 종료일자
            },
            changeStartDate(event) {
                this.startDate = event.target.value;
            },
            changeEndDate(event) {
                this.endDate = event.target.value;
            },
            convertDeviceType: function (value) {
                if (value != null) {
                    if (value == 'K') 
                        value = '현장문진';
                    else if (value == 'M') 
                        value = '사전문진';
                    else if (value == 'A') 
                        value = '환자상세';
                    }
                return value
            },
            changeAnswerType(event) {
                this.answerType = event.target.value;
            },
            openTicket: function (item) {
                this.answerId = item.answerId;
                this.identityNo = item.identityNo;
                this.identityName = item.identityName;
                this.statusReason = item.statusReason;
                if (item.susikLabel != null) {
                    this.susikLabel = item.susikLabel;
                } else {
                    this.susikLabel = null;
                }

                this
                    .$modal
                    .show('addMaster');
            },
            //선별진료저장 확인모듈창 
            openClinic: function(item){
                this.identityNo = item.identityNo;
                this.identityName = item.identityName;
                this.statusReason = item.statusReason;
                this.$modal.show('openClinic');
            },
            //선별진료저장 확인모듈창 닫기
            closeClinic: function () {
                this.identityNo = null;
                this.identityName = null;
                this.statusReason = '';
                this.btnLock = false;
                this
                    .$modal
                    .hide('openClinic');
            },
            closePop: function () {
                this.answerId = null;
                this.identityNo = null;
                this.identityName = null;
                this.statusReason = '';
                this.susikLabel = '';
                this.passReason = '';
                this.btnLock = false;
                this
                    .$modal
                    .hide('addMaster');
            },
            next: function () {
                if (this.answerId == null || this.answerId == "") {
                    this.errMsg = "등록번호가 유효하지 않습니다. 다시 시도해 주세요.";
                    this
                        .$modal
                        .show('alertMsg');
                    return;
                }
                if (this.identityNo == null || this.identityNo == "") {
                    this.errMsg = "고유번호가 유효하지 않습니다. 다시 시도해 주세요.";
                    this
                        .$modal
                        .show('alertMsg');
                    return;
                }
                if (this.statusReason == null || this.statusReason == "") {
                    this.errMsg = "발급불가사유가 유효하지 않습니다. 다시 시도해 주세요.";
                    this
                        .$modal
                        .show('alertMsg');
                    return;
                }
                if (this.passReason == null || this.passReason == "") {
                    this.errMsg = "출입증 발급사유를 선택하세요.";
                    this
                        .$modal
                        .show('alertMsg');
                    return;
                }

                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                this
                    .$axios
                    .post('/api/mng/entrance/v1/pass/agree-pass', {
                        answerId: this.answerId,
                        agreePassReason: this.passReason
                    })
                    .then((response) => {
                        switch (response.data.resultCd) {
                            case 302:
                                this.errMsg = "문진에 대한 응답결과가 없습니다.";
                                this
                                    .$modal
                                    .show('alertMsg');
                                break;
                            case 305:
                                this.errMsg = "만료된 문진데이터 입니다.";
                                this
                                    .$modal
                                    .show('alertMsg');
                                break;
                            case 0:
                                this.errMsg = "예외출입 등록이 완료되었습니다.";
                                this
                                    .$modal
                                    .show('renewList');
                                break;
                            default:
                                throw new Error('정의되지 않은 resultCd 오류');
                        }

                    })

            },
            permitReInq: async function (item) {
                // 문진 사용 여부 확인
                let isInquiry = await this.checkInquiryUse(item);
                console.log(isInquiry)
                if(isInquiry === undefined){
                    console.log("aaaaaaaaaaaaaaaaa")
                    this.openAlertClosed('재문진 허용 중 오류가 발생했습니다.');
                    return;
                } else if (isInquiry === false){
                    this.openAlertClosed(this.convertAnswerType(item.answerType, item.answerTypeNm) + " 문진이 미사용 중입니다.");
                    return;
                }

                //재문진 허용 테이블 저장
                this
                    .$axios
                    .post('/api/mng/entrance/v1/pass/re-inquiry', {identityNo: item.identityNo})
                    .then((response) => {

                        if (response.data.success) {
                            console.log(response.data.identityNo);
                            
                            this.openAlert("재문진 허용이 완료 되었습니다.");
                            this.btnLock = false;
                            this.closePop();

                        } else if(response.data.resultCd == '300'){
                            this.openAlertClosed('요청한 사용자가 없습니다.');
                            this.btnLock = false;
                        }else if(response.data.resultCd == '-1'){
                            this.openAlertClosed('저장 중 오류가 발생했습니다.');
                            this.btnLock = false;
                        }else{
                            this.openAlertClosed('재문진 허용 중 오류가 발생했습니다.');
                            this.btnLock = false;
                        }

                    })
                    .catch((err) => {
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                        console.log(err.message);
                        this.errMsg = "재문진 허용 중 오류가 발생했습니다.";
                        this
                            .$modal
                            .show('alertMsg');
                        this.closePop();
                        this.btnLock = false;
                    });

            },   
            checkInquiryUse: async function(item) {
                let params = { inqType: item.answerType == 'RPT' ? 'ADMINER' : item.answerType }
                if(item.answerType == 'PT' ) {
                    params = { inqType: item.answerType,  ptNo: item.identityNo, visitDate: item.visitDate.replace(/-/g, '')};
                } 
                let response = await this.$axios.get("/api/v1/answerUseYn", {params: params});
                if(response.data.result.useYn === "Y"){
                    console.log("ccccc")
                    return true;
                } else {
                    return false;
                }
                    // .then((response)=>{
                    //     console.log("vvvvvvvv")
                    //     if(response.data.result.useYn === "Y"){
                    //         console.log("ccccc")
                    //         return true;
                    //     } else {
                    //         return false;
                    //     }
                    // })
                    // .catch((err)=>{
                    //     console.log(err);
                    // })
            },         
            closeAlert: function () {
                if (this.page.number != null) {
                    this.getPassResult(this.page.number);
                    this.errMsg = null;
                    this
                        .$modal
                        .hide('renewList');
                    this.closePop();
                } 
                this.errMsg = null;
                this
                    .$modal
                    .hide('alertMsg');
            },
            toScreeningclinic: function (identityNo) {
                //출입시 발열이 감지된 경우 선별진료소로 안내
                this
                    .$axios
                    .post('/api/mng/entrance/v1/pass/toScreeningclinic', {identityNo: identityNo})
                    .then((response) => {

                        if (response.data.success) {
                            // console.log(response.success);
                            
                            this.openAlert("선별진료소로 안내가 완료되었습니다.");
                            this.btnLock = false;
                            this.closePop();
                            this.closeClinic();

                        }
                        else{
                            this.openAlertClosed('저장 중 오류가 발생했습니다.');
                            this.btnLock = false;
                            this.closeClinic();
                        }

                    })
                    .catch((err) => {
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                        console.log(err.message);
                        this.errMsg = "저장 중 오류가 발생했습니다.";
                        this
                            .$modal
                            .show('alertMsg');
                        this.closePop();
                        this.btnLock = false;
                        this.closeClinic();
                    });

            },

        }
    }
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
    .pop {
        display: inline-block;
        box-shadow: none;
    }
    .modal-title {
        color: #818181;
    }
    .sBox {
        width: 100%;
    }
</style>