<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">출입자</a>
            </li>
            <li>
                <a href="javascript:void(0)">출입현황</a>
            </li>
        </div>
        <div class="wapper" style="min-height:800px">
            <div class="title">
                <h2>출입현황</h2>
                <p class="sub_title">{{convertDateDay(new Date())}} 기준 출입현황입니다.(총
                    {{totalCount}}명)</p>
            </div>
            <h3>
                <div class="btn-right">
                    <button class="btn_search" @click="openSearch()">검색</button>
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">
                            <dl class="full_width">
                                <dt>출입구분</dt>
                                <dd><select v-model="answerType" @change="changeAnswerType">
                                    <option v-for="(list,idx) in answerTypeList" :key="idx" :value=list.code>{{ list.name }}</option>
                                </select></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>고유번호</dt>
                                <dd>
                                    <input type="text" v-model="identityNo"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>입장장소</dt>
                                <dd><input type="text" v-model='place_name'/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>입장/퇴장 일시</dt>
                                <dd>
                                    <input type='date' v-model='startDate' @change="changeStartDate"/>
                                    ~
                                    <input type='date' v-model='endDate' @change="changeEndDate"/>
                                </dd>
                            </dl>
                            <dl class="full_width">
                                <dt>이름</dt>
                                <dd><input type="text" v-model='identity_name'/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>결과</dt>
                                <dd><select v-model="event_type_code" @change="changeEventTypeCode">
                                    <option value=''>전체</option>
                                    <option value='4102'>정상</option>
                                    <option value='4354'>차단</option>
                                    <!--
                                    <option value='6147'>시간 초과</option>
                                    <option value='6419'>기준 온도 초과</option>
                                    <option value='6421'>마스크 착용 안함</option>
                                    <option value='6420'>온도 측정 실패</option>
                                    <option value='6403'>출입 유효기간 만료</option>
                                    <option value='6405'>하드 안티패스백 위반</option>
                                    <option value='6408'>소프트 안티패스백 위반</option>
                                    <option value='4354'>등록되지 않은 카드</option>
                                    -->
                                </select></dd>
                            </dl>
                        </div>
                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'btn_filered' : filter}"
                                @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                            <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
                        </div>
                    </div>
                </div>
            </h3>
            <div class="table table_line">
                <table>
                    <colgroup>
                        <col width="5%"/>
                        <col width="10%"/>
                        <col width="5%"/>
                        <col width="10%"/>
                        <col width="10%"/>
                        <col width="10%"/>
                        <col width="15%"/>
                        <col width="10%"/>
                        <col width="10%"/>
                        <col width="10%"/>
                    </colgroup>
                    <tr>
                        <th colspan="3"></th>
                        <th colspan="5">입장</th>
                        <th colspan="2">퇴장</th>
                    </tr>
                    <tr>
                        <th>출입구분</th>
                        <th>방문목적</th>
                        <th>고유번호</th>
                        <th>이름</th>
                        <th>입장장소</th>
                        <th>입장결과</th>
                        <th>비고</th>
                        <th>입장일시</th>
                        <th>퇴장장소</th>
                        <th>퇴장일시</th>
                    </tr>
                    <tr v-for="(item,index) in this.list" :key="index">
                        <td>{{convertAnswerType(item.answer_type)}}</td>
                        <td>{{convertPurpose(item.visit_reason)}}</td>
                        <td>{{convertIdentityNo(item.identity_no)}}</td>
                        <td>
                            {{item.identity_name_in}}
                            <label v-if="item.blackYn == 'Y'" class="btn_primary btn_md_custom">B</label>
                        </td>
                        <td>{{item.place_name_in}}</td>
                        <td>{{convertEventTypeCode(item.event_type_code)}}</td>
                        <td>{{convertEventTypeNm(item.event_type_code)}} {{item.event_type_code != '4102' ? '(' + item.reg_date_in + ')' : null}} </td>
                        <td>{{ item.event_type_code === '4102' ? item.reg_date_in : null}}</td>
                        <td>{{item.place_name_out}}</td>
                        <td>{{item.reg_date_out}}</td>
                    </tr>
                </table>
            </div>
            <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" 
            :startDate="startDate" :endDate="endDate" :answerType="answerType"
            :placeName="place_name" :identityName="identity_name" :eventTypeCode="event_type_code"
            :identityNo="identityNo"
            />
        </div>
    </div>
</template>
<script>
    import pagination from '../../components/commonPagination.vue'
    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';
    import $ from 'jquery';

    export default {
        name:'entranceSummary',
        mixins: [mixin],
        data: () => {
            return {list: [], filter: false, totalCount: 0,
                answerType: '',
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
                place_name: null,
                identity_name: null,
                event_type_code: '',
                identityNo: null,
            }
        },
        mounted: function() {
            if(this.$route.query.page) {
                this.changeFilter()
                this.startDate=this.$route.query.startDate|| moment().format("YYYY-MM-DD")
                this.endDate=this.$route.query.endDate ||moment().format("YYYY-MM-DD")
                this.answerType=this.$route.query.answerType || ''
                this.place_name = this.$route.query.placeName || null
                this.identity_name = this.$route.query.identityName ||null
                this.identityNo=this.$route.query.identityNo || null
                this.event_type_code = this.$route.query.eventTypeCode || ''
                this.getEntranceSumResult(this.$route.query.page);
            } else {
                this.getEntranceSumResult(0);
            }
                        
        },
        components : {
            pagination
        },
        methods: {
            getEntranceSumResult: function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }

                let params = {};

                if (this.filter) {
                    params = {
                        page: page,
                        size: 30,
                        answerType: this.answerType,
                        startDate: this.startDate+' 00:00:00',
                        endDate: this.endDate+' 23:59:59',
                        place_name: this.place_name,
                        identity_name: this.identity_name,
                        event_type_code: this.event_type_code,
                        identityNo: this.identityNo 
                    }
                } else {
                    params = {
                        page: page,
                        size: 30,
                        startDate: this.startDate+' 00:00:00',
                        endDate: this.endDate+' 23:59:59'
                    }
                }               
                this.$axios
                .get('/api/v1/biostar/biostarEventlist', {params: params})
                .then((response) => {
                    this.list = response.data.result.list;

                    let pageInfo = response.data.result.pageInfo;
                    this.totalCount = pageInfo.totalCount;

                    this.pagerHandler(
                        pageInfo.number,
                        pageInfo.first,
                        pageInfo.last,
                        pageInfo.totalPages
                    );
                })
            },
            firstSearch: function () {
                let pageQuery = {
                    page : 0,
                    startDate:this.startDate|| null,
                    endDate:this.endDate || null,
                    answerType:this.answerType || null,
                    placeName:this.place_name || null,
                    identityName:this.identity_name || null,
                    identityNo:this.identityNo || null,
                    eventTypeCode:this.event_type_code || null
                }
                this.$router.push({
                    path: this.$route.path,
                    query: pageQuery
                }).catch(()=>{});
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.answerType = '';
                this.startDate = moment().format("YYYY-MM-DD");
                this.endDate = moment().format("YYYY-MM-DD");
                this.event_type_code = '';
                this.identity_name = null;
                this.identityNo = null;
            },
            changeAnswerType(event) {
                this.answerType = event.target.value;
            },
            confirmDownload: function () {
                  this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
                console.log(param.purpose);
                console.log(param.purpose_etc);
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {
                let params = {};
                if (this.filter) {
                    params = {
                        page: 0,
                        size: 1000000,
                        answerType: this.answerType,
                        startDate: this.startDate+' 00:00:00',
                        endDate: this.endDate+' 23:59:59',
                        identity_name: this.identity_name,
                        event_type_code: this.event_type_code,
                        place_name: this.place_name,
                        identityNo: this.identityNo 
                    }
                } else {
                    params = {
                        page: 0,
                        size: 1000000,
                        startDate: this.startDate+' 00:00:00',
                        endDate: this.endDate+' 23:59:59'
                    }
                }
                this.$axios
                .get('/api/v1/biostar/biostarEventlist/excel', {params: params, responseType: "blob"})
                .then((response) => {
                    console.log(response);
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type']}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '출입현황_'+moment().format("YYYYMMDD_HHmmss")+'.xlsx');
                    document.body.appendChild(link);
                    link.click();

                    if(response.status == 200) {
                        let param = {
                          fileName: '출입현황_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                          fileSize: response.data.size,
                          purpose: paramdata.purpose,
                          purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                        }
                        this.setDownloadHistory(param);
                    }
                })
            },
            changeStartDate(event) {
                this.startDate = event.target.value;
            },
            changeEndDate(event) {
                this.endDate = event.target.value;
            },
            changeEventTypeCode(event) {
                this.event_type_code = event.target.value;
            },
            convertEventTypeCode: function (value) {
                if (value != null) {
                    if(value == '4102') {
                        value = '정상';
                    } else {
                        value = '차단';
                    }
                }
                return value
            },
            convertEventTypeNm: function (value) {
                if (value != null) {
                    if(value == '4102') value = '';
                    else if(value == '6147') value = '측정 오류';
                    else if(value == '6419') value = '발열 감지';
                    else if(value == '6421') value = '마스크 미착용';
                    else if(value == '6420') value = '측정 오류';
                    else if(value == '6403') value = '문진 미작성';
                    else if(value == '6405') value = '중복 입장';
                    else if(value == '6408') value = '중복 입장';
                    else if(value == '4354') value = '인증 실패';
                }
                return value
            },
            convertPurpose: function (value) {
                if(null !== value && undefined !== value) {
                    value = value.replaceAll('|',', ');
                }
                return value;
            }

        }
    }
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
    .btn_md_custom {
        padding: 0 5px;
        min-width: auto;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
    }
</style>
