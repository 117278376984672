<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javscript:void(0)">출입관리</a>
            </li>
            <li>
                <a href="javscript:void(0)">재문진 허용</a>
            </li>
        </div>
        <div class="wapper" style="min-height: 700px;">
            <div class="title">
                <h2>재문진 허용(입원환자/협력업체)</h2>
            </div>
            <h3>
                <div class="btn-right">
                    <!-- <button class="btn_primary" @click="opnePop()">재문진허용</button> -->
                    <button class="btn_search" @click="openSearch()">검색</button>
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">
                            <dl class="full_width">
                                <dt>이름</dt>
                                <dd><input type="text" v-model="identityName"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>식별번호 (환자 = 등록번호 | 직원 = 전화번호)</dt>
                                <dd><input type="text" v-model="identityNo"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>업체명</dt>
                                <dd><input type="text" v-model="partnerName"/></dd>
                            </dl>

                            <dl class="full_width">
                                <dt>등록일</dt>
                                <dd>
                                    <input type='date' v-model='startDate' @change="changeStartDate"/>
                                    ~
                                    <input type='date' v-model='endDate' @change="changeEndDate"/>
                                </dd>
                            </dl>
                        </div>

                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'btn_filered' : filter}"
                                @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                            <button class="btn_primary btn_md" @click="getList(0)">적용</button>
                        </div>
                    </div>
                </div>
            </h3>

            <div class="table table_line">
                <table>
                    <tr>
                        <th>허용일시</th>
                        <th>허용자</th>
                        <th>허용대상</th>
                        <th>등록번호</th>
                        <th>구분</th>
                        <th>업체명</th>
                        <th>내원일</th>
                    </tr>
                    <tr v-for="(item, index) in reInquiryList" :key="index">
                        <td>{{ convertDate(item.regDate)}}</td>
                        <td>{{item.regUser }}</td>
                        <td>{{(item.identityName)}}</td>
                        <td>{{(item.identityNo)}}</td>
                        <td>{{convertType(item.answerType)}}</td>
                        <td>{{item.partnerName}}</td>
                        <td>{{item.visitDate}}</td>
                    </tr>
                </table>
            </div>
            <div class="pagination">
                <ul>
                    <li>
                        <a href="javascript:void(0)" @click="getList(page.number - 1)">이전</a>
                    </li>
                    <li
                        v-for="(item, index ) in pageNation"
                        :key="index"
                        class="pageNo"
                        :class="{'active': item == page.number +1}">
                        <a href="javascript:void(0)" @click="getList(item - 1)">{{item}}</a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="getList(page.number + 1)">다음</a>
                    </li>
                </ul>
            </div>
        </div>
        <modal
            name="addMaster"
            :width="540"
            :height="240"
            :adaptive="true"
            :clickToClose="false">
            <div id="popup_reg" class="pop pop_md pop_reg">
                <!-- 새 문진 등록 -->
                <div class="pop_head">
                    <h2>재문진 허용</h2>
                    <a class="btn_pop_close" @click="closePop()">닫기</a>
                </div>
                <div class="pop_body">
                    <dl>
                        <dt>등록번호</dt>
                        <dd>
                            <input
                                type="text"
                                class="input_full"
                                v-model="identityNo"
                                placeholder="환자등록번호 또는 직원의 전화번호 입력해주세요."/>
                        </dd>
                    </dl>
                    <dl>
                        <dt></dt>
                        <dd>※ 내원일이 금일인 문진 완료자에 한해서 가능합니다.
                        </dd>

                    </dl>
                </div>
                <div class="pop_footer">
                    <button class="btn_secondary" @click="closePop()">취 소</button>
                    <button class="btn_primary" @click="next()">적 용</button>
                </div>
            </div>
        </modal>
        <modal
            name="alertMsg"
            :width="540"
            :height="190"
            :adaptive="true"
            :clickToClose="false">
            <div id="popup_reg" class="pop pop_md">
                <!-- 새 문진 등록 -->
                <div class="pop_head">
                    <h2>알림</h2>
                    <a class="btn_pop_close">닫기</a>
                </div>
                <div class="pop_body">
                    {{errMsg}}
                </div>
                <div class="pop_footer">
                    <button class="btn_secondary" @click="closeAlert()">확 인</button>
                </div>
            </div>
        </modal>

    </div>

</template>
<script>
    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';
    import $ from 'jquery'
    export default {
        data: () => {
            return {
                errMsg: null,
                identityNo: null,
                reInquiryList: [],
                identityName: null,
                filter: false,
                partnerName: null,
                regUser: null,
                answerType: null,
                visitDate: null,
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            }
        },
        mixins: [mixin],
        mounted: function () {
            this.getList(0);
        },
        methods: {

            next: function () {
                if (this.identityNo == null || this.identityNo == "") {
                    this.errMsg = "환자등록번호 또는 협력업체 직원의 전화번호를 입력해주세요"
                    this
                        .$modal
                        .show('alertMsg');
                    return;
                }

                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                //재문진 허용 테이블 저장
                this
                    .$axios
                    .post('/api/mng/entrance/v1/pass/re-inquiry', {identityNo: this.identityNo})
                    .then((response) => {

                        if (response.data.success) {
                            console.log(response.data.identityNo);
                            this.identityNo = null;
                            this.openAlert("재문진 허용이 완료되었습니다.");
                            this.btnLock = false;
                            this.closePop();

                        } else if(response.data.resultCd == '300'){
                            this.openAlertClosed('요청한 사용자가 없습니다.');
                            this.btnLock = false;
                        }else if(response.data.resultCd == '-1'){
                            this.openAlertClosed('저장 중 오류가 발생했습니다.');
                            this.btnLock = false;
                        }else{
                            this.openAlertClosed('재문진 허용 중 오류가 발생했습니다.');
                            this.btnLock = false;
                        }

                    })
                    .catch((err) => {
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                        console.log(err.message);
                        this.errMsg = "재문진 허용 중 오류가 발생했습니다.";
                        this
                            .$modal
                            .show('alertMsg');
                        this.closePop();
                        this.btnLock = false;
                    });

            },
            closeAlert: function () {
                this.errMsg = null;
                this
                    .$modal
                    .hide('alertMsg');
            },
             leftPad: function(value) {
                if (value >= 10) {
                    return value;
                }
                return `0${value}`;
            },
             toStringByFormatting:function(source, delimiter = '-') {
                const year = source.getFullYear();
                const month = this.leftPad(source.getMonth() + 1);
                const day = this.leftPad(source.getDate());
                return [year, month, day].join(delimiter);
            },

            getList: function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }

                let param = {};

                if (this.filter) {
                    param = {
                        page: page,
                        size: 30,
                        identityName: this.identityName,
                        identityNo: this.identityNo,
                        partnerName: this.partnerName,
                        startDate: this.startDate + ' 00:00:00',
                        endDate: this.endDate + ' 23:59:59'
                    }
                } else {
                    param = {
                        page: page,
                        size: 30,
                        startDate: this.startDate + ' 00:00:00',
                        endDate: this.endDate + ' 23:59:59'
                    }
                }

                this
                    .$axios
                    .get('/api/mng/entrance/v1/permit-re-inquiry', {params: param})
                    .then((response) => {
                        console.log(response.data);

                        if (response.data.success) {
                            let result = response.data.result.content;

                            this.reInquiryList = result;

                            let pageInfo = response.data.result;

                            this.pagerHandler(
                                pageInfo.number,
                                pageInfo.first,
                                pageInfo.last,
                                pageInfo.totalPages
                            );
                        } else {
                            this.openAlert("재문진 허용 목록을 불러오는 도중 오류가 발생하였습니다.");
                        }
                    })
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            changeStartDate(event) {
                this.startDate = event.target.value;
            },
            changeEndDate(event) {
                this.endDate = event.target.value;
            },
            confirmDownload: function () {
                this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {
                let param = {};

                if (this.filter) {
                    param = {
                        page: 0,
                        size: 100000,
                        identityName: this.identityName,
                        identityNo: this.identityNo,
                        partnerName: this.partnerName,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                } else {
                    param = {
                        page: 0,
                        size: 100000,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                }
                this
                    .$axios
                    .get('/api/mng/entrance/v1/permit-re-inquiry/excel', {
                        params: param,
                        responseType: "blob"
                    })
                    .then((response) => {
                        console.log(response);
                        const url = window
                            .URL
                            .createObjectURL(
                                new Blob([response.data], {type: response.headers['content-type']})
                            );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute(
                            'download',
                            '재문진허용_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx'
                        );
                        document
                            .body
                            .appendChild(link);
                        link.click();

                        if (response.status == 200) {
                            let param = {
                                fileName: '재문진허용_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                                fileSize: response.data.size,
                                purpose: paramdata.purpose,
                                purposeEtc: paramdata.purpose_etc == null
                                    ? ''
                                    : paramdata.purpose_etc
                            }
                            this.setDownloadHistory(param);
                        }
                    })
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.identityname = null;
                this.identityNo = null;
                this.partnerName = null;
                this.startDate = moment().add(-1, 'd').format("YYYY-MM-DD");
                this.endDate = moment().add(1, 'M').format("YYYY-MM-DD");
            },
            convertType: function (type) {
                if (type != null) {
                    if (type == "PT") {
                        return "환자";
                    } else if (type == "GST") {
                        return "방문객";
                    } else if (type == "NOK") {
                        return "보호자";
                    } else if (type == "PEMP") {
                        return "협력업체";
                    } else if (type == "ETC") {
                        return "기타";
                    }
                }
            },
            convertEntryType: function (type) {
                if (type != null) {
                    if (type == "oneday") {
                        return "당일권";
                    } else if (type == "allday") {
                        return "24시권";
                    } else if (type == "onetime") {
                        return "1회권";
                    }
                }
            },
            convertAnswerYn: function (value) {
                if (value !== null) {
                    if (value == "Y") {
                        return "시행";
                    }
                }
            },
            opnePop: function () {
                this
                    .$modal
                    .show('addMaster');
            },
            closePop: function () {
                this.identityNo = null;
                this
                    .$modal
                    .hide('addMaster');
            },
        }
    }
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
    .btn_md_custom {
        padding: 0 5px;
        min-width: auto;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
    }
    .etcBtn {
        width: 60px;
        padding: 0;
    }
    .pop {
        display: inline-block;
        box-shadow: none;
    }
</style>