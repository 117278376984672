<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="">개인정보 관리</a>
            </li>
            <li>
                <a href="">개인정보 처리방침 관리</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>개인정보처리방침</h2>
                <div class="btn-right">
                    <button class="btn_primary" @click="savePrivacy()">저장</button>
                    <button class="btn_secondary" @click="goBack()">취소</button>
                </div>
            </div>
            <div class="details">
                <div class="detail_conts">
                    <h4>한국어 버전 </h4>
                    <blockquote>문진에서 한국어 선택시 보여집니다.</blockquote>                    
                        <div id="editor"></div>
                </div>
                <div class="detail_conts">
                    <h4>영문 버전 </h4>
                    <blockquote>문진에서 한국어 이외의 언어를 선택시 보여집니다.</blockquote>
                        <div id="editor2"></div>
                </div>                 
            </div> 
        </div>
        <modal
            name="alertMsg"
            :width="540"
            :height="190"
            :adaptive="true"
            :clickToClose="false">
            <div id="popup_reg" class="pop pop_md">
                <!-- 새 문진 등록 -->
                <div class="pop_head">
                    <h2>알림</h2>
                    <a class="btn_pop_close" @click="closePop('alertMsg')">닫기</a>
                </div>
                <div class="pop_body">
                    {{errMsg}}
                </div>
                <div class="pop_footer">
                    <button class="btn_secondary" @click="closePop('alertMsg')">확 인</button>
                </div>
            </div>
        </modal>
        <modal
            name="success"
            :width="540"
            :height="190"
            :adaptive="true"
            :clickToClose="false"
            @closed="goBack">
            <div id="popup_reg" class="pop pop_md">
                <!-- 새 문진 등록 -->
                <div class="pop_head">
                    <h2>알림</h2>
                    <a class="btn_pop_close" @click="closePop('success')">닫기</a>
                </div>
                <div class="pop_body">
                    {{errMsg}}
                </div>
                <div class="pop_footer">
                    <button class="btn_secondary" @click="closePop('success')">확 인</button>
                </div>
            </div>
        </modal>
    </div>

</template>
<script>
    import 'codemirror/lib/codemirror.css'; // Editor's Dependency Style
    import '@toast-ui/editor/dist/toastui-editor.css'; // Editor's Style
    import 'tui-color-picker/dist/tui-color-picker.css';

    import Editor from '@toast-ui/editor';
    import colorSyntax from '@toast-ui/editor-plugin-color-syntax';

    export default {
        created: function () {
            this.privacyCode = this.$route.query.privacyCode;
        },
        mounted() {
            const editor = new Editor({
                el: document.querySelector('#editor'),
                height: '700px',
                language: 'ko',
                initialEditType: 'wysiwyg',
                previewStyle: 'vertical',
                plugins: [colorSyntax]
            });

            const editor2 = new Editor({
                el: document.querySelector('#editor2'),
                height: '700px',
                language: 'en',
                initialEditType: 'wysiwyg',
                previewStyle: 'vertical',
                plugins: [colorSyntax]
            });

            editor.getHtml();
            this.editor = editor;
            editor2.getHtml();
            this.editor2 = editor2;                         
            this.getPrivacyInfo();
        },
        data: () => {
            return {content: null,contentEng:null, editor: null, editor2:null, errMsg: null, btnLock: false, privacyCode: null}
        },
        methods: {
            goBack: function () {
                this
                    .$router
                    .push({
                        name: 'privacy-view',
                        query: {
                            privacyCode: this.privacyCode
                        }
                    });
            },
            // getPrivacyInfo: function () {     console.log("getPrivacyInfo");     this
            // .$axios         .get('/api/mng/privacy?privacyCode=' + this.privacyCode)
            // .then((response) => {             console.log(response.data);
            // this.content = response                 .data                 .result
            // .content[0]                 .privacyContents;             this
            // .editor                 .setHtml(this.content);         }); },
            getPrivacyInfo: function () {
                this
                    .$axios
                    .get('/api/mng/env/v1/privacy', {
                        params: {
                            privacyCode: this.privacyCode
                        }
                    })
                    .then((response) => {
                        console.log(response.data.result);
                        if (response.data.success) {
                            this.content = response.data.result.privacyContents;
                            this.editor.setHtml(this.content);
                            this.contentEng = response.data.result.privacyContentsEng;
                            this.editor2.setHtml(this.contentEng);

                        } else {
                            this.openAlert("개인정보 정보를 가져오는 도중 오류가 발생했습니다.");
                        }

                    })
                    .catch(() => {
                        this.openAlert("개인정보 정보를 가져오는 도중 오류가 발생했습니다.");
                    });
            },
            savePrivacy: function () {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                let text = this.editor.getHtml();
                let text2 = this.editor2.getHtml();              

                if (text.length <= 0 ) {
                    this.errMsg = "한국어 내용을 입력하세요.";
                    this
                        .$modal
                        .show('alertMsg');
                    return;
                }
                if (text2.length <=0) {
                    this.errMsg = "영어 내용을 입력하세요.";
                    this
                        .$modal
                        .show('alertMsg');
                    return;                    
                }
                this
                    .$axios
                    .put('/api/mng/env/v1/privacy', {
                        privacyContents: text,
                        privacyContentsEng: text2,
                        privacyCode: this.privacyCode
                    })
                    .then(() => {
                        this.errMsg = "저장이 완료되었습니다. \n 개인정보 처리방침 조회 페이지로 이동합니다.";
                        this
                            .$modal
                            .show('success');
                        this.btnLock = false;
                    })
                    .catch((err) => {
                        this.btnLock = false;
                        this.errMsg = err.response.data;
                        this
                            .$modal
                            .show('alertMsg');
                    });
            },
            closePop: function (target) {
                this.errMsg = null;
                this
                    .$modal
                    .hide(target);
            }
        }
    };
</script>
<style scoped="scoped">
    #popup_reg {
        display: inline-block;
    }
    .details{ 
        padding:20px 0; 
        display:flex;
        }
    .details .detail_conts{
        width:48%; 
        margin:0 1%; 
        }    
</style>