<template>

    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">알림서비스</a>
            </li>
            <li>
                <a href="javascript:void(0)">블랙리스트내원알림</a>
            </li>
            <li>
                <a href="javascript:void(0)">블랙리스트내원알림 수신자등록</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>블랙리스트내원알림 수신자등록</h2>
            </div>
            <div class="register">
                <dl>
                    <dt>부서명</dt>
                    <dd>
                        <input
                            type="text"
                            v-model="deptNm"
                            placeholder="부서"
                            readonly="readonly"
                            style="width:40%;"/>
                        <button class="btn_secondary" @click="openSearchUser()">대상선택</button>
                    </dd>
                </dl>
                <dl>
                    <dt>이름</dt>
                    <dd>
                        <input
                            type="text"
                            v-model="userNm"
                            placeholder="이름"
                            readonly="readonly"
                            style="width:40%;"/></dd>
                </dl>
                <dl>
                    <dt>수신유형</dt>
                    <dd>
                        <input
                            type="radio"
                            id="oneTime"
                            name="anser_option"
                            value="O"
                            v-model="receiveType"/>
                        <label for="oneTime" style="display:inline-block;">
                            <span>최초 1회</span>
                        </label>
                        <input
                            type="radio"
                            id="allTime"
                            name="anser_option"
                            value="A"
                            v-model="receiveType"/>
                        <label for="allTime" style="display:inline-block; margin-left: 40px;">
                            <span>모든 출입</span>
                        </label>
                    </dd>
                </dl>
                <dl>
                    <dt>사용여부</dt>
                    <dd>
                        <label class="switch">
                            <input type="checkbox" id="guide_check" v-model="useYn"/>
                            <span class="slider"></span>
                        </label>
                        <span class="txt">가능</span>
                    </dd>
                </dl>

            </div>
            <div class="btn_wrap">
                <div class="btn_left">
                    <button class="btn_secondary" @click="goList()">취 소</button>
                    <button class="btn_primary" @click="saveConfirm()">저 장</button>
                </div>
                <!-- <div class="btn_right"> <button class="btn_danger">삭제</button> </div> -->
            </div>
        </div>
    </div>
</template>
<script>
    import searchUser from '../../components/userSearch.vue';
    import mixin from '../../mixin/mixin.js';

    export default {
        data: () => {
            return {
                userId: null, //사용자 아이디
                userNm: null, //사용자 이름
                deptCd: null, //부서코드
                deptNm: null, //부서이름
                receiveType: 'A', //수신유형 O: 1회(One time) , A: 모든 출입 (All time)
                useYn: true //사용여부
            }
        },
        mixins: [mixin],
        methods: {
            openSearchUser: function () {
                this
                    .$modal
                    .show(searchUser, {
                        getSearchUserInfo: this.getSearchUserInfo
                    }, {
                        width: '640px',
                        height: 'auto',
                        adaptive: true,
                        clickToClose: false
                    },)
            },
            getDetpUser: function () {
                this
                    .$axios
                    .get('/api/mng/common/v1/dept-user', {
                        params: {
                            userId: this.userId,
                            deptCd: this.deptCd
                        }
                    })
                    .then((response) => {
                        if (response.data.success) {
                            this.userNm = response.data.result.korNm;
                            this.deptNm = response.data.result.deptNm;
                        } else {
                            this.openAlert("사용자 정보를 가져오지 못했습니다.")
                        }

                    })
                    .catch(() => {
                        this.openAlert("사용자 정보를 가져오지 못했습니다.")
                    })
                },
            getSearchUserInfo: function (user, dept) {
                this.userId = user;
                this.deptCd = dept;
                this.getDetpUser();
            },
            saveConfirm: function () {
                if (this.userId == null) {
                    this.openAlert("알림을 수신할 사용자를 선택하세요.");
                    return;
                }

                if (this.userId.trim() == '') {
                    this.openAlert("알림을 수신할 사용자를 선택하세요.");
                    return;
                }

                if (this.deptCd == null) {
                    this.openAlert("알림을 수신할 부서를 선택하세요.");
                    return;
                }

                if (this.deptCd.trim() == '') {
                    this.openAlert("알림을 수신할 부서를 선택하세요.");
                    return;
                }

                this.openConfirm("저장하시겠습니까?", this.save);
            },
            save: function () {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                this
                    .$axios
                    .post('/api/mng/service/send-rule/v1/amcblk', {
                        receiverNm: this.userNm,
                        receiverId: this.userId,
                        deptCd: this.deptCd,
                        deptNm: this.deptNm,
                        receiveType: this.receiveType,
                        useYn: this.useYn
                    })
                    .then((response) => {
                        if (response.data.success) {
                            if (response.data.result == null) {
                                this.openAlert("이미 등록된 사용자입니다.");
                            } else {
                                this.openAlertClosed("저장이 완료되었습니다.", this.goList);
                            }
                        } else {
                            this.openAlert("저장중 오류가 발생했습니다.");
                        }

                        this.btnLock = false;
                    })
                    .catch(() => {
                        this.openAlert("저장중 오류가 발생했습니다.");
                        this.btnLock = false;
                    })
                },
            goList: function () {
                this
                    .$router
                    .go(-1);
                    // .push({name: 'blackListNoticeList'});
            }
        }
    }
</script>
