<template>
    <section>
        <h3>
            <div class="btn-right">
                <label class="select-label" for="inquiry-type">문진유형</label>
                <select id="inquiry-type" style="margin-right: 20px" @change="getInquiryVersion($event.target.value)">
                    <option v-for="(item, index) in this.masterList" :key="index" :value="item.masterId">
                       {{ item.masterIdNm }}
                    </option>
                </select>
                <label class="select-label" for="inquiry-version">문진표 버전</label>
                <select id="inquiry-version" v-model="selectedVersion">
                    <option v-for="(item, index) in this.versionList" :key="index" :value="item">
                        {{ item }}
                    </option>
                </select>

                <button class="btn_search" @click="openSearch">검색</button>
                <button class="btn_download" @click="confirmDownload">다운로드</button>
                <div class="search">
                    <h3>검색</h3>
                    <div class="search_detail">
                        <dl class="full_width">
                            <dt>문진상태</dt>
                            <dd>
                                <select v-model="searchDeniedYn" @change="changeDeniedYn">
                                    <option value='all'>전체</option>
                                    <option value='Y'>정상</option>
                                    <option value='N'>확인필요</option>
                                </select>
                            </dd>
                        </dl>
                        <dl class="full_width">
                            <dt>연락처</dt>
                            <dd>
                                <input type="text" v-model="searchPhoneNum" @input="checkPhoneNum"/>
                            </dd>
                        </dl>
                        <dl class="full_width">
                            <dt>기간</dt>
                            <dd class="date-period">
                                <input type='date' v-model='searchStartDate' @change="changeStartDate"/>
                                ~
                                <input type='date' v-model='searchEndDate' @change="changeEndDate"/>
                            </dd>
                        </dl>
                    </div>
                    <div class="search_footer">
                        <button
                            class="btn_secondary btn_md btn_filer"
                            :class="{ btn_filered: filter }"
                            @click="changeFilter()">
                            {{ this.filter ? "필터해제" : "필터적용" }}
                        </button>
                        <button class="btn_secondary btn_md" @click="closeSearch()">
                            취소
                        </button>
                        <button class="btn_primary btn_md" @click="getInquiryAnswer(0)">적용</button>
                    </div>
                </div>
            </div>
        </h3>
        <div class="table responsive">
            <table v-if="tableHeader.length > 0 && tableHeaderSub.length > 0">
                <tr>
                    <th>문진상태</th>
                    <th>기준일</th>
                    <th>문진작성일</th>
                    <th>문진작성시간</th>
                    <th>기본정보</th>
                    <th
                        v-for="(header, index) in tableHeader"
                        :key="`${index}_${header.itemContents}`"
                        :colspan="header.colSpan">
                        {{ header.itemContents }}
                    </th>
                    <th>방문력</th>
                </tr>
                <tr>
                    <th>구분</th>
                    <th>일자</th>
                    <th>작성일자</th>
                    <th>문진작성시간</th>
                    <th>연락처</th>
                    <th
                        class="sub-header"
                        v-for="(header, index) in tableHeaderSub"
                        :key="`${index}_${header.itemContents}`">
                        {{ header.itemContents }}
                    </th>
                    <th>여부</th>
                </tr>
                <tr v-for="(answer, index) in tableList" :key="`${index}`">
                    <td v-for="(key, subIndex) in answer" :key="subIndex">{{ key }}</td>
                </tr>
            </table>
        </div>
        <div class="pagination">
            <ul>
                <li>
                    <a href="javascript:void(0)" @click="getInquiryAnswer(page.number - 1)">이전</a >
                </li>
                <li
                    v-for="(item, index) in pageNation"
                    :key="index"
                    class="pageNo"
                    :class="{ active: item == page.number + 1 }">
                    <a href="javascript:void(0)" @click="getInquiryAnswer(item - 1)">{{
            item
          }}</a>
                </li>
                <li>
                    <a href="javascript:void(0)" @click="getInquiryAnswer(page.number + 1)">다음</a >
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
    import mixin from "../mixin/mixin.js";
    import moment from "moment";
    import $ from "jquery";

    export default {
        name: "inquiryStatusTableNoPass",
        mixins: [mixin],
        data: () => {
            return {
                selectedVersion: null,
                inquiryVersionInfo: null,
                searchPatNo: null,
                searchPatName: null,
                searchPhoneNum: null,
                searchDeniedYn: 'all',
                searchStartDate: null,
                searchEndDate: null,
                tableHeader: [],
                tableHeaderSub: [],
                tableList: [],
                answerSize: 0,
                filter: false,
                masterList: [],
                detailId: null,
            };
        },
        computed: {
            versionList() {
                return this.inquiryVersionInfo?.map((item) => item.ver);
            }
        },
        methods: {
            getNoPassMaster: async function () {
                try {
                    const response = await this
                        .$axios
                        .get("/api/mng/inquiry/v1/inq-status/no-pass-master");
                    if (!response.data.success) 
                        throw new Error("API Fail");
                    this.masterList = response.data.result;
                    if(this.masterList.length > 0) {
                        // 마스터 정보가 있다면 첫번째 마스터로 초기 데이터를 불러온다.
                        let firstMaster = this.masterList[0].masterId;
                        this.getInquiryVersion(firstMaster);
                    }    
                } catch (error) {
                    this.openAlert("문진마스터 정보를 불러오는 도중 오류가 발생했습니다.");
                }
            },
            // API 호출 Method
            getInquiryVersion: async function (masterId) {
              console.log(masterId);
                try {
                    const response = await this
                        .$axios
                        .get("/api/mng/inquiry/v1/inq-status/ver", {params: {
                                masterId
                            }});
                    if (!response.data.success) 
                        throw new Error("API Fail");
                    this.inquiryVersionInfo = response.data.result;
                    if (this.inquiryVersionInfo.length > 0) 
                        this.selectedVersion = this
                            .inquiryVersionInfo[0]
                            .ver;
                        this.detailId = this
                            .inquiryVersionInfo[0].detailId;
                    }
                catch (error) {
                    this.openAlert("문진표 정보를 불러오는 도중 오류가 발생했습니다.");
                }
            },
            getInquiryHeader: async function () {
                // init

                try {
                    const detailId = this
                        .inquiryVersionInfo
                        .filter((item) => item.ver === this.selectedVersion)[0]
                        .detailId;
                    const masterId = this.masterId;
                    const response = await this
                        .$axios
                        .get("/api/mng/inquiry/v1/inq-status/list", {
                            params: {
                                detailId,
                                masterId
                            }
                        });
                    this.tableHeader = [];
                    this.tableHeaderSub = [];
                    if (!response.data.success) 
                        throw new Error("API Fail");
                    const headerInfos = response.data.result;
                    for (const headerInfo of headerInfos) {
                        const tableHeader = {
                            itemContents: headerInfo.itemContents,
                            colSpan: headerInfo.subItems.length
                        };
                        this
                            .tableHeader
                            .push(tableHeader);
                        for (const subHeaderInfo of headerInfo.subItems) {
                            console.log(subHeaderInfo);
                            const tableSubHeader = {
                                mappingId: subHeaderInfo.mappingId,
                                itemContents: subHeaderInfo.itemContents
                            };
                            this
                                .tableHeaderSub
                                .push(tableSubHeader);
                        }
                    }
                    this.getInquiryAnswer(0);
                } catch (_error) {
                    this.openAlert("문진표 정보를 불러오는 도중 오류가 발생했습니다.");
                }
            },
            getInquiryAnswer: async function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }
                // init
                this.tableList = [];
                try {
                    const detailId = this
                        .inquiryVersionInfo
                        .filter((item) => item.ver === this.selectedVersion)[0]
                        .detailId;
                    let param = {};

                    if (this.filter) {
                        param = {
                            page: page,
                            size: 30,
                            detailId: detailId,
                            answerType: this.masterId,
                            patNo: this.searchPatNo,
                            phoneNum: this.searchPhoneNum,
                            deniedYn: this.searchDeniedYn,
                            name: this.searchPatName,
                            startDate: this.searchStartDate,
                            endDate: this.searchEndDate
                        };
                    } else {
                        param = {
                            page: page,
                            size: 30,
                            detailId: detailId,
                            answerType: this.masterId,
                            startDate: this.searchStartDate,
                            endDate: this.searchEndDate
                        };
                    }
                    const response = await this
                        .$axios
                        .get("/api/mng/inquiry/v1/inq-status/list-answer-no-pass", {params: param});
                    if (!response.data.success) 
                        throw new Error("API Fail");
                    this.tableList = response.data.result.content;
                    let pageInfo = response.data.result;

                    this.pagerHandler(
                        pageInfo.number,
                        pageInfo.first,
                        pageInfo.last,
                        pageInfo.totalPages
                    );
                } catch (_error) {
                    this.openAlert("문진표 정보를 불러오는 도중 오류가 발생했습니다.");
                }
            },
            openSearch: () => $(".search").slideToggle("down"),
            confirmDownload: function () {
                this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: async function (paramdata) {
                const detailId = this
                    .inquiryVersionInfo
                    .filter((item) => item.ver === this.selectedVersion)[0]
                    .detailId;
                let params = {};
                if (this.filter) {
                    params = {
                        page: 0,
                        size: 100000,
                        detailId: detailId,
                        answerType: this.masterId,
                        patNo: this.searchPatNo,
                        phoneNum: this.searchPhoneNum,
                        deniedYn: this.searchDeniedYn,
                        name: this.searchPatName,
                        startDate: this.searchStartDate,
                        endDate: this.searchEndDate,
                        masterId: this.masterId
                    };
                } else {
                    params = {
                        page: 0,
                        size: 100000,
                        detailId: detailId,
                        answerType: this.masterId,
                        startDate: this.searchStartDate,
                        endDate: this.searchEndDate,
                        masterId: this.masterId
                    };
                }
                this
                    .$axios
                    .get("/api/mng/inquiry/v1/inq-status/list-answer-no-pass/excel", {
                        params: params,
                        responseType: "blob"
                    })
                    .then((response) => {
                        console.log(response);
                        const url = window
                            .URL
                            .createObjectURL(
                                new Blob([response.data], {type: response.headers['content-type']})
                            );
                        const link = document.createElement('a');
                        link.href = url;
                        let title = "";
                        if (this.masterId == 'GST') 
                            title = "방문객";
                        if (this.masterId == 'PEMP') 
                            title = "협력업체";
                        if (this.masterId == 'NOK') 
                            title = "일반보호자";
                        link.setAttribute(
                            'download',
                            '문진현황_' + title + "_" + moment().format("YYYYMMDD_HHmmss") + '.xlsx'
                        );
                        document
                            .body
                            .appendChild(link);
                        link.click();

                        if (response.status == 200) {
                            let param = {
                                fileName: '문진현황_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                                fileSize: response.data.size,
                                purpose: paramdata.purpose,
                                purposeEtc: paramdata.purpose_etc == null
                                    ? ''
                                    : paramdata.purpose_etc
                            }
                            this.setDownloadHistory(param);
                        }
                    })
            },
            checkPatNo: function (e) {
                this.searchPatNo = e
                    .target
                    .value
                    .replace(/[^a-z|A-Z|0-9]/gi, "");
            },
            checkPatName: function (e) {
                this.searchPatName = e
                    .target
                    .value
                    .replace(/[^ㄱ-ㅎ|가-힣|a-z|A-Z|\s]/gi, "");
            },
            checkPhoneNum: function (e) {
                this.searchPhoneNum = e
                    .target
                    .value
                    .replace(/[^0-9|-]/gi, "");
            },
            changeStartDate(event) {
                this.searchStartDate = event.target.value;
            },
            changeEndDate(event) {
                this.searchEndDate = event.target.value;
            },
            setStartDate: function (date) {
                this.searchStartDate = date;
            },
            setEndDate: function (date) {
                this.searchEndDate = date;
            },
            resetFilter: function () {
                this.searchPatNo = null;
                this.searchPatName = null;
                this.searchPhoneNum = null;
                this.searchStartDate = null;
                this.searchEndDate = null;
            },
            closeSearch: function () {
                $(".search").slideToggle("up");
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.searchPatNo = null;
                this.searchPatName = null;
                this.searchPhoneNum = null;
                this.searchDeniedYn = 'all';
                this.startDate = moment().format("YYYY-MM-DD");
                this.endDate = moment().format("YYYY-MM-DD");
            },
            changeDeniedYn: function (event) {
                this.searchDeniedYn = event.target.value;
            }
        },
        mounted() {           
            const today = moment().format("YYYY-MM-DD");           
            this.searchStartDate = today;
            this.searchEndDate = today;
            this.getNoPassMaster();
        },
        watch: {
            // 문진표 버전 변경 (초기셋팅 포함) 시 Header 정보 조회
            selectedVersion: function () {
                this.getInquiryHeader();
            },
            detailId: function() {
              this.getInquiryHeader();
            }
        }
    };
</script>

<style lang="scss" scoped="scoped">
    h3 {
        height: 50px;
        .btn-right {
            width: 100%;
            display: flex;
            > select {
                margin-right: auto;
                height: 30px;
                line-height: 30px;
                align-self: flex-end;
            }
            > label {
                padding: 0;
                margin-right: 10px;
                margin-bottom: 3px;
                align-self: flex-end;
            }
        }
    }
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
    .search {
        top: 36px;
    }

    .date-period {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    th {
        border: solid 1px #e8e9eb;
        &.sub-header {
            word-break: keep-all;
            max-width: 160px;
        }
    }
    .responsive table {
        width: 3800px !important;
        border-collapse: separate !important;
        border-spacing: 0 !important;
    }

    table tr:nth-child(1) th {
        position: sticky;
        top: 0;
        z-index: 5;
        height: 50px;
        border: solid 1px #e8e9eb;
    }

    table tr:nth-child(2) th {
        position: sticky;
        top: 50px;
        z-index: 5;
        border: solid 1px #e8e9eb;
    }

    table tr:nth-child(1) th:nth-child(1) {
        left: 0;
        z-index: 10;
    }
    table tr:nth-child(1) th:nth-child(2) {
        left: 77.2px;
        z-index: 10;
    }
    table tr:nth-child(1) th:nth-child(3) {
        left: 207.2px;
        z-index: 10;
    }
    table tr:nth-child(1) th:nth-child(4) {
        left: 337.2px;
        z-index: 10;
    }
    table tr:nth-child(1) th:nth-child(5) {
        left: 467.2px;
        z-index: 10;
    }

    table tr:nth-child(2) th:nth-child(1) {
        width: 50px;
        left: 0;
        z-index: 10;
    }
    table tr:nth-child(2) th:nth-child(2) {
        width: 130px;
        left: 77.2px;
        z-index: 10;
    }
    table tr:nth-child(2) th:nth-child(3) {
        width: 130px;
        left: 207.2px;
        z-index: 10;
    }
    table tr:nth-child(2) th:nth-child(4) {
        width: 130px;
        left: 337.2px;
        z-index: 10;
    }
    table tr:nth-child(2) th:nth-child(5) {
        width: 130px;
        left: 467.2px;
        z-index: 10;
    }
    // table tr:nth-child(2) th:nth-child(6) {
    //     width: 130px;
    //     left: 597.2px;
    //     z-index: 10;
    // }

    table td:nth-child(1) {
        position: sticky;
        width: 50px;
        left: 0;
        z-index: 5;
        border-bottom: solid 1px #e8e9eb;
        background: #ffffff;
    }
    table td:nth-child(2) {
        position: sticky;
        width: 130px;
        left: 77.2px;
        z-index: 5;
        border-bottom: solid 1px #e8e9eb;
        background: #ffffff;
    }
    table td:nth-child(3) {
        position: sticky;
        width: 130px;
        left: 207.2px;
        z-index: 5;
        border-bottom: solid 1px #e8e9eb;
        background: #ffffff;
    }
    table td:nth-child(4) {
        position: sticky;
        width: 130px;
        left: 337.2px;
        z-index: 5;
        border-bottom: solid 1px #e8e9eb;
        background: #ffffff;
    }
    table td:nth-child(5) {
        position: sticky;
        width: 130px;
        left: 467.2px;
        z-index: 5;
        border-bottom: solid 1px #e8e9eb;
        background: #ffffff;
    }
    // table td:nth-child(6) {
    //     position: sticky;
    //     width: 130px;
    //     left: 597.2px;
    //     z-index: 5;
    //     border-bottom: solid 1px #e8e9eb;
    //     background: #ffffff;
    // }

    .responsive {
        overflow: auto;
        height: 600px;
    }
    .search {
        z-index: 11;
    }

    #tab1:checked~#tab_content1, #tab2:checked~#tab_content2, #tab3:checked~#tab_content3, #tab4:checked~#tab_content4 {
        display: block;
    }
</style>