<template>
  <div class="contents">
    <div class="loc">
      <li>
        <a href="javascript:void(0)">감사</a>
      </li>
      <li>
        <a href="javascript:void(0)">응급실 관계 삭제로그</a>
      </li>
    </div>
    <div class="wapper" style="min-height:700px">
      <div class="title">
        <h2>응급실 관계 삭제로그</h2>
      </div>
      <h3>
        <div class="btn-right">
          <button class="btn_search" @click="openSearch()">검색</button>
          <button class="btn_download" @click="confirmDownload()">다운로드</button>
          <div class="search">
            <h3>검색</h3>
            <div class="search_detail">
              <dl class="full_width">
                <dt>삭제일</dt>
                <dd>
                  <input type='date' v-model='startDate' @change="changeStartDate"/>
                  ~
                  <input type='date' v-model='endDate' @change="changeEndDate"/>
                </dd>
              </dl>
              <dl class="full_width">
                <dt>환자등록번호</dt>
                <dd><input type="text" v-model='patientNo'/></dd>
              </dl>
              <dl class="full_width">
                <dt>환자이름</dt>
                <dd><input type="text" v-model='patientName'/></dd>
              </dl>
            </div>
            <div class="search_footer">
              <button
                  class="btn_secondary btn_md btn_filer"
                  :class="{'btn_filered' : filter}"
                  @click="changeFilter()">{{ this.filter ? "필터해제" : "필터적용" }}
              </button>
              <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
              <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
            </div>
          </div>
        </div>
      </h3>
      <div class="table table_line">
        <table>
          <tr>
            <th>삭제 유형</th>
            <th>환자 이름</th>
            <th>환자 번호</th>
            <th>보호자 이름</th>
            <th>보호자 연락처</th>
            <th>관계</th>
            <th>삭제 사유</th>
            <th>이름</th>
            <th>사번</th>
            <th>삭제일</th>
          </tr>
          <tr v-for="(item,index) in plist" :key="index">
            <td>{{ item.answerType == "ENOK" ? '보호자' : '환자'}}</td>
            <td>{{ item.patNm }}</td>
            <td>{{ item.patNo }}</td>
            <td>{{ item.nokName }}</td>
            <td>{{ item.nokPhone }}</td>
            <td>{{ item.relation }}</td>
            <td>{{ item.comment }}</td>
            <td>{{ item.deleteUserName }}</td>
            <td>{{ item.deleteUser }}</td>
            <td>{{ item.deleteDate ? moment(item.deleteDate).format('YYYY-MM-DD HH:mm') : null }}</td>
          </tr>
        </table>
      </div>
      <pagination :pageArray="pageNation" :page="page" v-if="pageNation.length != 0"
                  :startDate="startDate" :endDate="endDate" :pname="patientName" :patNo="patientNo"/>
    </div>
  </div>
</template>
<script>
import pagination from '../../components/commonPagination.vue'
import mixin from '../../mixin/mixin.js';
import moment from 'moment';
import $ from 'jquery'

export default {
  mixins: [mixin],
  name: 'emmaList',
  data: () => {
    return {
      moment: moment,
      plist: [],
      filter: false,
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      patientName: null,
      patientNo: null,
      tempItem: {},//수정할 item 임시 저장
      isEditing: false, // 현재 수정중인 상태일때 true
      patient: null,
      comment: null,
      deleteSeq: null,
    }
  },
  components: {
    pagination
  },
  mounted: function () {
    if (this.$route.query.page) {
      this.changeFilter()
      this.startDate = this.$route.query.startDate || moment().format("YYYY-MM-DD")
      this.endDate = this.$route.query.endDate || moment().format("YYYY-MM-DD")
      this.patientName = this.$route.query.pname || null,
      this.patientNo = this.$route.query.patNo || null,
      this.getPassPtNokResult(this.$route.query.page);
    } else {
      this.getPassPtNokResult(0);
    }
  },
  methods: {
    getPassPtNokResult: function (page) {
      if (page < 0 || this.page.totalPages < page) {
        return;
      }

      let params = {};

      if (this.filter) {
        let filterObj = {
          page: page,
          size: 30,
          patientName: this.patientName,
          patientNo: this.patientNo,
          startDate: this.startDate,
          endDate: this.endDate,
        }
        params = filterObj;
      } else {
        let filterObj = {
          page: page,
          size: 30,
          startDate: this.startDate,
          endDate: this.endDate
        }
        params = filterObj;
      }
      this.$axios
          .get('/api/mng/entrance/emma/relation/delete', {params: params})
          .then((response) => {
            this.plist = response.data.result.content;
            for (let element of this.plist) {
              element.isEdit = false
            }
            this.plist = Object.assign({}, this.plist);
            let pageInfo = response.data.result;
            this.totalCount = pageInfo.totalElements;
            this.pagerHandler(
                pageInfo.number,
                pageInfo.first,
                pageInfo.last,
                pageInfo.totalPages
            );
          })
    },
    openSearch: function () {
      $('.search').slideToggle('down');
    },
    closeSearch: function () {
      $('.search').slideToggle('up');
    },
    changeFilter: function () {
      this.filter = !this.filter;
      if (!this.filter) {
        this.initSearchCnd();
      }
    },
    initSearchCnd: function () {
      this.startDate = moment().format("YYYY-MM-DD");
      this.endDate = moment().format("YYYY-MM-DD");
      this.patientName = null;
      this.patientNo = null;
    },
    firstSearch: function () {
                let pageQuery = {
                    page : 0,
                    pname : this.patientName || null,
                    patNo : this.patientNo|| null,
                    startDate : this.startDate|| null,
                    endDate : this.endDate|| null
                }
                this.$router.push({
                    path: this.$route.path,
                    query: pageQuery
                }).catch(()=>{});
            },
    changeStartDate(event) {
      this.startDate = event.target.value;
    },
    changeEndDate(event) {
      this.endDate = event.target.value;
    },
    confirmDownload: function () {
      this.downloadConfirm(this.confirmExcelDown);
    },
    confirmExcelDown: function (param) {
      this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
    },
    excelDown: function (paramdata) {
      let params = {};
      if (this.filter) {
        params = {
          patientName: this.patientName,
          patientNo: this.patientNo,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      } else {
        params = {
          startDate: this.startDate,
          endDate: this.endDate
        };
      }
      this.$axios
          .get('/api/mng/entrance/emma/relation/delete/excel', {params: params, responseType: "blob"})
          .then((response) => {
            console.log(response);
            const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '응급실_관계_삭제로그_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx');
            document.body.appendChild(link);
            link.click();

            if (response.status == 200) {
              let param = {
                fileName: '응급실_관계_삭제로그' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                fileSize: response.data.size,
                purpose: paramdata.purpose,
                purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
              }
              this.setDownloadHistory(param);
            }
          })
    },


  }
};
</script>
<style scoped="scoped">
.search_footer .btn_filered {
  margin-right: auto;
  justify-self: start;
  background-color: #E85656;
  border: 1px solid #E85656;
  color: white;
}

.btn_md_custom {
  padding: 0 5px;
  min-width: auto;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  display: inline;
  font-weight: 600;
  color: #ffffff;
  border: 1px solid transparent
}
</style>
<style scoped>
.hover:hover {
  background-color: #F5F6FB;
}

.hover td.editable {
  padding: 0;

}

.hover td.editable input {
  /* width: 100px; */
}

.hover td.editable input:read-only {
  border: none;
  background: transparent;
  /* height: unset;
  line-height: unset; */
}

.hover td.editable button {
  float: right;
  margin-top: 5px;
  margin-right: 10px
}

.pop {
  display: inline-block;
  color: #393939;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 12px 14px 0 rgb(0 0 0 / 20%);
}

.pop_reg {
  display: flex;
  flex-direction: row;
}

.pop_reg dl {
  margin-bottom: 0px !important;
  padding: 10px 0px;
  border-bottom: 0px;
}

.width_fix {
  width: 220px;
}

</style>
