<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javscript:void(0)">방문신청</a>
            </li>
            <li>
                <a href="javscript:void(0)">방문고객관리</a>
            </li>
        </div>
        <div class="wapper" style="min-height: 700px;">
            <div class="title">
                <h2>방문고객관리</h2>
            </div>
            <h3>
                <div class="btn-right">
                    <button class="btn_primary" @click="add()">신규등록</button>
                    <button class="btn_search" @click="openSearch()">검색</button>
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">
                            <dl class="full_width">
                                <dt>이름</dt>
                                <dd><input type="text" v-model="name"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>휴대전화번호</dt>
                                <dd><input type="text" v-model="phone"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>내원목적</dt>
                                <dd><input type="text" v-model="purpose"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>내원장소</dt>
                                <dd><input type="text" v-model="place"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>등록일/내원일</dt>
                                <dd>
                                    <input type='date' v-model='startDate' @change="changeStartDate"/>
                                    ~
                                    <input type='date' v-model='endDate' @change="changeEndDate"/>
                                </dd>
                            </dl>
                        </div>
                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'btn_filered' : filter}"
                                @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                            <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
                        </div>
                    </div>
                </div>
            </h3>
            <div class="table table_line">
                <table>
                    <tr>
                        <th>등록일시</th>
                        <th>등록자</th>
                        <th>방문자</th>
                        <th>방문유형</th>
                        <th>출입유형</th>
                        <th>휴대전화번호</th>
                        <th>내원일</th>
                        <th>내원목적</th>
                        <th>내원장소</th>
                        <th>출입일시</th>
                        <th>퇴실일시</th>
                        <th>문진작성결과</th>
                        <th>임시출입카드번호</th>
                        <th>비고</th>
                    </tr>
                    <tr v-for="(item, index) in visitorList" :key="index">
                        <td>{{ convertDate(item.regDate)}}</td>
                        <td>{{item.regUserName +'('+ item.regDeptName + ')' }}</td>
                        <td>
                            {{item.name}}
                            <label v-if="item.pcrYn == 'Y'" class="btn_primary btn_md_custom">P</label>
                            <label v-if="item.vaccYn == 'Y'" class="btn_primary btn_md_custom">V</label>
                            <label v-if="item.deniedYn == 'Y'" class="btn_danger btn_md_custom">R</label>
                        </td>
                        <td>{{convertType(item.type,item.isNok)}}</td>
                        <td>{{convertEntryType(item.entryType)}}</td>
                        <td>{{convertPhone(item.phone)}}</td>
                        <td>{{item.reserveDate}}</td>
                        <td>{{item.purpose ? item.purpose === 'premium' ? '프리미엄 숙박건진' : item.purpose : item.purpose}}</td>
                        <td>{{item.place}}</td>
                        <td>{{item.regDateIn}}</td>
                        <td>{{item.regDateOut}}</td>
                        <td>
                            <button v-if="item.answerYn == 'Y'" @click="routeResult(item)">결과확인</button>
                        </td>
                        <td>{{item.cardNo}}</td>
                        <td>
                            <button class="etcBtn" @click="reSend(item)">재발송</button>
                            <button class="etcBtn" @click="modify(item)">재등록</button>
                            <button
                                class="etcBtn"
                                v-if="item.deniedYn !== 'Y'"
                                @click="deniedVisitor(item)">출입거부</button>
                        </td>
                    </tr>
                </table>
            </div>
            <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" 
            :startDate="startDate" :endDate="endDate" 
            :name="name" :phone="phone" :purpose="purpose" :place="place"   
            />
        </div>
    </div>

</template>
<script>
    import pagination from '../../components/commonPagination.vue'
    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';
    import $ from 'jquery'
    export default {
        data: () => {
            return {
                visitorList: [],
                result:[],
                inqResult: [],
                name: null,
                phone: null,
                purpose: null,
                place: null,
                filter: false,
                startDate: moment()
                    .add(-1, 'd')
                    .format("YYYY-MM-DD"),
                endDate: moment()
                    .add(1, 'M')
                    .format("YYYY-MM-DD"),
                answerType: null,
                answerTime: null,
                identityNo:null,
                detailId:null,
                masterId:null,
                isNok:null,
            }
        },
        mixins: [mixin],
        mounted: function() {
            if(this.$route.query.page) {
                this.changeFilter() //검색 param을 넣기 위해서 필요
                this.name = this.$route.query.name || null
                this.phone = this.$route.query.phone|| null
                this.purpose = this.$route.query.purpose|| null
                this.place = this.$route.query.place|| null
                this.startDate = this.$route.query.startDate|| null
                this.endDate = this.$route.query.endDate|| null
                this.getList(this.$route.query.page);
            } else {
                this.getList(0);
            }
                        
        },
        components : {
            pagination
        },
        methods: {
            getList: function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }

                let param = {};

                if (this.filter) {
                    param = {
                        page: page,
                        size: 30,
                        name: this.name,
                        phone: this.phone,
                        purpose: this.purpose,
                        place: this.place,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                } else {
                    param = {
                        page: page,
                        size: 30,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                }

                this
                    .$axios
                    .get('/api/mng/visitor/v1/visitor', {params: param})
                    .then((response) => {
                        console.log(response.data);

                        if (response.data.success) {
                            let result = response.data.result.content;

                            this.visitorList = result;

                            let pageInfo = response.data.result;

                            this.pagerHandler(
                                pageInfo.number,
                                pageInfo.first,
                                pageInfo.last,
                                pageInfo.totalPages
                            );
                        } else {
                            this.openAlert("방문고객을 불러오는 도중 오류가 발생하였습니다.");
                        }
                    })
            },
            firstSearch: function () {
                let pageQuery = {
                    page : 0,
                    name : this.name || null,
                    phone : this.phone|| null,
                    purpose : this.purpose|| null,
                    place : this.place|| null,
                    startDate : this.startDate|| null,
                    endDate : this.endDate|| null
                }
                this.$router.push({
                    path: this.$route.path,
                    query: pageQuery
                }).catch(()=>{});
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            changeStartDate(event) {
                this.startDate = event.target.value;
            },
            changeEndDate(event) {
                this.endDate = event.target.value;
            },
            confirmDownload: function () {
                this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {
                let param = {};

                if (this.filter) {
                    param = {
                        page: 0,
                        size: 100000,
                        name: this.name,
                        phone: this.phone,
                        purpose: this.purpose,
                        place: this.place,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                } else {
                    param = {
                        page: 0,
                        size: 100000,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                }
                this
                    .$axios
                    .get('/api/mng/visitor/v1/visitor/excel', {
                        params: param,
                        responseType: "blob"
                    })
                    .then((response) => {
                        console.log(response);
                        const url = window
                            .URL
                            .createObjectURL(
                                new Blob([response.data], {type: response.headers['content-type']})
                            );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute(
                            'download',
                            '방문고객관리_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx'
                        );
                        document
                            .body
                            .appendChild(link);
                        link.click();

                        if (response.status == 200) {
                            let param = {
                                fileName: '방문고객관리_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                                fileSize: response.data.size,
                                purpose: paramdata.purpose,
                                purposeEtc: paramdata.purpose_etc == null
                                    ? ''
                                    : paramdata.purpose_etc
                            }
                            this.setDownloadHistory(param);
                        }
                    })
            },

            reSend: function (item) {

                this
                    .$axios
                    .post('/api/mng/visitor/v1/visitor/resend', {
                        seq: item.seq,
                        phone: item.phone,
                        name: item.name
                    })
                    .then((response) => {
                        if (response.data.success) {
                            this.openAlert("메시지를 재발송하였습니다.");
                        } else {
                            this.openAlert("메시지 발송중 오류가 발생했습니다.");
                        }
                    })
                    .catch(() => {
                        this.openAlert("메시지 발송중 오류가 발생했습니다.");
                    })

                },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.name = null;
                this.phone = null;
                this.startDate = moment()
                    .add(-1, 'd')
                    .format("YYYY-MM-DD");
                this.endDate = moment()
                    .add(1, 'M')
                    .format("YYYY-MM-DD");
                this.reserve = false;
                this.purpose = null;
                this.place = null;
            },
            add: function () {
                this
                    .$router
                    .push({name: 'visitorMng'});
            },
        inqResultDetail: function(item) {
            this.$router.push({
                name: 'inquiryResult',
                params: {
                    visitDate: item.visitDate,
                    reserveDate : item.reserveDate,     
                    identityNo : item.identityNo,
                    masterId : item.type                    
                }
            });
        },            

            deniedVisitor: function (item) {
                this.openConfirm("해당 방문객을 출입거부 하시겠습니까?", this.denied, item);
            },
            delVisitor: function (seq) {
                this.openConfirm("해당 방문객을 삭제하시겠습니까?", this.remove, seq);

            },
            denied: function (item) {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                this
                    .$axios
                    .post('/api/mng/visitor/v1/visitor/denied', {seq: item.seq})
                    .then(() => {
                        this.openAlertClosed("출입거부가 완료되었습니다.", this.getList, 0);
                        this.btnLock = false;
                    })
                    .catch(() => {
                        this.btnLock = false;
                    });
            },
            remove: function (seq) {
                console.log(seq);
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                this
                    .$axios
                    .post('/api/mng/visitor/v1/visitor/del', {seq: seq})
                    .then((response) => {

                        if (response.data.success) {
                            this.openAlertClosed("삭제되었습니다.", this.getList, 0);
                        } else {
                            this.openAlert("삭제중 오류가 발생하였습니다.");
                        }
                        this.btnLock = false;
                    })
                    .catch(() => {
                        this.openAlert("삭제중 오류가 발생하였습니다.");
                        this.btnLock = false;
                    });
            },
            modify: function (item) {
                this
                    .$router
                    .push({
                        name: 'visitorUpd',
                        query: {
                            info: item
                        }
                    })
            },
            convertType: function (type,isNok) {
                if (type != null) {
                    if (type == "PT") {
                        return "환자";
                    } else if (type == "GST") {
                        if(isNok =="Y"){
                            return "병동방문객";
                        }else{
                            return "방문객";
                        }
                    } else if (type == "NOK") {
                            return "보호자";               
                    } else if (type == "TMP") {
                        return "방문객";
                    } else if ( type == "PEMP"){
                        return "협력업체";
                    } else if (type == "ETC") {
                        return "기타";
                    }
                }
            },
            convertEntryType: function (type) {
                if (type != null) {
                    if (type == "oneday") {
                        return "당일권";
                    } else if (type == "allday") {
                        return "24시권";
                    } else if (type == "onetime") {
                        return "1회권";
                    }
                }
            },
            convertAnswerYn: function (value) {
                if (value !== null) {
                    if (value == "Y") {
                        return "시행";
                    }
                }
            },
            openPop: function (item) {

                let parameter = {};

                parameter = {     
                    page: 0,
                    size: 30,                          
                    reserveDate : item.reserveDate,     
                    identityNo : item.identityNo,
                    answerType : item.type
                }
                this
                    .$axios
                    .get("/api/mng/visitor/v1/visitor/inq-result", {params: parameter})
                    .then((response) => {
                        if(response.data.success){
                            let result = response.data.result.content;
                            let pageInfo = response.data.result;
                                this.pagerHandler(
                                pageInfo.number,
                                pageInfo.first,
                                pageInfo.last,
                                pageInfo.totalPages
                                );                            
                            this.inqResult = result ;
                        }
                    })
                
                this
                    .$modal
                    .show('addMaster');
            },
            closePop: function () {
                this.phone = null;
                this.type = null;
                this
                    .$modal
                    .hide('addMaster');
            },            
            inqResultCnt: function(value){
                if(value == 0 ){
                    return "해당없음"
                }else if (value >= 0 ){
                    return "해당있음"
                }

            },
            routeResult: async function (item){
                let isInquiry = await this.checkInquiryUse(item);
                if(isInquiry === undefined){
                    this.openAlertClosed('문진 정보를 가져오는 데 오류가 발생했습니다.');
                    return;
                } else if (isInquiry === false){
                    this.openAlertClosed(this.convertType(item.type,item.isNok) + " 문진이 미사용 중입니다.");
                    return;
                } else {
                    if(item.isNok =='Y') {
                        item.type = "WGST"
                    }
                    this.$router.push({
                        name: 'inquiryIndividual',
                        query: {
                            visitorList: item
                        }
                    })
                }
            },
            checkInquiryUse: async function(item) {
                let params = { inqType: item.type == 'RPT' ? 'ADMINER' : item.type }
                if(item.type == 'PT' ) {
                    params = { inqType: item.type,  ptNo: item.ptno, visitDate: item.reserveDate.replace(/-/g, '')};
                } 
                let response = await this
                    .$axios
                    .get("/api/v1/answerUseYn", {params: params})
                if(response.data.result.useYn === "Y"){
                    
                    return true;
                } else {
                    return false;
                }
                    // .then((response)=>{
                    //     if(response.data.result.useYn === "Y"){
                    //         return true;
                    //     } else {
                    //         return false;
                    //     }
                    // })
                    // .catch((err)=>{
                    //     console.log(err);
                    // })
            },                      
        }
    }
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
    .btn_md_custom {
        padding: 0 5px;
        min-width: auto;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
    }
    .etcBtn {
        width: 60px;
        padding: 0;
    }
    .pop {
        display: inline-block;
        box-shadow: none;
        overflow-y: auto;
        max-height: 500px;
        width: 1000px;
    }    
    .subHead {
        font-size : 13px;
        font-weight: bold;
        margin-bottom: 10px;

    }
    .ddText{
        text-align : center ;
        
    }
    .pop_body dt {
        width: 200%;
    }
    .titleBar{ 
        font-size : 15px; 
        font-weight: bold;
        
        
    }
    #titleBc{
        background-color: rgba(128, 126, 126, 0.212);
    }
    .dtText{
        margin-left: 30px;
        display: inline-block;
    }
    .spanId{
        margin-left: 100px;
    }


 
</style>