<template>
  <div class="contents">

    <div class="loc">
      <li>
        <a
            @click="() => {this
                            .$router
                            .push({
                                name: 'em-inq-list'})}">응급관리</a>
      </li>
      <li>
        <a href="javascript:void(0)">체류시간 안내문구 관리</a>
      </li>
    </div>
    <div class="wapper">
      <div class="title">
        <h2>체류시간 안내문구 관리</h2>
      </div>
      <div class="setVersion">
        <dl>
          <dt>사용설정</dt>
          <dd>
            <v-app style="min-height: 0 !important;">
              <v-switch
                  v-model="useYn"
                  readonly
                  color="primary"
                  @click="changeUseYn()"
                  hide-details="hide-details"></v-switch>
            </v-app>
            <span class="txt">{{ useYn ? '사용중' : '미사용' }}</span>
          </dd>
          <div style="display:inline-block">
            <!-- 예약 종료가 설정 되어 있는 경우 -->
            <div class="end-date" v-if="reserveDetail">
              <p style="font-size:12px;">
                {{ convertDate(reserveDetail.targetTime) }}에 자동으로
                {{ reserveDetail.onOffType == 'OFF' ? "종료" : "시작" }}됩니다. </p>
              <div class="dropdown">
                <button class="dropbtn">더보기</button>
                <div class="dropdown_conts">
                  <a href="javascript:void(0)" @click="inqRuleTimer()">수정하기</a>
                  <a
                      href="javascript:void(0)"
                      @click="delReserveConfirm()">삭제하기</a>
                </div>
              </div>
            </div>
            <div v-else @click="inqRuleTimer()">
              <img src = "@/assets/images/ico_stop-watch.png" style="width: 14px; margin-top: 4px; cursor: pointer;"/>
              <a>예약{{ useYn ? "종료" : "시작" }}</a>
            </div>
          </div>
        </dl>
      </div>
      <h3>안내문구 구성
        <div class="btn-right">
          <button class="btn_primary" @click="addVersion()">새버전 등록</button>
        </div>
      </h3>
      <div class="table table_line">
        <table>
          <colgroup>
            <col style="width: 15%"/>
            <col style="width: 15%"/>
            <col/>
            <col style="width: 15%"/>
            <col style="width: 15%"/>
            <col style="width: 10%"/>
          </colgroup>
          <tr>
            <th>버전</th>
            <th>상태</th>
            <th>적용일자</th>
            <th>마지막 수정</th>
            <th>수정일시</th>
            <th>관리</th>
          </tr>
          <tr v-for="(item, index) of residenceTimeList" :key="index">
            <td>{{ item.privacyVer }}</td>
            <td v-if="item.state === 'F'">만료</td>
            <td v-else-if="item.state === 'O'" class="label_ing">사용중</td>
            <td v-else-if="item.state === 'W'">대기중(미사용)</td>
            <td v-else-if="item.state === 'D'">삭제</td>
            <td v-if="item.endDate == null">
              {{
                item.startDate === null ? null : moment(item.startDate).format('YYYY-MM-DD HH:mm') + ' ~ '
              }}
            </td>
            <td v-else>
              {{
                item.startDate === null ? null : moment(item.startDate).format('YYYY-MM-DD HH:mm') + ' ~ ' + moment(item.endDate).format('YYYY-MM-DD HH:mm')
              }}
            </td>
            <td>{{ item.regDate === null ? null : moment(item.regDate).format('YYYY-MM-DD HH:mm') }}</td>
            <td>{{ item.updDate === null ? null : moment(item.updDate).format('YYYY-MM-DD HH:mm') }}</td>
            <td>
              <button class="btn_lang"
                      @click="$router.push({name : 'residenceTimeView', query:{code : item.privacyCode}})">조회
              </button>
            </td>
          </tr>
        </table>
      </div>
      <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0"/>
    </div>
    <modal
        name="inqRuleTimer"
        :width="540"
        :height="190"
        :adaptive="true"
        :clickToClose="false">
      <div id="popup_reg" class="pop pop_md">
        <div class="pop_head">
          <h2>예약 {{ useYn ? '종료' : '시작' }}</h2>
          <a class="btn_pop_close" @click="closeInqRuleTimer()">닫기</a>
        </div>
        <div class="pop_body modal-date-picker">
          설정된 시간에 체류시간 안내 기능이 자동으로 {{ useYn ? '종료' : '시작' }}됩니다.
          <date-picker style="background-color:transparent"
                       :dateParam.sync="reserveDate"
                       @dateVal="reserveDateFunc"
          ></date-picker>
          <time-picker style="background-color:transparent"
                       :timeParam=reserveTime
                       @dateVal="reserveTimeFunc">
          </time-picker>
          <!-- <input type="text" v-model="publishStartTimeInput" style="vertical-align:
          inherit; margin-left:5px"/> -->
        </div>
        <div class="pop_footer">
          <button class="btn_secondary" @click="closeInqRuleTimer()">취 소</button>
          <button class="btn_secondary" @click="changeUseYnRserve()">확 인</button>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import mixin from "@/mixin/mixin";
import moment from 'moment';
import datePicker from "../../components/datePicker.vue";
import timePicker from "../../components/datePickerTime.vue";
import pagination from "@/components/commonPagination.vue";

export default {
  name: "durationList",
  mixins: [mixin],
  data: () => {
    return {
      moment: moment,
      useYn: false,
      residenceTimeList: [],
      reserveDetail: null,
      reserveDate: moment().format("YYYY-MM-DD"),
      reserveTime: moment().format("HH:mm"),
      current: {
        version: null,
        startDate: null,
      },
      next: {
        version: null,
        startDate: null,
      },
      latestInfo: null,
    }
  },
  components: {
    pagination,
    datePicker,
    timePicker,
  },
  computed: {},
  mounted() {
    if(this.$route.query.page) {
      this.getResidenceTimeList(this.$route.query.page);
    } else {
      this.getResidenceTimeList(0);
    }
    this.getReserveInfo();
  },
  methods: {
    reserveDateFunc(date) {
      this.reserveDate = date
    },
    reserveTimeFunc(date) {
      this.reserveTime = date
    },
    async changeUseYnRserve() {
      await this.changeUseYn(this.reserveDate + ' ' + this.reserveTime);
      this.closeInqRuleTimer();
    },
    async changeUseYn(reserveDate) {
      let isValid = false;
      for(let residence of this.residenceTimeList){
        if(residence.state === 'O' || residence.state === 'W' || residence.state === 'F') {
          isValid = true;
        }
      }
      if (!isValid) {
        this.openAlert("안내문구를 등록하여 주세요.");
        return;
      }
      let yn = this.useYn ? "미사용" : "사용";
      yn += reserveDate ? " 예약" : "";
      await this.openConfirm("체류시간 안내문구를 " + yn + "하시겠습니까?", () => {
        this.$axios.post("/api/mng/v1/residence/on-off",
            {
              useYn: this.useYn ? 'N' : 'Y',
              reserveDate: reserveDate,
            })
            .finally(() => {
              this.getResidenceTimeList(0);
              this.getReserveInfo();
            })
      });
    },
    addVersion() {
      this
          .$router
          .push({
            name: 'residenceTimeMng'
          })
    },
    async getResidenceTimeList(page) {
      await this.$axios.get(
          "/api/mng/v1/residence",
          {
            params: {
              page: page,
              size: 30
            }
          }
      ).then(response => {
        if (response.data.success) {
          this.residenceTimeList = response.data.result.content;
          if (this.residenceTimeList.length === 0) {
            this.useYn = false;
          }
          let pageInfo = response.data.result;
          this.totalCount = pageInfo.totalElements;
          this.pagerHandler(
              pageInfo.number,
              pageInfo.first,
              pageInfo.last,
              pageInfo.totalPages
          );
        } else {
          this.openAlert("리스트 조회에 실패했습니다.");
        }
      }).finally(()=> {this.getLatestInfo()});
    },
    async getReserveInfo() {
      await this.$axios.get("/api/mng/v1/residence/on-off/reserve")
          .then((response) => {
            this.reserveDetail = response.data.result != null && response.data.result.seq ? response.data.result : null;
            if (response.data.result.targetTime != null) {
              this.reserveDate = moment(response.data.result.targetTime).format('YYYY-MM-DD')
              this.reserveTime = moment(response.data.result.targetTime).format('HH:mm')
            }
          })
    },
    async getLatestInfo() {
      await this.$axios.get("/api/mng/v1/residence/latest")
          .then((response) => {
            this.latestInfo = response.data.result;
            if (this.latestInfo != null) {
              if (this.latestInfo.state === "O" || this.latestInfo.state === "W") {
                this.useYn = this.latestInfo.state === "O";
                let current = Object.assign({}, this.current);
                current.version = this.latestInfo.privacyVer;
                current.startDate = this.latestInfo.startDate;
                Object.assign(this.current, current);
              } else {
                this.useYn = false;
              }
            }
          })
    },
    inqRuleTimer: function () {
      this
          .$modal
          .show('inqRuleTimer');
    },
    closeInqRuleTimer: function () {
      this
          .$modal
          .hide('inqRuleTimer');
    },
    delReserveConfirm() {
      this.openConfirm('예약 스케줄을 삭제하시겠습니까?', this.delReserveRuleTime)
    },
    delReserveRuleTime() {
      this.$axios.delete('/api/reserveRule?seq=' + this.reserveDetail.seq)
          .then(() => {
            // this.reserveStatus =  this.reserveStatus == 'off-show' ? "on-show" : 'off-show'
            this.closeInqRuleTimer()
            // this.getDetailInfo(this.masterId)
            this.getReserveRule();
          }).catch((err) => {
        console.log(err);
      });
    },
  }
}
</script>

<style scoped>
.theme--light.v-application {
  background: #f0f1f6;
  color: rgba(0, 0, 0, 0.87);
  float: left;
}

.v-input--selection-controls {
  margin-top: 0;
  padding-top: 6px;
  padding-bottom: 6px;
}

#app > div {
  flex: 1 1 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  min-height: 0 !important;
  max-width: 0 !important;
  position: relative;
}

.pop {
  display: inline-block;
  box-shadow: none;
}

</style>