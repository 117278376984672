<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">출입자</a>
            </li>
            <li>
                <a href="javascript:void(0)">협력업체 관리</a>
            </li>
            <li>
                <a href="javascript:void(0)">협력업체 수정</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>협력업체 수정</h2>
            </div>
            <div class="register">
                <dl>
                    <dt>업체명</dt>
                    <dd><input type="text" v-model="partnerName"/></dd>
                </dl>
                <dl>
                    <dt>사업자등록번호</dt>
                    <dd><input type="text" v-model="partnerRegNo"/></dd>
                </dl>
                <dl>
                    <dt>주소</dt>
                    <dd><input type="text" v-model="addr"/></dd>
                </dl>
                <dl>
                    <dt>대표전화번호</dt>
                    <dd><input type="text" v-model="phone"/></dd>
                </dl>
                <dl>
                    <dt>대표자명</dt>
                    <dd><input type="text" v-model="repName"/></dd>
                </dl>
                <dl>
                    <dt>관리자명</dt>
                    <dd><input type="text" v-model="managerName"/></dd>
                </dl>
                <dl>
                    <dt>관리자전화번호</dt>
                    <dd><input type="text" v-model="managerPhone"/></dd>
                </dl>
                <dl>
                    <dt>발열감지알림수신자</dt>
                    <dd>
                        <input
                            type="text"
                            v-model="partner_reg_deptNm"
                            placeholder="부서"
                            readonly="readonly"
                            style="width:20%;"/>
                        <input
                            type="text"
                            v-model="partner_reg_userNm"
                            placeholder="이름"
                            readonly="readonly"
                            style="width:20%; margin-left:4px"/>
                        <button class="btn_secondary" @click="openSearchUser()">검색</button>
                    </dd>

                </dl>
                <dl>
                    <dt>직원등록 권한</dt>
                    <dd>
                        <label class="switch">
                            <input type="checkbox" id="guide_check" v-model="empAurth"/>
                            <span class="slider"></span>
                        </label>
                    </dd>
                </dl>
            </div>
            <div class="btn_wrap">
                <div class="btn_left">
                    <button class="btn_secondary" @click="goList()">취 소</button>
                    <button class="btn_primary" @click="confirmSave()">저 장</button>
                </div>
                <div class="btn_rihgt">
                    <button class="btn_danger" @click="confirmDelete()">삭제</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import searchUser from '../../components/userSearch.vue';
    import mixin from '../../mixin/mixin.js';
    export default {
        data: () => {
            return {
                partnerCode: null,
                partnerName: null,
                partnerRegNo: null,
                addr: null,
                phone: null,
                repName: null,
                managerName: null,
                managerPhone: null,
                empAurth: false,
                partner_reg_userNm: null,
                partner_reg_deptNm: null,
                partner_reg_userId: null,
                partner_reg_deptCd: null,
                partnerRegId: null
            }
        },
        created: function () {
            this.partnerCode = this.$route.query.pid;
            this.getInfo(0);
        },
        watch: {

            partnerRegNo: function (newVal) {
                this.partnerRegNo = this.convertPartnerRegNo(newVal);
            },
            phone: function (newVal) {
                this.phone = this.convertRepPhone(newVal);
            },
            managerPhone: function (newVal) {
                this.managerPhone = this.convertPhone(newVal);
            },
            repName: function (newVal) {
                this.repName = this.convertRepName(newVal);
            },
            managerName: function (newVal) {
                this.managerName = this.convertManagerName(newVal);
            }

        },
        mixins: [mixin],
        methods: {
            openSearchUser: function () {
                this
                    .$modal
                    .show(searchUser, {
                        getSearchUserInfo: this.getSearchUserInfo
                    }, {
                        width: '640px',
                        height: 'auto',
                        adaptive: true,
                        clickToClose: false
                    },)
            },
            getSearchUserInfo: function (user, dept) {
                this.partner_reg_userId = user;
                this.partner_reg_deptCd = dept;
                this.getDetpUser();
            },
            getDetpUser: function () {
                this
                    .$axios
                    .get('/api/mng/common/v1/dept-user', {
                        params: {
                            userId: this.partner_reg_userId,
                            deptCd: this.partner_reg_deptCd
                        }
                    })
                    .then((response) => {
                        if (response.data.success) {
                            this.partner_reg_userNm = response.data.result.korNm;
                            this.partner_reg_deptNm = response.data.result.deptNm;
                        } else {
                            this.openAlert("사용자 정보를 가져오지 못했습니다.")
                        }

                    })
                    .catch(() => {
                        this.openAlert("사용자 정보를 가져오지 못했습니다.")
                    })
            },
            getInfo: function () {
                this
                    .$axios
                    .get('/api/mng/partner/group/v1/list', {
                        params: {
                            partnerCode: this.partnerCode
                        }
                    })
                    .then((response) => {
                        console.log(response.data);

                        if (response.data.success) {
                            let result = response
                                .data
                                .result
                                .content[0];

                            this.partnerCode = result.partnerCode;
                            this.partnerName = result.partnerName;
                            this.partnerRegNo = result.partnerRegNo;
                            this.addr = result.addr;
                            this.phone = result.phone;
                            this.repName = result.repName;
                            this.managerName = result.managerName;
                            this.managerPhone = result.managerPhone;
                            this.empAurth = result.empAurth;
                            this.partner_reg_userId = result.partnerRegId;
                            this.getDetpName();
                        
                        } else {
                            this.openAlert("협력업체를 불러오는 도중 오류가 발생하였습니다.");
                        }
                    })
                    .catch(() => {
                        this.openAlert("협력업체를 불러오는 도중 오류가 발생하였습니다.");
                    });
            },
            getDetpName: function () {
                this
                    .$axios
                    .get('/api/mng/common/v1/dept-name', {
                        params: {
                            userId: this.partner_reg_userId,
                        }
                    })
                    .then((response) => {
                        if (response.data.success) {
                            this.partner_reg_userNm = response.data.result.korNm;
                            this.partner_reg_deptNm = response.data.result.deptNm;
                        } else {
                            this.openAlert("등록자 정보를 가져오지 못했습니다.")
                        }

                    })
                    .catch(() => {
                        this.openAlert("등록자 정보를 가져오지 못했습니다.")
                    })
            },
            confirmSave: function () {
                let checkList = [
                    {
                        id: this.partnerName,
                        name: '업체명'
                    }, {
                        id: this.addr,
                        name: '주소'
                    }, {
                        id: this.phone,
                        name: '대표번호'
                    }, {
                        id: this.repName,
                        name: '대표자이름'
                    }, {
                        id: this.managerName,
                        name: '관리자이름'
                    }, {
                        id: this.managerPhone,
                        name: '관리자전화번호'
                    }, {
                        id: this.partner_reg_userId,
                        name: '발열감지알림수신자'
                    }
                ]

                let partnerRegNo = this.partnerRegNo;
                let phone = this.phone;
                let managerPhone = this.managerPhone;
                partnerRegNo = partnerRegNo.replaceAll('-','');
                phone = phone.replaceAll('-','');
                managerPhone = managerPhone.replaceAll('-','');

                if (!this.nullChecker(checkList)) {
                    return;
                }
                if (this.partnerName.trim().length > 50) {
                    this.openAlert('업체명은 50자를 넘을 수 없습니다.');
                    return false;
                }
                if (partnerRegNo != null) {
                    if (partnerRegNo.trim().length > 10) {
                        this.openAlert('사업자번호는 10자를 넘을 수 없습니다.');
                        return false;
                    }
                }
                if (this.addr.trim().length > 100) {
                    this.openAlert('주소는 100자를 넘을 수 없습니다.');
                    return false;
                }
                if (phone.trim().length > 11) {
                    this.openAlert('대표번호는 11자를 넘을 수 없습니다.');
                    return false;
                }
                if (this.repName.trim().length > 20) {
                    this.openAlert('대표자명은 20자를 넘을 수 없습니다.');
                    return false;
                }
                if (this.managerName.trim().length > 20) {
                    this.openAlert('관리자명은 20자를 넘을 수 없습니다.');
                    return false;
                }
                if (managerPhone.trim().length > 11) {
                    this.openAlert('관리자 전화번호는 11자를 넘을 수 없습니다.');
                    return false;
                }

                this
                    .$axios
                    .put('/api/mng/partner/group/v1/list', {
                        partnerCode: this.partnerCode,
                        partnerRegNo: partnerRegNo,
                        partnerName: this.partnerName,
                        addr: this.addr,
                        phone: phone,
                        repName: this.repName,
                        managerName: this.managerName,
                        managerPhone: managerPhone,
                        empAurth: this.empAurth,
                        partnerRegNm: this.partner_reg_userNm,
                        partnerRegId: this.partner_reg_userId
                    })
                    .then((response) => {
                        console.log(response.data);

                        if (response.data.success) {

                            this.openAlertClosed('저장이 완료되었습니다.', this.goList);

                        } else {
                            this.openAlert("저장하는 도중 오류가 발생하였습니다.");
                        }
                    })
                    .catch(() => {
                        this.openAlert("저장하는 도중 오류가 발생하였습니다.");
                    });

            },
            confirmDelete: function() {
                this.openConfirm("협력업체를 삭제 하시겠습니까?", this.deleteGroup);
            },
            deleteGroup: function() {
                this
                .$axios
                .post('/api/mng/partner/group/v1/list/del', {
                    partnerCode: this.partnerCode,
                    partnerName: this.partnerName
                })
                .then((response) => {
                    console.log(response.data);

                    if (response.data.success) {
                        this.openAlertClosed('삭제가 완료되었습니다.', this.goList);

                    } else {
                        this.openAlert("삭제중 오류가 발생하였습니다.");
                    }
                    this.btnLock = false;
                })
                .catch(() => {
                    this.openAlert("삭제중 오류가 발생하였습니다.");
                    this.btnLock = false;
                });
            },
            goList: function () {
                this
                    .$router
                    .go(-1);
                    // .push({name: 'partnerGroupList'});
            }

        }
    }
</script>
