<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javscript:void(0)">문진표통계분석</a>
            </li>
            <li>
                <a href="javscript:void(0)">방문객</a>
            </li>
        </div>
        <div class="wapper" style="min-height: 700px;">
            <div class="title">
                <h2>방문객</h2>
            </div>
            <div class="infection_search">
                <dl>
                    <dt>기간 <span class="ico_help">도움말</span></dt>
                    <dd>
                        <input type='date' v-model='startDate' @change="changeStartDate"/>
                        ~
                        <input type='date' v-model='endDate' @change="changeEndDate"/>
                    </dd>
                    <button class="btn_search" @click="getList(0)">조회</button>
                </dl>
            </div>
            <h3>
                <div class="btn-right">
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                </div>
            </h3>
            <div class="table">
                <table>
                    <tr>
                        <th>일자</th>
                        <th>URL 발송건수</th>
                        <th>모바일 문진건수</th>
                        <th>모바일<br>문진시행률</th>
                        <th>병원 홈페이지<br>문진건수</th>
                        <th>키오스크<br>문진건수</th>
                        <th>전체 문진건수</th>
                        <th>전체 문진시행률</th>
                    </tr>
                    <tr v-for="(item,index) in this.list" :key="index">
                         <td>{{convertDateDay(item.visitDate)}}</td>
                        <td>{{item.urlSendCnt}}</td>
                        <td>{{item.mobileInqCnt}}</td>
                        <td>{{ ((item.mobileInqCnt / (item.urlSendCnt == 0 ? 1 : item.urlSendCnt))* 100).toFixed(2)}}%</td>
                        <td>{{item.homeInqCnt}}</td>
                        <td>{{item.kioskInqCnt}}</td>
                        <td>{{item.totalInqCnt}}</td>
                        <td>{{((item.totalInqCnt / ((item.urlSendCnt + item.homeInqCnt +item.kioskInqCnt) == 0 ? 1 : (item.urlSendCnt + item.homeInqCnt +item.kioskInqCnt)))*100).toFixed(2)}}%</td>
                    </tr>
                    <tfoot>
                        <tr>
                            <td>전체</td>
                            <td>{{totalUrlSendCnt()}}</td>
                            <td>{{totalMobileInqCnt()}}</td>
                            <td>{{ ((totalMobileInqCnt() /( totalUrlSendCnt() == 0 ? 1 : totalUrlSendCnt())*100)).toFixed(2)}} %</td>
                            <td>{{totalHomeInqCnt()}}</td>
                            <td>{{totalKioskInqCnt()}}</td>
                            <td>{{totalInqCnt()}}</td>
                            <td>{{ ((totalInqCnt() / ((totalUrlSendCnt() + totalHomeInqCnt() + totalKioskInqCnt()) == 0 ? 1 : (totalUrlSendCnt() + totalHomeInqCnt() + totalKioskInqCnt()))*100)).toFixed(2)}} %</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <!-- <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" 
            :startDate="startDate" :endDate="endDate"/> -->
        </div>
    </div>

</template>
<script>
    import pagination from '../../components/commonPagination.vue'

    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';

    export default {
        data: () => {
            return {
                list: [],
                inqType: 'GST',
                startDate: moment().subtract(1, 'days').format("YYYY-MM-DD"),
                endDate: moment().subtract(1, 'days').format("YYYY-MM-DD")
            }
        },
        mixins: [mixin],
        mounted: function() {
            if(this.$route.query.page) {
                this.startDate = this.$route.query.startDate
                this.endDate = this.$route.query.endDate
                this.getList(this.$route.query.page);
            } else {
                this.getList(0);
            }
                        
        },
        components : {
            pagination
        },
        methods: {
            getList: function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }

                const param = {
                    page: page,
                    size: 30,
                    inqType: this.inqType,
                    startDate: this.startDate,
                    endDate: this.endDate,
                }
                this
                .$axios
                .get('/api/statistics/analysis/getGstlist',{
                    params: param
                })
                .then((response) => {
                    if (response.data.success) {
                        this.list = response.data.result.content;
                        let pageInfo = response.data.result;
                        this.pagerHandler(
                            pageInfo.number,
                            pageInfo.first,
                            pageInfo.last,
                            pageInfo.totalPages
                        );
                    } else {
                        this.openAlert("리스트 조회에 실패했습니다.");
                    }
                })
                .catch(() => {
                    this.openAlert("리스트 조회에 실패했습니다.");
                });
            },
            confirmDownload: function () {
                this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {
                const param = {
                    page: 0,
                    size: 2000,
                    inqType: this.inqType,
                    startDate: this.startDate,
                    endDate: this.endDate,
                }
                this
                .$axios
                .get('/api/statistics/analysis/getGstlist/excel', {
                    params: param,
                    responseType: "blob"
                })
                .then((response) => {
                    console.log(response);
                    const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download','문진표통계분석_방문객_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx');
                    document.body.appendChild(link);
                    link.click();

                    if(response.status == 200) {
                          let param = {
                              fileName: '문진표통계분석_방문객_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                              fileSize: response.data.size,
                              purpose: paramdata.purpose,
                              purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                          }
                          this.setDownloadHistory(param);
                    }
                })
            },
            changeStartDate(event) {
                this.startDate = event.target.value;
            },
            changeEndDate(event) {
                this.endDate = event.target.value;
            },
            totalUrlSendCnt: function () {
                var totalCnt = 0;
                this.list.forEach(e => {
                    totalCnt += e.urlSendCnt;
                });
                return totalCnt
            },
            totalMobileInqCnt: function () {
                var totalCnt = 0;
                this.list.forEach(e => {
                    totalCnt += e.mobileInqCnt;
                });
                return totalCnt
            },
            totalHomeInqCnt: function () {
                var totalCnt = 0;
                this.list.forEach(e => {
                    totalCnt += e.homeInqCnt;
                });
                return totalCnt
            },
            totalKioskInqCnt: function () {
                var totalCnt = 0;
                this.list.forEach(e => {
                    totalCnt += e.kioskInqCnt;
                });
                return totalCnt
            },
            totalInqCnt: function () {
                var totalCnt = 0;
                this.list.forEach(e => {
                    totalCnt += e.totalInqCnt;
                });
                return totalCnt
            }
        }
    }
</script>
