<template>
  <section id="tab_content4">
    <h3>
      <div class="btn-right">
        <button class="btn_search" @click="openSearch()">검색</button>
        <button class="btn_download" @click="confirmDownload()">다운로드</button>
        <div class="search">
          <h3>검색</h3>
          <div class="search_detail">
            <dl class="full_width">
              <dt>내원일</dt>
              <dd>
                <input type='date' v-model='startDate' @change="changeStartDate"/>
                ~
                <input type='date' v-model='endDate' @change="changeEndDate"/>
              </dd>
            </dl>
            <dl class="full_width">
              <dt>환자등록번호</dt>
              <dd><input type="text" v-model='patientNo'/></dd>
            </dl>
            <dl class="full_width">
              <dt>환자이름</dt>
              <dd><input type="text" v-model='patientName'/></dd>
            </dl>
          </div>
          <div class="search_footer">
            <button
                class="btn_secondary btn_md btn_filer"
                :class="{'btn_filered' : filter}"
                @click="changeFilter()">{{ this.filter ? "필터해제" : "필터적용" }}
            </button>
            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
            <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
          </div>
        </div>
      </div>
    </h3>
    <div class="table table_line">
      <table>
        <tr>
          <th>환자등록번호</th>
          <th>유형</th>
          <th>환자 이름</th>
          <th>보호자 이름</th>
          <th>보호자 연락처</th>
          <th>관계</th>
          <th>구분</th>
          <th>구역</th>
          <th>BED</th>
          <th>입실시간</th>
          <th>퇴실시간</th>
          <th>비고</th>
          <th>관리</th>
        </tr>
        <tr v-for="(item,index) in plist" :key="index" :class="{'hover': item.answerType=='ENOK'}">
          <td style="text-align: center">{{ item.answerType == "ENOK" ? '↳' : item.patNo }}</td>
          <td>{{ item.answerType == "ENOK" ? "보호자" : "환자" }}</td>
          <td>{{ item.patNm }}</td>
          <td v-if="item.answerType=='ENOK'" class="editable">
            <input type="text" v-model="item.nokName" :disabled="!item.isEdit">
          </td>
          <td v-if="item.answerType!=='ENOK'">{{ item.nokName }}
          </td>
          <td v-if="item.answerType=='ENOK'" class="editable">
            <input type="text" v-model="item.nokPhone" :disabled="!item.isEdit">
          </td>
          <td v-if="item.answerType!='ENOK'">
            {{ convertPhone(item.nokPhone) }}
          </td>
          <td v-if="item.answerType=='ENOK'" class="editable">
            <input type="text" v-model="item.relation" :disabled="!item.isEdit">
          </td>
          <td v-if="item.answerType!=='ENOK'">
            {{ item.relation }}
          </td>
          <td>{{ item.locKorNm }}</td>
          <td>{{ item.erZoneCd }}</td>
          <td>{{ item.erBedNo }}</td>
          <td>{{ item.inDate ? moment(item.inDate).format('YYYY-MM-DD HH:mm') : null }}</td>
<!--          <td v-if="item.answerType !=='ENOK'">{{ item.outDate }}</td>-->
<!--          <td v-if="item.answerType ==='ENOK'">-->
          <td>
            <div v-if="!item.isEdit">{{ item.outDate }} </div>
            <div v-if="item.isEdit" class="flatPicker">
              <flatPickr
                  v-model="item.outDate"
                  :config="flatPickrConfig"
                  class="form-control"
                  placeholder="Select date"
                  name="date"/>
            </div>
            <button v-if="item.isEdit" @click="() => {item.outDate = null}">비우기</button>
          </td>
          <td v-if="item.answerType !== 'ENOK'">{{ item.comment }}</td>
          <td v-if="item.answerType === 'ENOK'" class="editable">
            <input type="text" v-model="item.comment" :disabled="!item.isEdit"/>
          </td>
          <td v-if="item.answerType !== 'ENOK'">
            <button style="float:right" v-if="!item.isEdit && !item.outDate" @click="openEmmaRelation(item)">추가</button>
            <button style="float:right; margin-right: 10px" v-if="!item.isEdit" @click="makeEdit(item)">편집</button>
            <button v-if="item.isEdit" @click="cancelEdit(item)">취소</button>
            <button v-if="item.isEdit" @click="saveEdit(item)">저장</button>
          </td>
          <td v-if="item.answerType === 'ENOK'">
            <button style="color:#ff4848;" v-if="!item.isEdit  && isEditable(item)" @click="deleteENOK(item)">삭제</button>
            <button v-if="!item.isEdit && isEditable(item)" @click="makeEdit(item)">편집</button>
            <button v-if="item.isEdit" @click="cancelEdit(item)">취소</button>
            <button v-if="item.isEdit" @click="saveEdit(item)">저장</button>
          </td>
        </tr>
      </table>
    </div>
    <pagination :pageArray="pageNation" :page="page" v-if="pageNation.length != 0"
                :startDate="startDate" :endDate="endDate" :pname="patientName" :patNo="patientNo"
                :tab="$route.query.tab"/>
    <modal name="openEmmaRelation"
           :width="540"
           :height="390"
           :adaptive="true"
           :clickToClose="true">
      <openEmmaRelation :patient="patient" @save="save" @close="$modal.hide('openEmmaRelation')"/>
    </modal>
    <modal name="openEmmaDelete"
           :width="288"
           :height="256"
           :adaptive="true"
           :clickToClose="true">
      <div class="pop">
        <div class="pop_head">
          <h2>보호자 삭제</h2>
          <a class="btn_pop_close" href="javascript:void(0)" @click="$modal.hide('openEmmaDelete')">닫기</a>
        </div>
        <div class="pop_body">
          <div class="pop_reg">
            <p>삭제시 로그가 기록됩니다.
              <br/>
              삭제 사유를 입력하여 주세요.
            </p>
            <br/>
          </div>
          <div class="pop_reg">
            <dl></dl>
            <dl>
              <input class="width_fix" v-model="comment" type="text"
                     placeholder="삭제 사유를 입력하세요.(필수)"/>
            </dl>
          </div>
          <div class="pop_reg">
            <dl>
              <button class="btn_primary width_fix" @click="deleteConfirm()">보호자 삭제</button>
            </dl>
          </div>
        </div>
      </div>
    </modal>
  </section>
</template>
<script>
import pagination from '../../components/commonPagination.vue'
import mixin from '../../mixin/mixin.js';
import moment from 'moment';
import $ from 'jquery'
import openEmmaRelation from '../../components/openEmmaRelation.vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  mixins: [mixin],
  name: 'emmaList',
  data: () => {
    return {
      moment: moment,
      plist: [],
      filter: false,
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      patientName: null,
      patientNo: null,
      tempItem: {},//수정할 item 임시 저장
      isEditing: false, // 현재 수정중인 상태일때 true
      patient: null,
      comment: null,
      deleteSeq: null,
      flatPickrConfig : {
        monthSelectorType: "static",
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        time_24hr: true,
        maxDate:  moment().format("YYYY-MM-DD HH:mm")
      }
    }
  },
  components: {
    openEmmaRelation,
    pagination,
    flatPickr
  },
  mounted: function () {
    if (this.$route.query.page) {
      this.changeFilter()
      this.startDate = this.$route.query.startDate || moment().format("YYYY-MM-DD")
      this.endDate = this.$route.query.endDate || moment().format("YYYY-MM-DD")
      this.patientName = this.$route.query.pname || null
      this.patientNo = this.$route.query.patNo || null
      this.getPassPtNokResult(this.$route.query.page);
    } else {
      this.getPassPtNokResult(0);
    }

  },
  methods: {
    getPassPtNokResult: function (page) {
      if (page < 0 || this.page.totalPages < page) {
        return;
      }

      let params = {};

      if (this.filter) {
        let filterObj = {
          page: page,
          size: 30,
          patientName: this.patientName,
          patientNo: this.patientNo,
          startDate: this.startDate,
          endDate: this.endDate,
        }
        params = filterObj;
      } else {
        let filterObj = {
          page: page,
          size: 30,
          startDate: this.startDate,
          endDate: this.endDate
        }
        params = filterObj;
      }
      this.$axios
          .get('/api/mng/entrance/emma/relation', {params: params})
          .then((response) => {
            this.plist = response.data.result.content;
            for (let element of this.plist) {
              element.isEdit = false;
              const outDate = element.outDate;
              element.outDate = outDate === null ? '' : moment(outDate).format('YYYY-MM-DD HH:mm')
            }
            let pageInfo = response.data.result;
            this.totalCount = pageInfo.totalElements;
            this.$emit('totalCountFunc', this.totalCount);
            this.pagerHandler(
                pageInfo.number,
                pageInfo.first,
                pageInfo.last,
                pageInfo.totalPages
            );
          })
    },
    openSearch: function () {
      $('.search').slideToggle('down');
    },
    closeSearch: function () {
      $('.search').slideToggle('up');
    },
    firstSearch: function () {
        let pageQuery = {
            page : 0,
            patNo : this.patientNo || null,
            pname : this.patientName|| null,
            startDate : this.startDate|| null,
            endDate : this.endDate|| null,
            tab : this.$route.query.tab
        }
        this.$router.push({
            path: this.$route.path,
            query: pageQuery
        }).catch(()=>{});
    },
    changeFilter: function () {
      this.filter = !this.filter;
      if (!this.filter) {
        this.initSearchCnd();
      }
    },
    initSearchCnd: function () {
      this.startDate = moment().format("YYYY-MM-DD");
      this.endDate = moment().format("YYYY-MM-DD");
      this.patientName = null;
      this.patientNo = null;
    },
    changeStartDate(event) {
      this.startDate = event.target.value;
    },
    changeEndDate(event) {
      this.endDate = event.target.value;
    },
    confirmDownload: function () {
      this.downloadConfirm(this.confirmExcelDown);
    },
    confirmExcelDown: function (param) {
      this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
    },
    excelDown: function (paramdata) {
      let params = {};
      if (this.filter) {
        params = {
          patientName: this.patientName,
          patientNo: this.patientNo,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      } else {
        params = {
          startDate: this.startDate,
          endDate: this.endDate
        };
      }
      this.$axios
          .get('/api/mng/entrance/emma/relation/excel', {params: params, responseType: "blob"})
          .then((response) => {
            console.log(response);
            const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '응급실_출입관리' + moment().format("YYYYMMDD_HHmmss") + '.xlsx');
            document.body.appendChild(link);
            link.click();

            if (response.status == 200) {
              let param = {
                fileName: '응급실_출입관리' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                fileSize: response.data.size,
                purpose: paramdata.purpose,
                purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
              }
              this.setDownloadHistory(param);
            }
          })
    },
    makeEdit(item) {
      if (this.isEditing) {
        alert("현재 수정중인 내용이 있습니다. 이전 수정을 완료 후 다시 시도해주세요.")
      } else {
        this.flatPickrConfig.maxDate = moment().format("YYYY-MM-DD HH:mm");
        this.tempItem = Object.assign({}, item);
        this.isEditing = true;
        item.isEdit = true;
        this.plist = Object.assign([], this.plist);
      }
    },
    deleteENOK(item) {
      this.comment = null;
      this.deleteSeq = item.seq;
      this.$modal.show('openEmmaDelete',)
    },
    async deleteConfirm() {
      if (this.comment == null || this.comment.trim().length == 0) {
        this.openAlert("삭제 사유를 입력하세요.");
        return;
      }
      if (this.comment.length > 45) {
        this.openAlert("45자 이내로 작성하세요.");
        return;
      }
      await this.openConfirm("보호자를 삭제하시겠습니까?",
          () => {
            this.$axios
                .delete("/api/mng/entrance/emma/relation/" + this.deleteSeq + "?comment=" + this.comment)
                .then(response => {
                  if (response.data.resultCd == 0) {
                    this.getPassPtNokResult(this.$route.query.page);
                  } else {
                    this.openAlert("삭제를 완료할 수없습니다.");
                  }
                }).catch(() => {
              this.openAlert("삭제중 오류가 발생했습니다.");
            }).finally(() => {
              this.comment = null;
              this.deleteSeq = null;
              this.$modal.hide("openEmmaDelete")
            })
          }
      )
    },
    async saveEdit(item) {
      const phoneEx = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/;
      if (item.nokPhone && !phoneEx.test(item.nokPhone)) {
        this.openAlert('올바른 전화번호 형식을 입력하세요.')
        return;
      }

      let response = await this.$axios.put('/api/mng/entrance/emma/relation', {
        seq: item.seq,
        relation: item.relation,
        nokName: item.nokName,
        nokPhone: this.convertPhone(item.nokPhone),
        comment: item.comment,
        outDate: this.convertTime(item.outDate),
      })
      console.log(response);
      if (!response.data.success) {
        this.openAlert("저장중 오류가 발생하여 저장할 수 없습니다.");
      } else {
        this.getPassPtNokResult(this.$route.query.page);
      }
      this.isEditing = false;
      item.isEdit = false
      this.plist = Object.assign([], this.plist);
    },
    cancelEdit(item) {
      item.nokPhone = this.tempItem.nokPhone;
      item.nokName = this.tempItem.nokName;
      item.relation = this.tempItem.relation;
      item.comment = this.tempItem.comment;
      item.outDate = this.tempItem.outDate;
      item.isEdit = false;
      this.isEditing = false;
      this.plist = Object.assign([], this.plist);
    },
    openEmmaRelation: function (item) {
      this.patient = item;
      this.$modal.show('openEmmaRelation');
    },
    async save() {
      this.$modal.hide('openEmmaRelation');
      await this.getPassPtNokResult(this.$route.query.page);
    },
    convertTime: function(time){
      if(time) {
         const moment = this.moment(time,'YYYY-MM-DDThh:mm');
         return moment.format('YYYY-MM-DD HH:mm')
      } else {
        return null;
      }
    },
    isEditable: function(item) {
      if(item.patNo && item.vistSn){
        for(let pat of this.plist) {
          if(pat.patNo === item.patNo && pat.vistSn === item.vistSn) {
            return !(pat.outDate);
          }
        }
      }
      return false;
    }
  }
};
</script>
<style scoped="scoped">
.search_footer .btn_filered {
  margin-right: auto;
  justify-self: start;
  background-color: #E85656;
  border: 1px solid #E85656;
  color: white;
}

.btn_md_custom {
  padding: 0 5px;
  min-width: auto;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  display: inline;
  font-weight: 600;
  color: #ffffff;
  border: 1px solid transparent
}
</style>
<style scoped>
.hover:hover {
  background-color: #F5F6FB;
}

.hover td.editable {
  padding: 0;

}

.hover td.editable input {
  /* width: 100px; */
}

.hover td.editable input:read-only {
  border: none;
  background: transparent;
  /* height: unset;
  line-height: unset; */
}

td button {
  float: right;
  margin-top: 5px;
  margin-right: 10px;
}

td button:first-child {
  margin-right: 0px;
}

.pop {
  display: inline-block;
  color: #393939;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 12px 14px 0 rgb(0 0 0 / 20%);
}

.pop_reg {
  display: flex;
  flex-direction: row;
}

.pop_reg dl {
  margin-bottom: 0px !important;
  padding: 10px 0px;
  border-bottom: 0px;
}

.width_fix {
  width: 220px;
}

.flatPicker{
  display: inline-block;
}

.flatPicker input{
  width: 130px;
}
</style>
