<template>
  <div class="contents">
    <div class="loc">
      <li>
        <a
            @click="() => {this
                            .$router
                            .push({
                                name: 'em-inq-list'})}">응급관리</a>
      </li>
      <li>
        <a href="javascript:void(0)">체류시간 안내문구 관리</a>
      </li>
    </div>
    <div class="wapper" style="min-height: 800px">
      <div class="title">
        <h2>체류시간 동의내역 조회</h2>
        <div class="btn-right">
          <button class="btn_search" @click="openSearch">검색</button>
          <button class="btn_download" @click="confirmDownload">다운로드</button>
          <div class="search">
            <h3>검색</h3>
            <div class="search_detail">
              <dl class="full_width">
                <dt>구분</dt>
                <dd>
                  <select v-model="answerType">
                    <option value="">전체</option>
                    <option value="EPT">환자</option>
                    <option value="ENOK">보호자</option>
                  </select>
                </dd>
              </dl>
              <dl class="full_width">
                <dt>이름</dt>
                <dd>
                  <input v-model="identityName" type="text"/></dd>
              </dl>
              <dl class="full_width">
                <dt>고유번호</dt>
                <dd>
                  <input v-model="identityNo" type="text"/></dd>
              </dl>
              <dl class="full_width">
                <dt>동의버전</dt>
                <dd>
                  <select v-model="residenceCode">
                    <option value="">전체</option>
                    <option
                        v-for="(item,index) in this.residenceList"
                        :key="index"
                        :value="item.privacyCode">{{ item.privacyVer }}
                    </option>
                  </select>
                </dd>
              </dl>
              <dl class="full_width">
                <dt>동의일자</dt>
                <dd>
                  <date-picker
                      :dateParam.sync="agreeDate"
                      @dateVal="startDateFunc"
                      style="width: 150px; height:40px;"></date-picker>
                </dd>
              </dl>
            </div>
            <div class="search_footer">
              <button
                  class="btn_secondary btn_md btn_filer"
                  :class="{'btn_filered' : filter}"
                  @click="changeFilter()">{{ this.filter ? "필터해제" : "필터적용" }}
              </button>
              <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
              <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
            </div>
          </div>
        </div>
      </div>
      <div class="table table_line">
        <table>
          <tr>
            <th>구분</th>
            <th>고유번호</th>
            <th>이름</th>
            <th>동의버전</th>
            <th>동의일시</th>
            <th>설정</th>
          </tr>
          <tr v-for="(item,index) in agreeList" :key="index">
            <td v-if="item.answerType === 'EPT'">환자(응급실)</td>
            <td v-else-if="item.answerType === 'ENOK'">보호자(응급실)</td>
            <td>{{ item.identityNo }}</td>
            <td>{{ item.identityName }}</td>
            <td>{{ item.residenceVer }}
              <button @click="$router.push({name : 'residenceTimeView', query:{code : item.residenceCode}})">보기</button>
            </td>
            <td>{{ convertDate(item.agreeDate) }}</td>
            <td>
              <button @click="deleteConfirm(item.answerId)">삭제</button>
            </td>
          </tr>
        </table>
      </div>
      <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0"
                   :answerType = "answerType" :identityName = "identityName" :identityNo = "identityNo" 
                   :residenceCode = "residenceCode" :agreeDate = "agreeDate"
      />
    </div>
  </div>

</template>

<script>

import mixin from '../../mixin/mixin.js';
import datePicker from '../../components/datePicker.vue';
import moment from 'moment';
import $ from 'jquery'
import pagination from "@/components/commonPagination.vue";

export default {
  data: () => {
    return {
      agreeList: [],
      answerType: "",
      inqType: null,
      identityName: null,
      identityNo: null,
      residenceCode: "",
      agreeDate: null,
      residenceList: [],
      filter: false, //검색필터 적용여부.
    }
  },
  mixins: [mixin],
  created: function () {
    this.getResidenceVer();
    if(this.$route.query.page) {
      this.changeFilter() //검색 param을 넣기 위해서 필요
      this.answerType = this.$route.query.answerType || null;
      this.identityName = this.$route.query.identityName || null;
      this.identityNo = this.$route.query.identityNo || null;
      this.residenceCode = this.$route.query.residenceCode || null;
      this.agreeDate = this.$route.query.agreeDate || null;
      this.getResidenceList(this.$route.query.page);
    } else {
      this.getResidenceList(0);
    }
  },
  components: {
    pagination,
    'date-picker': datePicker
  },
  methods: {
    async getResidenceList(page) {
      if (page < 0 || this.page.totalPages < page) {
        return;
      }

      let params = {};
      if (this.filter) {
        params = {
          page: page ? page : 0,
          size: 30,
          answerType: this.answerType,
          identityName: this.identityName,
          identityNo: this.identityNo,
          residenceCode: this.residenceCode,
          agreeDate: this.agreeDate
        }
      } else {
        params = {
          page: page,
          size: 30,
          type: 'P'
        };
      }
      await this
          .$axios
          .post('/api/mng/v1/residence/agree-list', params)
          .then((response) => {
            console.log(response.data);
            this.agreeList = response.data.result.content;
            let pageInfo = response.data.result;
            this.totalCount = pageInfo.totalElements;
            this.pagerHandler(
                pageInfo.number,
                pageInfo.first,
                pageInfo.last,
                pageInfo.totalPages
            );
          })
    },
    async getResidenceVer() {
      await this
          .$axios
          .get('/api/mng/v1/residence', {
            params: {
              page: 0,
              size: 100
            }
          })
          .then((response) => {
            console.log(response.data)
            if (response.data.success) {
              this.residenceList = response.data.result.content;
            }

          })
          .catch();
    },
    firstSearch: function () {
        let pageQuery = {
            page : 0,
            answerType: this.answerType ||null,
            identityName : this.identityName || null,
            identityNo : this.identityNo|| null,
            residenceCode: this.residenceCode || null,
            agreeDate: this.agreeDate || null,
        }
        this.$router.push({
            path: this.$route.path,
            query: pageQuery
        }).catch(()=>{});
    },
    deleteConfirm: function (answerId) {
      this.openConfirm("동의서 동의 내역을 삭제 하시겠습니까?", this.deleteAgree, answerId);
    },
    deleteAgree: function (answerId) {
      if (this.btnLock) {
        return;
      } else {
        this.btnLock = true;
      }
      this
          .$axios
          .post('/api/mng/v1/residence/agree-list/' + answerId)
          .then((response) => {
            if (response.data.success) {
              this.openAlertClosed("삭제가 완료되었습니다.", this.getPrivacyList, this.page.number);
            } else {
              this.openAlert("삭제중 오류가 발생했습니다.");
            }
          })
          .catch(() => {
            this.openAlert("삭제중 오류가 발생했습니다.");
          })
          .finally(() => {
            this.btnLock = false;
            this.getResidenceList(0);
          })
    },
    openSearch: function () {
      $('.search').slideToggle('down');
    },
    confirmDownload: function () {
      this.downloadConfirm(this.confirmExcelDown);
    },
    confirmExcelDown: function (param) {
      this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
    },
    excelDown: function (paramdata) {
      let params = {};
      if (this.filter) {
        params = {
          answerType: this.answerType,
          identityName: this.identityName,
          identityNo: this.identityNo,
          residenceCode: this.residenceCode,
          agreeDate: this.agreeDate
        }
      }
      this
          .$axios
          .get('/api/mng/v1/residence/agree-list/excel', {params: params, responseType: "blob"})
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '개인정보동의내역조회_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx');
            document.body.appendChild(link);
            link.click();

            if (response.status == 200) {
              let param = {
                fileName: '체류시간_동의내역조회_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                fileSize: response.data.size,
                purpose: paramdata.purpose,
                purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
              }
              this.setDownloadHistory(param);
            }
          })
    },
    startDateFunc: function (date) {
      this.agreeDate = date;
    },
    closeSearch: function () {
      $('.search').slideToggle('up');
    },
    changeFilter: function () {
      this.filter = !this.filter;
      if (!this.filter) {
        this.initSearchCnd();
      }
    },
    initSearchCnd: function () {
      this.answerType = "";
      this.identityName = null;
      this.identityNo = null;
      this.residenceCode = "";
      this.agreeDate = null;
    }
  }
}
</script>
<style scoped="scoped">
.search_footer .btn_filered {
  margin-right: auto;
  justify-self: start;
  background-color: #E85656;
  border: 1px solid #E85656;
  color: white;
}
</style>
