<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">알림서비스</a>
            </li>
            <li>
                <a href="javascript:void(0)">직원발열관리및조회</a>
            </li>
            <li>
                <a href="javascript:void(0)">직원발열 제외사번 신규등록</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>직원발열 제외사번 신규등록</h2>
            </div>
            <div class="register">
                <dl>
                    <dt style="text-align:center;">사번</dt>
                    <dd>
                        <div v-for="(item, index) in this.exceptList" :key="index">
                            <input v-model="item.usrEmpno" type="text" placeholder="사번"/>
                            <button v-if="index > 0" @click="delExcept(index)" class="btn_del">삭제</button>
                        </div>
                        <p>
                            <button
                                @click="addExcept()"
                                class="btn_secondary btn_md"
                                style="width:190px; margin-left:0;">+ 추가</button>
                        </p>
                    </dd>
                </dl>
            </div>
            <div class="btn_wrap">
                <div class="btn_left">
                    <button class="btn_secondary" @click="goPrev()">취 소</button>
                    <button class="btn_primary" @click="saveConfirm()">저 장</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import mixin from '../../mixin/mixin.js';
    export default {
        data: () => {
            return {
                exceptList: [
                    {
                        usrEmpno: null
                    }
                ]
            }
        },
        mixins: [mixin],
        methods: {
            delExcept: function (index) {
                let exceptList = JSON.parse(JSON.stringify(this.exceptList));
                exceptList.splice(index, 1);
                console.log(exceptList);
                this.exceptList = exceptList;
            },
            addExcept: function () {
                let obj = {
                    usrEmpno: null
                }
                this
                    .exceptList
                    .push(obj);
            },
            saveConfirm: function () {
                for (let item of this.exceptList) {
                    console.log(item.usrEmpno);
                    if (item.usrEmpno == null) {
                        this.openAlert("사번을 입력하여 주세요.");
                        return;
                    }
                    if (item.usrEmpno.trim() == '') {
                        this.openAlert("사번을 입력하여 주세요.");
                        return;
                    }
                    let regex = /^[a-zA-Z]{1,2}[0-9]{5,6}$/;
                    if (!regex.test(item.usrEmpno)) {
                        this.openAlert("사번형식에 맞게 입력해 주세요.");
                        return;
                    }
                    item.usrEmpno = item
                        .usrEmpno
                        .trim()
                        .toUpperCase();
                }
                this.openConfirm("저장 하시겠습니까?", this.save);
            },
            save: function () {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                this
                    .$axios
                    .post('/api/mng/service/empinqmng/v1/emp-except', {exceptList: this.exceptList})
                    .then((response) => {
                        if (response.data.success) {
                             this.btnLock = false;
                            console.log(response.data.result);
                            this.openAlertClosed("저장이 완료되었습니다.", this.goPrev, null);

                        } else {
                            this.openAlert(response.data.resultMsg);
                             this.btnLock = false;
                        }
                        this.btnLock = false;
                    })
                    .catch(() => {
                        this.openAlert("저장중 오류가 발생했습니다.");
                        this.btnLock = false;
                    })
                },
            goPrev: function () {
                this
                    .$router
                    .push({
                        name: 'empInfManage',
                        query: {
                            tid: window.btoa(3)
                        }
                    })
            }
        }
    }
</script>
<style scoped="scoped">
    .btn_del {
        padding: 0 10px 0 25px;
        background: url("../../assets/images/ico_del.svg") no-repeat 3px 5px;
        height: 36px;
        line-height: 36px;
        border: 1px solid #A0A3B9;
        border-radius: 3px;
        color: #A0A3B9;
        font-size: 15px;
        margin-left: 20px;
    }
</style>