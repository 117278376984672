<template>

    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">알림서비스</a>
            </li>
            <li>
                <a href="javascript:void(0)">알림사용통계분석</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>알림사용 통계 분석</h2>
            </div>
            <div class="version_info">
                <dl>
                    <dt>기간</dt>
                    <dd>
                        <v-app>
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="startDate"
                                transition="scale-transition"
                                offset-y="offset-y"
                                min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <input
                                        class="input_cal"
                                        v-model="startDate"
                                        label="적용일자를 선택하세요."
                                        readonly="readonly"
                                        v-bind="attrs"
                                        v-on="on"
                                        id="startDate"
                                        style="height: 32px;"/>
                                </template>
                                <v-date-picker
                                    v-model="startDate"
                                    :weekday-format="getWeekDay"
                                    :month-format="getMonth"
                                    :header-date-format="getHeader"
                                    no-title="no-title"
                                    scrollable="scrollable">
                                    <v-spacer></v-spacer>
                                    <v-btn text="text" color="primary" @click="menu = false">
                                        취소
                                    </v-btn>
                                    <v-btn text="text" color="primary" @click="$refs.menu.save(startDate)">
                                        확인
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>

                        </v-app>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <v-app>
                            <v-menu
                                ref="menu2"
                                v-model="menu2"
                                :close-on-content-click="false"
                                :return-value.sync="endDate"
                                transition="scale-transition"
                                offset-y="offset-y"
                                min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <input
                                        class="input_cal"
                                        v-model="endDate"
                                        label="적용일자를 선택하세요."
                                        readonly="readonly"
                                        v-bind="attrs"
                                        v-on="on"
                                        id="endDate"
                                        style="height: 32px;"/>
                                </template>
                                <v-date-picker
                                    v-model="endDate"
                                    :weekday-format="getWeekDay"
                                    :month-format="getMonth"
                                    :header-date-format="getHeader"
                                    no-title="no-title"
                                    scrollable="scrollable">
                                    <v-spacer></v-spacer>
                                    <v-btn text="text" color="primary" @click="menu2 = false">
                                        취소
                                    </v-btn>
                                    <v-btn text="text" color="primary" @click="$refs.menu2.save(endDate)">
                                        확인
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-app>

                    </dd>
                </dl>
            </div>
            <h3>
                통계
                <div class="btn-right">
                    <button class="btn_secondary" @click="confirmDownload">다운로드</button>
                </div>
            </h3>
            <div class="chart_wrap">
                <div style="width: 100%;">                   
                    <canvas id ="myChart"  height="300"  width="1000"></canvas>
                </div>
            </div>
            <div class="table" style="min-height:400px">
                <table>
                    <colgroup>
                        <col width="30%"/>
                        <col width="70%"/>
                    </colgroup>
                    <tr>
                        <th>날짜</th>
                        <th style="text-align: center;">발송 수</th>

                    </tr>
                    <tr v-for="(item,index) in this.listData" :key="index">
                        <td>{{item.sendDate}}</td>
                        <td style="text-align: center;">{{item.cnt}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from '../../mixin/mixin.js';
    import Chart from 'chart.js/auto';
    import moment from 'moment';
    export default {       
        watch: {
            startDate: function () {
                this.getList();
            },
            endDate: function () {
                this.getList();
            }
        },
        data: () => {
            return {
                startDate: null,
                endDate: new Date()
                    .toISOString()
                    .substring(0, 10),
                menu: false,
                menu2: false,
                chartData: null,
                listData: null,
                labels: [],
                datasets: [],
                myChart: {},
                ctx : null,
            }
        },
        mounted: function () {
            let oneMonthAgo = new Date();
            oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
            this.startDate = oneMonthAgo
                .toISOString()
                .substring(0, 10);
            this.ctx = document.getElementById('myChart').getContext("2d");  
            this.createChart(this.chartData);         
            this.getList();
           
        },
        mixins: [mixin],
        methods: {
            getList: function () {
                this.myChart.destroy();
                this.labels = [];
                this.datasets = [];
                if(this.startDate > this.endDate) {
                    this.openAlert("시작일이 종료일보다 나중일 수 없습니다.");
                    return;
                }

                let starDate = new Date(this.startDate).getTime();
                let endDate = new Date(this.endDate).getTime();
                let diff = (endDate - starDate) / (1000 * 60 * 60 * 24)
                if(diff > 31 ) {
                    this.openAlert("한달 이상의 결과는 조회할 수 없습니다.");
                    return;
                }
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                this
                    .$axios
                    .get('/api/mng/service/send-anal/v1/list', {
                        params: {
                            startDate: this.startDate,
                            endDate: this.endDate
                        }
                    })
                    .then((response) => {
                         this.btnLock = true;
                        if (response.data.success) {
                            let label = [];
                            let sendCnt = [];
                            console.log(response.data.result);
                            for (let item of response.data.result) {
                                label.push(item.sendDate);
                                sendCnt.push(item.cnt);
                            }
                           
                            let chartDataSets = {
                                labels:  label,
                                datasets: [
                                    {
                                        type:'bar',
                                        label: "발송건수",
                                        backgroundColor: "#006769",
                                        data: sendCnt
                                    }
                                ]
                            }
                            this.chartData = chartDataSets;
                            console.log('chartData : ', this.chartData);
                            this.createChart(this.chartData);
                            console.log('okChart');
                            this.listData = response.data.result;
                        } else {
                            this.openAlert('통계데이터를 불러오지 못하였습니다.');
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        this.openAlert('통계데이터를 불러오지 못하였습니다.');
                    })
                },
            createChart: function(chartData) {
                console.log('chartData', chartData);                    
                console.log('ctx', this.ctx);
                this.myChart = new Chart(this.ctx, {
                    type: 'bar',
                    data: chartData,
                    });
                 console.log('endChart');
            },
                confirmDownload: function () {
                	this.downloadConfirm(this.confirmExcelDown);
                },
                confirmExcelDown: function (param) {
                	this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
                },
                excelDown: function (paramdata) {
                    let params = {
                        startDate: this.startDate,
                        endDate: this.endDate
                    };

                    this
                    .$axios
                    .get('/api/mng/service/send-anal/v1/list/excel', {params: params, responseType: "blob"})
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', '알림사용통계분석_'+moment().format("YYYYMMDD_HHmmss")+'.xlsx');
                        document.body.appendChild(link);
                        link.click();

                        if(response.status == 200) {
                        	let param = {
                        		fileName: '알림사용통계분석_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                        		fileSize: response.data.size,
                        		purpose: paramdata.purpose,
                        		purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                        	}
                        	this.setDownloadHistory(param);
                        }
                    })
                }
        }
        }
</script>
<style>
    .small {
        max-width: 600px;
        margin: 150px auto;
    }
</style>
