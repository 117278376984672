<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">출입자</a>
            </li>
            <li>
                <a href="javascript:void(0)">직원·관계사</a>
            </li>
        </div>
        <div class="wapper" style="min-height:700px">
            <div class="title">
                <h2>출입자 관리 (직원·관계사)</h2>
                <p class="sub_title">{{convertDateDay(new Date())}} 기준 출입대상 (직원·관계자) 목록입니다.(총
                    {{totalCount}}명)</p>
            </div>
            <h3>
                <div class="btn-right">
                    <button class="btn_search" @click="openSearch()">검색</button>
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">
                            <dl class="full_width">
                                <dt>기간</dt>
                                <dd>
                                    <input type='date' v-model='startDate' @change="changeStartDate"/>
                                    ~
                                    <input type='date' v-model='endDate' @change="changeEndDate"/>
                                </dd>
                            </dl>
                            <dl class="full_width">
                                <dt>부서명</dt>
                                <dd><input type="text" v-model='deptNm'/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>협력업체명</dt>
                                <dd><input type="text" v-model='partnerNm'/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>이름</dt>
                                <dd><input type="text" v-model='usrName'/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>직원번호</dt>
                                <dd><input type="text" v-model='usrId'/></dd>
                            </dl>
                        </div>
                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'btn_filered' : filter}"
                                @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                            <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
                        </div>
                    </div>
                </div>
            </h3>
            <div class="table table_line">
                <table>
                    <tr>
                        <th>소속</th>
                        <th>직원번호</th>
                        <th>협력업체명</th>
                        <th>직원명</th>
                        <th>출입허용</th>
                        <th>출입일시</th>
                        <th>출입장소</th>
                        <th>퇴실일시</th>
                        <th>퇴실장소</th>
                        <th>출입기록</th>
                    </tr>
                    <tr v-for="(item,index) in this.list" :key="index">
                        <td>{{item.dept_nm}}</td>
                        <td>{{item.usr_id}}</td>
                        <td>{{item.partner_name}}</td>
                        <td>{{item.kor_nm}}</td>
                        <td>{{convertAllow(item.allow_entry)}}</td>
                        <td>{{item.regDateIn}}</td>
                        <td>{{item.placeNameIn}}</td>
                        <td>{{item.regDateOut}}</td>
                        <td>{{item.placeNameOut}}</td>
                        <td><button v-if="item.regDateIn !== null" @click="getInoutDetail(item)">보기</button></td>
                    </tr>
                </table>
            </div>
            <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" 
            :startDate="startDate" :endDate="endDate"
            :name="usrName"  :deptName="deptNm"
            :usr="usrId" :partnerName="partnerNm"
            />
        </div>
    </div>
</template>
<script>
    import pagination from '../../components/commonPagination.vue'
    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';
    import $ from 'jquery'

    export default {
        mixins: [mixin],
        data: () => {
            return {list: [], filter: false, totalCount: 0,
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
                deptNm: null,
                partnerNm: null,
                usrName: null,
                usrId: null
            }
        },
        components : {
            pagination
        },
        created: function () {
            if(this.$route.query.page) {
                this.changeFilter()
                this.startDate = this.$route.query.startDate|| moment().format("YYYY-MM-DD")
                this.endDate = this.$route.query.endDate ||moment().format("YYYY-MM-DD")
                this.deptNm = this.$route.query.deptName || null
                this.partnerNm = this.$route.query.partnerName || null
                this.usrName = this.$route.query.name ||null
                this.usrId =this.$route.query.usr || null
              
                this.getDeptPartnerResult(this.$route.query.page);
            } else {
                this.getDeptPartnerResult(0);
            }
        },
        methods: {
            getDeptPartnerResult: function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }

                let params = {};

                if (this.filter) {
                    params = {
                        page: page,
                        size: 30,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        deptNm: this.deptNm,
                        partnerNm: this.partnerNm,
                        usrName: this.usrName,
                        usrId: this.usrId
                    }
                } else {
                    params = {
                        page: page,
                        size: 30,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                }

                this.$axios
                    .get('/api/mng/entrance/v1/pass/dept-partner', {params: params})
                    .then((response) => {
                        this.list = response.data.result.list;
                        let pageInfo = response.data.result.pageInfo;
                        this.totalCount = pageInfo.totalCount;

                        this.pagerHandler(
                            pageInfo.number,
                            pageInfo.first,
                            pageInfo.last,
                            pageInfo.totalPages
                        );
                    })
            },
            firstSearch: function () {
                let pageQuery = {
                    page : 0,
                    startDate:this.startDate|| null,
                    endDate:this.endDate || null,
                    deptName:this.deptNm || null,
                    partnerName:this.partnerNm || null,
                    name:this.usrName ||null,
                    usr:this.usrId || null
                }
                this.$router.push({
                    path: this.$route.path,
                    query: pageQuery
                }).catch(()=>{});
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.startDate = moment().format("YYYY-MM-DD");
                this.endDate = moment().format("YYYY-MM-DD");
                this.deptNm = null;
                this.usrName = null;
                this.usrId = null;
            },
            changeStartDate(event) {
                this.startDate = event.target.value;
            },
            changeEndDate(event) {
                this.endDate = event.target.value;
            },
            convertAllow(value) {
                if("Y" == value) {
                    return "가능";
                } else {
                    return "불가";
                }
            },
            confirmDownload: function () {
                  this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {
                let params = {};
                if (this.filter) {
                    params = {
                        page: 0,
                        size: 100000,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        deptNm: this.deptNm,
                        usrName: this.usrName,
                        usrId: this.usrId
                    }
                } else {
                    params = {
                        page: 0,
                        size: 100000,
                        startDate: this.startDate,
                        endDate: this.endDate,
                    }
                }
                this.$axios
                .get('/api/mng/entrance/v1/pass/dept-partner/excel', {params: params, responseType: "blob"})
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type']}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '출입자관리_'+moment().format("YYYYMMDD_HHmmss")+'.xlsx');
                    document.body.appendChild(link);
                    link.click();

                    if(response.status == 200) {
                        let param = {
                          fileName: '출입자관리_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                          fileSize: response.data.size,
                          purpose: paramdata.purpose,
                          purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                        }
                        this.setDownloadHistory(param);
                    }
                })
            },
            getInoutDetail: function(item) {
                this.$router.push({
                    name: 'detail-inout',
                    params: {
                        userId: item.usr_id,
                        visitDate: item.visit_date,
                        name: item.kor_nm
                    }
                });
            }
        }
    }
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
</style>
