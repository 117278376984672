<template>
    <v-app >
        <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="time"
            persistent="persistent"
            width="290px">
            <template v-slot:activator="{ on, attrs }">
                <!-- <v-text-field v-model="time" readonly="readonly" v-bind="attrs" v-on="on"
                style="padding:0;"></v-text-field> -->
                <input
                    type="text"
           
                    v-model="time"
                    label="적용시간을 선택하세요."
                    readonly="readonly"
                    v-bind="attrs"
                    v-on="on"/>
            </template>
            <v-time-picker
                v-model="time"
                color="#006769"
              >

                <v-spacer></v-spacer>
                <v-btn text="text" color="primary" @click="modal = false">
                    취소
                </v-btn>
                <v-btn text="text" color="primary" @click="$refs.dialog.save(time)">
                    확인
                </v-btn>

            </v-time-picker>
        </v-dialog>

    </v-app>
</template>
<script>
    import mixin from '../mixin/mixin.js';

    export default {
        data: () => ({time: null, modal: false}),
        props: ['timeParam'],
        created: function () {
            this.time = this.timeParam;
        },
        watch: {
            time: function () {
                this.$emit('dateVal', this.time);
            },
            timeParam: function () {
                this.time = this.timeParam;
            }
        },
        mixins: [mixin]
    }
</script>
