<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">감염관리</a>
            </li>
            <li>
                <a href="javascript:void(0)">방문력 관리</a>
            </li>
        </div>
        <div class="wapper" style="padding-bottom: 10px">
            <div class="title">
                <h2>방문력 관리</h2>
            </div>
            <div class="visit_history">
                <div class="visit_table">
                    <div class="table_head">
                        <p>{{convertDate(selectTime)}}
                            기준 장소방문력 현황입니다. (총
                            {{totalCount}}건)</p>
                        <div class="btn-right">
                            <input
                                type="checkbox"
                                id="containEndDateYn"
                                v-model="containEndDateYn"
                                class="checkbox_right"
                                value="Y"
                                @click="checkEndDateYn($event)"/>
                            <label for="containEndDateYn">
                                <span>종료포함
                                </span>
                            </label>
                            <button class="btn_search" @click="openSearch()">검색</button>
                            <button class="btn_download" @click="confirmDownload()">다운로드</button>
                            <div class="search">
                                <h3>검색</h3>
                                <div class="search_detail">
                                    <dl class="full_width">
                                        <dt>질병분류코드</dt>
                                        <dd>
                                            <select v-model="searchInfectionCode" @change="changeInfectionCode">
                                            	<option value="" selected>전체</option>
                                            	<option
                                            		v-for="(code, codeIndex) in diseasesCodes"
                                            		:key="codeIndex"
                                            		:value="code.seq">
                                            		{{code.diseaseName}}</option>
                                            </select>
                                        </dd>
                                    </dl>
                                </div>
                                <div class="search_detail">
                                    <dl class="full_width">
                                        <dt>상호명</dt>
                                        <dd><input type="text" v-model="storeName"/></dd>
                                    </dl>
                                    <dl class="full_width">
                                        <dt>분류코드</dt>
                                        <dd><input type="text" v-model="classCodeName"/></dd>
                                    </dl>
                                    <dl class="full_width">
                                        <dt>지역</dt>
                                        <dd><input type="text" v-model="localName"/></dd>
                                    </dl>
                                    <dl class="full_width">
                                        <dt>등록일</dt>
                                        <dd>
                                            <input type='date' v-model='searchStartDate' @change="changeStartDate"/>
                                            ~
                                            <input type='date' v-model='searchEndDate' @change="changeEndDate"/>
                                        </dd>
                                    </dl>
                                    <dl class="full_width">
                                        <dt>등록자</dt>
                                        <dd><input type="text" v-model="searchName"/></dd>
                                    </dl>
                                </div>
                                <div class="search_footer">
                                    <button class="btn_secondary btn_md btn_filer"
                                    :class="{'btn_filered' : filter}"
                                     @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                                    <button class="btn_secondary btn_md"  @click="closeSearch()">취소</button>
                                    <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table responsive scrollAll" style="height: 64vh">
                        <table>
                            <tr class="headerFix">
                                <th>관리</th>
                                <th style="min-width: 200px;">유행감염병</th>
                                <th style="min-width: 100px;">분류</th>
                                <th>지역</th>
                                <th>소재지</th>
                                <th>세부지역</th>
                                <th style="min-width: 300px;">상호명</th>
                                <th>등급</th>
                                <th>노출일</th>
                                <th>노출종료일자</th>
                                <th>적용종료일자(직원 외)</th>
                                <th>적용종료일자(직원)</th>
                                <th>특이사항</th>
                                <th style="min-width: 500px;">적용대상</th>
                                <th>등록자</th>
                                <th>등록일자</th>
                                <th>수정자</th>
                                <th>수정일시</th>
                                <th>관리</th>
                            </tr>
                            <tr class="headerFix_sec">
                                <td>
                                    <button @click="placeHisotyrSaveBtn()">추가</button>
                                </td>
                                <td>
                                    <select style="min-width: 200px;" v-model="saveParam.infectionCode" @change="changeDiseasesCodes">
                                        <option disabled="disabled" value="">질병분류코드를 선택하세요.</option>
                                        <option
                                            v-for="(item, index) in this.diseasesCodes"
                                            :key="index"
                                            :value="item.seq">
                                            {{item.diseaseName}}</option>
                                    </select>
                                </td>
                                <td>
                                    <select style="min-width: 100px;" v-model="saveParam.classCode" @change="changePlaceCodes">
                                        <option disabled="disabled" value="">장소분류코드를 선택하세요.</option>
                                        <option
                                            v-for="(item, index) in this.placeCodes"
                                            :key="index"
                                            :value="item.placeClassCode">{{item.placeClassName}}</option>
                                    </select>
                                </td>
                                <td>
                                    <select @change="getZipcode($event, 'local1')" v-model="saveParam.local1">
                                        <option disabled="disabled" selected="selected" value="">지역을 선택하세요.</option>
                                        <option value="-">-</option>
                                        <option
                                            v-for="(local, index) in zipcode.local1"
                                            :key="index"
                                            :value="local.local1">{{local.local1}}</option>
                                    </select>
                                </td>
                                <td>
                                    <select @change="getZipcode($event, 'local2')" v-model="saveParam.local2">
                                        <option disabled="disabled" selected="selected" value="">지역을 선택하세요.</option>
                                        <option value="-">-</option>
                                        <option
                                            v-for="(local, index) in zipcode.local2"
                                            :key="index"
                                            :value="local.local2">{{local.local2}}</option>
                                    </select>
                                </td>
                                <td>
                                    <select v-model="saveParam.local3">
                                        <option disabled="disabled" selected="selected" value="">지역을 선택하세요.</option>
                                        <option value="-">-</option>
                                        <option
                                            v-for="(local, index) in zipcode.local3"
                                            :key="index"
                                            :value="local.local3">{{local.local3}}</option>
                                    </select>
                                </td>
                                <td>
                                    <input style="min-width: 300px;" type="text" v-model="saveParam.storeName"/>
                                </td>
                                <td>
                                    <select v-model="saveParam.grade">
                                        <option selected="selected" value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                </td>
                                <td>
                                    <div id="date-pick">
                                        <date-picker-multiple
                                            @rangeDates="rangeDateChange($event)"
                                            style="width: 150px; height:40px;"></date-picker-multiple>
                                    </div>
                                </td>
                                <td>
                                    <div id="date-pick">
                                        <date-picker
                                            :dateParam.sync="saveParam.startDate"
                                            @dateVal="startDateFunc($event)"
                                            style="width: 150px; height:40px;"></date-picker>
                                    </div>
                                </td>
                                <td>
                                    <div id="date-pick">
                                        <date-picker
                                            :dateParam.sync="saveParam.endDateEtc"
                                            @dateVal="endDateEtcFunc($event)"
                                            style="width: 150px; height:40px;"></date-picker>
                                    </div>
                                </td>
                                <td>
                                    <div id="date-pick">
                                        <date-picker
                                            :dateParam.sync="saveParam.endDateEmp"
                                            @dateVal="endDateEmpFunc($event)"
                                            style="width: 150px; height:40px;"></date-picker>
                                    </div>
                                </td>
                                <td><input type="text" v-model="saveParam.comment"/></td>
                                <td>
                                    <div
                                        style="display: inline-block;"
                                        v-for="(item, index) in this.answerTypeList.filter(type => {
                                          //응급관리 메뉴를 통해 들어오면 응급실만 등록가능해야 함으로 필터 건다.
                                          if(this.isEmma) {
                                            if(type.answerType === 'ERPER'){
                                              return type;
                                            }
                                          } else {
                                            return type;
                                          }
                                        })"
                                        :key="index">
                                        <input
                                            type="checkbox"
                                            :id="item.answerType"
                                            name="answerType"
                                            class="checkbox_right"
                                            :value="item.answerType"
                                            v-model="saveParam.targetMasterCode"/>
                                        <label
                                            :for="item.answerType"
                                            style="width: unset; margin-right: 15px;line-height: 33px;">
                                            <span>{{item.answerTypeNm}}
                                            </span>
                                        </label>
                                    </div>
                                </td>
                                <td></td>
                                <td style="width: 150px;"></td>
                                <td></td>
                                <td style="width: 140px;"></td>
                                 <td>
                                    <button @click="placeHisotyrSaveBtn()">추가</button>
                                </td>
                            </tr>
                            <tr v-for="(item, index) in this.placeHistoryList" :key="`${item.seq}`">
                                <td v-if="!item.modifyYn">
                                    <button @click="modify(index)">수정</button>
                                </td>
                                <td v-if="!item.modifyYn">{{item.infectionCodeNm}}</td>
                                <td v-if="!item.modifyYn">{{item.classCodeName}}</td>
                                <td v-if="!item.modifyYn">{{item.local1}}</td>
                                <td v-if="!item.modifyYn">{{item.local2}}</td>
                                <td v-if="!item.modifyYn">{{item.local3}}</td>
                                <td v-if="!item.modifyYn">{{item.storeName}}</td>
                                <td v-if="!item.modifyYn">{{item.grade}}</td>
                                <td v-if="!item.modifyYn">{{item.boundaryDate}}</td>
                                <td v-if="!item.modifyYn">{{get_YYYY_MM_DD(item.startDate)}}</td>
                                <td v-if="!item.modifyYn">{{get_YYYY_MM_DD(item.endDateEtc)}}</td>
                                <td v-if="!item.modifyYn">{{get_YYYY_MM_DD(item.endDateEmp)}}</td>
                                <td v-if="!item.modifyYn">{{item.comment}}</td>
                                <td v-if="!item.modifyYn">{{convertTargetMasterCode(item.targetMasterCode)}}</td>
                                <td v-if="!item.modifyYn">{{item.regUserNm}}</td>
                                <td v-if="!item.modifyYn">{{convertDate(item.regDate)}}</td>
                                <td v-if="!item.modifyYn">{{item.updUserNm}}</td>
                                <td v-if="!item.modifyYn">{{convertDate(item.updDate)}}</td>
                                 <td v-if="!item.modifyYn">
                                    <button @click="modify(index)">수정</button>
                                </td>

                                <td v-if="item.modifyYn">
                                    <button @click="placeHisotyrModifyBtn(index)">저장</button>
                                    <button @click="placeHisotyrDeleteBtn(index)">삭제</button>
                                    <button @click="placeHisotyrCancelBtn(index)">취소</button>
                                </td>
                                <td v-if="item.modifyYn">
                                    <select v-model="item.infectionCode" @change="changeDiseasesCodesModify">
                                        <option disabled="disabled" value="">질병분류코드를 선택하세요.</option>
                                        <option
                                            v-for="(code, codeIndex) in diseasesCodes"
                                            :key="codeIndex"
                                            :value="code.seq">
                                            {{code.diseaseName}}</option>
                                    </select>
                                </td>
                                <td v-if="item.modifyYn">
                                    <select v-model="item.classCode" @change="changePlaceCodesModify($event, index)">
                                        <option disabled="disabled" value="">장소분류코드를 선택하세요.</option>
                                        <option
                                            v-for="(place, placeIndex) in placeCodesModify"
                                            :key="placeIndex"
                                            :value="place.placeClassCode">{{place.placeClassName}}</option>
                                    </select>
                                </td>
                                <td v-if="item.modifyYn">{{item.local1}}</td>
                                <td v-if="item.modifyYn">{{item.local2}}</td>
                                <td v-if="item.modifyYn">{{item.local3}}</td>
                                <td v-if="item.modifyYn">
                                    <input type="text" v-model="item.storeName"/>
                                </td>
                                <td v-if="item.modifyYn">
                                    <select v-model="item.grade">
                                        <option selected="selected" value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                </td>
                                <td v-if="item.modifyYn">
                                    <div id="date-pick">
                                        <date-picker-multiple
                                            v-model="item.boundaryDate"
                                            @rangeDates="rangeDateChange($event, index)"
                                            :initData="item.boundaryDateInfo"
                                            style="width: 150px; height:40px;"></date-picker-multiple>
                                    </div>
                                </td>
                                <td v-if="item.modifyYn">
                                    <div id="date-pick">
                                        <date-picker
                                            :dateParam.sync="item.startDate.substr(0,10)"
                                            @dateVal="startDateFunc($event, index)"
                                            style="width: 150px; height:40px;"></date-picker>
                                    </div>
                                </td>
                                <td v-if="item.modifyYn">
                                    <div id="date-pick">
                                        <date-picker
                                            :dateParam.sync="item.endDateEtc.substr(0,10)"
                                            @dateVal="endDateEtcFunc($event, index)"
                                            style="width: 150px; height:40px;"></date-picker>
                                    </div>
                                </td>
                                <td v-if="item.modifyYn">
                                    <div id="date-pick">
                                        <date-picker
                                            :dateParam.sync="item.endDateEmp.substr(0,10)"
                                            @dateVal="endDateEmpFunc($event, index)"
                                            style="width: 150px; height:40px;"></date-picker>
                                    </div>
                                </td>
                                <td v-if="item.modifyYn"><input type="text" v-model="item.comment"/></td>
                                <td v-if="item.modifyYn">
                                    <div
                                        style="display: inline-block;"
                                        v-for="(answerItem, answerIndex) in answerTypeList"
                                        :key="answerIndex">
                                        <input
                                            type="checkbox"
                                            :id="answerItem.answerType + item.seq + '_' + answerIndex"
                                            name="answerType"
                                            class="checkbox_right"
                                            :value="answerItem.answerType"
                                            @change="onTargetListChange($event,index)"
                                            :checked="item.targetMasterCode.split('|').includes(answerItem.answerType)"
                                            :disabled="isEmma ? (answerItem.answerType !== 'ERPER') : false"
                                            />
                                        <label
                                            :for="answerItem.answerType + item.seq + '_' + answerIndex"
                                            style="width: unset; margin-right: 15px; line-height: 33px;">
                                            <span>{{answerItem.answerTypeNm}}
                                            </span>
                                        </label>
                                    </div>
                                </td>
                                <td v-if="item.modifyYn">{{item.regUserNm}}</td>
                                <td style="width: 150px;" v-if="item.modifyYn">{{convertDate(item.regDate)}}</td>
                                <td v-if="item.modifyYn">{{item.updUserNm}}</td>
                                <td style="width: 140px;" v-if="item.modifyYn">{{convertDate(item.updDate)}}</td>
                                <td v-if="item.modifyYn">
                                    <button @click="placeHisotyrModifyBtn(index)">저장</button>
                                    <button @click="placeHisotyrDeleteBtn(index)">삭제</button>
                                    <button @click="placeHisotyrCancelBtn(index)">취소</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                 <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" 
                    :type="containEndDateYn"
                    :infectionCode="searchInfectionCode"
                    :name="storeName"
                    :classCodeName="classCodeName"
                    :placeName="localName"
                    :startDate="searchStartDate"
                    :endDate="searchEndDate"
                    :regUser="searchName"
                 />
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import pagination from '../../components/commonPagination.vue'

    import mixin from '../../mixin/mixin.js';
    import datePickerMultiple from '../../components/datePickerMultiple.vue';
    import datePicker from '../../components/datePicker.vue';
    // import RoadAddress from '../../components/placeHistory/RoadAddress'
    import moment from 'moment';
    import $ from 'jquery';
    import _ from 'lodash';

    export default {
        name: 'PlaceHistory',
        data: () => {
            return {
                selectTime: moment().format("YYYY-MM-DD"),
                totalCount: 0,
                editor: null,
                zipcode: {
                    local1: null,
                    local2: null,
                    local3: null,
                    roadName: null
                },
                diseasesCodes: "", //질병분류코드 리스트
                placeCodes: "", //장소 분류 코드 리스트
                placeCodesModify: "", //장소 분류 코드 리스트 (수정용)
                answerTypeList: [], //응답자 리스트.
                applyDate: null, //적용일자
                saveParam: {
                    infectionCode: '', //유행감염병
                    classCode: '', //분류
                    local1: '', //지역
                    local2: '', //소재지
                    local3: '', //세부지역
                    storeName: null, //상호명
                    grade: 1, //등급
                    boundaryDate: null, //노출일
                    boundaryDateInfo: [], //노출일 상세 정보
                    startDate: moment().format("YYYY-MM-DD"), //노출종료일자
                    endDateEtc: null, //적용종료일자(직원 외)
                    endDateEmp: null, //적용종료일자(직원)
                    comment: null, //특이사항
                    targetMasterCode: [], //적용대상
                    regDate: null,
                    updDate: null
                },
                containEndDateYn: false, //종료포함여부
                placeHistoryList: [], //방문력 조회결과 리스트
                endDateYn: "N",
                filter: false,
                storeName: null, // 상호명
                localName: null, // 지역명
                searchInfectionCode: '', // 질병분류코드조회
                searchStartDate: moment().add(-2, 'M').format("YYYY-MM-DD"), // 등록일자 조회
                searchEndDate: moment().format("YYYY-MM-DD"),
                searchName: null, // 이름,
                classCodeName: null,
                isEmma: false
            }
        },
        mixins: [mixin],
        components: {
            // RoadAddress,
            'date-picker-multiple': datePickerMultiple,
            'date-picker': datePicker,
            pagination
        },
        created: function () {
            this.getZipcode();
            this.getInitData();
        },

        mounted() {
          if(this.$route.name === 'em-place-manage'){
            this.isEmma = true;
          }
           if(this.$route.query.page) {
                this.changeFilter()
                this.containEndDateYn = this.$route.query.type === 'true' ? true:false
                this.searchInfectionCode = this.$route.query.infectionCode
                this.storeName = this.$route.query.name
                this.localName = this.$route.query.placeName
                this.searchStartDate = this.$route.query.startDate
                this.searchEndDate = this.$route.query.endDate
                this.searchName = this.$route.query.regUser
                this.classCodeName = this.$route.query.classCodeName
                this.getPlaceHistoryList(this.$route.query.page);
            }
            else{
                this.getPlaceHistoryList(0);
            }
          
        },
        methods: {
            getZipcode: function (event, key) { //시/군/구 가져오기.
                let param = {};
                if (key != null) {
                    param[key] = event.target.value;
                }
                this
                    .$axios
                    .get('/api/mng/placehistory/v1/zipcode', {params: param})
                    .then((response) => {
                        if (!response.data.success) {
                            this.openAlert("주소 정보를 가져오는 도중 오류가 발생했습니다.");
                        }
                        if (key == null) {
                            this.zipcode.local1 = response.data.result;
                            this.zipcode.local2 = [];
                            this.zipcode.local3 = [];
                            this.saveParam.local2 = '';
                            this.saveParam.local3 = '';
                        } else if (key == 'local1') {
                            if (event.target.value == "세종특별자치시") { //세종시의 경우 local2가 없기 대문에 바로 local3 불러오기.
                                this.zipcode.local2 = [];
                                this.zipcode.local3 = response.data.result;
                                this.saveParam.local2 = '';
                                this.saveParam.local3 = '';
                            } else {
                                this.zipcode.local2 = response.data.result;
                                this.zipcode.local3 = [];
                                this.saveParam.local2 = '';
                                this.saveParam.local3 = '';
                            }
                        } else if (key == 'local2') {
                            this.zipcode.local3 = response.data.result;
                            this.saveParam.local3 = '';
                        } else if (key == 'roadName') {
                            this.saveParam.zipcode.roadName = response.data.result;
                        }
                    })
                    .catch(() => {
                        this.openAlert("주소 정보를 가져오는 도중 오류가 발생했습니다.");
                    });

            },
            getInitData: function () {
                this
                .$axios
                .get('/api/mng/placehistory/v1/initCode')
                .then((response) => {
                    if (!response.data.success) {
                        this.openAlert("초기 데이터를 불러오는중 오류가 발생했습니다.");
                    }
                    this.diseasesCodes = response.data.result.diseasesCodes;
                    this.answerTypeList = response.data.result.answerTypeList;
                    for (let answerType of this.answerTypeList.filter(type => {
                      if(this.isEmma) {
                        if(type.answerType === 'ERPER'){
                          return type;
                        }
                      } else {
                        return type;
                      }
                    })) {
                        this
                            .saveParam
                            .targetMasterCode
                            .push(answerType.answerType);
                    }
                })
                .catch(() => {
                    this.openAlert("초기 데이터를 불러오는중 오류가 발생했습니다.");
                })

            },
            getInitDataPlaceCodes: function (code) {
                let param = {
                    page: 0,
                    size: 100000,
                    code: code,
                    useYn: 'Y'
                };
                this
                .$axios
                .get('/api/mng/placehistory/v1/initCode/placeCode', {params: param})
                .then((response) => {
                    if (!response.data.success) {
                        this.openAlert("분류코드 데이터를 불러오는중 오류가 발생했습니다.");
                    }
                    this.placeCodes = response.data.result.placeCodes;
                })
            },
            getInitDataPlaceCodesModify: function (code) {
                let param = {
                    page: 0,
                    size: 100000,
                    code: code,
                    useYn: 'Y'
                };
                this
                .$axios
                .get('/api/mng/placehistory/v1/initCode/placeCode', {params: param})
                .then((response) => {
                    if (!response.data.success) {
                        this.openAlert("분류코드 데이터를 불러오는중 오류가 발생했습니다.");
                    }
                    this.placeCodesModify = response.data.result.placeCodes;
                })
            },
            getInitDateApplyDate: function (infectionCode, classCode) {
                this
                .$axios
                .get('/api/mng/placehistory/v1/initCode/applyDate', {
                    params: {
                        code: infectionCode,
                        placeClassCode: classCode
                    }
                })
                .then((response) => {
                    if (!response.data.success) {
                        this.openAlert("적용일자 데이터를 불러오는중 오류가 발생했습니다.");
                    }
                    this.applyDate = response.data.result.applyDates;
                })
                .catch(() => {
                    this.openAlert("적용일자 데이터를 불러오는중 오류가 발생했습니다.");

                })
            },
            firstSearch: function () {
                let pageQuery = {
                    page : 0,
                    type : this.containEndDateYn,
                    infectionCode : this.searchInfectionCode,
                    name : this.storeName,
                    placeName : this.localName,
                    startDate : this.searchStartDate,
                    endDate : this.searchEndDate,
                    regUser : this.searchName,
                    classCodeName : this.classCodeName
                }
                this.$router.push({
                    path: this.$route.path,
                    query: pageQuery
                }).catch(()=>{});
            },
            placeHisotyrSaveBtn: function () {
                if (this.checkValidation(this.saveParam)) {
                    return;
                }

                this.openConfirm("저장하시겠습니까?", this.savePlaceHistory);
            },
            checkValidation: function (target) {
                if (target.infectionCode == '') {
                    this.openAlert("유행감염병을 선택하세요");
                    return true;
                }
                //     classCode: '', 분류
                if (target.classCode == '') {
                    this.openAlert("질병분류를 선택하세요");
                    return true;
                }
                //     local1: '', 지역
                if (target.local1 == '') {
                    this.openAlert("지역을 선택하세요");
                    return true;
                }
                //     local2: '', 소재지
                if (target.local1 != '세종특별자치시' && target.local2 == '') {
                    this.openAlert("소재지를 선택하세요");
                    return true;
                }
                //     local3: '', 세부지역
                if (target.local3 == '') {
                    this.openAlert("세부지역을 선택하세요");
                    return true;
                }
                //     storeName: null, 상호명
                if (target.storeName == null) {
                    this.openAlert("상호명을 입력하세요");
                    return true;
                } else {
                    if (target.storeName.trim() == '') {
                        this.openAlert("상호명을 입력하세요");
                        return true;
                    }
                }
                //     boundaryDate: null, 노출일
                if (target.boundaryDate == null || target.boundaryDate == '') {
                    this.openAlert("노출일을 선택하세요");
                    return true;
                }
                //     startDate: null, 노출종료일자
                if (target.startDate == null || target.startDate == '') {
                    this.openAlert("노출종료일자를 선택하세요");
                    return true;
                }
                //     endDateEtc: null, 적용종료일자(직원 외)
                if (target.endDateEtc == null || target.endDateEtc == '') {
                    this.openAlert("직원외 적용종료일자 선택하세요");
                    return true;
                }
                //     endDateEmp: null, 적용종료일자(직원)
                if (target.endDateEmp == null || target.endDateEmp == '') {
                    this.openAlert("직원 적용종료일자 선택하세요");
                    return true;
                }
                //     comment: null, 특이사항 targetMasterCode: null, 적용대상
                if (target.targetMasterCode.length == 0) {
                    this.openAlert("적용대상은 1개 이상 선택해야 합니다.");
                    return true;
                }

                return false;

            },
            savePlaceHistory: function () {
                console.log("start save");
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                this
                    .$axios
                    .post('/api/mng/placehistory/v1/places', this.saveParam)
                    .then((response) => {
                        if (!response.data.success) {
                            this.openAlert("저장중 오류가 발생했습니다.");
                        } else {
                            this.openAlert("저장이 완료되었습니다.");
                        }
                        this.btnLock = false;
                        this.getPlaceHistoryList(0);
                    })
                    .catch(() => {
                        this.openAlert("저장중 오류가 발생했습니다.");
                        this.btnLock = false;
                        this.getPlaceHistoryList(0);
                    });
            },
            rangeDateChange: function ({val, valInfo, lastDate}, index) { //노출일자를 설정시 컴포넌트에서 받은 파라미터를 세팅한다.

                console.log(lastDate);
                if (!lastDate) return;

                let updateTarget = this.saveParam;

                if (index) {
                    updateTarget = this.placeHistoryList[index];
                }

                updateTarget.boundaryDate = val;
                updateTarget.boundaryDateInfo = valInfo;
                updateTarget.startDate = moment(lastDate).format("YYYY-MM-DD");

                if (this.applyDate) {
                    let empApplyDateList = this.applyDate.EMP;
                    let etcApplyDateList = this.applyDate.ETC;
                    let dateStandard = null;
                    for (let dateInfo of empApplyDateList) {
                        if (dateInfo.applyDateGrade == updateTarget.grade) {
                            console.log(JSON.stringify(dateInfo));
                            if(dateInfo.referDateValue == "EXP") { // 노출종료일자 기준
                                dateStandard = new Date(lastDate);

                            } else if(dateInfo.referDateValue == "REG") { // 등록일자 기준
                                if(updateTarget.regDate == null) {
                                    dateStandard = new Date(moment().format("YYYY-MM-DD"));
                                } else {
                                    dateStandard = new Date(moment(updateTarget.regDate).format("YYYY-MM-DD"));
                                }
                            } else if(dateInfo.referDateValue == "UPD") { // 수정일자 기준
                                dateStandard = new Date(moment().format("YYYY-MM-DD"));
                            }
                            if(dateStandard !== null) {
                                dateStandard.setDate(dateStandard.getDate() + (dateInfo.applyDateValue * 1));
                                dateStandard = moment(dateStandard).format('YYYY-MM-DD');
                                updateTarget.endDateEmp = dateStandard;
                            }
                        }
                    }
                    for (let dateInfo of etcApplyDateList) {
                        if (dateInfo.applyDateGrade == updateTarget.grade) {
                            console.log(JSON.stringify(dateInfo));
                            if(dateInfo.referDateValue == "EXP") { // 노출종료일자 기준
                                dateStandard = new Date(lastDate);

                            } else if(dateInfo.referDateValue == "REG") { // 등록일자 기준
                                if(updateTarget.regDate == null) {
                                    dateStandard = new Date(moment().format("YYYY-MM-DD"));
                                } else {
                                    dateStandard = new Date(moment(updateTarget.regDate).format("YYYY-MM-DD"));
                                }
                            } else if(dateInfo.referDateValue == "UPD") { // 수정일자 기준
                                dateStandard = new Date(moment().format("YYYY-MM-DD"));
                            }
                            if(dateStandard !== null) {
                                dateStandard.setDate(dateStandard.getDate() + (dateInfo.applyDateValue * 1));
                                dateStandard = moment(dateStandard).format('YYYY-MM-DD');
                                updateTarget.endDateEtc = dateStandard;
                            }
                        }
                    }
                }
            },
            startDateFunc: function (val, index) { //날짜선텍 콤포넌트에서 선택된 날짜를 세팅한다.
                if(index) {
                    // 기 입력된 데이터 Update 요청 건
                    this.placeHistoryList[index].startDate = val;
                } else {
                    this.saveParam.startDate = val;
                }
            },
            endDateEtcFunc: function (val, index) { //날짜선텍 콤포넌트에서 선택된 날짜를 세팅한다.
                if(index) {
                    // 기 입력된 데이터 Update 요청 건
                    this.placeHistoryList[index].endDateEtc = val;
                } else {
                    this.saveParam.endDateEtc = val;
                }
            },
            endDateEmpFunc: function (val, index) { //날짜선텍 콤포넌트에서 선택된 날짜를 세팅한다.
                if(index) {
                    // 기 입력된 데이터 Update 요청 건
                    this.placeHistoryList[index].endDateEmp = val;
                } else {
                    this.saveParam.endDateEmp = val;
                }
            },
            getPlaceHistoryList: function (page) { //등록된 방문력 리스트를 가져온다.
                if (page < 0 || (this.page.totalPages > 0 && this.page.totalPages <= page)) {
                    return;
                }

                let param = {};

                if (this.filter) {
                    param = {
                        page,
                        size: 30,
                        type: 'P',
                        containEndDateYn: this.containEndDateYn ? 'Y' : 'N',
                        searchInfectionCode: this.searchInfectionCode,
                        storeName: this.storeName,
                        localName: this.localName,
                        searchStartDate: this.searchStartDate,
                        searchEndDate: this.searchEndDate,
                        searchName: this.searchName,
                        classCodeName: this.classCodeName
                    }
                } else {
                    param = {
                        page,
                        size: 30,
                        type: 'P',
                        containEndDateYn: this.containEndDateYn ? 'Y' : 'N',
                        searchStartDate: this.searchStartDate,
                        searchEndDate: this.searchEndDate,
                    }
                }
                if(this.isEmma) {
                  this.$set(param, 'targetMasterCode', 'ERPER')
                }
                console.log(param);
                this
                .$axios
                .get('/api/mng/placehistory/v2/places', {params: param})
                .then((response) => {
                    console.log(response.data);
                    this.placeHistoryList = response.data.result.list;
                    let pageInfo = response.data.result.pageInfo;

                    this.totalCount = pageInfo.totalCount;
                    this.selectTime = new Date().toISOString();

                    for (let i = 0; i < this.placeHistoryList.length; i++) {

                        // 수정모드 진입시 -9 가 되면서 일자가 바뀌는 문제 관련 수정
                        this.placeHistoryList[i].startDate = moment(this.placeHistoryList[i].startDate).format('YYYY-MM-DD');

                        //감염병코드 매핑
                        for (let infection of this.diseasesCodes) {
                            if (this.placeHistoryList[i].infectionCode == infection.seq) {
                                this
                                    .placeHistoryList[i]
                                    .infectionCodeNm = infection.diseaseName;
                            }
                        }

                        //응답자 유형 매핑
                        let targetArray = [];
                        for (let k = 0; k < this.placeHistoryList[i].targetMasterCode.length; k++) {
                            for (let answer of this.answerTypeList) {
                                if (this.placeHistoryList[i].targetMasterCode[k] == answer.answerType) {
                                    targetArray.push(answer);
                                }
                            }
                        }
                        this.$set(this.placeHistoryList[i], 'targetMasterCodeInfo', targetArray);

                        //수정을 위한 값 세팅
                        this.$set(this.placeHistoryList[i], 'modifyYn', false);
                    }

                    this.placeHistoryList = Object.assign([], this.placeHistoryList);

                    //페이지 설정
                    this.pagerHandler(
                        pageInfo.number,
                        pageInfo.first,
                        pageInfo.last,
                        pageInfo.totalPages
                    );
                });
            },
            modify: function (index) { //수정버튼 클릭시 해당 열 수정모드로 전환.
                this.getInitDataPlaceCodesModify(this.placeHistoryList[index].infectionCode);
                this.getInitDateApplyDate(this.placeHistoryList[index].infectionCode, this.placeHistoryList[index].classCode);
                this.$set(this.placeHistoryList[index], 'modifyYn', true);
                this.placeHistoryList = Object.assign({}, this.placeHistoryList);
            },
            placeHisotyrModifyBtn: function (index) {
                if (this.checkValidation(this.placeHistoryList[index])) {
                    return;
                }

                this.openConfirm("저장하시겠습니까?", this.modifyPlaceHistory, index);
            },
            modifyPlaceHistory: function (index) {

                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                const payload = _.cloneDeep(this.placeHistoryList[index]);
                payload.targetMasterCode = payload.targetMasterCode.split('|');
                payload.seq = payload.seq;

                this
                .$axios
                .put('/api/mng/placehistory/v1/places', payload)
                .then((response) => {
                    if (response.data.success) {
                        this.openAlert("수정이 완료되었습니다.");
                    } else {
                        this.openAlert("수정중 오류가 발생했습니다..");
                    }
                    this.btnLock = false;
                    this.getPlaceHistoryList(this.page.number);
                })
                .catch(() => {
                    this.openAlert("수정중 오류가 발생했습니다..");
                    this.btnLock = false;
                })
            },
            placeHisotyrDeleteBtn: function (index) {
                this.openConfirm("삭제하시겠습니까?", this.deletePlaceHistory, index);
            },
            deletePlaceHistory: async function (index) {
                try {
                    if (this.btnLock) return;
                    else this.btnLock = true;
                    const response = await this.$axios.post(
                        "/api/mng/placehistory/v1/places/del",
                        { keyCode: this.placeHistoryList[index].seq }
                        );
                    if(!response.data.success) throw new Error('error');
                    this.btnLock = false;
                    this.getPlaceHistoryList(this.page.number);
                } catch (error) {
                    this.openAlert("삭제중 오류가 발생했습니다..");
                    this.btnLock = false;
                }
            },
            placeHisotyrCancelBtn: function () {
                this.getPlaceHistoryList(this.page.number);
            },
            checkEndDateYn: function (event) {
                this.containEndDateYn = event.target.checked;
                this.getPlaceHistoryList(0);
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.searchInfectionCode = '';
                this.storeName = null;
                this.localName = null;
                this.searchStartDate = moment().add(-2, 'M').format("YYYY-MM-DD");
                this.searchEndDate = moment().format("YYYY-MM-DD");
                this.searchName = null;
            },
            confirmDownload: function () {
                this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {
                console.log(paramdata);

                let param = {};

                if (this.filter) {
                    param = {
                        page: 0,
                        size: 100000,
                        type: 'P',
                        containEndDateYn: this.containEndDateYn ? 'Y' : 'N',
                        storeName: this.storeName
                    }
                } else {
                    param = {
                        page: 0,
                        size: 100000,
                        type: 'P',
                        containEndDateYn: this.containEndDateYn ? 'Y' : 'N',
                    }
                }
                this
                .$axios
                .get('/api/mng/placehistory/v2/places/excel', {params: param, responseType: "blob"})
                .then((response) => {
                    console.log(response);
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type']}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '방문력관리_'+moment().format("YYYYMMDD_HHmmss")+'.xlsx');
                    document.body.appendChild(link);
                    link.click();

                    if(response.status == 200) {
                        let param = {
                          fileName: '방문력관리_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                          fileSize: response.data.size,
                          purpose: paramdata.purpose,
                          purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                        }
                        this.setDownladHistory(param);
                    }
                });
            },
            convertTargetMasterCode: function (code) {
                const codelist = code.split('|');
                const returnValue = [];
                for (let answer of this.answerTypeList) {
                    for (let item of codelist) {
                        if(answer.answerType === item)
                            returnValue.push(answer.answerTypeNm);
                    }
                }
                return returnValue.join(', ');
            },
            onTargetListChange: function (e, index) {
                const updateTarget = this.placeHistoryList[index];
                if (e.target.checked) {
                    // Check
                    const codeList = updateTarget.targetMasterCode.split("|");
                    if (!codeList.includes(e.target.value)) {
                        codeList.push(e.target.value);
                    }
                    updateTarget.targetMasterCode = codeList.join("|");
                } else {
                    // Uncheck
                    const codeList = updateTarget.targetMasterCode.split("|");
                    updateTarget.targetMasterCode = codeList.filter((item) => item !== e.target.value).join("|");
                }
            },
            get_YYYY_MM_DD: (timeInfo) => moment(timeInfo).format('YYYY-MM-DD'),
            changeDiseasesCodes(event) {
                this.saveParam.infectionCode = event.target.value;
            	this.getInitDataPlaceCodes(event.target.value);
            },
            changeDiseasesCodesModify(event) {
            	this.getInitDataPlaceCodesModify(event.target.value);
            },
            changePlaceCodes(event) {
                this.saveParam.classCode = event.target.value;
                this.getInitDateApplyDate(this.saveParam.infectionCode, this.saveParam.classCode);
            },
            changePlaceCodesModify(event, index) {
                this.placeHistoryList[index].classCode = event.target.value;
                this.getInitDateApplyDate(this.placeHistoryList[index].infectionCode, event.target.value);
            },
            changeInfectionCode(event) {
                this.searchInfectionCode = event.target.value;
            },
            changeStartDate(event) {
                this.searchStartDate = event.target.value;
            },
            changeEndDate(event) {
                this.searchEndDate = event.target.value;
            },
        }
    }
</script>
<style scoped="scoped">
    .memo_board {
        position: relative;
        padding: 10px 0;
        width: 70%;
        background: #ffffff;
        height: 400px;
        overflow: hidden;
        display: inline-block;
    }
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
    .search {
        z-index: 9999;
    }
    table th {
        font-size: 15px;
    }
    table td {
        line-height: 18px;
    }
</style>
