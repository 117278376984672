<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">재난문자</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>재난문자</h2>
            </div>
            <h3>재난문자 리스트
                <div class="btn-right">
                    <button class="btn_search" @click="openSearch()">검색</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">
                            <v-app style="clear:both;">

                                <dl class="full_width">
                                    <dt>기간</dt>
                                    <dd>

                                        <!-- <input v-model="startDate" type="date"/> -->
                                        <v-menu
                                            ref="menu"
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            :return-value.sync="startDate"
                                            transition="scale-transition"
                                            offset-y="offset-y"
                                            min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <input
                                                    class="input_cal"
                                                    v-model="startDate"
                                                    label="적용일자를 선택하세요."
                                                    readonly="readonly"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    id="startDate"/>
                                            </template>
                                            <v-date-picker
                                                v-model="startDate"
                                                :weekday-format="getWeekDay"
                                                :month-format="getMonth"
                                                :header-date-format="getHeader"
                                                no-title="no-title"
                                                scrollable="scrollable">
                                                <v-spacer></v-spacer>
                                                <v-btn text="text" color="primary" @click="menu = false">
                                                    취소
                                                </v-btn>
                                                <v-btn text="text" color="primary" @click="$refs.menu.save(startDate)">
                                                    확인
                                                </v-btn>
                                            </v-date-picker>
                                        </v-menu>

                                    </dd>
                                </dl>
                                <dl>
                                    <dt>검색</dt>
                                    <dd>
                                        <select v-model="type">
                                            <option selected="selected" value="L">지역</option>
                                            <option value="M">내용</option>
                                        </select>
                                        <input v-model="searchWord" type="text"/></dd>
                                </dl>
                            </v-app>

                        </div>
                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'noCondition': !noCondition}"
                                @click="condition()">필터해제</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">닫기</button>
                            <button class="btn_primary btn_md" @click="filterSearch()">적용</button>
                        </div>
                    </div>
                </div>
            </h3>
            <div class="table table_line">
                <table>
                    <colgroup>
                        <col width="15%"/>
                        <col width="70%"/>
                        <col width="15%"/>
                    </colgroup>
                    <tr>
                        <th>지역</th>
                        <th>내용</th>
                        <th>발생일</th>
                    </tr>
                    <tr v-for="(item, index) in placeList" :key="index">
                        <td>
                            {{item.locationNm}}
                        </td>
                        <td>
                            {{item.msg}}
                        </td>
                        <td>
                            {{convertDate(item.createDate)}}
                        </td>
                    </tr>
                </table>
            </div>
            <div class="pagination">
                <ul>
                    <li>
                        <a href="javascript:void(0)" @click="viewPlace(page.number - 1)">이전</a>
                    </li>
                    <li
                        v-for="(item, index ) in pageNation"
                        :key="index"
                        class="pageNo"
                        :class="{'active': item == page.number +1}">
                        <a href="javascript:void(0)" @click="viewPlace(item - 1)">{{item}}</a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="viewPlace(page.number + 1)">다음</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import $ from 'jquery'

    const monthList = [
        '1월',
        '2월',
        '3월',
        '4월',
        '5월',
        '6월',
        '7월',
        '8월',
        '9월',
        '10월',
        '11월',
        '12월'
    ];
    const weekDayList = [
        '월',
        '화',
        '수',
        '목',
        '금',
        '토',
        '일'
    ];

    export default {
        data: () => ({
            date: new Date()
                .toISOString()
                .substr(0, 10),
            menu: false,
            menu2: false,
            masters: [],
            items: [],
            file: [],
            masterCode: '',
            placeList: [],
            page: {},
            pageNation: [],
            startDate: null,
            searchWord: null,
            type: "L",
            noCondition: false
        }),
        created: function () {
        },
        mounted: function () {
            this.viewPlace(0);
        },
        methods: {
            pagerHandler: function (number, first, last, totalPages) {
                this.pageNation = [];
                this.page = {};
                let pageOffset = 5;
                let pageObj = {
                    number: number,
                    first: first,
                    last: last,
                    totalPages: totalPages
                }
                this.page = pageObj;
                let start = (Math.floor((number / pageOffset)) * pageOffset);
                for (var k = start; k < start + 5; k++) {
                    if (k == totalPages) {
                        break;
                    } else {
                        this
                            .pageNation
                            .push(k + 1);
                    }
                }

            },
            formatDate(date) {
                if (!date) 
                    return null

                const [year, month, day] = date.split('-')
                return `${year}/${month}/${day}`
            },
            parseDate(date) {
                if (!date) 
                    return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },

            viewPlace: function (page) {
                console.log('page', page)
                this
                    .$axios
                    .get('/api/disaster/disasters', {
                        params: {
                            type: null,
                            searchWord: null,
                            startDate: this.startDate,
                            page: page,
                            size: 30
                        }
                    })
                    .then((response) => {
                        console.log(response.data);
                        this.placeList = response.data.content;
                        let pageInfo = response.data;
                        this.pagerHandler(
                            pageInfo.number,
                            pageInfo.first,
                            pageInfo.last,
                            pageInfo.totalPages
                        );
                    })
            },
            openNewVer: function () {
                $('.fileupload').slideToggle('down');
            },
            closeNewVer: function () {
                $('.fileupload').slideToggle('up');
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            convertDate: function (date) {
                let dateVal = new Date(date);
                let year = dateVal.getFullYear();
                year = year
                    .toString()
                    .substring(2, 4);
                let month = dateVal.getMonth() + 1;

                let day = dateVal.getDate();
                let hour = dateVal.getHours();
                let minute = dateVal.getMinutes();

                let result = year + '.' + month
                    .toString()
                    .padStart(2, "0") + '.' + day
                    .toString()
                    .padStart(2, "0") + ' ' + hour
                    .toString()
                    .padStart(2, "0") + ':' + minute
                    .toString()
                    .padStart(2, "0");

                return result;
            },
            condition: function () {
                if (this.noCondition) {
                    this.type = '';
                    this.searchWord = null;
                    this.startDate = null;
                    this.endDate = null;
                    this.viewPlace(0);
                    this.noCondition = false;
                }
            },
            filterSearch: function () {
                this.noCondition = true;
                this.viewPlace(0);
                this.closeSearch();
            },
            getHeader: function (date) {
                let i = new Date(date).getMonth();
                return new Date(date).getFullYear() + '년 ' + monthList[i];
            },
            getMonth: function (date) {
                let i = new Date(date).getMonth();
                return monthList[i];
            },
            getWeekDay: function (date) {
                let i = new Date(date).getDay(date);
                return weekDayList[i];
            },
            onFileChange(e) {
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length) {
                    return;
                }
                this.file = files[0];
            }
        }

    }
</script>
<style scoped="scoped">

    .input_cal {
        width: 46%;
        height: 36px;
        padding: 0 8px;
        line-height: 36px;
        border: solid 1px #E3EBF6;
        border-radius: 3px;
        box-shadow: none;
        -webkit-appearance: none;
        font-size: 14px;
    }
    div.v-menu__content {
        min-width: auto !important;
        top: 194px !important;
        left: 19px !important;
        transform-origin: left top !important;
        z-index: 8!important;
    }

    .search_footer .btn_filer.noCondition {
        margin-right: auto;
        justify-self: start;
        border: 1px solid #424874;
        color: #424874;
    }
</style>