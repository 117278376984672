<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">기본환경</a>
            </li>
            <li>
                <a href="javascript:void(0)">API 관리</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>API 관리</h2>
                <div class="btn-right">
                    <!-- <button class="btn_search">검색</button> <div class="search"> <h3>검색</h3>
                    <div class="search_detail"> <dl> <dt>API 이름</dt> <dd> <input type="text"/></dd>
                    </dl> </div> <div class="search_footer"> <button class="btn_secondary btn_md
                    btn_filer">필터해제</button> <button class="btn_secondary btn_md">취소</button>
                    <button class="btn_primary btn_md">적용</button> </div> </div> -->
                </div>
            </div>
            <div class="table table_line">
                <table>
                    <tr>
                        <th>API ID</th>
                        <th>API 이름</th>
                        <th>IP</th>
                        <th>METHOD</th>
                        <th>PATH</th>
                        <th>PORT</th>
                        <th>서비스아이디</th>
                        <th>관리</th>
                    </tr>
                    <tr>
                        <td><input type="text" v-model="apiId"/></td>
                        <td><input type="text" v-model="apiDesc"/></td>
                        <td><input type="text" v-model="apiIp"/></td>
                        <td><input type="text" v-model="apiMethod"/></td>
                        <td><input type="text" v-model="apiPath"/></td>
                        <td><input type="text" v-model="apiPort"/></td>
                        <td><input type="text" v-model="serviceId"/></td>
                        <td>
                            <button @click="saveConfirm(index)">추가</button>
                        </td>
                    </tr>
                </table>
                <br/>
                <br/>
                <table>
                    <tr>
                        <th>API ID</th>
                        <th>API 이름</th>
                        <th>IP</th>
                        <th>METHOD</th>
                        <th>PATH</th>
                        <th>PORT</th>
                        <th>서비스아이디</th>
                        <th>관리</th>
                    </tr>
                    <tr v-for="(item, index) in this.apiList" :key="index">
                        <td v-if="!item.modifyYn">{{item.apiId}}</td>
                        <td v-if="!item.modifyYn">{{item.apiDesc}}</td>
                        <td v-if="!item.modifyYn">{{item.apiIp}}</td>
                        <td v-if="!item.modifyYn">{{item.apiMethod}}</td>
                        <td v-if="!item.modifyYn">{{item.apiPath}}</td>
                        <td v-if="!item.modifyYn">{{item.apiPort}}</td>
                        <td v-if="!item.modifyYn">{{item.serviceId}}</td>
                        <td v-if="!item.modifyYn">
                            <button @click="modify(index)">수정</button>
                        </td>

                        <td v-if="item.modifyYn">{{item.apiId}}</td>
                        <td v-if="item.modifyYn"><input type="text" v-model="item.apiDesc"/>
                        </td>
                        <td v-if="item.modifyYn"><input type="text" v-model="item.apiIp"/>
                        </td>
                        <td v-if="item.modifyYn"><input type="text" v-model="item.apiMethod"/>
                        </td>
                        <td v-if="item.modifyYn"><input type="text" v-model="item.apiPath"/>
                        </td>
                        <td v-if="item.modifyYn"><input type="text" v-model="item.apiPort"/>
                        </td>
                        <td v-if="item.modifyYn"><input type="text" v-model="item.serviceId"/>
                        </td>
                        <td v-if="item.modifyYn">
                            <button @click="cancel()">취소</button>
                            <button @click="modifyConfirm(index)">저장</button>
                        </td>

                    </tr>
                </table>
            </div>
            <div class="pagination">
                <ul>
                    <li>
                        <a href="javascript:void(0)" @click="getList(page.number - 1)">이전</a>
                    </li>
                    <li
                        v-for="(item, index ) in pageNation"
                        :key="index"
                        class="pageNo"
                        :class="{'active': item == page.number +1}">
                        <a href="javascript:void(0)" @click="getList(item - 1)">{{item}}</a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="getList(page.number + 1)">다음</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</template>
<script>
    import mixin from '../../mixin/mixin.js';

    export default {
        data: () => {
            return {
                apiList: [],
                apiId: null,
                apiDesc: null,
                apiIp: null,
                apiMethod: null,
                apiPath: null,
                apiPort: null,
                serviceId: null
            }
        },
        mixins: [mixin],
        created: function () {
            this.getList();
        },
        methods: {
            getList: function (page) {

                if (page < 0 || this.page.totalPages < page) {
                    return;
                }

                let params = {};
                if (this.filter) {
                    let obj = {
                        page: page,
                        size: 30,
                        apiDesc: this.classCodeName
                    }
                    params = obj
                } else {
                    let obj = {
                        page: page,
                        size: 30
                    }
                    params = obj
                }

                this
                    .$axios
                    .get('/api/mng/apimange/v1/list', {params: params})
                    .then((response) => {

                        if (response.data.success) {
                            console.log(response.data);

                            for (let api of response.data.result.content) {
                                api.modifyYn = false;
                            }

                            this.apiList = JSON.parse(JSON.stringify(response.data.result.content));

                            let pageInfo = response.data.result;
                            this.pagerHandler(
                                pageInfo.number,
                                pageInfo.first,
                                pageInfo.last,
                                pageInfo.totalPages
                            );

                        }

                    })

            },
            modify: function (index) {
                console.log('modify');
                let item = this.apiList;
                item[index].modifyYn = true;

                this.apiList = JSON.parse(JSON.stringify(item));
            },
            modifyConfirm: function (index) {
                this.openConfirm("수정내용을 저장하시겠습니까?", this.modifySave, index);
            },
            modifySave: function (index) {
                for (let item of this.apiList) {

                    if (item.apiId == null) {
                        this.openAlert("API ID는 필수 값입니다.");
                        return;
                    }
                    if (item.apiIp == null) {
                        this.openAlert("API Ip는 필수 값입니다.");
                        return;
                    }
                    if (item.apiMethod == null) {
                        this.openAlert("API Method는 필수 값입니다.");
                        return;
                    } else {
                        item.apiMethod = item
                            .apiMethod
                            .toUpperCase();
                    }

                    if (item.apiPort != null) {
                        if (!/^[0-9]*$/.test(item.apiPort)) {
                            this.openAlert("포트는 숫자만 가능합니다.");
                            return;
                        }

                    }
                    item.apiId = item
                        .apiId
                        .trim();
                    item.apiDesc = item
                        .apiDesc
                        .trim();
                    item.apiIp = item
                        .apiIp
                        .trim();
                    item.apiMethod = item
                        .apiMethod
                        .trim();
                    item.apiPath = item
                        .apiPath
                        .trim();
                }

                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                this
                    .$axios
                    .put('/api/mng/apimange/v1/modify', this.apiList[index])
                    .then((response) => {
                        console.log(response.data.result);
                        if (response.data.success) {
                            this.openAlertClosed("저장이 완료되었습니다.", this.getList, this.page.number);

                        } else {
                            this.openAlert("저장중 오류가 발생했습니다.");
                        }
                        this.btnLock = false;
                    })
                    .catch(() => {
                        this.openAlert("저장중 오류가 발생했습니다.");
                        this.btnLock = false;
                    })

                },
            cancel: function () {
                this.getList(this.page.number);
            },
            saveConfirm: function () {
                if (this.apiId == null) {
                    this.openAlert("API ID는 필수 값입니다.");
                    return;
                }
                if (this.apiIp == null) {
                    this.openAlert("API Ip는 필수 값입니다.");
                    return;
                }
                if (this.apiMethod == null) {
                    this.openAlert("API Method는 필수 값입니다.");
                    return;
                } else {
                    this.apiMethod = this
                        .apiMethod
                        .toUpperCase();
                }

                if (this.apiPort != null) {
                    if (!/^[0-9]*$/.test(this.apiPort)) {
                        this.openAlert("포트는 숫자만 가능합니다.");
                        return;
                    }

                }

                this.openConfirm("작성한 내용을 추가하시겠습니까?", this.save);

            },
            save: function () {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                this
                    .$axios
                    .post('/api/mng/apimange/v1/add', {
                        apiId: this
                            .apiId
                            .trim(),
                        apiDesc: this
                            .apiDesc
                            .trim(),
                        apiIp: this
                            .apiIp
                            .trim(),
                        apiMethod: this
                            .apiMethod
                            .trim(),
                        apiPath: this
                            .apiPath
                            .trim(),
                        apiPort: this
                            .apiPort
                            .trim(),
                        serviceId: this
                            .serviceId
                            .trim()
                    })
                    .then((response) => {
                        console.log(response.data.result);
                        if (response.data.success) {
                            this.openAlertClosed("저장이 완료되었습니다.", this.getList, this.page.number);
                            this.apiId = null;
                            this.apiDesc = null;
                            this.apiIp = null;
                            this.apiMethod = null;
                            this.apiPath = null;
                            this.apiPort = null;
                            this.serviceId = null;
                        } else {
                            this.openAlert("저장중 오류가 발생했습니다.");
                        }
                        this.btnLock = false;
                    })
                    .catch(() => {
                        this.openAlert("저장중 오류가 발생했습니다.");
                        this.btnLock = false;
                    })

                }

        }
    }
</script>