<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">기본환경</a>
            </li>
            <li>
                <a href="javascript:void(0)">민감정보 동의내역 관리</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>민감정보 동의내역 v{{privacyVer}}
                </h2>
                <div class="btn-right">
                    <button class="btn_primary" v-if="agreeCount == 0 && connCount == 0" @click="edit()">편집</button>
                    <button class="btn_secondary" v-if="agreeCount == 0 && connCount == 0" @click="() => { $modal.show('deleteMsg')}">삭제</button>
                </div>
            </div>
            <div class="details">
                <div class="tui-editor-contents">
                    <h4>한국어 버전 </h4>
                    <blockquote>문진에서 한국어 선택시 보여집니다.</blockquote>                      
                    <div id="content" tabindex="0" class="fullCont" v-html="content">
                        <!-- <div id="viewer" initialValue="content"></div> -->
                    </div>
                </div>
                <div class="tui-editor-contents contents-line">
                    <h4>영문 버전 </h4>
                    <blockquote>문진에서 한국어 이외의 언어를 선택시 보여집니다.</blockquote>                     
                    <div id="contentEng" tabindex="0" class="fullCont" v-html="contentEng">
                        <!-- <div id="viewer" initialValue="content"></div> -->
                    </div>
                </div>
            </div>            
        </div>
        <modal
            name="deleteMsg"
            :width="540"
            :height="190"
            :adaptive="true"
            :clickToClose="false">
            <div id="popup_reg" class="pop pop_md">
                <!-- 새 문진 등록 -->
                <div class="pop_head">
                    <h2>알림</h2>
                    <a class="btn_pop_close" @click="closePop('deleteMsg')">닫기</a>
                </div>
                <div class="pop_body">
                    "정말로 민감정보처리방침v{{privacyVer}}을 삭제하시겠습니까?"
                </div>
                <div class="pop_footer">
                    <button class="btn_secondary" @click="deletePrivacy()">네</button>
                    <button class="btn_secondary" @click="closePop('deleteMsg')">아니오</button>
                </div>
            </div>
        </modal>
        <modal
            name="alertMsg"
            :width="540"
            :height="190"
            :adaptive="true"
            :clickToClose="false">
            <div id="popup_reg" class="pop pop_md">
                <!-- 새 문진 등록 -->
                <div class="pop_head">
                    <h2>알림</h2>
                    <a class="btn_pop_close" @click="closePop('deleteMsg')">닫기</a>
                </div>
                <div class="pop_body">
                    {{ alertMsg }}
                </div>
                <div class="pop_footer">
                    <button class="btn_secondary" @click="goBack()">확인</button>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
    // import '@toast-ui/editor/dist/toastui-editor-viewer.css'; import Viewer from
    // '@toast-ui/editor/dist/toastui-editor-viewer';

    export default {
        created() {
            this.privacyCode = this.$route.query.privacyCode;
            this.getPrivacyInfo();
             if(this.$route.query.ccount) {
                console.log('yes');
                this.connCount = window.atob(this.$route.query.ccount);
            }
            if(this.$route.query.acount) {
                console.log('a');
                this.agreeCount =  window.atob(this.$route.query.acount);
            }
        },
        mounted() {
            // const viewer = new Viewer(     {el: document.querySelector('#viewer'),
            // height: '800px', initialValue: this.content} ); viewer.getHtml();
        },
        data() {
            return {privacyVer: null, content: null,conetntEng:null, privacyCode: null, btnLock: false, alertMsg: '',connCount: 0, agreeCount: 0}
        },
        methods: {

            getPrivacyInfo: function () {
                this
                    .$axios
                    .get('/api/mng/env/v1/privacy', {
                        params: {
                            privacyCode: this.privacyCode
                        }
                    })
                    .then((response) => {
                        console.log(response.data.result);
                        if (response.data.success) {
                            this.privacyVer = response.data.result.privacyVer;
                            this.privacyCode = response.data.result.privacyCode;
                            this.content = response.data.result.privacyContents;
                            this.contentEng = response.data.result.privacyContentsEng;
                        } else {
                            this.openAlert("민감정보를 가져오는 도중 오류가 발생했습니다.");
                        }

                    })
                    .catch(() => {
                        this.openAlert("민감정보를 가져오는 도중 오류가 발생했습니다.");
                    })
                },

            // getPrivacyInfo: function () {     this         .$axios
            // .get('/api/mng/privacy?privacyCode=' + this.privacyCode) .then((response) =>
            // {             console.log(response.data); this.content = response
            // .data                 .result .content[0]                 .privacyContents;
            // this.privacyVer = response                 .data                 .result
            // .content[0]                 .privacyVer;         }); },
            deletePrivacy: function () {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                this
                    .$axios
                    .post('/api/mng/sensitive/del', {
                        privacyCode: this.privacyCode
                    })
                    .then(() => {
                        this.alertMsg = "삭제가 완료되었습니다. \n 개인정보 처리방침 리스트 페이지로 이동합니다.";
                        this
                            .$modal
                            .hide('deleteMsg');
                        this
                            .$modal
                            .show('alertMsg');
                        this.btnLock = false;
                    })
                    .catch((err) => {
                        this.btnLock = false;
                        this.errMsg = err.response.data;
                        this
                            .$modal
                            .show('alertMsg');
                    });
            },
            edit: function () {
                let code = this.$route.query.privacyCode;
                this
                    .$router
                    .push({
                        name: 'sensitive-mng-upd',
                        query: {
                            privacyCode: code
                        }
                    });
            },
            closePop: function (target) {
                this.errMsg = null;
                this
                    .$modal
                    .hide(target);
            },
            goBack: function () {
                this
                    .$router
                    .push({name: 'privacy-list'});
            }
        }
    }
</script>
<style scoped="scoped">
    #popup_reg {
        display: inline-block;
    }
    .details{ 
        padding:20px 0; 
        display:flex;
        }
    .details .tui-editor-contents{
        width:48%; 
        margin:0 2%;
        padding-right: 30px;
        overflow:hidden;
        word-wrap:break-word;         
        }
    .details .contents-line{
    min-height: 600px;
    border-left: 1px solid #dad7d7;
    padding-left: 40px;
    margin-left: -2%;
}    
            
</style>
