<template>
  <div class="contents">
    <div class="loc">
      <li>
        <a href="javscript:void(0)">문진</a>
      </li>
      <li>
        <a href="javscript:void(0)">문진현황</a>
      </li>
    </div>
    <div class="wapper" style="min-height: 700px">
      <div class="title">
        <h2>문진 시행 결과</h2>
        <p>
          {{ $route.query.visitorList.name }}님의 문진결과입니다.
          <!-- {{ "(총 12,000명)" }} -->
        </p>
      </div>
      <div class="tabArea">
        <input v-if="tapType == 'GST'" id="tab1" type="radio" name="tabs" checked=""/>
        <input v-if="tapType == 'PT'" id="tab2" type="radio" name="tabs" checked="" />
        <input v-if="tapType == 'NOK'" id="tab3" type="radio" name="tabs" checked="" />
        <input v-if="tapType == 'WGST'" id="tab4" type="radio" name="tabs" checked="" />
     
        <individualStatusTable v-if="tapType == 'GST'" id="tab_content1" masterId="GST"/>
        <individualStatusTable v-if="tapType == 'PT'"  id="tab_content2" masterId="PT"/>
        <individualStatusTable v-if="tapType == 'NOK'" id="tab_content3" masterId="NOK"/>
        <individualStatusTable v-if="tapType == 'WGST'" id="tab_content4" masterId="WGST"/>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import individualStatusTable from "../../components/individualStatusTable";

export default {
  components: {
    individualStatusTable,

  },
  computed : {
    tapType (){
      return this.$route.query.visitorList.type
    }
  },
  name: "inquiryIndividual",
  methods: {
    get_YYYY_MM_DD: () => moment().format("YYYY.MM.DD"),
  },
  data() {
    return {
      
    }
  },
};
</script>
<style scoped>
#tab1:checked~#tab_content1, #tab2:checked~#tab_content2, #tab3:checked~#tab_content3, #tab4:checked~#tab_content4 {
    display: block;
}
</style>
