<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="">알림서비스</a>
            </li>
            <li>
                <a href="">발송 이력조회</a>
            </li>
        </div>
        <div class="wapper" style="min-height:800px">
            <div class="title">
                <h2>발송 이력조회 ({{totalCount  | comma }}건)</h2>
            </div>
            <h3>
                <div class="btn-right">
                    <button class="btn_search" @click="openSearch()">검색</button>
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">
                            <dl class="full_width">
                                <dt>구분</dt>
                                <dd><select v-model="sendCode" @change="changeSendCode">
                                    <option value='all'>전체</option>
                                    <option value='AL'>알림톡</option>
                                    <option value='AT'>아산톡</option>
                                    <option value='AC'>아산쿱</option>
                                </select></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>결과</dt>
                                <dd><select v-model="useYn" @change="changeUseYn">
                                    <option value='all'>전체</option>
                                    <option value='Y'>성공</option>
                                    <option value='N'>실패</option>
                                </select></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>대상</dt>
                                <dd><input type="text" v-model='sendTarget'/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>IP</dt>
                                <dd><input type="text" v-model='ipAddr'/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>이름(발송자,이름)</dt>
                                <dd><input type="text" v-model='regUser'/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>기간</dt>
                                <dd>
                                    <input type='date' v-model='startDate' @change="changeStartDate"/>
                                    ~
                                    <input type='date' v-model='endDate' @change="changeEndDate"/>
                                </dd>
                            </dl>
                        </div>
                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'btn_filered' : filter}"
                                @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                            <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
                        </div>
                    </div>
                </div>
            </h3>
            <div class="table table_line">
                <table>
                    <tr>
                        <th>일자</th>
                        <th>구분</th>
                        <th>발송시간</th>
                        <th>발송자</th>
                        <th>대상</th>
                        <th>이름</th>
                        <th>IP</th>
                        <th>결과</th>
                    </tr>
                    <tr v-for="(item,index) in this.resultList" :key="index">
                        <td>{{convertDate(item.regDate)}}</td>
                        <td>{{item.sendCode}}</td>
                        <td>{{item.sendTime}}</td>
                        <td>{{item.regUser}}</td>
                        <td>{{convertIdentityNo(item.sendTarget)}}</td>
                        <td>{{item.targetName}}</td>
                        <td>{{item.ipAddr}}</td>
                        <td>{{item.sendResult == 'Y' ? '성공': '-'}}</td>
                    </tr>

                </table>
            </div>
            <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" 
            :startDate="startDate" :endDate="endDate" 
            :type="sendCode" :statusCd="useYn" :target="sendTarget" :addr="ipAddr" :regUser="regUser"
            />
        </div>
    </div>
</template>
<script>
    import pagination from '../../components/commonPagination.vue'
    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';
    import $ from 'jquery'

    export default {
        data: () => {
            return {
                resultList: [],
                startDate: moment().add(-1, 'd').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
                useYn: 'all',
                sendTarget: null,
                regUser: null,
                sendCode: 'all',
                ipAddr: null,
                filter: false,
                totalCount: 0
            }
        },
        mixins: [mixin],
        created: function () {
            if(this.$route.query.page) {
                this.changeFilter() //검색 param을 넣기 위해서 필요
                this.sendCode = this.$route.query.type || null;
                this.useYn = this.$route.query.statusCd || null;
                this.sendTarget = this.$route.query.target || null;
                this.ipAddr = this.$route.query.addr || null;
                this.regUser = this.$route.query.regUser || null;
                this.startDate = this.$route.query.startDate|| moment().add(-1, 'd').format("YYYY-MM-DD");
                this.endDate = this.$route.query.endDate|| moment().format("YYYY-MM-DD");
                this.getList(this.$route.query.page);
            } else {
                this.getList(0);
            }
        },
        components : {
            pagination
        },
        filters: {
          comma(val) {
            if (val !== null)
              return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
        methods: {
            getList: function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }
                let param = {};

                if (this.filter) {
                    param = {
                        page: page,
                        size: 30,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        useYn: this.useYn,
                        sendTarget: this.sendTarget,
                        regUser: this.regUser,
                        sendCode: this.sendCode,
                        ipAddr: this.ipAddr
                    }
                } else {
                    param = {
                        page: page,
                        size: 30,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                }
                this
                .$axios
                .get('/api/mng/service/send-rule/v1/result',{
                    params: param
                })
                .then((response) => {
                    if (response.data.success) {
                        console.log(response.data.result.content);
                        this.resultList = response.data.result.content;

                        let pageInfo = response.data.result;
                        this.totalCount = pageInfo.totalElements;

                        this.pagerHandler(
                            pageInfo.number,
                            pageInfo.first,
                            pageInfo.last,
                            pageInfo.totalPages
                        );
                    } else {
                        this.openAlert("리스트 조회에 실패했습니다.");
                    }
                })
                .catch(() => {
                    this.openAlert("리스트 조회에 실패했습니다.");
                });
            },
            firstSearch: function () {
                let pageQuery = {
                    page : 0,
                    type : this.sendCode || null,
                    statusCd : this.useYn || null,
                    target : this.sendTarget || null,
                    addr : this.ipAddr || null,
                    regUser : this.regUser || null,
                    startDate : this.startDate|| null,
                    endDate : this.endDate|| null
                }
                this.$router.push({
                    path: this.$route.path,
                    query: pageQuery
                }).catch(()=>{});
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.startDate = moment().add(-1, 'd').format("YYYY-MM-DD");
                this.endDate = moment().format("YYYY-MM-DD");
                this.useYn = 'all';
                this.sendTarget = null;
                this.regUser = null;
                this.sendCode = null;
                this.ipAddr = null;
                this.filter = false;
            },
            changeStartDate(event) {
                this.startDate = event.target.value;
            },
            changeEndDate(event) {
                this.endDate = event.target.value;
            },
            confirmDownload: function () {
                this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {
                let param = {};

                if (this.filter) {
                    param = {
                        page: 0,
                        size: 100000,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        useYn: this.useYn,
                        sendTarget: this.sendTarget,
                        regUser: this.regUser
                    }
                } else {
                    param = {
                        page: 0,
                        size: 100000,
                    }
                }
                this
                .$axios
                .get('/api/mng/service/send-rule/v1/result/excel', {
                    params: param,
                    responseType: "blob"
                })
                .then((response) => {
                    console.log(response);
                    const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download','발송이력조회_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx');
                    document.body.appendChild(link);
                    link.click();

                    if(response.status == 200) {
                          let param = {
                              fileName: '발송이력조회_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                              fileSize: response.data.size,
                              purpose: paramdata.purpose,
                              purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                          }
                          this.setDownloadHistory(param);
                    }
                })
            },
            changeUseYn(event) {
                this.useYn = event.target.value;
            },
            changeSendCode(event) {
                this.sendCode = event.target.value;
            }
        }
    }
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
</style>
