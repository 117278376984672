<template>

    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">마스터관리</a>
            </li>
            <li>
                <a href="javascript:void(0)">유행감염병관리</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>유행감염병관리</h2>
            </div>
            <h3>
                <!-- <div class="btn-right"> <button class="btn_search">검색</button> <div
                class="search"> <h3>검색</h3> <div class="search_detail"> <dl class="full_width">
                <dt>이름</dt> <dd><input type="text"/></dd> </dl> <dl class="full_width">
                <dt>휴대전화번호</dt> <dd><input type="text"/></dd> </dl> </div> <div
                class="search_footer"> <button class="btn_secondary btn_md
                btn_filer">필터해제</button> <button class="btn_secondary btn_md">취소</button>
                <button class="btn_primary btn_md">적용</button> </div> </div> </div> -->
            </h3>
            <div class="table table_line">
                <table>
                    <tr>
                        <th>등급</th>
                        <th>감염병 이름</th>                       
                    </tr>
                    <tr v-for="(item,index) in this.epidemicList" :key="index">
                        <td>{{item.diseaseGrade}}</td>
                        <td>{{item.diseaseName}}</td>                       
                    </tr>
                </table>
            </div>
            <div class="pagination">
                <ul>
                    <li>
                        <a href="javascript:void(0)" @click="getList(page.number - 1)">이전</a>
                    </li>
                    <li
                        v-for="(item, index ) in pageNation"
                        :key="index"
                        class="pageNo"
                        :class="{'active': item == page.number +1}">
                        <a href="javascript:void(0)" @click="getList(item - 1)">{{item}}</a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="getList(page.number + 1)">다음</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
    import mixin from '../../mixin/mixin.js';
    export default {
        data: () => {
            return {epidemicList: []}
        },
        created: function () {
            this.getList(0);
        },
        mixins: [mixin],
        methods: {
            getList: function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }
                this
                    .$axios
                    .get('/api/mng/service/master/v1/epidemic', {
                        params: {
                            page: page,
                            size: 30
                        }
                    })
                    .then((response) => {
                        if (response.data.success) {
                            this.epidemicList = response.data.result.content;
                            let pageInfo = response.data.result;
                            this.pagerHandler(
                                pageInfo.number,
                                pageInfo.first,
                                pageInfo.last,
                                pageInfo.totalPages
                            );
                        } else {
                            this.openAlert("감염병 조회중 오류가 발생했습니다.");
                        }
                    })
                    .catch(() => {
                        this.openAlert("감염병 조회중 오류가 발생했습니다.");
                    });
            }
        }
    }
</script>