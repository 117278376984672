<template>
  <section
    class="container"
    style="width: 1045px; height: 750px; overflow-y: scroll"
  >
    <div ref="print">
      <div class="paper_q">
        <div class="page">
          <div class="print_wrap">
            <div class="logo">서울아산병원</div>
            <h2 id="inquiryTitle">{{ $t(inquiryTitle) }}[{{ masterName }}]</h2>
            <section
              class="user_info"
              v-if="
                masterId == 'PT' || masterId == 'HSC' || masterId == 'ADMINER'
              "
            >
              <dl>
                <dt>{{ $t("등록번호") }}</dt>
                <dd></dd>
              </dl>
              <dl>
                <dt>{{ $t("환자명") }}</dt>
                <dd></dd>
              </dl>
              <dl>
                <dt>{{ $t("전화번호") }}</dt>
                <dd style="width: 100px"></dd>
              </dl>
            </section>
            <section class="user_info" v-else-if="masterId == 'EMP' || masterId == 'EMPPRINT'">
              <dl>
                <dt>{{ $t("소속부서") }}</dt>
                <dd></dd>
              </dl>
              <dl>
                <dt>{{ $t("사번(등록번호)") }}</dt>
                <dd></dd>
              </dl>
              <dl>
                <dt>{{ $t("직원명") }}</dt>
                <dd></dd>
              </dl>
              <dl>
                <dt>{{ $t("전화번호") }}</dt>
                <dd style="width: 100px"></dd>
              </dl>
            </section>
            <section class="user_info" v-else-if="masterId == 'NOK'">
              <dl>
                <dt>{{ $t("보호자_이름(관계)") }}</dt>
                <dd></dd>
              </dl>
              <dl>
                <dt>{{ $t("환자명(등록번호)") }}</dt>
                <dd></dd>
              </dl>
              <dl>
                <dt>{{ $t("병동") }}</dt>
                <dd></dd>
              </dl>
              <dl>
                <dt>{{ $t("전화번호") }}</dt>
                <dd style="width: 100px"></dd>
              </dl>
            </section>
            <section class="user_info" v-else>
              <dl>
                <dt>{{ $t("이름") }}</dt>
                <dd></dd>
              </dl>
              <dl>
                <dt>{{ $t("방문처") }}</dt>
                <dd></dd>
              </dl>
              <dl>
                <dt>{{ $t("방문목적") }}</dt>
                <dd></dd>
              </dl>
              <dl>
                <dt>{{ $t("전화번호") }}</dt>
                <dd style="width: 100px"></dd>
              </dl>
            </section>
            <section class="notice">
              {{
                $t(
                  "허위로_작성할_경우_보건관련_법률,_형법_등에_의해_처벌_또는_과태료_처분_대상이_될_수_있으니_정확히_작성하여_주시기_바랍니다"
                )
              }}
            </section>
            <section class="q_detail">
              <div v-for="(item, motherIndex) in inqItems" :key="motherIndex">
                <div
                  class="q_type2"
                  v-if="
                    item.categoryType == 'M' ||
                    item.categoryType == 'I' ||
                    item.categoryType == 'N' ||
                    item.categoryType == 'OV'||
                    item.categoryType == 'HV'
                  "
                >
                  <h3>{{ item.langQuestion }}</h3>
                  <ul>
                    <h4>{{ item.langCategoryDesc }}</h4>
                    <li
                      v-for="(inquiry, index) in item.inquiryList"
                      :key="index"
                    >
                      □ {{ inquiry.langContents }}
                    </li>
                  </ul>
                </div>
                <div
                  class="q_type3"
                  v-if="item.categoryType == 'H'"
                  :key="motherIndex"
                >
                  <h3>{{ $t("방문력") }}</h3>
                  <ul>
                    <h4>{{ item.langCategoryDesc }}</h4>
                    <li>□ {{ $t("해당_있음") }}</li>
                    <li style="margin-left: 50px; width: 200px; page-break-before: always;">
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          width: 528px;
                          margin-left: 22px;
                        "
                      >
                        <div>□ {{ $t("해당_없음") }}</div>
                        <div>)</div>
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <table>
                      <tr>
                        <th>{{ $t("도/시") }}</th>
                        <th>{{ $t("시/군/구") }}</th>
                        <th>{{ $t("읍/면/동") }}</th>
                        <th>{{ $t("상세주소") }}</th>
                        <th>{{ $t("기간") }}</th>
                      </tr>

                      <template v-for="item in placeHistoryList">
                        <tr
                          v-for="(store, storeIndex) in item.storeList"
                          :key="store.historyId"
                        >
                          <td
                            v-if="storeIndex == 0"
                            :rowspan="item.storeList.length"
                          >
                            {{
                              language === "ko"
                                ? item.local1
                                : item.localEn1 === null || item.localEn1 === ""
                                ? item.local1
                                : item.localEn1
                            }}
                          </td>
                          <td style="padding-left: 0">
                            {{
                              language === "ko"
                                ? store.local2
                                : store.localEn2 === null ||
                                  store.localEn2 === ""
                                ? store.local2
                                : store.localEn2
                            }}
                          </td>
                          <td>
                            {{
                              language === "ko"
                                ? store.local3
                                : store.localEn3 === null ||
                                  store.localEn3 === ""
                                ? store.local3
                                : store.localEn3
                            }}
                          </td>
                          <td>
                            <span v-if="newBadgeChk(store)" class="label_new">{{
                              $t("신규")
                            }}</span>
                            <span
                              v-if="store.grade === 1"
                              class="label_important"
                              >{{ $t("중요") }}</span
                            >
                            {{
                              language === "ko"
                                ? store.storeName
                                : store.storeNameEn === null ||
                                  store.storeNameEn === ""
                                ? store.storeName
                                : store.storeNameEn
                            }}
                          </td>
                          <td>{{ store.boundaryDate }}</td>
                        </tr>
                        <!-- tr end -->
                      </template>
                    </table>
                  </ul>
                </div>
                <div
                  class="q_type3"
                  style="page-break-before: always"
                  v-if="item.categoryType == 'P'"
                >
                  <h3>{{ item.langQuestion }}</h3>
                  <ul>
                    <h4 v-html="convertCategoryDesc(item.langCategoryDesc)"></h4>
                    <li>□ {{$t("동의함")}}</li>
                  </ul>
                </div>
                <div
                  class="q_type3"
                  style="page-break-before: always"
                  v-if="item.categoryType == 'T'"
                >
                  <h3>{{ item.langQuestion }}</h3>
                  <ul>
                    <h4>{{ item.langCategoryDesc }}</h4>
                  </ul>
                  <br />
                  <br />
                </div>
              </div>
              <div class="q_footer">
                <div class="info_txt">
                  <p>{{ $t("위_기재_내용이_사실임을_확인합니다") }}</p>
                  <p>
                    {{ $t("작성일") }} : <span
                      v-html="signDate + $t('년_월_일')"
                    ></span>
                  </p>
                </div>
                <div class="sign">
                  <div>
                    <div style="width: 150px; display: inline-block">
                      {{ $t("서_명") }} :
                    </div>
                    (인)
                  </div>
                  <div>
                    <div style="width: 150px; display: inline-block">
                      {{ $t("확인_직원") }} :
                    </div>
                    (인)
                  </div>
                </div>
              </div>
              <section class="page_footer">
                {{ $t("출력일시") }} :
                {{ printDate }}
              </section>
            </section>
          </div>
        </div>
      </div>
    </div>

    <button class="btn_primary" @click="print()">출력</button>
  </section>
</template>
<script>
import moment from "moment";
export default {
  data: () => {
    return {
      inqItems: [],
      detailId: null,
      placeHistoryList: [],
      placeHistoryList2: [],
      signDate: null,
      printDate: null,
      updDate: null,
      containerLang : 'ko',
      inquiryTitle: "코로나19_선별_문진표",
    };
  },
  props: ["items", "masterName", "masterId", "language"],
  created: function () {
    let date = moment().format("YYYY-MM-DD HH:mm:ss");
    this.printDate = date;
    this.updDate = date;
    this.signDate = moment().format("YYYY");
    this.containerLang =  this.$i18n.locale;
    this.setLanguage(this.language);
  },
  beforeDestroy: function() {
    this.setLanguage(this.containerLang);
  },
  mounted: function () {
    for (let place of this.placeHistoryList) {
      console.log(this.$refs[place.local1]);
    }
    if(this.masterId === "ERPER"){ // 문진유형이 "환자(응급용)" 경우 문진표 타이틀이 다름
      this.inquiryTitle = "유행성감염병_선별_문진표"
      if(this.language === "en"){ 
        const titleSize = document.getElementById("inquiryTitle");
        titleSize.style.fontSize = 5.5+"mm";
      }
    } else{
      this.inquiryTitle = "코로나19_선별_문진표"
    }


    this.getInquiryInfo();
  },
  methods: {
    print: function () {
      console.log(this.$refs.print.innerHTML);
      const html = document.querySelector("html");
      console.log(html);
      const printContents = this.$refs.print.innerHTML;
      const printDiv = document.createElement("DIV");
      printDiv.className = "print-div";
      let tempBody = "<body>";
      tempBody += printContents;
      tempBody += "</body>";
      console.log(tempBody);
      document.body.style.display = "none";
      html.appendChild(printDiv);
      printDiv.innerHTML = tempBody;
      window.print();
      document.body.style.display = "block";
      printDiv.style.display = "none";
    },
    getInquiryInfo: function () {
      //해당 타입에 맞는 문진 정보 가져오기.
      console.log("detailId" + this.masterId);
      console.log("language" + this.language);
      this.$axios
        .get("/api/v1/inquiry", {
          params: { answerType: this.masterId, language: this.language },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.resultCd == "304") {
            this.openAlert("등록된 문진데이터가 없습니다.");
            return;
          }
          this.detailId = response.data.result.detailId;
          this.inqItems = response.data.result.categoryList;
          for (let item of this.inqItems) {
            if (item.categoryType == "H") {
              console.log("aa", item.placeList);
              this.placeHistoryList2 = JSON.parse(
                JSON.stringify(item.placeList)
              );
              this.getPlaceHistory(item.placeList);
            }
          }

          if(this.items != null) {
            this.inqItems = this.items;
          }

        });
    },
    newBadgeChk(item) {
        const today = moment(moment().format('YYYYMMDD'));
        const updDate = moment(moment(item.updDate).format('YYYYMMDD'));
        const regDate = moment(moment(item.regDate).format('YYYYMMDD'));
        return (
            (item.updDate && today <= updDate.add(1, 'day')) ||
            (item.regDate && today <= regDate.add(1, 'day'))
        );
    },
    getPlaceHistory: function (place) {
      //해당 타입에 맞는 방문력 정보 가져오기
      console.log("placeList", place);
      let tempList = place;
      let placeList = [];
      let placeInfo = {};
      let storeList = [];
      for (let [index, temp] of tempList.entries()) {
        if (index == 0) {
          let storeInfo = {};
          placeInfo = {
            local1: temp.local1,
            localEn1: temp.localEn1,
            type: temp.type,
          };
          storeInfo = {
            local2: temp.local2,
            localEn2: temp.localEn2,
            local3: temp.local3,
            localEn3: temp.localEn3,
            storeName: temp.storeName,
            storeNameEn: temp.storeNameEn,
            boundaryDate: temp.boundaryDate,
            historyId: temp.historyId,
            regDate: temp.regDate,
            updDate: temp.updDate,
            grade: temp.grade,
            check: false,
          };
          storeList.push(storeInfo);
          if(storeList.length == 1 ){
              placeInfo.storeList = storeList;
              placeList.push(placeInfo);
              storeList = [];
              placeInfo = {};
          }

         
        }

        if (index > 0) {
          if (temp.local1 == tempList[index - 1].local1) {
            let storeInfo = {};
            storeInfo = {
              local2: temp.local2,
              localEn2: temp.localEn2,
              local3: temp.local3,
              localEn3: temp.localEn3,
              storeName: temp.storeName,
              storeNameEn: temp.storeNameEn,
              boundaryDate: temp.boundaryDate,
              historyId: temp.historyId,
               regDate: temp.regDate,
            updDate: temp.updDate,
              grade: temp.grade,
              check: false,
            };
            storeList.push(storeInfo);
            if (index + 1 == tempList.length) {
              placeInfo.storeList = storeList;
              placeList.push(placeInfo);
              storeList = [];
              placeInfo = {};
            }
            continue;
          } else {
            placeInfo.storeList = storeList;

            placeList.push(placeInfo);
            storeList = [];
            placeInfo = {};
            let storeInfo = {};

            placeInfo = {
              local1: temp.local1,
              localEn1: temp.localEn1,
              type: temp.type,
            };
            storeInfo = {
              local2: temp.local2,
              localEn2: temp.localEn2,
              local3: temp.local3,
              localEn3: temp.localEn3,
              storeName: temp.storeName,
              storeNameEn: temp.storeNameEn,
              boundaryDate: temp.boundaryDate,
              historyId: temp.historyId,
               regDate: temp.regDate,
            updDate: temp.updDate,
              grade: temp.grade,
              check: false,
            };
            storeList.push(storeInfo);
            if (index + 1 == tempList.length) {
              placeInfo.storeList = storeList;
              placeList.push(placeInfo);
              storeList = [];
              placeInfo = {};
            }
          }
        }
      }
      this.placeHistoryList = placeList;
      for (let [index, inq] of this.inqItems.entries()) {
        if (inq.categoryType == "H") {
          this.$set(this.inqItems[index], "placeList", placeList);
        }
      }

      if (this.answerType == "EMP") {
        //직원의 경우 동거가족 조사를 위한 방문력 세팅
        for (var k = 0; k < this.famInfo.length; k++) {
          //깊은 복사를 하여 넣는다.
          let temp = JSON.parse(JSON.stringify(this.placeHistoryList));
          this.$set(this.famInfo[k], "famItems", temp);
        }
      }
    },
    convertCategoryDesc: function (value) {
        if(null !== value) {
            return value.replaceAll("\n","<br/>");
        }
    },
    setLanguage: function(language) {
      this.$i18n.locale = language
      document.documentElement.setAttribute('lang', language);
    }
  },
};
</script>
<style scoped="scoped">
.paper_q .page {
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 0;
  width: 210mm;
  background: #fff;
  /*page-break-after: always;*/
}

.label_new {
    margin-right: 5px;
    width: auto !important;
    padding: 0 10px !important;
    font-size: 11px !important;
    background: #00a2ff;
    color: #ffffff;
    border-radius: 5px;
    display: inline-block !important;
}

.label_important {
    margin-right: 5px;
    width: auto !important;
    padding: 0 10px !important;
    font-size: 11px !important;
    background: #f68b1f;
    color: #ffffff;
    border-radius: 5px;
    display: inline-block !important;
}
.paper_q h3{
  height: unset;
  padding-bottom: 10px;
}
</style>
