<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">출입자</a>
            </li>
            <li>
                <a href="javascript:void(0)">예외 출입자</a>
            </li>
        </div>
        <div class="wapper" style="min-height:700px">
            <div class="title">
                <h2>예외 출입자</h2>
                <p class="sub_title">{{convertDateDay(new Date())}} 기준 출입대상 예외 출입자 목록입니다.(총
                    {{totalCount}}명)</p>
            </div>
            <h3>
                <div class="btn-right">
                    <button class="btn_search" @click="openSearch()">검색</button>
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">
                            <dl class="full_width">
                                <dt>예외출입일시</dt>
                                <dd>
                                    <input type='date' v-model='startDate' @change="changeStartDate"/>
                                    ~
                                    <input type='date' v-model='endDate' @change="changeEndDate"/>
                                </dd>
                            </dl>
                            <dl class="full_width">
                                <dt>출입장소</dt>
                                <dd>
                                    <select v-model="placeCode">
                                        <option value="all">전체</option>
                                        <option
                                            v-for="(item,index) in this.placelist"
                                            :key="index"
                                            :value="item.placeId">{{item.placeName}}</option>
                                    </select>
                                </dd>
                            </dl>
                            <dl class="full_width">
                                <dt>발급자</dt>
                                <dd><input type="text" v-model='placeUser'/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>예외출입사유</dt>
                                <dd><input type="text" v-model='passReason'/></dd>
                            </dl>
                        </div>
                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'btn_filered' : filter}"
                                @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                            <button class="btn_primary btn_md" @click="getExceptionList(0)">적용</button>
                        </div>
                    </div>
                </div>
            </h3>
            <div class="table table_line">
                <table>
                    <tr>
                        <th>예외출입일시</th>
                        <th>고유번호</th>
                        <th>출입장소</th>
                        <th>체온</th>
                        <th>예외출입사유</th>
                        <th>발급자

                        </th>
                    </tr>
                    <tr v-for="(item,index) in this.list" :key="index">
                        <td>{{convertDate(item.regDate)}}</td>
                        <td>{{checkUserId(item.userId)}}</td>
                        <td>{{convertPlaceCode(item.placeCode)}}</td>
                        <td>{{getTemperature(item.temperature)}}℃</td>
                        <td>{{item.passReason}}</td>
                        <td>{{item.placeUser}}</td>
                    </tr>
                </table>
            </div>
            <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" 
            :startDate="startDate" :endDate="endDate"/>
        </div>
    </div>
</template>
<script>
    import pagination from '../../components/commonPagination.vue'

    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';
    import $ from 'jquery'

    export default {
        mixins: [mixin],
        data: () => {
            return {list: [], filter: false, totalCount: 0,
                placelist: [],
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
                placeCode: 'all',
                passReason: null,
                placeUser: null
            }
        },
        created: function () {
            this.getPlaceList();
        },
        mounted: function() {
            if(this.$route.query.page) {
                this.getExceptionList(this.$route.query.page);
            } else {
                this.getExceptionList(0);
            }
                        
        },
        components : {
            pagination
        },
        methods: {
            getExceptionList: function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }

                let params = {};

                if (this.filter) {
                    params = {
                        page: page,
                        size: 30,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        placeCode: this.placeCode,
                        passReason: this.passReason,
                        placeUser: this.placeUser
                    }
                } else {
                    params = {
                        page: page,
                        size: 30,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                }
                console.log(JSON.stringify(params));
                this.$axios
                .get('/api/mng/entrance/v1/pass/exceptionlist', {params: params})
                .then((response) => {
                    let result = response.data.result.content;
                    this.list = result;

                    let pageInfo = response.data.result;
                    this.totalCount = pageInfo.totalElements;

                    this.pagerHandler(
                        pageInfo.number,
                        pageInfo.first,
                        pageInfo.last,
                        pageInfo.totalPages
                    );
                })
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.startDate = moment().format("YYYY-MM-DD");
                this.endDate = moment().format("YYYY-MM-DD");
                this.placeCode = 'all';
                this.passReason = null;
                this.placeUser = null;
            },
            confirmDownload: function () {
                  this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
                console.log(param.purpose);
                console.log(param.purpose_etc);
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {
                let params = {};
                if (this.filter) {
                    params = {
                        page: 0,
                        size: 100000,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        placeCode: this.placeCode,
                        passReason: this.passReason,
                        placeUser: this.placeUser
                    }
                } else {
                    params = {
                        page: 0,
                        size: 100000,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                }
                this.$axios
                .get('/api/mng/entrance/v1/pass/exceptionlist/excel', {params: params, responseType: "blob"})
                .then((response) => {
                    console.log(response);
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type']}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '예외출입자관리_'+moment().format("YYYYMMDD_HHmmss")+'.xlsx');
                    document.body.appendChild(link);
                    link.click();

                    if(response.status == 200) {
                        let param = {
                          fileName: '예외출입자관리_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                          fileSize: response.data.size,
                          purpose: paramdata.purpose,
                          purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                        }
                        this.setDownloadHistory(param);
                    }
                })
            },
            changeStartDate(event) {
                this.startDate = event.target.value;
            },
            changeEndDate(event) {
                this.endDate = event.target.value;
            },
            getPlaceList: function () {
                this.$axios
                .get('/api/mng/entrance/v1/pass/placecodelist')
                .then((response) => {
                    console.log(response.data);
                    if (response.data.success) {
                        this.placelist = response.data.result.content
                    }
                })
            },
            convertPlaceCode: function (value) {
                let list = this.placelist;
                for (var i = 0; i < list.length ; i++) {
                    if(list[i].placeId == value)
                        return list[i].placeName;      
                }
            },
            checkUserId: function (value) {
                console.log(value)
                if(value == null || value == 'null' || value == '') {
                     value = "";
                }
                return value
            },
         getTemperature :function(value) {       	          	  
            
             let a = value.substr(0,3);
             
             let output = a.substring(0,2)+"."+a.substring(2);
             return output;

         }
        }
    }
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
</style>
