<template>
    <div id="wrapper">
    <div class="contents">
        <h2>문진표 다운로드</h2>
        <table>
            <colgroup>
                <col width="20%"/>
                <col width="10%"/>
                <col width="20%"/>
                <col width="20%"/>
            </colgroup>
            <tr>
                <th>문진유형</th>
                <th>버젼</th>
                <th>작성일</th>
                <th>다운로드</th>
            </tr>
            <tr v-for="master in masters" :key="master.masterId">
                <td>{{master.masterName}}</td>
                <td v-if="master.currntInfo">v{{master.currntInfo.detailVer}}</td><td v-else></td>
                <td v-if="master.currntInfo">{{convertDate(master.currntInfo.regDate)}}</td><td v-else></td>
                <td>
                    <!-- <button class="btn_primary btn_md" @click="showQr(master.masterId)">QR코드</button> -->
                    <select class="btn_primary btn_md" @change="changePdfDown(master, $event)">
                        <option value=''>PDF</option>
                        <option value='ko'>한국어</option>
                        <option value='en'>영어</option>
                    </select>
                </td>
            </tr>
        </table>
        <input type="hidden" id="copyUrl" ref="copyUrl" value=""/>
        <modal
            name="qrcontainer"
            :width="340"
            :height="340"
            :adaptive="true"
            :clickToClose="true">

            <vue-qrcode :value="qrValue" :width="340"/>

        </modal>

    </div>
</div>
</template>

<script>
    import VueQrcode from 'vue-qrcode'
    import mixin from '../../mixin/mixin.js';
    import preivewPaper from '../../components/previewPaper.vue';

    export default {
        data: () => ({
            masters: [],
            privacy: "",
            sensitive: "",
            privacyList: [],
            sensitiveList: [],
            masterId: null,
            masterIdNm: null,
            errMsg: null,
            btnLock: false,
            qrValue: null
        }),
        mixins: [mixin],
        created: function () {
            this
                .$store
                .commit('initState');
        },
        computed: {},
        watch: {},
        mounted: function () {
            this.getMasterInfo();
            this.getPrivacyList();
        },
        components: {
            'vue-qrcode': VueQrcode
        },
        methods: {
            getMasterInfo: function () {
                this
                .$axios
                .get("/api/inq-list")
                .then((response) => {
                    // console.log("getMasterInfo", response.data);
                    this.masters = response.data.result;
                })
                .catch((err) => {
                    console.log(err);
                });
            },
            getDetailInfo: function (master, language) {

                this.masterIdNm = master.masterName;
                this.masterId = master.masterId;
                // this
                // .$axios
                // .post('/api/inq-detail', {masterId: master.masterId})
                // .then((response) => {
                //     // console.log("getDetailInfo", response.data);
                //     this.detailList = response.data.detailList;
                //     if(this.detailList.length > 0) {
                //         this.preView(this.detailList[0].detailId, language);
                //     }
                // })
                // .catch((err) => {
                //     console.log(err);
                // });
                this.preView(master.currntInfo.detailId, language);
            },
            getPrivacyList: function () {
                this
                    .$axios
                    .get('/api/inquiry/privacy')
                    .then((response) => {
                        // console.log(response.data);
                        let privacy = response.data.result;

                        if (privacy.privacyList.length > 0) {
                            this.privacyList = privacy.privacyList;
                        } else {
                            this.openAlert("등록된 개인정보가 없습니다.");
                        }

                        if (privacy.sensitiveList.length > 0) {
                            this.sensitiveList = privacy.sensitiveList;
                        } else {
                            this.openAlert("등록된 민감정보가 없습니다.");
                        }
                    })
                    .catch(() => {
                        this.openAlert("정보동의서를 가져오는데 오류가 발생했습니다.");
                    });
            },
            copyUrl: function (masterId) {
                let urlInput = this.$refs.copyUrl;
                this
                    .$axios
                    .get('/api/inquiry/get-url', {
                        params: {
                            masterId: masterId
                        }
                    })
                    .then((response) => {
                        if (response.data.success) {
                            // console.log(response.data.result);
                            urlInput.value = response.data.result.url;
                            urlInput.type = 'text';
                            urlInput.select();
                            document.execCommand("Copy");
                            urlInput.type = 'hidden';
                            this.openAlert("URL이 복사 되었습니다.");
                        } else {
                            this.openAlert(response.data.resultMsg);
                        }
                    })
                    .catch(() => {
                        this.openAlert("URL 데이터를 가져오는 도중 오류가 발생했습니다.");
                    });
            },
            showQr: function (masterId) {
                this
                .$axios
                .get('/api/inquiry/get-url', {
                    params: {
                        masterId: masterId
                    }
                })
                .then((response) => {
                    if (response.data.success) {
                        this.qrValue = response.data.result.url;
                        this
                            .$modal
                            .show('qrcontainer');
                    } else {
                        this.openAlert(response.data.resultMsg);
                    }
                })
                .catch(() => {
                    this.openAlert("URL 데이터를 가져오는 도중 오류가 발생했습니다.");
                });
            },
            preView: function (detailId, language) {
                this
                .$axios
                .get('/api/v1/inquiry/detailid', {
                    params: {
                        detailId: detailId,
                        language: language
                    }
                })
                .then((response) => {
                    // console.log(response);
                    this.paper(response.data.result.categoryList, language);
                })
                .catch((err) => {
                    console.log(err);
                });
            },
            openPreviewPopup: function (
                strWindowFeatures,
                windowName,
                popupHost,
                subUrl,
                categoryList,
                language
            ) {
              let items = Object.assign([], categoryList);
              const inquiryType = this.$route.query.masterId;
              const eventListenerFtn = function (e) {
                if (e.origin === popupHost && e.data.method === "needCategoryData") {
                  e.source.postMessage(
                    {
                      method: e.data.method,
                      categoryList: items,
                      inquiryType,
                      language,
                    },
                    popupHost
                  );
                  window.removeEventListener("message", eventListenerFtn);
                }
              };
              window.addEventListener("message", eventListenerFtn);
              window.open(
                `${popupHost}${subUrl}${new Date().getTime()}`,
                windowName,
                strWindowFeatures
              );
            },
            paper: function (categoryList, language) {
              let items = Object.assign([], categoryList);
              this.$modal.show(
                preivewPaper,
                {
                  items: items,
                  masterName: this.masterIdNm,
                  masterId: this.masterId,
                  language: language
                },
                {
                  width: "1080px",
                  height: "800px",
                  adaptive: true,
                  clickToClose: true,
                }
              );
          },
          changePdfDown(master, event) {
              this.getDetailInfo(master, event.target.value);
              event.target.value = "";
          },
        }
    }
</script>
<style scoped="scoped">
    .pop {
        display: inline-block;
        box-shadow: none;
    }
    .contents {
        padding-top: 0px;
        padding-left: 0px;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }
    body{padding:0; margin:0;}
    table{width:100%; border-collapse: collapse; border:0;}
    th{padding:7px 10px 5px 10px; font-size:12px; color:#444444; background: #efefef; text-align: center;}
    td{padding:10px; border:1px solid #efefef; color:#777777; text-align: center;}
    a{padding:6px 10px 4px 10px; border-radius: 3px;border:1px solid #006769; background: #006769; color:#ffffff; text-decoration: none;}
    a:hover{border:1px solid #006769; color:#006769; background: #ffffff;}
    h2{margin:0; padding:10px; font-size:18px; background: #006769;color:#ffffff; }
    h2 a{position:absolute; top:7px; right:10px; font-size:14px; font-weight: normal;}
</style>
