<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">기본환경</a>
            </li>
            <li>
                <a href="javascript:void(0)">장소 관리</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>장소 관리</h2>
                <div class="btn-right">
                    <!-- <button class="btn_search">검색</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">
                            <dl>
                                <dt>API 이름</dt>
                                <dd>
                                    <input type="text"/></dd>
                            </dl>
                        </div>
                        <div class="search_footer">
                            <button class="btn_secondary btn_md btn_filer">필터해제</button>
                            <button class="btn_secondary btn_md">취소</button>
                            <button class="btn_primary btn_md">적용</button>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="table table_line">
                <table>
                    <tr>
                        <th>장소타입</th>
                        <th>장소 아이디</th>
                        <th>장소명</th>
                        <th>장소 설명</th>
                        <th>관리</th>
                    </tr>
                    <tr>
                        <td>
                            <select @change="changeType($event)" v-model="placeType">
                                <option value="KS">키오스크</option>
                                <option value="SG">스피드 게이트</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" v-model="placeId" :disabled="placeType == 'SG'"/>
                        </td>
                        <td><input v-if="placeType=='KS'" type="text" v-model="placeName"/>
                            <select
                                @change="selectPlace($event)"
                                v-if="placeType=='SG'"
                                v-model="placeName">
                                <option value="" disabled="disabled">장치를 선택하세요.</option>
                                <option
                                    v-for="(item, index) of this.bsPlaceList"
                                    :key="index"
                                    :value="item.name">{{item.name}}</option>
                            </select>
                        </td>
                        <td><input type="text" v-model="placeDesc"/></td>
                        <td>
                            <button @click="saveConfirm()">추가</button>
                        </td>
                    </tr>
                </table>
                <br/>
                <br/>
                <table>
                    <tr>
                        <th>장소타입</th>
                        <th>장소 아이디</th>
                        <th>장소명</th>
                        <th>장소 설명</th>
                        <th>관리</th>
                    </tr>
                    <tr v-for="(item,index) of this.placeList" :key="index">
                        <td>{{item.placeType == 'KS' ? '키오스크' : '스피드게이트'}}</td>
                        <td>{{item.placeId}}</td>
                        <td>{{item.placeName}}</td>
                        <td>{{item.placeDesc}}</td>
                        <td>
                            <button @click="deleteConfirm(index)">삭제</button>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="pagination">
                <ul>
                    <li>
                        <a href="javascript:void(0)" @click="getList(page.number - 1)">이전</a>
                    </li>
                    <li
                        v-for="(item, index ) in pageNation"
                        :key="index"
                        class="pageNo"
                        :class="{'active': item == page.number +1}">
                        <a href="javascript:void(0)" @click="getList(item - 1)">{{item}}</a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="getList(page.number + 1)">다음</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</template>
<script>
    import mixin from '../../mixin/mixin.js';

    export default {
        data: () => {
            return {
                placeList: [],
                placeType: "",
                placeId: "",
                placeName: null,
                placeDesc: null,
                bsPlaceList: []
            }
        },
        mixins: [mixin],
        created: function () {
            this.getList(0);
            this.getBiostarPlace();
        },
        methods: {
            getList: function (page) {

                if (page < 0 || this.page.totalPages < page) {
                    return;
                }

                let params = {};
                if (this.filter) {
                    let obj = {
                        page: page,
                        size: 30,
                        placeName: this.placeName
                    }
                    params = obj
                } else {
                    let obj = {
                        page: page,
                        size: 30
                    }
                    params = obj
                }

                this
                    .$axios
                    .get('/api/mng/setting/v1/place/list', {params: params})
                    .then((response) => {

                        if (response.data.success) {
                            console.log(response.data);

                            for (let api of response.data.result.content) {
                                api.modifyYn = false;
                            }

                            this.placeList = JSON.parse(JSON.stringify(response.data.result.content));

                            let pageInfo = response.data.result;
                            this.pagerHandler(
                                pageInfo.number,
                                pageInfo.first,
                                pageInfo.last,
                                pageInfo.totalPages
                            );

                        } else {
                            this.openAlert("장소 정보 조회 중 오류가 발생했습니다.");
                        }

                        this.placeType = 'KS';
                        this.placeId = null;

                    })
                    .catch(() => {
                        this.openAlert("장소 정보 조회 중 오류가 발생했습니다.");
                    });

            },
            getBiostarPlace: function () {
                this
                    .$axios
                    .get('/api/mng/setting/v1/place/bs/list')
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.success) {
                            this.bsPlaceList = response.data.result;
                        } else {
                            this.openAlert("장소 정보 조회중 오류가 발생했습니다.");
                        }
                    })
                    .catch(() => {
                        this.openAlert("장소 정보 조회중 오류가 발생했습니다.");
                    });

            },
            changeType: function (event) {
                console.log(event.target.value);
                if (event.target.value == "SG") {
                    this.getBiostarPlace();
                }
                this.placeName = null;
                this.placeId = null;
                this.deviceId = "";
                this.placeDesc = null;

            },
            selectPlace: function (event) {
                console.log(event.target);
                for (let item of this.bsPlaceList) {
                    if (item.name == event.target.value) {
                        this.placeName = item.name;
                        this.placeId = item.id;
                    }
                }
            },
            deleteConfirm: function (index) {
                this.openConfirm("정말로 삭제하시겠습니까?", this.deleteDevice, index);
            },
            deleteDevice: function (index) {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                let placeId = this
                    .placeList[index]
                    .placeId;

                this
                    .$axios
                    .post('/api/mng/setting/v1/place/del', {placeId: placeId})
                    .then((response) => {
                        if (response.data.success) {
                            this.openAlert("장소를 삭제했습니다.");
                            this.getList(0);
                        } else {
                            this.openAlert("장소 삭제중 오류가 발생했습니다.");
                        }
                        this.btnLock = false;
                    })
                    .catch(() => {
                        this.openAlert("장소 삭제중 오류가 발생했습니다.");
                        this.btnLock = false;
                    })

                },
            cancel: function () {
                this.getList(this.page.number);
            },
            saveConfirm: function () {

                if (this.placeId == null) {
                    this.openAlert("장치 아이디는 필수 입니다.");
                    return;
                }
                if (this.placeId.trim() == '') {
                    this.openAlert("장치 아이디는 필수 입니다.");
                    return;
                }

                this.openConfirm("작성한 내용을 추가하시겠습니까?", this.save);

            },
            save: function () {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                this
                    .$axios
                    .post('/api/mng/setting/v1/place', {
                        placeType: this.placeType,
                        placeId: this.placeId,
                        placeName: this.placeName,
                        placeDesc: this.placeDesc
                    })
                    .then((response) => {
                        console.log(response.data.result);
                        if (response.data.success) {
                            this.openAlertClosed("저장이 완료되었습니다.", this.getList, this.page.number);
                            this.placeType = "KS";
                            this.placeId = null;
                            this.placeName = "";
                            this.placeDesc = null;
                        } else {
                            this.openAlert("저장중 오류가 발생했습니다.");
                        }
                        this.btnLock = false;
                    })
                    .catch(() => {
                        this.openAlert("저장중 오류가 발생했습니다.");
                        this.btnLock = false;
                    });
            }

        }
    }
</script>