<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javscript:void(0)">감사</a>
            </li>
            <li>
                <a href="javscript:void(0)">협력업체 관리로그</a>
            </li>
        </div>
        <div class="wapper" style="min-height:800px">
            <div class="title">
                <h2>협력업체 관리로그</h2>
                <div class="btn-right">
                    <button class="btn_search" @click="openSearch">검색</button>
                    <button class="btn_download" @click="confirmDownload">다운로드</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">
                            <dl class="full_width">
                                <dt>ID</dt>
                                <dd><input type="text" v-model="userId"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>이름(사용자,직원명)</dt>
                                <dd><input type="text" v-model="userName"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>협력업체명</dt>
                                <dd><input type="text" v-model="partnerName"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>휴대전화번호</dt>
                                <dd><input type="text" v-model="empPhone"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>IP</dt>
                                <dd><input type="text" v-model="connectIp"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>작업결과</dt>
                                <dd><select v-model="resultCode" @change="changeResultCode">
                                    <option value='all'>전체</option>
                                    <option value='C'>등록</option>
                                    <option value='U'>수정</option>
                                    <option value='D'>삭제</option>
                                </select></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>작업일시</dt>
                                <dd>
                                    <input type='date' v-model='startDate' @change="changeStartDate"/>
                                    ~
                                    <input type='date' v-model='endDate' @change="changeEndDate"/>
                                </dd>
                            </dl>
                        </div>
                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'btn_filered' : filter}"
                                @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                            <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table table_line">
                <table>
                    <tr>
                        <th>ID</th>
                        <th>사용자</th>
                        <th>협력업체명</th>
                        <th>직원명</th>
                        <th>휴대전화번호</th>
                        <th>작업결과</th>
                        <th>작업내용</th>
                        <th>IP</th>
                        <th>작업일시</th>
                    </tr>
                    <tr v-for="(list, index) in this.list" :key="index">
                        <td>{{list.userId}}</td>
                        <td>{{list.userName}}</td>
                        <td>{{list.partnerName}}</td>
                        <td>{{list.empName}}</td>
                        <td>{{convertPhone(list.empPhone)}}</td>
                        <td>{{convertResultCode(list.resultCode)}}</td>
                        <td>{{list.resultMsg}}</td>
                        <td>{{list.connectIp}}</td>
                        <td>{{convertDateSec(list.regDate)}}</td>
                    </tr>
                </table>
            </div>
            <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" 
            :startDate="startDate" :endDate="endDate" 
            :usr="userId" :name="userName" :partnerName="partnerName"
            :empPhone="empPhone" :deviceIp="connectIp" :statusCd="resultCode"
            />
        </div>
    </div>

</template>
<script>
    import pagination from '../../components/commonPagination.vue'
    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';
    import $ from 'jquery'

    export default {
        mixins: [mixin],
        data: () => {
            return {list: [],
                userId: null,
                userName: null,
                partnerName: null,
                empPhone: null,
                connectIp: null,
                resultCode: 'all',
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
                filter: false
            }
        },
        created: function () {
            if(this.$route.query.page) {
                this.changeFilter()
                this.userId = this.$route.query.usr || null;
                this.userName = this.$route.query.name|| null;
                this.partnerName = this.$route.query.partnerName|| null;
                this.empPhone = this.$route.query.empPhone|| null;
                this.connectIp = this.$route.query.deviceIp|| null;
                this.resultCode = this.$route.query.statusCd|| null;
                this.startDate = this.$route.query.startDate|| moment().format("YYYY-MM-DD");
                this.endDate = this.$route.query.endDate|| moment().format("YYYY-MM-DD");
                this.getPartnerManageLog(this.$route.query.page);
            } else {
                this.getPartnerManageLog(0);
            }
        },
        components : {
            pagination
        },
        methods: {
            getPartnerManageLog: function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }
                let param = {};
                if (this.filter) {
                    param = {
                        page: page,
                        size: 30,
                        userId: this.userId,
                        userName: this.userName,
                        partnerName: this.partnerName,
                        empPhone: this.empPhone,
                        connectIp: this.connectIp,
                        resultCode: this.resultCode,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                } else {
                    param = {
                        page: page,
                        size: 30,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                }
                this
                    .$axios
                    .get('/api/mng/setting/v1/log/partner/list', {params: param})
                    .then((response) => {
                        this.list = response.data.result.content;
                        let pageInfo = response.data.result;
                        this.totalCount = pageInfo.totalElements;
                        this.pagerHandler(
                            pageInfo.number,
                            pageInfo.first,
                            pageInfo.last,
                            pageInfo.totalPages
                        );
                    })
            },
            confirmDownload: function () {
            	this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
            	this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {

                let param = {};
                if (this.filter) {
                    param = {
                        page: 0,
                        size: 100000,
                        userId: this.userId,
                        userName: this.userName,
                        partnerName: this.partnerName,
                        empPhone: this.empPhone,
                        connectIp: this.connectIp,
                        resultCode: this.resultCode,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                } else {
                    param = {
                        page: 0,
                        size: 100000
                    }
                }

                this
                .$axios
                .get('/api/mng/setting/v1/log/partner/list/excel', {params: param, responseType: "blob"})
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type']}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '협력업체관리로그_'+moment().format("YYYYMMDD_HHmmss")+'.xlsx');
                    document.body.appendChild(link);
                    link.click();

                    if(response.status == 200) {
                    	let param = {
                    		fileName: '협력업체관리로그_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                    		fileSize: response.data.size,
                    		purpose: paramdata.purpose,
                    		purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                    	}
                    	this.setDownloadHistory(param);
                        this.getPartnerManageLog(0);
                    }
                })
            },
            firstSearch: function () {
                let pageQuery = {
                    page : 0,
                    usr : this.userId || null,
                    name : this.userName|| null,
                    partnerName : this.partnerName|| null,
                    empPhone : this.empPhone|| null,
                    deviceIp : this.connectIp|| null,
                    statusCd : this.resultCode|| null,
                    startDate : this.startDate|| null,
                    endDate : this.endDate|| null
                }
                this.$router.push({
                    path: this.$route.path,
                    query: pageQuery
                }).catch(()=>{});
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.userId = null;
                this.userName = null;
                this.partnerName = null;
                this.empPhone = null;
                this.connectIp = null;
                this.resultCode = 'all';
                this.startDate = moment().format("YYYY-MM-DD");
                this.endDate = moment().format("YYYY-MM-DD");
            },
            convertResultCode: function (value) {
                if (value != null) {
                    if (value == "C") {
                        return "등록";
                    } else if (value == "U") {
                        return "수정";
                    } else if (value == "D") {
                        return "삭제";
                    }
                }
            },
            changeResultCode(event) {
                this.resultCode = event.target.value;
            },
            changeStartDate(event) {
                this.startDate = event.target.value;
            },
            changeEndDate(event) {
                this.endDate = event.target.value;
            }
        }

    }
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
</style>
