<template>
  <div class="pagination">
    <ul>
      <li class="button">
        <a @click="goToFirstPage">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
            <path fill-rule="evenodd"
              d="M15.79 14.77a.75.75 0 01-1.06.02l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 111.04 1.08L11.832 10l3.938 3.71a.75.75 0 01.02 1.06zm-6 0a.75.75 0 01-1.06.02l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 111.04 1.08L5.832 10l3.938 3.71a.75.75 0 01.02 1.06z"
              clip-rule="evenodd" />
          </svg>

        </a>
      </li>
      <li class="button">
        <a @click="goToPage(page.number - 1)">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
            <path fill-rule="evenodd"
              d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
              clip-rule="evenodd" />
          </svg>
        </a>
      </li>
      <li v-for="item in pageArray" :key="item" :class="{ active: item == page.number + 1 }">
        <a @click="goToPage(item - 1)">{{ item }}</a>
      </li>
      <li class="button">
        <a @click="goToPage(page.number + 1)">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>

        </a>
      </li>
      <li class="button">
        <a @click="goToLastPage">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
            <path fill-rule="evenodd"
              d="M10.21 14.77a.75.75 0 01.02-1.06L14.168 10 10.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
            <path fill-rule="evenodd"
              d="M4.21 14.77a.75.75 0 01.02-1.06L8.168 10 4.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>

        </a>
      </li>
    </ul>
  </div>
</template>
<script>
  export default {
    name: 'commonPagination',
    props: {
      pageArray:Array,
      page:Object,
      startDate:String,
      endDate:String,
      tab:String,               //{출입관리: 환자-보호자(환자-보호자), 환자-보호자(상주보호자), 환자-보호자(중환자실면회객)}
      masterId:String,          //{감염관리: 문진현황(출입증포함), 문진현황(출입증미포함)}
      subMasterId:String,       //{감염관리: 문진현황(출입증미포함)}
      regUser:String,           //{방문신청: 집합행사 관리, 집합행사 관리(참석대상자), 협력업체 관리}, {감염관리: 방문력 관리}, {서비스관리: 개인정보 동의내역 관리, 민감정보 동의내역 관리}, {서비스관리: 권한변경 기록관리}
      usr:String,               //{방문신청: 집합행사 관리(참석대상자)}, {출입관리: 직원-관계사}, {서비스관리: 접속기록 관리, 권한변경 기록관리, 파일다운로드 이력관리, 제어기록 관리, 협력업체 관리로그, 병동방문객 조회로그}
      name:String,              //{방문신청: 방문고객관리, 집합행사 관리(참석대상자)}, {출입관리: 환자-보호자(환자-보호자), 환자-보호자(상주보호자), 직원-관계사}, {감염관리: 방문력 관리}, {서비스관리: 블랙리스트내원알림, 접속기록 관리, 파일다운로드 이력관리, 제어기록 관리, 협력업체 관리로그, 병동방문객 조회로그}
      meetingName:String,       //{방문신청: 집합행사 관리}
      meetingId:String,         //{방문신청: 집합행사 관리(참석대상자)}
      pid:String,               //{방문신청: 협력업체 관리(등록직원), 병동방문객관리}, {서비스관리: 병동방문객 조회로그}
      pname:String,             //{방문신청: 협력업체 관리(등록직원)}, {출입관리: 환자-보호자(환자-보호자(응급실))}, {서비스관리 : 응급실 관계 삭제로그}
      type:String,              //{방문신청: 권한관리}, {감염관리: 방문력 관리}, {서비스관리: 장치관리, 발송 이력조회, 블랙리스트내원알림}
      typeNm:String,
      answerType:String,        //{출입관리: 출입현황, 출입증발급현황}, {서비스관리: 개인정보 동의내역 조회, 민감정보 동의내역 조회}, {응급관리: 체류시간 동의내역 조회}
      answerTypeNm:String,      //{출입관리: 출입증발급현황}
      inqType:String,           //{서비스관리: 개인정보 동의내역 조회, 민감정보 동의내역 조회}
      searchWord:String,        //{방문신청: 권한관리}, {서비스관리: 장치관리}
      phone:String ,            //{방문신청: 방문고객관리, 집합행사 관리(참석대상자)}, {출입관리: 환자-보호자(중환자실면회객), 방문고객}, {감염관리: 문진현황(출입증포함), 문진현황(출입증미포함)}
      purpose:String,           //{방문신청: 방문고객관리}, {출입관리: 환자-보호자(중환자실면회객)}, {서비스관리: 파일다운로드 이력관리}
      place:String,             //{서비스관리: 제어기록 관리}
      placeNo:String,           //{서비스관리: 제어기록 관리}
      placeName:String,         //{출입관리: 출입현황}, {감염관리: 방문력 관리}, {서비스관리: 장치관리, 제어기록 관리}
      meetingPlace:String,      //{방문신청: 집합행사 관리}
      identityNo:String,        //{출입관리: 출입현황, 출입증발급현황, 환자-보호자(환자-보호자), 환자-보호자(중환자실면회객), 예외출입자}, {서비스관리: 발열감지 내역}
      identityName:String,      //{출입관리: 출입현황, 출입증발급현황, 예외출입자},  {서비스관리: 발열감지 내역}
      agreeRegUser:String,      //{출입관리: 예외출입자}
      agreePassReason:String,   //{출입관리: 예외출입자}
      eventTypeCode:String,     //{출입관리: 출입현황}, {서비스관리: 접속기록 관리}
      deviceType:String,        //{출입관리: 출입증발급현황}
      status:String,            //{출입관리: 출입증발급현황}, {서비스관리: 장치관리, 블랙리스트내원알림}
      statusCd:String,          //{방문신청: 집합행사 관리(참석대상자)}, {서비스관리: 발송 이력조회, 협력업체 관리로그}
      deptName:String,          //{출입관리: 직원-관계사}, {서비스관리: 발열감지 내역, 블랙리스트내원알림}
      partnerName:String,       //{방문신청: 협력업체 관리}, {출입관리: 직원-관계사}, {서비스관리: 협력업체 관리로그}
      partnerRegNo:String,      //{방문신청: 협력업체 관리}
      filterpartnerregID:String,//{방문신청: 협력업체 관리}
      empNm:String,             //{방문신청: 협력업체 관리, 협력업체 관리(등록직원)}
      empPhone:String,          //{방문신청: 협력업체 관리, 협력업체 관리(등록직원)}, {서비스관리: 협력업체 관리로그}
      addr:String,              //{방문신청: 협력업체 관리}, {서비스관리: 발송이력조회, 접속기록관리}
      searchYn:String,          //{방문신청: 협력업체 관리(등록직원)}
      patNo:String,             //{감염관리: 문진현황(출입증포함)}, {출입관리: 환자-보호자(환자-보호자(응급실))},, {서비스관리 : 응급실 관계 삭제로그}
      deniedYn:String,          //{감염관리: 문진현황(출입증포함)}
      version:String,           //{감염관리: 문진현황(출입증포함), 문진현황(출입증미포함)}, {서비스관리: 개인정보 동의내역 관리, 개인정보 동의내역 조회, 민감정보 동의내역 관리, 민감정보 동의내역 조회}
      infectionCode:String,     //{감염관리: 방문력 관리}
      classCodeName:String,     //{감염관리: 방문력 관리}
      deviceIp:String,          //{서비스관리: 장치관리, 협력업체 관리로그}
      target:String,            //{서비스관리: 발송이력조회, 파일다운로드 이력관리}
      description:String,       //{서비스관리: 접속기록 관리}
      residenceCode:String,     //{응급관리: 체류시간 동의내역 조회}
      agreeDate:String,         //{응급관리: 체류시간 동의내역 조회}
    },
    computed: {

    },
    data() {
      return {

      }
    },
    methods: {
      goToPage(pageNumber) {
        if(pageNumber > this.page.totalPages-1 || pageNumber <0) return
        else {
          let pageQuery = {
              page:pageNumber,
              startDate:this.$props.startDate,
              endDate:this.$props.endDate,
              tab:this.$props.tab,
              masterId:this.$props.masterId,
              subMasterId:this.$props.subMasterId,
              regUser:this.$props.regUser,
              usr:this.$props.usr,
              name:this.$props.name,
              meetingName:this.$props.meetingName,
              meetingId:this.$props.meetingId,
              pid:this.$props.pid,
              pname:this.$props.pname,
              type:this.$props.type,
              typeNm:this.$props.typeNm,
              answerType: this.$props.answerType,
              answerTypeNm: this.$props.answerTypeNm,
              inqType: this.$props.inqType,
              searchWord:this.$props.searchWord,
              phone:this.$props.phone, 
              purpose:this.$props.purpose,
              place:this.$props.place,
              placeNo:this.$props.placeNo,
              placeName:this.$props.placeName,
              meetingPlace:this.$props.meetingPlace,
              identityNo:this.$props.identityNo,
              identityName:this.$props.identityName,
              agreeRegUser:this.$props.agreeRegUser,
              agreePassReason:this.$props.agreePassReason,
              eventTypeCode:this.$props.eventTypeCode, 
              deviceType:this.$props.deviceType,
              status:this.$props.status,
              statusCd:this.$props.statusCd,
              deptName:this.$props.deptName,
              partnerName:this.$props.partnerName,
              partnerRegNo:this.$props.partnerRegNo,
              filterpartnerregID:this.$props.filterpartnerregID,
              empNm:this.$props.empNm,
              empPhone:this.$props.empPhone,
              addr:this.$props.addr,
              searchYn:this.$props.searchYn,
              patNo:this.$props.patNo,
              deniedYn:this.$props.deniedYn,
              version:this.$props.version,
              infectionCode:this.$props.infectionCode,
              classCodeName:this.$props.classCodeName,
              deviceIp:this.$props.deviceIp,
              target:this.$props.target,
              description:this.$props.description,
              residenceCode: this.$props.residenceCode,
              agreeDate: this.$props.agreeDate,
            }
          // if(Object.keys(this.$route.query).length === 0) {
          //   console.log('쿼리가 있어용 ',this.$route.query)
          //   Object.assign(pageQuery, this.$route.query)
          // }

          this.$router.push({
            path: this.$route.path,
            query: pageQuery
          }).catch(()=>{});
        }
        
      },
      goToFirstPage() {
        if(this.page.number == 0) return
        this.goToPage(0);
      },
      goToLastPage() {
        const lastPage = this.page.totalPages - 1;
        if(this.page.number == lastPage) return
        this.goToPage(lastPage);
      }
    }

  }
</script>
<style scoped> /* 230712 김다슬 페이지네이션 수정 */
  .pagination ul li:first-child a,
  .pagination ul li:last-child a {
    background: unset;
    border: none;
    /* background: url(/img/ico_prev.05d6f490.svg) no-repeat; */
    overflow: unset;
    text-indent: unset;
  }
  .pagination ul li.button a {
    background: unset;
    border: 1px solid #BABBC2;
    border-radius: 4px;
    /* background: url(/img/ico_prev.05d6f490.svg) no-repeat; */
    overflow: unset;
    text-indent: unset;

  }
  .pagination ul li.button a:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  }
  .pagination ul li a{
    width: auto;
    min-width: 24px;
    padding-left:2px;
    padding-right:2px;
  }
  .pagination ul li a svg {
    width: 18px;
    height: 18px;
    vertical-align: sub;
  }
</style>