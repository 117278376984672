<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">출입자</a>
            </li>
            <li>
                <a href="javascript:void(0)">협력업체 관리</a>
            </li>
            <li>
                <a href="javascript:void(0)">협력업체 사원 수정</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>등록직원 수정</h2>
            </div>
            <div class="register">
                <dl>
                    <dt>이름</dt>
                    <dd><input type="text" v-model="empNm"/></dd>
                </dl>
                <dl>
                    <dt>휴대전화번호</dt>
                    <dd><input type="text" v-model="empPhone" readonly/></dd>
                </dl>
            </div>
            <div class="btn_wrap">
                <div class="btn_left">
                    <button class="btn_secondary" @click="goList()">취 소</button>
                    <button class="btn_primary" @click="confirmSave()">저 장</button>
                </div>
                <div class="btn_rihgt">
                    <button class="btn_danger" @click="confirmDelete()">삭제</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import mixin from '../../mixin/mixin.js';
    export default {
        data: () => {
            return {empNm: null, empPhone: null, pid: null, pname: null, empId: null}
        },
        watch: {
            empPhone: function (newVal) {
                let convert = newVal.replaceAll("-", "");

                if (convert.length > 11) {
                    convert = convert.substring(0, 11);
                }

                convert = convert.replace(
                    /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/,
                    '$1-$2-$3'
                );

                this.empPhone = convert
            }
        },
        mixins: [mixin],
        created: function() {
            this.pid = this.$route.query.pid;
            this.pname = this.$route.query.pname;
            this.empId = this.$route.query.empId
            this.getInfo();
        },
        methods: {
            getInfo: function () {
                this
                    .$axios
                    .get('/api/mng/partner/emp/v1/list', {
                        params: {
                            page: 0,
                            size: 30,
                            empId: this.empId
                        }
                    })
                    .then((response) => {
                        console.log(response.data);

                        if (response.data.success) {
                            let result = response.data.result.content[0];
                            this.empNm = result.empNm;
                            this.empPhone = result.empPhone;
                            this.empId = result.empId;
                        } else {
                            this.openAlert("협력업체 사원을 불러오는 도중 오류가 발생하였습니다.");
                        }
                    })
                    .catch(() => {
                        this.openAlert("협력업체 사원을 불러오는 도중 오류가 발생하였습니다.");
                    });
            },

            confirmSave: function () {
                let checkList = [
                    {
                        id: this.empNm,
                        name: '이름'
                    }, {
                        id: this.empPhone,
                        name: '휴대번호'
                    }
                ]

                let empPhone = this.empPhone;
                empPhone = empPhone.replaceAll('-','');

                if (!this.nullChecker(checkList)) {
                    return;
                }
                if (this.empNm.trim().length > 20) {
                    this.openAlert('이름은 20자를 넘을 수 없습니다.');
                    return false;
                }
                if (empPhone.trim().length > 11) {
                    this.openAlert('휴대 전화번호는 11자를 넘을 수 없습니다.');
                    return false;
                }
                 if (empPhone.trim().length < 10) {
                    this.openAlert('관리자 전화번호는 10자보다 작을 수 없습니다..');
                    return false;
                }
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                console.log(this.pid);
                this
                    .$axios
                    .put('/api/mng/partner/emp/v1/list', {
                        partnerCode: this.pid,
                        empNm: this.empNm,
                        empId: this.empId,
                        empPhone: empPhone
                    })
                    .then((response) => {
                        console.log(response.data);

                        if (response.data.success) {

                            this.openAlertClosed('저장이 완료되었습니다.', this.goList);

                        } else {
                            this.openAlert("저장하는 도중 오류가 발생하였습니다.");
                        }
                        this.btnLock = false;
                    })
                    .catch(() => {
                        this.openAlert("저장하는 도중 오류가 발생하였습니다.");
                        this.btnLock = false;
                    });

            },
            confirmDelete: function() {
                this.openConfirm("삭제 하시겠습니까?", this.deleteEmp);
            },
            deleteEmp: function() {
                this
                    .$axios
                    .post('/api/mng/partner/emp/v1/list/del', {
                        partnerCode: this.pid,
                        empId: this.$route.query.empId
                    })
                    .then((response) => {
                        console.log(response.data);

                        if (response.data.success) {

                            if(response.data.result == null) {
                                this.openAlertClosed('삭제가 완료되었습니다.', this.goList);
                            } else {
                                this.openAlert("관리자는 삭제할수 없습니다.");
                            }

                        } else {
                            this.openAlert("삭제가 도중 오류가 발생하였습니다.");
                        }
                        this.btnLock = false;
                    })
                    .catch(() => {
                        this.openAlert("삭제가 도중 오류가 발생하였습니다.");
                        this.btnLock = false;
                    });

            },
            goList: function () {
                this
                .$router
                .go(-1);
                // .push({
                //     name: 'partnerEmpList',
                //     query: {
                //         pid: this.pid,
                //         pname: this.pname
                //     }
                // });
            }
        }
    }
</script>
