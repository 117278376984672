<template>

    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">마스터관리</a>
            </li>
            <li>
                <a href="javascript:void(0)">분류코드관리</a>
            </li>
            <li>
                <a href="javascript:void(0)">분류코드 수정</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>분류코드 수정</h2>
            </div>
            <div class="register">

                <dl>
                    <dt>분류코드</dt>
                    <dd><input type="text" v-model="classCode" readonly/></dd>
                </dl>
                <dl>
                    <dt>분류명</dt>
                    <dd><input type="text" v-model="className"/></dd>
                </dl>
                <dl>
                    <dt>비고</dt>
                    <dd><input type="text" v-model="comment"/></dd>
                </dl>
                <dl>
                    <dt>사용여부</dt>
                    <dd>
                        <select v-model="useYn">
                            <option value="Y">사용</option>
                            <option value="N">미사용</option>
                        </select>
                    </dd>
                </dl>
            </div>
            <div class="btn_wrap">
                <div class="btn_left">
                    <button class="btn_secondary" @click="goPrev()">취 소</button>
                    <button class="btn_primary" @click="confirmSave()">저 장</button>
                </div>
                 <div class="btn_right">
                    <button class="btn_danger" @click="confirmDel()">삭 제</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import mixin from '../../mixin/mixin.js';

    export default {
        data: () => {
            return {code: null, className: null, classCode: null, comment: null, useYn: "Y"}
        },
        mixins: [mixin],
        created: function () {
            this.classCode = window.atob(this.$route.query.classCode);
            this.code = window.atob(this.$route.query.code);

            this.getCodeInfo();
        },
        methods: {
            getCodeInfo: function () {
                this
                .$axios
                .get('/api/mng/service/master/v1/place-class', {
                    params: {
                        code: this.code,
                        classCode: this.classCode
                    }
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data.result.content != null) {
                        let classCodeInfo = response
                            .data
                            .result
                            .content[0];
                        this.classCode = classCodeInfo.classCode;
                        this.className = classCodeInfo.className;
                        this.comment = classCodeInfo.comment;
                        this.useYn = classCodeInfo.useYn == null ? "N" : classCodeInfo.useYn;
                    }
                })
            },
            confirmSave: function () {
                console.log("SAVE")
                if (this.classCode == null) {
                    this.openAlert("분류코드는 필수 항목입니다.");
                    return;

                }
                if (this.classCode.trim() == '') {
                    this.openAlert("분류코드는 필수 항목입니다.");
                    return;
                }
                if (this.classCode.length > 10) {
                    this.openAlert("분류코드는 10자를 넘을 수 없습니다.");
                    return;
                }
                if (this.className == null) {
                    this.openAlert("분류명은 필수 항목입니다.");
                    return;

                }
                if (this.className.trim() == '') {
                    this.openAlert("분류명은 필수 항목입니다.");
                    return;
                }
                if (this.className.length > 20) {
                    this.openAlert("분류명은 20자를 넘을 수 없습니다.");
                    return;
                }
                if (this.comment !== null && this.comment.length > 50) {
                    this.openAlert("비고는 50자를 넘을 수 없습니다.");
                    return;
                }

                this.openConfirm("해당 내용을 저장하시겠습니까?", this.save);

            },
            confirmDel: function () {

                this.openConfirm("분류코드를 삭제하시겠습니까?", this.deleteEvent);

            },
            save: function () {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                this
                .$axios
                .put('/api/mng/service/master/v1/place-class', {
                    code: this.code,
                    className: this.className,
                    classCode: this.classCode,
                    comment: this.comment,
                    useYn: this.useYn

                })
                .then((response) => {
                    if (response.data.success) {
                        this.openAlertClosed("수정하였습니다.", this.goPrev);
                        this.goPrev();
                    } else {
                        this.openAlert("수정중 오류가 발생했습니다.");
                    }
                    this.btnLock = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.openAlert("수정중 오류가 발생했습니다.");
                    this.btnLock = false;
                })
            },

            goPrev: function () {
                this.$router.push({
                    name: 'diseasesCodeList',
                    params: {
                        code: this.code
                    }
                })
            },
            deleteEvent: function () {
                if (this.classCode) {
                    if (this.btnLock) {
                        return;
                    } else {
                        this.btnLock = true;
                    }
                    this
                    .$axios
                    .post('/api/mng/service/master/v1/place-class/del', {code: this.code, classCode: this.classCode})
                    .then((response) => {
                        if (response.data.success) {
                            this.openAlertClosed("삭제가 완료되었습니다.");
                            this.goPrev();
                        } else {
                            this.openAlert("삭제중 오류가 발생했습니다.");
                        }
                        this.btnLock = false;
                    })
                    .catch(() => {
                        this.openAlert("삭제중 오류가 발생했습니다.");
                        this.btnLock = false;
                    })
                }
            }

        }
    }
</script>
