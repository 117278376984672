<template>

    <section>
        <h3>
            <div class="btn-right">
                <button class="btn_primary" @click="addTarget()">신규등록</button>
            </div>
        </h3>
        <div class="table table_line">
            <table>
                <tr>
                    <th>관리자사번</th>
                    <th>일련번호</th>
                    <th>관리받을 사번</th>
                    <th>부서코드</th>
                    <th>유닛코드</th>
                    <th>관리</th>
                </tr>
                <tr v-for="(item,index) in this.targetList" :key="index">
                    <td>{{item.aprcAtprEmpno}}</td>
                    <td>{{item.sn}}</td>
                    <td>{{item.addAprcAtprEmpno}}</td>
                    <td>{{item.blngDeptCd}}</td>
                    <td>{{item.blngUnitCd}}</td>
                    <td>
                        <button class="btn-secondary" @click="delConfirm(item.aprcAtprEmpno,item.sn)">삭제</button>
                    </td>
                </tr>

            </table>
        </div>
        <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0"  />

    </section>

</template>
<script>
    import pagination from './commonPagination.vue'
    import mixin from '../mixin/mixin.js';

    export default {
        data: () => {
            return {targetList: []}
        },
        mixins: [mixin],
        created: function () {
            if(this.$route.query.page) {
                this.getList(this.$route.query.page);
            } else {
            this.getList(0);
            }
        },
        components : {
            pagination
        },
        methods: {
            getList: function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }
                this
                    .$axios
                    .get('/api/mng/service/empinqmng/v1/emp-notice-target', {
                        params: {
                            size: 30,
                            number: page
                        }
                    })
                    .then((response) => {
                        if (response.data.success) {

                            console.log(response.data.result.content);
                            this.targetList = response.data.result.content;
                            let pageInfo = response.data.result;
                            this.pagerHandler(
                                pageInfo.number,
                                pageInfo.first,
                                pageInfo.last,
                                pageInfo.totalPages
                            );

                        } else {
                            this.openAlert("리스르틀 불러오는 도중 오류가 발생했습니다.");
                        }
                    })
                    .catch(() => {
                        this.openAlert("리스르틀 불러오는 도중 오류가 발생했습니다.");
                    });
            },
            addTarget: function () {
                this
                    .$router
                    .push({name: 'empInfManageAdd'})
            },
            delConfirm: function (aprcAtprEmpno, sn) {
                let param = {
                    aprcAtprEmpno: aprcAtprEmpno,
                    sn: sn
                }
                this.openConfirm("삭제하시겠습니까?", this.delReceiver, param);

            },
            delReceiver: function (param) {
                console.log(param.aprcAtprEmpno);
                console.log(param.sn);

                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                this
                    .$axios
                    .post('/api/mng/service/empinqmng/v1/emp-notice-target/del', {
                        aprcAtprEmpno: param.aprcAtprEmpno,
                        sn: param.sn
                    })
                    .then((response) => {
                        if (response.data.success) {
                            console.log(response.data.result);
                            this.openAlertClosed("삭제가 완료되었습니다.", this.getList);
                        } else {
                            this.openAlert(response.data.resultMsg);
                        }
                        this.btnLock = false;
                    })
                    .catch(() => {

                        this.openAlert('삭제중 오류가 발생했습니다.');

                        this.btnLock = false;
                    });
            }
        }
    }
</script>
