<template>
  <div class="contents">
    <div class="loc">
      <li>
        <a href="javscript:void(0)">문진</a>
      </li>
      <li>
        <a href="javscript:void(0)">문진현황</a>
      </li>
    </div>
    <div class="wapper" style="min-height: 700px">
      <div class="title">
        <h2>문진현황</h2>
        <p>
          {{ get_YYYY_MM_DD() }} 기준 출입대상 문진결과입니다.
          <!-- {{ "(총 12,000명)" }} -->
        </p>
      </div>
      <div class="tabArea">
        <input id="tab1" type="radio" :checked="masterId == 'GST'" @click="tabChange('GST')" />
        <label for="tab1">방문객</label>
        <input id="tab2" type="radio" :checked="masterId == 'PEMP'" @click="tabChange('PEMP')" />
        <label for="tab2">협력업체</label>
        <input id="tab3" type="radio" :checked="masterId == 'NOK'" @click="tabChange('NOK')" />
        <label for="tab3">일반보호자</label>
        <input id="tab4" type="radio" :checked="masterId == 'ETC'" @click="tabChange('ETC')" />
        <label for="tab4">일반문진표</label>
        <input id="tab5" type="radio" :checked="masterId == 'ICUGST'" @click="tabChange('ICUGST')" />
        <label for="tab5">중환자실 면회객</label>
        <input id="tab6" type="radio" :checked="masterId == 'WGST'" @click="tabChange('WGST')" />
        <label for="tab6">병동부 방문객</label>
        <inquiryStatusTable id="tab_content1" v-if="masterId =='GST'" masterId="GST"/>
        <inquiryStatusTable id="tab_content2" v-if="masterId =='PEMP'" masterId="PEMP"/>
        <inquiryStatusTable id="tab_content3" v-if="masterId =='NOK'" masterId="NOK"/>
        <inquiryStatusTableNoPass id="tab_content4" v-if="masterId =='ETC'" mainMasterId="ETC"/>
        <inquiryStatusTable id="tab_content5" v-if="masterId =='ICUGST'" masterId="ICUGST"/>
        <inquiryStatusTable id="tab_content6" v-if="masterId =='WGST'" masterId="WGST"/>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import inquiryStatusTableNoPass from "../../components/inquiryStatusTableNoPass";
import inquiryStatusTable from "../../components/inquiryStatusTable";

export default {
  data: () => {
    return {
      masterId: 'GST'
    }
  },
  created: function () {
    if (this.$route.query.masterId) {
      this.masterId = this.$route.query.masterId || 'GST'
    }
  },
  components: {
    inquiryStatusTable,
    inquiryStatusTableNoPass
  },
  name: "inquiryStatus",
  methods: {
    get_YYYY_MM_DD: () => moment().format("YYYY.MM.DD"),

    tabChange(masterId) {
      this.$router.push({
          name: this.$route.name,
          query: {
            masterId: masterId
          }
      }).catch(()=>{});
    },
  },
};
</script>

