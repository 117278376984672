<template>

    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">문진</a>
            </li>
            <li>
                <a href="javascript:void(0)">긴급방문력조사</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>긴급방문력조사</h2>
            </div>
            <h3>
                <div class="btn-right">
                    <button class="btn_primary" @click="addEmInq()">새 설문 등록</button>
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                </div>
            </h3>
            <div class="table table_line">
                <table>
                    <colgroup>
                        <col width="20%"/>
                        <col width="20%"/>
                        <col width="10%"/>
                        <col width="15%"/>
                        <col />
                        <col />
                        <col width="10%"/>
                    </colgroup>
                    <tr>
                        <th>발송일자</th>
                        <th>작성일시</th>
                        <th>상태</th>
                        <th>문항수</th>
                        <th>대상자</th>
                        <th>응답</th>
                        <th>관리</th>
                    </tr>
                    <tr v-for="(item, index) in emList" :key="index">
                        <td>{{item.title}}</td>
                        <td>{{convertDateSec(item.updDate)}}</td>
                        <td>{{item.state == 'F' ? '발송완료' :  '임시저장'}}</td>
                        <td>{{item.inqCnt}}</td>
                        <td>{{item.targetCnt | comma}}</td>
                        <td>{{item.answerCnt}}</td>
                        <td>
                            <button v-if="item.state == 'F'" @click="goDetail(item)">응답보기</button>
                            <button  v-if="item.state == 'T'" @click="edit(item)">문항편집</button>
                        </td>
                    </tr>
                </table>
            </div>
         <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" />
        </div>
    </div>

</template>
<script>
    import pagination from '../../components/commonPagination.vue'
    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';

    export default {
        data: () => {
            return {emList: []}
        },
        created: function () {
            if(this.$route.query.page) {
                this.getList(this.$route.query.page);
            }
            else{
                this.getList(0);
            }
        },
        components : {
            pagination
        },
        mixins: [mixin],
        filters: {
            comma (val) {
                if(val !== null)
                return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
        },
        methods: {
            getList: function (page) {
                this
                    .$axios
                    .get('/api/mng/inquiry/v1/emergency', {
                        params: {
                            page: page,
                            size: 30
                        }
                    })
                    .then((response) => {

                        console.log(response.data.result);
                        if (response.data.success) {
                            this.emList = response.data.result.content;
                            let pageInfo = response.data.result;

                            this.pagerHandler(
                                pageInfo.number,
                                pageInfo.first,
                                pageInfo.last,
                                pageInfo.totalPages
                            );
                        } else {
                            this.openAlert("긴급문진 리스트를 불러오는 도중 오류가 발생하였습니다.");
                        }
                    })
                    .catch(() => {
                        this.openAlert("긴급문진 리스트를 불러오는 도중 오류가 발생하였습니다.");
                    });

            },
            addEmInq: function () {
                this
                    .$router
                    .push({name: 'inquiry-emergency-item'})
            },
            edit: function(item) {
                this.$router.push({
                    name: 'inquiry-emergency-item',
                    params: {
                        eid: item.emInqId,
                        did: item.detailId,
                        startDate: moment(item.startDate).format("YYYY-MM-DD"),
                        startTime: moment(item.startDate).format("HH:mm"),
                        endDate: moment(item.endDate).format("YYYY-MM-DD"),
                        endTime: moment(item.endDate).format("HH:mm"),
                        privacyCode: item.privacyCode,
                        sensitiveCode: item.sensitiveCode
                    }
                })
            },
            goDetail: function(content) {
                this.$router.push({
                    name: 'inquiry-emergency-result',
                    params: { content }
                })
            },
            confirmDownload: function () {
                this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {
                this
                .$axios
                .get('/api/mng/inquiry/v1/emergency/excel', {
                    params: {
                        page: 0,
                        size: 1000000
                    }, responseType: "blob"
                })
                .then((response) => {
                    console.log(response);
                    const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download','긴급방문력조사_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx');
                    document.body.appendChild(link);
                    link.click();

                    if(response.status == 200) {
                          let param = {
                              fileName: '긴급방문력조사_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                              fileSize: response.data.size,
                              purpose: paramdata.purpose,
                              purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                          }
                          this.setDownloadHistory(param);
                    }
                })
            },
        }
    }
</script>
