<template>
  <v-app>
    <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="dates"
      persistent="persistent"
      width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <input
          class="input_cal"
          v-model="dateMultiText"
          label="적용일자를 선택하세요."
          readonly="readonly"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="dates"
        multiple
        scrollable="scrollable"
        no-title="no-title"
        :weekday-format="getWeekDay"
        :month-format="getMonth"
        :header-date-format="getHeader"
        prev-icon="mdi-skip-previous"
        next-icon="mdi-skip-next"
      >
        <v-spacer></v-spacer>
        <v-btn text="text" color="primary" @click="dates = []"> 초기화 </v-btn>
        <v-btn text="text" color="primary" @click="cancle"> 취소 </v-btn>
        <v-btn text="text" color="primary" @click="$refs.dialog.save(dates)">
          확인
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </v-app>
</template>

<script>
import mixin from "../mixin/mixin.js";
import moment from "moment";

export default {
  data: () => ({
    dates: [],
    modal: false,
  }),
  props: {
    initData: {
      type: Array,
    },
  },
  computed: {
    dateMultiText: function () {
      const dateList = this.rangeDateList(this.sortedDates);
      let lastDate = "";
      const momentFormat = "M/D";
      let returnValue = [];

      for (const dateInfo of dateList) {
        lastDate = dateInfo[dateInfo.length - 1];
        if (dateInfo.length === 1) {
          returnValue.push(moment(dateInfo[0]).format(momentFormat));
        } else {
          const from = moment(dateInfo[0]).format(momentFormat);
          const to = moment(dateInfo[dateInfo.length - 1]).format(momentFormat);
          returnValue.push(`${from}~${to}`);
        }
      }
      this.$emit("rangeDates", {
        val: returnValue.join(","),
        valInfo: this.sortedDates,
        lastDate,
      });
      return returnValue.join(",");
    },
    sortedDates: function () {
      return [...this.dates].sort();
    },
  },
  methods: {
    rangeDateList: function (sortedList) {
      const returnValue = [];
      let temp = null;
      for (let i = 0; i < sortedList.length; i++) {
        if (temp && this.checkDiffOneDay(temp, sortedList[i])) {
          returnValue[returnValue.length - 1].push(sortedList[i]);
        } else {
          returnValue.push([sortedList[i]]);
        }
        temp = sortedList[i];
      }
      return returnValue;
    },
    checkDiffOneDay: function (from, to) {
      return moment(from).add(1, "days").format("YYYY-MM-DD") === to;
    },
    setInitData: function (initData) {
      this.dates = initData;
    },
    cancle: function(){
      this.modal = false;
    }
  },
  mounted() {
    this.initData && this.setInitData(this.initData);
  },
  mixins: [mixin],
};
</script>
<style scoped>
.input_cal {
  border: solid 1px #aaacb4;
  border-radius: 3px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  margin-top: 6px;
}
.v-date-picker-table--date td {
  width: 45px;
  padding: 0;
  height: 32px;
}
</style>
