<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">마스터관리</a>
            </li>
            <li>
                <a href="javascript:void(0)">적용일자관리</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>적용일자관리</h2>
            </div>
            <h3>
                <div class="btn-right">
                    <button class="btn_primary" v-if="!this.modifyYn" @click="modify()">수정</button>
                </div>
            </h3>
            <div class="table table_line">
                <table>
                    <tr>
                        <th>유형</th>
                        <th>1등급</th>
                        <th>2등급</th>
                        <th>3등급</th>
                        <th>4등급</th>
                        <th>5등급</th>
                    </tr>
                    <tr v-if="!modifyYn">
                        <td>직원</td>
                        <td
                            v-for="(item) in this.empList.filter(item => {return item.applyDateGrade == 1})"
                            :key="item.seq">{{item.applyDateValue}}일</td>
                        <td
                            v-for="(item) in this.empList.filter(item => {return item.applyDateGrade == 2})"
                            :key="item.seq">{{item.applyDateValue}}일</td>
                        <td
                            v-for="(item) in this.empList.filter(item => {return item.applyDateGrade == 3})"
                            :key="item.seq">{{item.applyDateValue}}일</td>
                        <td
                            v-for="(item) in this.empList.filter(item => {return item.applyDateGrade == 4})"
                            :key="item.seq">{{item.applyDateValue}}일</td>
                        <td
                            v-for="(item) in this.empList.filter(item => {return item.applyDateGrade == 5})"
                            :key="item.seq">{{item.applyDateValue}}일</td>
                    </tr>
                    <tr v-if="modifyYn">
                        <td>직원</td>
                        <td
                            v-for="(item) in this.empList.filter(item => {return item.applyDateGrade == 1})"
                            :key="item.seq"><input type="text" v-model="item.applyDateValue"/></td>
                        <td
                            v-for="(item) in this.empList.filter(item => {return item.applyDateGrade == 2})"
                            :key="item.seq"><input type="text" v-model="item.applyDateValue"/></td>
                        <td
                            v-for="(item) in this.empList.filter(item => {return item.applyDateGrade == 3})"
                            :key="item.seq"><input type="text" v-model="item.applyDateValue"/></td>
                        <td
                            v-for="(item) in this.empList.filter(item => {return item.applyDateGrade == 4})"
                            :key="item.seq"><input type="text" v-model="item.applyDateValue"/></td>
                        <td
                            v-for="(item) in this.empList.filter(item => {return item.applyDateGrade == 5})"
                            :key="item.seq"><input type="text" v-model="item.applyDateValue"/></td>
                    </tr>
                    <tr v-if="!modifyYn">
                        <td>직원외</td>
                        <td
                            v-for="(item) in this.etcList.filter(item => {return item.applyDateGrade == 1})"
                            :key="item.seq">{{item.applyDateValue}}일</td>
                        <td
                            v-for="(item) in this.etcList.filter(item => {return item.applyDateGrade == 2})"
                            :key="item.seq">{{item.applyDateValue}}일</td>
                        <td
                            v-for="(item) in this.etcList.filter(item => {return item.applyDateGrade == 3})"
                            :key="item.seq">{{item.applyDateValue}}일</td>
                        <td
                            v-for="(item) in this.etcList.filter(item => {return item.applyDateGrade == 4})"
                            :key="item.seq">{{item.applyDateValue}}일</td>
                        <td
                            v-for="(item) in this.etcList.filter(item => {return item.applyDateGrade == 5})"
                            :key="item.seq">{{item.applyDateValue}}일</td>
                    </tr>
                    <tr v-if="modifyYn">
                        <td>직원외</td>
                        <td
                            v-for="(item) in this.etcList.filter(item => {return item.applyDateGrade == 1})"
                            :key="item.seq">
                            <input type="text" v-model="item.applyDateValue"/></td>
                        <td
                            v-for="(item) in this.etcList.filter(item => {return item.applyDateGrade == 2})"
                            :key="item.seq">
                            <input type="text" v-model="item.applyDateValue"/></td>
                        <td
                            v-for="(item) in this.etcList.filter(item => {return item.applyDateGrade == 3})"
                            :key="item.seq">
                            <input type="text" v-model="item.applyDateValue"/></td>
                        <td
                            v-for="(item) in this.etcList.filter(item => {return item.applyDateGrade == 4})"
                            :key="item.seq">
                            <input type="text" v-model="item.applyDateValue"/></td>
                        <td
                            v-for="(item) in this.etcList.filter(item => {return item.applyDateGrade == 5})"
                            :key="item.seq">
                            <input type="text" v-model="item.applyDateValue"/></td>
                    </tr>
                </table>
            </div>
            <div class="btn_wrap" v-if="this.modifyYn">
                <div class="btn_left">
                    <button class="btn_secondary" @click="cancel()">취 소</button>
                    <button class="btn_primary" @click="confirmSave()">저 장</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import mixin from '../../mixin/mixin.js';

    export default {
        data: () => {
            return {etcList: [], empList: [], modifyYn: false, saveList: []}
        },
        mixins: [mixin],
        created: function () {
            this.getApplyDate();
        },
        methods: {
            getApplyDate: function () {
                this
                    .$axios
                    .get('/api/mng/service/master/v1/apply-date')
                    .then((response) => {

                        this.etcList = response.data.ETC;
                        this.empList = response.data.EMP;

                    })
            },
            modify: function () {
                this.modifyYn = true;
            },
            cancel: function () {
                this.modifyYn = false;
            },
            confirmSave: function () {
                let list = [];

                for (let item of this.empList) {
                    if (item.applyDateValue == null) {
                        this.openAlert("적용일자는 필수값 입니다.");
                        return;
                    }
                    if (/[^\d$]/.test(item.applyDateValue)) {
                        this.openAlert("적용일자는 숫자만 입력 가능합니다.");
                        return;
                    }
                    if (item.applyDateValue > 1000) {
                        this.openAlert("적용일자는 1000일을 넘어갈 수 없습니다.");
                        return;
                    }
                    list.push(item);
                }

                for (let item of this.etcList) {
                    if (item.applyDateValue == null) {
                        this.openAlert("적용일자는 필수값 입니다.");
                        return;
                    }
                    if (/[^\d$]/.test(item.applyDateValue)) {
                        console.log(item);
                        this.openAlert("적용일자는 숫자만 입력 가능합니다.");
                        return;
                    }
                    if (item.applyDateValue > 1000) {
                        this.openAlert("적용일자는 1000일을 넘어갈 수 없습니다.");
                        return;
                    }
                    list.push(item);
                }

                this.openConfirm("작성된 내용을 저장하시겠습니까?", this.save, list);

            },
            save: function (param) {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                this
                    .$axios
                    .put('/api/mng/service/master/v1/apply-date', {applyList: param})
                    .then((response) => {
                        if (response.data.success) {
                            this.openAlertClosed("정상적으로 수정하였습니다.", this.getApplyDate);

                        } else {
                            this.openAlert("수정중 오류가 발생하였습니다.");
                        }
                        this.btnLock = false;
                        this.modifyYn = false;
                    })
                    .catch(() => {
                        this.openAlert("수정중 오류가 발생하였습니다.");
                        this.btnLock = false;
                    });
            }
        }
    }
</script>