<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">기본환경</a>
            </li>
            <li>
                <a href="javascript:void(0)">민감정보 동의내역 조회</a>
            </li>
        </div>
        <div class="wapper" style="min-height: 800px">
            <div class="title">
                <h2>민감정보 동의내역 조회</h2>
                <div class="btn-right">
                    <button class="btn_search" @click="openSearch">검색</button>
                    <button class="btn_download" @click="confirmDownload">다운로드</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">
                            <dl class="full_width">
                                <dt>문진구분</dt>
                                <dd>
                                    <select v-model="inqType">
                                        <option value="">전체</option>
                                        <option
                                            v-for="(item,index) in this.masterList"
                                            :key="index"
                                            :value="item.inquiryType">{{item.inquiryTypeName}}</option>
                                    </select>
                                </dd>
                            </dl>
                            <dl class="full_width">
                                <dt>출입구분</dt>
                                <dd>
                                    <select v-model="answerType">
                                        <option v-for="(list,idx) in answerTypeList" :key="idx" :value=list.code>{{ list.name }}</option>
                                    </select>
                                </dd>
                            </dl>
                            <dl class="full_width">
                                <dt>이름</dt>
                                <dd>
                                    <input v-model="identityName" type="text"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>고유번호</dt>
                                <dd>
                                    <input v-model="identityNo" type="text"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>동의버전</dt>
                                <dd>
                                    <select v-model="privacyCode">
                                        <option value="">전체</option>
                                        <option
                                            v-for="(item,index) in this.sensitiveList"
                                            :key="index"
                                            :value="item.privacyCode">{{item.privacyVer}}</option>
                                    </select>
                                </dd>
                            </dl>
                            <dl>
                                <dt>동의일자</dt>
                                <dd>
                                    <date-picker
                                        :dateParam.sync="agreeDate"
                                        @dateVal="startDateFunc"
                                        style="width: 150px; height:40px;"></date-picker>
                                </dd>
                            </dl>
                        </div>
                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'btn_filered' : filter}"
                                @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                            <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table table_line">
                <table>
                    <tr>
                        <th>출입구분</th>
                        <th>고유번호</th>
                        <th>이름</th>
                        <th>동의버전</th>
                        <th>동의일시</th>
                        <th>설정</th>
                    </tr>
                    <tr v-for="(item,index) in agreeList" :key="index">
                        <td>{{convertAnswerType(item.answerType)}}</td>
                        <td>{{item.identityNo}}</td>
                        <td>{{item.identityName}}</td>
                        <td>{{item.sensitiveVer}}
                            <button @click="goToView(item.sensitiveCode)">보기</button>
                        </td>
                        <td>{{convertDate(item.agreeDate)}}</td>
                        <td>
                            <button @click="deleteConfirm(item.answerId)">삭제</button>
                        </td>
                    </tr>
                </table>
            </div>
            <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" 
            :startDate="agreeDate" :inqType="inqType" :answerType="answerType"
            :identityName="identityName" :identityNo="identityNo" :version="privacyCode"
            />
        </div>
    </div>

</template>

<script>
    import pagination from '../../components/commonPagination.vue'
    import mixin from '../../mixin/mixin.js';
    import datePicker from '../../components/datePicker.vue';
    import moment from 'moment';
    import $ from 'jquery'
    export default {
        data: () => {
            return {
                agreeList: [],
                answerType: "all",
                inqType: "",
                identityName: null,
                identityNo: null,
                privacyCode: "",
                agreeDate: null,
                sensitiveList: [],
                masterList: [],
                filter: false, //검색필터 적용여부.
            }
        },
        mixins: [mixin],
        created: function () {
            this.getMaster();
            this.getPrivacyVer();
            if(this.$route.query.page) {
                this.changeFilter()
                this.agreeDate = this.$route.query.startDate|| null
                this.inqType = this.$route.query.inqType || null
                this.answerType = this.$route.query.answerType|| null
                this.identityName = this.$route.query.identityName|| null
                this.identityNo = this.$route.query.identityNo|| null
                this.privacyCode = this.$route.query.version|| null
                this.getPrivacyList(this.$route.query.page);
            } else {
                this.getPrivacyList(0);
            }
            
        },
        components: {
            'date-picker': datePicker,
            pagination
        },
        methods: {
            getPrivacyList: function (page) {
                let params = {};

                if (this.filter) {
                    let filterObj = {
                        page: page,
                        size: 30,
                        type:'S',
                        inqType: this.inqType,
                        answerType: this.answerType,
                        identityName: this.identityName,
                        identityNo: this.identityNo,
                        sensitiveCode: this.privacyCode,
                        agreeDateParam: this.agreeDate
                    }
                    params = filterObj;
                } else {
                    let filterObj = {
                        page: page,
                        size: 30,
                        type:'S',
                    }
                    params = filterObj;
                }

                this
                    .$axios
                    .get('/api/mng/env/v1/privacy/list', {params: params})
                    .then((response) => {
                        console.log(response.data);

                        this.agreeList = response.data.result.content;
                        for (let item of this.agreeList) {
                            for (let master of this.masterList) {
                                if (item.answerType == master.inquiryType) {
                                    item.answerTypeName = master.inquiryTypeName;
                                }
                            }
                        }

                        let pageInfo = response.data.result;
                        this.totalCount = pageInfo.totalElements;

                        this.pagerHandler(
                            pageInfo.number,
                            pageInfo.first,
                            pageInfo.last,
                            pageInfo.totalPages
                        );
                    })
            },
            getMaster: function () {
                this
                    .$axios
                    .get('/api/mng/common/v1/master-type')
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.success) {
                            this.masterList = response.data.result
                        }
                    })
                    .catch(() => {
                        this.openAlert("문진 유형 정보를 가져오는데 실패했습니다.");
                    })
            },
            getPrivacyVer: function () {
                this
                    .$axios
                    .get('/api/mng/common/v1/privacy')
                    .then((response) => {
                        console.log("ㅣㅣ",response.data);
                        if (response.data.success) {
                            this.sensitiveList = response.data.result.sensitiveList;
                        } else {
                            this.openAlert("민감정보 버전정보를 가져오는데 실패했습니다.");
                        }
                    })
                    .catch(() => {
                        this.openAlert("민감정보 버전정보를 가져오는데 실패했습니다.");
                    });
                },
                deleteConfirm: function (answerId) {
                this.openConfirm("동의서 동의 내역을 삭제 하시겠습니까?", this.deleteAgree, answerId);
            },
            firstSearch: function () {
                let pageQuery = {
                    page : 0,
                    startDate : this.agreeDate|| null,
                    inqType : this.inqType || null,
                    answerType : this.answerType|| null,
                    identityName : this.identityName|| null,
                    identityNo : this.identityNo|| null,
                    version : this.privacyCode|| null
                }
                this.$router.push({
                    path: this.$route.path,
                    query: pageQuery
                }).catch(()=>{});
            },
            goToView: function (privacyCode) {
                console.log(privacyCode);
                this
                    .$router
                    .push({
                        name: 'sensitive-view',
                        query: {
                            privacyCode: privacyCode,
                        }
                    });
            },
            deleteAgree: function (answerId) {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                this
                    .$axios
                    .post('/api/mng/env/v1/sensitive/rm', {answerId: answerId})
                    .then((response) => {
                        if (response.data.success) {
                            this.openAlertClosed("삭제가 완료되었습니다.", this.getPrivacyList, this.page.number);
                        } else {
                            this.openAlert("삭제중 오류가 발생했습니다.");
                        }
                        this.btnLock = false;
                    })
                    .catch(() => {
                        this.openAlert("삭제중 오류가 발생했습니다.");
                        this.btnLock = false;
                    })
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            confirmDownload: function () {
            	this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
            	this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {

                let params = {};
                if (this.filter) {
                    let filterObj = {
                        page: 0,
                        size: 100000,
                        type:'S',
                        answerType: this.answerType,
                        inqType: this.inqType,
                        identityName: this.identityName,
                        identityNo: this.identityNo,
                        privacyCode: this.privacyCode,
                        agreeDateParam: this.agreeDate
                    }
                    params = filterObj;
                } else {
                    let filterObj = {
                        page: 0,
                        size: 100000,
                        type:'S',
                    }
                    params = filterObj;
                }

                this
                    .$axios
                    .get('/api/mng/env/v1/privacy/list/excel', {params: params, responseType: "blob"})
                    .then((response) => {
                        console.log(response);
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type']}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', '민감정보동의내역조회_'+moment().format("YYYYMMDD_HHmmss")+'.xlsx');
                        document.body.appendChild(link);
                        link.click();

                        if(response.status == 200) {
                        	let param = {
                        		fileName: '민감정보동의내역조회_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                        		fileSize: response.data.size,
                        		purpose: paramdata.purpose,
                        		purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                        	}
                        	this.setDownloadHistory(param);
                        }
                    });
            },
            startDateFunc: function (date) {
                this.agreeDate = date;
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.answerType = "all";
                this.inqType = "";
                this.identityName = null;
                this.identityNo = null;
                this.privacyCode ="";
                this.agreeDate = null;
            }
        }
    }

</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
</style>
