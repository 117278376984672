<template>
    <div id="popup_reg" class="pop pop_md">

        <div class="pop_head">
            <h2>알림</h2>
            <a class="btn_pop_close" href="javascript:void(0)" @click="close">닫기</a>
        </div>
        <div class="pop_body" v-html="alertMsg"></div>
        <div class="pop_footer">
            <button class="btn_secondary" @click="close">확 인</button>
        </div>
    </div>
</template>
<script>
    export default {

        props: ['alertMsg'],
        methods: {
            close: function () {
                this.$emit('close');
            }
        }

    }
</script>

<style scoped="scoped">
    .pop {
        display: inline-block;
        position: unset;
    }
</style>