<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">출입자</a>
            </li>
            <li>
                <a href="javascript:void(0)">협력업체 사원 관리</a>
            </li>
        </div>
        <div class="wapper" style="min-height: 700px;">
            <div class="title">
                <template v-if="searchYn == 'Y'">
                    <h2>등록직원 - 검색결과</h2>
                </template>
                <template v-else>
                    <h2>등록직원 ({{this.pname}})</h2>
                </template>
            </div>
            <h3>
                <div class="btn-left">
                    <button class="btn_primary" @click="sendConfirm">알림발송</button>
                    <button class="btn_primary" @click="deleteEmp">선택삭제</button>
                </div>
                <div class="btn-right">
                    <select @change="addPartner($event)">
                        <option value="" selected="selected" disabled="disabled">등록방식 선택</option>
                        <option value="I">개별등록</option>
                        <option value="E">엑셀일괄등록</option>
                    </select>
                    <button class="btn_search" @click="openSearch()">검색</button>
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">
                            <dl class="full_width">
                                <dt>이름</dt>
                                <dd>
                                    <input type="text" v-model="empNm"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>휴대전화번호</dt>
                                <dd>
                                    <input type="text" v-model="empPhone"/></dd>
                            </dl>
                        </div>
                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'btn_filered' : filter}"
                                @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                            <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
                        </div>
                    </div>
                </div>
            </h3>
            <div class="table table_line">
                <table>
                    <tr>
                        <th style="width:3%;" ><input type="checkbox" v-model="allChecked" @click="checkedAll($event.target.checked)"/></th>
                        <th>등록일시</th>
                        <th>등록자</th>
                        <th>이름</th>
                        <th>휴대전화 번호</th>
                        <th>관리</th>
                    </tr>
                    <tr v-for="(item, index) in partnerList" :key="index">
                        <td>
                            <input type="checkbox"
                                :id="'check_' + item.empId"
                                :value="item.empId"
                                v-model="item.selected"
                                @change="selected($event)">
                        </td>
                        <td>{{convertDate(item.regDate)}}</td>
                        <td>{{item.regUser}}</td>
                        <td>{{item.empNm}}</td>
                        <td>{{convertPhone(item.empPhone)}}</td>
                        <td>
                            <button @click="reSend(item, 'Y')">발송</button>
                            <button @click="goModify(item.empPhone, item.empId)">수정</button>
                        </td>
                    </tr>
                </table>
            </div>
            <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0"
             :pid="partnerCode" :pname="pname" :empNm="empNm" :empPhone="empPhone" :searchYn="searchYn"
           />
        </div>
    </div>

</template>

<script>
//여기 페이지 어딘지 몰라서 테스트 못했음
    import pagination from '../../components/commonPagination.vue'
    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';
    import $ from 'jquery'
    export default {
        data: () => {
            return {
                partnerCode: null,
                empNm: null,
                empPhone: null,
                partnerList: [],
                filter: false,
                pname: null,
                allChecked: false,
                searchYn: null
            }
        },
        mixins: [mixin],
        created: function () {
            this.partnerCode = this.$route.query.pid;
            this.pname = this.$route.query.pname;
            this.empNm = this.$route.query.empNm;
            this.empPhone = this.$route.query.empPhone;
            this.searchYn = this.$route.query.searchYn;
        },
        mounted: function() {
            if(this.$route.query.page) {
                this.changeFilter()
                this.getList(this.$route.query.page);
            } else {
                this.getList(0);
            }
                       
        },
        components : {
            pagination
        },
        methods: {
            getList: function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }

                let param = {};

                if (this.filter) {
                    param = {
                        page: page,
                        size: 30,
                        partnerCode: this.partnerCode,
                        empNm: this.empNm,
                        empPhone: this.empPhone
                    }
                } else {
                    if(this.searchYn == 'Y') {
                        param = {
                            page: page,
                            size: 30,
                            partnerCode: this.partnerCode,
                            empNm: this.empNm,
                            empPhone: this.empPhone
                        }
                    } else {
                        param = {
                            page: page,
                            size: 30,
                            partnerCode: this.partnerCode,
                        }
                    }
                }

                this
                    .$axios
                    .get('/api/mng/partner/emp/v1/list', {params: param})
                    .then((response) => {
                        console.log(response.data);

                        if (response.data.success) {
                            let result = response.data.result.content;

                            this.partnerList = result;
                            let pageInfo = response.data.result;

                            this.pagerHandler(
                                pageInfo.number,
                                pageInfo.first,
                                pageInfo.last,
                                pageInfo.totalPages
                            );
                        } else {
                            this.openAlert("협력업체를 불러오는 도중 오류가 발생하였습니다.");
                        }
                    })
                    .catch(() => {
                        this.openAlert("협력업체를 불러오는 도중 오류가 발생하였습니다.");
                    });
            },
            firstSearch: function () {
                let pageQuery = {
                    page : 0,
                    pid : this.partnerCode,
                    pname : this.pname,
                    empNm : this.empNm,
                    empPhone : this.empPhone,
                    searchYn : this.searchYn,
                }
                this.$router.push({
                    path: this.$route.path,
                    query: pageQuery
                }).catch(()=>{});
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.empNm = null;
                this.empPhone = null;
            },
            addPartner: function (event) {
                if (event.target.value == 'I') {
                    this
                        .$router
                        .push({
                            name: 'partnerEmpAdd',
                            query: {
                                pid: this.partnerCode,
                                list: this.partnerList,
                                pname: this.pname
                            }
                        });
                } else {
                    this
                        .$router
                        .push({
                            name: 'partnerEmpAddExcel',
                            query: {
                                pid: this.partnerCode,
                                list: this.partnerList,
                                pname: this.pname
                            }
                        });
                }

            },
            goModify: function (empPhone, empId) {
                this
                    .$router
                    .push({
                        name: 'partnerEmpUpd',
                        query: {
                            pid: this.partnerCode,
                            empPhone: window.btoa(empPhone),
                            empId : empId,
                            pname: this.pname
                        }
                    });
            },
            confirmDownload: function () {
                this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {
                let param = {};

                if (this.filter) {
                    param = {
                        page: 0,
                        size: 100000,
                        partnerCode: this.partnerCode,
                        empNm: this.empNm,
                        empPhone: this.empPhone
                    }
                } else {
                    param = {
                        page: 0,
                        size: 100000,
                        partnerCode: this.partnerCode
                    }
                }
                this
                    .$axios
                    .get('/api/mng/partner/emp/v1/list/exceldown', {
                        params: param,
                        responseType: "blob"
                    })
                    .then((response) => {
                        console.log(response);
                        const url = window
                            .URL
                            .createObjectURL(
                                new Blob([response.data], {type: response.headers['content-type']})
                            );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute(
                            'download',
                            '협력업체등록직원_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx'
                        );
                        document
                            .body
                            .appendChild(link);
                        link.click();

                        if (response.status == 200) {
                            let param = {
                                fileName: '협력업체등록직원_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                                fileSize: response.data.size,
                                purpose: paramdata.purpose,
                                purposeEtc: paramdata.purpose_etc == null
                                    ? ''
                                    : paramdata.purpose_etc
                            }
                            this.setDownloadHistory(param);
                        }
                    })
            },
            reSend: function (item, Yn) {
                this
                .$axios
                .post('/api/mng/partner/emp/v1/list/resend', {
                    partnerCode: item.partnerCode,
                    empId: item.empId,
                    empNm: item.empNm,
                    empPhone: item.empPhone
                })
                .then((response) => {
                    if (response.data.success) {
                        if("Y" == Yn) this.openAlert("메시지를 재발송하였습니다.");
                    } else {
                        this.openAlert("메시지 발송중 오류가 발생했습니다.");
                    }
                })
                .catch(() => {
                    this.openAlert("메시지 발송중 오류가 발생했습니다.");
                })
            },
            sendConfirm: function () {
                let list = this.partnerList;
                let selectedYn = false;
                for (let i in list) {
                    if(list[i].selected) selectedYn = true;
                }
                if(selectedYn) this.openConfirm("선택하신 등록직원분들께 알림을 발송합니다.", this.checkedSend);
                else this.openAlert("등록직원을 선택해 주세요.");
             },
            checkedSend: function() {
                let list = this.partnerList;
                let selectedYn = false;
                for (let i in list) {
                    if(list[i].selected) {
                        console.log(list[i].empId);
                        this.reSend(list[i]);
                        selectedYn = true;
                    }
                }
                if(selectedYn) this.openAlert("메시지를 발송하였습니다.");
            },
            deleteEmp: function(){
              let list = this.partnerList;
              let selectedYn = false;
              for (let i in list) {
                if(list[i].selected) selectedYn = true;
              }
              if(selectedYn) this.openConfirm("선택하신 등록직원을 삭제하시겠습니까?", this.checkedDelete);
              else this.openAlert("삭제할 직원을 선택해 주세요.");
            },
            checkedDelete: async function() {
              let list = this.partnerList.filter(partner => partner.selected).map((partner) => { return partner.empId });
              console.log(list);
              let response = await this.$axios.post('/api/mng/partner/emp/v1/list/deleteEmp', {
                empCodeList : list
              }).catch(() => {
                this.openAlert("등록직원 삭제 중 오류 발생")
              })
              if(response.data.success) {
                this.getList(0);
              }
            },
            checkedAll: function(checked) {
                this.allChecked = checked
                 for (let i in this.partnerList) {
                     this.partnerList[i].selected = this.allChecked;
                 }
            },
            selected (e) {
                for (let i in this.partnerList) {
                    if(! this.partnerList[i].selected) {
                        this.allChecked = false;
                        return;
                    } else {
                        this.allChecked = true;
                    }
                }
            }
        }
    }
</script>

<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
    .contents input[type="checkbox"] {
        display: block;
    }
</style>
