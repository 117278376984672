<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">마스터관리</a>
            </li>
            <li>
                <a href="javascript:void(0)">블랙리스트관리</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>블랙리스트관리</h2>
                <p>{{convertDateDay(this.searchDate)}}
                    기준 출입제한자 명단입니다.(총{{this.totalCnt}}명)</p>
            </div>

            <div class="tabArea">
                <input id="tab1" type="radio" name="tabs" checked="checked"/>
                <label for="tab1">출입제한자 명단</label>
                <!-- <input id="tab2" type="radio" name="tabs" > <label for="tab2">협력업체</label>
                <input id="tab3" type="radio" name="tabs"> <label for="tab3">일반보호자</label> -->
                <h3>
                    <div class="btn-right">
                        <button class="btn_search" @click="openSearch()">검색</button>
                        <div class="search">
                            <h3>검색</h3>
                            <div class="search_detail">
                                <dl class="full_width">
                                    <dt>병원등록번호</dt>
                                    <dd>
                                        <input type="text" v-model="patno"/></dd>
                                </dl>
                                <dl class="full_width">
                                    <dt>환자이름</dt>
                                    <dd>
                                        <input type="text" v-model="patNm"/></dd>
                                </dl>
                            </div>
                            <div class="search_footer">
                                <button
                                    class="btn_secondary btn_md btn_filer"
                                    :class="{'btn_filered' : filter}"
                                    @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                                <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                                <button class="btn_primary btn_md" @click="getBlakList(0)">적용</button>
                            </div>
                        </div>
                    </div>
                </h3>
                <section id="tab_content1">
                    <div class="table table_line">
                        <table>
                            <colgroup>
                                <col width="5%"/>
                                <col width="5%"/>
                                <col width="5%"/>
                                <col width="5%"/>
                                <col width="5%"/>
                                <col width="5%"/>
                                <col width="55%"/>
                                <col width="10%"/>
                            </colgroup>
                            <tr>
                                <th>병원등록번호</th>
                                <th>환자성명</th>
                                <th>성별코드</th>
                                <th>나이</th>
                                <th>발생일자</th>
                                <th>발생장소명</th>
                                <th style="text-align: center;">출동내역(조치사항내용)</th>
                                <th>특이사항</th>
                            </tr>
                            <tr v-for="(item,index) in this.blackList" :key="index">
                                <td>{{item.patno}}</td>
                                <td>{{item.patNm}}</td>
                                <td>{{item.sexDivNm}}</td>
                                <td>{{item.ageCnte}}</td>
                                <td>{{item.occurDt}}</td>
                                <td>{{item.occurLocNm}}</td>
                                <td>{{item.tkactSchmCnte}}</td>
                                <td>{{item.rsmk}}</td>

                            </tr>
                        </table>
                    </div>
                    <div class="pagination">
                        <ul>
                            <li>
                                <a href="javascript:void(0)" @click="getBlakList(page.number - 1)">이전</a>
                            </li>
                            <li
                                v-for="(item, index ) in pageNation"
                                :key="index"
                                class="pageNo"
                                :class="{'active': item == page.number +1}">
                                <a href="javascript:void(0)" @click="getBlakList(item - 1)">{{item}}</a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click="getBlakList(page.number + 1)">다음</a>
                            </li>
                        </ul>
                    </div>
                </section>
            </div>

        </div>
    </div>

</template>

<script>
    import mixin from '../../mixin/mixin.js';
    import $ from 'jquery'
    export default {
        data: () => {
            return {
                blackList: [],
                filter: false,
                patno: null,
                patNm: null,
                searchDate: null,
                totalCnt: 0
            }
        },
        mixins: [mixin],
        created: function () {
            this.getBlakList();
        },
        methods: {
            getBlakList: function (page) {

                if (page < 0 || this.page.totalPages < page) {
                    return;
                }

                let params = {};

                if (this.filter) {
                    let obj = {
                        page: page,
                        size: 30,
                        patno: this.patno,
                        PatNm: this.patNm
                    }
                    params = obj
                } else {
                    let obj = {
                        page: page,
                        size: 30
                    }
                    params = obj
                }

                this
                    .$axios
                    .get('/api/mng/service/master/v1/amcblk', {params: params})
                    .then((response) => {
                        if (response.data.success) {
                            console.log(response.data.result);
                            this.blackList = response.data.result.content;

                            this.searchDate = new Date()
                                .toISOString()
                                .substring(0, 10);
                            this.totalCnt = response.data.result.totalElements;

                            let pageInfo = response.data.result;
                            this.pagerHandler(
                                pageInfo.number,
                                pageInfo.first,
                                pageInfo.last,
                                pageInfo.totalPages
                            );
                        } else {
                            this.openAlert("출입제한자 명단 조회에 실패했습니다.");
                        }
                    })
                    .catch(() => {
                        this.openAlert("출입제한자 명단 조회에 실패했습니다.");
                    });
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
             openSearch: function () {
                $('.search').slideToggle('down');
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            initSearchCnd: function() {
                    this.patno = null;
                    this.patNm = null;
            }
        }
    }
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
</style>
