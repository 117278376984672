<template>
  <div class="contents">
    <div class="loc">
      <li>
        <a href="">기본환경</a>
      </li>
      <li>
        <a href="">체류시간 안내문구 관리</a>
      </li>
    </div>
    <div class="wapper">
      <div class="title">
        <h2>체류시간 안내문구 관리</h2>
      </div>
      <div class="details">
        <div class="detail_conts">
          <h4>한국어 버전 </h4>
          <blockquote>문진에서 한국어 선택시 보여집니다.</blockquote>
          <br/>
          <h4>제목</h4>
          <div style="width: 100%;"><div style="width: 100%;" v-html="title" readonly></div></div>
          <br/>
          <h4>내용</h4>
          <div v-html="content"></div>
        </div>
        <div class="detail_conts">
          <h4>영문 버전 </h4>
          <blockquote>문진에서 한국어 이외의 언어를 선택시 보여집니다.</blockquote>
          <br/>
          <h4>제목</h4>
          <div style="width: 100%;"><div style="width: 100%;" v-html="titleEn" readonly></div></div>
          <br/>
          <h4>내용</h4>
          <div v-html="contentEn"></div>
        </div>
      </div>
      <div class="title">
        <div class="btn-right">
          <button class="btn_secondary" @click="goBack()">목록</button>
        </div>
      </div>
    </div>
    <modal
        name="alertMsg"
        :width="540"
        :height="190"
        :adaptive="true"
        :clickToClose="false">
      <div id="popup_reg" class="pop pop_md">
        <div class="pop_head">
          <h2>알림</h2>
          <a class="btn_pop_close" @click="closePop('alertMsg')">닫기</a>
        </div>
        <div class="pop_body">
          {{ errMsg }}
        </div>
        <div class="pop_footer">
          <button class="btn_secondary" @click="closePop('alertMsg')">확 인</button>
        </div>
      </div>
    </modal>
    <modal
        name="success"
        :width="540"
        :height="190"
        :adaptive="true"
        :clickToClose="false"
        @closed="goBack">
      <div id="popup_reg" class="pop pop_md">
        <div class="pop_head">
          <h2>알림</h2>
          <a class="btn_pop_close" @click="closePop('success')">닫기</a>
        </div>
        <div class="pop_body">
          {{ errMsg }}
        </div>
        <div class="pop_footer">
          <button class="btn_secondary" @click="closePop('success')">확 인</button>
        </div>
      </div>
    </modal>
    <modal
        name="popup_prev_road"
        :width="320"
        :height="228"
        :adaptive="true"
        :clickToClose="false">
      <div id="popup_prev_road" class="pop pop_sm">
        <!-- 새 문진 등록 -->
        <div class="pop_head">
          <h2>이전 버전 불러오기</h2>
          <a class="btn_pop_close" @click="closePop('popup_prev_road')">닫기</a>
        </div>
        <div class="pop_body">
          <dl>
            <dt>버전 선택</dt>
            <dd>
              <select @change="selectPrevVer($event)">
                <option value="" disabled="disabled" selected="selected">버전</option>
                <option v-for="(item, index) in prevList" :key="index" :value="item.code">{{ item.ver }}
                </option>
              </select>
            </dd>
          </dl>
        </div>
        <div class="pop_footer">
          <button class="btn_secondary" id="btn_reg_cancel" @click="closePop('popup_prev_road')">취 소</button>
          <button class="btn_primary" @click="getPrivacyInfo()">확인</button>
        </div>
      </div>
    </modal>
  </div>

</template>
<script>
import mixin from "@/mixin/mixin";


export default {
  mixins: [mixin],
  mounted() {
    this.code = this.$route.query.code;
    if (!this.code) {
      this.openAlert("유효하지 않은 안내문구 코드입니다.");
      this.goBack();
    }
    this.getPrivacy();
  },
  data: () => {
    return {
      errMsg: null,
      code: null,
      prevList: [],
      prevVer: null,
      title: null,
      titleEn: null,
      content: null,
      contentEn: null,
    }
  },
  methods: {
    goBack: function () {
      this.$router.go(-1);
    },
    async getPrivacy() {

      await this
          .$axios
          .get('/api/mng/v1/residence/' + this.code)
          .then((response) => {
            console.log(response.data);
            if (response.data.success) {
              let result = response.data.result;
              this.title = result.title.replaceAll('\n', '<br/>');
              this.titleEn = result.titleEn.replaceAll('\n', '<br/>');
              this.content = result.privacyContents.replaceAll('\n', '<br/>');
              this.contentEn = result.privacyContentsEng.replaceAll('\n', '<br/>');
            } else {
              this.openAlert("유효하지 않은 안내문구 코드입니다.");
              this.goBack();
            }
          })
          .catch(() => {
            this.openAlert("안내문구 호출중 오류가 발생했습니다.");
            this.goBack();
          });
    },
    selectPrevVer(event) {
      let code = event.target.value;
      if (code) {
        this.prevVer = code;
      }
    },
    async getPrivacyInfo() {
      if (this.prevVer) {
        await this
            .$axios
            .get('/api/mng/env/v1/privacy', {
              params: {
                privacyCode: this.prevVer
              }
            })
            .then((response) => {
              if (response.data.success) {
                this.editor = response.data.result.privacyContents;
                this.editor2 = response.data.result.privacyContentsEng
              } else {
                this.openAlert("개인정보 정보를 가져오는 도중 오류가 발생했습니다.");
              }
            })
            .catch(() => {
              this.openAlert("개인정보 정보를 가져오는 도중 오류가 발생했습니다.");
            })
      }
      this.prevVer = null;
      this.$modal.hide('popup_prev_road')
    },
    closePop: function (target) {
      this.errMsg = null;
      this
          .$modal
          .hide(target);
    },
  }
};
</script>
<style scoped="scoped">
#popup_reg {
  display: inline-block;
}

.details {
  padding: 20px 0;
  display: flex;
}

.details .detail_conts {
  width: 48%;
  margin: 0 1%;
}

.pop {
  display: inline-block;
}

</style>
