<template>
  <div class="contents">
    <div class="loc">
      <li>
        <a href="javscript:void(0)">문진</a>
      </li>
      <li>
        <a href="javscript:void(0)">긴급방문력조사</a>
      </li>
      <li>
        <a href="javscript:void(0)">{{ content.title }}</a>
      </li>
    </div>
    <div class="wapper" style="min-height: 600px">
      <div class="title">
        <h2>{{ content.title }}</h2>
        <p>기준 출입대상 문진결과입니다. (총 {{ totalCnt | comma }}명)</p>
      </div>
      <div class="tabArea">
        <input
          @click="changeTab('rpt')"
          id="tab1"
          type="radio"
          name="tabs"
          checked
        />
        <label for="tab1">재원환자</label>
        <input @click="changeTab('emp')" id="tab2" type="radio" name="tabs" />
        <label for="tab2">직원</label>
        <input @click="changeTab('rnok')" id="tab3" type="radio" name="tabs" />
        <label for="tab3">상주보호자</label>

        <inquiryEmergencyPt
          ref="rpt"
          id="tab_content1"
          masterId="RPT"
          :detailId="content.detailId"
        ></inquiryEmergencyPt>
        <inquiryEmergencyEmp
          ref="emp"
          id="tab_content2"
          masterId="EMP"
          :detailId="content.detailId"
        ></inquiryEmergencyEmp>
        <inquiryEmergencyNok
          ref="rnok"
          id="tab_content3"
          masterId="RNOK"
          :detailId="content.detailId"
        ></inquiryEmergencyNok>
      </div>
    </div>
  </div>
</template>

<script>
import inquiryEmergencyPt from "./inquiryEmergencyPtDetail.vue";
import inquiryEmergencyEmp from "./inquiryEmergencyEmpDetail.vue";
import inquiryEmergencyNok from "./inquiryEmergencyNokDetail.vue";
export default {
  name: "InquiryEmergencyResult",
  components: {
    inquiryEmergencyPt,
    inquiryEmergencyEmp,
    inquiryEmergencyNok,
  },
  filters: {
    comma(val) {
      if (val !== null)
        return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  mounted: function () {
    this.totalCnt = this.$refs["rpt"].totalCount;
  },
  props: {
    content: Object,
  },
  data() {
    return {
      totalCnt: 0,
    };
  },
  mounted() {
    console.log(JSON.stringify(this.content));
  },
  methods: {
    totalCountFunc: function (count) {
      this.totalCnt = count;
    },
    changeTab: function (target) {
      console.log(target);
      this.totalCnt = this.$refs[target].totalCount;
    },
  },
};
</script>

<style>
</style>
