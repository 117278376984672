<template>

    <section>
        <h3>
            <div class="btn-right">
                <button class="btn_primary" @click="addAdmin()">신규등록</button>
            </div>
        </h3>
        <div class="table table_line">
            <table>
                <colgroup>
                    <col width="80%"/>
                    <col width="20%"/>
                </colgroup>
                <tr>
                    <th>사번</th>
                    <th>관리</th>
                </tr>
                <tr v-for="(item, index) in this.adminList" :key="index">
                    <td>{{item.adminId}}
                    </td>
                    <td>
                        <button @click="delAdmin(item.adminId)" class="btn-secondary">삭제</button>
                    </td>

                </tr>

            </table>
        </div>
          <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0"  />
    </section>
</template>
<script>
    import pagination from './commonPagination.vue'

    import mixin from '../mixin/mixin.js';
    export default {
        data: () => {
            return {adminList: []}
        },
        mixins: [mixin],
        created: function () {
            if(this.$route.query.page) {
                this.getList(this.$route.query.page);
            } else {
            this.getList(0);
            }
        },
        components : {
            pagination
        },
        methods: {
            getList: function (page) {
                if (page < 0 || this.page.totalPages < page) {
                    return;
                }
                this
                    .$axios
                    .get('/api/mng/service/empinqmng/v1/mngno')
                    .then((response) => {
                        if (response.data.success) {
                            this.adminList = response.data.result.content;
                            console.log(this.adminList);
                            let pageInfo = response.data.result;
                            this.pagerHandler(
                                pageInfo.number,
                                pageInfo.first,
                                pageInfo.last,
                                pageInfo.totalPages
                            );
                        } else {
                            this.openAlert("리스트 조회에 실패했습니다.");
                        }
                    })
                    .catch(() => {
                        this.openAlert("리스트 조회에 실패했습니다.");
                    });
            },
            addAdmin: function () {
                this
                    .$router
                    .push({name: 'empInfAdminAdd'})
            },
            delAdmin: function (adminId) {
                this.openConfirm(adminId + " 를 관리자사번에서 삭제하시겠습까?", this.delAdminId, adminId)
            },
            delAdminId: function (adminId) {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                this
                    .$axios
                    .post('/api/mng/service/empinqmng/v1/mngno/del', {adminId: adminId})
                    .then((response) => {
                        if (response.data.success) {
                            this.openAlert("삭제가 완료되었습니다.");
                            this.getList(0);
                        } else {
                            this.openAlert("삭제에 실패했습니다.");
                        }
                    })
                    .catch(() => {
                        this.openAlert("삭제에 실패했습니다.");
                    });
            },

        }
    }
</script>
