<template>

    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">방문신청</a>
            </li>
            <li>
                <a href="javascript:void(0)">집합행사관리</a>
            </li>
        </div>
        <div class="wapper" style="min-height:700px">
            <div class="title">
                <h2>참석대상자
                    {{this.visitPurpose}}</h2>
            </div>
            <h3>
                <div class="btn-right">
                    <select v-if="statusCd !== 'end' && usr == username" @change="addParticipants($event)" >
                        <option value=""  selected="selected" disabled="disabled">등록방식 선택</option>
                        <option value="I">개별등록</option>
                        <option value="E">엑셀일괄등록</option>
                    </select>
                    <button class="btn_search" @click="openSearch()">검색</button>
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">
                            <dl class="full_width">
                                <dt>이름</dt>
                                <dd>
                                    <input type="text" v-model="name"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>등록자</dt>
                                <dd>
                                    <input type="text" v-model="regUser"/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>휴대전화번호</dt>
                                <dd>
                                    <input type="text" v-model="phone"/></dd>
                            </dl>
                        </div>
                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'btn_filered' : filter}"
                                @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                            <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
                        </div>
                    </div>
                </div>
            </h3>
            <div class="table table_line">
                <table>
                    <tr>
                        <th>등록일시</th>
                        <th>등록자</th>
                        <th>방문자</th>
                        <th>유형</th>
                        <th>휴대전화번호</th>
                        <th>내원일</th>
                        <th>내원목적</th>
                        <th>출입일시</th>
                        <th>문진작성일시</th>
                        <th>비고</th>
                    </tr>
                    <tr v-for="(item, index) in this.list" :key="index">
                        <td>{{convertDate(item.regDate)}}</td>
                        <td>{{item.regUser}}</td>
                        <td>{{item.participantsNm}}</td>
                        <td>일반방문</td>
                        <td>{{convertPhone(item.participantsPhone)}}</td>
                        <td>{{item.visitDate}}</td>
                        <td>{{item.visitPurpose}}</td>
                        <td>{{convertDateSec(item.passDate)}}</td>
                        <td>{{convertDate(item.inqDate)}}</td>
                        <td>
                            <button v-if="statusCd !== 'end'" @click="reSend(item)">재발송</button>
                            <button v-if="statusCd !== 'end' && item.regUser == username" @click="confirmDelete(item.partId)">취소</button>
                        </td>
                    </tr>

                </table>
            </div>
           <pagination :pageArray ="pageNation" :page ="page" 
           :meetingId="$route.query.mid ? $route.query.mid:$route.query.meetingId" :usr="$route.query.usr" :statusCd = "statusCd"
           :name="name" :regUser="regUser" :phone="phone"  v-if="pageNation.length != 0" />
        </div>
    </div>
</template>
<script>
    import pagination from '../../components/commonPagination.vue'
    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';
    import $ from 'jquery';
    export default {
        mixins: [mixin],
        data: () => {
            return {
                filter: false,
                statusCd: null,
                name: null,
                phone: null,
                list: [],
                meetingId: null,
                meetingName: null,
                visitPurpose: null,
                regUser: null,
                usr: null,
            }
        },
        created: function () {          

            this.meetingId = this.$route.query.mid ? window.atob(this.$route.query.mid) : window.atob(this.$route.query.meetingId);
            this.usr = window.atob(this.$route.query.usr);
            this.statusCd = this.$route.query.statusCd;
            if (this.$route.query.page) {
                this.name = this.$route.query.name
                this.phone = this.$route.query.phone
                this.regUser = this.$route.query.regUser
                this.changeFilter()
            }
            let page = this.$route.query.page ? this.$route.query.page : 0
            this.getList(page);
        },
        components : {
            pagination
        },
        mounted: function() {
             this.getMyinfo();
            console.log(this.username);
        },
        methods: {
            getList: function (page) {
                let param = {};

                if (this.filter) {
                    param = {
                        page: page,
                        size: 30,
                        meetingId: this.meetingId,
                        participantsNm: this.name,
                        participantsPhone: this.phone,
                        regUser: this.regUser
                    }
                } else {
                    param = {
                        meetingId: this.meetingId,
                        page: page,
                        size: 30
                    }
                }
                this
                    .$axios
                    .get('/api/mng/group-event/v1/participants', {params: param})
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.success) {
                            this.list = response.data.result.content;
                            console.log(this.list);
                            if (this.list.length > 0) {
                                this.visitPurpose = '(' + this.list[0].visitPurpose + ')';
                            }

                            let pageInfo = response.data.result;
                            this.pagerHandler(
                                pageInfo.number,
                                pageInfo.first,
                                pageInfo.last,
                                pageInfo.totalPages
                            );
                        } else {
                            this.openAlert("참석자 리스트를 불러오는 도중 오류가 발생하였습니다.");
                        }
                    })
                    .catch(() => {
                        this.openAlert("참석자 리스트를 불러오는 도중 오류가 발생하였습니다.");
                    });
            },
            firstSearch: function () {
                let pageQuery = {
                    page : 0,
                    meetingId : this.$route.query.mid || this.$route.query.meetingId,
                    usr : this.$route.query.usr,
                    statusCd : this.statusCd,
                    name : this.name || null,
                    regUser : this.regUser|| null,
                    phone : this.phone|| null,
                }
                this.$router.push({
                    path: this.$route.path,
                    query: pageQuery
                }).catch(()=>{});
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            confirmDownload: function () {
            	this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
            	console.log(param.purpose);
            	console.log(param.purpose_etc);
            	this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {
                let param = {};
                if (this.filter) {
                    param = {
                        page: 0,
                        size: 100000,
                        meetingId: this.meetingId,
                        participantsNm: this.name,
                        participantsPhone: this.phone,
                        regUser: this.regUser
                    }
                } else {
                    param = {
                        meetingId: this.meetingId,
                        page: 0,
                        size: 100000
                    }
                }
                this
                .$axios
                .get('/api/mng/group-event/v1/participants/exceldown', {
                    params: param,
                    responseType: "blob"
                })
                .then((response) => {
                    console.log(response);
                    const url = window
                        .URL
                        .createObjectURL(
                            new Blob([response.data], {type: response.headers['content-type']})
                        );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        '참석대상자_' + this.visitPurpose + '_' + moment().format("YYYYMMDD_HHmmss") +'.xlsx'
                    );
                    document
                        .body
                        .appendChild(link);
                    link.click();

                    if(response.status == 200) {
                    	  let param = {
                    		  fileName: '참석대상자_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                    		  fileSize: response.data.size,
                    		  purpose: paramdata.purpose,
                    		  purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                    	  }
                    	  this.setDownloadHistory(param);
                    }
                })
            },
            reSend: function(item) {
                 this.$axios.post('/api/mng/group-event/v1/participants/resend', {
                    participantsNm: item.participantsNm,
                    participantsPhone: item.participantsPhone,
                    meetingId : item.meetingId
                })
                .then((response) => {
                   if(response.data.success) {
                        this.openAlert("메시지를 재발송하였습니다.");
                   } else {
                        this.openAlert("메시지 발송중 오류가 발생했습니다.");
                   }
                }).catch(() => {
                    this.openAlert("메시지 발송중 오류가 발생했습니다.");
                })
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.name = null;
                this.phone = null;
                this.regUser = null;
            },
            addParticipants: function (event) {
                let type = event.target.value;
                if (type == "I") {
                    this.individual();
                } else {
                    this.excel();
                }
            },
            individual: function () {
                this
                    .$router
                    .push({
                        name: 'participantsUpd',
                        query: {
                            mid: this.$route.query.mid || this.$route.query.meetingId,
                            list: this.list
                        }
                    })
            },
            excel: function () {
                this
                    .$router
                    .push({
                        name: 'participantsExcel',
                        query: {
                            mid: this.$route.query.mid || this.$route.query.meetingId,
                            list: this.list
                        }
                    })

            },
            confirmDelete: function (partId) {

                this.openConfirm("해당 참석자를 삭제하시겠습니까?", this.deletePart, partId);

            },
            deletePart: function (partId) {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                this
                    .$axios
                    .put('/api/mng/group-event/v1/participant/del', {partId: partId})
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.success) {
                            this.openAlertClosed("해당 참석자를 삭제했습니다.", this.getList, this.page.number);
                        } else {
                            this.openAlert("참석자를 삭제하는데 실패했습니다.");
                        }
                        this.btnLock = false;
                    })
                    .catch(() => {
                        this.openAlert("참석자를 삭제하는데 실패했습니다.");
                        this.btnLock = false;
                    });
            }
        }
    }
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
</style>
