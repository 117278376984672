<template>
    <div class="contents">
        <div class="loc">
             <li>
                <a href="javascript:void(0)">사용자</a>
            </li>
            <li>
                <a href="javascript:void(0)">권한관리</a>
            </li>
        </div>
        <div class="wapper">
            <div class="title">
                <h2>사용자 권한 등록</h2>
            </div>
            <div class="register">
                <dl>
                    <dt>소속/이름/직급</dt>
                    <dd>
                        <button class="btn_secondary" id="btn_road_staff" @click="openSearchUser()">대상선택</button>
                        {{this.deptNm +" "+this.userNm+" "+this.jobposNm}}
                    </dd>
                </dl>
                <dl>
                    <dt>사번</dt>
                    <dd><input type="text" v-model="this.userId" placeholder=""/></dd>
                </dl>
                <dl>
                    <dt>권한설정</dt>
                    <dd>
                        <ul>
                            <dl>
                                <dt><b>사용권한</b></dt>
                            </dl>
                            <dl>
                                <dt>방문신청</dt>
                                <dd>
                                    <input v-model="categoryCode" type="checkbox" id="categoryCode_a" value="A" checked />
                                    <label for="categoryCode_a"><span></span></label>
                                </dd>
                            </dl>
                            <dl>
                                <dt>출입관리</dt>
                                <dd>
                                    <input v-model="categoryCode" type="checkbox" id="categoryCode_p" value="P" checked />
                                    <label for="categoryCode_p"><span></span></label>
                                </dd>
                            </dl>
                            <dl>
                                <dt>감염관리</dt>
                                <dd>
                                    <input v-model="categoryCode" type="checkbox" id="categoryCode_i" value="I" checked />
                                    <label for="categoryCode_i"><span></span></label>
                                </dd>
                            </dl>
                            <dl>
                                <dt>서비스관리</dt>
                                <dd>
                                    <input v-model="categoryCode" type="checkbox" id="categoryCode_s" value="S" checked />
                                    <label for="categoryCode_s"><span></span></label>
                                </dd>
                            </dl>
                        </ul>
                    </dd>
                </dl>
            </div>
            <div class="btn_wrap">
                <div class="btn_left">
                    <button class="btn_secondary" @click="goList">취 소</button>
                    <button class="btn_primary" @click="saveConfirm">저 장</button>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    import searchUser from '../../components/userSearch.vue';
    import mixin from '../../mixin/mixin.js';

    export default {
        data: () => {
            return {userNm: "", deptNm: "", jobposNm: "", userId: null, deptCd: null, emailAddr: null, categoryCode: []}
        },
        mixins: [mixin],
        methods: {
            openSearchUser: function () {
                this.$modal
                .show(searchUser, {
                    getSearchUserInfo: this.getSearchUserInfo
                }, {
                    width: '640px',
                    height: 'auto',
                    adaptive: true,
                    clickToClose: false
                },)
            },
            getSearchUserInfo: function (user, dept) {
                console.log(user);
                console.log(dept);
                this.userId = user;
                this.deptCd = dept;
                this.getDetpUser();
            },
            getDetpUser: function () {
                this.$axios
                .get('/api/mng/common/v1/dept-user', {
                    params: {
                        userId: this.userId,
                        deptCd: this.deptCd
                    }
                })
                .then((response) => {
                    if (response.data.success) {
                        console.log(JSON.stringify(response.data.result));
                        this.userNm = response.data.result.korNm;
                        this.deptNm = response.data.result.deptNm;
                        this.emailAddr = response.data.result.emailAddr;
                        this.jobposNm = response.data.result.jobposNm;
                    } else {
                        this.openAlert("사용자 정보를 가져오지 못했습니다.")
                    }

                })
                .catch(() => {
                    this.openAlert("사용자 정보를 가져오지 못했습니다.")
                })
            },
            goList: function () {
                console.log("goList");
                this.$router.push({name: 'user-list'});
            },
            saveConfirm: function () {
                let permission = "";
                if(this.userId !== null && this.categoryCode.length > 0) {
                    for (let i in this.categoryCode) {
                        permission += this.categoryCode[i];
                    }
                    console.log("permission : "+permission)
                    this.openConfirm("저장하시겠습니까?", this.save, permission);
                } else {
                    if(this.userId == null) this.openAlert("사용자를 선택해 주세요.");
                    else this.openAlert("권한을 선택해 주세요.")
                }
            },
            save: function (permission) {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }
                this
                .$axios
                .put('/api/mng/service-user', {
                    permission: permission,
                    userName: this.userId
                })
                .then((response) => {

                    if (response.data.success) {
                        this.openAlertClosed("저장이 완료되었습니다.", this.goList);
                    } else {
                        this.openAlert('저장중 오류가 발생했습니다.');
                    }
                    this.btnLock = false;
                })
                .catch(() => {
                    this.openAlert('저장중 오류가 발생했습니다.');
                    this.btnLock = false;
                })
            }

        }
    }
</script>
<style scoped="scoped">
    #popup_road_staff {
        display: inline-block;
    }
</style>
