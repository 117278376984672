<template>
    <div class="contents">
        <div class="loc">
            <li>
                <a href="javascript:void(0)">기본환경</a>
            </li>
            <li>
                <a href="javascript:void(0)">장치 관리</a>
            </li>
        </div>
        <div class="wapper" style="min-height:700px">
            <div class="title">
                <h2>장치 관리</h2>
            </div>
            <h3>
                <div class="btn-right">
                    <button class="btn_search" @click="openSearch()">검색</button>
                    <button class="btn_download" @click="confirmDownload()">다운로드</button>
                    <div class="search">
                        <h3>검색</h3>
                        <div class="search_detail">
                            <dl class="full_width">
                                <dt>장치타입</dt>
                                <dd><select v-model="searchDeviceType" @change="changeSearchDeviceType">
                                    <option value='all'>전체</option>
                                    <option value='SG'>스피드게이트</option>
                                    <option value='KS'>키오스크</option>
                                </select></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>장치 검색(장치아이디,장치명)</dt>
                                <dd><input type="text" v-model='searchKeyword'/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>설치장소</dt>
                                <dd><input type="text" v-model='searchPlaceName'/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>장치IP</dt>
                                <dd><input type="text" v-model='searchDeviceIp'/></dd>
                            </dl>
                            <dl class="full_width">
                                <dt>상태</dt>
                                <dd><select v-model="searchPrintState" @change="changeSearchPrintState">
                                    <option value='all'>전체</option>
                                    <option value='0'>정상</option>
                                    <option value='-1'>끊김</option>
                                    <option value='1'>용지 걸림</option>
                                    <option value='2'>용지 부족</option>
                                    <option value='3'>용지 없음</option>
                                </select></dd>
                            </dl>
                        </div>
                        <div class="search_footer">
                            <button
                                class="btn_secondary btn_md btn_filer"
                                :class="{'btn_filered' : filter}"
                                @click="changeFilter()">{{this.filter ? "필터해제" : "필터적용" }}</button>
                            <button class="btn_secondary btn_md" @click="closeSearch()">취소</button>
                            <button class="btn_primary btn_md" @click="firstSearch()">적용</button>
                        </div>
                    </div>
                </div>
            </h3>
            <div class="table table_line">
                <table>
                    <tr>
                        <th>장치타입</th>
                        <th>장치 아이디</th>
                        <th>장치명</th>
                        <th>설치장소</th>
                        <th>장치 IP</th>
                        <th>하위 장치 아이디</th>
                        <th>출입 구분</th>
                        <th>와이드 여부</th>
                        <th>장치순서</th>
                        <th>관리</th>
                    </tr>
                    <tr>
                        <td>
                            <select @change="changeType($event)" v-model="deviceTypeCd">
                                <option
                                    v-for="(item) of this.deviceTypeList"
                                    :key="item.typeCd"
                                    :value="item.typeCd">{{ item.typeNm}}</option>
                            </select>
                        </td>
                        <td>
                            <input
                                type="text"
                                v-model="deviceId"
                                :disabled="deviceTypeCd == 'SG' || !addBtnShow"/>
                        </td>
                        <td><input v-if="deviceTypeCd !='SG'" type="text" v-model="deviceName"/>
                            <select
                                @change="selectDevice($event)"
                                v-if="deviceTypeCd=='SG'"
                                v-model="deviceId">
                                <option value="" disabled="disabled">장치를 선택하세요.</option>
                                <option
                                    v-for="(item, index) of this.bsDeviceList"
                                    :key="index"
                                    :value="item.id">{{item.name}}</option>
                            </select>
                        </td>
                        <td><input type="text" v-model="placeName" :disabled="deviceTypeCd == 'SG'"/></td>
                        <td><input type="text" v-model="deviceIp"/></td>
                        <td><input type="text" v-model="subDeviceId" v-if="deviceTypeCd == 'SG'"/></td>
                        <td>
                            <select @change="changeInOut" v-model="inOut" v-if="deviceTypeCd == 'SG'">
                                <option value="IN" selected>입실</option>
                                <option value="OUT">퇴실</option>
                            </select>
                        </td>
                        <td>
                            <input type="checkbox" id="isWide" v-model="wide" v-if="deviceTypeCd == 'SG'"/>
                            <label for="isWide" style="margin-left: 10px;" v-if="deviceTypeCd == 'SG'">
                                <span style="vertical-align: -webkit-baseline-middle;">와이드형</span>
                            </label>
                        </td>
                        <td><input type="text" v-model="deviceOrder"/></td>
                        <td>
                            <button v-show="modifyBtnShow" @click="saveConfirm()">수정</button>
                            <button v-show="modifyBtnShow" @click="btnInit()">초기화</button>
                            <button v-show="addBtnShow" @click="saveConfirm()">추가</button>
                        </td>
                    </tr>
                </table>
                <br/>
                <br/>
                <table>
                    <tr>
                        <th>장치타입</th>
                        <th>장치 아이디</th>
                        <th>장치명</th>
                        <th>상태</th>
                        <th>설치장소</th>
                        <th>장치 IP</th>
                        <th>하위 장치 아이디</th>
                        <th>출입구분</th>
                        <th>와이드 여부</th>
                        <th>장치순서</th>
                        <th>관리</th>
                    </tr>
                    <tr @click="deviceDetail(item)" v-for="(item,index) of this.deviceList" :key="index">
                        <td>{{getDeviceTypeName(item.deviceTypeCd)}}</td>
                        <td>{{item.deviceId}}</td>
                        <td>{{item.deviceName}}</td>
                        <td>{{getDevicePrintState(item)}}</td>
                        <td>{{item.placeName}}</td>
                        <td>{{item.deviceIp}}</td>
                        <td>{{item.subDeviceId}}</td>
                        <td>{{item.inOut}}</td>
                        <td>
                            {{item.deviceTypeCd == 'SG' && item.wide ? '와이드형' : '일반형'}}
                        </td>
                        <td>{{item.deviceOrder}}</td>
                        <td>
                            <button @click="deleteConfirm(index)">삭제</button>
                        </td>
                    </tr>
                </table>
            </div>
            <pagination :pageArray ="pageNation" :page ="page" v-if="pageNation.length != 0" 
            :type="searchDeviceType" :searchWord="searchKeyword" :placeName="searchPlaceName"
            :deviceIp="searchDeviceIp" :status="searchPrintState"
            />
        </div>
    </div>

</template>
<script>
    import pagination from '../../components/commonPagination.vue'
    import mixin from '../../mixin/mixin.js';
    import moment from 'moment';
    import $ from 'jquery'

    export default {
        data: () => {
            return {
                deviceList: [],
                deviceId: "",
                deviceName: null,
                parentDeviceId: null,
                placeName: null,
                placeId: null,
                deviceIp: null,
                deviceTypeCd: 'KS',
                deviceTypeNm: null,
                deviceOrder: 0,
                insertType: "",
                bsDeviceList: [],
                wide: false,
                inOut: 'IN',
                subDeviceId: null,
                addBtnShow: true,
                modifyBtnShow: false,
                deviceTypeList: [],
                searchDeviceType: 'all',
                searchKeyword: null,
                searchPlaceName: null,
                searchDeviceIp: null,
                searchPrintState: 'all',
                filter: false
            }
        },
        mounted: function () {
            console.log("get kiosk")
            this.getDeviceType();
        },
        mixins: [mixin],
        created: function () {
            if(this.$route.query.page) {
                this.changeFilter()
                this.searchDeviceType = this.$route.query.type || null;
                this.searchKeyword = this.$route.query.searchWord || null;
                this.searchPlaceName = this.$route.query.placeName || null;
                this.searchDeviceIp = this.$route.query.deviceIp || null;
                this.searchPrintState = this.$route.query.status || null;
                this.getList(this.$route.query.page);
            } else {
                this.getList(0);
            }
            this.getBiostarDoor();
        },
        components : {
            pagination
        },
        methods: {
            getList: function (page) {

                if (page < 0 || this.page.totalPages < page) {
                    return;
                }

                let params = {};
                if (this.filter) {
                    let obj = {
                        page: page,
                        size: 30,
                        searchDeviceType: this.searchDeviceType,
                        searchKeyword: this.searchKeyword,
                        searchPlaceName: this.searchPlaceName,
                        searchDeviceIp: this.searchDeviceIp,
                        searchPrintState: this.searchPrintState
                    }
                    params = obj
                } else {
                    let obj = {
                        page: page,
                        size: 30
                    }
                    params = obj
                }
                console.log(JSON.stringify(params));
                this
                .$axios
                .get('/api/mng/setting/v1/device/list', {params: params})
                .then((response) => {

                    if (response.data.success) {
                        console.log(response.data);

                        for (let api of response.data.result.content) {
                            api.modifyYn = false;
                        }

                        this.deviceList = JSON.parse(JSON.stringify(response.data.result.content));

                        let pageInfo = response.data.result;
                        this.pagerHandler(
                            pageInfo.number,
                            pageInfo.first,
                            pageInfo.last,
                            pageInfo.totalPages
                        );

                    }

                    this.deviceTypeCd = 'KS';
                    this.placeId = null;

                })

            },
            getBiostarDoor: function () {
                this
                .$axios
                .get('/api/mng/setting/v1/device/bs/list')
                .then((response) => {
                    console.log(response.data);
                    if (response.data.success) {
                        this.bsDeviceList = response.data.result;
                    }
                })

            },
            firstSearch: function () {
                let pageQuery = {
                    page : 0,
                    type : this.searchDeviceType || null,
                    searchWord : this.searchKeyword || null,
                    placeName : this.searchPlaceName || null,
                    deviceIp : this.searchDeviceIp || null,
                    status : this.searchPrintState || null,
                }
                this.$router.push({
                    path: this.$route.path,
                    query: pageQuery
                }).catch(()=>{});
            },
            changeType: function (event) {
                console.log(event.target.value);

                if (addBtnShow) {
                    if (event.target.value == "SG") {
                        this.getBiostarDoor();
                    }
                    this.placeName = null;
                    this.deviceName = null;
                    this.deviceId = "";
                }
            },
            selectDevice: function (event) {
                console.log(event.target);
                for (let item of this.bsDeviceList) {
                    if (item.id == event.target.value) {
                        this.placeName = item.doorGroupId.name;
                        this.placeId = item.doorGroupId.id;
                        this.deviceName = item.name;
                        this.subDeviceId = item.entryDeviceId.id;
                    }
                }
            },
            deleteConfirm: function (index) {
                this.openConfirm("정말로 삭제하시겠습니까?", this.deleteDevice, index);
            },
            deleteDevice: function (index) {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                let deviceId = this
                    .deviceList[index]
                    .deviceId;
                console.log(deviceId);

                this
                    .$axios
                    .post('/api/mng/setting/v1/device/del', {deviceId: deviceId})
                    .then((response) => {
                        if (response.data.success) {
                            this.openAlert("장치를 삭제했습니다.");
                            this.getList(0);
                            this.btnLock = false;
                        }
                    })
            },
            cancel: function () {
                this.getList(this.page.number);
            },
            btnInit: function () {
                this.deviceId = "",
                this.deviceName = null,
                this.parentDeviceId = null,
                this.placeName = null,
                this.placeId = null,
                this.deviceIp = null,
                this.deviceTypeCd = 'KS',
                this.deviceTypeNm = null,
                this.deviceOrder = 0;
                this.inOut = 'IN';
                this.subDeviceId = null;
                this.wide = false;
                this.modifyBtnShow = false;
                this.addBtnShow = true;
            },
            saveConfirm: function () {

                if (this.deviceId == null) {
                    this.openAlert("장치 아이디는 필수 입니다.");
                    return;
                }
                if (this.deviceId.trim() == '') {
                    this.openAlert("장치 아이디는 필수 입니다.");
                    return;
                }

                if (!/^[0-9]{0,4}$/.test(this.deviceOrder)) {
                    this.openAlert("장치 순번은 숫자만 가능합니다.");
                    return;
                }

                if (this.modifyBtnShow)
                    this.openConfirm("수정하시겠습니까?", this.modifyDevice);
                else
                    this.openConfirm("작성한 내용을 추가하시겠습니까?", this.save);

                }
            ,
            modifyDevice: function (index) {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                this
                .$axios
                .put('/api/mng/setting/v1/device', {
                    deviceId: this.deviceId,
                    deviceName: this.deviceName,
                    placeId: this.placeId,
                    placeName: this.placeName,
                    deviceIp: this.deviceIp,
                    deviceTypeCd: this.deviceTypeCd,
                    deviceOrder: this.deviceOrder,
                    inOut: this.inOut,
                    subDeviceId: this.subDeviceId,
                    wide: this.wide
                })
                .then((response) => {
                    if (response.data.success) {
                        this.openAlert("장치를 수정했습니다.");
                        this.getList(0);
                        this.btnInit();
                        this.btnLock = false;
                    }
                })
            },
            deviceDetail: function (item) {
                this.deviceTypeCd = item.deviceTypeCd;
                this.deviceId = item.deviceId;
                this.deviceName = item.deviceName;
                this.placeId = item.placeId;
                this.placeName = item.placeName;
                this.deviceIp = item.deviceIp;
                this.deviceOrder = item.deviceOrder;
                this.inOut = item.inOut;
                this.subDeviceId = item.subDeviceId;
                this.modifyBtnShow = true;
                this.addBtnShow = false;
                this.isActive = true;
            },
            save: function () {
                if (this.btnLock) {
                    return;
                } else {
                    this.btnLock = true;
                }

                this
                .$axios
                .post('/api/mng/setting/v1/device', {
                    deviceId: this.deviceId,
                    deviceName: this.deviceName,
                    placeId: this.placeId,
                    placeName: this.placeName,
                    deviceIp: this.deviceIp,
                    deviceTypeCd: this.deviceTypeCd,
                    deviceOrder: this.deviceOrder,
                    inOut: this.inOut,
                    subDeviceId: this.subDeviceId,
                    wide: this.wide
                })
                .then((response) => {
                    console.log(response.data.result);
                    if (response.data.success) {
                        this.openAlertClosed("저장이 완료되었습니다.", this.getList, this.page.number);
                        this.btnInit();
                    } else {
                        this.openAlert("저장중 오류가 발생했습니다.");
                    }
                    this.btnLock = false;
                })
                .catch(() => {
                    this.openAlert("저장중 오류가 발생했습니다.");
                    this.btnLock = false;
                })

            },
            getDeviceType: function () {
                console.log("kiosk type");
                this
                .$axios
                .get('/api/mng/setting/v1/device/deviceType')
                .then((response) => {
                    console.log("kiosk");
                    console.log(response.data.result);
                    this.deviceTypeList = response.data.result;
                })
                .catch((err) => {
                    console.log("에러 메시지 :" + err);
                });
            },
            getDeviceTypeName: function (deviceTypeCd) {
                for (let item of this.deviceTypeList) {

                    if (deviceTypeCd === item.typeCd) {
                        return item.typeNm;
                    }
                }
            },
            getDevicePrintState: function (item) {
                let state = "";
                if (item.deviceTypeCd !== "SG") {
                    if(item.printState == 0) state = "정상";
                    else if(item.printState == -1) state = "끊김";
                    else if(item.printState == 1) state = "용지 걸림";
                    else if(item.printState == 2) state = "용지 부족";
                    else if(item.printState == 3) state = "용지 없음";
                }
                return state;
            },
            changeInOut(event) {
                this.inOut = event.target.value;
            },
            openSearch: function () {
                $('.search').slideToggle('down');
            },
            closeSearch: function () {
                $('.search').slideToggle('up');
            },
            changeFilter: function () {
                this.filter = !this.filter;
                if (!this.filter) {
                    this.initSearchCnd();
                }
            },
            initSearchCnd: function () {
                this.searchDeviceType = 'all';
                this.searchKeyword = null;
                this.searchPlaceName = null;
                this.searchDeviceIp = null;
                this.searchPrintState = 'all';
            },
            changeSearchDeviceType(event) {
                this.searchDeviceType = event.target.value;
            },
            changeSearchPrintState(event) {
                this.searchPrintState = event.target.value;
            },
            confirmDownload: function () {
                this.downloadConfirm(this.confirmExcelDown);
            },
            confirmExcelDown: function (param) {
                this.openConfirm("엑셀로 다운로드 하시겠습니까?", this.excelDown, param);
            },
            excelDown: function (paramdata) {
                let param = {};

                if (this.filter) {
                    param = {
                        page: 0,
                        size: 100000,
                        searchDeviceType: this.searchDeviceType,
                        searchKeyword: this.searchKeyword,
                        searchPlaceName: this.searchPlaceName,
                        searchDeviceIp: this.searchDeviceIp,
                        searchPrintState: this.searchPrintState
                    }
                } else {
                    param = {
                        page: 0,
                        size: 100000,
                    }
                }
                this
                .$axios
                .get('/api/mng/setting/v1/device/list/excel', {
                    params: param,
                    responseType: "blob"
                })
                .then((response) => {
                    console.log(response);
                    const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download','장치관리_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx');
                    document.body.appendChild(link);
                    link.click();

                    if(response.status == 200) {
                          let param = {
                              fileName: '장치관리_' + moment().format("YYYYMMDD_HHmmss") + '.xlsx',
                              fileSize: response.data.size,
                              purpose: paramdata.purpose,
                              purposeEtc: paramdata.purpose_etc == null ? '' : paramdata.purpose_etc
                          }
                          this.setDownloadHistory(param);
                    }
                })
            }
        }
    }
</script>
<style scoped="scoped">
    .search_footer .btn_filered {
        margin-right: auto;
        justify-self: start;
        background-color: #E85656;
        border: 1px solid #E85656;
        color: white;
    }
</style>
